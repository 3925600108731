import { FeedBackIconTuple, FeedbackTypes } from '../model/utils.typings';

export class FeedBackIconHelper {
	private static readonly iconsMappingList: FeedBackIconTuple[] = [
		{'type': FeedbackTypes.BACKEND_ERROR, 'icon': 'error'},
		{'type': FeedbackTypes.FRONTEND_ERROR, 'icon': 'error'},
		{'type': FeedbackTypes.BACKEND_WARNING, 'icon': 'warning'},
		{'type': FeedbackTypes.FRONTEND_WARNING, 'icon': 'warning'},
		{'type': FeedbackTypes.BACKEND_INFORMATION, 'icon': 'information'},
		{'type': FeedbackTypes.FRONTEND_INFORMATION, 'icon': 'information'},
		{'type': FeedbackTypes.BACKEND_SUCCESS, 'icon': 'done'},
		{'type': FeedbackTypes.FRONTEND_SUCCESS, 'icon': 'done'},
	];

	static getIconName(type: string): string {
		const tuple = FeedBackIconHelper.iconsMappingList.find((element: FeedBackIconTuple) => element.type === type);
		if (!tuple) {
			throw new Error(`There are no icon defined for the feedbacks of type ${type}`);
		}

		return tuple.icon;
	}
}

