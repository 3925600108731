import { PdfStatementStatus } from '@mdib/pdf-statements/model/pdf-statement-status.enum';
import { CashAccount } from '@mdib/cash-accounts';


export class PdfStatementsPageFilter {
	cashAccount: CashAccount;
	status: PdfStatementStatus;
	startDate: Date;
	endDate: Date;
}
