import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { saveAs } from 'file-saver/FileSaver';

import { ModalDialogComponent, ModalDialogProperties } from '@mdib-pages/generic';
import { AccountStatement, AccountStatementsPageFilter, AccountStatementsService, AccountStatementStatus } from '@mdib/account-statements';
import { CashAccount } from '@mdib/cash-accounts';
import { GenericListPage, CommonDataSource } from '@mdib/generic';
import { SessionUtilsService } from '@mdib/sessions';
import { IconButton } from '@mdib/styling';
import { FileStatus, MdibFile, ServiceResponse, FileFormat, ConfigurationService } from '@mdib/utils';
import { NotificationMessageService } from '@mdib/notification-message';

@Component({
	selector: 'account-statements-page',
	templateUrl: './account-statements-page.component.html',
	styleUrls: ['./account-statements-page.component.scss'],
})
export class AccountStatementsPageComponent extends GenericListPage implements OnInit {

	readonly NAMESPACE = 'accountStatementsPagesModule.accountStatementsPageComponent.';

	form: FormGroup;
	isB2B = false;
	isCashAccountQueried = false;

	public filter: AccountStatementsPageFilter;
	public displayedColumns = ['account', 'date', 'statementNumber', 'status', 'actions'];
	readonly roles;
	protected iconArray: Array<IconButton> = [{ iconName: 'file_download', iconLabel: 'download' }];

	protected accountNumber: string;
	protected AccountStatementStatus = AccountStatementStatus;
	protected FileStatus = FileStatus;
	protected statementDownloading: boolean[] = [];
	private representedClientNumber: string;

	constructor(
		private route: ActivatedRoute,
		private accountStatementsService: AccountStatementsService,
		private dialog: MatDialog,
		private fb: FormBuilder,
		private sessionUtilsService: SessionUtilsService,
		private config: ConfigurationService,
		private notificationMessageService: NotificationMessageService,
		injector: Injector,
	) {
		super(injector);
		this.roles = this.config.instant('user.roles');
		this.dataSourceType = CommonDataSource;
		this.create();
		this.listConfigurations.listTitle = this.NAMESPACE + 'accountStatementsTitle';
		this.listConfigurations.selectorTitle = this.NAMESPACE + 'accountLabel';
		this.listConfigurations.listEmptyMessage = this.NAMESPACE + 'listEmptyMessage';
		this.filter = {
			cashAccount: null,
			status: AccountStatementStatus.New,
			fileFormat: this.fileExt.value,
			accountingDateFrom: null,
			accountingDateTo: null,
			statementNumberFrom: null,
			statementNumberTo: null,
			statementYear: null,
		};
		if (this.roles && this.sessionUtilsService.getSessionActiveBusinessRole() === this.roles.b2b) {
			this.isB2B = true;
			this.listConfigurations.showFormat = true;
			this.listConfigurations.formatTitle = this.NAMESPACE + 'format';
			this.displayedColumns = ['account', 'format', 'date', 'statementNumber', 'status', 'actions'];
		}
	}

	ngOnInit() {
		// Load URL context
		this.route.queryParams.subscribe(params => {
			this.loadSearchModel(params, this.filter);
			if (this.isB2B) {
				this.accountStatementsService.getRepresentedClientNumber().subscribe((response) => {
					this.representedClientNumber = response.getModel();
					this.filter.representedClientNumber = this.representedClientNumber;
					this.subscriptions();
					this.fetchData();
				});
			} else {
				this.applyFilter();
			}
		});
		this.isCashAccountQueried = this.filter.cashAccount ? true : false;
	}

	create() {
		this.form = this.fb.group({
			fileExt: FileFormat.mt940,
		});
	}

	count(): void {
		this.accountStatementsService.count(this.filter).subscribe((serviceResponse: ServiceResponse<number>) => {
			this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponse, this.NAMESPACE);
			this.listConfigurations.totalCount = serviceResponse.getModel();
		},
			(serviceResponseError: ServiceResponse<null>) => {
				this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
			});
	}

	clearFilter(): void {
		this.filter = {
			cashAccount: null,
			fileFormat: FileFormat.mt940,
			status: AccountStatementStatus.New,
			accountingDateFrom: null,
			accountingDateTo: null,
			statementNumberFrom: null,
			statementNumberTo: null,
			statementYear: null,
		};
		if (this.isB2B) {
			this.filter.representedClientNumber = this.representedClientNumber;
		}
	}

	consultRow(row: any, index?: number): void {
		if (row.file.format === FileFormat.pdf) {
			this.getAccountStatement(row).subscribe(
				(file: MdibFile) => {
					const fileAvailable = file.status === FileStatus.Available;

					this.dialog.open(ModalDialogComponent,  <MatDialogConfig<ModalDialogProperties>> {
						width: '1000px',
						maxWidth: '99%',
						disableClose: true,
						data: <ModalDialogProperties> {
							document: fileAvailable ? file.document : null,
							rightButtonLabel: fileAvailable ? this.NAMESPACE + 'generic:readingAcknowledgement' : this.NAMESPACE + 'generic:ok',
							leftButtonLabel: fileAvailable ? this.NAMESPACE + 'generic:unreadingAcknowledgement' : null,
							message: fileAvailable ? this.NAMESPACE + 'accountStatementMessage' : null,
							content: fileAvailable ? null : this.NAMESPACE + 'fileUnavailableMessage',
							documentName: fileAvailable ? file.name : null,
							isModalView: true,
						}
					}).componentInstance.rightButtonClicked.subscribe(() => {
						// actions to be performed on "I have printed" button click
						if (fileAvailable) {
							this.updateStatement(row, index);
						}
					});
				},
				(error) => {
					console.log(error);
				},
			);
		}
	}

	/**
	 * This method is called to download an account statement in the xml format.
	 * @param {row}
	 * @param {index}
	 * @param {boolean} indicates if the status must be updated or not.
	 */
	downloadFile(row: any, index?: number) {
		if (row.file.status === FileStatus.Available) {
			this.notificationMessageService.clearAll();
			this.statementDownloading[index] = true;
			this.getAccountStatement(row).subscribe(
				(file: MdibFile) => {
					if (!file || !file.document) {
						this.serviceResponseNotifMapper.sendResponseFeedbacks(new ServiceResponse('No data file to download for this statement'));
					} else {
						saveAs(file.document, file.name);
					}

					this.updateStatement(row, index);
					this.statementDownloading[index] = false;

				},
				() => {
					this.statementDownloading[index] = false;
					this.serviceResponseNotifMapper.sendResponseFeedbacks(new ServiceResponse('There was an issue while retrieving the account statement'));
				},
			);
		}
	}

	/**
	 * This method is called to retrieve a file of an account statement.
	 * @param row
	 * @returns {Observable<MdibFile>}
	 */
	getAccountStatement(row: any): Observable<MdibFile> {
		const sendFeedbacks = (response: ServiceResponse<null>) => this.serviceResponseNotifMapper.sendResponseFeedbacks(response, this.NAMESPACE);
		const extractStatement = (serviceResponse: ServiceResponse<MdibFile>) => serviceResponse.getModel();
		return this.accountStatementsService.get(row.file.name).pipe(
			tap(sendFeedbacks, sendFeedbacks),
			map(extractStatement),
		);
	}

	/**
	 * This method update an account statement status to "Read" and refreshes it in the table.
	 * @param row
	 */
	updateStatement(row: any, index: number): void {
		this.statementDownloading[index] = true;
		if (row.status === AccountStatementStatus.New) {
			const sendFeedbacks = (response: ServiceResponse<null>) => {
				this.serviceResponseNotifMapper.sendResponseFeedbacks(response, this.NAMESPACE);
			};
			const extractStatement = (serviceResponse: ServiceResponse<AccountStatement>) => serviceResponse.getModel();

			const saveState = (statement: AccountStatement) => {
				statement.file.id = row.file.id; // FIXME : update call doesn't send fileId, so we use the old.
				statement.file.name = row.file.name; // FIXME : update call doesn't send fileName, so we use the old.
				statement.status = AccountStatementStatus.Read;
				statement.accountingStartDate = row.accountingStartDate;
				// Change dataSource row with new statement
				const dataSourceIndex = this.listConfigurations.dataSource.data.findIndex(x => x.identifier === row.identifier);
				this.listConfigurations.dataSource.data[dataSourceIndex] = statement;
				this.listConfigurations.dataSource = new CommonDataSource(this.listConfigurations.dataSource.data);
				this.statementDownloading[index] = false;
			};

			const updateStream = this.accountStatementsService.update(row).pipe(
				tap(sendFeedbacks, sendFeedbacks),
				map(extractStatement),
			);

			zip(updateStream, saveState).subscribe();
		} else {
			this.statementDownloading[index] = false;
		}
	}

	get fileExt(): FormControl {
		return <FormControl>this.form.get('fileExt');
	}

	onCashAccountChange(cashAccount: CashAccount): void {
		this.clearFilter();
		this.filter.cashAccount = (cashAccount != null) ? cashAccount : null;
		this.applyFilter();
	}

	onClearFilter() {
		this.clearFilter();
		this.applyFilter();
	}

	protected load(index?: number, count?: number): Observable<ServiceResponse<any>> {
		return this.accountStatementsService.list(index, count, this.filter);
	}

	private subscriptions() {
		this.fileExt.valueChanges.subscribe(value => {
			this.filter.fileFormat = value;
			this.applyFilter();
		});
	}
}
