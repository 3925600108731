import { Injectable } from '@angular/core';
import { CashAccountMemoryService } from './cash-account-memory.service';
import { CashAccountSortOrder, CashAccountSortOrderService } from '@mdib/cash-accounts';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CashAccountSortOrderMemoryService extends CashAccountSortOrderService {

	constructor(private cashAccountMemoryService: CashAccountMemoryService) {
		super();
	}

	public update(accountSortOrder: CashAccountSortOrder): Observable<ServiceResponse<CashAccountSortOrder>> {
		this.cashAccountMemoryService.updateSortOrder(accountSortOrder);
		return of(new ServiceResponse<CashAccountSortOrder>(accountSortOrder));
	}
}
