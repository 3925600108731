import { Input, Output, EventEmitter } from '@angular/core';

import { Signable } from '../interfaces/signable';

/**
 * Component representing one tpye of signature process.
 */
export abstract class SignatureProcessComponent {

	/**
	 * @property (Input) Object to be signed
	 */
	@Input()
	public signable: Signable;

	/**
	 * @property (Output) Emits the signed object
	 */
	@Output()
	public onSuccess: EventEmitter<Signable> = new EventEmitter<Signable>();

	/**
	 * @property (Output) Emits events on errors or cancellation
	 */
	@Output()
	public onEvent: EventEmitter<any> = new EventEmitter<any>();

}
