import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CordovaPluginIroot } from '../types/cordova-plugin-iroot';

type CordovaOnSuccess = (result: any) => void;
type CordovaOnFailure = (error: any) => void;

@Injectable({
	providedIn: 'root'
})
export class CordovaPluginIrootService {

	public isRooted(): Observable<boolean> {
		return new Observable(ob => {
			const method = this.getPluginMethod('isRooted');
			method(r => ob.next(r), () => ob.next(false));
		});
	}

	public isRootedRedBeer(): Observable<boolean> {
		return new Observable(ob => {
			const method = this.getPluginMethod('isRootedRedBeer');
			method(r => ob.next(r), () => ob.next(false));
		});
	}

	public isRootedRedBeerWithoutBusyBoxCheck(): Observable<boolean> {
		return new Observable(ob => {
			const method = this.getPluginMethod('isRootedRedBeerWithoutBusyBoxCheck');
			method(r => ob.next(r), () => ob.next(false));
		});
	}

	protected getPluginMethod(method: string): (onSuccess: CordovaOnSuccess, onFailure: CordovaOnFailure) => void {
		// If the plugin is missing (not mobile), we mock it
		const plugin: CordovaPluginIroot = window['IRoot'];
		if (!plugin || !plugin[method] || typeof plugin[method] !== 'function') {
			return (onSuccess) => { onSuccess(false); };
		}

		return plugin[method];
	}

}
