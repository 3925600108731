import { ModuleWithProviders, NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { ShoppingBasketService } from '@mdib/shopping-basket';

import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';

import { ShoppingBasketOperationMapperXclService } from './service/shopping-basket-operation-mapper-xcl.service';
import { ShoppingBasketXclService } from './service/shopping-basket-xcl.service';

@NgModule()
export class XclShoppingBasketModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(ShoppingBasketService, adapterId, ShoppingBasketXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: XclShoppingBasketModule,
			providers: [ShoppingBasketOperationMapperXclService]
		};
	}
}
