import { NgModule } from '@angular/core';

import { UtilsModule } from '@customs/utils';

@NgModule({
	imports: [
		UtilsModule,
	]
})
export class CustomsImports { }
