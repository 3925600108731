import { FileFormat } from '@mdib/utils';

export enum FileStatus {
	Available,
	Unavailable,
}

export class MdibFile {
	id: string;
	status: FileStatus;
	name: string;
	document: Blob;
	format?: FileFormat;
}

export class PdfStatementFile {
	content: string;
	contentType: string;
	extension: string;
	name: string;
	format?: FileFormat;
}
