import { Contact } from './contact';
import { ContactType } from './person.enum';
import { SignatureModes } from '@mdib/signature-modes';
import { Status } from '@mdib/utils';

export class ContactPhoneNumber implements Contact {

	reference: string;
	status: Status;
	identifier: string;
	signatureTypesAllowed: SignatureModes[];

	constructor(public phoneNumber: string, public label: string) {
	}

	getType(): ContactType {
		return ContactType.PhoneNumber;
	}

	toStrings(): string[] {
		return [this.phoneNumber];
	}
}
