import { Component } from '@angular/core';

import { provideNamespace } from '@mdib/core/customization';
import { TableComponent, TableRowAnimation } from '@mdib/commons';

import { Beneficiary } from '../../types/models/beneficiary';
import { BeneficiaryType } from '../../types/enums/beneficiary-type.enum';
import { UtilsHelper } from '@mdib/utils';

@Component({
	selector: 'beneficiaries-table',
	templateUrl: './beneficiaries-table.component.html',
	styleUrls: ['./beneficiaries-table.component.scss'],
	viewProviders: [provideNamespace('beneficiaries', 'beneficiariesTableComponent')],
	animations: [TableRowAnimation]
})
export class BeneficiariesTableComponent extends TableComponent<Beneficiary> {

	public BeneficiaryType = BeneficiaryType;

	// Table setup
	protected displayedColumns: Array<string> = ['expander', 'beneficiary', 'accountNumber', 'address', 'country', 'actions'];

	protected isCommunicationEmpty(communication: string): boolean {
		return UtilsHelper.isNullOrWhiteSpace(communication);
	}
}
