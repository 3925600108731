import { Injectable } from '@angular/core';

import { SecretQuestionsList, SecretQuestion } from '@mdib/customers';

import { MonogoalOrderXCLModel } from '@mdib-xcl/http';

import { XclSecretQuestionModel, XclSecretQuestionsModel } from '../types/models/xcl-secret-questions-model';

@Injectable()
export class SecretQuestionsMapperXclService {

	getFunctionalDataFromXclData(xclOrder: MonogoalOrderXCLModel<XclSecretQuestionsModel>): SecretQuestionsList {
		const questions = new Array<SecretQuestion>();
		if (xclOrder.goal.listSecuritySecretQuestionAnswers) {
			xclOrder.goal.listSecuritySecretQuestionAnswers.forEach(item => {
				questions.push(new SecretQuestion({
					id: item.questionIdentifier,
					question: 'secretQuestions.questions.xcl.' + item.questionIdentifier,
					answer: item.answer,
				}));
			});
		}

		return new SecretQuestionsList({ questions: questions });
	}

	/**
	 * Be careful that this method doesn't fill the principal need for XCL input
	 *
	 * @param {SecretQuestionsList[]} secretQuestionsList
	 * @returns {XclSecretQuestionsModel}
	 */
	getXclDataFromFunctionalData(secretQuestionsList: SecretQuestionsList, principal?: string): XclSecretQuestionsModel {
		const listSecuritySecretQuestionAnswers = <XclSecretQuestionModel[]>[];
		secretQuestionsList.questions.forEach(secretQuestion => {
			const xclSecretQuestionModel = <XclSecretQuestionModel>{};
			xclSecretQuestionModel.questionIdentifier = secretQuestion.id;
			xclSecretQuestionModel.answer = secretQuestion.answer;
			listSecuritySecretQuestionAnswers.push(xclSecretQuestionModel);
		});
		const xclSecretQuestions = <XclSecretQuestionsModel>{};
		xclSecretQuestions.listSecuritySecretQuestionAnswers = listSecuritySecretQuestionAnswers;
		if (principal) {
			xclSecretQuestions.principalIdentification = principal;
		}
		return xclSecretQuestions;
	}
}
