import { Injectable } from '@angular/core';

import { ConfigurationService } from '@mdib/utils';

import { AccessRight } from '../types/access-right';
import { Authorization } from '../types/authorization.enum';

@Injectable()
export class AuthorizationsService {

	private accessRights: Array<AccessRight> = [];

	constructor(
		private configurationService: ConfigurationService,
	) { }

	public authorized(allowedTokens: Array<string>): boolean {
		// No access rights
		if (this.accessRights.length === 0) {
			return this.configurationService.instant('application.security.defaultAccessRight');
		}

		// Check tokens against access rights
		return [Authorization.ALWAYS, Authorization.GRANT].indexOf(
			this.accessRights
				.map((accessRight: AccessRight) => accessRight.authorizationFor(allowedTokens))
				.reduce((prev: Authorization, next: Authorization) => Math.min(prev, next))
		) >= 0;
	}

	public addAccessRight(...accessRights: AccessRight[]) {
		accessRights.forEach(accessRight => {
			if (this.accessRights.indexOf(accessRight) < 0) {
				this.accessRights.push(accessRight);
			}
		});
	}

	public remAccessRight(accessRight: AccessRight) {
		this.accessRights = this.accessRights.filter(value => value !== accessRight);
	}

	public flushAccessRights() {
		this.accessRights = [];
	}

}
