import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdapterSwitchService } from '@mdib/core/adapters';

import { MultiAdaptersService } from './services/multi-adapters.service';
import { CUSTOM_BUTTONS } from './types/operation-playbar-buttons';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [],
	providers: [
		{ provide: AdapterSwitchService, useClass: MultiAdaptersService },
		...CUSTOM_BUTTONS,
	]
})
export class UtilsModule { }
