import { Injectable } from '@angular/core';
import { Authentication } from '@mdib/sessions';
import { OtpAuthenticationService } from '@mdib/sessions-modes';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';

import { AuthenticationXclService } from './authentication-xcl.service';

@Injectable({
	providedIn: 'root',
})
export class OtpAuthenticationXclService extends AuthenticationXclService implements OtpAuthenticationService {

	protected AUTHENTICATION_TYPE = 'OTP';

	public initAuth(username: string): Observable<ServiceResponse<Authentication>> {
		return super.prepareAuthentication(username);
	}

	public authenticate(authentication: Authentication, answerCode: string): Observable<ServiceResponse<Authentication>> {
		return super.doAuthenticate(authentication, answerCode);
	}

}
