import { Injectable } from '@angular/core';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { CashAccount } from '../model/cash-account';
import { ModifyAccountNameOperation } from '../model/modify-account-name-operation';

@Injectable()
export abstract class ModifyAccountNameService {

	/**
	 * Modify an account name
	 * @param {CashAccount} cashAccount instance of the account whose name is to be modified
	 * @return {Observable<ServiceResponse<ModifyAccountNameOperation | null>} Functional observable of {@link ModifyAccountNameOperation} or null in case of error
	 */
	public abstract update(cashAccount: CashAccount): Observable<ServiceResponse<ModifyAccountNameOperation | null>>;
}
