
import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CordovaPluginIsDebug } from '../types/cordova-plugin-is-debug';
import { CordovaService } from './cordova.service';

type CordovaOnSuccess = (result: any) => void;
type CordovaOnFailure = (error: any) => void;

@Injectable({
	providedIn: 'root'
})
export class CordovaPluginIsDebugService {

	constructor(
		private cordovaService: CordovaService,
	) { }

	public isDebug(): Observable<boolean> {
		return this.cordovaService.deviceReady.pipe(mergeMap(() =>
			new Observable(ob => {
				const method = this.getPluginMethod('getIsDebug');
				method(r => ob.next(r), () => ob.next(false));
			})
		));
	}

	protected getPluginMethod(method: string): (onSuccess: CordovaOnSuccess, onFailure: CordovaOnFailure) => void {
		// If the plugin is missing (not mobile), we mock it
		const cordova = window['cordova'];
		const plugin: CordovaPluginIsDebug = cordova && cordova.plugins && cordova.plugins.IsDebug;
		if (!plugin || !plugin[method] || typeof plugin[method] !== 'function') {
			return (onSuccess) => { onSuccess(false); };
		}
		return plugin[method];
	}

}
