import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RouterModule } from '@angular/router';
import { ConfigModule } from '@mdib/config';
import { NotificationMessageModule } from '@mdib/notification-message';
import { StylingModule } from '@mdib/styling';
import { TranslateModule } from '@ngx-translate/core';

import { BannerNotificationComponent } from './component/banner-notification/banner-notification.component';
import { DefaultBannerNotificationComponent } from './component/banner-notification/default-banner-notification.component';
import { CanDeactivateComponent } from './component/candeactivate/can-deactivate.component';
import { CanDeactivateGuard } from './component/candeactivate/can-deactivate.guard';
import { ChartsComponent } from './component/charts/charts.component';
import { CurrenciesComponent } from './component/currency/currencies.component';
import { DropdownComponent } from './component/dropdown/dropdown.component';
import { EmptyComponent } from './component/empty/empty.component';
import { ImageComponent } from './component/image/image.component';
import { InfoBoxComponent } from './component/info-box/info-box.component';
import { InlineEditComponent } from './component/inline-edit/inline-edit.component';
import { InputErrorsComponent } from './component/input-errors/input-errors.component';
import { InputSelectComponent } from './component/input-select/input-select.component';
import { NotificationBoxComponent } from './component/notification-box/notification-box.component';
import { ProgressbarComponent } from './component/progressbar/progressbar.component';
import { AccountNameMaskDirective } from './directive/account-name-mask.directive';
import { AmountMaskDirective } from './directive/amount-mask.directive';
import { CloseOnClickDirective } from './directive/close-on-click.directive';
import { FocusDirective } from './directive/focus.directive';
import { IbanMaskDirective } from './directive/iban-mask.directive';
import { NestableFormDirective } from './directive/nestable-form.directive';
import { NoCopyPasteDirective } from './directive/no-copy-paste.directive';
import { NumberMaskDirective } from './directive/number-mask.directive';
import { PhoneInputMaskDirective } from './directive/phone-input-mask.directive';
import { WorkInProgressDirective } from './directive/work-in-progress.directive';
import { CurrencyLabelPipe } from './pipe/currency-label.pipe';
import { CurrencyPipe } from './pipe/currency.pipe';
import { DateFormatterPipe } from './pipe/date-formatter.pipe';
import { IbanFormatterPipe } from './pipe/iban-formatter.pipe';
import { MdibAcronymPipe } from './pipe/mdib-acronym.pipe';
import { MdibCurrencyPipe } from './pipe/mdib-currency.pipe';
import { MdibPhoneNumberPipe } from './pipe/mdib-phone-number.pipe';
import { SpaceFormatterPipe } from './pipe/space-formatter.pipe';
import { TimeFormatterPipe } from './pipe/time-formatter.pipe';
import { TruncatePipe } from './pipe/truncate.pipe';
import { CacheService } from './service/cache.service';
import { DataFormattingService } from './service/data-formatting.service';
import { DefaultNotificationBannerStateHolderService } from './service/default-notification-banner-state-holder.service';
import { ExchangeRateService } from './service/exchange-rate.service';
import { NavigationStorage } from './service/navigation-storage';
import { TranslationLoader } from './service/translation-loader.service';
import { UniqueIdentifierService } from './service/unique-identifier.service';
import { ConfigurationService } from './service/configuration.service';
import { ConfigurationServiceStub } from './service/configuration.service.stub';

/**
 * Holds a set of front end tools related to banking applications.
 */
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		StylingModule,
		RouterModule,
		TranslateModule,
		NotificationMessageModule,
		ConfigModule,
	],
	declarations: [
		IbanFormatterPipe,
		TruncatePipe,
		DateFormatterPipe,
		CurrencyLabelPipe,
		NotificationBoxComponent,
		PhoneInputMaskDirective,
		AmountMaskDirective,
		BannerNotificationComponent,
		DefaultBannerNotificationComponent,
		InputErrorsComponent,
		EmptyComponent,
		MdibCurrencyPipe,
		IbanMaskDirective,
		WorkInProgressDirective,
		InfoBoxComponent,
		InlineEditComponent,
		CloseOnClickDirective,
		ProgressbarComponent,
		ChartsComponent,
		NestableFormDirective,
		AccountNameMaskDirective,
		TimeFormatterPipe,
		InputSelectComponent,
		MdibPhoneNumberPipe,
		MdibAcronymPipe,
		CurrencyPipe,
		SpaceFormatterPipe,
		ImageComponent,
		FocusDirective,
		CanDeactivateComponent,
		DropdownComponent,
		FocusDirective,
		NoCopyPasteDirective,
		NumberMaskDirective,
		CurrenciesComponent,
	],
	exports: [
		IbanFormatterPipe,
		TruncatePipe,
		DateFormatterPipe,
		CurrencyLabelPipe,
		NotificationBoxComponent,
		PhoneInputMaskDirective,
		AmountMaskDirective,
		IbanMaskDirective,
		BannerNotificationComponent,
		DefaultBannerNotificationComponent,
		InputErrorsComponent,
		MdibCurrencyPipe,
		WorkInProgressDirective,
		InfoBoxComponent,
		InlineEditComponent,
		CloseOnClickDirective,
		ProgressbarComponent,
		ChartsComponent,
		NestableFormDirective,
		AccountNameMaskDirective,
		TimeFormatterPipe,
		InputSelectComponent,
		MdibPhoneNumberPipe,
		MdibAcronymPipe,
		CurrencyPipe,
		SpaceFormatterPipe,
		ImageComponent,
		FocusDirective,
		DropdownComponent,
		FocusDirective,
		NoCopyPasteDirective,
		NumberMaskDirective,
		CurrenciesComponent,
	],
	providers: [
		ExchangeRateService,
		DataFormattingService,
		DefaultNotificationBannerStateHolderService,
		NavigationStorage,
		UniqueIdentifierService,
		TranslationLoader,
		{ provide: MatDialogRef, useValue: {} },
		{ provide: MAT_DIALOG_DATA, useValue: {} },
		CanDeactivateGuard,
	],
})
export class UtilsModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: UtilsModule,
			providers: [
				// Defining the cacheService here ensures that the same cache is shared by all service and components of the app
				CacheService,
			],
		};
	}

	static forTests(): ModuleWithProviders {
		return {
			ngModule: UtilsModule,
			providers: [
				CacheService,
				{ provide: ConfigurationService, useClass: ConfigurationServiceStub },
			],
		};
	}
}
