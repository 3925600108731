import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ThemeService {

	readonly styleSrc = 'custom-styles.js';
	private _theme: Subject<string> = new Subject<string>();

	get theme(): Observable<string> {
		return this._theme.asObservable();
	}

	setTheme(theme: string) {
		this._theme.next(theme);
	}
}
