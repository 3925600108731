import { Injectable } from '@angular/core';
import { SignableOperation } from '@mdib/signature';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { PaymentOperation } from '../types/models/payment-operation';
import { PaymentTypes } from '../types/models/payments.enum';
import { PendingPayment } from '../types/models/pending-payment';
import { PendingPaymentsPageFilter } from '../types/models/pending-payments-page-filter';

import { PaymentsService } from './payments.service';

@Injectable()
export class PaymentsServiceStub extends PaymentsService {

	public validatePayment(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null>>> {
		return of();
	}

	public get(reference: string, type: PaymentTypes): Observable<ServiceResponse<PaymentOperation<null>>> {
		return of();
	}

	public getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<null>>> {
		return of();
	}

	public signPayment(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null>>> {
		return of();
	}

	public delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>> {
		return of();
	}

	public count(filter?: PendingPaymentsPageFilter): Observable<ServiceResponse<number>> {
		return undefined;
	}

	public list(index: number, count: number, filter?: PendingPaymentsPageFilter): Observable<ServiceResponse<PendingPayment[]>> {
		return undefined;
	}

	public signDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {
		return of();
	}

	public confirmDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {
		return of();
	}

	public confirmPayment(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null>>> {
		return of();
	}
}
