import { SecretQuestionsList } from '@mdib/customers';

export const SecretQuestionsListMock = <SecretQuestionsList>{
	questions: [
		{
			id: '01',
			question: 'secretQuestions.questions.xcl.01',
			answer: '',
		},
		{
			id: '02',
			question: 'secretQuestions.questions.xcl.02',
			answer: '',
		},
		{
			id: '03',
			question: 'secretQuestions.questions.xcl.03',
			answer: '',
		},
	],
};
