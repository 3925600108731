import { Observable, Observer } from 'rxjs';

import { LiveTourStep } from './live-tour-step';
import { LiveTourService } from '../live-tour.service';

export class WaitDomElementStep extends LiveTourStep {

	constructor(private elementSelector: string) {
		super();
	}

	public execute(service: LiveTourService, liveTourEvents: Observable<Event>): Observable<Event> {
		// Create the Observable
		return Observable.create((obv: Observer<Event>) => {
			// Element already exist
			const checkForElement = () => {
				const element = document.querySelector(this.elementSelector);
				if (element) {
					obv.next(new Event('nextStep'));
					obv.next(new Event('stepComplete'));
					return true;
				}
				return false;
			};

			if (!checkForElement()) {
				// Listen to changes on the DOM
				const observer = new MutationObserver((changes) => {
					if (checkForElement()) {
						observer.disconnect();
					}
				});
				const config = { childList: true, attributes: true, characterData: true, subtree: true, attributeOldValue: true, characterDataOldValue: true };
				observer.observe(document, config);
			}
		});
	}

}
