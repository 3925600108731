import { Injectable } from '@angular/core';
import { AdapterProxy } from '@mdib/core/adapters';
import { PaymentFilesServiceStub } from './payment-files.service.stub';
import { PaymentFilesService } from './payment-files.service';

@Injectable()
export class PaymentFilesServiceProxy extends AdapterProxy {
	protected ServiceToken() { return PaymentFilesService; }
	protected ServiceDefinition() { return PaymentFilesServiceStub; }
}
