import { Component } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
	selector: 'mdib-live-tour-step',
	templateUrl: './live-tour-step.component.html',
	styleUrls: ['./live-tour-step.component.scss']
})
export class LiveTourStepComponent {

	public title: string;
	public content: string;

	private eventEmitter: Subject<Event> = new Subject<Event>();

	public show(options: any): Observable<Event> {
		this.title = options['title'] || '';
		this.content = options['content'] || '';
		return this.eventEmitter;
	}

	public next() {
		this.eventEmitter.next(new Event('nextStep'));
	}

	public end() {
		this.eventEmitter.next(new Event('endTour'));
	}

}
