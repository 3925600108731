import { Injectable } from '@angular/core';

import { OtpSignatureService } from '@mdib/signature-modes';

import { SignatureServiceXcl } from './signature.service-xcl';

@Injectable({
	providedIn: 'root'
})
export class OtpSignatureXclService extends SignatureServiceXcl implements OtpSignatureService {

	protected SIGNATURE_TYPE = 'OTP';

}
