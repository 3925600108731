import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfigurationService } from '@mdib/utils';
import { Subscription, interval } from 'rxjs';

@Injectable()
export abstract class TimeoutService {

	protected abstract configProperties: string;
	protected abstract dialogComponent: any;
	protected timer: Subscription;

	constructor(
		protected matDialog: MatDialog,
		protected configurationService: ConfigurationService,
		protected ngZone: NgZone
	) { }

	public init(): void {
		const config = this.configurationService.instant(this.configProperties);
		this.setTimer(config);
		addEventListener('click', () => this.setTimer(config));
	}

	protected abstract isShowWarning(): boolean;

	private setTimer(config: any): void {
		// Clear existing timer if any
		if (this.timer) {
			this.timer.unsubscribe();
		}

		// Timeout may be disabled
		if (!config || config.timeout <= 0) {
			return;
		}

		this.timer = this.ngZone.runOutsideAngular(
			() => interval(config.timeout).subscribe(
				() => {
					this.ngZone.run(() => {
						if (this.isShowWarning()) {
							const dialog = this.matDialog.open(this.dialogComponent, { disableClose: true, data: config });
							dialog.afterClosed().subscribe(() => this.setTimer(config));
						}
					});
				}
			)
		);
	}
}
