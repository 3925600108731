import { Component } from '@angular/core';

/**
 * [WIP] displays a list of cards
 */
@Component({
	selector: 'mdib-cards-list',
	templateUrl: './cards-list.component.html',
	styleUrls: ['./cards-list.component.scss']
})
export class CardsListComponent {
	readonly NAMESPACE = 'cardsModule.cardsListComponent.';
}
