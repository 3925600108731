import { Observable } from 'rxjs';
import { Signable, Signature } from '@mdib/signature';
import { ServiceResponse } from '@mdib/utils';
import { of } from 'rxjs/internal/observable/of';

import { OtpSignatureService } from './otp-signature.service';

export class OtpSignatureServiceStub extends OtpSignatureService {

	public prepareSignatureFor(object: Signable): Observable<ServiceResponse<Signature>> {
		return of();
	}
	public createSignatureFor(object: Signable, token: string): Observable<ServiceResponse<Signature>> {
		return of();
	}
}
