import { Injectable, Inject, InjectionToken } from '@angular/core';

import { NavigationItem } from '../types/navigation-item';
import { NavigationFilter } from '../types/navigation-filter';

export const NAVIGATION_MENUS = new InjectionToken<NavigationItem[]>('NAVIGATION_MENUS');
export const NAVIGATION_FILTERS = new InjectionToken<NavigationFilter[]>('NAVIGATION_FILTERS');

@Injectable()
export class NavigationService {

	/**
	 * @param navigationItems Full list of menus available to the application
	 * @param navigationFilters Filters to use for filtering the menus
	 */
	constructor(
		@Inject(NAVIGATION_MENUS) private navigationItems: Array<NavigationItem>,
		@Inject(NAVIGATION_FILTERS) private navigationFilters: Array<NavigationFilter>,
	) { }

	/**
	 * Returns the list of filtered menus.
	 *
	 * @returns The list of menus
	 */
	public getMenus(): Array<NavigationItem> {
		return this.hideMenus(this.navigationItems);
	}

	private hideMenus(items: NavigationItem[]) {
		items.forEach(item => {
			// Reset
			item.hidden = false;

			// Apply filtering
			this.navigationFilters.forEach(filter => {
				item.hidden = item.hidden || !filter.filterItem(item);
			});

			// Handle childrens
			if (item.children) {
				this.hideMenus(item.children);
			}
		});
		return items;
	}

}
