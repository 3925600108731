import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';

// Mdib
import { AppMdibImports } from './app-mdib.imports';
import { ConfigurationStore, checkEnv } from '@mdib/utils';
import { BankConfig, FileConfigurationStore, CONFIGURATION_FILE } from '@mdib/config';
import { SELF_TRANSLATIONS } from '@mdib/core/customization';

// Adapters
import { AppAdaptersXclImports } from './services-configuration/app-adapters-xcl.imports';
import { AppAdaptersDxpImports } from './services-configuration/app-adapters-dxp.imports';
import { AppAdaptersMemoryImports } from './services-configuration/app-adapters-memory.imports';

// Pages
import { AppPagesImports } from './app-pages.imports';

// Customs
import { AppCustomizationModule } from './app-customization.import';
import { CustomsImports } from '@customs/customs.imports';

// Assembly
import { AppLoaderFactory } from './app-loader.factory';
import { AppRootComponent } from './app-root/app-root.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { AppWildcardRoutingModule } from './routing/app-wildcard-routing.module';

import * as fallback from 'assets/fallback.json';

@NgModule({
	declarations: [
		AppRootComponent,
	],
	imports: [
		// MDIB
		AppMdibImports,
		// Adapters
		AppAdaptersMemoryImports,
		AppAdaptersXclImports,
		AppAdaptersDxpImports,
		// pages
		AppPagesImports,
		// Customs
		AppCustomizationModule,
		CustomsImports,
		// Routing
		AppRoutingModule,
		AppWildcardRoutingModule,
	],
	providers: [
		// Configuration
		{ provide: SELF_TRANSLATIONS, multi: true, useValue: { translations: fallback.default } },
		{ provide: LOCALE_ID, useValue: BankConfig.baseLocale },
		{ provide: ConfigurationStore, useClass: FileConfigurationStore },
		{ provide: CONFIGURATION_FILE, multi: true, useValue: 'assets/configuration/properties/defaults.properties' }, // !IMPORTANT: The defaults.properties must always be the FIRST properties file loaded !
		{ provide: CONFIGURATION_FILE, multi: true, useValue: 'assets/configuration/properties/xcl.properties' },
		{ provide: CONFIGURATION_FILE, multi: true, useValue: 'assets/configuration/properties/bank.properties' },
		{ provide: CONFIGURATION_FILE, multi: true, useValue: 'assets/configuration/properties/dxp.properties' },
		checkEnv('mobile') ? { provide: CONFIGURATION_FILE, multi: true, useValue: 'assets/configuration/properties/mobile.properties' } : [],
		{ provide: CONFIGURATION_FILE, multi: true, useValue: 'assets/configuration/properties/custom.properties' },  // !IMPORTANT: The custom.properties must always be the LAST properties file loaded !!
		{ provide: APP_INITIALIZER, multi: true, useFactory: AppLoaderFactory, deps: [ConfigurationStore] },
	],
	bootstrap: [AppRootComponent],
})
export class AppModule {
}
