import { ContactAddressBuilder, ContactAddress } from '@mdib/customers';
import { Beneficiary, BeneficiaryType } from '@mdib/beneficiaries';
import { CommunicationTypes, Address } from '@mdib/commons';
import { OperationStatus, PaymentOperation, PaymentOperationBuilder, PaymentTypes } from '@mdib/payments';
import { Status } from '@mdib/utils';

export class DraftPaymentsMock {

	private _draftPayments: PaymentOperation<null>[] =
		[
			new PaymentOperationBuilder<null>()
				.reference('B7J25CWUIK83QDEH')
				.status(Status.InShoppingBasket)
				.operationStatus(OperationStatus.inShoppingBasket)
				.ordererAccountNumber('BE74945239392707')
				.ordererAccountAlias('Online payments')
				.counterparty(new Beneficiary({type: BeneficiaryType.INTERNAL}))
				.counterpartyName('For the kids')
				.counterpartyAccountNumber('BE95750642304658')
				.amount(0.99)
				.currency('EUR')
				.maturityDate(new Date(2017, 10, 25))
				.operationDate(new Date(2017, 10, 22))
				.type(PaymentTypes.sepaCreditTransfer)
				.communication('My communication')
				.notificationPhoneNumber('0477777777')
				.paymentOperation,
			new PaymentOperationBuilder<null>()
				.reference('B5625CWUIK8324L8')
				.status(Status.InShoppingBasket)
				.operationStatus(OperationStatus.inShoppingBasket)
				.ordererAccountNumber('BE56305177635588')
				.ordererAccountAlias('Daily')
				.counterparty(new Beneficiary({type: BeneficiaryType.INTERNAL}))
				.counterpartyName('For the kids')
				.counterpartyAccountNumber('BE95750642304658')
				.amount(10)
				.currency('EUR')
				.maturityDate(new Date(2018, 10, 27))
				.operationDate(new Date(2018, 10, 25))
				.type(PaymentTypes.instantPayment)
				.communication('My communication')
				.notificationPhoneNumber('0477777777')
				.paymentOperation,
			new PaymentOperationBuilder<null>()
				.reference('B7J27CWWFJ9F7RI8')
				.status(Status.InShoppingBasket)
				.operationStatus(OperationStatus.inShoppingBasket)
				.ordererAccountNumber('BE56305177635588')
				.ordererAccountAlias('Daily')
				.counterparty(new Beneficiary({type: BeneficiaryType.SEPA}))
				.counterpartyName('Luc Montaret')
				.counterpartyAccountNumber('BE65981696589311')
				.amount(12.34)
				.currency('EUR')
				.maturityDate(new Date(2017, 12, 4))
				.counterpartyAddress(new Address({
					addressLine2: '1150, Brussels',
					addressLine1: '226, Avenue de Tervuren',
					country: 'BE',
				}))
				.type(PaymentTypes.internationalPayment)
				.notificationPhoneNumber('0488123456')
				.communication('My communication')
				.paymentOperation,
			new PaymentOperationBuilder<null>()
				.reference('B7K14CWKMD6RK1F9')
				.status(Status.InShoppingBasket)
				.operationStatus(OperationStatus.inShoppingBasket)
				.ordererAccountNumber('BE56305177635588')
				.counterpartyName('John Fitzgerald Kennedy')
				.ordererAccountAlias('Daily')
				.counterparty(new Beneficiary({type: BeneficiaryType.SEPA}))
				.counterpartyAccountNumber('NL01SOPR0116688253')
				.amount(0.05)
				.currency('EUR')
				.maturityDate(new Date(2017, 11, 27))
				.counterpartyAddress(new Address({
					addressLine2: '1150, Brussels',
					addressLine1: '226, Avenue de Tervuren',
					country: 'BE',
				}))
				.communicationType(CommunicationTypes.free)
				.communication('Hello!')
				.type(PaymentTypes.europeanStandingOrder)
				.notificationPhoneNumber('0498561237')
				.paymentOperation,
			new PaymentOperationBuilder<null>()
				.reference('B7K27CWSIO4FPH3R')
				.status(Status.NotAuthorizedToSign)
				.ordererAccountNumber('BE23999000477491')
				.ordererAccountAlias('For next holidays')
				.counterpartyName('John Fitzgerald Kennedy')
				.counterpartyAccountNumber('BR75999000036951')
				.amount(0.05)
				.currency('EUR')
				.maturityDate(new Date(20171127))
				.counterpartyAddress(new Address({
					addressLine2: '1150, Brussels',
					addressLine1: 'Avenue de Tervuren, 226',
					country: 'BE',
				}))
				.communicationType(CommunicationTypes.free)
				.communication('Hi!')
				.type(PaymentTypes.europeanStandingOrder)
				.notificationPhoneNumber('0498561237')
				.paymentOperation
		];

	get draftPayments(): PaymentOperation<null>[] {
		return this._draftPayments;
	}
}
