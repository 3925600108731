import { LanguageCodes } from '@mdib/config';

import { ContactAddress } from './contact-address';
import { Person } from './person';
import { NaturalPersonGender, NaturalPersonMaritalStatus } from './person.enum';

export class NaturalPerson extends Person {

	constructor(
		// Mandatory
		readonly id: any,
		// Optional
		readonly name?: string,
		readonly firstName?: string,
		readonly lastName?: string,
		readonly birthDate?: Date,
		readonly birthCity?: string,
		readonly birthCountry?: string,
		readonly legalAddress?: ContactAddress,
		readonly language?: LanguageCodes,
		readonly maritalStatus?: NaturalPersonMaritalStatus,
		readonly nationality?: string,  // TODO Enum ?
		readonly gender?: NaturalPersonGender,
		readonly defaultRepresentedClientSwitch?: boolean,
	) {
		super(id);
	}
}
