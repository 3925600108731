import { Injectable } from '@angular/core';
import { AccountValuationXcl } from '../model/account-valuation-xcl';
// import { XclDateFormatter } from '@mdib-xcl/utils';
import { AccountValuation } from '@mdib/account-valuations';
import { isNullOrUndefined } from 'util';

@Injectable()
export class AccountValuationsMapperXclService {

	constructor() {}

	/**
	 * Converts the technical model list into functional model list
	 * AccountValuation[] to XclAccountValuationModel[]
	 * @param AccountValuationXcl[] : source format list.
	 * @returns AccountValuation[] : functional model format list.
	 */
	public accountValuationXclListToAccountValuationList(accountValuationXclList: AccountValuationXcl[]): AccountValuation[] {
		if (isNullOrUndefined(accountValuationXclList)) {
			console.log('ERROR: mapping issue from AccountValuationXcl[] to AccountValuation[]');
			return null;
		}

		const accountValuationList: AccountValuation[] = new Array<AccountValuation>();
		for (const accountValuation of accountValuationXclList) {
			if (accountValuation.valuationDate.toString().substr(4 , 4) === '0101' || accountValuation.valuationDate.toString().substr(4 , 4) === '0401'
				|| accountValuation.valuationDate.toString().substr(4 , 4) === '0701' || accountValuation.valuationDate.toString().substr(4 , 4) === '1001') {
					accountValuationList.push(this.accountValuationXclToAccountValuation(accountValuation));
			}
		}
		return accountValuationList;
	}

	/**
	 * Parse technical model in functional model
	 * @param AccountValuationXcl : source format.
	 * @returns AccountValuation : parsed functional model format.
	 */
	private accountValuationXclToAccountValuation(accountValuationXcl: AccountValuationXcl): AccountValuation {
		if (isNullOrUndefined(accountValuationXcl)) {
			console.log('ERROR: AccountValuationXcl issue');
			return null;
		}

		const accountValuationModel = <AccountValuation>{
			accountNumber: accountValuationXcl.accountNumber,
			valuationDate: accountValuationXcl.valuationDate,
			totalInterest: accountValuationXcl.totalInterestPaidToClient,
			operationReference: accountValuationXcl.operationReference,
		};

		return accountValuationModel;
	}
}
