import { Component, Input} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PaymentOperation } from '@mdib/payments/types/models/payment-operation';
import { PaymentForm } from '@mdib/payments/types/forms/payment-form';

import { ConfigurationService, UtilsHelper } from '@mdib/utils';
import { PaymentTypes } from '@mdib/payments/types/models/payments.enum';

import { CommunicationTypes } from '../../model/commons.enum';
import { CommunicationFormBuilder } from '../../types/forms/communication-form.builder';
import { Communication } from '../../model/communication';

@Component({
	selector: 'communication',
	templateUrl: './communication.component.html',
	styleUrls: ['./communication.component.scss'],
})
export class CommunicationComponent {

	public readonly NAMESPACE = 'pages.commons.communicationComponent.';

	public readonly CommunicationType = CommunicationTypes;
	public readonly CommunicationTypes = Object.keys(CommunicationTypes);
	@Input() isSwitchOff: boolean;

	public formGroup: FormGroup;
	PaymentTypes = PaymentTypes; // this trick is necessary to let the template have access to the enum (see beneficiary-section.component.ts)

	private externalFormGroup: FormGroup;
	private externalSubscriptions: Array<Subscription>;

	constructor(
		private configurationService: ConfigurationService,
		communicationFormBuilder: CommunicationFormBuilder
	) {
		this.formGroup = communicationFormBuilder.build();
		this.formGroup.valueChanges.subscribe(this.updateModel.bind(this));
	}

	public updateModel(viewCommunication: Communication): void {
		const communication = new Communication(viewCommunication);
		communication.value = communication.value || '';
		if (communication.type === CommunicationTypes.european) {
			communication.value = 'RF' + communication.value;
		}
		this.externalFormGroup.patchValue(communication);
	}

	public updateView(modelCommunication: Communication): void {
		const communication = new Communication(modelCommunication);
		communication.value = communication.value || '';
		if (communication.type === CommunicationTypes.european && communication.value.startsWith('RF')) {
			communication.value = communication.value.substr(2);
		}
		this.formGroup.patchValue(communication, { emitEvent: false });
	}

	public updateStatus(status: any): void {
		if (status === 'DISABLED' && this.formGroup.enable) {
			this.formGroup.disable({ emitEvent: false });
		} else if (status !== 'DISABLED' && this.formGroup.disabled) {
			this.formGroup.enable({ emitEvent: false });
		}
	}

	@Input()
	public set group(formGroup: FormGroup) {
		// Update the state of the component
		this.externalFormGroup = formGroup;
		this.updateView(formGroup.value);
		this.updateStatus(formGroup.status);

		// Change subscription
		if (this.externalSubscriptions) {
			this.externalSubscriptions.forEach(s => s.unsubscribe());
		}
		this.externalSubscriptions = [
			this.externalFormGroup.valueChanges.subscribe(this.updateView.bind(this)),
			this.externalFormGroup.statusChanges.subscribe(this.updateStatus.bind(this)),
		];
	}

	public isEmpty(value: any): boolean {
		return !UtilsHelper.objectNotEmpty(value);
	}

	public get communicationMaxLength(): number {
		const type: string = this.formGroup.value.type || 'free';
		return this.configurationService.instant('commons.fields.communication_' + type + '.maxLength');
	}

}
