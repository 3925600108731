import { OnInit, ViewChild, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { Unsubscribable } from 'rxjs';

import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { Feedback, FeedbackTypes } from '@mdib/utils';

import { BackendRetrieveService, BackendUpdateService } from '../../types/backends/backend-service';
import { BackendOperation } from '../../types/operations/backend-operation';
import { BackendOperationStep } from '../../types/operations/backend-operation-step';
import { BackendOperationState } from '../../types/operations/backend-operation-state.enum';

export abstract class UpdatePageComponent<T> implements OnInit {

	public model: T;
	public operation: BackendOperation<T, T>;
	public formGroup: FormGroup;

	public isLoadingData = false;
	public itemIdentifier = null;

	@ViewChild(MatStepper) protected stepper: MatStepper;
	protected subscription: Unsubscribable;

	public constructor(
		protected backendService: BackendRetrieveService<T> & BackendUpdateService<T>,
		protected notificationsMapper: ServiceResponseNotificationMessagesMapperService,
	) { }

	public ngOnInit(): void {
		this.initializeModel();
		this.createOperation();
	}

	public handleOperationEvent(event: Event): void {
		switch (event.type) {
			case 'cancel':
				this.createOperation();
				this.stepper.selectedIndex = 0;
				break;
			case 'reset':
				this.initializeModel();
				this.createOperation();
				this.stepper.reset();
				break;
		}
	}

	protected initializeModel(): void {
		this.isLoadingData = true;

		const retrieveOperation = this.backendService.retrieve();
		retrieveOperation.execute(this.itemIdentifier).subscribe(
			(step) => {
				this.notificationsMapper.sendFeedbacks(step.feedbacks);
				this.model = step.result;
				this.isLoadingData = false;
			},
			(error) => this.handleOperationError(error)
		);
	}

	protected createOperation(): void {
		// Clean any previous one
		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		// Create and listen to a new operation
		this.operation = this.backendService.update();
		this.subscription = this.operation.subscribe(this.handleOperationStep.bind(this), this.handleOperationError.bind(this));
	}

	protected handleOperationStep(step: BackendOperationStep<T>): void {
		this.notificationsMapper.clearAll();
		this.notificationsMapper.sendFeedbacks(step.feedbacks);
		switch (this.operation.state) {
			case BackendOperationState.CANCELLED:
			case BackendOperationState.SUCCEEDED:
			case BackendOperationState.FAILED:
				this.stepper.selectedIndex = 2;
				break;
			case BackendOperationState.WAITING_ACTION:
			case BackendOperationState.WAITING_DATA:
			case BackendOperationState.BUSY:
				if (step.availableTriggers.indexOf('validate') === -1) {
					this.stepper.selectedIndex = 1;
				} else {
					this.stepper.selectedIndex = 0;
				}
		}
	}

	protected handleOperationError(error: any): void {
		this.notificationsMapper.clearAll();
		this.createOperation();
		if (error.feedbacks) {
			this.notificationsMapper.sendFeedbacks(error.feedbacks);
		} else {
			this.notificationsMapper.sendFeedback(new Feedback('unexpectedError', FeedbackTypes.BACKEND_ERROR, 'An unexpected error occured'));
		}
	}
}
