import { Component, Input, OnChanges } from '@angular/core';
import { PaymentForm, PaymentOperation, PaymentTypes } from '@mdib/payments';
import { DateHelper, Link, MessageMap, Notification, Status } from '@mdib/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'mdib-confirmation-step',
	templateUrl: './confirmation-step.component.html',
	styleUrls: ['./confirmation-step.component.scss'],
})
export class ConfirmationStepComponent implements OnChanges {

	@Input() paymentForm: PaymentForm;
	notificationShoppingBasket: Notification;
	notificationDate: Notification;
	Status = Status;

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.confirmationStepComponent.';

	constructor(private translate: TranslateService) {
	}

	/**
	 * Creates the notification message with validated payment data.
	 */
	ngOnChanges() {
		this.notificationShoppingBasket =
			<Notification>{
				type: 'info',
				iconName: 'info_outline',
				messageMap: <MessageMap>{
					messageKey: this.NAMESPACE + 'notification.messageShoppingBasket',
					messageParams: {
						paymentType: this.translate.instant('operation:payment:type:' + PaymentTypes[this.paymentForm.type.value]),
						date: this.date,
					},
				},
				link: <Link>{
					title: this.NAMESPACE + 'operation:shoppingBasket',
					target: '/shopping-basket',
				},
			};
		this.notificationDate =
			<Notification>{
				type: 'info',
				iconName: 'info_outline',
				messageMap: <MessageMap>{
					messageKey: this.NAMESPACE + 'notification.messageMaturityDate',
					messageParams: {
						paymentType: this.translate.instant('operation:payment:type:' + PaymentTypes[this.paymentForm.type.value]),
						date: this.date,
					},
				},
				link: <Link>{
					title: '',
					target: '',
				},
			};
	}

	get payment(): PaymentOperation<null> {
		return this.paymentForm && this.paymentForm.model || new PaymentOperation<null>();
	}

	get date(): string {
		return DateHelper.format(this.payment.paymentDate);
	}
}
