import { NgModule, Inject, ModuleWithProviders } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { MailboxMessagesService } from '@mdib/mailbox';

import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';

import { MailboxMessagesXclService } from './service/mailbox-messages-xcl.service';

@NgModule()
export class MailboxXclModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(MailboxMessagesService, adapterId, MailboxMessagesXclService);
	}

	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: MailboxXclModule
		};
	}
}
