import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { SavingsAccountService } from './savings-account.service';
import { SavingsAccountServiceStub } from './savings-account.service.stub';

@Injectable()
export class SavingsAccountServiceProxy extends AdapterProxy {
	protected ServiceToken() { return SavingsAccountService; }
	protected ServiceDefinition() { return SavingsAccountServiceStub; }
}
