import { Observable, Observer } from 'rxjs';

import { LiveTourStep } from './live-tour-step';
import { LiveTourService } from '../live-tour.service';

export class DirectNextStep extends LiveTourStep {

	constructor(private realStep: LiveTourStep) {
		super();
	}

	public execute(service: LiveTourService, liveTourEvents: Observable<Event>): Observable<Event> {
		return Observable.create((obv: Observer<Event>) => {
			obv.next(new Event('nextStep'));
			this.realStep.execute(service, liveTourEvents).subscribe(event => obv.next(event));
		});
	}

}
