import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Authentication } from '@mdib/sessions';
import { DigipassAuthenticationService } from '@mdib/sessions-modes';
import { ServiceResponse } from '@mdib/utils';

import { SessionHelper } from '../helpers/session-helper';
import { AuthenticationMemoryService } from './authentication-memory.service';

@Injectable({
	providedIn: 'root',
})
export class DigipassAuthenticationMemoryService extends AuthenticationMemoryService implements DigipassAuthenticationService {

	public authenticate(username: string, digipassResponse: string): Observable<ServiceResponse<Authentication>> {
		this.setAccessRights(username);
		return of(new ServiceResponse(SessionHelper.mockEnticate(username)));
	}

	public loadChallenge(): Observable<ServiceResponse<string>> {
		return of(new ServiceResponse('11111'));
	}
}
