import { Injectable } from '@angular/core';
import { AdapterProxy } from '@mdib/core/adapters';

import { PersonsService } from './persons.service';
import { PersonsServiceStub } from './persons.service.stub';

@Injectable()
export class PersonsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return PersonsService; }
	protected ServiceDefinition() { return PersonsServiceStub; }
}
