import { Observable, of, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';

import { BackendOperation, BackendOperationStep, BackendOperationType, BackendOperationState, SearchCriteria, SearchResult } from '@mdib/core/adapters';

import { CriteriaOperatorsMemory, SearchCriteriaMemory } from './criteria-operators-memory';

export class SearchOperationMemory<OUT> extends BackendOperation<SearchCriteria<SearchCriteriaMemory>, SearchResult<OUT>> {

	private defaultTrigger = 'search';

	constructor(
		protected data: OUT[] = null,
	) {
		super(BackendOperationType.SEARCH);
		this.curentStep = new BackendOperationStep(null, ['search']);
	}

	public execute(input: SearchCriteria<SearchCriteriaMemory>, trigger?: string): Observable<BackendOperationStep<SearchResult<OUT>>> {
		switch (trigger || this.defaultTrigger) {
			case 'cancel':
				return this.cancel(input, trigger || this.defaultTrigger);
			case 'search':
				return this.done(input, trigger || this.defaultTrigger);
			default:
				return this.error(new Error('The trigger ' + trigger + ' does not exist'));
		}
	}

	protected error(error: Error): Observable<BackendOperationStep<SearchResult<OUT>>> {
		this.state = BackendOperationState.FAILED;
		this.stepSubject.error(error);
		return throwError(error);
	}

	protected cancel(input: SearchCriteria<SearchCriteriaMemory>, trigger?: string): Observable<BackendOperationStep<SearchResult<OUT>>> {
		this.state = BackendOperationState.CANCELLED;
		this.defaultTrigger = null;
		this.curentStep = new BackendOperationStep(trigger, []);
		this.stepSubject.next(this.curentStep);
		return of(this.curentStep);
	}

	protected done(input: SearchCriteria<SearchCriteriaMemory>, trigger?: string): Observable<BackendOperationStep<SearchResult<OUT>>> {
		this.state = BackendOperationState.SUCCEEDED;
		this.defaultTrigger = '';
		this.curentStep = new BackendOperationStep(trigger, [], this.searchData(input));
		this.stepSubject.next(this.curentStep);
		return of(this.curentStep).pipe(delay(1500));
	}

	protected searchData(input: SearchCriteria<SearchCriteriaMemory>): SearchResult<OUT> {
		// Compila criteria
		const criteria = input.compile(CriteriaOperatorsMemory);

		// Pagination
		const index = criteria.index || 0;
		const endIndex = criteria.count ? (index + criteria.count) : undefined;

		// Results
		let results = this.data.filter(e => {
			return criteria.filter(e);
		});
		const total = results.length;
		results = results.slice(index, endIndex);

		return new SearchResult(results, total, index, results.length);
	}
}
