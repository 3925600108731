import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OnInit, Component, forwardRef, Input } from '@angular/core';


@Component({
	selector: 'inline-edit',
	styleUrls: ['./inline-edit.component.scss'],
	templateUrl: './inline-edit.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InlineEditComponent),
			multi: true,
		},
	],
})
export class InlineEditComponent implements ControlValueAccessor {

	public onChange: any = Function.prototype;
	public onTouched: any = Function.prototype;

	editing = false;
	isEditable = false;

	private _value = '';
	private preValue = '';

	constructor() {
		this.onChange = () => null;
		this.onTouched = () => null;
	}

	@Input()
	public set editable(isEditable: boolean) {
		this.isEditable = isEditable;
		if (!isEditable) {this.editing = false; }
	}

	commit() {
		this.isEditable = true;
		this.editing = false;
	}

	get value(): any {
		return this._value;
	}

	set value(v: any) {
		if (v !== this._value) {
			this._value = v;
			this.onChange(v);
		}
	}

	cancel(value: any) {
		this._value = this.preValue;
		this.editing = false;
	}

	edit() {
		this.preValue = this._value;
		this.editing = true;
	}

	writeValue(value: any) {
		this._value = value;
	}

	registerOnChange(fn: (selectedElement: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
}
