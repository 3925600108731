import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ErrorTypes } from '@mdib/errors';

@Component({
	selector: 'mdib-generic-error',
	templateUrl: './generic-error.component.html',
	styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent {
	@Input() errorCode = 'XXX';
	ErrorTypes = ErrorTypes;

	readonly NAMESPACE = 'errorsPagesModule.errorsPagesComponent.';

	constructor(private location: Location) {}

	goBack() {
		this.location.back();
	}
}
