import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColInfo, utils, WorkBook, WorkSheet, write } from 'xlsx';

import { IbanFormatterPipe } from '../pipe/iban-formatter.pipe';
import { MdibCurrencyPipe } from '../pipe/mdib-currency.pipe';
import { Action } from './typings/action.interface';
import { ColumnConfig } from './typings/column-config.interface';

@Injectable({
	providedIn: 'root',
})
export class XlsxService<T = any> {
	// Predefined method
	public readonly APPLY = 'applyMethod';
	public readonly FORMAT_ACCOUNT = 'formatAccount';
	public readonly FORMAT_AMOUNT = 'formatAmount';
	public readonly GET = 'getProperty';
	public readonly SEPARATOR = 'appendSeparator';
	public readonly TRANSLATE = 'translate';

	private readonly XLSX_EXTENSION = '.xlsx';
	private readonly MIN_WIDTH = 120;

	constructor(
		private translateService: TranslateService,
	) {
	}

	public createExcelFile(rawDataList: T[], columnsConfig: ColumnConfig[], fileName: string): File {

		const workBook: WorkBook = utils.book_new();
		const colInfo: ColInfo = {wpx: this.MIN_WIDTH};

		const data = [];
		const headers = [];
		const workSheetColumns = [];

		columnsConfig.forEach(
			(columnConfig: ColumnConfig) => {
				this.translateService.get(columnConfig.key).subscribe(
					(columnWording: string) => {
						headers.push(columnWording.toUpperCase());
						workSheetColumns.push(colInfo);
					},
				);
			},
		);
		data.push(headers.slice());

		rawDataList.forEach(
			(rawDataRow: T) => {
				const row = [];
				columnsConfig.forEach(
					(columnConfig: ColumnConfig) => {
						let formattedData: any;
						columnConfig.content.forEach(
							(content: {key: string, actions?: Action[]}) => {
								const formattedDataPart = this.applyActions(rawDataRow[content.key], content.actions);
								!formattedData ? formattedData = formattedDataPart : formattedData += formattedDataPart;
							},
						);
						row.push(formattedData);
					},
				);
				data.push(row);
			},
		);

		const workSheetName = fileName.split('_')[0];
		workBook.SheetNames.push(workSheetName);
		const workSheet: WorkSheet = utils.aoa_to_sheet(data);
		workSheet['!cols'] = workSheetColumns;
		workBook.Sheets[workSheetName] = workSheet;
		return this.createFile(workBook, fileName);
	}

	private createFile(workBook: WorkBook, fileName: string): File {
		const workBookBuffer = write(workBook, {bookType: 'xlsx', type: 'array'});
		return new File([new Blob([workBookBuffer])], fileName + this.XLSX_EXTENSION);
	}

	private applyActions(rawDataPart: any, actions: Action[]): string {
		if (!actions || actions.length === 0) {
			return rawDataPart;
		}
		actions.forEach(
			(action: Action) => {
				if (typeof action.method === 'function') {
					rawDataPart = action.method(action.attribute);
				} else {
					rawDataPart = this[action.method](rawDataPart, action.attribute);
				}
			},
		);
		return rawDataPart;
	}

	private formatAccount(account: string, arg?: any): string {
		return new IbanFormatterPipe().transform(account);
	}

	private formatAmount(amount: any, arg?: any): string {
		return new MdibCurrencyPipe().transform(amount);
	}

	private appendSeparator(data: any, separator: string): string {
		return data + separator;
	}

	private applyMethod(data: any, method: string): any {
		if (!data[method] || typeof data[method] !== 'function') {
			throw(new Error('You must provide a valid function'));
		}
		return data[method]();
	}

	private getProperty(data: any, property: string): any {
		if (!data[property]) {
			throw(new Error('You must provide a valid property'));
		}
		return data[property];
	}

	private translate(data: string, path?: string): string {
		if (!path) {
			return this.translateService.instant(data);
		}
		return this.translateService.instant(path + data);
	}
}
