import { Component, Input } from '@angular/core';

import { BackendOperation } from '../../types/operations/backend-operation';
import { BackendOperationState } from '../../types/operations/backend-operation-state.enum';

@Component({
	selector: 'operation-confirmation-step',
	templateUrl: './operation-confirmation-step.component.html',
	styleUrls: ['./operation-confirmation-step.component.scss']
})
export class OperationConfirmationStepComponent {

	@Input() public NAMESPACE = 'core.adaptersModule.operationConfirmationStepComponent.';

	@Input() public operation: BackendOperation<any, any>;

	@Input() public executedMessage: string;

	public BackendOperationState = BackendOperationState;

	readonly notificationShoppingBasket = {
		type: 'info',
		iconName: 'info_outline',
		label: this.NAMESPACE + 'generic:remind',
		messageMap: {
			messageKey: this.NAMESPACE + 'notification:message:shoppingBasket',
		},
		link: {
			title: 'operation:shoppingBasket',
			target: '/shopping-basket',
		},
	};
}
