/**
 * All cash account types.
 */
export enum CashAccountTypes {
	NoticeAccount30Days = 'notice_account_30_days',
	RegulatedSavingsAccounts = 'regulated_savings_account',
	CurrentAccountOrdinary = 'current_account_ordinary',
	CurrentAccountNoCost = 'current_account_no_costs',
	SavingsAccounts = 'savings_account',
}

/**
 * Cash account types to organize display.
 */
export enum CashAccountTypesGroup {
	CurrentAccounts = 'CurrentAccounts',
	SavingsAndInvestmentsAccounts = 'SavingsAndInvestmentsAccounts',
}
/**
 * Display types for line chart
 */
export enum LineChartDisplayType {
	ByMonth = 'lastThreeMonths',
	ByWeek = 'lastThreeWeeks'
}


/**
 * Us resident or not + american citizen
 */
export enum UsResidency {
	NotUsResident = 'N',
	UsResident = 'Y'
}

export enum CashAccountOperationMovement {
	Both = 'both',
	Credit = 'credit',
	Debit = 'debit',
}
