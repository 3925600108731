import { MailboxMessage } from '@mdib/mailbox';
import { MailboxConversationSummary } from './mailbox-conversation-summary';

/**
 * This class contains all the fields of a ConversationSummary as well as the list of messages contained by the conversation,
 * plus optionally some extra fields that would not be provided in a conversation summary.
 */
export class MailboxConversation extends MailboxConversationSummary {
	messages: MailboxMessage[] = [];
}
