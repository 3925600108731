import { Component, Input, OnInit } from '@angular/core';
import { PaymentTypes, PaymentOperation, StandingOrderDetails } from '@mdib/payments';
import { StandingOrder } from '@mdib/standing-orders';
import { DateHelper, Link, MessageMap, Notification } from '@mdib/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'mdib-confirmation-step-update-standing-order',
	templateUrl: './confirmation-step.component.html',
	styleUrls: ['./confirmation-step.component.scss'],
})
export class ConfirmationStepComponent implements OnInit {

	readonly NAMESPACE = 'standingOrdersPagesModule.updateStandingOrderPageComponent.confirmationStepComponent.';

	@Input() standingOrder: PaymentOperation<StandingOrderDetails>;
	public notificationDate: Notification;
	public date: string;

	constructor(private translate: TranslateService,
	) {}

	ngOnInit() {
		this.date = DateHelper.format(this.standingOrder.paymentDate);
		this.notificationDate = <Notification>{
			type: 'info',
			iconName: 'info_outline',
			label: this.NAMESPACE + 'generic:remind',
			messageMap: <MessageMap>{
				messageKey: this.NAMESPACE + 'notification.messageMaturityDate',
				messageParams: {
					date: this.date,
				},
			},
			link: <Link>{
				title: '',
				target: '',
			},
		};
	}
}
