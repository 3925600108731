import { Injectable } from '@angular/core';
import { FileFormat } from '@mdib/utils';
import { PdfStatementsService } from '@mdib/pdf-statements/service/pdf-statements.service';
import { PdfStatementsPageFilter } from '@mdib/pdf-statements/model/pdf-statement-page-filter';

@Injectable()
export abstract class PdfStatementsCommonService extends PdfStatementsService {

	public initializeFilter(): PdfStatementsPageFilter {

		const filter: PdfStatementsPageFilter = {
			cashAccount: null,
			status: null,
			startDate: null,
			endDate: null
		};
		return filter;
	}
}
