import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ConfigurationService } from '@mdib/utils';
import { LocalizationService } from '@mdib/core/customization';

@Component({
	selector: 'mdib-language-selector',
	templateUrl: './language-selector.component.html',
	styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {

	readonly NAMESPACE = 'headerModule.languageSelectorComponent.';

	languageCodes: string[];

	constructor(
		private configurationService: ConfigurationService,
		private translateService: TranslateService,
		private localizationService: LocalizationService,
	) { }

	ngOnInit() {
		this.languageCodes = this.configurationService.instant('localisation.language.list');
	}

	/**
	 * Changes the language for the application
	 * @param language Language in which the application must be translated
	 */
	setLanguage(languageCode: string): void {
		this.translateService.use(languageCode);
		this.localizationService.language = languageCode;
	}
}
