import { Injectable } from '@angular/core';
import { ServiceResponse, MdibFile } from '@mdib/utils';
import { ElectronicalStatement, ElectronicalStatementsPageFilter, ElectronicalStatementsCommonService, ElectronicalStatementsFormat } from '@mdib/electronical-statements';
import { Observable, of } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { MockElectronicalStatements } from '@mdib-memory/electronical-statements/mock/mock-electronical-statements';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ElectronicalStatementsMemoryService extends ElectronicalStatementsCommonService {

	readonly DEFAULT_DELAY = 400;
	readonly mockElectronicalStatements = new MockElectronicalStatements();

	constructor(private http: HttpClient) {
		super();
	}

	public list(offset?: number, limit?: number, filter?: ElectronicalStatementsPageFilter): Observable<ServiceResponse<ElectronicalStatement[]>> {
		const start = offset * limit;
		const end = start + limit;
		return of(new ServiceResponse<ElectronicalStatement[]>(
			filter ? this.getFilteredElectronicalStatementsListCropped(start, end, filter) : this.mockElectronicalStatements.mockElectronicalStatements.slice(start, end)))
			.pipe(delay(this.DEFAULT_DELAY));
	}
	public count(filter?: ElectronicalStatementsPageFilter): Observable<ServiceResponse<number>> {
		return of(new ServiceResponse<number>(
			filter ? this.getFilteredElectronicalStatementsList(filter).length : this.mockElectronicalStatements.mockElectronicalStatements.length))
			.pipe(delay(this.DEFAULT_DELAY));
	}
	public get(id: string): Observable<ServiceResponse<ElectronicalStatement>> {
		return of(new ServiceResponse<ElectronicalStatement>(
			this.mockElectronicalStatements.mockElectronicalStatements.find((statement) => statement.identifier === id))
		).pipe(delay(this.DEFAULT_DELAY));
	}

	public downloadStatement(electronicalStatement: ElectronicalStatement): Observable<ServiceResponse<any>> {
		if (electronicalStatement.format === ElectronicalStatementsFormat.CAMT053) {
			return this.http.get('/assets/documents/sampleXml1.xml', { responseType: 'blob' }).pipe(map((file: any) =>
				new ServiceResponse<any>(file)
			), delay(this.DEFAULT_DELAY));
		} else {
			return this.http.get('/assets/documents/example_MT940.txt', { responseType: 'blob' }).pipe(map((file: any) =>
				new ServiceResponse<any>(file)
			), delay(this.DEFAULT_DELAY));
		}
	}

	public update(electronicalStatement: ElectronicalStatement): Observable<ServiceResponse<ElectronicalStatement>> {
		throw new Error('Method not implemented.');
	}

	private getFilteredElectronicalStatementsList(filter: ElectronicalStatementsPageFilter): ElectronicalStatement[] {
		return this.mockElectronicalStatements.mockElectronicalStatements.filter((electronicalStatement: ElectronicalStatement) => {
			if (filter.accountNumber && !electronicalStatement.accountNumber.startsWith(filter.accountNumber)) {
				return false;
			}
			if (filter.format === ElectronicalStatementsFormat.MT940 || filter.format === ElectronicalStatementsFormat.CAMT053) {
				if (filter.format && electronicalStatement.format !== filter.format) {
					return false;
				}
			}

			if (filter.status !== electronicalStatement.status) {
				return false;
			}
			return true;
		});
	}

	private getFilteredElectronicalStatementsListCropped(start: number, end: number, filter: ElectronicalStatementsPageFilter): ElectronicalStatement[] {
		return this.getFilteredElectronicalStatementsList(filter).slice(start, end);
	}
}
