import { InjectionToken } from '@angular/core';

// Tokens
export const ADAPTER_MEMORY_IDENTIFIER = new InjectionToken<string>('ADAPTER_MEMORY_IDENTIFIER');

// Types
export { CreateOperationMemory } from './types/create-operation-memory';
export { RetrieveOperationMemory } from './types/retrieve-operation-memory';
export { UpdateOperationMemory } from './types/update-operation-memory';
export { DeleteOperationMemory } from './types/delete-operation-memory';
export { SearchOperationMemory } from './types/search-operation-memory';
export { CriteriaOperatorsMemory, SearchCriteriaMemory } from './types/criteria-operators-memory';
