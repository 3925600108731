import { Injectable } from '@angular/core';
import { Authentication } from '@mdib/sessions';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';

@Injectable()
export abstract class DigipassAuthenticationService {

	public abstract authenticate(username: string, digipassResponse: string): Observable<ServiceResponse<Authentication>>;

	public abstract loadChallenge(): Observable<ServiceResponse<string>>;
}
