import { PaymentFileFailure } from './payment-file-failure';
import { PaymentFileStatus, HashAlgorithms, PaymentFileTypes, PaymentFileError } from './payment-file.enum';
import { MdibFile, Status } from '@mdib/utils';

/**
 * Holds data about a payment file
 */
export class PaymentFile {
	identifier: string;
	file: MdibFile;
	hashValue: string;
	hashDigitsCheck: string;
	hashAlgorithm: HashAlgorithms;
	status?: Status;
	fileStatus: PaymentFileStatus;
	type: PaymentFileTypes;
	uploadDate: Date;
	sequenceNumber: string;
	batchesCount: number;
	paymentsCount: number;
	totalAmount: number;
	currency?: string;
	errorsInformation?: PaymentFileError;
	failures?: PaymentFileFailure[];
}
