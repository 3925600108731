import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

import { VirtualNumpadService } from '../services/virtual-numpad.service';

@Directive({
	selector: '[virtualNumpad]'
})
export class VirtualNumpadDirective {

	private isOnMobileDevice: boolean = !!window['cordova'];

	constructor(
		private virtualNumpadService: VirtualNumpadService,
		private elmentRef: ElementRef,
		private renderer2: Renderer2,
	) { }

	@HostListener('focus') onFocus(): void {
		const input: HTMLInputElement = this.elmentRef.nativeElement;
		if (input.type === 'number' && this.isOnMobileDevice) {
			this.renderer2.setProperty(input, 'readOnly', true);
			const keyboard = window['Keyboard'];
			keyboard.hide();
			this.virtualNumpadService.open(input);
		}
	}
}
