import { Injectable } from '@angular/core';

import { PasswordProcessService } from '@mdib/signature-modes';

import { SignatureServiceXcl } from './signature.service-xcl';

@Injectable({
	providedIn: 'root'
})
export class PasswordProcessXclService extends SignatureServiceXcl implements PasswordProcessService {

	readonly SIGNATURE_TYPE = 'PWD';

}
