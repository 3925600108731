import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyComponent } from '@mdib/utils';
import { SessionGuardService } from '@mdib/sessions';

import { SessionCreationPageComponent } from './page/session-creation-page/session-creation-page.component';
import { SessionLogoutPageComponent } from './page/session-logout-page/session-logout-page.component';

const routes: Routes = [
	{
		path: 'session',
		canActivate: [SessionGuardService],
		data: { allowAnonymous: true },
		children: [
			{ path: '', component: EmptyComponent, outlet: 'header' },
			{ path: '', component: EmptyComponent, outlet: 'footer' },
			{
				path: '',
				pathMatch: 'full',
				component: SessionCreationPageComponent
			},
			{
				path: ':userId',
				component: SessionCreationPageComponent
			}
		]
	},
	{
		path: 'session-logout',
		canActivate: [SessionGuardService],
		data: { allowAnonymous: true },
		children: [
			{ path: '', component: EmptyComponent, outlet: 'header' },
			{ path: '', component: EmptyComponent, outlet: 'footer' },
			{
				path: '',
				pathMatch: 'full',
				component: SessionLogoutPageComponent
			},
			{
				path: ':userId',
				component: SessionLogoutPageComponent
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SessionsRoutingModule { }
