import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { CordovaPluginAdvHttpService } from '@mdib/cordova';
import { HttpClient } from '@angular/common/http';
import { RequestOptions } from '../model';

@Injectable({
	providedIn: 'root'
})
export class HttpService {

	public static readonly GET = 'GET';
	public static readonly POST = 'POST';
	public static readonly PUT = 'PUT';
	public static readonly DELETE = 'DELETE';
	public static readonly PATCH = 'PATCH';
	public static readonly OPTIONS = 'OPTIONS';
	public static readonly HEAD = 'HEAD';

	private static readonly IS_MOBILE_DEVICE: boolean = !!window['cordova'];

	constructor(
		private httpClient: HttpClient,
		private cordovaPluginAdvHttpService: CordovaPluginAdvHttpService
	) { }

	public execute(method: string, url: string, data?: any, headers?: { [name: string]: string | string[]; }): Observable<any> {
		if (HttpService.IS_MOBILE_DEVICE) {
			return this.executeMobile(method, url, data, headers);
		}
		return this.executeBrowser(method, url, data, headers);
	}

	private executeMobile(method: string, url: string, data?: any, headers?: { [name: string]: string | string[]; }): Observable<any> {
		return this.cordovaPluginAdvHttpService.request(method, url, data, headers);
	}

	private executeBrowser(method: string, url: string, data?: any, headers?: { [name: string]: string | string[]; }): Observable<any> {
		const options: RequestOptions = new RequestOptions();
		options.headers = headers;

		let body: string;
		switch (method) {
			case HttpService.GET:
				options.params = data;
				return this.httpClient.get(url, options);
			case HttpService.POST:
				body = JSON.stringify(data);
				return this.httpClient.post(url, body, options);
			case HttpService.PUT:
				body = JSON.stringify(data);
				return this.httpClient.put(url, body, options);
			case HttpService.DELETE:
				return this.httpClient.delete(url, options);
			case HttpService.PATCH:
				body = JSON.stringify(data);
				return this.httpClient.patch(url, body, options);
			case  HttpService.OPTIONS:
				return this.httpClient.options(url, options);
			case HttpService.HEAD:
				return this.httpClient.head(url, options);
			default:
				return throwError('No ' + method + ' HTTP method');
		}
	}
}
