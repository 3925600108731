import { Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BackendOperationStep, BackendOperationState } from '@mdib/core/adapters';

import { XclCallConfiguration } from './xcl-call-configuration';
import { DecisionTree } from './decision-tree';
import { BackendOperationXcl } from './backend-operation-xcl';

export class BackendRetrieveOperationXcl<OUT, X> extends BackendOperationXcl<string, OUT, X> {

	// State machine
	protected decision: DecisionTree = {
		'____': {
			triggers: { 'retrieve': this.retrieve },
			defaultTrigger: 'retrieve',
			expectedStates: ['9000'],
		},
	};

	constructor(injector: Injector) {
		super(injector);

		// Intialize step
		const machineState = this.decision[this.xclState];
		const triggers = Object.keys((machineState && machineState.triggers) || {});
		this.curentStep = new BackendOperationStep(null, triggers);
	}

	protected getParameters(input: string): any {
		return null;
	}

	protected retrieve(trigger: string, input: string): Observable<BackendOperationStep<OUT>> {
		return this.calls(trigger, [
			new XclCallConfiguration({ resourceId: input, method: 'GET', state: BackendOperationState.SUCCEEDED, body: this.getParameters(input) }),
		]);
	}

}
