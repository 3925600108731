import { BankConfig } from '@mdib/config';
import * as moment from 'moment';
import { Moment } from 'moment';

export namespace DateHelper {

	/**
	 * Converts a date with specified format or use generic one.
	 * @param {Date} date: date to convert to iso date format
	 * @param {string} format :  option date to convert to iso date format
	 * @return {string | null} : converted date
	 */
	export function format(date: Date, formatting?: string): string | null {
		const m = moment(date ? date : null);
		return !m.isValid() ? null : m.format(formatting || BankConfig.baseDateFormat);
	}

	/**
	 * Check if a date is a week day.
	 * @param  Date : date to check.
	 * @returns boolean : true if date is a week day .
	 */
	export function isWeekDay(date: Moment): boolean {
		const weekDay = date.day();
		return weekDay !== 6 && weekDay !== 0;
	}

	/**
	 * Check if a date is >= today 00:00:00 and a week day.
	 * @param  Date : date to check.
	 * @returns boolean : true if date is a week day >= today 00:00:00.
	 */
	export function isBusinessDate(date: Moment): boolean {
		return date ? date.isSameOrAfter(moment({ hour: 0, minute: 0, second: 0, millisecond: 0 })) && DateHelper.isWeekDay(date) : false;
	}
}
