import { Injector } from '@angular/core';
import { Params } from '@angular/router';
import { Location } from '@angular/common';
import { PageEvent } from '@angular/material';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SequenceCache } from '@mdib/core/meta';
import { PaginatedSearchParams, SearchResult } from '@mdib/core/adapters';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { ConfigurationService, ServiceResponse } from '@mdib/utils';

import { ListConfigurations } from './list-configurations';
import { CommonDataSource } from './common-data-source';
import { ExpandedDataSource } from './expanded-data-source';

export abstract class GenericListPage {

	/**
	 * @property Object which contains some parameters of the list.
	 */
	listConfigurations: ListConfigurations;

	/**
	 * @property The current expanded row.
	 */
	expandedElement: any;

	public NAMESPACE: string;
	public filter: any;
	protected dataSourceType: new (data: any[]) => (CommonDataSource | ExpandedDataSource) = ExpandedDataSource;

	protected location: Location;
	protected configurationService: ConfigurationService;
	protected serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService;

	protected searchCache: SequenceCache<any> = new SequenceCache();

	constructor(injector: Injector) {
		this.location = injector.get(Location);
		this.configurationService = injector.get(ConfigurationService);
		this.serviceResponseNotifMapper = injector.get(ServiceResponseNotificationMessagesMapperService);
		this.listConfigurations = {
			listTitle: '',
			selectorTitle: '',
			formatTitle: '',
			tableTitle: '',
			listEmptyMessage: '',
			listErrorMessage: '',
			isModalView: false,
			isExportableToXls: false,
			isExportableToXlsClicked: false,
			showSelector: true,
			showFormat: false,
			showFilter: true,
			pageSize: 10,
			totalCount: 0,
			pageIndex: 0,
			loaded: false,
			dataSource: null,
			filterOpenedByDefault: false,
			showFilterHeader: true,
			isChildComponent: false,
			notificationBannerHidden: false
		};
	}

	/**
	 *  This method is called for known if the row is a expansion detail row.
	 * @returns {boolean}
	 */
	isExpansionDetailRow = (i, row) => row.hasOwnProperty('detailRow');

	/**
	 * This method is called when the paginator changes the page size or page index.
	 * @param {PageEvent} event
	 */
	onPaginationChanges(event: PageEvent): void {
		this.listConfigurations.pageSize = event.pageSize;
		this.listConfigurations.pageIndex = event.pageIndex;
		this.saveSearchModel(this.filter);

		// Fetch the results
		const index = this.listConfigurations.pageIndex * this.listConfigurations.pageSize;
		const count = Math.min(this.listConfigurations.pageSize, this.listConfigurations.totalCount - index);
		const results = this.searchCache.get(index, count);
		if (results) {
			this.listConfigurations.dataSource = new this.dataSourceType(results);
		} else {
			this.fetchData();
		}
	}

	/**
	 * Set the page index at 0 and load the list.
	 */
	applyFilter(): void {
		this.listConfigurations.pageIndex = 0;
		this.searchCache.invalidate();
		this.saveSearchModel(this.filter);
		this.fetchData();
	}

	/**
	 * Fetch a range of items to render
	 */
	public fetchData() {
		// Encapsulate pagination
		const pagination = new PaginatedSearchParams(this.listConfigurations.pageIndex, this.listConfigurations.pageSize);

		// Build citeria
		const previousPagesLoaded = this.configurationService.instant('pages.search.previousPagesLoaded') || 2;
		const nextPagesLoaded = this.configurationService.instant('pages.search.nextPagesLoaded') || 2;

		// Fill the cache
		this.listConfigurations.loaded = false;
		const searchIndex = Math.max(0, pagination.pageIndex - previousPagesLoaded) * pagination.pageSize;
		const searchCount = Math.max(pagination.pageSize, pagination.pageSize * (1 + previousPagesLoaded + nextPagesLoaded));
		this.load(searchIndex, searchCount)
			.pipe(
				map((serviceResponse: ServiceResponse<any[]>) => {
					this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponse);
					const results = serviceResponse.getModel();
					return new SearchResult(results, serviceResponse.properties.totalCount || results.length, searchIndex, searchCount);
				})
			)
			.subscribe(result => {
				// Update the cache
				this.searchCache.set(result.items, result.index);

				// Get the results
				const total = result.total || 0;
				const index = pagination.pageIndex * pagination.pageSize;
				const count = Math.min(pagination.pageSize, total - index);
				const items = this.searchCache.get(index, count);
				this.listConfigurations.totalCount = total;
				this.listConfigurations.dataSource = new this.dataSourceType(items);
				this.listConfigurations.loaded = true;
			},
				(serviceResponseError: ServiceResponse<null>) => {
					this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
				}
			);
	}

	/**
	 * This method is called for open the extra information panel that corresponds at the row or closed it if is expanded.
	 * @param row of datasource object, the type is the one from current operation.
	 */
	expandRow(row: any): void {
		row === this.expandedElement ? this.expandedElement = undefined : this.expandedElement = row;
	}

	/**
	 * This method is called for consult the operation that corresponds at the row.
	 * @param row of datasource object, the type is the one from current operation.
	 */
	abstract consultRow(row: any): void;

	/**
	 * Clears the filter form fields, reloads the list and set the pageIndex at 0.
	 */
	abstract clearFilter(): void;

	/**
	 * Call service and feed datasource based on the pageNumber and pageSize.
	 * The selector and filters should be applied as well as pageIndex and pageSize.
	 *
	 * When filling your dataSource, use ExpandedDataSource when you use an expanded row and CommonDataSource when you don't.
	 * eg : myDataSource = new ExpandedDataSource(serviceResponse.getModel());
	 */
	protected abstract load(index?: number, count?: number): Observable<ServiceResponse<any>>;

	/**
	 * Initiliaze the search model
	 */
	protected loadSearchModel(params: Params, filter: any = {}): any {
		let parameters: any = {};
		try {
			parameters = JSON.parse(atob(params['context']));
		} catch (ex) { }

		// Load the pagination
		this.listConfigurations.pageIndex = Number.parseInt(parameters.pageIndex, 10) || 0;
		this.listConfigurations.pageSize = Number.parseInt(parameters.pageSize, 10) || this.listConfigurations.pageSize;
		delete parameters.pageIndex;
		delete parameters.pageSize;
		// Load parameters in the search model
		return Object.assign(filter, parameters);
	}

	protected saveSearchModel(filter: any = {}) {
		// Save the parameters in the URL without triggering a search
		let basePath = this.location.path() + '?';
		basePath = basePath.substring(0, basePath.indexOf('?'));
		this.location.replaceState(basePath, 'context=' + btoa(JSON.stringify({
			...filter,
			pageIndex: this.listConfigurations.pageIndex,
			pageSize: this.listConfigurations.pageSize,
		})));
	}
}


