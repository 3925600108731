import { InjectionToken } from '@angular/core';

// Modules
export { XclUtilsModule } from './xcl-utils.module';

// Helpers
export { OrderStateHelper } from './helper/order-state-helper';
export { DaysAndMonthsHelper } from './helper/days-and-months-helper';
export { XclDateFormatter } from './helper/xcl-date-formatter';
export { b64toBlob, mapToFunctionalCommunication, mapToXclCommunication, parseXclLanguageCode, blobToB64, convertBeneficiaryAddressFromXclToStreet, formatEuropeanCommunication, getCountryCodeFromIBAN } from './helper/xcl-connector-helpers';

export { XclAPI, XclCommunicationFields } from './model/xcl.enum';

// Types
export { XclOrderNature } from './model/xcl-order-nature';
export { AccessRightTokenXcl } from './model/access-right-token-xcl';
export { AccessRightTokenXclDefinition } from './model/access-right-token-xcl-definition';
export { FileEntryXcl, FileXcl } from './model/file-entry-xcl';

// Services
export { NavigationFilterBusinessRoleService } from './service/navigation-filter-business-role.service';
export { FileMapperXclService } from './service/file-mapper-xcl.service';
// export { FileStatusMapperXclService } from './service/file-status-mapper-xcl.service';

// Tokens
export const XCL_ADAPTER_IDENTIFIER = new InjectionToken<string>('XCL_ADAPTER_IDENTIFIER');
