import { AfterContentInit, Component, DoCheck } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CashAccount, CashAccountForm } from '@mdib/cash-accounts';
import { FormComponent } from '@mdib/commons';
import { CountryCodes } from '@mdib/config';
import { provideNamespace } from '@mdib/core/customization';
import { EnumHelper, UtilsHelper } from '@mdib/utils';

import { BeneficiaryFormBuilder } from '../../types/forms/beneficiary-form.builder';
import { Beneficiary } from '../../types/models/beneficiary';
import { BeneficiaryFormGroup } from '../../types/forms/beneficiary-form-group';

@Component({
	selector: 'beneficiary-form',
	templateUrl: './beneficiary-form.component.html',
	styleUrls: ['./beneficiary-form.component.scss'],
	viewProviders: [provideNamespace('beneficiaries', 'beneficiaryFormComponent')],
})
export class BeneficiaryFormComponent extends FormComponent<Beneficiary> implements AfterContentInit, DoCheck {

	public countries = EnumHelper.numbersEnumToStringArray(CountryCodes);
	readonly accountForm: CashAccountForm = new CashAccountForm(new FormBuilder());
	public formGroup: BeneficiaryFormGroup;

	constructor(
		beneficiaryFormBuilder: BeneficiaryFormBuilder,
	) {
		super(Beneficiary, beneficiaryFormBuilder);
	}

	ngAfterContentInit() {
		this.accountForm.group.valueChanges.subscribe(account => this.formGroup.get('account').patchValue(account));
		this.accountForm.group.patchValue(this.model && this.model.account  || new CashAccount());
	}

	ngDoCheck() {
		if (this.readonly) {
			this.accountForm.group.patchValue(this.model && this.model.account || new CashAccount());
			this.accountForm.group.disable({emitEvent: false});
		}
	}

	public isEmpty(value: any): boolean {
		return !UtilsHelper.objectNotEmpty(value);
	}
}
