import { SepaDirectDebitContract, ContractStatus, ContractType, Creditor, Debtor, SuspensionPeriod } from '@mdib/sepa-direct-debits';
import { ContactAddressBuilder } from '@mdib/customers';
import * as moment from 'moment';

export class MockSepaDirectDebitContracts {

	static getMockSepaDirectDebitContracts(): SepaDirectDebitContract[] {
		return new Array<SepaDirectDebitContract>();
	}
}
