/**
 * Chart types from charts.js
 */

export enum ChartTypes {
	line = 'line',
	pie = 'pie',
	doughnut = 'doughnut',
}

export enum Status {
	// Special states
	None,
	Unknown,
	// Expected flow
	Initiated,
	ToValidate,
	ToConfirm,
	NotAuthorizedToSign,
	ToSign,
	InShoppingBasket,
	EnvelopeReadyToBeSigned,
	Closed,
	Cancelled,
	// Unexpected flow
	Error,
}

export enum Periodicities {
	oneMonth = 'oneMonth',
	daily = 'daily',
	weekly = 'weekly',
	demiSemiMonthly = 'demiSemiMonthly',
	semiMonthly = 'semiMonthly',
	monthly = 'monthly',
	trimonthly = 'trimonthly',
	biMonthly = 'biMonthly',
	quarterly = 'quarterly',
	semiAnnually = 'semiAnnually',
	yearly = 'yearly'
}

export enum WeekDays {
	monday = 'monday',
	tuesday = 'tuesday',
	wednesday = 'wednesday',
	thursday = 'thursday',
	friday = 'friday',
	saturday = 'saturday',
	sunday = 'sunday',
}

export enum Months {
	january = 'january',
	february = 'february',
	march = 'march',
	april = 'april',
	may = 'may',
	june = 'june',
	july = 'july',
	august = 'august',
	september = 'september',
	october = 'october',
	november = 'november',
	december = 'december',
}

export enum FileFormat {
	pdf = 'pdf',
	mt940 = 'mt940',
	camt053 = 'camt053',
}
