import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * I'm responsible of proxying configuration data retrieving (from an synchronous or asynchronous resource).
 * Each resource should be accessible with a key.
 */
@Injectable()
export abstract class ConfigurationStore {

	/**
	 * Load the configuration.
	 */
	public abstract load(): Observable<boolean>;

	/**
	 * Update a configuration property.
	 *
	 * @param key
	 * @param value
	 * @returns the resource corresponding to the given key.
	 */
	public abstract set(key: string, value: any): Observable<any>;

	/**
	 * Set the default values for some properties
	 *
	 * @param properties List of default properties to set
	 */
	public abstract setDefaults(properties: { [key: string]: any });

	/**
	 * Retrieve the current configuration parameters value for a key.
	 * If the configuration is not loaded, the value is not returned.
	 *
	 * @param key The configuration key to find
	 * @returns The parameter value
	 */
	public abstract instant(key: string): any;
}
