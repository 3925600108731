import { Injectable } from '@angular/core';

import { BeneficiaryType } from '@mdib/beneficiaries';

@Injectable({
	providedIn: 'root'
})
export class BeneficiaryTypeMapperService {

	private mapping = {
		'01': BeneficiaryType.SEPA,
		'02': BeneficiaryType.SEPA,
		'01;02': BeneficiaryType.SEPA,
		'03': BeneficiaryType.INTERNATIONAL,
	};

	public fromXcl(value: string): BeneficiaryType {
		return this.mapping[value];
	}

	public toXcl(value: BeneficiaryType): string {
		switch (value) {
			case BeneficiaryType.INTERNAL: return '01';
			case BeneficiaryType.SEPA: return '01;02';
			case BeneficiaryType.INTERNATIONAL: return '03';
		}
		return '01;02;03';
	}
}
