import { Immutable } from '@mdib/core/meta';
import { Feedback } from '@mdib/utils';

@Immutable()
export class BackendOperationStep<T> {
	constructor(
		public readonly usedTrigger: string,
		public readonly availableTriggers: Array<string> = [],
		public readonly result: T = null,
		public readonly feedbacks: Feedback[] = [],
	) { }
}
