import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { BeneficiariesService } from './beneficiaries.service';
import { BeneficiariesServiceStub } from './beneficiaries.service.stub';

@Injectable()
export class BeneficiariesServiceProxy extends AdapterProxy {
	protected ServiceToken() { return BeneficiariesService; }
	protected ServiceDefinition() { return BeneficiariesServiceStub; }
}
