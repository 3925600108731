import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { Authentication } from '../../../model/authentication';
import { AuthenticationMode } from '../../../model/authentication-mode';
import { User } from '../../../model/user';
import { AuthenticationModesService } from '../../../service/authentication-modes.service';

@Component({
	selector: 'session-creation',
	template: '',
})
export class SessionCreationComponent implements OnInit {

	/**
	 * @property (Output) Emits events on errors or cancellation
	 */
	@Output() onEvent: EventEmitter<Event> = new EventEmitter<Event>();

	/**
	 * @property (Output) Emits the signed object
	 */
	@Output() onSuccess: EventEmitter<Authentication> = new EventEmitter<Authentication>();

	/**
	 * @property (Input) User to authenticate (Optional)
	 */
	private userObject: User;
	private selectedMode: AuthenticationMode = null;

	constructor(private viewContainerRef: ViewContainerRef,
				private componentFactoryResolver: ComponentFactoryResolver,
				private authenticationsService: AuthenticationModesService,
	) {
	}

	@Input('user')
	set user(object: User) {
		this.userObject = object;
		this.selectedMode = null;
		this.updateScreen();
	}

	ngOnInit() {
		this.updateScreen();
	}

	/**
	 * Change the authentication mode.
	 *
	 * @param newMode The authentication mode to use
	 */
	private setMode(newMode: AuthenticationMode) {
		if (newMode !== this.selectedMode) {
			this.selectedMode = newMode;
			this.updateScreen();
		}
	}

	/**
	 * Update the screen with the mode selector or the authentication process.
	 */
	private updateScreen() {
		// Mode selected => Show Signature Process
		this.viewContainerRef.clear();
		if (this.selectedMode) {
			return this.showAuthenticationProcess();
		}

		// Show Selector
		return this.showModeSelector();
	}

	/**
	 * Display the authentication process corresponding to the selected mode.
	 */
	private showAuthenticationProcess() {
		// Update View
		const componentType = this.selectedMode.processComponent;
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
		const componentRef = this.viewContainerRef.createComponent(componentFactory, 0);

		// Bindings
		componentRef.instance.user = this.userObject;
		componentRef.instance.onSuccess.subscribe((result) => this.emitSuccess(result));
		componentRef.instance.onEvent.subscribe((event) => this.emitEvent(event));
	}

	/**
	 * Display the authentication mode selector.
	 */
	private showModeSelector() {
		// Update View
		const componentType = this.authenticationsService.getSelectorComponent();
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
		const componentRef = this.viewContainerRef.createComponent(componentFactory);

		// List of modes
		const iterator = this.authenticationsService.getModes();
		const modes: AuthenticationMode[] = Array.from(iterator);

		// Bindings
		componentRef.instance.modes = modes.map(mode => mode.id);
		componentRef.instance.onSelected.subscribe((mode) => this.setMode(mode));
		componentRef.instance.onEvent.subscribe((event) => this.emitEvent(event));
	}

	/**
	 * Trigger an event when something happened either in the selection or in the authentication process.
	 * @param result The occuring event
	 */
	private emitEvent(event: Event) {
		this.onEvent.emit(event);
	}

	/**
	 * When the dynamic authentication process succeeds, the onSuccess event is triggered with the resulting authentication.
	 * @param result The resulting authentication
	 */
	private emitSuccess(result: Authentication) {
		this.onSuccess.emit(result);
	}
}
