import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material';

/**
 * @example
 * <mdib-dropdown [control]="mailboxMessageWriterForm.recipient" name="recipient"
 * [placeholder]="NAMESPACE + 'dropdownText' | translate " [dropList]="recipients"></mdib-dropdown>
 */
@Component({
	selector: 'mdib-dropdown',
	templateUrl: './dropdown.component.html',
})
export class DropdownComponent {

	@Output() onSelect: EventEmitter<string> = new EventEmitter<string>();
	@Input() public namespace = '';
	@Input() public placeholder;
	@Input() public translate = true;
	@Input() public control: AbstractControl = new FormControl();
	private _dropList: Array<any> = [];

	/**
	 * Send the value of inputs
	 * @param {string} item
	 */
	public sendValue(event: MatSelectChange) {
		this.control.setValue(event.value);
		this.onSelect.emit(this.control.value);
	}

	get dropList(): Array<any> {
		return this._dropList;
	}

	@Input()
	set dropList(list: Array<any>) {
		this._dropList = list;
	}
}
