import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { saveAs } from 'file-saver';

import { ModalDialogProperties } from '../../model/generic-pages.typings';
import { PDFDocumentProxy } from 'pdfjs-dist';

/**
 * Provides the ability to open a mat dialog on the basis of data provided
 * @example <button (click)="openDialog()">Test Button </button>
 *
 * openDialog() {
 *  const dialogData = <MatDialogConfig<ModalDialogProperties>>{...};
 *  const dialogRef = this._dialog.open(MyComponent, { width: '1200px',disableClose: true,data: dialogData});
 *  dialogRef.componentInstance.myEvent.subscribe((data: MyDataType) => {...});
 * }
 */

@Component({
	templateUrl: './modal-dialog.component.html',
	styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent {

	@Output() rightButtonClicked = new EventEmitter();
	@Output() leftButtonClicked = new EventEmitter();
	isRightButtonVisible = false;
	isLeftButtonVisible = false;
	pdfLoaded = false;
	closeOnClick = true;
	dataUrlPdf: string;

	@ViewChild('invisibleFrame')
	private iframeRef: ElementRef;

	constructor(@Inject(MAT_DIALOG_DATA) public data: ModalDialogProperties, public dialogRef: MatDialogRef<ModalDialogComponent>) {
		if (!!this.data.rightButtonLabel) {
			this.isRightButtonVisible = true;
		}
		if (!!this.data.leftButtonLabel) {
			this.isLeftButtonVisible = true;
		}
		if (this.data.document) {
			this.pdfLoaded = false;
		}
		this.parseBlob();
	}

	actionRightButton() {
		this.rightButtonClicked.emit();
		this.pdfLoaded = false;
		if (this.closeOnClick) {
			this.dialogRef.close();
		}
	}

	actionLeftButton() {
		this.leftButtonClicked.emit();
	}
	// pdf: PDFDocumentProxy
	onPdfLoaded(pdf: PDFDocumentProxy) {
		this.pdfLoaded = true;
	}

	/* TODO : see with carole and security to use this method and remove iframe if needed
	 * const popupWin = window.open(blobUrl, '_blank', 'width=1000,height=600').print();
	 */
	print() {
		try {
			// Try the modern way, or try using FileReader
			if (URL.createObjectURL) {
				this.iframeRef.nativeElement.src = URL.createObjectURL(this.data.document);
				this.iframeRef.nativeElement.onload = () => {
					this.iframeRef.nativeElement.contentWindow.print();
				};
			} else {
				const reader = new FileReader();
				reader.onload = e => {
					this.iframeRef.nativeElement.src = e.target['result'];
					this.iframeRef.nativeElement.onload = () => {
						this.iframeRef.nativeElement.contentWindow.print();
					};
				};
				reader.readAsDataURL(this.data.document);
			}
		} catch (error) {
			// In case of error, we give the silent treatment for now
		}

	}

	save() {
		saveAs(this.data.document, this.data.documentName);
	}

	parseBlob() {
		try {
			// Try the modern way, or try using FileReader
			if (URL.createObjectURL) {
				this.dataUrlPdf = URL.createObjectURL(this.data.document);
			} else {
				const reader = new FileReader();
				reader.onload = e => this.dataUrlPdf = e.target['result'];
				reader.readAsDataURL(this.data.document);
			}
		} catch (error) {
			// In case of error, we give the silent treatment for now
		}
	}
}
