import { AccessRightToken, Authorization } from '@mdib/core/security';

import { AccessRightTokenXclDefinition } from './access-right-token-xcl-definition';

export class AccessRightTokenXcl extends AccessRightToken {

	constructor(definition: string | AccessRightTokenXclDefinition) {
		if (typeof definition === 'string') {
			super(definition);
		} else {
			const authorization = (definition.authorization !== undefined) ? Authorization[definition.authorization] : Authorization.GRANT;
			super(definition.token, authorization);
		}
	}
}
