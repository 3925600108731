import { NgModule, ModuleWithProviders } from '@angular/core';

import { AccountValuationsService } from './service/account-valuations.service';
import { AccountValuationsServiceProxy } from './service/account-valuations.service.proxy';

@NgModule()
export class AccountValuationsModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: AccountValuationsModule,
			providers: [
				{ provide: AccountValuationsService, useClass: AccountValuationsServiceProxy },
			]
		};
	}
}
