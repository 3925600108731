import { delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SepaDirectDebitContractsService, SepaDirectDebitContract } from '@mdib/sepa-direct-debits';
import { Observable, of } from 'rxjs';
import { ServiceResponse } from '@mdib/utils';
import { MockSepaDirectDebitContracts } from '../mock/mock-sepa-direct-debit-contracts';
import { BackendOperation, SearchCriteria, SearchResult } from '@mdib/core/adapters';
import { SepaDirectDebitContractsRetrieveMemory } from '../service/operations/sepa-direct-debit-contracts-retrieve-memory';
import { UpdateOperationMemory } from '@mdib-memory/core';

@Injectable({
	providedIn: 'root'
})
export class SepaDirectDebitContractsMemoryService extends SepaDirectDebitContractsService {

	readonly DEFAULT_DELAY = 400;

	public list(index?: number, count?: number, counterpartyAccountNumber?: string): Observable<ServiceResponse<SepaDirectDebitContract[]>> {
		const results = counterpartyAccountNumber ? this.getFilteredSepaDirectDebitContractsList(counterpartyAccountNumber) : MockSepaDirectDebitContracts.getMockSepaDirectDebitContracts();
		return of(new ServiceResponse(
			results.slice(index || 0, (index + count) || undefined),
			[],
			{ totalCount: results.length },
		)).pipe(delay(this.DEFAULT_DELAY));
	}

	retrieve(): BackendOperation<string, SepaDirectDebitContract> {
		return new SepaDirectDebitContractsRetrieveMemory();
	}

	create(): BackendOperation<SepaDirectDebitContract, SepaDirectDebitContract> {
		throw new Error('Method not implemented.');
	}

	update(): BackendOperation<Partial<SepaDirectDebitContract>, SepaDirectDebitContract> {
		return new UpdateOperationMemory(MockSepaDirectDebitContracts.getMockSepaDirectDebitContracts(), 'contractReference');
	}

	delete(): BackendOperation<SepaDirectDebitContract, SepaDirectDebitContract> {
		throw new Error('Method not implemented.');
	}

	search(): BackendOperation<SearchCriteria<any>, SearchResult<SepaDirectDebitContract>> {
		throw new Error('Method not implemented.');
	}

	public count(counterpartyAccountNumber?: string): Observable<ServiceResponse<number>> {
		return of(new ServiceResponse<number>(
			counterpartyAccountNumber ? this.getFilteredSepaDirectDebitContractsList(counterpartyAccountNumber).length : MockSepaDirectDebitContracts.getMockSepaDirectDebitContracts().length)).pipe(delay(this.DEFAULT_DELAY));
	}

	private getFilteredSepaDirectDebitContractsList(counterpartyAccountNumber?: string): SepaDirectDebitContract[] {
		return MockSepaDirectDebitContracts.getMockSepaDirectDebitContracts().filter((sepaDirectDebitContract: SepaDirectDebitContract) => (sepaDirectDebitContract.debtor.accountNumber === counterpartyAccountNumber)
		);
	}

}
