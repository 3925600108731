import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { ServiceResponse } from '@mdib/utils';
import { ParameterSetsService } from '@mdib/core/customization';

import { CashAccount } from '../../model/cash-account';
import { CashAccountTypesParam, CashAccountTypeParameter } from '@mdib/cash-accounts';
import { CashAccountTypes, CashAccountTypesGroup } from '../../model/cash-accounts.enum';
import { CashAccountService } from '../../service/cash-account.service';
import { filter, map } from 'rxjs/operators';

@Component({
	selector: 'mdib-cash-accounts-dropdown-new',
	templateUrl: './cash-accounts-dropdown-new.component.html',
	styleUrls: ['./cash-accounts-dropdown-new.component.scss'],
})
export class CashAccountsDropdownNewComponent implements OnChanges {
	readonly NAMESPACE = 'cashAccountsModule.cashAccountsDropdownComponent.';

	@Input() control: AbstractControl = new FormControl();
	@Output() select: EventEmitter<CashAccount> = new EventEmitter<CashAccount>();

	/**
	 * This optional string parameter indicates that the list of cash accounts should be filtered to only show the accounts in this particular currency.
	 */
	@Input() singleCurrencyToShow: string;

	/**
	 * This optional boolean parameter indicates whether only the current accounts should be displayed.
	 */
	@Input() onlyShowCurrentAccounts = false;

	/**
	 * This optional boolean parameter indicates whether, once the accounts are loaded, the first one should be automatically selected.
	 */
	@Input() selectFirstCashAccount = false;

	@Input() onlyShowSavingsAccounts = false;

	/**
	 * This optional boolean parameter indicates whether, all accounts option is enabled or not.
	 */
	@Input() isAllAccountsEnabled = false;

	cashAccounts: CashAccount[] = [];
	filteredCashAccounts: CashAccount[] = [];

	constructor(private cashAccountService: CashAccountService,
				private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService,
				private parametersService: ParameterSetsService,
	) {
	}

	ngOnChanges() {
		if (this.control && this.control.enabled) {
			this.loadCashAccounts();
		}
	}

	loadCashAccounts() {
		this.cashAccountService.list()
			.pipe(filter(response => !!response))
			.subscribe(
				(serviceResponse: ServiceResponse<CashAccount[]>) => {
					this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponse, this.NAMESPACE);
					// Load the cash accounts.
					this.cashAccounts = serviceResponse.getModel();
					this.filteredCashAccounts = this.cashAccounts;

					// Filter cash accounts if paymentType is provided as input
					this.filterCashAccounts();

					if (this.selectFirstCashAccount) {
						this.autoSelectFirstCashAccount();
					}

					// In case that the ordererAccount has to be set by the form or another parameter, this will be done here.
					if (this.control.value) {
						const selectAccount = this.cashAccounts.find(account => this.control.value.accountNumber === account.number);
						this.control.setValue(selectAccount);
					}

				},
				(serviceResponseError: ServiceResponse<null>) => {
					this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
				},
			);
	}

	filterCashAccounts() {
		this.filteredCashAccounts = this.cashAccounts ? this.cashAccounts.filter(
			(cashAccount: CashAccount) => {
				// The 'unknown' account types should never be displayed
				if (!cashAccount.type) {
					return false;
				}
				// If singleCurrencyToShow is defined, only show the accounts in this currency
				if (!!this.singleCurrencyToShow && this.singleCurrencyToShow !== cashAccount.valuationCurrency) {
					return false;
				}



				// If onlyShowCurrentAccounts is set to true, only show the current accounts
				// if (this.onlyShowCurrentAccounts
				// 	&& (<CashAccountTypesParam>this.parametersService.getParamFromKey('cash-account/cash-account-types', cashAccount.type)).cashAccountTypesGroup !== CashAccountTypesGroup.CurrentAccounts
				// ) {
				if (this.getParameters(cashAccount)) {
					return false;
				}

				// If onlyShowSavingsAccounts is set to true, only show the current accounts
				if (this.onlyShowSavingsAccounts && cashAccount.type !== CashAccountTypes.RegulatedSavingsAccounts) {
					return false;
				}
				return true;
			},
		) : [];
	}

	autoSelectFirstCashAccount() {
		if (this.filteredCashAccounts.length > 0) {
			this.control.setValue(this.filteredCashAccounts[0]);
			this.select.emit(this.control.value);
		} else  {
			this.control.setValue(this.cashAccounts[0]);
			this.select.emit(this.control.value);
		}
	}

	/**
	 * Send the value of inputs
	 */
	sendValue(select: MatSelectChange) {
		this.control.setValue(select.value);
		this.select.emit(this.control.value);
	}

	private getParameters(cashAccount: CashAccount) {
		let response;
		this.parametersService.get('cash-account-types').pipe(
			map((params: CashAccountTypeParameter[]) => {
				return params.find(param => {
					return param.key === cashAccount.type;
				});
			}
		)).subscribe((param: CashAccountTypeParameter) => {
			response = param.cashAccountTypesGroup !== CashAccountTypesGroup.CurrentAccounts;
		});
		return response;
	}
}
