import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { CashAccount } from '@mdib/cash-accounts';

import { UtilsHelper } from '../helper/utils-helper';
import { InputError } from '../model/utils.typings';

export namespace GroupFieldValidator {

	export const REQUIRED = {required: <InputError> {translationKey: 'utilsModule.groupFieldsValidator.required'}};
	export const DUPLICATE = {duplicate: <InputError> {translationKey: 'utilsModule.groupFieldsValidator.duplicate'}};

	export function ABOVEBALANCE(amount: string, balance: string): {[key: string]: any} {
		return {aboveBalance: {translationKey: 'notification:errors:inputs:amounts:aboveBalance', params: {amount: amount, availableBalance: balance}}};
	}

	// TODO: remove this validator when form object wil be correctly used
	/**
	 * Check if all variables in the control are filled
	 * @param {AbstractControl} control
	 * @returns {{[p: string]: any} | null}
	 */
	export function required(control: AbstractControl): {[key: string]: any} | null {
		return Object.values(control.value).filter(element => element === null || element === undefined || element === '').length <= 0 ? null : REQUIRED;
	}

	// TODO: Remove this function & use the generic one => isDuplicated(). Example in payment-form.ts
	/**
	 * Check if the codes of Secret Questions are duplicated
	 * @param {AbstractControl} formArray
	 * @returns {{[p: string]: any} | null}
	 */
	export function duplicated(formArray: AbstractControl): {[key: string]: any} | null {
		const questions: FormArray = <FormArray>formArray.get('questions');
		for (let i = 0; i < questions.controls.length; i++) {
			for (let j = i + 1; j < questions.controls.length; j++) {
				if (questions.controls[i].value.code === questions.controls[j].value.code) {
					return DUPLICATE;
				}
			}
		}
		return null;
	}

	/**
	 * Check if the values are similar
	 * @param value1
	 * @param value2
	 * @returns {(group: FormGroup) => {[p: string]: any}}
	 */
	export function isDuplicated(path1: string, path2: string) {
		return (group: FormGroup): {[key: string]: any} => {
			const control1 = group.get(path1);
			const control2 = group.get(path2);
			if (!!control2 && !!control2.value) {
				const cashAccount1 = (<CashAccount>control1.value).number;
				const cashAccount2 = (<CashAccount>control2.value).number;
				// Case where the beneficiary filled is the external beneficiary form
				if (UtilsHelper.nullOrUndefined(cashAccount2)) {
					return isBeneficiaryDuplicated(control2);
				} else {
					return cashAccount1 === cashAccount2 ? {duplicate: <InputError> {translationKey: 'notification:errors:inputs:accounts:duplicate'}} : null;
				}
			}
			return null;
		};
	}


	/**
	 * Check if the values are similar
	 * @param value1
	 * @param value2
	 * @returns {(group: FormGroup) => {[p: string]: any}}
	 */
	export function availableBalance(accountPath: string, amountPath: string, currencyPath: string) {
		return (group: FormGroup): {[key: string]: any} => {
			const account: CashAccount = group.get(accountPath) && (<CashAccount> group.get(accountPath).value);
			const amount: number = group.get(amountPath) && UtilsHelper.amountToNumber(group.get(amountPath).value);
			const currency: string = group.get(currencyPath) && group.get(currencyPath).value;
			return !account || !amount || !currency || currency !== account.valuationCurrency || (currency === account.valuationCurrency && account.availableBalance >= amount) ? null : ABOVEBALANCE(amount + '', account.availableBalance + '');
		};
	}

	/**
	 * Check if the accout number of the orderer and the beneficiary are similar
	 * @param {AbstractControl} beneficiaryAccountNumber
	 * @returns {{duplicate: InputError}}
	 */
	export function isBeneficiaryDuplicated(beneficiaryAccountNumber: AbstractControl) {
		const control = beneficiaryAccountNumber.root.get('ordererAccount');

		if (!!control && !!beneficiaryAccountNumber && !!beneficiaryAccountNumber.value) {
			const ordererAccountNumber = (<CashAccount>control.value).number;
			if (ordererAccountNumber === beneficiaryAccountNumber.value.replace(/[\s]/g, '')) {
				beneficiaryAccountNumber.markAsTouched();
				return {duplicate: <InputError> {translationKey: 'notification:errors:inputs:accounts:duplicate'}};
			}
		}

		return null;

	}


	}

