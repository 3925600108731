import { Component, Input } from '@angular/core';
import { Notification } from '../../model/common-types';

@Component({
	selector: 'mdib-notification-box',
	templateUrl: './notification-box.component.html',
	styleUrls: ['./notification-box.component.scss']
})
export class NotificationBoxComponent {

	@Input() notification: Notification;
}
