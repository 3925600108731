import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { DateValidator } from '@mdib/utils';
import { PaymentFileTypes } from '../model/payment-file.enum';
import { PaymentFilesFilter } from '../model/payment-files-filter';

export class PaymentFilesFilterForm {
	private form: FormGroup;

	constructor(private fb: FormBuilder,
				private readonly paymentFilesFilter?: PaymentFilesFilter,
	) {
		if (!this.paymentFilesFilter) {
			this.paymentFilesFilter = new PaymentFilesFilter();
		}
		this.create();
		this.initSubscription();
	}

	public clear() {
		this.form.reset();
		this.form.enable();
	}

	private create() {
		this.form = this.fb.group({
			fileStatus: [ this.paymentFilesFilter.fileStatus ],
			uploadDateFrom: [this.paymentFilesFilter.uploadDateFrom, [DateValidator.dateNotAfterToday]],
			uploadDateTo: [this.paymentFilesFilter.uploadDateTo, [DateValidator.dateNotAfterToday]],
		});
	}

	private initSubscription() {
		this.fileStatus.valueChanges.subscribe(fileStatus => this.paymentFilesFilter.fileStatus = fileStatus);
		this.uploadDateFrom.valueChanges.subscribe(date => this.paymentFilesFilter.uploadDateFrom = date);
		this.uploadDateTo.valueChanges.subscribe(date => this.paymentFilesFilter.uploadDateTo = date);
	}

	get group(): FormGroup {
		return this.form;
	}

	get fileStatus(): AbstractControl {
		return this.form.get('fileStatus');
	}

	get uploadDateFrom(): AbstractControl {
		return this.form.get('uploadDateFrom');
	}

	get uploadDateTo(): AbstractControl {
		return this.form.get('uploadDateTo');
	}
}
