import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SessionGuardService } from '@mdib/sessions';

const ROUTES = [
	{
		path: 'informations',
		loadChildren: '@mdib-pages/informations/#InformationsPagesModule',
	},
	{
		path: 'customers',
		loadChildren: '@mdib-pages/customers/#CustomersPagesModule',
	},
	{
		path: 'payments',
		loadChildren: '@mdib-pages/payments/#PaymentsPagesModule',
	},
	{
		path: 'investments',
		loadChildren: '@mdib-pages/investments/#InvestmentsPagesModule',
	},
	{
		path: 'loans',
		loadChildren: '@mdib-pages/loans/#LoansPagesModule',
	},
	{
		path: 'mailbox',
		loadChildren: '@mdib-pages/mailbox/#MailboxPagesModule',
	},
	{
		path: 'limits',
		loadChildren: '@mdib-pages/keying-limits/#KeyingLimitsPagesModule',
	},
	{
		path: 'shopping-basket',
		loadChildren: '@mdib-pages/shopping-basket/#ShoppingBasketPagesModule',
	},
	{
		path: 'statements',
		loadChildren: '@mdib-pages/account-statements/#AccountStatementsPagesModule',
	},
	{
		path: 'payment-files',
		loadChildren: '@mdib-pages/payment-files/#PaymentFilesPagesModule',
	},
	{
		path: 'security',
		loadChildren: '@mdib-pages/forgot-password/#ForgotPasswordPagesModule',
	},
	{
		path: 'beneficiaries',
		loadChildren: '@mdib-pages/beneficiaries/#BeneficiariesPagesModule',
	},
	{
		path: 'direct-debits',
		loadChildren: '@mdib-pages/sepa-direct-debits/#SepaDirectDebitsPagesModule',
	},
	{
		path: 'accounts',
		loadChildren: '@mdib-pages/cash-accounts/#CashAccountsPagesModule',
	},
	// Custom pages
	{
		path: 'app-samples',
		loadChildren: '@customs/pages/sample-page/#SamplePageModule',
	},
	// Fallbacks
	{
		// If no route is specified, by default go to the Dashboard (if the user is still connected, otherwise he will get disconnected).
		path: '', pathMatch: 'full', redirectTo: 'dashboard',
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(ROUTES)
	],
	exports: [RouterModule],
	providers: [SessionGuardService]
})
export class AppRoutingModule { }
