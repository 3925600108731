import { Injectable } from '@angular/core';
import { XclSignatureContext, XclSignatureModes } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';
import { mapToFunctionalCommunication, mapToXclCommunication, OrderStateHelper, XclDateFormatter, DaysAndMonthsHelper, XclAPI } from '@mdib-xcl/utils';
import { convertBeneficiaryAddressFromXclToStreet, convertBeneficiaryTownFromXclToCity, fromXclBoolean, toXclBoolean } from '@mdib-xcl/utils/helper/xcl-connector-helpers';
import { Beneficiary, BeneficiaryType } from '@mdib/beneficiaries';
import { CashAccount, CashAccountService } from '@mdib/cash-accounts';
import { Communication, CommunicationTypes, Address } from '@mdib/commons';
import { ManagedCurrencyCodes } from '@mdib/config';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { AmountTypes, PaymentOperation, PaymentOperationBuilder, PaymentTypes, StandingOrderDetails } from '@mdib/payments';
import { Periodicities, ServiceResponse, UtilsHelper } from '@mdib/utils';
import * as moment from 'moment';
import { StandingOrderXcl } from '../../model/standing-order-xcl';
import { OperationStatusHelper } from '../../helper/operation-status-helper';
import convertDateToXCLDateFormat = XclDateFormatter.convertDateToXCLDateFormat;

@Injectable({
	providedIn: 'root',
})
export class StandingOrderMapperXclService {

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.';

	private userCashAccounts: CashAccount[];

	private readonly frequencyMappingList = [
		{'code': '01', 'value': Periodicities.weekly},
		{'code': '02', 'value': Periodicities.demiSemiMonthly},
		{'code': '03', 'value': Periodicities.semiMonthly},
		{'code': '04', 'value': Periodicities.monthly},
		{'code': '05', 'value': Periodicities.biMonthly},
		{'code': '06', 'value': Periodicities.trimonthly},
		{'code': '07', 'value': Periodicities.semiAnnually},
		{'code': '08', 'value': Periodicities.yearly},
		{'code': '10', 'value': Periodicities.daily},
		{'code': '11', 'value': Periodicities.quarterly},
	];

	constructor(private cashAccountService: CashAccountService,
				private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService) {
	}

	public toXcl(payment: PaymentOperation<StandingOrderDetails>): StandingOrderXcl {
		const address = payment.counterParty.address;
		let xclModel = <StandingOrderXcl>{
			standingOrderReference: payment.reference,
			standingOrderFixedAmount: !UtilsHelper.nullOrUndefined(payment.amount) ? payment.amount : null,
			operationAmount: !UtilsHelper.nullOrUndefined(payment.amount) ? payment.amount : null,
			ordererAccountNumber: payment.ordererAccount.number,
			beneficiaryAccountNumber: UtilsHelper.objectNotEmpty(payment.counterParty) && UtilsHelper.objectNotEmpty(payment.counterParty.account) ? payment.counterParty.account.number : null,
			beneficiaryIdentification: UtilsHelper.objectNotEmpty(payment.counterParty) && UtilsHelper.objectNotEmpty(payment.counterParty.account) ? payment.counterParty.fullName || payment.counterParty.account.clientWording : null,
			beneficiaryIdentificationForBeneficiary: UtilsHelper.objectNotEmpty(payment.counterParty) ? payment.counterParty.alias : null,
			beneficiaryCountryCode: payment.counterParty.address.country,
			beneficiaryAddress: payment.counterParty.address.addressLine1,
			beneficiaryAddress_1: payment.counterParty.address.addressLine2,
			saveBeneficiary: payment.saveBeneficiary,
			standingOrderType: '21',
			orderPaymentFrequency: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.frequency ? this.frequencyTypeToXcl(payment.executionDetails.frequency) : '04',
			standingOrderFixedAmountSwitch: UtilsHelper.objectNotEmpty(payment.executionDetails) ? toXclBoolean(payment.executionDetails.amountType === AmountTypes.fixed) : null,
			standingOrderVariableAmountSwitch: UtilsHelper.objectNotEmpty(payment.executionDetails) ? toXclBoolean(payment.executionDetails.amountType === AmountTypes.variable) : null,
			startDate: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.startDate ? convertDateToXCLDateFormat(payment.executionDetails.startDate.toDate()) : convertDateToXCLDateFormat(new Date()),
			endDate: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.endDate ? convertDateToXCLDateFormat(payment.executionDetails.endDate.toDate()) : null,
			totalAmountToPay: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.totalAmount ? payment.executionDetails.totalAmount : null,
			numberPayments: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.iteration ? `${payment.executionDetails.iteration}` : '',
			paymentDate1_1: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.firstPaymentDay ? payment.executionDetails.firstPaymentDay : '1',
			paymentDate1_2: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.secondPaymentDay ? payment.executionDetails.secondPaymentDay : '',
			paymentDate1_3: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.thirdPaymentDay ? payment.executionDetails.thirdPaymentDay : '',
			minimumPaymentAmount: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.minAmount ? payment.executionDetails.minAmount : null,
			maximumPaymentAmount: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.maxAmount ? payment.executionDetails.maxAmount : null,
			paymentMonthNumber: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.monthPayment ? DaysAndMonthsHelper.monthToXcl(payment.executionDetails.monthPayment) : '1',
			paymentDay: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.weekDayPayment ? DaysAndMonthsHelper.dayToXcl(payment.executionDetails.weekDayPayment) : '1',
			ordererAccountTargetBalance: UtilsHelper.objectNotEmpty(payment.executionDetails) && !!payment.executionDetails.minBalance ? payment.executionDetails.minBalance : null,
		};
		xclModel = mapToXclCommunication<StandingOrderXcl>(payment, xclModel);
		return xclModel;
	}

	public fromXcl(payment: PaymentOperation<StandingOrderDetails>, paymentXcl: MonogoalOrderXCLModel<StandingOrderXcl>, phase: string = XclAPI.retrieve): PaymentOperation<StandingOrderDetails> {
		payment.status = OrderStateHelper.getStatusFromXCLOrderStateCode(paymentXcl.state);
		let functionalModel: PaymentOperation<StandingOrderDetails> = new PaymentOperationBuilder<StandingOrderDetails>(payment)
			.amount(paymentXcl.goal.standingOrderFixedAmount)
			.currency(paymentXcl.goal.orderCurrency || ManagedCurrencyCodes.EUR)
			.reference(paymentXcl.goal.standingOrderReference)
			.type(PaymentTypes.europeanStandingOrder)
			.ordererAccountNumber(paymentXcl.goal.ordererAccountNumber)
			.ordererName(paymentXcl.goal.ordererIdentity)
			.counterparty(phase === XclAPI.retrieve ? this.buildBeneficiary(paymentXcl) : payment.counterParty)
			.executionDetails(new StandingOrderDetails({
				amountType: fromXclBoolean(paymentXcl.goal.standingOrderFixedAmountSwitch) ? AmountTypes.fixed : AmountTypes.variable,
				minAmount: !!paymentXcl.goal.minimumPaymentAmount ? Number(paymentXcl.goal.minimumPaymentAmount) : null,
				maxAmount: !!paymentXcl.goal.maximumPaymentAmount ? Number(paymentXcl.goal.maximumPaymentAmount) : null,
				minBalance: !!paymentXcl.goal.ordererAccountTargetBalance ? paymentXcl.goal.ordererAccountTargetBalance : null,
				frequency: !!paymentXcl.goal.orderPaymentFrequency ? this.frequencyTypeFromXcl(paymentXcl.goal.orderPaymentFrequency) : Periodicities.monthly,
				firstPaymentDay: !!paymentXcl.goal.paymentDate1_1 ? Number(paymentXcl.goal.paymentDate1_1) : null,
				secondPaymentDay: !!paymentXcl.goal.paymentDate1_2 ? Number(paymentXcl.goal.paymentDate1_2) : null,
				thirdPaymentDay: !!paymentXcl.goal.paymentDate1_3 ? Number(paymentXcl.goal.paymentDate1_3) : null,
				monthPayment: !!paymentXcl.goal.paymentMonthNumber ? DaysAndMonthsHelper.monthFromXcl(paymentXcl.goal.paymentMonthNumber) : null,
				weekDayPayment: !!paymentXcl.goal.paymentDay ? DaysAndMonthsHelper.dayFromXcl(paymentXcl.goal.paymentDay) : null,
				startDate: !!paymentXcl.goal.startDate ? moment(XclDateFormatter.xclStringToDate(paymentXcl.goal.startDate)) : null,
				endDate: !!paymentXcl.goal.endDate ? moment(XclDateFormatter.xclStringToDate(paymentXcl.goal.endDate)) : null,
				iteration: !!paymentXcl.goal.numberPayments ? Number(paymentXcl.goal.numberPayments) : null,
				totalAmount: !!paymentXcl.goal.totalAmountToPay ? Number(paymentXcl.goal.totalAmountToPay) : null,
			}))
			.status(OrderStateHelper.getStatusFromXCLOrderStateCode(paymentXcl.state))
			.operationStatus(OperationStatusHelper.paymentStatusFromXcl(paymentXcl.goal.operationStatus))
			.signatureContext(new XclSignatureContext({signableReference: paymentXcl.reference}))
			.saveBeneficiary(paymentXcl.goal.saveBeneficiary)
			.signatureTypesAllowed(paymentXcl.signatureTypesAllowed.map(type => XclSignatureModes.fromXclType(type))).paymentOperation;
		functionalModel = mapToFunctionalCommunication<StandingOrderXcl>(functionalModel, paymentXcl.goal);
		return functionalModel;
	}

	/**
	 * Create a beneficiary only if the user consult a standing order. Use the beneficiary encoded by him if not.
	 * @param {MonogoalOrderXCLModel<StandingOrderXcl>} paymentXcl
	 * @returns {Beneficiary}
	 */
	buildBeneficiary(paymentXcl: MonogoalOrderXCLModel<StandingOrderXcl>): Beneficiary {

		let beneficiary = null;
		this.cashAccountService.list().subscribe(
			(response: ServiceResponse<CashAccount[]>) => {
				this.userCashAccounts = response.getModel();
			},
			(serviceResponseError: ServiceResponse<null>) => {
				this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
			},
			() => {
				// Check if the counterparty account is an account of the connected user
				const beneficiaryCashAccount = this.userCashAccounts.find((cashAccount: CashAccount) => cashAccount.number === paymentXcl.goal.beneficiaryAccountNumber);
				const beneficiaryType = UtilsHelper.nullOrUndefined(beneficiaryCashAccount) ? BeneficiaryType.SEPA : BeneficiaryType.INTERNAL;

				beneficiary = new Beneficiary({
					fullName: paymentXcl.goal.beneficiaryIdentification,
					account: new CashAccount({
						clientWording: paymentXcl.goal.beneficiaryIdentification,
						valuationCurrency: paymentXcl.goal.orderCurrency || ManagedCurrencyCodes.EUR,
						number: paymentXcl.goal.beneficiaryAccountNumber,
					}),
					communication: new Communication({
						value: paymentXcl.goal.freeCommunication,
						type: CommunicationTypes.free,
					}),
					address: new Address({
						country: paymentXcl.goal.beneficiaryCountryCode,
						addressLine1: paymentXcl.goal.beneficiaryAddress,
						addressLine2: paymentXcl.goal.beneficiaryAddress_1
					}),
					alias: paymentXcl.goal.beneficiaryIdentificationForBeneficiary,
					type: beneficiaryType,
				});

			});
		return beneficiary;
	}

	private frequencyTypeFromXcl(periodicity: string): Periodicities {
		const tuple = this.frequencyMappingList.find((element: any) => element.code === periodicity);
		return tuple ? tuple.value : Periodicities.monthly;
	}

	private frequencyTypeToXcl(periodicity: any): string {
		const tuple = this.frequencyMappingList.find((element: any) => element.value === periodicity);
		return tuple ? tuple.code : '04';
	}
}
