import { Injectable, Inject, Type } from '@angular/core';

import { AuthenticationMode } from '../model/authentication-mode';
import { AUTHENTICATION_MODES, AUTHENTICATION_DEFAULT, AUTHENTICATION_SELECTOR } from '../sessions.const';
import { AuthenticationModeSelectorComponent } from '../component/authentications/authentication-mode-selector/authentication-mode-selector.component';
import { environment } from 'environments/environment';
import { SignatureModes } from '@mdib/signature-modes';
import { PasswordAuthenticationProcessComponent } from '@mdib/sessions-modes/component/password-authentication-process/password-authentication-process.component';

@Injectable()
export class AuthenticationModesService {

	private modesMap: Map<any, AuthenticationMode> = new Map<any, AuthenticationMode>();
	private incorrectAuthenticationAttempts = 0;

	/**
	 * @param authenticationModes List of authentication modes allowed by the application
	 * @param defaultMode Identifier of the default authentication mode (null for no default)
	 * @param selectorComponent Component to use for the selection of the authentication mode
	 */
	constructor(
		@Inject(AUTHENTICATION_MODES) private authenticationModes: AuthenticationMode[][],
		@Inject(AUTHENTICATION_DEFAULT) private defaultMode: any,
		@Inject(AUTHENTICATION_SELECTOR) private selectorComponent: Type<AuthenticationModeSelectorComponent>
	) {
		authenticationModes.forEach((modes) => {
			modes.forEach(mode => {
				// Disable password authentication and signature when environment is not dev
				if (!environment.dev && mode.id === 'password') {
					return;
				}
				this.modesMap.set(mode.id, mode);
			});
		});
	}

	/**
	 * Find all process modes available
	 *
	 * @returns An iterator on the authorisation modes
	 */
	public getModes(): IterableIterator<AuthenticationMode> {
		return this.modesMap.values();
	}

	/**
	 * Find an authorisation process mode based on its name
	 *
	 * @param name The name of the process
	 * @returns The process mode, or undefined
	 */
	public getMode(id: any): AuthenticationMode {
		return this.modesMap.get(id);
	}

	/**
	 * Retrieve the default process to use.
	 * This should can be used to display a missing mode or use a default authorisation process.
	 *
	 * @returns The default process mode
	 */
	public getDefaultMode(): AuthenticationMode {
		return this.getMode(this.defaultMode);
	}

	/**
	 * Retrieve the component to use for selecting the mode of authentication to use.
	 * It will be used even when 0 or one mode is allowed.
	 *
	 * @returns The component class to use as a process mode selector
	 */
	public getSelectorComponent(): Type<AuthenticationModeSelectorComponent> {
		return this.selectorComponent;
	}

	/**
	 * Retrieve the number of incorrect log in attempts by user
	 * @returns {number} : incorrect login attempts
	 */
	public getIncorrectAttempts(): number {
		return this.incorrectAuthenticationAttempts;
	}

	/**
	 * Sets the number of incorrect log in attempts by user
	 * @param {number} value: incorrect login attempts
	 */
	public setIncorrectAttempts(value: number): void {
		this.incorrectAuthenticationAttempts = value;
	}

}
