import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '@mdib/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'language-picker',
	templateUrl: './language-picker.component.html',
	styleUrls: ['./language-picker.component.scss'],
	animations: [
		trigger('appear', [
			state('expanded', style({ transform: 'scale(1)' })),
			transition('collapsed => expanded', animate('0.1s  ease-in')),
			state('collapsed', style({ transform: 'scale(0)', display: 'none' })),
			transition('expanded => collapsed', animate('0.1s ease-out')),
		]),
	],
})

export class LanguagePickerComponent implements OnInit {
	languageCodes: string[];
	public isSelectorExpanded = false;

	constructor(private configurationService: ConfigurationService,
				private translateService: TranslateService,
	) {}

	ngOnInit() {
		this.languageCodes = this.configurationService.instant('localisation.language.list');
	}

	public get currentLanguage() {
		const currentLanguage = this.translateService.currentLang;
		if (currentLanguage === 'fallback') {
			return '';
		}
		return currentLanguage;
	}

	public get selectorState() {
		return this.isSelectorExpanded ? 'expanded' : 'collapsed';
	}

	public setLanguage(language: string) {
		this.translateService.use(language);
	}

	public closeLanguagePicker() {
		setTimeout(() => {
			this.isSelectorExpanded = false;
		}, 100);
	}

	public toggleLanguagePicker() {
		this.isSelectorExpanded = !this.isSelectorExpanded;
	}
}
