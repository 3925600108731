import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FormGroupBuilder } from '@mdib/commons';
import { CommonValidator } from '@mdib/utils';

@Injectable({
	providedIn: 'root',
})
export class AccountFormBuilder implements FormGroupBuilder {

	constructor(
		private formBuilder: FormBuilder,
	) { }

	build(): FormGroup {
		return this.formBuilder.group({
			number: ['', [CommonValidator.required]],
			bic: [],
		});
	}
}
