import { NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';

import { ElectronicalStatementsMemoryService } from './service/electronical-statements-memory.service';
import { ElectronicalStatementsService } from '@mdib/electronical-statements';

@NgModule()
export class ElectronicalStatementsMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(ElectronicalStatementsService, adapterId, ElectronicalStatementsMemoryService);
	}
}
