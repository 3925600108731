import { Injectable } from '@angular/core';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { CashAccountOperation } from '../model/cash-account-operation';
import { CashAccountOperationsFilter } from '@mdib/cash-accounts';

@Injectable()
export abstract class CashAccountOperationService {

	/**
	 * @param {string} accountNumber Cash account number for which to retrieve operations
	 * @param {number} offset Offset to start from (by default 0, i.e. from the beginninf)
	 * @param {number} limit Maximum number of operations to retrieve (by default 0, i.e. no limit)
	 * @param {CashAccountOperationsFilter} filter Object containing properties on which the result must be filtered
	 * @example
	 * list(accountNumber, offset, limit)
	 *     .subscribe(
	 *         (serviceResponse: ServiceResponse<CashAccountOperation[]>) => this.operations = serviceResponse.getModel()
	 *     );
	 */
	public abstract list(accountNumber: string,
		index?: number,
		count?: number, filter?: CashAccountOperationsFilter): Observable<ServiceResponse<CashAccountOperation[]>>;

	/**
	 * @param {string} accountNumber Cash account number for which to count the operations
	 * @param {CashAccountOperationsFilter} filter Object containing properties on which the result must be filtered
	 */
	public abstract count(filter?: CashAccountOperationsFilter): Observable<ServiceResponse<number>>;

}
