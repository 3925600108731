import { Authorization } from './authorization.enum';

export abstract class AccessRight {
	/**
	 * authorizationFor
	 *
	 * Returns the authorization level for a set of tokens. How this authorization
	 * is computed depends on the specific implementation of the Access Right.
	 *
	 * @param tokens The list of token to check for authorization
	 * @returns The level of Authorization
	 */
	public abstract authorizationFor(tokens: Array<string>): Authorization;
}
