import { Feedback, ServiceResponse, ConfigurationService } from '@mdib/utils';
import { Observable, ReplaySubject, Subject, merge, of, empty, interval, BehaviorSubject } from 'rxjs';
import { MailboxConversation } from '../model/mailbox-conversation';
import { MailboxConversationSummary } from '../model/mailbox-conversation-summary';
import { MailboxMessagesService } from './mailbox-messages.service';
import { catchError, map, reduce, tap, mergeMap } from 'rxjs/operators';
import { Injector, Injectable } from '@angular/core';

@Injectable()
export abstract class MailboxMessagesCommonService extends MailboxMessagesService {

	/** A stream (a type of subject) that will emit an array of conversations every time there is a new version of that array */
	protected conversationsSummariesStream: Subject<ServiceResponse<MailboxConversationSummary[]>> = new Subject();

	protected unreadMessageCounterChanged: BehaviorSubject<null> = new BehaviorSubject<null>(null);

	/**
	 * A map of streams (progressively filled) that will emit a conversation every time there is a new version of a given conversation.
	 * It will be progressively filled when streams are requested for any new conversation.
	 */
	protected conversationDetailsStreams: Map<string, Subject<ServiceResponse<MailboxConversation>>> = new Map();

	protected configurationService: ConfigurationService;

	private unreadMessageCounterUpdateInterval = 30000;

	constructor(injector: Injector) {
		super();
		this.configurationService = injector.get(ConfigurationService);
		const counterUpdateInterval = this.configurationService.instant('application.notifications.unread_messages_counter_update.interval');
		if (counterUpdateInterval > this.unreadMessageCounterUpdateInterval) {
			this.unreadMessageCounterUpdateInterval = counterUpdateInterval;
		}
	}

	// Override
	public getConversations(): Observable<ServiceResponse<MailboxConversationSummary[]>> {
		return merge(this.conversationsSummariesStream.asObservable(), new Observable<null>(subscriber => {
			this.reloadConversations();
			subscriber.complete();
		}));
	}

	// Override
	public getConversationDetails(conversationIdentifier: string): Observable<ServiceResponse<MailboxConversation>> {
		if (!conversationIdentifier) {
			throw new Error('You must provide a valid (defined and non-null) conversationIdentifier !');
		}
		return merge(this.getConversationStream(conversationIdentifier).asObservable(), new Observable<null>(subscriber => {
			this.reloadConversation(conversationIdentifier);
			subscriber.complete();
		}));
	}

	// Override
	public deleteConversations(conversationIdentifiers: string[], reloadConversationsUponCompletion?: boolean): Observable<ServiceResponse<null>> {
		/*  Default implementation, which can be overridden by a specific implementation if the backend allows deleting multiple conversations at once.
			The default implementation works as follows:
			- Call deleteConversation() on each element of the list
			- Aggregate all the feedbacks/errors
			- When all the individual requests have ended:
				- Reload the list of conversations
				- Emit a "null" on the upstream observable to indicate that the query is finished, with the aggregated list of feedbacks
					- emit it on the normal stream if there were no errors
					- emit it on the error stream if at least 1 deletion failed
		*/

		if (!reloadConversationsUponCompletion || reloadConversationsUponCompletion === true) {
			// Already inform the observers that the conversations will reload
			this.conversationsSummariesStream.next(null);
		}

		const replaySubject: ReplaySubject<ServiceResponse<null>> = new ReplaySubject();

		let errorsHaveOccurred = false;
		let mergedObservable: Observable<any> = empty();

		conversationIdentifiers.forEach((conversationIdentifier: string) => {
			// Merge all the observables of single deletions into one resulting observable
			const singleDeletionObservable = this.deleteConversation(conversationIdentifier).pipe(
				catchError((error: ServiceResponse<null>) => {
					errorsHaveOccurred = true;
					return of(error);
				}));
			mergedObservable = merge(mergedObservable, singleDeletionObservable);
		});
		mergedObservable = mergedObservable.pipe(
			map((response: ServiceResponse<null>) => response.getFeedbacks()), // Extract the feedbacks
			reduce((mergedFeedbacks: Feedback[], feedbacks: Feedback[]) => mergedFeedbacks.concat(feedbacks), []), // Aggregate the feedbacks into a merged array of feedbacks
			tap(() => this.reloadConversationsIfTrueOrNullOrUndefined(reloadConversationsUponCompletion)), // In any case, reload the conversations once all the observables have finished
			map((feedbacks: Feedback[]) => {
				// When all the single deletions have completed, return one ServiceResponse on the normal stream if there were no errors, or on the error stream if errors occurred.
				return this.manageFeedbacks(feedbacks, errorsHaveOccurred);
			})
		);

		mergedObservable.subscribe(replaySubject);
		return replaySubject.asObservable();
	}

	// Override
	public markConversationsAsReadOrUnread(conversationIdentifiers: string[], isRead: boolean): Observable<ServiceResponse<null>> {
		/*  Default implementation, which can be overridden by a specific implementation if the backend allows changing readness info on multiple conversations at once.
			The default implementation works as follows:
			- Change readness info of each element of the list
			- Aggregate all the feedbacks/errors
			- When all the individual requests have ended:
				- Reload the list of conversations
				- Emit a "null" on the upstream observable to indicate that the query is finished, with the aggregated list of feedbacks
					- emit it on the normal stream if there were no errors
					- emit it on the error stream if at least 1 deletion failed
		*/

		const replaySubject: ReplaySubject<ServiceResponse<null>> = new ReplaySubject();

		let errorsHaveOccurred = false;
		let mergedObservable: Observable<any> = empty();

		conversationIdentifiers.forEach((conversationIdentifier: string) => {
			// Merge all the observables of single deletions into one resulting observable
			const singleDeletionObservable = this.markConversationAsReadOrUnread(conversationIdentifier, isRead)
				.pipe(catchError((error: ServiceResponse<null>) => {
					errorsHaveOccurred = true;
					return of(error);
				}));
			mergedObservable = merge(mergedObservable, singleDeletionObservable);
		});
		mergedObservable = mergedObservable.pipe(
			map((response: ServiceResponse<null>) => response.getFeedbacks()), // Extract the feedbacks
			reduce((mergedFeedbacks: Feedback[], feedbacks: Feedback[]) => mergedFeedbacks.concat(feedbacks), []), // Aggregate the feedbacks into a merged array of feedbacks
			tap(() => {
				this.unreadMessageCounterChanged.next(null);
				this.reloadConversations();
			}), // Reload the conversations once all the observables have finished
			map((feedbacks: Feedback[]) => {
				// When all the single operations have completed, return one ServiceResponse on the normal stream if there were no errors, or on the error stream if errors occurred.
				return this.manageFeedbacks(feedbacks, errorsHaveOccurred);
			})
		);

		mergedObservable.subscribe(replaySubject);
		return replaySubject.asObservable();
	}

	public unreadMessagesCounter(): Observable<ServiceResponse<number>> {
		return merge(this.unreadMessageCounterChanged.pipe(mergeMap(() => this.countUnreadMessages())), interval(this.unreadMessageCounterUpdateInterval).pipe(mergeMap(() => this.countUnreadMessages())));
	}

	/**
	 * Returns the stream of a conversation. If this stream does not exist yet, it first creates it.
	 *
	 * @param conversationIdentifier
	 * @returns {Subject<ServiceResponse<MailboxConversation>>}
	 */
	protected getConversationStream(conversationIdentifier: string): Subject<ServiceResponse<MailboxConversation>> {
		// Retrieve the stream from the streams map if it already exists
		let conversationStream = this.conversationDetailsStreams.get(conversationIdentifier);
		if (!conversationStream) {
			// If the stream does not exist yet, create it and store it in the streams map
			conversationStream = new Subject();
			this.conversationDetailsStreams.set(conversationIdentifier, conversationStream);
		}
		return conversationStream;
	}

	protected closeConversationStreamIfExists(conversationIdentifier: string): boolean {
		const conversationStream = this.conversationDetailsStreams.get(conversationIdentifier);
		if (conversationStream) {
			// If the stream exists, complete it and remove it from the streams map
			conversationStream.complete();
			this.conversationDetailsStreams.delete(conversationIdentifier);
			return true;
		}
		return false;
	}

	/**
	 * Reloads the conversations if the input parameter is true or null/undefined (i.e. default behavior)
	 * @param {boolean} reloadConversationsUponCompletion
	 */
	protected reloadConversationsIfTrueOrNullOrUndefined(reloadConversations?: boolean): void {
		if (!reloadConversations || reloadConversations === true) {
			this.reloadConversations();
		}
	}

	private manageFeedbacks(feedbacks: Feedback[], errorsHaveOccurred: boolean): ServiceResponse<null> {
		if (errorsHaveOccurred) {
			throw new ServiceResponse<null>(null, feedbacks);
		} else {
			return new ServiceResponse<null>(null, feedbacks);
		}
	}
}
