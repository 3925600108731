import { Injectable, Inject, Optional, Injector } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ParameterSet } from '../../types/models/parameter-set';
import { PARAMETER_SETS } from '../../types/tokens/parameter-sets-tokens';

@Injectable({
	providedIn: 'root'
})
export class ParameterSetsService {

	private cache: any = {};

	private parameterSets: Map<string, ParameterSet> = new Map();

	constructor(
		injector: Injector,
		@Inject(PARAMETER_SETS) @Optional() parameterSets: ParameterSet[],
	) {
		(parameterSets || []).forEach(parameterSet => {
			parameterSet.onInit(injector);
			this.parameterSets.set(parameterSet.name, parameterSet);
		});
	}

	public get<T>(parameterSetName: string): Observable<T> {
		// Get the definition
		const parameterSet = this.parameterSets.get(parameterSetName);
		if (!parameterSet) {
			console.warn(`No such parameter set: ${parameterSetName}`);
			return throwError(new Error(`No such parameter set: ${parameterSetName}`));
		}

		// Check if the cache can be used
		if (parameterSet.isCachable && this.cache[parameterSetName]) {
			return of(this.cache[parameterSetName]);
		}

		// Load the parameters
		return parameterSet.load().pipe(
			tap(parameters => {
				if (parameterSet.isCachable) {
					this.cache[parameterSetName] = parameters;
				}
			})
		);
	}
}
