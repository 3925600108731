import { NgModule, ModuleWithProviders, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StylingModule } from '@mdib/styling';

import { SessionCreationComponent } from './component/sessions/session-creation/session-creation.component';
import { AuthenticationModeSelectorComponent } from './component/authentications/authentication-mode-selector/authentication-mode-selector.component';
import { AuthenticationModesService } from './service/authentication-modes.service';
import { AuthenticationDataService } from './service/authentication-data/authentication-data.service';
import { AuthenticationDataServiceProxy } from './service/authentication-data/authentication-data.service.proxy';
import { SessionsService } from './service/sessions.service';
import { SessionUtilsService } from './service/session-utils.service';
import { SessionTimeoutService } from './service/session-timeout.service';
import { AuthenticationMode } from './model/authentication-mode';
import { AUTHENTICATION_MODES, AUTHENTICATION_DEFAULT, AUTHENTICATION_SELECTOR } from './sessions.const';
import { AuthenticationComponent } from './component/authentications/authentication/authentication.component';
import { SessionTimeoutComponent } from './component/sessions/session-timeout/session-timeout.component';
import { AuthenticationTimeoutComponent } from './component/authentications/authentication-timeout/authentication-timeout.component';
import { AuthenticationTimeoutService } from './service/authentication-timeout.service';
import { SessionResetService } from './service/session-reset/session-reset.service';
import { SessionResetServiceProxy } from './service/session-reset/session-reset.service.proxy';
import { SessionBackgroundTimeoutService } from './service/session-background-timeout.service';

@NgModule({
	imports: [
		CommonModule,
		StylingModule
	],
	declarations: [
		SessionCreationComponent,
		AuthenticationModeSelectorComponent,
		AuthenticationComponent,
		SessionTimeoutComponent,
		AuthenticationTimeoutComponent,
	],
	entryComponents: [
		AuthenticationModeSelectorComponent,
		SessionTimeoutComponent,
		AuthenticationTimeoutComponent,
	],
	exports: [
		AuthenticationComponent,
		AuthenticationModeSelectorComponent,
		SessionCreationComponent
	]
})
export class SessionsModule {
	public static forRoot(
		modes: AuthenticationMode[] = [],
		options: SessionsModuleOptions = {}
	): ModuleWithProviders {
		return {
			ngModule: SessionsModule,
			providers: [
				AuthenticationModesService, SessionTimeoutService, AuthenticationTimeoutService,
				SessionBackgroundTimeoutService,
				{ provide: AUTHENTICATION_MODES, multi: true, useValue: modes },
				{ provide: AUTHENTICATION_DEFAULT, useValue: options.defaultMode },
				{ provide: AUTHENTICATION_SELECTOR, useValue: options.SelectorComponent || AuthenticationModeSelectorComponent },
				{ provide: SessionsService, useClass: options.SessionsService || SessionsService },
				SessionUtilsService,
				{ provide: AuthenticationDataService, useClass: AuthenticationDataServiceProxy },
				{ provide: SessionResetService, useClass: SessionResetServiceProxy },
			]
		};
	}
}

interface SessionsModuleOptions {
	SelectorComponent?: Type<AuthenticationModeSelectorComponent>;
	SessionsService?: Type<SessionsService>;
	defaultMode?: any;
}
