import { Observable, of } from 'rxjs';

import { ParameterSetsService } from './parameter-sets.service';

export class ParameterSetsServiceStub extends ParameterSetsService {

	public get<T>(parameterSetName: string): Observable<T> {
		return of();
	}

}
