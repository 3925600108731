import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionsService } from '@mdib/sessions';
import { SidenavService, SvgIconPath } from '@mdib/styling';
import { Subscription } from 'rxjs';

@Component({
	selector: 'mdib-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
	readonly NAMESPACE = 'headerModule.headerComponent.';
	smallLogo = SvgIconPath.NWBLOGO;
	isSidenavOpen = false;
	subscription: Subscription;

	@HostBinding('class.side-menu-background-gray') grayVisible = false;

	constructor(
		private router: Router,
		private sessionsService: SessionsService,
		private sidenavService: SidenavService,
	) {
		/*** Will Subscribe the backdrop click of sidenav to update the variable isSidenavOpen */
		this.subscription = this.sidenavService.sidenavToggle.subscribe(isSidenavOpen => {
			this.isSidenavOpen = isSidenavOpen;
		});
	}

	ngOnInit() {
		this.sidenavService.state = this.isSidenavOpen;
	}

	sidenavToggle() {
		this.isSidenavOpen = !this.isSidenavOpen;
		this.sidenavService.state = this.isSidenavOpen;
	}

	ngOnDestroy() {
		this.sidenavService.clearData();
	}

	doLogout() {
		this.sessionsService.removeSession().subscribe(() => {
			this.sessionsService.setSessionsAndActiveSessionIdInStorage(null);
			this.router.navigate(['/session-logout']);
		});
	}

}
