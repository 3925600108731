import { LiveTourStep } from './live-tour-step';
import { LiveTourService } from '../live-tour.service';
import { Observable, Observer } from 'rxjs';

export class ClickStep extends LiveTourStep {

	constructor(
		private elementSelector: string,
		private delay = 0
	) {
		super();
	}

	public execute(service: LiveTourService, liveTourEvents: Observable<Event>): Observable<Event> {
		const element = document.querySelector(this.elementSelector) as HTMLInputElement;
		return Observable.create((obv: Observer<Event>) => {
			setTimeout(() => {
				element.dispatchEvent(new MouseEvent('click', {
					view: window,
					bubbles: true,
					cancelable: true
				}));
				obv.next(new Event('stepComplete'));
			}, this.delay);
		});
	}

}
