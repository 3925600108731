import { SignableOperation } from '@mdib/signature';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { InternationalPaymentDetails } from '../../types/models/international-payment-details';
import { PaymentOperation } from '../../types/models/payment-operation';
import { InternationalPaymentsService } from './international-payments.service';

export class InternationalPaymentsServiceStub extends InternationalPaymentsService {

	public validate(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>> {
		return of();
	}

	public confirm(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null> | null> {
		return of();
	}

	public get(reference: string): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>> {
		return of();
	}

	public getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>> {
		return of();
	}

	public delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>> | null>> {
		return of();
	}

	public sign(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>> {
		return of();
	}

	public signDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>> | null>> {
		return of();
	}

	public confirmDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>> | null>> {
		return of();
	}

}
