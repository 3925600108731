import { BackendOperationStub, BackendOperationType, BackendOperation, SearchCriteria, SearchResult } from '@mdib/core/adapters';

import { SecretQuestionsListsService } from './secret-questions-lists.service';
import { SecretQuestionsList } from '../../types/secret-questions-list';

export class SecretQuestionsListsServiceStub extends SecretQuestionsListsService {
	public create(): BackendOperation<SecretQuestionsList, SecretQuestionsList> {
		return new BackendOperationStub(BackendOperationType.CREATE);
	}

	public retrieve(): BackendOperation<string, SecretQuestionsList> {
		return new BackendOperationStub(BackendOperationType.RETRIEVE);
	}

	public update(): BackendOperation<Partial<SecretQuestionsList>, SecretQuestionsList> {
		return new BackendOperationStub(BackendOperationType.UPDATE);
	}

	public delete(): BackendOperation<any, SecretQuestionsList> {
		return new BackendOperationStub(BackendOperationType.DELETE);
	}

	public search(): BackendOperation<SearchCriteria<any>, SearchResult<SecretQuestionsList>> {
		return new BackendOperationStub(BackendOperationType.SEARCH);
	}
}
