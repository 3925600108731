import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CommonsModule } from '@mdib/commons';
import { UtilsModule } from '@mdib/utils';
import { SignatureModule } from '@mdib/signature';
import { PaymentsModule } from '@mdib/payments';
import { CashAccountsModule } from '@mdib/cash-accounts';
import { StylingModule } from '@mdib/styling';
import { BeneficiariesModule } from '@mdib/beneficiaries';

import { GenericPagesModule } from '@mdib-pages/generic';

import { DataGatheringStepComponent } from './component/create-payment-page/data-gathering-step/data-gathering-step.component';
import { BeneficiarySectionComponent } from './component/create-payment-page/data-gathering-step/beneficiary-section/beneficiary-section.component';
import { AmountSectionComponent } from './component/create-payment-page/data-gathering-step/amount-section/amount-section.component';
import { OperationDateSectionComponent } from './component/create-payment-page/data-gathering-step/operation-date-section/operation-date-section.component';
import { FrequencySectionComponent } from './component/create-payment-page/data-gathering-step/frequency-section/frequency-section.component';
import { BeneficiarySectionEuropeanInternationalComponent } from './component/create-payment-page/data-gathering-step/beneficiary-section-european-international/beneficiary-section-european-international.component';
import { OrdererSectionComponent } from './component/create-payment-page/data-gathering-step/orderer-section/orderer-section.component';

@NgModule({
	imports: [
		CommonModule,
		CommonsModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		UtilsModule,
		SignatureModule,
		PaymentsModule,
		CashAccountsModule,
		StylingModule,
		BeneficiariesModule,
		GenericPagesModule,
	],
	declarations: [
		DataGatheringStepComponent,
		BeneficiarySectionComponent,
		AmountSectionComponent,
		OperationDateSectionComponent,
		FrequencySectionComponent,
		BeneficiarySectionEuropeanInternationalComponent,
		OrdererSectionComponent,
	],
	exports: [
		DataGatheringStepComponent,
		BeneficiarySectionComponent,
		AmountSectionComponent,
		OperationDateSectionComponent,
		FrequencySectionComponent,
		BeneficiarySectionEuropeanInternationalComponent,
		OrdererSectionComponent,
	]
})
export class PaymentsGatheringPageModule { }
