import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ServiceResponse } from '@mdib/utils';
import { SignableOperation } from '@mdib/signature';

import { ForgotPasswordModel } from '../model/forgot-password-model';
import { ForgotPasswordService } from './forgot-password.service';

@Injectable()
export class ForgotPasswordServiceStub extends ForgotPasswordService {

	public init(userId: string): Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>> {
		return of();
	}

	public confirm(forgotPasswordOperation: SignableOperation<ForgotPasswordModel>): Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>> {
		return of();
	}

	public getPasswordModel(): SignableOperation<ForgotPasswordModel> {
		return undefined;
	}
}
