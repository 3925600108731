import { Injectable } from '@angular/core';

import { BackendService } from '@mdib/core/adapters';

import { SecretQuestionsList } from '../../types/secret-questions-list';

@Injectable()
export abstract class SecretQuestionsListsService extends BackendService<SecretQuestionsList> {

}
