import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Params, Router } from '@angular/router';
import { SteppedOperation } from '../../model/stepped-operation';
import { NavigationStorage } from '../../service/navigation-storage';
import { Subject } from 'rxjs';

/**
 * Displays a progress bar based on a list of labelled steps and a numeric progression
 * @example
 * <mdib-progressbar [activeWizardStep]="0" [wizardSteps]="["step 1", "step 2"...]"></mdib-progressbar>
 *
 * @description
 * If a route with a referenced {@link SteppedOperation} stored in the {@link NavigationStorage} is found, the {@property activeWizardStep} input is not mandatory.
 * In this case, the progressbar will start at step 0, and will be updated based on the currentStep property.
 *
 */
@Component({
	selector: 'mdib-progressbar',
	templateUrl: './progressbar.component.html',
	styleUrls: [ './progressbar.component.scss' ]
})
export class ProgressbarComponent implements OnInit {

	/**
	 * List of steps names which will be displayed
	 *
	 * @type {string[]}
	 */
	@Input() wizardSteps: string[] = [];

	/**
	 * Binded current step
	 */
	@Input() activeWizardStep: number;

	constructor(
		private _route: ActivatedRoute,
		private _navigationStorage: NavigationStorage
	) {
	}

	ngOnInit() {
		this._route.params.subscribe((params: Params) => {
			const id = params[ 'id' ];
			const steppedOperationSubject = this._navigationStorage.get(id) as Subject<SteppedOperation>;

			if (steppedOperationSubject) {
				steppedOperationSubject.subscribe((steppedOperation: SteppedOperation) => {
					if (!steppedOperation) {
						return;
					}

					this.activeWizardStep = steppedOperation.currentStep;
				});
			}
		});

		if (!this.activeWizardStep) {
			this.activeWizardStep = 0;
		}

		if (this.wizardSteps.length > 3) {
			this.wizardSteps = this.wizardSteps.slice(0, 3);
		}
	}

	isActiveWizardStep(wizardStep: number) {
		return wizardStep === this.activeWizardStep;
	}

	isValidatedWizardStep(wizardStep: number) {
		return wizardStep < this.activeWizardStep;
	}

}
