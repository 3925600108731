import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ServiceResponse } from '@mdib/utils';

import { Authentication } from '@mdib/sessions';

@Injectable()
export abstract class OtpAuthenticationService {

	public abstract authenticate(authentication: Authentication, answerCode: string): Observable<ServiceResponse<Authentication>>;

	public abstract initAuth(username: string): Observable<ServiceResponse<Authentication>>;

}
