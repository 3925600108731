import { FileFormat } from '@mdib/utils';

export class XclAccountStatementHelper {

	static getFileFormatFromXclCode(xclCode: string): string {
		switch (xclCode) {
			case 'PAP':
				return FileFormat.pdf;
			case 'Z01':
				return FileFormat.mt940;
			case 'CA3':
				return FileFormat.camt053;
			default:
				return '';
		}
	}

	static getXclCodeFromFileFormat(fileFormat: FileFormat): string {
		switch (fileFormat) {
			case FileFormat.pdf:
				return 'PAP';
			case FileFormat.mt940:
				return 'Z01';
			case FileFormat.camt053:
				return 'CA3';
			default:
				return '';
		}
	}
}
