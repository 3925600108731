import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ServiceResponse } from '@mdib/utils';

import { Authentication } from '@mdib/sessions';

@Injectable()
export abstract class PasswordAuthenticationService {

	public abstract authenticate(username: string, password: string): Observable<ServiceResponse<Authentication>>;

}
