import { Injectable } from '@angular/core';
import { AdapterProxy } from '@mdib/core/adapters';
import { StandingOrderService } from './standing-order.service';
import { StandingOrderStubService } from './standing-order-stub.service';

@Injectable()
export class StandingOrderProxyService extends AdapterProxy {
	protected ServiceToken() { return StandingOrderService; }
	protected ServiceDefinition() { return StandingOrderStubService; }
}

