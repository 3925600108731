import { throwError, Observable, Subject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BankConfig } from '@mdib/config';
import { ParameterModel } from '@mdib/http';

import { ServiceResponse } from '../model/service-response';
import { FeedbackTypes } from '../model/utils.typings';
import { UtilsHelper } from '../helper/utils-helper';

interface CacheContent {
	expiry: number;
	value: ServiceResponse<any>;
}

/**
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * This implementation is based on Ashwin Sureshkumar's article:
 * https://hackernoon.com/angular-simple-in-memory-cache-service-on-the-ui-with-rxjs-77f167387e39
 * @export
 * @class CacheService
 */
@Injectable()
export class CacheService {

	private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
	private inFlightObservables: Map<string, Subject<ServiceResponse<any>>> = new Map<string, Subject<ServiceResponse<any>>>();
	private readonly DEFAULT_MAX_AGE_MILLISEC = BankConfig.cacheDefaultExpiryTime * 1000;
	private readonly KEY_SEPARATOR = '§';

	constructor() {
	}

	/**
	 * Builds a string from the content of a ParameterModel
	 * @param {string} endpointAlias
	 * @param {ParameterModel[]} params
	 * @returns {string}
	 */
	generateCacheKeyFromParams(endpointAlias: string, params?: ParameterModel[]) {
		if (!BankConfig.isCacheActivated) {
			return '';
		}

		let cacheKey = endpointAlias.concat(this.KEY_SEPARATOR).concat(this.KEY_SEPARATOR);
		if (!params) {
			return cacheKey;
		}
		params.forEach((parameterModel: ParameterModel) => {
			cacheKey = cacheKey.concat(parameterModel.val).concat(this.KEY_SEPARATOR);
		});
		return cacheKey;
	}

	/**
	 * Gets the value from cache if the key is provided.
	 * If no value exists in cache, then check if the same call exists
	 * in flight, if so return the subject. If not create a new
	 * Subject inFlightObservable and return the source observable.
	 *
	 * @param {string} key is the used to check if the returned value is already in the cache
	 * @param {Observable<ServiceResponse<any>>} fallback is the method to be called in the case the cache has no
	 * record for this key
	 * @param {number} maxAge is the expiry time of the record in milliseconds. If not filled the expire time is
	 * DEFAULT_MAX_AGE_MILLISEC
	 * @param {boolean} force is a switch that forces the use of the cache even if it is deactivated in the global
	 * config
	 * @returns {Observable<ServiceResponse<any>> | Subject<ServiceResponse<any>>}
	 */
	get(key: string, fallback?: Observable<ServiceResponse<any>>, maxAge?: number, force?: boolean): Observable<ServiceResponse<any>> | Subject<ServiceResponse<any>> {

		if (!BankConfig.isCacheActivated && !force) {
			return fallback.pipe(tap(() => {
			}));
		}

		if (this.hasValidCachedValue(key)) {
			return of(this.cache.get(key).value);
		}

		if (this.inFlightObservables.has(key)) {
			return this.inFlightObservables.get(key);
		} else if (fallback && fallback instanceof Observable) {
			// Return the input unchanged and ask the cache to subscribe to events if the response doesn't contain errors
			return fallback.pipe(tap((value) => {
				if (!this.isResponseWithError(value)) {
					this.set(key, value, maxAge);
				}
			}));
		} else {
			if (!fallback) {
				return throwError('Fallback not provided');
			}
			if (!(fallback instanceof Observable)) {
				return throwError('Fallback is not an Observable');
			}
			return throwError('Requested key is not available in Cache');
		}

	}

	/**
	 * Invalidates the cache
	 * @param {string} endpoint is the endpoint for which the cache must be cleared. If left empty the whole cache for
	 * all endpoints is cleared.
	 */
	invalidate(endpoint?: string): void {
		if (!endpoint) {
			this.inFlightObservables.clear();
			this.cache.clear();
		} else {
			let keys = Array.from(this.inFlightObservables.keys());
			keys.filter((key: string) => key.startsWith(endpoint))
				.forEach((key: string) => this.inFlightObservables.delete(key));
			keys = Array.from(this.cache.keys());
			keys.filter((key: string) => key.startsWith(endpoint))
				.forEach((key: string) => this.cache.delete(key));
		}
	}

	/**
	 * Sets the value with key in the cache
	 * Notifies all observers of the new value
	 */
	private set(key: string, value: ServiceResponse<any>, maxAge?: number): void {
		this.inFlightObservables.set(key, new Subject());
		if (!maxAge) {
			maxAge = this.DEFAULT_MAX_AGE_MILLISEC;
		}
		this.cache.set(key, { value: value, expiry: Date.now() + maxAge });
		this.notifyInFlightObservers(key, value);
	}

	/**
	 * Publishes the value to all observers of the given in progress observables if observers exist.
	 */
	private notifyInFlightObservers(key: string, value: ServiceResponse<any>): void {
		if (this.inFlightObservables.has(key)) {
			const inFlight = this.inFlightObservables.get(key);
			const observersCount = inFlight.observers.length;
			if (observersCount) {
				// console.log(`Notifying ${inFlight.observers.length} flight subscribers for ${key}`);
				inFlight.next(value);
			}
			inFlight.complete();
			this.inFlightObservables.delete(key);
		}
	}

	/**
	 * Checks if the key exists and has not expired.
	 */
	private hasValidCachedValue(key: string): boolean {
		if (this.cache.has(key)) {
			if (this.cache.get(key).expiry < Date.now()) {
				// console.log('CACHE - key ' + key + ' found in the cache, EXPIRED');
				this.cache.delete(key);
				return false;
			}
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Check the response of the http call to know if there are any backend issues.
	 *
	 * @param response The response of the http call.
	 * @return True if the http call have on or more backend errors. False if there is no backend error.
	 */
	private isResponseWithError(response: ServiceResponse<any>): boolean {
		return UtilsHelper.objectNotEmpty(response.getFeedbacksByType(FeedbackTypes.BACKEND_ERROR));
	}
}
