import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ParameterSet } from './parameter-set';

export class ParameterSetJsonLoader implements ParameterSet {

	private httpClient: HttpClient;
	private isLoading: Subject<any> = null;

	constructor(
		public name: string,
		private url: string,
		public isCachable = true,
	) { }

	public onInit(injector: Injector): void {
		this.httpClient = injector.get(HttpClient);
	}

	public load(): Observable<any> {
		if (!this.isLoading) {
			this.isLoading = new Subject();
			this.httpClient.get(this.url).pipe(tap(result => {
				this.isLoading.next(result);
				this.isLoading.complete();
				this.isLoading = null;
			})).subscribe();
		}
		return this.isLoading;
	}

}
