import { AuthenticationStatisticData } from './authentication-statistic-data';
import { isUndefined } from 'util';

/**
 * Tool to generate a {@link AuthenticationStatisticData}
 *
 * @example
 * AuthenticationStatisticDataBuilder.empty() // Initiates an empty {@link AuthenticationStatisticData}
 *   .clientWording('test') // Fill some fields thanks to chaining
 *   .type(AuthenticationStatisticDataType.authenticationType) // ...
 *   .AuthenticationStatisticDataType; // Retrieves the generated {@link AuthenticationStatisticData}
 */
export class AuthenticationStatisticDataBuilder {

	readonly _authenticationStatisticData: AuthenticationStatisticData;

	constructor(authenticationStatisticData?: AuthenticationStatisticData) {
		this._authenticationStatisticData = <AuthenticationStatisticData>{};
		if (authenticationStatisticData) {
			this.lastConnectionDateAndTime(authenticationStatisticData.lastConnectionDateAndTime);
		}
	}

	/**
	 * Initiates a builder based on an empty {@link AuthenticationStatisticData}
	 * @returns {AuthenticationStatisticDataBuilder}
	 */
	static empty(): AuthenticationStatisticDataBuilder {
		return new AuthenticationStatisticDataBuilder();
	}

	/**
	 * Initiates a builder based on an already existing {@link AuthenticationStatisticData}.
	 * The {@link AuthenticationStatisticData} will be cloned.
	 *
	 * @param {AuthenticationStatisticData} authenticationStatisticData
	 * @returns {AuthenticationStatisticDataBuilder}
	 */
	static from(authenticationStatisticData: AuthenticationStatisticData): AuthenticationStatisticDataBuilder {
		return new AuthenticationStatisticDataBuilder(authenticationStatisticData);
	}

	public lastConnectionDateAndTime(value: Date): AuthenticationStatisticDataBuilder {
		if (!isUndefined(value)) {
			this._authenticationStatisticData.lastConnectionDateAndTime = value;
		}
		return this;
	}

	get authenticationStatisticData(): AuthenticationStatisticData {
		return this._authenticationStatisticData;
	}
}

