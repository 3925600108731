import { Beneficiary } from '@mdib/beneficiaries';
import { CashAccount } from '@mdib/cash-accounts';
import { Communication, CommunicationTypes, Address } from '@mdib/commons';
import { SignatureModes } from '@mdib/signature-modes';
import { PaymentOperation } from './payment-operation';
import { PaymentTypes } from './payments.enum';

/**
 * Tool to generate a {@link PaymentOperation<T>}
 *
 */
export class PaymentOperationBuilder<T> {
	private readonly _paymentOperation: PaymentOperation<T>;

	constructor(paymentOperation?: PaymentOperation<T>) {
		this._paymentOperation = new PaymentOperation<T>(paymentOperation || null);
	}

	get paymentOperation(): PaymentOperation<T> {
		return this._paymentOperation;
	}

	/**
	 * Initiates a builder based on an empty {@link PaymentOperation<T>}
	 * @returns {PaymentOperationBuilder}
	 */
	empty(): PaymentOperationBuilder<T> {
		return new PaymentOperationBuilder<T>();
	}

	reference(reference: string): PaymentOperationBuilder<T> {
		this._paymentOperation.reference = reference;
		return this;
	}

	status(status): PaymentOperationBuilder<T> {
		this._paymentOperation.status = status;
		return this;
	}

	operationStatus(operationStatus): PaymentOperationBuilder<T> {
		this._paymentOperation.operationStatus = operationStatus;
		return this;
	}

	counterparty(counterparty: Beneficiary) {
		this._paymentOperation.counterParty = counterparty;
		return this;
	}

	counterpartyAddress(counterpartyAddress: Address) {
		this._paymentOperation.counterParty.address = counterpartyAddress;
		return this;
	}

	communication(communication: string) {
		this.checkInnerInstances();
		this._paymentOperation.counterParty.communication.value = communication;
		return this;
	}

	communicationType(communicationType: CommunicationTypes) {
		this.checkInnerInstances();
		this._paymentOperation.counterParty.communication.type = communicationType;
		return this;
	}

	counterpartyName(counterpartyName: string): PaymentOperationBuilder<T> {
		this.checkInnerInstances();
		this._paymentOperation.counterParty.fullName = counterpartyName;
		return this;
	}

	counterpartyAccountNumber(counterpartyAccountNumber: string): PaymentOperationBuilder<T> {
		this.checkInnerInstances();
		this._paymentOperation.counterParty.account.number = counterpartyAccountNumber;
		return this;
	}

	counterpartyBicCode(counterpartyBicCode: string): PaymentOperationBuilder<T> {
		this.checkInnerInstances();
		this._paymentOperation.counterParty.account.bic = counterpartyBicCode;
		return this;
	}

	saveBeneficiary(saveBeneficiary: boolean): PaymentOperationBuilder<T> {
		this._paymentOperation.saveBeneficiary = saveBeneficiary;
		return this;
	}

	operationDate(operationDate: Date): PaymentOperationBuilder<T> {
		this._paymentOperation.operationDate = operationDate;
		return this;
	}

	amount(amount: number): PaymentOperationBuilder<T> {
		this._paymentOperation.amount = amount;
		return this;
	}

	currency(currency: string): PaymentOperationBuilder<T> {
		this._paymentOperation.currency = currency;
		return this;
	}

	maturityDate(maturityDate: Date): PaymentOperationBuilder<T> {
		this._paymentOperation.paymentDate = maturityDate;
		return this;
	}

	ordererAccountNumber(ordererAccountNumber: string): PaymentOperationBuilder<T> {
		this._paymentOperation.ordererAccount = this._paymentOperation.ordererAccount || new CashAccount();
		this._paymentOperation.ordererAccount.number = ordererAccountNumber;
		return this;
	}

	ordererAccountAlias(ordererAccountAlias: string): PaymentOperationBuilder<T> {
		this.checkInnerInstances();
		this._paymentOperation.ordererAccount.clientWording = ordererAccountAlias;
		return this;
	}

	ordererName(ordererName: string): PaymentOperationBuilder<T> {
		this._paymentOperation.ordererName = ordererName;
		return this;
	}

	signatureTypesAllowed(signatureTypesAllowed: SignatureModes[]): PaymentOperationBuilder<T> {
		this._paymentOperation.signatureTypesAllowed = signatureTypesAllowed;
		return this;
	}

	type(type: PaymentTypes): PaymentOperationBuilder<T> {
		this._paymentOperation.type = type;
		return this;
	}

	signatureContext(signatureContext: any): PaymentOperationBuilder<T> {
		this._paymentOperation.signatureContext = signatureContext;
		return this;
	}

	notificationPhoneNumber(notificationPhoneNumber: any): PaymentOperationBuilder<T> {
		this._paymentOperation.counterParty.communication.notificationPhoneNumber = notificationPhoneNumber;
		return this;
	}

	executionDetails(details: T): PaymentOperationBuilder<T> {
		this._paymentOperation.executionDetails = details;
		return this;
	}

	// TODO: temporary bad code because of model refact

	private checkInnerInstances() {
		if (!this.paymentOperation.counterParty) {
			this.paymentOperation.counterParty = new Beneficiary();
		}
		if (!this.paymentOperation.counterParty.communication) {
			this.paymentOperation.counterParty.communication = new Communication();
		}
		if (!this.paymentOperation.counterParty.account) {
			this.paymentOperation.counterParty.account = new CashAccount();
		}
		if (!this.paymentOperation.ordererAccount) {
			this.paymentOperation.ordererAccount = new CashAccount();
		}
	}
}
