// Modules
export { XclCustomersModule } from './xcl-customers.module';

// Types
export { XclSecretQuestionModel } from './types/models/xcl-secret-questions-model';
export { XclRepresentedClientModel }  from './types/models/xcl-represented-client-model';

// Services
export { SecretQuestionsListsXclService } from './services/secret-questions-lists-xcl.service';
export { PersonsXclService } from './services/persons-xcl.service';
