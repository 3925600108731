import { SignatureModes } from '@mdib/signature-modes';
import { Status } from '@mdib/utils';
import { AddressModel } from '@mdib/commons';

import { Contact } from './contact';
import { ContactType } from './person.enum';

export class ContactAddress implements Contact, AddressModel {

	status: Status;
	identifier: string;
	signatureTypesAllowed: SignatureModes[];

	public id: string;
	public addressLine1: string;
	public addressLine2: string;
	public label: string;
	public country: string;

	constructor(address?: Partial<ContactAddress>) {
		Object.assign(this, address);
	}

	getType(): ContactType {
		return ContactType.Address;
	}

	/**
	 * @see Contact
	 * @returns {string[]} Street number, Street Name, Postal Code, City, Country
	 */
	toStrings(): string[] {
		return [this.addressLine1, this.addressLine2, this.country];
	}

	get reference(): string {
		return this.id;
	}

	set reference(reference: string) {
		this.id = reference;
	}

}
