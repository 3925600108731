import { Immutable, Enum } from '@mdib/core/meta';

@Immutable()
export class BackendOperationType extends Enum {
	public static readonly CREATE = new BackendOperationType('create');
	public static readonly RETRIEVE = new BackendOperationType('retrieve');
	public static readonly UPDATE = new BackendOperationType('update');
	public static readonly DELETE = new BackendOperationType('delete');
	public static readonly SEARCH = new BackendOperationType('search');
}
