import { Injectable } from '@angular/core';
import { BusinessRole } from '@mdib/sessions';
import { SessionsService } from './sessions.service';

@Injectable()
export class SessionUtilsService {

	constructor(private sessionService: SessionsService) {}

	public getSessionActiveUserId(): string {
		const activeSession = this.sessionService.getSession();
		return activeSession.activeUser.id ? activeSession.activeUser.id : '';
	}

	public getSessionActiveUserPersonId(): string {
		const activeSession = this.sessionService.getSession();
		return activeSession.activeUser.person ? activeSession.activeUser.person.id : '';
	}

	public getSessionActiveUserBusinessRole(): string {
		const activeSession = this.sessionService.getSession();
		return activeSession.activeUser.businessRole ? activeSession.activeUser.businessRole.name : '';
	}

	public isActiveUserConnected(): boolean {
		const activeSession = this.sessionService.getSession();
		return activeSession && activeSession.activeUser && activeSession.activeUser.connected;
	}

	public getSessionActiveBusinessRole(): string {
		const activeSession = this.sessionService.getSession();
		return activeSession && activeSession.activeUser && activeSession.activeUser.businessRole.name;
	}

	public getSessionActiveBusinessRoleId(): string {
		const activeSession = this.sessionService.getSession();
		return activeSession && activeSession.activeUser && activeSession.activeUser.businessRole.id;
	}
}
