import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetsComponent } from '@mdib/stubs/widgets/widgets.component';
import { UtilsModule } from '@mdib/utils';
import { TranslateModule } from '@ngx-translate/core';
import { StylingModule } from '@mdib/styling';

@NgModule({
	imports: [
		CommonModule,
		// Third party
		TranslateModule,
		StylingModule,
		UtilsModule,
	],
	declarations: [
		WidgetsComponent,
	],
	exports: [
		WidgetsComponent,
	]
})
export class StubsModule {
}
