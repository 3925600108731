import { Injectable } from '@angular/core';
import { BicService } from './bic.service';

import { AdapterProxy } from '@mdib/core/adapters';

import { BicServiceStub } from './bic.service.stub';

@Injectable()
export class BicServiceProxy extends AdapterProxy {
	protected ServiceToken() {
		return BicService;
	}

	protected ServiceDefinition() {
		return BicServiceStub;
	}
}
