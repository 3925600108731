import { Component, Input } from '@angular/core';
import { DigipassChallenge } from '../../model/digipass-signature';

@Component({
	selector: 'digipass-challenge',
	templateUrl: './digipass-challenge.component.html',
	styleUrls: ['./digipass-challenge.component.scss']
})
export class DigipassChallengeComponent {

	readonly NAMESPACE = 'signatureModule.digipassChallengeComponent.';

	@Input()
	public challenge: DigipassChallenge;

}
