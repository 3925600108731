/**
 * All payment-file types.
 */
export enum PaymentFileTypes {
	sctInCu2Fi = 'sctInCu2Fi',
}

/**
 * All payment-file status.
 */
export enum PaymentFileStatus {
	awaitingProcessing = 'awaitingProcessing',
	beingProcessed = 'beingProcessed',
	unformatted = 'unformatted',
	checkingContent = 'checkingContent',
	controlled = 'controlled',
	signed = 'signed',
	inProgress = 'inProgress',
	executed = 'executed',
	rejectedByUser = 'rejectedByUser',
	cancelled = 'cancelled',
	// Old statuses not returned by backend, but coul still be present in migrated data.
	rejected = 'rejected',
	contentToCheck = 'contentToCheck',
	riskOfDuplicates = 'riskOfDuplicates',
	incorrectFormat = 'incorrectFormat',
	cancelError = 'cancelError',



}

/**
 * All hash algorithms used for payment-files.
 */
export enum HashAlgorithms {
	sha1 = 'sha1',
	sha2 = 'sha2',
}

export enum PaymentFileError {
	noError = 'noError',
	riskOfDuplicates = 'riskOfDuplicates',
	accessRightsProblem = 'accessRightsProblem',
	accountNotLinkedToThePrincipal = 'accountNotLinkedToThePrincipal',
	incorrectLayout = 'incorrectLayout',
	voucherProblem = 'voucherProblem',
	b2bFile= 'b2bFile',
	notB2bFile= 'notB2bFile',
	incorrectHeaderCode = 'incorrectHeaderCode',
	incorrectMessageCode = 'incorrectMessageCode',
	incorrectApplicationCode = 'incorrectApplicationCode',
	incorrectOrderCode = 'incorrectOrderCode',
	incorrectCurrencyCode = 'incorrectCurrencyCode',
	incorrectNumberOfBatches = 'incorrectNumberOfBatches',
	differentChecksums = 'differentChecksums',
	incorrectResubmissionNumber = 'incorrectResubmissionNumber',
	incorrectAwlClient = 'incorrectAwlClient',
	incorrectMemberIdentity = 'incorrectMemberIdentity',
	incorrectFileDigitalSignature = 'incorrectFileDigitalSignature',
	incorrectFileFormat = 'incorrectFileFormat',
	incorrectOperationsNumber = 'incorrectOperationsNumber',
	incorrectOperationsAmount = 'incorrectOperationsAmount',
	duplicateMessageID = 'duplicateMessageID',
	limitReached = 'limitReached',
	formattingError = 'formattingError',
	noPain = 'noPain',
	instantPayments = 'instantPayments'
}
