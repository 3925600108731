import { Injectable, Injector } from '@angular/core';

import { BackendOperation, SearchCriteria, SearchResult } from '@mdib/core/adapters';
import { SecretQuestionsListsService, SecretQuestionsList } from '@mdib/customers';

import { SecretQuestionsListUpdateXcl } from './operations/secret-questions-list-update-xcl';
import { SecretQuestionsListRetrieveXcl } from './operations/secret-questions-list-retrieve-xcl';

@Injectable({
	providedIn: 'root',
})
export class SecretQuestionsListsXclService extends SecretQuestionsListsService {

	constructor(
		private injector: Injector
	) { super(); }

	public create(): BackendOperation<SecretQuestionsList, SecretQuestionsList> {
		throw new Error('Method not implemented');
	}

	public retrieve(): BackendOperation<string, SecretQuestionsList> {
		return new SecretQuestionsListRetrieveXcl(this.injector);
	}

	public update(): BackendOperation<Partial<SecretQuestionsList>, SecretQuestionsList> {
		return new SecretQuestionsListUpdateXcl(this.injector);
	}

	public delete(): BackendOperation<string, SecretQuestionsList> {
		throw new Error('Method not implemented');
	}

	public search(): BackendOperation<SearchCriteria<any>, SearchResult<SecretQuestionsList>> {
		throw new Error('Method not implemented');
	}
}
