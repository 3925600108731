import { SepaDirectDebitPaymentType } from './sepa-direct-debit-payment-type.enum';
import { SepaDirectDebitPaymentStatus } from './sepa-direct-debit-payment-status.enum';
import { Creditor, Debtor, ContractType } from '@mdib/sepa-direct-debits';
import { Communication } from '@mdib/commons';

export class SepaDirectDebitPayment {
	operationDate: Date;
	paymentDate: Date;
	creditorReference: string;
	paymentType: SepaDirectDebitPaymentType;
	transferAmount: number;
	paymentStatus: SepaDirectDebitPaymentStatus;
	paymentReference: string;
	operationCurrency: string;
	contractReference: string;
	creditor: Creditor;
	debtor: Debtor;
	contractNumber: string;
	communication: Communication;
	contractType: ContractType;
}
