import { NgModule } from '@angular/core';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';
import { AccountStatementsMemoryModule } from '@mdib-memory/account-statements';
import { AccountValuationsMemoryModule } from '@mdib-memory/account-valuations';
import { BeneficiariesMemoryModule } from '@mdib-memory/beneficiaries';
import { CashAccountsMemoryModule } from '@mdib-memory/cash-accounts';
import { CustomersMemoryModule } from '@mdib-memory/customers';
import { KeyingLimitsMemoryModule } from '@mdib-memory/keying-limits';
import { MailboxMemoryModule } from '@mdib-memory/mailbox';
import { PaymentFilesMemoryModule } from '@mdib-memory/payment-files';
import { PaymentsMemoryModule } from '@mdib-memory/payments';
import { SessionsMemoryModule } from '@mdib-memory/sessions';
import { ShoppingBasketMemoryModule } from '@mdib-memory/shopping-basket';
import { SignatureMemoryModule } from '@mdib-memory/signature';
import { StandingOrdersMemoryModule } from '@mdib-memory/standing-orders';
import { ForgotPasswordMemoryModule } from '@mdib-memory/forgot-password';
import { ElectronicalStatementsMemoryModule } from '@mdib-memory/electronical-statements/electronical-statements-memory.module';
import { SepaDirectDebitsMemoryModule } from '@mdib-memory/sepa-direct-debits';

@NgModule({
	imports: [
		AccountStatementsMemoryModule,
		AccountValuationsMemoryModule,
		BeneficiariesMemoryModule,
		CashAccountsMemoryModule,
		CustomersMemoryModule,
		ElectronicalStatementsMemoryModule,
		ForgotPasswordMemoryModule,
		KeyingLimitsMemoryModule,
		MailboxMemoryModule,
		PaymentsMemoryModule,
		SepaDirectDebitsMemoryModule,
		PaymentFilesMemoryModule,
		SessionsMemoryModule,
		ShoppingBasketMemoryModule,
		SignatureMemoryModule,
		StandingOrdersMemoryModule,
	],
	providers: [
		{ provide: ADAPTER_MEMORY_IDENTIFIER, useValue: 'memory' },
	]
})
export class AppAdaptersMemoryImports { }
