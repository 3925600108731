import { ContractStatus } from './contract-status.enum';
import { ContractType } from './contract-type.enum';
import { SuspensionPeriod, Debtor, Creditor } from './sepa-direct-debit-typings';

export class SepaDirectDebitContract {
	creditor: Creditor;
	debtor: Debtor;
	contractNumber: string;
	contractStatus: ContractStatus;
	contractType: ContractType;
	contractReference: string;
	signingDate: Date;
	contractStartDate: Date;
	contractEndDate: Date;
	copyRequestDate?: Date;
	suspensionPeriod: SuspensionPeriod;
	singlePayment?: boolean;
	frequencyPeriodEndDate?: Date;
	numberOfPaymentsForPeriod?: number;
	maximumFrequency?: string;
	maximumNumberOfPaymentsForPeriod?: number;
	amountLimitActivation?: boolean;
	maximumAmount?: number;
}
