import { Injectable } from '@angular/core';

import { BankConfig } from '../model/bank-config';
import { CountryCodes, ManagedCurrencyCodes } from '../model/config.enum';
import { FeatureToggles } from '../model/feature-toggles';

@Injectable()
export class ConfigService {

	private readonly _featureToggles = new FeatureToggles();

	/**
	 * Retrieve app toggle features.
	 * @returns FeatureToggles: all the app defined features you can toggle.
	 */
	get featureToggles(): FeatureToggles {
		return this._featureToggles;
	}

	get bankCountry(): CountryCodes {
		return BankConfig.baseCountryCode;
	}

	get bbanLenght(): number {
		return BankConfig.bbanLenght;
	}

	get baseCurrencyCode(): ManagedCurrencyCodes {
		return BankConfig.baseCurrencyCode;
	}

	get ibanMinLenght(): number {
		return BankConfig.ibanMinLength;
	}

	get ibanMaxLength(): number {
		return BankConfig.ibanMaxLength;
	}

	get freeCommunicationMaxLength(): number {
		return BankConfig.freeCommunicationMaxLength;
	}

	get structuredCommunicationMaxLength(): number {
		return 12;
	}

	get europeanCommunicationMaxLength(): number {
		return 23;
	}

	get emailMaxLength(): number {
		return BankConfig.emailMaxLength;
	}

	get nameMaxLength(): number {
		return BankConfig.nameMaxLength;
	}

	get phoneNumberPlaceholder(): string {
		if (!!BankConfig.phoneNumberPlaceholder && !!BankConfig.phoneNumberPlaceholder) {
			// If the bank has configured a default placeholder, use this one. Otherwise, use the predefined ones below.
			return BankConfig.phoneNumberPlaceholder;
		}
		switch (BankConfig.baseCountryCode) {
			case CountryCodes.FR: {
				return '+33612345678';
			}
			case CountryCodes.DE: {
				return '+491234567890';
			}
			case CountryCodes.ES: {
				return '+34612345678';
			}
			case CountryCodes.NL: {
				return '+3161234567';
			}
			case CountryCodes.BE:
			default: {
				return '+32475123456';
			}
		}
	}

	/**
	 * Retrieve URL to robot loan advisor
	 * @returns string: URL to robot loan advisor
	 */
	get robotLoanAdvisorURL(): string {
		return BankConfig.robotLoanAdvisorURL;
	}

	/**
	 * Retrieve account name max length
	 * @returns number: account name max length
	 */
	get accountNameMaxLength(): number {
		return BankConfig.accountNameMaxLength;
	}

	/**
	 * Retrieves the maximum from today delay to ask fro withdrawal simulation.
	 * @returns number: number of months
	 */
	get withdrawalSimulationDelay(): number {
		return BankConfig.withdrawalSimulationDelay;
	}
}
