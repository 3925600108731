import { ModuleWithProviders, NgModule, Inject } from '@angular/core';
import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { AdaptersService } from '@mdib/core/adapters';
import { ForgotPasswordService } from '@mdib/forgot-password';
import { ForgotPasswordXclService } from './service/forgot-password-xcl.service';
import { ForgotPasswordMapperXclService } from './service/forgot-password-mapper-xcl.service';

@NgModule()
export class XclForgotPasswordModule {

	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(ForgotPasswordService, adapterId, ForgotPasswordXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: XclForgotPasswordModule,
			providers: [
				ForgotPasswordXclService,
				ForgotPasswordMapperXclService,
			]
		};
	}
}
