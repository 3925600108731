import { BackendOperation, BackendOperationStep, BackendOperationType } from '@mdib/core/adapters';
import { SepaDirectDebitPayment } from '@mdib/sepa-direct-debits';
import { Observable, of } from 'rxjs';
import { MockSepaDirectDebitPayments } from '../../mock/mock-sepa-direct-debit-payments';

export class SepaDirectDebitPaymentsRetrieveMemory extends BackendOperation<string, SepaDirectDebitPayment> {

	constructor() {
		super(BackendOperationType.RETRIEVE);
	}

	execute(paymentReference: string, trigger?: string): Observable<BackendOperationStep<SepaDirectDebitPayment>> {
		const payment: SepaDirectDebitPayment = MockSepaDirectDebitPayments.getMockSepaDirectDebitPayments().filter((sepaPayment: SepaDirectDebitPayment) => sepaPayment.paymentReference === paymentReference).pop();
		return of(new BackendOperationStep<SepaDirectDebitPayment>(trigger, [], payment));
	}
}
