import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { PasswordAuthenticationService } from './password-authentication.service';
import { PasswordAuthenticationServiceStub } from './password-authentication.service.stub';

@Injectable()
export class PasswordAuthenticationServiceProxy extends AdapterProxy {
	protected ServiceToken() { return PasswordAuthenticationService; }
	protected ServiceDefinition() { return PasswordAuthenticationServiceStub; }
}
