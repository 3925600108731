import { ModuleWithProviders, NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { FileMapperXclService } from '@mdib-xcl/utils';

import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { PdfStatementsXclService } from '@mdib-xcl/pdf-statements/service/pdf-statements-xcl.service';
import { PdfStatementsMapperXclService } from '@mdib-xcl/pdf-statements/service/pdf-statements-mapper-xcl.service';
import { PdfStatementsService } from '@mdib/pdf-statements/service/pdf-statements.service';


@NgModule()
export class PdfStatementsXclModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(PdfStatementsService, adapterId, PdfStatementsXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: PdfStatementsXclModule,
			providers: [
				PdfStatementsMapperXclService,
				FileMapperXclService
			]
		};
	}
}
