import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';

import { LiveTour } from './model/live-tour';
import { LiveTourComponent } from './live-tour/live-tour.component';
import { LiveTourStep } from './model/live-tour-step';
import { InputStep } from './model/input-step';
import { ClickStep } from './model/click-step';
import { ShowPopupStep } from './model/show-popup-step';
import { TargetElementStep } from './model/target-element-step';
import { WaitDomElementStep } from './model/wait-dom-element-step';
import { DirectNextStep } from './model/direct-next-step';
import { WaitEventStep } from './model/wait-event-step';

@Injectable()
export class LiveTourService {

	private tours: Map<string, LiveTour> = new Map<string, LiveTour>();

	private currentTourId: string = null;
	private currentStepId = -1;
	private sharedStream: Subject<Event>;

	private component: LiveTourComponent;

	public start(tourName: string) {
		// No Component set
		if (!this.component) {
			throw new Error('No TourComponent set');
		}

		// Tour already in progress
		if (this.currentStepId >= 0) {
			throw new Error('Tour already in progress');
		}

		// Find Definition
		if (!this.tours.has(tourName)) {
			throw new Error('Wrong tour identifier');
		}

		// Start the tour
		this.currentTourId = tourName;
		this.currentStepId = -1;
		this.component.tour = this.getTour();
		this.sharedStream = new Subject();

		this.sharedStream.subscribe(
			event => this.handleStepEvent(event)
		);
		this.executeNextStep();
	}

	public stop() {
		this.sharedStream.complete();
		this.sharedStream.unsubscribe();
		this.currentTourId = null;
		this.currentStepId = -1;
		this.component.tour = null;
		this.component.step = null;
	}

	public executeNextStep(): Observable<Event> {
		// May be the last one
		this.currentStepId++;
		const tour = this.getTour();
		if (!tour || this.currentStepId >= tour.size()) {
			this.stop();
			return of();
		}

		// Execute the selected step
		return this.executeStep();
	}

	public executeStep(id?: number): Observable<Event> {
		this.component.step = this.getStep(id);
		const events = this.component.step.execute(this, this.sharedStream);
		const sub: Subscription = events.subscribe(
			event => this.sharedStream.next(event),
			error => { },
			() => { if (sub) { sub.unsubscribe(); } }
		);
		return events;
	}

	public handleStepEvent(event: Event) {
		switch (event.type) {
			case 'nextStep':
				this.executeNextStep();
				break;

			case 'endTour':
				this.stop();
				break;
		}
	}

	public setTourComponent(component: LiveTourComponent) {
		this.component = component;

		// TODO Remove this is a test
		const tour: LiveTour = new LiveTour()
			.addStep(new ShowPopupStep('Welcome tour', 'We may take you through a short tour of the application.'))
			// Username
			.addStep(new DirectNextStep(new ShowPopupStep('Welcome tour', 'To login, you need to enter your username.')))
			.addStep(new TargetElementStep('input[name=username]'))
			.addStep(new DirectNextStep(new InputStep('input[name=username]', 'MockAccess', 125)))
			.addStep(new WaitEventStep('stepComplete'))
			// Password
			.addStep(new DirectNextStep(new ShowPopupStep('Welcome tour', 'Then you enter your password')))
			.addStep(new TargetElementStep('input[name=password]'))
			.addStep(new DirectNextStep(new InputStep('input[name=password]', 'password', 125)))
			.addStep(new WaitEventStep('stepComplete'))
			// Submit
			.addStep(new DirectNextStep(new ShowPopupStep('Welcome tour', 'Finally, just submit by clicking on this button')))
			.addStep(new TargetElementStep('form button'))
			.addStep(new DirectNextStep(new ClickStep('form button', 0)))
			.addStep(new DirectNextStep(new TargetElementStep()))
			// Highlight accounts
			.addStep(new DirectNextStep(new ShowPopupStep('Welcome tour', 'We wait for the application to load...')))
			.addStep(new WaitDomElementStep('mdib-cash-accounts-list > div'))
			.addStep(new DirectNextStep(new ShowPopupStep('Welcome tour', 'The dashboard shows your list of accounts')))
			.addStep(new TargetElementStep('mdib-cash-accounts-list > div'))
			;

		this.tours.set('debug', tour);
		document.addEventListener('keyup', (event: KeyboardEvent) => {
			if (event.keyCode === 113) {
				this.start('debug');
			}
		});
	}

	public getTourComponent(): LiveTourComponent {
		return this.component;
	}

	public getTour(id?: string): LiveTour {
		return this.tours.get(id || this.currentTourId);
	}

	public getStep(id?: number): LiveTourStep {
		return this.getTour().getStep(id || this.currentStepId);
	}

	public getCurrentTourId(): string {
		return this.currentTourId;
	}

	public setCurrentStep(id: number): number {
		return (this.currentStepId = id);
	}

	public getCurrentStepId(): number {
		return this.currentStepId;
	}

}
