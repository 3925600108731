import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { ServiceResponse } from '@mdib/utils';
import { KeyingLimitsService, KeyingLimit } from '@mdib/keying-limits';

import { MockKeyingLimits } from '../mock/mock-keying-limits';

@Injectable({
	providedIn: 'root'
})
export class KeyingLimitsMemoryService extends KeyingLimitsService {

	readonly DEFAULT_DELAY = 400;

	public list(index?: number, count?: number): Observable<ServiceResponse<KeyingLimit[]>> {
		const results = MockKeyingLimits.getMockKeyingLimits();
		return of(new ServiceResponse<KeyingLimit[]>(
			results.slice(index || 0, (index + count) || undefined),
			[],
			{ totalCount: results.length },
		)).pipe(delay(this.DEFAULT_DELAY));
	}

	public count(): Observable<ServiceResponse<number>> {
		return of(new ServiceResponse<number>(
			MockKeyingLimits.getMockKeyingLimits().length)).pipe(delay(this.DEFAULT_DELAY));
	}
}
