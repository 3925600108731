import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpStatusManagementService } from './service/http-status-management.service';
import { NotificationMessageModule } from '@mdib/notification-message';
import { UtilsModule } from '@mdib/utils';

@NgModule({
	imports: [
		CommonModule,
		NotificationMessageModule,
		UtilsModule,
	],
	declarations: [],
	providers: [
		HttpStatusManagementService,
	],
})
export class HttpModule { }
