import { Injectable } from '@angular/core';
import { NotificationHelper } from '../model/notification-helper';
import { Feedback, ServiceResponse } from '@mdib/utils';
import { NotificationMessage } from '../model/notification-message';
import { NotificationMessageService } from './notification-message.service';

/**
 * My role is to generate and/or send {NotificationMessage} based on {ServiceResponse}
 *
 * @export
 * @class ServiceResponseNotificationMessagesMapperService
 */
@Injectable()
export class ServiceResponseNotificationMessagesMapperService {
	constructor(private notificationMessageService: NotificationMessageService) {
	}

	/**
	 * Builder for notifications
	 * @param {Feedback} feedback
	 * @param {string} [context=''] The context is a string which will be prepend to messages attributes of {NotificationMessage} sent.
	 * @returns {NotificationMessage}
	 * @memberOff ServiceResponseNotificationMessagesMapperService
	 * @example
	 * buildNotification( <Feedback>{key: 'k', defaultMessage: 'd', type: 't', options: {}}, 'c' ) =>
	 *     <NotificationMessage> {
	 *     message: 'ck',
	 *     fallbackMessage: 'd',
	 *     title: 't',
	 *     type: 't',
	 *     messageParams: {}
	 *    }
	 */
	buildNotification(feedback: Feedback, context = ''): NotificationMessage {
		return feedback ? <NotificationMessage>{
			message: 'notification.' + (feedback && feedback.type + '.') + (feedback && feedback.key || ''),
			fallbackMessage: feedback && feedback.defaultMessage || '',
			title: feedback.type || '',
			type: feedback.type || '',
			messageParams: feedback.option || {},
			isClosable: true,
			scrollToMe: true
		} : <NotificationMessage>{};
	}

	/**
	 * Builds a list of {NotificationMessage} based on a list of {Feedback}
	 *
	 * @param {Feedback[]} feedbacks
	 * @param {string} [context=''] The context is a string which will be prepend to messages attributes of {NotificationMessage} sent.
	 * @returns {NotificationMessage[]}
	 * @memberof ServiceResponseNotificationMessagesMapperService
	 */
	buildNotifications(feedbacks: Feedback[], context = ''): NotificationMessage[] {
		if (!NotificationHelper.objectNotEmpty(feedbacks)) { return []; }
		return feedbacks.map((feedback: Feedback) => this.buildNotification(feedback, context));
	}

	/**
	 * Send a list of {NotificationMessage} based on the {Feedback}s hold by a {ServiceResponse}
	 *
	 * @param {ServiceResponse<any>} serviceResponse
	 * @param {string} [context=''] The context is a string which will be prepend to messages attributes of {NotificationMessage} sent.
	 * @memberof ServiceResponseNotificationMessagesMapperService
	 */
	sendResponseFeedbacks(serviceResponse: ServiceResponse<any>, context = ''): void {
		// TODO : still null pointer coming from here
		let feedbacks: Feedback[] = [];
		if (serviceResponse && serviceResponse.getFeedbacks && serviceResponse.getFeedbacks().length > 0 ) {
			feedbacks = serviceResponse.getFeedbacks();
		}
		this.sendFeedbacks(feedbacks, context);
	}

	/**
	 * Sends a list of {Feedback}s as a list of {NotificationMessage}s
	 *
	 * @param {Feedback[]} feedbacks
	 * @param {string} [context=''] The context is a string which will be prepend to messages attributes of {NotificationMessage} sent.
	 * @returns {void}
	 * @memberof ServiceResponseNotificationMessagesMapperService
	 */
	sendFeedbacks(feedbacks: Feedback[], context = ''): void {
		if (!feedbacks.length) { return; }

		this.notificationMessageService.sendMessages(this.buildNotifications(feedbacks, context));
	}

	/**
	 * Sends a single {Feedback} as {NotificationMessage}
	 *
	 * @param {Feedback} feedback
	 * @param {string} [context=''] The context is a string which will be prepend to messages attributes of {NotificationMessage} sent.
	 * @memberof ServiceResponseNotificationMessagesMapperService
	 */
	sendFeedback(feedback: Feedback, context = ''): void {
		if (!feedback || !NotificationHelper.objectNotEmpty(feedback)) { return; }
		this.notificationMessageService.sendMessage(this.buildNotification(feedback, context));
	}

	/**
	 * Clear all notifications
	 * @memberof ServiceResponseNotificationMessagesMapperService
	 */
	clearAll() {
		this.notificationMessageService.clearAll();
	}
}
