import { Injectable, Injector } from '@angular/core';
import { SepaDirectDebitContractsService, SepaDirectDebitContract } from '@mdib/sepa-direct-debits';
import { AbstractOrderXCLModel, FunctionalFeedbacksFromXclOrderExtractor, MultigoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { SepaDirectDebitContractXcl } from '../model/sepa-direct-debit-contract-xcl';
import { SepaDirectDebitContractsMapperXclService } from './sepa-direct-debit-contracts-mapper-xcl.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ServiceResponse } from '@mdib/utils';
import { ParameterModel } from '@mdib/http';
import { BackendOperation, SearchCriteria, SearchResult } from '@mdib/core/adapters';
import { SepaDirectDebitContractsRetrieveXcl } from '../service/operations/sepa-direct-debit-contracts-retrieve-xcl';
import { SepaDirectDebitContractsUpdateXcl } from '../service/operations/sepa-direct-debit-contracts-update-xcl';

@Injectable({
	providedIn: 'root'
})
export class SepaDirectDebitContractsXclService extends SepaDirectDebitContractsService {

	constructor(private sepaDirectDebitContractsMapperXclService: SepaDirectDebitContractsMapperXclService,
		private xclHttpService: XclHttpService,
		private feedbacksExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private injector: Injector
	) {
		super();
	}

	public list(index?: number, count?: number, counterpartyAccountNumber?: string): Observable<ServiceResponse<SepaDirectDebitContract[]>> {
		const params: ParameterModel[] = [
			new ParameterModel('start', (index).toString())
		];

		// When no maxResults parameter is provided, the CBS returns all the values
		if (count != null) {
			params.push(new ParameterModel('maxResults', count.toString()));
		}

		// If account number is selected then search contracts for that account number otherwise provide all the contracts of the user
		if (counterpartyAccountNumber != null) {
			params.push(new ParameterModel('accountNumber', counterpartyAccountNumber));
		}

		const xclObservable = this.xclHttpService.execute('xcl.sepaDirectDebit.contracts', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<SepaDirectDebitContractXcl>>;

		// Extract the sepa direct debit contract list from the root 'goalList' property returned by the XCL
		// Return an array of direct debit contract list (functional model) corresponding to
		// the array of direct debit contract list returned by the XCL (technical model)
		return xclObservable.pipe(mergeMap((xclOrder: MultigoalOrderXCLModel<SepaDirectDebitContractXcl>) => {
			return this.sepaDirectDebitContractsMapperXclService
				.sepaDirectDebitContractListXclToSepaDirectDebitContractList(xclOrder.goalList)
				.pipe(map(list => {
					return new ServiceResponse<SepaDirectDebitContract[]>(
						list,
						this.feedbacksExtractor.extract(xclOrder),
						{ totalCount: xclOrder.rowCount },
					);
				}));
		}));
	}

	public count(counterpartyAccountNumber?: string): Observable<ServiceResponse<number>> {
		const params: ParameterModel[] = [];

		// If account number is selected then search contracts for that account number otherwise provide all the contracts of the user
		if (counterpartyAccountNumber != null) {
			params.push(new ParameterModel('accountNumber', counterpartyAccountNumber));
		}

		return this.xclHttpService.execute('sepa-direct-debit-contracts-count', XclHttpService.GET, params)
			.pipe(map((xclOrder: AbstractOrderXCLModel) =>
				new ServiceResponse<number>(
					xclOrder.rowCount,
					this.feedbacksExtractor.extract(xclOrder),
				)
			));
	}

	public retrieve(): BackendOperation<string, SepaDirectDebitContract> {
		return new SepaDirectDebitContractsRetrieveXcl(this.injector);
	}

	public create(): BackendOperation<SepaDirectDebitContract, SepaDirectDebitContract> {
		throw new Error('Method not implemented.');
	}
	public update(): BackendOperation<Partial<SepaDirectDebitContract>, SepaDirectDebitContract> {
		return new SepaDirectDebitContractsUpdateXcl(this.injector);
	}
	public delete(): BackendOperation<any, SepaDirectDebitContract> {
		throw new Error('Method not implemented.');
	}
	public search(): BackendOperation<SearchCriteria<any>, SearchResult<SepaDirectDebitContract>> {
		throw new Error('Method not implemented.');
	}

}

