import { BackendOperation } from '../operations/backend-operation';
import { SearchCriteria } from '../search/search-criteria';
import { SearchResult } from '../search/search-result';

export interface BackendCreationService<T> {
	create(): BackendOperation<T, T>;
}

export interface BackendRetrieveService<T> {
	retrieve(): BackendOperation<any, T>;
}

export interface BackendUpdateService<T> {
	update(): BackendOperation<Partial<T>, T>;
}

export interface BackendDeletionService<T> {
	delete(): BackendOperation<any, T>;
}

export interface BackendSearchService<T> {
	search(): BackendOperation<SearchCriteria<any>, SearchResult<T>>;
}

export type BackendFullService<T>
	= BackendCreationService<T>
	& BackendRetrieveService<T>
	& BackendUpdateService<T>
	& BackendDeletionService<T>
	& BackendSearchService<T>;

export abstract class BackendService<T> implements BackendFullService<T> {
	public abstract create(): BackendOperation<T, T>;
	public abstract retrieve(): BackendOperation<any, T>;
	public abstract update(): BackendOperation<Partial<T>, T>;
	public abstract delete(): BackendOperation<any, T>;
	public abstract search(): BackendOperation<SearchCriteria<any>, SearchResult<T>>;
}
