import { Injectable } from '@angular/core';
import { DigipassAuthenticationService } from './digipass-authentication.service';
import { Observable, of } from 'rxjs';
import { ServiceResponse } from '@mdib/utils';
import { Authentication } from '@mdib/sessions';

@Injectable()
export class DigipassAuthenticationServiceStub extends DigipassAuthenticationService {

	public authenticate(username: string, digipassResponse: string): Observable<ServiceResponse<Authentication>> {
		return undefined;
	}

	public loadChallenge(): Observable<ServiceResponse<string>> {
		return of();
	}

}
