import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';

import { AbstractOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { AccessRightTokenXcl, AccessRightTokenXclDefinition } from '@mdib-xcl/utils';

import { AuthorizationsService } from '@mdib/core/security';

import { ArtificialPerson, NaturalPerson } from '@mdib/customers';
import { Authentication, UserBuilder, BusinessRole } from '@mdib/sessions';
import { ConfigurationService, ServiceResponse } from '@mdib/utils';
import { TranslateService } from '@ngx-translate/core';
// TODO Load dynamically using generic loader
import * as XclRolesMapping from 'assets/configuration/security/authorizations-mapping.json';
import { BusinessRolesXcl } from '../model/sessions-types';
import { mergeMap, map, tap } from 'rxjs/operators';
import { ParameterSetsService } from '@mdib/core/customization';

@Injectable()
export class AuthenticationXclService {

	protected AUTHENTICATION_TYPE: string;
	protected readonly roles;

	private xclRolesMapping: any;

	constructor(
		protected xclHttpService: XclHttpService,
		protected configurationService: ConfigurationService,
		protected authorizationsService: AuthorizationsService,
		protected translateService: TranslateService,
		protected config: ConfigurationService,
		protected parameterSetsService: ParameterSetsService,
	) {
		this.roles = this.config.instant('user.roles');
	}

	protected prepareAuthentication(username: string): Observable<ServiceResponse<Authentication>> {
		// Request body
		const initBody = {
			'language': 'nl', // forced for Centric
			'securityContext': {
				'businessChannel': 'B2C',
				'company': this.configurationService.instant('xcl.company.id'),
				'authenticationType': this.AUTHENTICATION_TYPE,
				'communicationMode': this.configurationService.instant('xcl.authentication.communicationMode'),
				'deviceType': 'DSK',
				'project': this.configurationService.instant('xcl.authentication.project'),
			},
			'user': {
				'loginName': username,
			},
		};

		// Create a new authentication
		const authentication = new Authentication();
		authentication.type = 'XCL';
		authentication.close = () => this.disconnect(authentication);
		authentication.keepAlive = () => this.keepAlive(authentication);

		// Calls
		return this.xclHttpService.execute('session-init', XclHttpService.POST, [], initBody).pipe(
			mergeMap(result => {
				// In case a session is still open, close it first
				if (result.state === '4000') {
					return this.xclHttpService
						.execute('session-close', XclHttpService.POST)
						.pipe(mergeMap(order => this.xclHttpService.execute('session-init', XclHttpService.POST, [], initBody)));
				} else {
					return of(result);
				}
			}),
			map(result => {
				return new ServiceResponse(authentication);
			}));
	}

	protected doAuthenticate(authentication: Authentication, authenticationData: any): Observable<ServiceResponse<Authentication>> {
		// Request body
		const authBody = {
			'dataMap': {
				'code': authenticationData,
			},
		};

		// Calls
		return zip(
			this.xclHttpService.execute('session-auth', XclHttpService.POST, [], authBody),
			this.loadParameters(),
		).pipe(
			mergeMap(([result]) => {
				// Fill the authentication
				authentication.user = new UserBuilder(
					{
						id: result['user']['userId'],
						connected: false,
					})
					.setAccessRights(this.doImportAccessRights(result['securityContext']['userProfileList'] || []))
					.setBusinessRole(this.doGetBusinessRole(result['securityContext']['businessRole']))
					.build();

				// Retrieve user data
				return <Observable<AbstractOrderXCLModel[]>>(this.xclHttpService.execute('session-data', XclHttpService.GET, []));
			}),
			map((result: AbstractOrderXCLModel[]) => {
				// Fill the authentication
				authentication.user.connected = true;
				authentication.user = new UserBuilder(authentication.user)
					.setPerson(<NaturalPerson | ArtificialPerson>{
						id: result[0]['visitors'][0]['thirdPartyNumber'],
						name: result[0]['visitors'][0]['thirdPartyShortName'],
					}).build();

				// Success
				return new ServiceResponse(authentication);
			})
		);

	}

	protected disconnect(authentication: Authentication): Observable<any> {
		return this.xclHttpService.execute('session-close', XclHttpService.POST).pipe(map(() => true));
	}

	protected keepAlive(authentication: Authentication): Observable<any> {
		return this.xclHttpService.execute('session-keepAlive', XclHttpService.GET).pipe(map(() => true));
	}

	protected doImportAccessRights(roles: Array<any>): Array<string | AccessRightTokenXclDefinition> {
		// Flush all the previous access rights
		this.authorizationsService.flushAccessRights();

		// Convert XCL roles to Front Access Rights
		const accessRights: Array<string | AccessRightTokenXclDefinition> = [];
		roles.forEach(role => {
			const roleMapping: Array<string | AccessRightTokenXclDefinition> = this.xclRolesMapping[role.roleName] || [];
			roleMapping.forEach(rightDef => {
				accessRights.push(rightDef);
				this.authorizationsService.addAccessRight(new AccessRightTokenXcl(rightDef));
			});
		});
		return accessRights;
	}

	protected doGetBusinessRole(businessRoleId: string): BusinessRole {

		if (!businessRoleId || BusinessRolesXcl.b2c.indexOf(businessRoleId) >= 0) {
			return <BusinessRole>{
				id: businessRoleId,
				name: this.roles.b2c,
			};

		} else if (BusinessRolesXcl.b2b.indexOf(businessRoleId) >= 0) {
			return <BusinessRole>{
				id: businessRoleId,
				name: this.roles.b2b,
			};
		}
		return <BusinessRole>{
			id: businessRoleId,
			name: this.roles.b2c,
		};
	}

	private loadParameters(): Observable<null> {
		return this.parameterSetsService.get('xcl/authorizations').pipe(
			tap((parameters: any) => {
				this.xclRolesMapping = parameters;
			}),
			map(() => null)
		);
	}

}
