import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AsideComponent } from '@mdib/aside/component/aside/aside.component';
import { AccountValuationsPageComponent } from './component/account-valuations-page/account-valuations-page.component';
import { FooterComponent } from '@mdib/footer/component/footer/footer.component';
import { HeaderComponent } from '@mdib/header/component/header/header.component';
import { SessionGuardService } from '@mdib/sessions';

const routes: Routes = [
	{
		path: 'account-valuations',
		canActivate: [SessionGuardService],
		data: { authorizedTokens: ['accounts.valuations.list'] },
		children: [
			{ path: '', component: HeaderComponent, outlet: 'header' },
			{ path: '', component: AsideComponent, outlet: 'aside' },
			{ path: '', component: FooterComponent, outlet: 'footer' },
			{
				path: '',
				component: AccountValuationsPageComponent,
				children: [
					{ path: '', component: AccountValuationsPageComponent, pathMatch: 'full' },
				],
			},
			{ path: ':accountNumber', component: AccountValuationsPageComponent, pathMatch: 'full' }
		],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AccountValuationsRoutingModule {
}
