import { HttpResponseModel } from './http-response-model';

export class HttpStatusActionModel {
	private _httpStatus: RegExp = /.*/;
	private _endpoint: RegExp = /.*/;

	public execute(httpStatus: HttpResponseModel) {
		if (this.matchHttpStatus(httpStatus.status) && this.matchEndPoint(httpStatus.endpoint)) {
			this._action();
		}
	}

	set action(value: Function) {
		if (!value) {
			throw new SyntaxError('Function action must be different from undefined/null');
		} else {
			this._action = value;
		}
	}

	set httpStatus(value: string | number | RegExp) {
		if (value instanceof RegExp) {
			this._httpStatus = value;
		} else if (value) {
			this._httpStatus = new RegExp(value.toString());
		}

	}

	set endpoint(value: string | RegExp) {
		if (value instanceof RegExp) {
			this._endpoint = value;
		} else if (value) {
			this._endpoint = new RegExp(value.toString());
		}
	}


	public equals(errorAction: HttpStatusActionModel) {
		if (!errorAction) {
			return false;
		}

		let isHttpStatusEqual = false;
		if (this._httpStatus && errorAction._httpStatus) {
			isHttpStatusEqual = this._httpStatus.toString() === errorAction._httpStatus.toString();
		}

		let isEndpointEqual = false;
		if (this._endpoint && errorAction._endpoint) {
			isEndpointEqual = this._endpoint.toString() === errorAction._endpoint.toString();
		}

		let isActionEqual = false;
		if (this._action && errorAction._action) {
			isActionEqual = this._action.toString() === errorAction._action.toString();
		}

		return isHttpStatusEqual && isEndpointEqual && isActionEqual;
	}

	private _action: Function = () => { };

	private matchHttpStatus(httpStatus: string): boolean {
		let matched = false;
		if (!this._httpStatus) {
			matched = true;
		} else {
			matched = this._httpStatus.test(httpStatus);
		}
		return matched;
	}

	private matchEndPoint(endpoint: string): boolean {
		let matched = false;
		if (!this._endpoint) {
			matched = true;
		} else {
			matched = this._endpoint.test(endpoint);
		}
		return matched;
	}
}
