import { Injectable } from '@angular/core';

import { BeneficiaryV2 as Beneficiary } from '@mdib/beneficiaries';

import { XclBeneficiaryModel } from '../../model/xcl-beneficiary-model';
import { BeneficiaryTypeMapperService } from './beneficiary-type-mapper.service';
import { BeneficiaryAddressMapperService } from './beneficiary-address-mapper.service';
import { BeneficiaryAccountMapperService } from './beneficiary-account-mapper.service';
import { BeneficiaryCommunicationMapperService } from './beneficiary-communication-mapper.service';

@Injectable({ providedIn: 'root' })
export class BeneficiaryMapperService {

	constructor(
		private beneficiaryTypeMapperService: BeneficiaryTypeMapperService,
		private beneficiaryAddressMapperService: BeneficiaryAddressMapperService,
		private beneficiaryAccountMapperService: BeneficiaryAccountMapperService,
		private beneficiaryCommunicationMapperService: BeneficiaryCommunicationMapperService,
	) { }

	public fromXcl(value: XclBeneficiaryModel): Beneficiary {
		return new Beneficiary({
			id: value.beneficiaryReference,
			type: this.beneficiaryTypeMapperService.fromXcl(value.beneficiaryType),
			fullName: value.beneficiaryName,
			alias: value.beneficiaryIdentification,
			account: this.beneficiaryAccountMapperService.fromXcl(value),
			communication: this.beneficiaryCommunicationMapperService.fromXcl(value),
			address: this.beneficiaryAddressMapperService.fromXcl(value),
		});
	}

	public toXcl(value: Beneficiary, target?: XclBeneficiaryModel): XclBeneficiaryModel {
		target = target || <any>{};

		// Fill own properties
		target.beneficiaryReference = value.id;
		target.beneficiaryType = this.beneficiaryTypeMapperService.toXcl(value.type);
		target.beneficiaryName = value.fullName;
		target.beneficiaryIdentification = value.alias;

		// Filled subs
		this.beneficiaryAccountMapperService.toXcl(value.account, target);
		this.beneficiaryAddressMapperService.toXcl(value.address, target);
		this.beneficiaryCommunicationMapperService.toXcl(value.communication, target);

		return target;
	}
}
