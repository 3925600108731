import { Subscription, Observable, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DisplayedNotification } from './displayed-notification';
import { NotificationMessage } from './notification-message';
import { ElementRef } from '@angular/core';

export class SynchronizedStreamAndNotificationList {
	private stream: Subscription;
	private notificationsBuffer: Array<DisplayedNotification>;
	private translateService: TranslateService;
	private elRef: ElementRef;

	constructor(stream: Observable<NotificationMessage>, translateService: TranslateService, elRef: ElementRef) {
		this.translateService = translateService;
		this.stream = stream.subscribe(this.handleReceivedMessage);
		this.notificationsBuffer = [];
		this.elRef = elRef;
	}

	public unsubscribe() {
		this.stream.unsubscribe();
	}

	public getNotifications(): Array<DisplayedNotification> {
		return this.notificationsBuffer;
	}

	public deleteNotification(notification: DisplayedNotification): void {
		const notificationIndex = this.notificationsBuffer.indexOf(notification);

		if (notificationIndex >= 0) {
			this.notificationsBuffer.splice(notificationIndex, 1);
		}
	}

	public clearList() {
		this.notificationsBuffer.length = 0;
	}

	private handleReceivedMessage: (message: NotificationMessage) => void =
		(message: NotificationMessage) => {
			if (!message || !message.type) { return; }

			if (message.clear) {
				this.clearList();
			} else {
				const displayedNotification: DisplayedNotification = new DisplayedNotification(message, this.translateService);
				if (!this.isAlreadyInBuffer(displayedNotification)) {
					this.notificationsBuffer.push(displayedNotification);
					this.setNotificationLifeTime(displayedNotification);
				}
			}
		}

	private isAlreadyInBuffer(newDisplayedNotification: DisplayedNotification): boolean {
		return this.notificationsBuffer.some((displayedNotification) => {
			return newDisplayedNotification.equals(displayedNotification);
		});
	}

	private setNotificationLifeTime(notification: DisplayedNotification) {
		if (notification.hasTimeOut) {
			timer(notification.timeOut).subscribe(
				() => {
					this.deleteNotification(notification);
				}
			);
		}
	}

}
