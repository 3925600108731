import { OnInit, Injectable } from '@angular/core';
import { Unsubscribable } from 'rxjs';

import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { Feedback, FeedbackTypes } from '@mdib/utils';

import { BackendRetrieveService } from '../../types/backends/backend-service';

export abstract class RetrievePageComponent<T> implements OnInit {

	public model: T;

	public isLoadingData = false;

	protected itemIdentifier;
	protected subscription: Unsubscribable;

	public constructor(
		protected backendService: BackendRetrieveService<T>,
		protected notificationsMapper: ServiceResponseNotificationMessagesMapperService,
	) { }

	public ngOnInit(): void {
		this.initializeModel();
	}

	protected initializeModel(): void {
		this.isLoadingData = true;

		const retrieveOperation = this.backendService.retrieve();
		retrieveOperation.execute(this.itemIdentifier).subscribe(
			(step) => {
				this.notificationsMapper.sendFeedbacks(step.feedbacks);
				this.model = step.result;
				this.isLoadingData = false;
			},
			(error) => this.handleOperationError(error)
		);
	}

	protected handleOperationError(error: any): void {
		this.notificationsMapper.clearAll();
		if (error.feedbacks) {
			this.notificationsMapper.sendFeedbacks(error.feedbacks);
		} else {
			this.notificationsMapper.sendFeedback(new Feedback('unexpectedError', FeedbackTypes.BACKEND_ERROR, 'An unexpected error occured'));
		}
	}
}
