import { isNullOrUndefined, isUndefined } from 'util';
import { ForgotPasswordModel } from '../model/forgot-password-model';
import { SecretQuestion } from '@mdib/customers';

export class ForgotPasswordModelBuilder {
	private _forgotPasswordModel: ForgotPasswordModel;

	constructor(forgotPasswordModel?: ForgotPasswordModel) {
		this._forgotPasswordModel = isNullOrUndefined(forgotPasswordModel) ? new ForgotPasswordModel() : forgotPasswordModel;
	}

	/**
	 * Initiates a builder based on an empty {@link ForgotPasswordModel}
	 * @returns {ForgotPasswordModelBuilder}
	 */
	static empty(): ForgotPasswordModelBuilder {
		return new ForgotPasswordModelBuilder();
	}

	/**
	 * Initiates a builder based on an already existing {@link ForgotPasswordModel}.
	 * The {@link ForgotPasswordModel} will be cloned.
	 *
	 * @param {ForgotPasswordModel} forgotPasswordModel
	 * @returns {ForgotPasswordModelBuilder}
	 */
	static from(forgotPasswordModel: ForgotPasswordModel): ForgotPasswordModelBuilder {
		return new ForgotPasswordModelBuilder(forgotPasswordModel);
	}

	public userId(value: string): ForgotPasswordModelBuilder {
		if (!isUndefined(value)) {
			this._forgotPasswordModel.userId = value;
		}
		return this;
	}

	public secretQuestions(value: SecretQuestion[]): ForgotPasswordModelBuilder {
		if (!isUndefined(value)) {
			this._forgotPasswordModel.secretQuestions = value;
		}

		return this;
	}

	get forgotPasswordModel(): ForgotPasswordModel {
		return this._forgotPasswordModel;
	}
}
