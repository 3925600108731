import { MdibFile, FileStatus } from '@mdib/utils';
import { AccountStatementStatus } from '@mdib/account-statements';
import { ElectronicalStatementsFormat, ElectronicalStatement } from '@mdib/electronical-statements';


export class MockElectronicalStatements {

	private _mockElectronicalStatements: ElectronicalStatement[]  = [
		<ElectronicalStatement>{
			identifier: '0003',
			accountNumber: 'BE56305177635588',
			sequenceNumber: '0000',
			statementNumber: 3,
			statementNumberYear: 2018,
			status: AccountStatementStatus.New,
			format: ElectronicalStatementsFormat.CAMT053,
			firstStatementDate: new Date(2018, 2, 1),
			lastStatementDate: new Date(2018, 2, 31),
			generationDate: new Date(2018, 2, 22),
			file: <MdibFile> {
				id: '1091',
				status: FileStatus.Available,
				name: 'BE56305177635588_20180322_CAMT053.xml',
				document : new Blob(['/assets/documents/sampleXml1.xml'], {type: 'application/xml'})
			}
		},
			<ElectronicalStatement>{
			identifier: '0001',
			accountNumber: 'BE56305177635588',
			sequenceNumber: '0000',
			statementNumber: 1,
			statementNumberYear: 2018,
			status: AccountStatementStatus.New,
			format: ElectronicalStatementsFormat.CAMT053,
			firstStatementDate: new Date(2018, 0, 1),
			lastStatementDate: new Date(2018, 0, 30),
			generationDate: new Date(2018, 1, 22),
			file: <MdibFile> {
				id: '1091',
				status: FileStatus.Available,
				name: 'BE56305177635588_20180122_CAMT053.xml',
				document : null
			}
		},
		<ElectronicalStatement>{
			identifier: '0002',
			accountNumber: 'BE95750642304658',
			sequenceNumber: '0000',
			statementNumber: 2,
			statementNumberYear: 2018,
			status: AccountStatementStatus.New,
			format: ElectronicalStatementsFormat.CAMT053,
			firstStatementDate: new Date(2018, 1, 1),
			lastStatementDate: new Date(2018, 1, 28),
			generationDate: new Date(2018, 2, 22),
			file: <MdibFile> {
				id: '1091',
				status: FileStatus.Available,
				name: 'BE56305177635588_20180222_CAMT053.xml',
				document : null
			}
		},
		<ElectronicalStatement>{
			identifier: '0011',
			accountNumber: 'BE95750642304658',
			sequenceNumber: '0000',
			statementNumber: 2,
			statementNumberYear: 2018,
			status: AccountStatementStatus.New,
			format: ElectronicalStatementsFormat.MT940,
			firstStatementDate: new Date(2018, 1, 1),
			lastStatementDate: new Date(2018, 1, 28),
			generationDate: new Date(2018, 2, 22),
			file: <MdibFile> {
				id: '1091',
				status: FileStatus.Available,
				name: 'BE56305177635588_20180222_MT940.txt',
				document : null
			}
		},
		<ElectronicalStatement>{
			identifier: '0010',
			accountNumber: 'BE56305177635588',
			sequenceNumber: '0000',
			statementNumber: 1,
			statementNumberYear: 2018,
			status: AccountStatementStatus.New,
			format: ElectronicalStatementsFormat.MT940,
			firstStatementDate: new Date(2018, 0, 1),
			lastStatementDate: new Date(2018, 0, 30),
			generationDate: new Date(2018, 1, 22),
			file: <MdibFile> {
				id: '1091',
				status: FileStatus.Available,
				name: 'BE56305177635588_20180122_MT940.txt',
				document : null
			}
		}
	];

	get mockElectronicalStatements (): ElectronicalStatement[] {
		return this._mockElectronicalStatements;
	}
}
