import { Component, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AdapterSwitchService } from '@mdib/core/adapters';
import { AuthenticationProcessComponent, Authentication } from '@mdib/sessions';
import { CommonValidator, ConfigurationService } from '@mdib/utils';

import { OtpAuthenticationService } from '../../service/otp-authentication.service';

@Component({
	selector: 'mdib-otp-authentication-process',
	templateUrl: './otp-authentication-process.component.html',
	styleUrls: ['./otp-authentication-process.component.scss'],
})
export class OtpAuthenticationProcessComponent extends AuthenticationProcessComponent implements OnInit {

	public NAMESPACE = 'sessionsModesModule.otpAuthenticationProcessComponent.';

	/**
	 * @property Indicates if the curent process is valid
	 */
	public failed = false;

	public otpAuthenticationFormGroup: FormGroup;

	public isSmsSent = false;

	private authentication: Authentication;

	constructor(
		private otpAuthenticationService: OtpAuthenticationService,
		private formBuilder: FormBuilder,
		private configurationService: ConfigurationService,
		@Optional() private adapterSwitchService: AdapterSwitchService,
	) {
		super();
		this.otpAuthenticationFormGroup = this.formBuilder.group({
			username: ['', CommonValidator.required],
			answerCode: ['', CommonValidator.required],
		});
	}

	public ngOnInit() {
		// Reset view
		this.answerCode = '';
		this.username = this.user && this.user.person.name;
		this.failed = false;
	}

	public sendOtp() {
		this.isSmsSent = true;
		this.otpAuthenticationService
			.initAuth(this.username)
			.subscribe(response => {
				this.authentication = response.getModel();
				this.onSuccess.emit(response.getModel());
			}, error => {
				// Form is in error
				this.handleError(error);
			}, () => {
				this.otpAuthenticationFormGroup.enable();
			}
			);
	}

	public authenticate() {
		// Trim the useless trailing spaces of the username
		const username = this.otpAuthenticationFormGroup.get('username').value || '';
		this.otpAuthenticationFormGroup.get('username').setValue(username.trim());

		if (this.otpAuthenticationFormGroup.invalid) {
			return;
		}

		// TODO Find another way to switch Mock ON/OFF
		if (this.adapterSwitchService) {
			this.adapterSwitchService.switchForUser(this.username);
		}

		// Disable the form while we try to log in. It will be enabled again once the call has been done
		this.otpAuthenticationFormGroup.disable();

		// Try to authenticate
		this.otpAuthenticationService
			.authenticate(this.authentication, this.answerCode)
			.subscribe(response => {
				this.onSuccess.emit(response.getModel());
			}, error => {
				// Form is in error
				this.handleError(error);
			}, () => {
				this.otpAuthenticationFormGroup.enable();
			}
			);
	}

	public cancel() {
		this.onEvent.emit(new Event('abort'));
	}

	private handleError(error: any) {
		this.failed = true;
		this.onEvent.emit(new ErrorEvent('error', { error: error }));
		this.otpAuthenticationFormGroup.enable();
	}

	get answerCode(): string {
		return this.otpAuthenticationFormGroup.get('answerCode').value;
	}

	get username(): string {
		return this.otpAuthenticationFormGroup.get('username').value;
	}

	set answerCode(answerCode: string) {
		this.otpAuthenticationFormGroup.patchValue({ 'answerCode': answerCode });
	}

	set username(username: string) {
		this.otpAuthenticationFormGroup.patchValue({ 'username': username });
	}
}
