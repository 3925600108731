import { Person } from '@mdib/customers';
import { BusinessRole } from '@mdib/sessions';

export class User {

	constructor(
		// Mandatory
		public pseudo: string,
		public parameters: object = {},
		readonly id: any,
		public connected: boolean,
		// Optional
		readonly businessRole?: BusinessRole,
		readonly accessRights?: Array<any>,
		readonly person?: Person,
	) {
	}
}
