import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { ConfigService } from '@mdib/config';
import { NotificationMessageService, ServiceResponseNotificationMessagesMapperService, NotificationMessage } from '@mdib/notification-message';
import { InternationalPaymentDetails, InternationalPaymentsService, PaymentForm, PaymentOperation, PaymentsService, PaymentTypes, StandingOrderDetails, StandingOrderService, InstantPaymentsService, PaymentOperationBuilder } from '@mdib/payments';
import { ShoppingBasketService } from '@mdib/shopping-basket';
import { CacheService, DataFormattingService, FeedbackTypes, ServiceResponse, Status, UtilsHelper, Feedback, ConfigurationService  } from '@mdib/utils';

import { Observable } from 'rxjs';
import { CashAccount } from '@mdib/cash-accounts';
import { BeneficiaryType, Beneficiary } from '@mdib/beneficiaries';
import { Communication, CommunicationTypes } from '@mdib/commons';

@Component({
	templateUrl: './create-payment-page.component.html',
})
export class CreatePaymentPageComponent implements OnInit {

	get paymentForm(): PaymentForm {
		return this._paymentForm;
	}

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.';

	readonly paymentTypes = PaymentTypes;
	readonly Status = Status;
	public errorMessage: string = null;

	actionRequestOngoing = false;
	showSignature: boolean;
	selectedStep = 0;

	private _paymentForm: PaymentForm;

	constructor(
		private paymentsService: PaymentsService,
		private standingOrderService: StandingOrderService,
		private internationalPaymentsService: InternationalPaymentsService,
		private route: ActivatedRoute,
		private notifications: NotificationMessageService,
		private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService,
		private cacheService: CacheService,
		private shoppingBasketService: ShoppingBasketService,
		private instantPaymentsService: InstantPaymentsService,
		private notificationMessageService: NotificationMessageService,
		private configService: ConfigService
	) {
		this.paymentFormInit(null, true);
	}

	resetStepper(stepper: MatStepper, paymentFormTypes: PaymentTypes) {
		stepper.reset();
		this.paymentFormInit(null, true);
		this.paymentForm.type.setValue(paymentFormTypes);
		this.notifications.clearAll();
	}

	selectionChange(event: StepperSelectionEvent) {
		this.selectedStep = event.selectedIndex;
		if (this.selectedStep === 0) {
			this.paymentFormInit(this.paymentForm.model, true);
			setTimeout(() => this._paymentForm.enable()); // Hack
		} else {
			this._paymentForm.disable();
		}
		UtilsHelper.scrollTo();
	}

	ngOnInit() {
		const paymentOperationReferenceToDuplicate = this.route.snapshot.paramMap.get('reference');
		if (paymentOperationReferenceToDuplicate) {
			this.paymentsService.get(paymentOperationReferenceToDuplicate, PaymentTypes.sepaCreditTransfer).subscribe(
				(serviceResponse: ServiceResponse<PaymentOperation<null>>) => {
					this.paymentFormInit(serviceResponse.getModel());
					this.treatResponse(serviceResponse);
				},
				(serviceResponseError) => this.treatResponse(serviceResponseError));
		}

		// This checks for url context and sends it as an output to the payment form
		this.route.queryParams.subscribe(params => {
			if (UtilsHelper.objectNotEmpty(params)) {
				const parameters = JSON.parse(atob(params['context']));
				const payment = new PaymentOperationBuilder<any>(
					<PaymentOperation<null>>{
						ordererAccount: new CashAccount(parameters.cashAccount),
						counterParty: new Beneficiary({type: BeneficiaryType.INTERNAL, communication: new Communication({type: CommunicationTypes.free})}),
						type: PaymentTypes.sepaCreditTransfer,
						currency: this.configService.baseCurrencyCode
					}
				).paymentOperation;
				this.paymentFormInit(payment, true);
			}
		});

	}

	validateOperation(stepper: MatStepper) {
		this.notifications.clearAll();
		this.actionRequestOngoing = true;
		const subscribeToValidate = (validateMethod: Observable<ServiceResponse<PaymentOperation<any | null>>>) => {
			validateMethod.subscribe(
				(serviceResponse: ServiceResponse<PaymentOperation<null>>) => {
					this.paymentFormInit(serviceResponse.getModel());
					this.treatResponse(serviceResponse);
					stepper.next();
				},
				(serviceResponseError) => this.treatResponse(serviceResponseError));
		};
		switch (this.paymentForm.type.value) {
			case PaymentTypes.sepaCreditTransfer:
				subscribeToValidate(this.paymentsService.validatePayment(this.paymentForm.model));
				break;
			case PaymentTypes.europeanStandingOrder:
				subscribeToValidate(this.standingOrderService.validate(this.paymentForm.model));
				break;
			case PaymentTypes.internationalPayment:
				subscribeToValidate(this.internationalPaymentsService.validate(this.paymentForm.model));
				break;
			case PaymentTypes.urgentPayment:
				subscribeToValidate(this.internationalPaymentsService.validate(this.paymentForm.model));
				break;
			case PaymentTypes.instantPayment:
				subscribeToValidate(this.instantPaymentsService.validate(this.paymentForm.model));
				break;
			default:
				break;
		}
	}

	confirmOperation(stepper: MatStepper) {
		this.notifications.clearAll();
		const subscribeToConfirm = (confirmMethod: Observable<ServiceResponse<PaymentOperation<any | null>>>) => {
			confirmMethod.subscribe(
				(serviceResponse: ServiceResponse<PaymentOperation<null>>) => {
					this.paymentFormInit(serviceResponse.getModel());
					this.treatResponse(serviceResponse);
					this.shoppingBasketService.reloadOperations();
					stepper.next();
				},
				(serviceResponseError) => this.treatResponse(serviceResponseError));
		};
		switch (this.paymentForm.type.value) {
			case PaymentTypes.sepaCreditTransfer:
				subscribeToConfirm(this.paymentsService.confirmPayment(this.paymentForm.model));
				break;
			case PaymentTypes.europeanStandingOrder:
				subscribeToConfirm(this.standingOrderService.confirm(this.paymentForm.model));
				break;
			case PaymentTypes.internationalPayment:
				subscribeToConfirm(this.internationalPaymentsService.confirm(this.paymentForm.model));
				break;
			case PaymentTypes.urgentPayment:
				subscribeToConfirm(this.internationalPaymentsService.confirm(this.paymentForm.model));
				break;
			case PaymentTypes.instantPayment:
				subscribeToConfirm(this.instantPaymentsService.confirm(this.paymentForm.model));
				break;
			default:
				break;
		}
	}

	signOperation() {
		this.notifications.clearAll();
		this.showSignature = true;
	}

	handleSignatureSuccess(stepper: MatStepper): void {
		this.showSignature = false;
		this.actionRequestOngoing = true;
		const subscribeToSign = (signMethod: Observable<ServiceResponse<PaymentOperation<any | null>>>) => {
			signMethod.subscribe(
				(serviceResponse: ServiceResponse<PaymentOperation<null>>) => {
					this.paymentFormInit(serviceResponse.getModel());
					if (serviceResponse.getModel().saveBeneficiary === true) {
						this.cacheService.invalidate('beneficiaries');
					}
					this.treatResponse(serviceResponse);
					stepper.next();
				},
				(serviceResponseError) => this.treatResponse(serviceResponseError));
		};
		switch (this.paymentForm.type.value) {
			case PaymentTypes.sepaCreditTransfer:
				subscribeToSign(this.paymentsService.signPayment(this.paymentForm.model));
				break;
			case PaymentTypes.europeanStandingOrder:
				subscribeToSign(this.standingOrderService.sign(this.paymentForm.model));
				break;
			case PaymentTypes.internationalPayment:
				subscribeToSign(this.internationalPaymentsService.sign(this.paymentForm.model));
				break;
			case PaymentTypes.urgentPayment:
				subscribeToSign(this.internationalPaymentsService.sign(this.paymentForm.model));
				break;
			case PaymentTypes.instantPayment:
				subscribeToSign(this.instantPaymentsService.sign(this.paymentForm.model));
				break;
			default:
				break;
		}
		this.cacheService.invalidate('accounts');
	}

	handleSignatureErrorsOrAbort(feedback: Feedback): void {
		this.serviceResponseNotifMapper.sendFeedback(feedback);
		this.showSignature = false;
	}

	paymentFormInit(payment?: PaymentOperation<any>, subscribe = false) {
		this._paymentForm = new PaymentForm(new FormBuilder(), new DataFormattingService(), new ConfigService(), subscribe, !!payment ? payment : null);
	}

	private treatResponse(serviceResponse: ServiceResponse<any>) {
		if (serviceResponse.getModel() && serviceResponse.getModel().error) {
			if (serviceResponse.getModel().error.errorCode === 'XXXX0001' && serviceResponse.getModel().error.fieldError === 'countryCode_1') {
				this.pushCountryMandatoryError();
			}
		}
		this.serviceResponseNotifMapper.sendFeedbacks(serviceResponse.getFeedbacksByType('error'), this.NAMESPACE);
		this.actionRequestOngoing = false;
		UtilsHelper.scrollTo();
	}

	private pushCountryMandatoryError(): void { // Overrides the message coming from the backend.
		setTimeout(() => {
			const BICMessage: NotificationMessage = <NotificationMessage>{
				title: 'notification.message.' + FeedbackTypes.FRONTEND_ERROR,
				type: FeedbackTypes.FRONTEND_ERROR,
				message: 'notification.message.error_XXXX0001_country',
				scrollToMe: true
			};
			this.notificationMessageService.sendMessage(BICMessage);
		}, 1);
	}
}
