import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { StandingOrdersService } from './standing-orders.service';
import { StandingOrdersServiceStub } from './standing-orders.service.stub';

@Injectable()
export class StandingOrdersServiceProxy extends AdapterProxy {
	protected ServiceToken() { return StandingOrdersService; }
	protected ServiceDefinition() { return StandingOrdersServiceStub; }
}
