import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StylingModule } from '@mdib/styling';
import { TranslateModule } from '@ngx-translate/core';

import { SessionsModule } from '@mdib/sessions';
import { UtilsModule } from '@mdib/utils';

import { SessionsRoutingModule } from './sessions-routing.module';
import { SessionCreationPageComponent } from './page/session-creation-page/session-creation-page.component';
import { LanguagePickerComponent } from './component/language-picker/language-picker.component';
import { SessionLogoutPageComponent } from './page/session-logout-page/session-logout-page.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		SessionsRoutingModule,
		SessionsModule,
		TranslateModule,
		UtilsModule,
		StylingModule,
	],
	declarations: [
		SessionCreationPageComponent,
		LanguagePickerComponent,
		SessionLogoutPageComponent,
	],
	exports: [
		SessionsRoutingModule,
		LanguagePickerComponent,
	]
})
export class SessionsPagesModule { }
