import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { SearchCriteriaCompiler, SearchCriteria } from '@mdib/core/adapters';
import { all, page, and, contains, equals } from '@mdib/core/adapters/criteria';

export type XclFieldMapper = (fieldName: string, fieldValue: any) => Observable<{ field: string; value: any }>;
export type SearchCriteriaXcl = (fieldMapper: XclFieldMapper) => Observable<any>;
export const SearchOperatorsXcl: Map<Function, SearchCriteriaCompiler<SearchCriteriaXcl>> = new Map();

/**
 * No filtering
 */
function allXcl(): SearchCriteriaXcl {
	return () => of({});
}

/**
 * XCL implementation of the AND Search Criterium
 */
function andXcl(...criteria: Array<SearchCriteria<SearchCriteriaXcl>>): SearchCriteriaXcl {
	// Compile criteria
	const compiled = criteria.map(criterium => criterium.compile(SearchOperatorsXcl));

	// Wait for all criteria then combine the results
	return (fieldMapper) => {
		const observables = compiled.map(fn => fn(fieldMapper));
		const joined = observables.length ? zip(...observables) : of([]);
		return joined.pipe(
			map(results => {
				return results.reduce((o, n) => Object.assign(o, n), {});
			})
		);
	};
}

/**
 * Xcl Pagination
 * @param criterium Criterium to apply the pagination to
 * @param pageSize Number of items per page
 * @param pageIndex Curent page index starting at 0
 */
function pageXcl(criterium: SearchCriteria<SearchCriteriaXcl>, index: number, count: number): SearchCriteriaXcl {
	// Compile criteria
	const compiled = criterium.compile(SearchOperatorsXcl);

	// Add pagination to the criterium result
	return (fieldMapper) => compiled(fieldMapper).pipe(map(result => {
		result.maxResults = count;
		result.start = index;
		return result;
	}));
}

/**
 * Xcl implementation of the EQUALS Search Criterium
 */
function equalsXcl(fieldName: string, value: any, caseSensistive: boolean): SearchCriteriaXcl {
	return (fieldMapper) => fieldMapper(fieldName, value).pipe(
		map(mappedField => ({
			[mappedField.field]: mappedField.value
		}))
	);
}

/**
 * Xcl implementation of the CONTAINS Search Criterium
 */
function containsXcl(fieldName: string, value: string, caseSensistive: boolean): SearchCriteriaXcl {
	return (fieldMapper) => fieldMapper(fieldName, value).pipe(
		map(mappedField => ({
			[mappedField.field]: mappedField.value
		}))
	);
}

// Set in map
SearchOperatorsXcl.set(all, allXcl);
SearchOperatorsXcl.set(and, andXcl);
SearchOperatorsXcl.set(page, pageXcl);
SearchOperatorsXcl.set(equals, equalsXcl);
SearchOperatorsXcl.set(contains, containsXcl);
