import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StylingModule } from '@mdib/styling';
import { UtilsModule } from '@mdib/utils';
import { AsideComponent } from './component/aside/aside.component';
import { RouterModule } from '@angular/router';
import { SecurityModule } from '@mdib/core/security';

@NgModule({
	imports: [
		CommonModule,
		StylingModule,
		UtilsModule,
		RouterModule,
		SecurityModule,
	],
	declarations: [
		AsideComponent
	],
	exports: [
		AsideComponent
	],
})
export class AsideModule {}
