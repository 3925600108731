import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mdib-empty',
	template: ''
})
export class EmptyComponent {
	// This is a (dummy) empty component which is meant to show nothing on screen and have no impact on the behaviour.
	// One use of it is to define it as header and footer component when we desire no header or footer.
}
