import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

import { DisplayedNotification } from '../model/displayed-notification';
import { NotificationMessage } from '../model/notification-message';
import { SynchronizedStreamAndNotificationList } from '../model/synchronized-stream-and-notification-list';
import { NotificationMessageService } from '../service/notification-message.service';

@Component({
	templateUrl: './notification-message.html'
})
export class NotifactionMessageComponent implements OnInit, OnDestroy {
	public notificationTypes: Array<string> = [];
	public notificationMessagesBufferList: Map<string, Array<DisplayedNotification>>;

	private streamsAndDisplayedNotificationsByTypes: Map<string, SynchronizedStreamAndNotificationList>;
	private clearAllStream;

	constructor(protected translateService: TranslateService,
		protected notificationMessageService: NotificationMessageService,
		protected elRef: ElementRef) {
		this.streamsAndDisplayedNotificationsByTypes = new Map();
	}

	public ngOnInit(): void {
		this.generateContext();
		this.generateClearAllStream();
	}

	public ngOnDestroy(): void {
		this.deleteContext();
	}

	public closeNotification(type: string, notification: DisplayedNotification): void {
		const syncStreamAndDisplay: SynchronizedStreamAndNotificationList = this.streamsAndDisplayedNotificationsByTypes.get(type);

		if (!syncStreamAndDisplay) { return; }

		syncStreamAndDisplay.deleteNotification(notification);
	}

	public clearAllNotifications() {
		this.streamsAndDisplayedNotificationsByTypes.forEach(
			(value: SynchronizedStreamAndNotificationList) => value.clearList()
		);
	}

	public getNotifications(type: string): Array<DisplayedNotification> {
		const syncStreamAndDisplay: SynchronizedStreamAndNotificationList =
			this.streamsAndDisplayedNotificationsByTypes.get(type);

		if (!syncStreamAndDisplay) { return []; }

		return syncStreamAndDisplay.getNotifications();
	}

	public typeHasNotifications(type: string): boolean {
		const syncStreamAndDisplay = this.streamsAndDisplayedNotificationsByTypes.get(type);

		if (!syncStreamAndDisplay) { return false; }

		return syncStreamAndDisplay.getNotifications().length > 0;
	}

	private generateContext() {
		this.notificationTypes.forEach(this.generateContextForType);
	}

	private deleteContext() {
		this.notificationTypes
			.forEach((type: string) => {
				this.streamsAndDisplayedNotificationsByTypes.get(type).unsubscribe();
				this.clearAllStream.unsubscribe();
				this.streamsAndDisplayedNotificationsByTypes.delete(type);
			});
	}

	private generateContextForType: (type: string) => void = (type: string) => {
		const messageEmitter = this.notificationMessageService.messageEmitter;

		const specificEventStream = messageEmitter.pipe(
			filter((message: NotificationMessage) => message.type === type && !message.clearAll)
		);

		this.streamsAndDisplayedNotificationsByTypes.set(type, new SynchronizedStreamAndNotificationList(specificEventStream, this.translateService, this.elRef));
	}

	private generateClearAllStream(): void {
		this.clearAllStream =
			this.notificationMessageService
				.messageEmitter
				.pipe(filter((message: NotificationMessage) => message.clearAll))
				.subscribe(() =>
					this.streamsAndDisplayedNotificationsByTypes
						.forEach((e: SynchronizedStreamAndNotificationList) => e.clearList())
				);
	}
}
