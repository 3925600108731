import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AsideComponent } from '@mdib/aside/component/aside/aside.component';
import { StandingOrdersPageComponent } from './component/standing-orders-page/standing-orders-page.component';
import { UpdateStandingOrderPageComponent } from './component/update-standing-order-page/update-standing-order-page.component';
import { FooterComponent } from '@mdib/footer/component/footer/footer.component';
import { HeaderComponent } from '@mdib/header/component/header/header.component';
import { SessionGuardService } from '@mdib/sessions';

const routes: Routes = [
	{
		path: 'standing-orders',
		canActivate: [SessionGuardService],
		data: { authorizedTokens: ['standing-orders.list'] },
		children: [
			{ path: '', component: HeaderComponent, outlet: 'header' },
			{ path: '', component: AsideComponent, outlet: 'aside' },
			{ path: '', component: FooterComponent, outlet: 'footer' },
			{
				path: '',
				component: StandingOrdersPageComponent,
				children: [
					{ path: '', component: StandingOrdersPageComponent, pathMatch: 'full' },
				],
			},
			{ path: 'update/:reference', component: UpdateStandingOrderPageComponent, pathMatch: 'full' },
		],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class StandingOrdersRoutingModule {
}
