import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { MailboxMessagesService } from './mailbox-messages.service';
import { MailboxMessagesServiceStub } from './mailbox-messages-service.stub';

@Injectable()
export class MailboxMessagesServiceProxy extends AdapterProxy {
	protected ServiceToken() { return MailboxMessagesService; }
	protected ServiceDefinition() { return MailboxMessagesServiceStub; }
}
