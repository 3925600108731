import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { OtpAuthenticationService } from './otp-authentication.service';
import { OtpAuthenticationServiceStub } from './otp-authentication.service.stub';

@Injectable()
export class OtpAuthenticationServiceProxy extends AdapterProxy {
	protected ServiceToken() { return OtpAuthenticationService; }
	protected ServiceDefinition() { return OtpAuthenticationServiceStub; }
}
