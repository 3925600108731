import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Themes, ThemeService } from '@mdib/styling';
import { Observable, of } from 'rxjs';

import { ConfigurationService, MemoryStorage } from '@mdib/utils';

import { Session } from '../model/session';
import { SessionRestorationService } from './session-restoration.service';

@Injectable()
export class SessionsService implements OnDestroy {

	private readonly storageActiveSessionIdKey = 'SessionsService.activeSessionId';
	private readonly storageSessionsKey = 'SessionsService.sessions';
	private sessions: Map<string, Session> = new Map();
	private activeSessionId: string;

	private browserStorage: Storage = localStorage;

	constructor(
		@Inject(SessionRestorationService) @Optional() private sessionRestorationServices: SessionRestorationService[],
		private router: Router,
		private configurationService: ConfigurationService,
		private theme: ThemeService,
	) {
		this.addStorageListener();

		// In case no multi-tabs is allowed, we switch to SessionStorage
		if (!this.configurationService.instant('application.session.multiTabs')) {
			this.browserStorage = sessionStorage;
		}

		// In case no restoration is allowed, we switch to MemoryStorage
		if (!this.configurationService.instant('application.session.restorable')) {
			this.browserStorage = new MemoryStorage();
		}
	}

	ngOnDestroy() {
		this.removeStorageListener();
	}

	public getSession(id?: string): Session {
		return this.sessions.get(id || this.activeSessionId);
	}

	public setSession(session: Session, id: string) {
		this.sessions.set(id, session);
	}

	public useSession(id: string): Session {
		this.browserStorage.setItem(this.storageActiveSessionIdKey, id);
		this.activeSessionId = id;
		return this.getSession(id);
	}

	public removeSession(id?: string): Observable<any> {
		const session: Session = this.getSession(id);
		if (session) {
			this.sessions.delete(id || this.activeSessionId);
			const result: Observable<any> = session.close ? session.close() : of(session);
			result.subscribe();
		}
		this.theme.setTheme(Themes.b2c);
		return of(null);
	}

	public setSessionsAndActiveSessionIdInStorage(sessionId: string) {
		this.saveSessionsInStorage();
		this.saveSessionIdInStorage(sessionId);
	}

	public saveSessionsInStorage() {
		const list = [];
		this.sessions.forEach((session, id) => list.push({ id: id, s: session }));
		this.browserStorage.setItem(this.storageSessionsKey, JSON.stringify(list));
	}

	public saveSessionIdInStorage(sessionId: string) {
		this.browserStorage.setItem(this.storageActiveSessionIdKey, sessionId);
	}

	public loadSessions() {
		try {
			const list = JSON.parse(this.browserStorage.getItem(this.storageSessionsKey));
			list.forEach(item => {
				// Find the first service able to restore the session
				let restored = false;
				for (const restorationService of (this.sessionRestorationServices || [])) {
					if (restorationService.restore(item.s)) {
						restored = true;
						break;
					}
				}
				// If the session has been restored, store it
				if (restored) {
					this.setSession(item.s, item.id);
					this.activeSessionId = item.id;
					this.saveSessionIdInStorage(item.id);
				}
			});
		} catch (error) {
			this.sessions = new Map();
			this.activeSessionId = null;
		}
	}

	/**
	 * Listen to the storage changes. In case the activeSessionId has changed while the application still
	 * use another one, we redirect to the logout page.
	 *
	 * @param event The storage event
	 */
	private storageEventListener(event: StorageEvent) {
		if (event.storageArea === this.browserStorage && event.key === this.storageActiveSessionIdKey && this.activeSessionId) {
			if (this.activeSessionId !== this.browserStorage.getItem(this.storageActiveSessionIdKey)) {
				this.sessions = new Map();
				this.activeSessionId = null;
				this.router.navigate(['/session-logout']);
			}
		}
	}

	private addStorageListener() {
		window.addEventListener('storage', this.storageEventListener.bind(this));
	}

	private removeStorageListener() {
		window.removeEventListener('storage', this.storageEventListener.bind(this));
	}
}
