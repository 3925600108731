export enum MessageTypes {
	Unknown = 'unknown',
	BankInformation = 'bankInformation',
	Suggestions = 'suggestions',
	BankProductsAndServices = 'bankProductsAndServices',
	Complaint = 'complaint',
}

export enum BankDepartments {
	HelpDesk,
	Complaints,
}

export interface MessageTypeParameter {
	id: string;
	label: string;
}


