import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ServiceResponse } from '@mdib/utils';
import { Authentication } from '@mdib/sessions';
import { PasswordAuthenticationService } from '@mdib/sessions-modes';

import { AuthenticationXclService } from './authentication-xcl.service';

@Injectable({
	providedIn: 'root'
})
export class PasswordAuthenticationXclService extends AuthenticationXclService implements PasswordAuthenticationService {

	protected AUTHENTICATION_TYPE = 'PWD';

	public authenticate(username: string, password: string): Observable<ServiceResponse<Authentication>> {
		return super.prepareAuthentication(username).pipe(mergeMap(response => {
			const authentication = response.getModel();
			return super.doAuthenticate(authentication, password);
		}));
	}

}
