import {TranslateService} from '@ngx-translate/core';
import {MatPaginatorIntl} from '@angular/material';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomPaginatorService extends MatPaginatorIntl {
	readonly NAMESPACE = 'stylingModule.customPaginatorService.';
	constructor(private translate: TranslateService) {
		super();
		this.itemsPerPageLabel = this.translate.instant(this.NAMESPACE + 'itemsPerPageLabel');
		this.nextPageLabel = this.translate.instant(this.NAMESPACE + 'nextPageLabel');
		this.previousPageLabel = this.translate.instant(this.NAMESPACE + 'previousPageLabel');
	}

	getRangeLabel = (page: number, pageSize: number, length: number) =>  {
		const separator = this.translate.instant(this.NAMESPACE + 'separator');
		if (length === 0 || pageSize === 0) {
			return `0 ${separator} ${length}`;
		}
		length = Math.max(length, 0);
		const startIndex = page * pageSize;
		const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
		return `${startIndex + 1} - ${endIndex} ${separator} ${length}`;
	}
}
