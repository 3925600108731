import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { DigipassSignatureService } from './digipass-signature.service';
import { DigipassSignatureServiceStub } from './digipass-signature.service.stub';

@Injectable()
export class DigipassSignatureServiceProxy extends AdapterProxy {
	protected ServiceToken() { return DigipassSignatureService; }
	protected ServiceDefinition() { return DigipassSignatureServiceStub; }
}
