import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

import { Signable } from '../../interfaces/signable';
import { SignatureService } from '../../service/signature.service';
import { SignatureType } from '../../interfaces/signature-type';

/**
 * Generic Signature component handling multiple processes.
 *
 * When a signable is bound, the components checks what types of process
 * can be used to sign it. If multiple types are available, a type selector
 * is shown. If one type is available or once the type is selected, the
 * specific component is shown.
 *
 * Usage:
 * 		`<mdib-signature [signable]="object" (onEvent)="handler($event)" (onSuccess)="signed(signable)"></mdib-signature>`
 */
@Component({
	selector: 'mdib-signature',
	template: ``
})
export class SignatureComponent implements OnInit {

	/**
	 * @property (Output) Emits events on errors or cancellation
	 */
	@Output() onEvent: EventEmitter<Event> = new EventEmitter<Event>();

	/**
	 * @property (Output) Emits the signed object
	 */
	@Output() onSuccess: EventEmitter<Signable> = new EventEmitter<Signable>();

	/**
	 * @property (Input) Object to be signed
	 */
	private signableObject: Signable;
	@Input('signable')
	set signable(object: Signable) {
		this.signableObject = object;
		this.selectedType = null;
		this.updateScreen();
	}

	private selectedType: SignatureType = null;

	constructor(
		private viewContainerRef: ViewContainerRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private signatureService: SignatureService
	) { }

	ngOnInit() {
		this.updateScreen();
	}

	private setType(newType: SignatureType) {
		if (newType !== this.selectedType) {
			this.selectedType = newType;
			this.updateScreen();
		}
	}

	private updateScreen() {
		// No Signable => No Display
		this.viewContainerRef.clear();
		if (!this.signableObject) { return; }

		// Type selected => Show Signature Process
		if (this.selectedType) {
			return this.showSignatureProcess();
		}

		// Show Selector
		return this.showTypeSelector();
	}

	private showTypeSelector() {
		// Update View
		const componentType = this.signatureService.getSelectorComponent();
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
		const componentRef = this.viewContainerRef.createComponent(componentFactory);

		// List of types
		const iterator = this.signatureService.getTypes();
		const types: SignatureType[] = Array.from(iterator).filter(
			(type) => this.signableObject.isSignatureTypeAllowed(type.id)
		);

		// Bindings
		componentRef.instance.types = types.map(type => type.id);
		componentRef.instance.onSelected.subscribe((type) => this.setType(type));
		componentRef.instance.onError.subscribe((event) => this.emitEvent(event));
	}

	private showSignatureProcess() {
		// Update View
		const componentType = this.selectedType.processComponent;
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
		const componentRef = this.viewContainerRef.createComponent(componentFactory, 0);

		// Bindings
		componentRef.instance.signable = this.signableObject;
		componentRef.instance.onSuccess.subscribe((result) => this.emitSuccess(result));
		componentRef.instance.onEvent.subscribe((event) => this.emitEvent(event));
	}

	private emitEvent(event: Event) {
		this.onEvent.emit(event);
	}

	private emitSuccess(result: Signable) {
		this.onSuccess.emit(result);
	}
}
