import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ParameterModel } from '@mdib/http';
import { SessionsService, SessionUtilsService } from '@mdib/sessions';
import { MdibFile, ServiceResponse, UtilsHelper } from '@mdib/utils';
import { XclRepresentedClientModel } from '@mdib-xcl/customers';
import { AbstractOrderXCLModel, FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, MultigoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { FileEntryXcl, FileMapperXclService, XclDateFormatter } from '@mdib-xcl/utils';


import { PdfStatementsMapperXclService } from '@mdib-xcl/pdf-statements/service/pdf-statements-mapper-xcl.service';
import { PdfStatementSummaryXcl, PdfStatementXcl } from '@mdib-xcl/pdf-statements/model/pdf-statement-xcl';
import { XclAccountStatementHelper } from '@mdib-xcl/account-statements/helper/xcl-account-statement-helper';
import { PdfStatementsCommonService } from '@mdib/pdf-statements/service/pdf-statements-common.service';
import { PdfStatementSummary, PdfStatement } from '@mdib/pdf-statements/model/pdf-statement';
import { PdfStatementsPageFilter } from '@mdib/pdf-statements/model/pdf-statement-page-filter';
import { PdfStatementStatus } from '@mdib/pdf-statements/model/pdf-statement-status.enum';


@Injectable({
	providedIn: 'root',
})
export class PdfStatementsXclService extends PdfStatementsCommonService {
	constructor(private xclAccountStatementsMapperService: PdfStatementsMapperXclService,
		private xclHttpService: XclHttpService,
		private sessionService: SessionsService,
		private feedbacksExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private fileMapperXclService: FileMapperXclService,
		private sessionUtilsService: SessionUtilsService,
	) {
		super();
	}

	public list(index?: number, count?: number, filter?: PdfStatementsPageFilter): Observable<ServiceResponse<PdfStatementSummary[]>> {
		const params: ParameterModel[] = [
			new ParameterModel('duplicateSwitch', filter.status === PdfStatementStatus.New ? 'true' : 'false'),
			new ParameterModel('callMode', filter.status === PdfStatementStatus.New ? '01' : '02'),
			new ParameterModel('principalIdentifier', this.sessionService.getSession().activeUser.id)
		];

		if (filter.status === PdfStatementStatus.Downloaded) {
			params.push(new ParameterModel('startDate', XclDateFormatter.convertDateToXCLDateFormat(filter.startDate)));
			params.push(new ParameterModel('endDate', XclDateFormatter.convertDateToXCLDateFormat(filter.endDate)));
		}

		// When no maxResults parameter is provided, the CBS returns all the values
		if (count !== null) {
			params.push(new ParameterModel('maxResults', count.toString()));
		}
		// Filter
		this.fillFilterCriteria(params, filter);

		const xclObservable = this.xclHttpService.execute('pdf-statements-list', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<PdfStatementSummaryXcl>>;
		// Extract the account statement list from the root "goalList" property returned by the XCL
		// Return an array of account statement (functional model) corresponding to
		// the array of account statement returned by the XCL (technical model)
		return xclObservable.pipe(map((xclOrder: MultigoalOrderXCLModel<PdfStatementSummaryXcl>) =>
			new ServiceResponse<PdfStatementSummary[]>(
				this.xclAccountStatementsMapperService.PdfStatementSummaryXclToAccountStatementSummaryArray(xclOrder.goalList),
				this.feedbacksExtractor.extract(xclOrder),
				{ totalCount: xclOrder.rowCount },
			)
		));
	}

	public count(filter?: PdfStatementsPageFilter): Observable<ServiceResponse<number>> {
		const params: ParameterModel[] = [
			new ParameterModel('duplicateSwitch', 'N'),
			new ParameterModel('callMode', '01'),
			new ParameterModel('principalIdentifier', this.sessionService.getSession().activeUser.id)
		];

		// Filter
		this.fillFilterCriteria(params, filter);

		return this.xclHttpService.execute('pdf-statements-list', XclHttpService.GET, params)
			.pipe(map((xclOrder: AbstractOrderXCLModel) =>
				new ServiceResponse<number>(
					xclOrder.rowCount,
					this.feedbacksExtractor.extract(xclOrder),
				),
			));
	}

	public get(accountStatementSummary?: PdfStatementSummary, previousAccountStatement?: PdfStatement, filter?: PdfStatementsPageFilter): Observable<ServiceResponse<PdfStatement>> {
		const params: ParameterModel[] = [
			new ParameterModel('identifier', this.sessionService.getSession().activeUser.id),
			new ParameterModel('accountNumber', accountStatementSummary ? accountStatementSummary.accountNumber.substr(8) : ''),
			new ParameterModel('callMode', '01'),
			new ParameterModel('startDate', accountStatementSummary ? XclDateFormatter.dateToString(accountStatementSummary.firstStatementDate) : ''),
			new ParameterModel('endDate', accountStatementSummary ? XclDateFormatter.dateToString(accountStatementSummary.lastStatementDate) : ''),
		];

		if (filter && filter.status) {
			params.push(new ParameterModel('duplicataSwitch', String(filter.status === PdfStatementStatus.Downloaded)));
		}

		// If there is a lastGeneratedStatementDate we want to retrieve the next PDF file, therefore we need to update the parameter models with new values
		if (previousAccountStatement) {
			params.find(parameterModel => parameterModel.par === 'startDate').val = XclDateFormatter.dateToString(previousAccountStatement.lastStatementDate);
			params.find(parameterModel => parameterModel.par === 'callMode').val = '03';
			params.find(parameterModel => parameterModel.par === 'accountNumber').val = previousAccountStatement.accountNumber;
			params.find(parameterModel => parameterModel.par === 'endDate').val = XclDateFormatter.dateToString(previousAccountStatement.endDate);
			params.push(new ParameterModel('sortFieldValue', previousAccountStatement.lastSortFieldValue));
			params.push(new ParameterModel('previousPDFsequenceNumber', previousAccountStatement.pdfSequenceNumber.toString()));
			params.push(new ParameterModel('originalStartDate', XclDateFormatter.dateToString(previousAccountStatement.pdfStartDate)));
		}

		const xclObservable = this.xclHttpService.execute('pdf-statements-retrieve', XclHttpService.GET, params);

		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<PdfStatementXcl>) =>
			new ServiceResponse<PdfStatement>(
				this.xclAccountStatementsMapperService.pdfStatementXclToAccountStatement(xclOrder.goal),
		)));
	}

	public update(accountStatement: PdfStatement): Observable<ServiceResponse<PdfStatement>> {
		const params: ParameterModel[] = [
			new ParameterModel('identifier', accountStatement.identifier),
		];

		const body = {
			'accountNature': 'E',
			'accountNumber': accountStatement.accountNumber,
			'statementYear': accountStatement.statementNumberYear,
			'addressSequenceNumber': accountStatement.sequenceNumber,
			'transmissionMedium': XclAccountStatementHelper.getXclCodeFromFileFormat(accountStatement.file.format),
			'statementNumber': accountStatement.statementNumber,
		};

		const xclObservable = this.xclHttpService
			.execute('account-statements-update-init', XclHttpService.PUT, params).pipe(
				mergeMap((order: MonogoalOrderXCLModel<PdfStatementXcl>) =>
					this.xclHttpService.execute(
						'account-statements-update-retrieve',
						XclHttpService.PUT,
						[<ParameterModel>{ par: 'reference', val: order.reference }],
					)),
				mergeMap((order: MonogoalOrderXCLModel<PdfStatementXcl>) =>
					this.xclHttpService.execute(
						'account-statements-update-validate',
						XclHttpService.PUT,
						[<ParameterModel>{ par: 'reference', val: order.reference }],
						body,
					)),
				mergeMap((order: MonogoalOrderXCLModel<PdfStatementXcl>) =>
					this.xclHttpService.execute(
						'account-statements-update-confirm',
						XclHttpService.PUT,
						[<ParameterModel>{ par: 'reference', val: order.reference }],
					)),
			) as Observable<MonogoalOrderXCLModel<PdfStatementXcl>>;

		// Extract the account statement from the root "goal" property returned by the XCL
		// Return a account statement (functional model) corresponding to account statement returned by the XCL (technical model)
		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<PdfStatementXcl>) =>
			new ServiceResponse<PdfStatement>(
				this.xclAccountStatementsMapperService.pdfStatementXclToAccountStatement(xclOrder.goal),
				this.feedbacksExtractor.extract(xclOrder),
			)));
	}

	public getRepresentedClientNumber(): Observable<ServiceResponse<string>> {
		const params: ParameterModel[] = [
			new ParameterModel('groupName', '02'),
			new ParameterModel('principalIdentification', this.sessionUtilsService.getSessionActiveUserId()),
			new ParameterModel('defaultRepresentedClientSwitch', 'true'),
		];
		const xclObservable = this.xclHttpService.execute('person-mandates', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<XclRepresentedClientModel>>;
		return xclObservable.pipe(map((xclOrder: MultigoalOrderXCLModel<XclRepresentedClientModel>) =>
			new ServiceResponse<string>(
				this.getDefaultRepresentedClient(xclOrder.goalList),
				this.feedbacksExtractor.extract(xclOrder),
			),
		));
	}

	private getDefaultRepresentedClient(list: XclRepresentedClientModel[]): string {
		let representedClientIdentity = '';
		list.forEach((client: XclRepresentedClientModel) => {
			if (client.defaultRepresentedClientSwitch) {
				representedClientIdentity = client.representedClientIdentity;
			}
		});
		return representedClientIdentity;
	}

	private fillFilterCriteria(params: ParameterModel[], statementFilter: PdfStatementsPageFilter | undefined) {
		if (statementFilter == null) {
			return;
		}
		if (!UtilsHelper.nullOrUndefined(statementFilter.cashAccount) && statementFilter.cashAccount.number) {
			params.push(new ParameterModel('accountNumber', statementFilter.cashAccount.number));
		}
	}

}
