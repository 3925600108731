import { NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { ForgotPasswordService } from '@mdib/forgot-password';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';

import { ForgotPasswordMemoryService } from './service/forgot-password-memory.service';

@NgModule()
export class ForgotPasswordMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(ForgotPasswordService, adapterId, ForgotPasswordMemoryService);
	}
}
