import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { MailboxMessagesCommonService, MailboxConversation, MailboxConversationSummary, MailboxMessage, MessageTypes, } from '@mdib/mailbox';
import { ServiceResponse } from '@mdib/utils';

import { MockMailboxMessages } from '../mock/mock-mailbox-messages';

@Injectable({
	providedIn: 'root'
})
export class MailboxMessagesMemoryService extends MailboxMessagesCommonService {

	private nextId = 100;
	private nbrUnreadMsg;
	private conversations: Array<MailboxConversation> = MockMailboxMessages.getConversations();


	public reloadConversations(): void {
		this.conversationsSummariesStream.next(null);
		// FIXME: try to found an other solution than setTimeout.
		setTimeout(() => {
			this.conversationsSummariesStream.next(new ServiceResponse<MailboxConversationSummary[]>(this.conversations));
		}, 100);
	}

	public reloadConversation(conversationIdentifier: string): void {
		this.getConversationStream(conversationIdentifier).next(null);
		// FIXME: try to found an other solution than setTimeout.
		setTimeout(() => {
			this.getConversationStream(conversationIdentifier).next(new ServiceResponse<MailboxConversation>(this.findConversation(conversationIdentifier).conversation));
		}, 100);
	}

	public addMessage(message: MailboxMessage): Observable<ServiceResponse<MailboxMessage>> {
		message.identifier = (this.nextId++).toString();
		this.conversations.unshift(Object.assign(new MailboxConversation(), <MailboxConversation>{
			identifier: message.identifier,
			type: MessageTypes.BankInformation,
			isImportant: true,
			isRead: false,
			isWaitingResponse: false,
			isAnswered: false,
			sendingDate: new Date(),
			title: message.title,
			messages: [
				Object.assign(new MailboxMessage(), <MailboxMessage>{
					senderName: 'NWB Bank',
					recipientName: message.recipientName,
					content: message.content.toString(),
					sendingDate: new Date(),
					wasSentByCurrentUser: true,
				}),
			],
			correspondentName: 'NWB Bank',
		}));
		return of(new ServiceResponse<MailboxMessage>(message)).pipe(delay(100));
	}


	public deleteConversation(conversationIdentifier: string, reloadConversationsUponCompletion?: boolean): Observable<ServiceResponse<null>> {
		const result = this.findConversation(conversationIdentifier);
		if (!isNullOrUndefined(result.conversation)) {
			this.conversations.splice(result.conversationIndex, 1);
		}


		this.closeConversationStreamIfExists(conversationIdentifier);
		this.reloadConversationsIfTrueOrNullOrUndefined(reloadConversationsUponCompletion);
		return of(new ServiceResponse<null>(null)).pipe(delay(100));
	}

	public markConversationAsReadOrUnread(conversationIdentifier: string, isRead: boolean): Observable<ServiceResponse<null>> {
		const result = this.findConversation(conversationIdentifier);
		if (!isNullOrUndefined(result.conversation)) {
			result.conversation.isRead = isRead;
			this.conversations.splice(result.conversationIndex, 1, result.conversation);
		}

		this.reloadConversation(conversationIdentifier);
		this.reloadConversations();
		return of(new ServiceResponse<null>(null)).pipe(delay(100));
	}

	public countUnreadMessages(): Observable<ServiceResponse<number>> {
		this.nbrUnreadMsg = 0;
		this.conversations.forEach((selectedConversation: MailboxConversationSummary) => {
			if (!selectedConversation.isRead) {
				this.nbrUnreadMsg++;
			}
		});
		return of(new ServiceResponse<number>(this.nbrUnreadMsg));
	}

	private findConversation(conversationIdentifier: string) {
		const result = { conversationIndex: null, conversation: null };
		result.conversation = this.conversations.find(
			(msg, i, list) => {
				if (msg.identifier === conversationIdentifier) {
					result.conversationIndex = i;
					return true;
				} else {
					return false;
				}
			},
		);
		return result;
	}
}
