import { NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { PasswordProcessService, OtpSignatureService, DigipassSignatureService } from '@mdib/signature-modes';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';

import { PasswordProcessMemoryService } from './service/password-process-memory.service';
import { DigipassSignatureMemoryService } from './service/digipass-signature-memory.service';
import { OtpProcessMemoryService } from './service/otp-process-memory.service';

@NgModule()
export class SignatureMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(PasswordProcessService, adapterId, PasswordProcessMemoryService);
		adaptersService.setAdapter(DigipassSignatureService, adapterId, DigipassSignatureMemoryService);
		adaptersService.setAdapter(OtpSignatureService, adapterId, OtpProcessMemoryService);
	}
}
