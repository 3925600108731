import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ManagedCurrencyCodes } from '@mdib/config';
import { PaymentForm, PaymentTypes } from '@mdib/payments';
import { AmountTypes } from '@mdib/payments/types/models/payments.enum';
import { IconService } from '@mdib/styling';
import { DateHelper, UtilsHelper } from '@mdib/utils';

@Component({
	selector: 'mdib-operation-date-section',
	templateUrl: './operation-date-section.component.html',
})
export class OperationDateSectionComponent implements OnInit {

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.dataGatheringStepComponent.operationDateSectionComponent.';
	@Input() form: PaymentForm;
	readonly dateHelper = DateHelper;
	readonly paymentTypes = PaymentTypes;
	readonly amountTypes = AmountTypes;

	constructor(private iconService: IconService) {
	}

	ngOnInit() {

	}

	getCurrencyIcon(currency: string): string {
		return this.iconService.getCurrencyIcon(ManagedCurrencyCodes[currency]);
	}

	toDisplay(control: AbstractControl, group = false): boolean {
		return this.form.group.enabled || (this.form.group.disabled && (group ? UtilsHelper.objectNotEmpty(control.value) : !!control.value));
	}
}
