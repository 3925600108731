import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalDialogComponent, ModalDialogProperties } from '@mdib-pages/generic';
import { ConfigurationService } from '@mdib/utils';
import { Subject } from 'rxjs/internal/Subject';

import { CashAccountForm } from '../../form/cash-account-form';
import { BicService } from '../../service/bic/bic.service';

@Component({
	selector: 'account-input',
	templateUrl: './account-input.component.html',
	styleUrls: ['./account-input.component.scss'],
})
export class AccountInputComponent {

	readonly NAMESPACE = 'cashAccountsModule.accountInputComponent.';
	@Input() form: CashAccountForm;
	@Input() showBic = true;
	readonly bicFiller: Subject<string> = new Subject<string>();
	private fillBic = false;

	constructor(private bicService: BicService,
				private dialog: MatDialog,
				private configurationService: ConfigurationService,
	) {
		this.fillBic = this.configurationService.instant('bic.autofill');
		this.bicFiller.subscribe(bic => {
			if (bic && !this.form.bic.value) {
				this.form.bic.setValue(bic);
			}
		});
	}

	getBic(): void {
		if (this.fillBic) {
			this.bicService.get(this.form.number.value).subscribe(
				response => this.bicFiller.next(response.getModel()),
			);
		}
	}

	/**
	 * This method opens a dialog box when "BIC information" icon is clicked
	 */
	public openBicDialog() {
		this.dialog.open(ModalDialogComponent, <MatDialogConfig<ModalDialogProperties>> {
			maxHeight: '95%',
			maxWidth: '95%',
			disableClose: true,
			data: <ModalDialogProperties> {
				title: this.NAMESPACE + 'modelDialogTitleLine',
				content: this.NAMESPACE + 'bicCodeInfo',
				rightButtonLabel: 'generic:ok',
				showDivider: true,
			},
		});
	}
}
