import { KeyingLimit, KeyingLimitsBuilder, KeyingLimitTypes } from '@mdib/keying-limits';

export class MockKeyingLimits {
	static getMockKeyingLimits(): KeyingLimit[] {
		return [
			new KeyingLimitsBuilder().limitType(KeyingLimitTypes.InternetBanking)
			.keyingLimitPerOperation(10000)
			.keyingLimitPerDay(20000)
			.keyingLimitPerWeek(30000)
			.keyingLimitForStockExchange(4000)
			.dailyAvailableLimit(200)
			.weeklyAvailableLimit(1000)
			.keyingLimit
		];
	}
}
