import { Component } from '@angular/core';

@Component({
	selector: 'mdib-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
	readonly NAMESPACE = 'footerModule.footerComponent.';
}
