import { Injectable } from '@angular/core';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { SepaDirectDebitContract } from '../model/sepa-direct-debit-contract';
import { BackendService } from '@mdib/core/adapters';

/**
 * Abstracts the calls related to {@link SepaDirectDebitContracts}
 */
@Injectable()
export abstract class SepaDirectDebitContractsService extends BackendService<SepaDirectDebitContract> {
	// FIXME - To be removed and included in search operation
	/**
	 * Retrieves the list of sepa direct debit contracts
	 * @param {number} [offset] Offset to start from (by default 0, i.e. from the beginning)
	 * @param {number} [limit] Maximum number of operations to retrieve (by default 0, i.e. no limit)
	 * @param {string} [counterpartyAccountNumber] Counterparty account number for which the list of sepa contracts to be filtered
	 * @returns {Observable<ServiceResponse<SepaDirectDebitContract[]>>} List of sepa direct debit contracts related to counterparty account number
	 * @memberof SepaDirectDebitContractsService
	 */
	public abstract list(index?: number, count?: number, counterpartyAccountNumber?: string): Observable<ServiceResponse<SepaDirectDebitContract[]>>;

	/**
	 * Counts the list of sepa direct debit contracts related to counterparty account number
	 * @param {string} [counterpartyAccountNumber] Counterparty accountNumber for which the list of sepa contracts to be filtered
	 * @returns {Observable<ServiceResponse<number>>} The number of sepa direct debit contracts related to counterparty accountnumber
	 * @memberof SepaDirectDebitContractsService
	 */
	public abstract count(counterpartyAccountNumber?: string): Observable<ServiceResponse<number>>;

}
