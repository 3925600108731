// Libraries
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomersModule } from '@mdib/customers';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DndModule } from 'ng2-dnd';

// MDIB - Modules
import { SecurityModule, FingerprintInterceptorService } from '@mdib/core/security';
import { NavigationModule, NavigationFilterWipService, NAVIGATION_FILTERS, NavigationFilterSecurityService } from '@mdib/core/navigation';
import { NotificationMessageModule } from '@mdib/notification-message';
import { HeaderModule } from '@mdib/header/header.module';
import { HttpModule } from '@mdib/http';
import { LiveTourModule } from '@mdib/live-tour/live-tour.module';
import { SessionsModule } from '@mdib/sessions';
import { ConfigModule } from '@mdib/config';
import { StylingModule } from '@mdib/styling';
import { TranslationLoader, GenericTranslateParser, UtilsModule } from '@mdib/utils';
import { BeneficiariesModule } from '@mdib/beneficiaries';
import { AccountStatementsModule } from '@mdib/account-statements';
import { AccountValuationsModule } from '@mdib/account-valuations';
import { CashAccountsModule } from '@mdib/cash-accounts';
import { ForgotPasswordModule } from '@mdib/forgot-password';
import { KeyingLimitsModule } from '@mdib/keying-limits';
import { MailboxModule } from '@mdib/mailbox';
import { PaymentsModule } from '@mdib/payments';
import { ShoppingBasketModule } from '@mdib/shopping-basket';
import { StandingOrdersModule } from '@mdib/standing-orders';
import { SepaDirectDebitsModule } from '@mdib/sepa-direct-debits';
import { PaymentFilesModule } from '@mdib/payment-files';
import { CustomizationModule } from '@mdib/core/customization';

// Authentications
import { PasswordAuthenticationProcessComponent, SessionsModesModule } from '@mdib/sessions-modes';
import { DigipassAuthenticationProcessComponent } from '@mdib/sessions-modes';
import { OtpAuthenticationProcessComponent } from '@mdib/sessions-modes';

// Signatures
import { SignatureModule, SignatureTypeListComponent, UnimplementedSignatureComponent } from '@mdib/signature';
import { SignatureModes, DigipassSignatureProcessComponent, PasswordSignatureComponent, OtpSignatureComponent, SignatureModesModule } from '@mdib/signature-modes';
import { ElectronicalStatementsModule } from '@mdib/electronical-statements/electronical-statements.module';
import { VirtualNumpadModule } from '@mdib/virtual-numpad';
import { PdfStatementsModule } from '@mdib/pdf-statements/pdf-statements.module';

// Assets
export function menusLoader() { return require('../../assets/menu-items.json'); }

const module = {
	declarations: [
		PasswordAuthenticationProcessComponent,
		DigipassAuthenticationProcessComponent,
		OtpAuthenticationProcessComponent
	],
	shared: [
		// The standard modules
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		// MDIB
		StylingModule,
		HttpModule,
		ConfigModule,
		UtilsModule,
		LiveTourModule,
		HeaderModule,
		VirtualNumpadModule
	],
	imports: [
		SecurityModule.forRoot(),
		NavigationModule.forRoot({
			menusLoader: menusLoader,
			menusFilters: [
				{ provide: NAVIGATION_FILTERS, multi: true, useClass: NavigationFilterWipService },
				{ provide: NAVIGATION_FILTERS, multi: true, useClass: NavigationFilterSecurityService },
			]
		}),
		NotificationMessageModule.forRoot(),
		AccountStatementsModule.forRoot(),
		AccountValuationsModule.forRoot(),
		BeneficiariesModule.forRoot(),
		CashAccountsModule.forRoot(),
		ConfigModule.forRoot(),
		CustomersModule.forRoot(),
		ElectronicalStatementsModule.forRoot(),
		ForgotPasswordModule.forRoot(),
		KeyingLimitsModule.forRoot(),
		MailboxModule.forRoot(),
		PaymentsModule.forRoot(),
		SessionsModesModule.forRoot(),
		ShoppingBasketModule.forRoot(),
		StandingOrdersModule.forRoot(),
		PaymentFilesModule.forRoot(),
		PdfStatementsModule.forRoot(),
		StylingModule.forRoot(),
		SepaDirectDebitsModule.forRoot(),
		CustomizationModule.forRoot(),
		TranslateModule.forRoot({
			loader: { provide: TranslateLoader, useClass: TranslationLoader },
			parser: { provide: TranslateParser, useClass: GenericTranslateParser },
		}),
		SignatureModesModule.forRoot(),
		SignatureModule.forRoot([
			{ id: SignatureModes.PASSWORD, name: 'password', processComponent: PasswordSignatureComponent },
			{ id: SignatureModes.DIGIPASS, name: 'digipass', processComponent: DigipassSignatureProcessComponent },
		], null, SignatureTypeListComponent),
		SessionsModule.forRoot([
			{ id: SignatureModes.PASSWORD, name: 'password', processComponent: PasswordAuthenticationProcessComponent },
			{ id: SignatureModes.DIGIPASS, name: 'digipass', processComponent: DigipassAuthenticationProcessComponent },
		], { defaultMode: SignatureModes.PASSWORD }),
		UtilsModule.forRoot(),
		DndModule.forRoot(),
		VirtualNumpadModule
	],
	entryComponents: [
		PasswordSignatureComponent,
		SignatureTypeListComponent,
		UnimplementedSignatureComponent,
		PasswordAuthenticationProcessComponent,
		DigipassAuthenticationProcessComponent,
		OtpAuthenticationProcessComponent
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: FingerprintInterceptorService, multi: true },
	]
};

@NgModule({
	imports: [...module.shared, ...module.imports],
	declarations: module.declarations,
	entryComponents: module.entryComponents,
	exports: module.shared,
	providers: module.providers,
})
export class AppMdibImports {
}
