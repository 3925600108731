import { Injectable } from '@angular/core';
import { ManagedCurrencyCodes } from '@mdib/config';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { CashAccount } from '../model/cash-account';
import { CashAccountCreation } from '../model/cash-account-creation';
import { CashAccountService } from '../service/cash-account.service';

/**
 * Abstracts the calls related to {@link CashAccount} and {@link CashAccountCreation}
 */
@Injectable()
export class CashAccountServiceStub extends CashAccountService {

	public list(offset?: number, limit?: number): Observable<ServiceResponse<CashAccount[]>> {
		return of();
	}

	public get(accountNumber: string): Observable<ServiceResponse<CashAccount>> {
		return of();
	}

	public getIconByCashAccountType(cashAccountType: string): string {
		return undefined;
	}

	public getTotalAmount(cashAccounts: CashAccount[], baseCurrencyCode?: ManagedCurrencyCodes): number {
		return undefined;
	}

	public validate(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		return of();
	}

	public confirm(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		return of();
	}

	public sign(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		return of();
	}
}
