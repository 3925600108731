import { Injectable, NgZone } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { IAdvHttpError, IAdvHttpResponse } from '../types/cordova-plugin-adv-http';
import { CordovaService } from './cordova.service';

@Injectable({
	providedIn: 'root'
})
export class CordovaPluginAdvHttpService {

	private cordovaPluginAdvHttpService: CordovaPluginAdvHttpService;

	constructor(
		private cordovaService: CordovaService,
		private ngZone: NgZone,
	) {
		this.cordovaService.deviceReady.subscribe(
			(isDeviceReady: boolean) => {
				if (isDeviceReady) {
					const cordova = window['cordova'];
					this.cordovaPluginAdvHttpService = cordova.plugin.http;
					if (!this.cordovaPluginAdvHttpService) {
						throw(new Error('No cordova plugin advanced http installed'));
					}
					this.setSSLCertMode('pinned');
					this.setDataSerializer('json');
				}
			}
		);
	}

	public request(httpMethod: string, url: string, data: Object, headers: Object): Observable<any> {
		const method = this.getPluginHTTPMethod(httpMethod.toLowerCase());
		return new Observable(obs => {
			method(url, data, headers,
				(r) => {
					this.ngZone.run(() => {
						try {
							r.data = JSON.parse(r.data.toString());
						} catch (e) {
							throwError('JSON parsing error');
						}
						obs.next(r.data);
						obs.complete();
					});
				}, (e) => {
					this.ngZone.run(() => {
						obs.error(e);
					});
				}
			);
		});
	}

	private setSSLCertMode(sslCertMode: string): void {
		const method = this.getPluginMethod('setSSLCertMode');
		method(sslCertMode);
	}

	private setDataSerializer(dataSerializer: string) {
		const method = this.getPluginMethod('setDataSerializer');
		method(dataSerializer);
	}

	private getPluginMethod(method: string): (arg: string) => void {
		if (!this.cordovaPluginAdvHttpService[method] || typeof this.cordovaPluginAdvHttpService[method] !== 'function') {
			throw(new Error('No cordova plugin advanced http setDataSerializer function'));
		}
		return this.cordovaPluginAdvHttpService[method];
	}

	private getPluginHTTPMethod(method: string): (url: string, data: Object, headers: Object, onSuccess: (response: IAdvHttpResponse) => void, onError: (error: IAdvHttpError) => void) => void {
		if (!this.cordovaPluginAdvHttpService[method] || typeof this.cordovaPluginAdvHttpService[method] !== 'function') {
			throw(new Error('No cordova plugin advanced http ' + method + ' function'));
		}
		return this.cordovaPluginAdvHttpService[method];
	}
}

