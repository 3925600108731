import { Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { BackendOperationStep, BackendOperationState, SearchCriteria, SearchResult } from '@mdib/core/adapters';

import { MultigoalOrderXCLModel } from '@mdib-xcl/http';

import { XclCallConfiguration } from './xcl-call-configuration';
import { DecisionTree } from './decision-tree';
import { BackendOperationXcl } from './backend-operation-xcl';
import { SearchOperatorsXcl } from '../criteria/search-operators-xcl';

export class BackendSearchOperationXcl<OUT, X> extends BackendOperationXcl<SearchCriteria<any>, SearchResult<OUT>, X> {

	// State machine
	protected decision: DecisionTree = {
		'____': {
			triggers: { 'search': this.search },
			defaultTrigger: 'search',
			expectedStates: ['9000'],
		},
	};

	constructor(injector: Injector) {
		super(injector);

		// Intialize step
		const machineState = this.decision[this.xclState];
		const triggers = Object.keys((machineState && machineState.triggers) || {});
		this.curentStep = new BackendOperationStep(null, triggers);
	}

	protected handleResponse(order: MultigoalOrderXCLModel<X>): Observable<SearchResult<OUT>> {
		return super.handleResponse(order).pipe(map(() => {
			return new SearchResult(this.convertResponses(order.goalList), order.rowCount, order.first, order.maxResults);
		}));
	}

	protected convertResponses(responses: Array<X>): Array<OUT> {
		return responses.map(this.convertResponse.bind(this));
	}

	protected convertResponse(response: X): OUT {
		return null;
	}

	protected getSearchData(input: SearchCriteria<any>): any {
		const compiled = input.compile(SearchOperatorsXcl);
		return compiled((field, value) => [field, value]);
	}

	protected search(trigger: string, input?: SearchCriteria<any>): Observable<BackendOperationStep<SearchResult<OUT>>> {
		// Fetch the search Data
		let searchData = this.getSearchData(input);
		if (!(searchData instanceof Observable)) {
			searchData = of(searchData);
		}

		// Execute the request
		return searchData.pipe(mergeMap(body => {
			return this.calls(trigger, [
				new XclCallConfiguration({ method: 'GET', body: body, state: BackendOperationState.SUCCEEDED }),
			]);
		}));
	}

}
