import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { ModifyAccountNameService } from './modify-account-name.service';
import { ModifyAccountNameServiceStub } from './modify-account-name.service.stub';

@Injectable()
export class ModifyAccountNameServiceProxy extends AdapterProxy {
	protected ServiceToken() { return ModifyAccountNameService; }
	protected ServiceDefinition() { return ModifyAccountNameServiceStub; }
}
