import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material';

import { provideNamespace } from '@mdib/core/customization';
import { UtilsHelper } from '@mdib/utils';

import { BeneficiariesSearch } from '../../types/models/beneficiaries-search';
import { BeneficiariesSearchFormBuilder } from '../../types/forms/beneficiaries-search-form.builder';

@Component({
	selector: 'beneficiary-search-form',
	templateUrl: './beneficiary-search-form.component.html',
	styleUrls: ['./beneficiary-search-form.component.scss'],
	viewProviders: [provideNamespace('beneficiaries', 'beneficiarySearchFormComponent')],
})
export class BeneficiarySearchFormComponent implements OnInit {

	public searchForm: FormGroup;

	@Output()
	private modelChange = new EventEmitter<BeneficiariesSearch>();

	private searchModel: BeneficiariesSearch = new BeneficiariesSearch();

	@ViewChild('filter')
	private filterPanel: MatExpansionPanel;

	/**
	 * Create the component
	 *
	 * @param formBuilder {FormBuilder} Injected
	 * @param dataFormattingService {DataFormattingService} Injected
	 */
	constructor(
		private beneficiariesSearchFormBuilder: BeneficiariesSearchFormBuilder,
	) { }

	ngOnInit() {
		// Initialize the form with the default values
		this.searchForm = this.beneficiariesSearchFormBuilder.build();

		// Watch changes on the form
		this.searchForm.valueChanges.subscribe(value => {
			this.searchModel = new BeneficiariesSearch(value);
		});

		// Initialize the form with the right values
		if (UtilsHelper.objectNotEmpty(this.searchModel)) {
			this.searchForm.patchValue(this.searchModel);
			this.filterPanel.open();
			this.search();
		}
	}

	@Input()
	public set model(model: BeneficiariesSearch) {
		this.searchModel = model || new BeneficiariesSearch();
		if (this.searchForm) {
			this.searchForm.patchValue(this.searchModel);
		}
	}

	@Input()
	public set disabled(disabled: boolean) {
		if (disabled && this.searchForm) {
			this.searchForm.disable();
		} else if (this.searchForm) {
			this.searchForm.enable();
		}
	}

	clear() {
		this.searchForm.reset();
		this.search();
	}

	search() {
		this.modelChange.emit(this.searchModel);
	}

}
