import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { StylingModule } from '@mdib/styling';
import { UtilsModule } from '@mdib/utils';

import { ContactsService } from './service/contacts/contacts.service';
import { SecretQuestionsListsService } from './service/secret-questions-lists/secret-questions-lists.service';
import { PersonsService } from './service/persons/persons.service';
import { ContactsServiceProxy } from './service/contacts/contacts.service.proxy';
import { SecretQuestionsListsServiceProxy } from './service/secret-questions-lists/secret-questions-lists.service.proxy';
import { PersonsServiceProxy } from './service/persons/persons.service.proxy';

import { InputPersonMandateComponent } from './components/input-person-mandate/input-person-mandate.component';
import { InputSecretQuestionsComponent } from './components/input-secret-questions/input-secret-questions.component';
import { SecretQuestionsListFormComponent } from './components/secret-questions-list-form/secret-questions-list-form.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		UtilsModule,
		TranslateModule,
		StylingModule.forRoot(),
	],
	exports: [
		InputPersonMandateComponent,
		InputSecretQuestionsComponent,
		SecretQuestionsListFormComponent,
	],
	declarations: [
		InputPersonMandateComponent,
		InputSecretQuestionsComponent,
		SecretQuestionsListFormComponent,
	]
})
export class CustomersModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: CustomersModule,
			providers: [
				{ provide: ContactsService, useClass: ContactsServiceProxy },
				{ provide: SecretQuestionsListsService, useClass: SecretQuestionsListsServiceProxy },
				{ provide: PersonsService, useClass: PersonsServiceProxy },
			],
		};
	}
}
