import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SequenceCacheStrategyService } from '@mdib/core/adapters';
import { StylingModule } from '@mdib/styling';
import { UtilsModule } from '@mdib/utils';

import { CommunicationComponent } from './components/communication/communication.component';
import { StructuredCommunicationComponent } from './components/communication/structured-communication/structured-communication.component';
import { MdibCommunicationPipe } from './pipes/mdib-communication.pipe';
import { SequenceCacheBasicStrategyService } from './services/sequence-cache-basic-strategy.service';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		UtilsModule,
		StylingModule,
	],
	declarations: [
		CommunicationComponent,
		StructuredCommunicationComponent,
		MdibCommunicationPipe,
	],
	exports: [
		CommunicationComponent,
		StructuredCommunicationComponent,
		MdibCommunicationPipe,
	],
	providers: [
		{ provide: SequenceCacheStrategyService, useClass: SequenceCacheBasicStrategyService },
	]
})
export class CommonsModule { }
