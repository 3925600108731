import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * A global store which shares the lifetime of the Angular app.
 * @example
 *
 * // navigationStore is a NavigationStore dependency injection.
 *
 * const stuff: StuffType = new StuffType(...);
 * const key: string = navigationStore.store(stuff); // I store a new object, and retrieve a key to access it later.
 * const stuffSubject: BehaviorSubject<StuffType> = navigationStore.get(key);
 *
 * ...
 *
 * const newStuff: StuffType = new StuffType(...);
 * navigationStore.set(key, newStuff);
 *
 * ...
 *
 * const synchronouslyRetrievedStuff: StuffType = stuffSubject.getValue(); // == newStuff
 *
 * let upToDateStuff: StuffType;
 *
 * stuffSubject.subscribe((stuff: StuffType) => upToDateStuff = stuff);
 * // The callback will be called each time the set method with the key parameter is called.
 *
 */
@Injectable()
export class NavigationStorage {
	private context: Map<string, BehaviorSubject<any>> = new Map<string, BehaviorSubject<any>>();
	private newId = 0;

	/**
	 * Retrieves subject from holding corresponding to the provided key.
	 *
	 * @param {string} key : associated with each subject
	 * @returns {BehaviorSubject<any>|null} : Holds an object to store. If none corresponds to the key null is returned.
	 */
	get(key: string): BehaviorSubject<any> {
		if (this.context.has(key)) {
			return this.context.get(key);
		}
		return null;
	}

	/**
	 * Replaces the object corresponding to the provided key in the store
	 * Emits the new objects to the listeners if the subject corresponding to the key was already existing.
	 * It is also possible to create a new emplacement with a consumer generated key. In this
	 * case there are no guarantees that no already stored object will be erased.
	 *
	 * @param {string} key The key referencing the emplacement to replace.
	 * @param object The new object
	 */
	set(key: string, object: any): void {
		if (this.context.has(key)) {
			this.context.get(key).next(object);
		} else {
			this.context.set(key, new BehaviorSubject(object));
		}
	}

	/**
	 * Stores a new object at a new emplacement.
	 *
	 * @param object The object to store
	 * @returns {string} The id corresponding to the object (in order to retrieve it)
	 */
	store(object: any) {
		this.context.set(this.newId.toString(), new BehaviorSubject(object));

		return (this.newId++).toString();
	}
}
