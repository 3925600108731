import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BackendOperationStep, BackendOperationState } from '@mdib/core/adapters';
import { SecretQuestionsList } from '@mdib/customers';

import { BackendOperationXcl, DecisionTree, XclCallConfiguration } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';

import { XclSecretQuestionsModel } from '../../types/models/xcl-secret-questions-model';
import { SecretQuestionsMapperXclService } from '../secret-questions-mapper-xcl.service';

/**
 * The retrieve does not exst on the XCL, we use the update-retrieve to do it :(
 */
export class SecretQuestionsListRetrieveXcl extends BackendOperationXcl<string, SecretQuestionsList, XclSecretQuestionsModel> {

	protected xclEndpoint = 'xcl.secretQuestions.update';

	// State machine
	protected decision: DecisionTree = {
		'____': {
			triggers: { 'start': this.init },
			defaultTrigger: 'start',
			expectedStates: ['1500'],
		},
	};

	private secretQuestionsMapperXclService: SecretQuestionsMapperXclService;

	constructor(injector: Injector) {
		super(injector);
		this.secretQuestionsMapperXclService = injector.get(SecretQuestionsMapperXclService);
	}

	protected getItemIdentifier(input: SecretQuestionsList) {
		return this.sessionUtilsService.getSessionActiveUserId();
	}

	protected handleResponse(order: MonogoalOrderXCLModel<XclSecretQuestionsModel>): Observable<SecretQuestionsList> {
		return super.handleResponse(order).pipe(map(() => {
			return this.secretQuestionsMapperXclService.getFunctionalDataFromXclData(order);
		}));
	}

	protected init(trigger: string, input?: SecretQuestionsList): Observable<BackendOperationStep<SecretQuestionsList>> {
		const itemIdentifier = this.getItemIdentifier(input);
		return this.calls(trigger, [
			new XclCallConfiguration({ resourceId: itemIdentifier, method: 'PUT' }),
			new XclCallConfiguration({ action: 'retrieve', useReference: true, state: BackendOperationState.SUCCEEDED }),
		]);
	}

}
