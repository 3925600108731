import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GenericPagesModule } from '@mdib-pages/generic';
import { CommonsModule } from '@mdib/commons';
import { StylingModule } from '@mdib/styling';
import { UtilsModule } from '@mdib/utils';
import { TranslateModule } from '@ngx-translate/core';
import { DndModule } from 'ng2-dnd';

import { CashAccountsDropdownNewComponent } from './component/cash-accounts-dropdown-new/cash-accounts-dropdown-new.component';
import { CashAccountOperationDetailComponent } from './component/cash-account-operation-detail/cash-account-operation-detail.component';
import { CashAccountOperationComponent } from './component/cash-account-operation/cash-account-operation.component';
import { CashAccountsDropdownItemComponent } from './component/cash-accounts-dropdown-item/cash-accounts-dropdown-item.component';
import { CashAccountsDropdownComponent } from './component/cash-accounts-dropdown/cash-accounts-dropdown.component';
import { CashAccountsListItemComponent } from './component/cash-accounts-list-item/cash-accounts-list-item.component';
import { CashAccountsListComponent } from './component/cash-accounts-list/cash-accounts-list.component';
import { BicService } from './service/bic/bic.service';
import { BicServiceProxy } from './service/bic/bic.service.proxy';
import { CashAccountOperationService } from './service/cash-account-operation.service';
import { CashAccountOperationServiceProxy } from './service/cash-account-operation.service.proxy';
import { CashAccountSortOrderService } from './service/cash-account-sort-order.service';
import { CashAccountSortOrderServiceProxy } from './service/cash-account-sort-order.service.proxy';
import { CashAccountService } from './service/cash-account.service';
import { CashAccountServiceProxy } from './service/cash-account.service.proxy';
import { ModifyAccountNameService } from './service/modify-account-name.service';
import { ModifyAccountNameServiceProxy } from './service/modify-account-name.service.proxy';
import { SavingsAccountService } from './service/savings/savings-account.service';
import { SavingsAccountServiceProxy } from './service/savings/savings-account.service.proxy';
import { AccountInputComponent } from './component/account-input/account-input.component';
import { SecurityModule } from '@mdib/core/security';

/**
 * Cash accounts module configuration
 */
@NgModule({
	imports: [
		CommonModule,
		GenericPagesModule,
		UtilsModule,
		RouterModule,
		TranslateModule,
		StylingModule,
		FormsModule,
		DndModule,
		ReactiveFormsModule,
		SecurityModule
	],
	declarations: [
		CashAccountsListComponent,
		CashAccountsListItemComponent,
		CashAccountOperationComponent,
		CashAccountOperationDetailComponent,
		CashAccountsDropdownItemComponent,
		CashAccountsDropdownComponent,
		AccountInputComponent,
		CashAccountsDropdownNewComponent,
	],
	exports: [
		AccountInputComponent,
		CashAccountsListComponent,
		CashAccountsDropdownComponent,
		CashAccountsDropdownNewComponent,
	],
})
export class CashAccountsModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: CashAccountsModule,
			providers: [
				{provide: CashAccountService, useClass: CashAccountServiceProxy},
				{provide: ModifyAccountNameService, useClass: ModifyAccountNameServiceProxy},
				{provide: CashAccountOperationService, useClass: CashAccountOperationServiceProxy},
				{provide: SavingsAccountService, useClass: SavingsAccountServiceProxy},
				{provide: CashAccountSortOrderService, useClass: CashAccountSortOrderServiceProxy},
				{provide: BicService, useClass: BicServiceProxy},
			],
		};
	}
}
