import { Status } from '@mdib/utils';

export class OrderStateHelper {
	private static readonly statusToXCLCodeMappingList: CodeOrderStateTuple[] = [
		{ 'code': '0000', 'state': Status.Initiated },
		{ 'code': '1500', 'state': Status.ToValidate },
		{ 'code': '6000', 'state': Status.ToConfirm },
		{ 'code': '6100', 'state': Status.NotAuthorizedToSign },
		{ 'code': '6200', 'state': Status.ToSign },
		{ 'code': '7000', 'state': Status.InShoppingBasket },
		{ 'code': '7200', 'state': Status.EnvelopeReadyToBeSigned },
		{ 'code': '9000', 'state': Status.Closed },
		{ 'code': '9900', 'state': Status.Cancelled },
		{ 'code': '3502', 'state': Status.Error },
	];

	/**
	 * Returns the XCL order state code based on an OrderState enum value
	 * @param orderState : OrderState enum value
	 * @returns {string} The corresponding XCL status as string or null if te
	 */
	static getXCLOrderStateCodeFromStatus(orderState: Status): string {
		if (orderState === Status.Unknown) {
			return null;
		}

		const tuple = OrderStateHelper.statusToXCLCodeMappingList.find((element: CodeOrderStateTuple) => element.state === orderState);

		return tuple ? tuple.code : null;
	}

	/**
	 * Returns an OrderState enum value based on an XCL order state code
	 * @param orderStateCode
	 * @returns {OrderStates} The corresponding status or OrderStates.unknown if the code is not recognized
	 */
	public static getStatusFromXCLOrderStateCode(orderStateCode: string): Status {
		const tuple = OrderStateHelper.statusToXCLCodeMappingList.find((element: CodeOrderStateTuple) => element.code === orderStateCode);

		return tuple ? tuple.state : Status.Unknown;
	}

}

interface CodeOrderStateTuple {
	code: string;
	state: Status;
}
