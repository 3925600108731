import { Observable, of, throwError } from 'rxjs';

import { BackendOperation, BackendOperationStep, BackendOperationType, BackendOperationState } from '@mdib/core/adapters';

export class RetrieveOperationMemory<OUT> extends BackendOperation<string, OUT> {

	private defaultTrigger = 'retrieve';

	constructor(
		protected datas: Array<OUT> | OUT = [],
		protected indexKey: string = null,
	) {
		super(BackendOperationType.UPDATE);
		this.curentStep = new BackendOperationStep(null, ['retrieve']);
	}

	public execute(input: string, trigger?: string): Observable<BackendOperationStep<OUT>> {
		switch (trigger || this.defaultTrigger) {
			case 'cancel':
				return this.cancel(input, trigger || this.defaultTrigger);
			case 'retrieve':
				return this.done(input, trigger || this.defaultTrigger);
			default:
				return this.error(new Error('The trigger ' + trigger + ' does not exist'));
		}
	}

	protected error(error: Error): Observable<BackendOperationStep<OUT>> {
		this.state = BackendOperationState.FAILED;
		this.stepSubject.error(error);
		return throwError(error);
	}

	protected cancel(input: string, trigger?: string): Observable<BackendOperationStep<OUT>> {
		this.state = BackendOperationState.CANCELLED;
		this.defaultTrigger = null;
		this.curentStep = new BackendOperationStep<OUT>(trigger, []);
		this.stepSubject.next(this.curentStep);
		return of(this.curentStep);
	}

	protected done(input: string, trigger?: string): Observable<BackendOperationStep<OUT>> {
		this.state = BackendOperationState.SUCCEEDED;
		this.defaultTrigger = '';
		// If no key or only one element
		if (!this.indexKey || !Array.isArray(this.datas)) {
			this.curentStep = new BackendOperationStep<OUT>(trigger, [], <OUT>this.datas);
		} else {
			this.curentStep = new BackendOperationStep<OUT>(trigger, [], this.datas.find(d => d[this.indexKey] === input));
		}
		this.stepSubject.next(this.curentStep);
		return of(this.curentStep);
	}

}
