export { ParameterModel } from './model/parameter-model';
export { HttpStatusActionModel } from './model/http-status-action-model';
export { EndPointModel } from './model/end-point-model';
export { HttpResponseModel } from './model/http-response-model';
export { FunctionalFeedbacksExtractor } from './model/functional-feedbacks-extractor';
export { RequestOptions } from './model/request-options';

export { RestHttpService } from './service/rest-http.service';
export { HttpService } from './service/http.service';
export { HttpStatusManagementService } from './service/http-status-management.service';

export { HttpModule } from './http.module';

