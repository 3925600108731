import { LiveTourStep } from './live-tour-step';
import { LiveTourService } from '../live-tour.service';
import { LiveTourStepComponent } from '../live-tour-step/live-tour-step.component';
import { Observable } from 'rxjs';

export class ShowPopupStep extends LiveTourStep {

	constructor(public title: string, public content: string, public cancelable = false) {
		super();
	}

	public execute(service: LiveTourService, liveTourEvents: Observable<Event>): Observable<Event> {
		return service.getTourComponent().showComponent(LiveTourStepComponent, {
			title: this.title,
			content: this.content,
			liveTourEvents: liveTourEvents
		});
	}

}
