import { Component } from '@angular/core';
import { ErrorTypes } from '@mdib/errors';

@Component({
	selector: 'mdib-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
	ErrorTypes = ErrorTypes;
}
