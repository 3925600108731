import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SequenceCache } from '@mdib/core/meta';
import { SequenceCacheStrategyService, BackendService, PaginatedSearchParams, SearchCriteria, SearchResult } from '@mdib/core/adapters';
import { page } from '@mdib/core/adapters/criteria';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { ConfigurationService } from '@mdib/utils';

@Injectable()
export class SequenceCacheBasicStrategyService implements SequenceCacheStrategyService {

	constructor(
		private configurationService: ConfigurationService,
		private feedbacksManagerService: ServiceResponseNotificationMessagesMapperService,
	) { }

	public fill<T>(
		cache: SequenceCache<T>,
		service: BackendService<T>,
		pagination: PaginatedSearchParams,
		searchCriteria: SearchCriteria<any>,
	): Observable<SearchResult<T>> {
		// Build citeria
		const previousPagesLoaded = this.configurationService.instant('pages.search.previousPagesLoaded') || 2;
		const nextPagesLoaded = this.configurationService.instant('pages.search.nextPagesLoaded') || 2;

		const criteria = page(
			searchCriteria,
			Math.max(0, pagination.pageIndex - previousPagesLoaded) * pagination.pageSize,
			Math.max(pagination.pageSize, pagination.pageSize * (1 + previousPagesLoaded + nextPagesLoaded)),
		);

		// Fetch new data
		const operation = service.search();
		return operation.execute(criteria).pipe(
			tap(step => {
				// Send any available feedbacks
				if (step.feedbacks) {
					this.feedbacksManagerService.sendFeedbacks(step.feedbacks);
				}
			}),
			map(step => {
				if (step.result) {
					// Update the cache
					cache.set(step.result.items, step.result.index);

					// Get the results
					const total = step.result.total || 0;
					const index = pagination.pageIndex * pagination.pageSize;
					const count = Math.min(pagination.pageSize, total - index);
					const items = cache.get(index, count);
					return new SearchResult<T>(items, total, index, count);
				} else {
					return new SearchResult<T>([], 0);
				}
			})
		);
	}
}
