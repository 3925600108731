import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';

import { BeneficiaryTypeMapperService } from './beneficiary-type-mapper.service';

@Injectable({
	providedIn: 'root',
})
export class BeneficiaryCriteriaMapper {

	constructor(
		private beneficiaryTypeMapperService: BeneficiaryTypeMapperService,
	) { }

	public mapFields(fieldName, fieldValue): Observable<any> {
		switch (fieldName) {
			case 'alias': fieldName = 'partOfBeneficiaryIdentification'; break;
			case 'fullName': fieldName = 'partOfBeneficiaryName'; break;
			case 'type':
				fieldName = 'beneficiaryAllowedTypes';
				fieldValue = this.beneficiaryTypeMapperService.toXcl(fieldValue);
				break;
			case 'account.number': fieldName = 'partOfBeneficiaryAccountNumber'; break;
		}
		return of({ field: fieldName, value: fieldValue });
	}
}
