import { Beneficiary, BeneficiaryType } from '@mdib/beneficiaries';
import { CashAccount } from '@mdib/cash-accounts';
import { Communication, CommunicationTypes, Address } from '@mdib/commons';
import { OperationStatus, PaymentTypes, PaymentOperation } from '@mdib/payments';
import { SignatureModes } from '@mdib/signature-modes';
import { Status } from '@mdib/utils';

/**
 * Mock data for payment operations
 */
export class PaymentOperationMock {

	static initPaymentOperation(paymentOperation: PaymentOperation<any>): void {
		paymentOperation.reference = 'MOCKREFERENCE123';
		paymentOperation.status = Status.Initiated;
	}

	static retrievePaymentOperation(paymentOperation: PaymentOperation<null>): PaymentOperation<null> {
		if (paymentOperation.status) {
			paymentOperation.status = Status.ToValidate;
		} else {
			paymentOperation.operationStatus = OperationStatus.awaitingProcessing;
			paymentOperation.paymentDate = new Date(20180924);
			paymentOperation.operationDate = new Date(20180920);
			paymentOperation.type = PaymentTypes.sepaCreditTransfer;
			paymentOperation.reference = 'U7K27CWRIO4FPT3V';
			paymentOperation.amount = 1234.56;
			paymentOperation.currency = 'EUR';
			paymentOperation.ordererAccount = new CashAccount();
			paymentOperation.ordererAccount.number = 'BE56305177635588';
			paymentOperation.ordererAccount.clientWording = 'Daily';
			paymentOperation.counterParty = new Beneficiary();
			paymentOperation.counterParty.account = new CashAccount();
			paymentOperation.counterParty.account.number = 'BE11736988971648';
			paymentOperation.counterParty.fullName = 'Pierre Gewelt';
			paymentOperation.counterParty.type = BeneficiaryType.SEPA;
			paymentOperation.counterParty.communication = new Communication();
			paymentOperation.counterParty.communication.type = CommunicationTypes.free;
			paymentOperation.counterParty.communication.value = 'This is a mock payment :-)';
			paymentOperation.counterParty.address = new Address();

		}
		return paymentOperation;
	}

	static validatePaymentOperation(paymentOperation: PaymentOperation<null>): void {
		if (paymentOperation.amount < 10) {
			paymentOperation.status = Status.ToConfirm;
		} else {
			paymentOperation.status = Status.ToSign;
		}
		paymentOperation.signatureTypesAllowed = [SignatureModes.PASSWORD];
		paymentOperation.counterParty.account.bic = 'DEUTBEBEXXX';
		if (!paymentOperation.paymentDate) {
			paymentOperation.paymentDate = new Date();
		}
	}

	static confirmPaymentOperation(paymentOperation: PaymentOperation<null>): void {
		if (paymentOperation.status === Status.ToConfirm) {
			paymentOperation.status = Status.Closed;
		} else {
			paymentOperation.status = Status.InShoppingBasket;
		}
	}

}
