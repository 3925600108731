import { isNullOrUndefined, isUndefined } from 'util';
import { ContactAddress } from '../model/contact-address';

export class ContactAddressBuilder {
	private _contactAddress: ContactAddress;

	constructor(contactAddress?: ContactAddress) {
		this._contactAddress = isNullOrUndefined(contactAddress) ? new ContactAddress() : contactAddress;
	}

	public addressLine1(value: string): ContactAddressBuilder {
		if (!isUndefined(value)) {
			this._contactAddress.addressLine1 = value;
		}
		return this;
	}

	public addressLine2(value: string): ContactAddressBuilder {
		if (!isUndefined(value)) {
			this._contactAddress.addressLine2 = value;
		}
		return this;
	}

	public country(value: string): ContactAddressBuilder {
		if (!isUndefined(value)) {
			this._contactAddress.country = value;
		}
		return this;
	}
	public label(value: string): ContactAddressBuilder {
		if (!isUndefined(value)) {
			this._contactAddress.label = value;
		}
		return this;
	}

	get contactAddress(): ContactAddress {
		return this._contactAddress;
	}
}
