import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AmountValidator, CommonValidator, DataFormattingService, DateValidator, Periodicities } from '@mdib/utils';
import { AmountTypes } from '../models/payments.enum';
import { StandingOrderDetails } from '../models/standing-order-details';

export class StandingOrderDetailsForm {

	private form: FormGroup;
	private standingOrderExecution: StandingOrderDetails;

	constructor(private fb: FormBuilder,
				private dataFormattingService: DataFormattingService,
				standingOrderExecution?: StandingOrderDetails,
				private readonly subscribe: boolean = false,
	) {
		this.standingOrderExecution = standingOrderExecution || this.initModel();
		this.create();
		if (subscribe) {
			this.subscriptions();
		}
	}

	public checkExecutionFieldsActivation() {
		if (!!this.iteration.value || !!this.totalAmount.value) {
			this.endDate.disable({ emitEvent: false });
		} else if (!!this.endDate.value) {
			this.iteration.disable({ emitEvent: false });
			this.totalAmount.disable({ emitEvent: false });
		} else if (!this.iteration.value && !this.totalAmount.value && !this.endDate.value) {
			this.iteration.enable({ emitEvent: false });
			this.totalAmount.enable({ emitEvent: false });
			this.endDate.enable({ emitEvent: false });
		}
	}

	public updateAmountFields(amountControl: FormControl) {
		if (this.standingOrderExecution.amountType === AmountTypes.variable) {
			amountControl.reset();
			amountControl.clearValidators();
			this.minBalance.setValidators([CommonValidator.required]);
		} else {
			this.minAmount.reset();
			this.maxAmount.reset();
			this.minBalance.reset();
			this.minBalance.clearValidators();
			amountControl.setValidators([AmountValidator.checkPositive, CommonValidator.required]);
		}
		amountControl.updateValueAndValidity();
		this.form.updateValueAndValidity();
	}

	public updateFrequencyFieldsValidations(frequencyValue) {
			switch (frequencyValue) {
				case Periodicities.semiMonthly : {
					this.firstPaymentDay.setValidators([CommonValidator.min(1), CommonValidator.max(31), CommonValidator.required]);
					this.secondPaymentDay.setValidators([CommonValidator.min(1), CommonValidator.max(31), CommonValidator.required]);
					break;
				}
				case Periodicities.demiSemiMonthly : {
					this.firstPaymentDay.setValidators([CommonValidator.min(1), CommonValidator.max(31), CommonValidator.required]);
					this.secondPaymentDay.setValidators([CommonValidator.min(1), CommonValidator.max(31), CommonValidator.required]);
					this.thirdPaymentDay.setValidators([CommonValidator.min(1), CommonValidator.max(31), CommonValidator.required]);
					break;
				}
				case Periodicities.monthly : {
					this.firstPaymentDay.setValidators([CommonValidator.min(1), CommonValidator.max(31), CommonValidator.required]);
					break;
				}
				case Periodicities.weekly : {
					this.weekDayPayment.setValidators([CommonValidator.required]);
					break;
				}
				case Periodicities.yearly :
				case Periodicities.semiAnnually :
				case Periodicities.quarterly :
				case Periodicities.biMonthly :
				case Periodicities.trimonthly : {
					this.firstPaymentDay.setValidators([CommonValidator.min(1), CommonValidator.max(31), CommonValidator.required]);
					this.monthPayment.setValidators([CommonValidator.required]);
					break;
				}
			}
			this.firstPaymentDay.updateValueAndValidity();
			this.secondPaymentDay.updateValueAndValidity();
			this.thirdPaymentDay.updateValueAndValidity();
			this.weekDayPayment.updateValueAndValidity();
			this.monthPayment.updateValueAndValidity();
			this.form.updateValueAndValidity();
	}

	public resetFrequencyValueAndValidators() {
		this.firstPaymentDay.reset();
		this.secondPaymentDay.reset();
		this.thirdPaymentDay.reset();
		this.monthPayment.reset();
		this.weekDayPayment.reset();
		this.firstPaymentDay.clearValidators();
		this.secondPaymentDay.clearValidators();
		this.thirdPaymentDay.clearValidators();
		this.monthPayment.clearValidators();
		this.weekDayPayment.clearValidators();

	}

	private create() {
		this.form = this.fb.group({
			amountType: [this.standingOrderExecution.amountType, [CommonValidator.required]],
			minBalance: [!!this.standingOrderExecution.minBalance ? this.dataFormattingService.amountToViewFormat(this.standingOrderExecution.minBalance) : null, [AmountValidator.checkPositive]],
			minAmount: [!!this.standingOrderExecution.minAmount ? this.dataFormattingService.amountToViewFormat(this.standingOrderExecution.minAmount) : null, [AmountValidator.checkPositive]],
			maxAmount: [!!this.standingOrderExecution.maxAmount ? this.dataFormattingService.amountToViewFormat(this.standingOrderExecution.maxAmount) : null, [AmountValidator.checkPositive]],
			frequency: [this.standingOrderExecution.frequency, [CommonValidator.required]],
			firstPaymentDay: [this.standingOrderExecution.firstPaymentDay, [CommonValidator.min(1), CommonValidator.max(31)]],
			secondPaymentDay: [this.standingOrderExecution.secondPaymentDay, [CommonValidator.min(1), CommonValidator.max(31)]],
			thirdPaymentDay: [this.standingOrderExecution.thirdPaymentDay, [CommonValidator.min(1), CommonValidator.max(31)]],
			monthPayment: [this.standingOrderExecution.monthPayment],
			weekDayPayment: [this.standingOrderExecution.weekDayPayment],
			startDate: [this.standingOrderExecution.startDate, [DateValidator.dateFromToday]],
			endDate: [this.standingOrderExecution.endDate, [DateValidator.dateFromToday]],
			iteration: [this.standingOrderExecution.iteration, [CommonValidator.min(1), CommonValidator.max(100)]],
			totalAmount: [!!this.standingOrderExecution.totalAmount ? this.dataFormattingService.amountToViewFormat(this.standingOrderExecution.totalAmount) : null, [AmountValidator.checkPositive]],
		}, { validator: DateValidator.isBefore('startDate', 'endDate') });
		this.checkExecutionFieldsActivation();
		this.updateFrequencyFieldsValidations(this.frequency.value);
	}

	private initModel() {
		return new StandingOrderDetails({
			amountType: AmountTypes.fixed,
			frequency: Periodicities.monthly,
			firstPaymentDay: 1,
		});
	}

	private subscriptions() {
		this.amountType.valueChanges.subscribe(amountType => this.standingOrderExecution.amountType = amountType);
		this.minBalance.valueChanges.subscribe(minBalance => this.standingOrderExecution.minBalance = this.dataFormattingService.parseAmount(minBalance));
		this.firstPaymentDay.valueChanges.subscribe(firstPaymentDay => this.standingOrderExecution.firstPaymentDay = firstPaymentDay);
		this.secondPaymentDay.valueChanges.subscribe(secondPaymentDay => this.standingOrderExecution.secondPaymentDay = secondPaymentDay);
		this.thirdPaymentDay.valueChanges.subscribe(thirdPaymentDay => this.standingOrderExecution.thirdPaymentDay = thirdPaymentDay);
		this.monthPayment.valueChanges.subscribe(monthPayment => this.standingOrderExecution.monthPayment = monthPayment);
		this.weekDayPayment.valueChanges.subscribe(weekDayPayment => this.standingOrderExecution.weekDayPayment = weekDayPayment);
		this.startDate.valueChanges.subscribe(startDate => {
			this.standingOrderExecution.startDate = startDate;
			if (!this.startDate.touched) {
				this.startDate.markAsTouched();
			}
		});
		this.endDate.valueChanges.subscribe(endDate => {
			this.checkExecutionFieldsActivation();
			this.standingOrderExecution.endDate = endDate;
			if (!this.endDate.touched) {
				this.endDate.markAsTouched();
			}
		});
		this.iteration.valueChanges.subscribe(iteration => {
			this.checkExecutionFieldsActivation();
			this.standingOrderExecution.iteration = iteration;
		});
		this.totalAmount.valueChanges.subscribe(totalAmount => {
			this.checkExecutionFieldsActivation();
			this.standingOrderExecution.totalAmount = this.dataFormattingService.parseAmount(totalAmount);
		});
		this.frequency.valueChanges.subscribe(frequencyValue => {
			if (frequencyValue !== this.standingOrderExecution.frequency) {
				this.resetFrequencyValueAndValidators();
				this.standingOrderExecution.frequency = frequencyValue;
				this.updateFrequencyFieldsValidations(frequencyValue);
			}
		});
		this.minAmount.valueChanges.subscribe(minAmount => {
			this.standingOrderExecution.minAmount = this.dataFormattingService.parseAmount(minAmount);
			if (this.maxAmount.value > 0) {
				this.minAmount.setValidators(CommonValidator.max(this.maxAmount));
			}
		});
		this.maxAmount.valueChanges.subscribe(maxAmount => {
			this.standingOrderExecution.maxAmount = this.dataFormattingService.parseAmount(maxAmount);
			if (this.maxAmount.value > 0) {
				this.minAmount.setValidators(CommonValidator.max(this.maxAmount));
				this.minAmount.updateValueAndValidity();
			}
		});
	}

	get group(): AbstractControl {
		return this.form;
	}

	get model(): StandingOrderDetails {
		return this.standingOrderExecution;
	}

	get monthPayment(): AbstractControl {
		return this.form.get('monthPayment');
	}

	get weekDayPayment(): AbstractControl {
		return this.form.get('weekDayPayment');
	}

	get firstPaymentDay(): AbstractControl {
		return this.form.get('firstPaymentDay');
	}

	get secondPaymentDay(): AbstractControl {
		return this.form.get('secondPaymentDay');
	}

	get thirdPaymentDay(): AbstractControl {
		return this.form.get('thirdPaymentDay');
	}

	get startDate(): AbstractControl {
		return this.form.get('startDate');
	}

	get endDate(): AbstractControl {
		return this.form.get('endDate');
	}

	get iteration(): AbstractControl {
		return this.form.get('iteration');
	}

	get totalAmount(): AbstractControl {
		return this.form.get('totalAmount');
	}

	get frequency(): AbstractControl {
		return this.form.get('frequency');
	}

	get amountType(): AbstractControl {
		return this.form.get('amountType');
	}

	get minBalance(): AbstractControl {
		return this.form.get('minBalance');
	}

	get minAmount(): AbstractControl {
		return this.form.get('minAmount');
	}

	get maxAmount(): AbstractControl {
		return this.form.get('maxAmount');
	}
}
