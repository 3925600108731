import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { CommonValidator, FileValidator } from '@mdib/utils';
import { PaymentFile } from '../model/payment-file';

export class PaymentFilesForm {
	private form: FormGroup;

	constructor(private fb: FormBuilder,
				private readonly paymentFile?: PaymentFile,
	) {
		this.paymentFile = paymentFile || this.initPaymentFile();
		this.create();
		this.subscription();
	}

	public clear() {
		this.form.reset({});
		this.form.enable();
	}

	private create() {
		this.form = this.fb.group({
			type: [this.paymentFile.type, [CommonValidator.required]],
			fileName: [this.paymentFile.file.name, [CommonValidator.required, FileValidator.fileName]],
			sequenceNumber: [this.paymentFile.sequenceNumber],
			uploadDate: [this.paymentFile.uploadDate],
			fileStatus: [this.paymentFile.fileStatus],
			batches: [this.paymentFile.batchesCount],
			payments: [this.paymentFile.paymentsCount],
			totalAmount: [this.paymentFile.totalAmount],
			hash: [this.paymentFile.hashAlgorithm],
		});
	}

	private subscription() {
		this.group.valueChanges.subscribe(paymentFileForm => {
			this.paymentFile.type = paymentFileForm.type;
			this.paymentFile.file.name = paymentFileForm.fileName;
			this.paymentFile.sequenceNumber = paymentFileForm.sequenceNumber;
			this.paymentFile.uploadDate = paymentFileForm.uploadDate;
			this.paymentFile.fileStatus = paymentFileForm.fileStatus;
			this.paymentFile.batchesCount = paymentFileForm.batches;
			this.paymentFile.paymentsCount = paymentFileForm.payments;
			this.paymentFile.totalAmount = paymentFileForm.totalAmount;
			this.paymentFile.hashAlgorithm = paymentFileForm.hash;
		});
	}

	private initPaymentFile(): PaymentFile {
		return new PaymentFile();
	}

	get group(): FormGroup {
		return this.form;
	}

	get model(): PaymentFile {
		return this.paymentFile;
	}

	get type(): AbstractControl {
		return this.form.get('type');
	}

	get fileName(): AbstractControl {
		return this.form.get('fileName');
	}

	get sequenceNumber(): AbstractControl {
		return this.form.get('sequenceNumber');
	}

	get uploadDate(): AbstractControl {
		return this.form.get('uploadDate');
	}

	get fileStatus(): AbstractControl {
		return this.form.get('fileStatus');
	}

	get batches(): AbstractControl {
		return this.form.get('batches');
	}

	get payments(): AbstractControl {
		return this.form.get('payments');
	}

	get totalAmount(): AbstractControl {
		return this.form.get('totalAmount');
	}

	get hash(): AbstractControl {
		return this.form.get('hash');
	}
}
