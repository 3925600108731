import { OperationStatus, PaymentTypes } from '@mdib/payments';
import { SignatureModes } from '@mdib/signature-modes';
import { Status, UtilsHelper } from '@mdib/utils';
import { Signable, Signature } from '@mdib/signature';

export class ShoppingBasketOperation implements Signable {

	reference: string;
	status: Status;
	name: string;
	isReadyToBeSigned: boolean;
	isContentLocked: boolean;
	signatureTypesAllowed: SignatureModes[];
	ordererAccountNumber: string;
	counterpartyAccountNumber: string;
	amount: number;
	currency: string;
	paymentDate: string;
	orderNatureCode: string;
	orderNatureLabel: string;
	isAlreadySignedByUser = false; // This front boolean indicates whether the connected user has already signed the operation or not.
	signature: Signature = null;
	signatureContext: any = null;
	modelIdentifier: string; // This is the (optional) identifier of the nested model (whatever the model, every model has an identifier)
	encoderUserIdentifier: string;
	countPastSignatures: number;
	ordererAccountAlias: string;
	counterPartyName: string;


	// TODO : temporary stub
	private _paymentType: OperationStatus;
	get paymentType(): PaymentTypes {
		return PaymentTypes.sepaCreditTransfer;
	}

	get isReadyToBeSignedByUser(): boolean {
		return this.isReadyToBeSigned && !this.isAlreadySignedByUser;
	}

	public isSignatureTypeAllowed(signatureType: any): boolean {
		return (UtilsHelper.nullOrUndefined(this.signatureTypesAllowed) ? false : this.signatureTypesAllowed.indexOf(signatureType) >= 0);
	}

	public isSigned(): boolean {
		return !UtilsHelper.nullOrUndefined(this.signature);
	}
	public addSignature(signature: Signature): void {
		this.signature = signature;
	}

	public getSignatureContext(): any {
		return this.signatureContext;
	}
}
