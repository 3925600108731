import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { OtpSignatureService } from './otp-signature.service';
import { OtpSignatureServiceStub } from './otp-signature.service.stub';

@Injectable()
export class OtpSignatureServiceProxy extends AdapterProxy {
	protected ServiceToken() { return OtpSignatureService; }
	protected ServiceDefinition() { return OtpSignatureServiceStub; }
}

