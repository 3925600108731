import { Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../../model/user';
import { Authentication } from '../../../model/authentication';

export abstract class AuthenticationProcessComponent {

	@Input()
	public user: User;

	/**
	 * @property (Output) Emits the signed object
	 */
	@Output()
	public onSuccess: EventEmitter<Authentication> = new EventEmitter<Authentication>();

	/**
	 * @property (Output) Emits events on errors or cancellation
	 */
	@Output()
	public onEvent: EventEmitter<Event> = new EventEmitter<Event>();
}
