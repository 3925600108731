import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { InputError, GroupFieldValidator } from '@mdib/utils';

import { SecretQuestionsList } from '../../types/secret-questions-list';
import { SecretQuestion } from '../../types/secret-question';

@Component({
	selector: 'secret-questions-list-form',
	templateUrl: './secret-questions-list-form.component.html',
	styleUrls: ['./secret-questions-list-form.component.scss']
})
export class SecretQuestionsListFormComponent implements OnInit, OnChanges {

	readonly NAMESPACE = 'CustomersModule.SecretQuestionsListFormComponent';

	@Input()
	public model: SecretQuestionsList;

	@Input()
	public readonly = false;

	public formGroup: FormGroup;

	@Output()
	private modelChange: EventEmitter<SecretQuestionsList> = new EventEmitter();

	@Output()
	private formGroupChange: EventEmitter<FormGroup> = new EventEmitter();

	constructor(
		private formBuilder: FormBuilder,
	) { }

	ngOnInit() {
		this.formGroup = this.formBuilder.group({
			questions: this.getQuestionsControl(this.model && this.model.questions || []),
		});

		this.formGroup.valueChanges.subscribe(value => {
			this.modelChange.next(new SecretQuestionsList(value));
		});

		this.formGroupChange.next(this.formGroup);
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (this.formGroup && changes.model) {
			this.reset();
		}
	}

	public reset() {
		const questionControls = <FormArray>this.formGroup.get('questions');
		if (!this.model || questionControls.controls.length !== this.model.questions.length) {
			this.formGroup.setControl('questions', this.getQuestionsControl(this.model && this.model.questions || []));
		}
		this.formGroup.reset(this.model, { emitEvent: false });
	}

	private getQuestionsControl(questions: SecretQuestion[]): FormArray {
		const questionsFormGroup = new Array<FormControl>();
		questions.forEach(secretQuestion => {
			questionsFormGroup.push(
				this.formBuilder.control({ value: secretQuestion, disabled: this.readonly }, [GroupFieldValidator.required]),
			);
		});
		return this.formBuilder.array(questionsFormGroup, this.noDuplicateQuestion);
	}

	private noDuplicateQuestion(formArray: FormArray): { [key: string]: any } | null {

		const DUPLICATE = { duplicateQuestions: <InputError> { translationKey: 'utilsModule.groupFieldsValidator.duplicateQuestions' } };

		const questions = formArray.value as Array<SecretQuestion>;
		const hasDuplicate = questions.map((question, ci) => {
			for (let i = ci + 1; i < questions.length; ++i) {
				if (questions[i].id === question.id) {
					formArray.controls[i].setErrors(DUPLICATE);
					return true;
				}
			}
			return false;
		}).reduce((n, acc) => n || acc, false);

		return hasDuplicate ? DUPLICATE : null;
	}

}
