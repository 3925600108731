import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material';

import { ParameterSetsService } from '@mdib/core/customization';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { ServiceResponse } from '@mdib/utils';
import { zip, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CashAccount } from '../../model/cash-account';
import { CashAccountTypesGroup } from '../../model/cash-accounts.enum';
import { CashAccountService } from '../../service/cash-account.service';
import { CashAccountTypeParameter } from '../../types/models/cash-account-type-parameter';

@Component({
	selector: 'mdib-cash-accounts-dropdown',
	templateUrl: './cash-accounts-dropdown.component.html',
	styleUrls: ['./cash-accounts-dropdown.component.scss'],
})
export class CashAccountsDropdownComponent implements OnChanges {
	readonly NAMESPACE = 'cashAccountsModule.cashAccountsDropdownComponent.';

	@Input() control: AbstractControl = new FormControl();
	@Output() select: EventEmitter<CashAccount> = new EventEmitter<CashAccount>();

	/**
	 * This optional string parameter indicates that the list of cash accounts should be filtered to only show the accounts in this particular currency.
	 */
	@Input() singleCurrencyToShow: string;

	/**
	 * This optional boolean parameter indicates whether only the current accounts should be displayed.
	 */
	@Input() onlyShowCurrentAccounts = false;

	/**
	 * This optional boolean parameter indicates whether, once the accounts are loaded, the first one should be automatically selected.
	 */
	@Input() selectFirstCashAccount: boolean;

	@Input() onlyShowSavingsAccounts = false;

	/**
	 * This optional boolean parameter indicates whether, all accounts option is enabled or not.
	 */
	@Input() isAllAccountsEnabled = false;

	@Input() isConsult = false;

	cashAccounts: CashAccount[] = [];
	filteredCashAccounts: CashAccount[] = [];

	isOperationOngoing = false;

	// private _selectFirstCashAccount: boolean;
	constructor(
		private cashAccountService: CashAccountService,
		private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService,
		private parameterSetsService: ParameterSetsService,
	) {
	}

	@Input() public set selectedAccount(account: any) {
		this.control.setValue(account);
	}

	ngOnChanges() {

		if (this.control && (this.control.enabled || this.isConsult)) {
			this.loadCashAccounts();
		}
	}

	compAccounts(val1: CashAccount, val2: CashAccount) {
		return !!val1 && !!val2 && val1.number === val2.number;
	}

	loadCashAccounts() {
		// Load the Account Types in a map
		const getParams = this.parameterSetsService.get('cash-account-types').pipe(map((params: CashAccountTypeParameter[]) => {
			return params.reduce((o, p) => {
				o[p.key] = p;
				return o;
			}, {});
		}));

		zip(getParams, this.initAccountListFetch())
			.pipe(filter(results => !!results[1]))
			.subscribe(results => {
					const cashAccountTypes = results[0];
					const serviceResponse = results[1];
					this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponse, this.NAMESPACE);
					// Load the cash accounts.
					this.cashAccounts = serviceResponse.getModel();
					this.filteredCashAccounts = this.cashAccounts;

					this.filterCashAccounts(cashAccountTypes);

					if (this.selectFirstCashAccount) {
						this.autoSelectFirstCashAccount();
					}
					this.isOperationOngoing = false;
				},
				(serviceResponseError: ServiceResponse<null>) => {
					this.isOperationOngoing = false;
					this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
				},
		);
	}

	filterCashAccounts(cashAccountTypes: {[key: string]: CashAccountTypeParameter}) {
		this.filteredCashAccounts = this.cashAccounts ? this.cashAccounts.filter(
			(cashAccount: CashAccount) => {
				// The 'unknown' account types should never be displayed
				if (!cashAccount.type) {
					return false;
				}
				// If singleCurrencyToShow is defined, only show the accounts in this currency
				if (!!this.singleCurrencyToShow && this.singleCurrencyToShow !== cashAccount.valuationCurrency) {
					return false;
				}
				// If onlyShowCurrentAccounts is set to true, only show the current accounts
				if (this.onlyShowCurrentAccounts && cashAccountTypes[cashAccount.type].cashAccountTypesGroup !== CashAccountTypesGroup.CurrentAccounts) {
					return false;
				}
				// If the dropdown is in a consultation page, only show the account selected
				if (this.isConsult && !!this.control.value && cashAccount.number !== (<CashAccount>this.control.value).number) {
					return false;
				}
				// If onlyShowSavingsAccounts is set to true, only show the current accounts
				if (this.onlyShowSavingsAccounts && cashAccountTypes[cashAccount.type].cashAccountTypesGroup !== CashAccountTypesGroup.SavingsAndInvestmentsAccounts) {
					return false;
				}
				return true;
			},
		) : [];
	}

	autoSelectFirstCashAccount() {
			if (this.filteredCashAccounts.length > 0) {
				this.control.setValue(this.filteredCashAccounts[0]);
				this.select.emit(this.control.value);
			} else {
				this.control.setValue(this.cashAccounts[0]);
				this.select.emit(this.control.value);
			}
	}

	/**
	 * Send the value of inputs
	 */
	sendValue(select: MatSelectChange) {
		this.control.setValue(select.value);
		this.select.emit(this.control.value);
	}

	private initAccountListFetch(): Observable<ServiceResponse<CashAccount[]>> {
		this.isOperationOngoing = true;
		return this.cashAccountService.list();
	}
}
