import { Injectable } from '@angular/core';

import { AuthorizationsService } from '@mdib/core/security';

import { NavigationFilter } from '../types/navigation-filter';
import { NavigationItem } from '../types/navigation-item';

@Injectable()
export class NavigationFilterSecurityService implements NavigationFilter {

	constructor(
		private authorizationService: AuthorizationsService,
	) { }

	public filterItem(item: NavigationItem): boolean {
		return this.authorizationService.authorized(item['authorizedTokens'] || []);
	}

}
