import { AbstractControl, Validators } from '@angular/forms';
import { InputError } from '@mdib/utils';

export namespace EmailValidator {

	export const INVALIDEMAILFORMAT = {emailFormat: <InputError> {translationKey: 'utilsModule.emailValidator.invalidEmailFormat'}};

	// RFC regex : valid but more restrictive, deactivate if all mail possible are needed.
	const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	export function checkFormat(control: AbstractControl): {[key: string]: any} | null {
		return !control.value || (Validators.email(control) === null && emailRegex.test(control.value)) ? null : INVALIDEMAILFORMAT;
	}
}
