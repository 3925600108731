import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Pipe({
	name: 'mdibPhoneNumber',
})
export class MdibPhoneNumberPipe implements PipeTransform {
	isNumber = /^\d/;

	// Area codes in the Netherlands consist of three or four digits, three digits are fewer areas so this list is to filter on that
	areaCodesThreeDigits: string[] = [
		'10', '13', '14', '15', '20', '23', '24',
		'26', '30', '33', '35', '36', '38', '40',
		'43', '44', '45', '46', '50', '53', '55',
		'58', '7', '88'
	];


	/**
	 * Parser for number phone
	 */
	transform(phoneNumber): string {
		if (this.isNumber.test(phoneNumber.substring(1, phoneNumber.length))) {
			const countryCode = phoneNumber.split(' ')[0];
			const number = phoneNumber.split(' ')[1];
			if (!isNullOrUndefined(number)) {
				if (countryCode !== '+31') {
					return phoneNumber;
				}
				if (this.areaCodeCheck(number)) {
					return `${countryCode} ${number.substring(0, 2)} ${number.substring(2, 5)} ${number.substring(5, 7)} ${number.substring(7, number.length)}`;
				} else if (number.startsWith('6')) { // number is Dutch mobile phone number
					return `${countryCode} ${number.substring(0, 1)} ${number.substring(1, 3)} ${number.substring(3, 5)} ${number.substring(5, 7)} ${number.substring(7, number.length)}`;
				}  else {
					return `${phoneNumber.substring(0, 3)} ${phoneNumber.substring(3, 5)} ${phoneNumber.substring(5, 7)} ${phoneNumber.substring(7, phoneNumber.length)}`;
				}
			}
		}
		console.error('Phone number does not contain calling code and cannot be formatted');
		return phoneNumber;
	}


	areaCodeCheck(phoneNumber: string): boolean {
		for (let i = 0; i < this.areaCodesThreeDigits.length; i++) {
			if (phoneNumber.startsWith(this.areaCodesThreeDigits[i])) {
				return true;
			}
		}
		return false;
	}
}
