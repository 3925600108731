import { AbstractControl } from '@angular/forms';
import { InputError } from '../model/utils.typings';

export namespace FileValidator {

	export const INVALIDFILENAME = {fileName: <InputError> {translationKey: 'utilsModule.fileValidator.fileName'}};
	export const INVALIDFILESIZE = {maxSize: <InputError> {translationKey: 'utilsModule.fileValidator.maxSize'}};

	export function INVALIDFILEEXTENSION(fileExtensions: any): {[key: string]: any} {
		return {fileExtension: {translationKey: 'utilsModule.fileValidator.fileExtension', params: {extensionList: fileExtensions.toString()}}};
	}

	export function fileName(control: AbstractControl): {[key: string]: any} | null {
		const regex = /^[0-9a-zA-Z.-.-._]*$/;
		return !control.value || regex.test(control.value) ? null : INVALIDFILENAME;
	}

	export function fileExtension(fileExtensions: any): {[key: string]: any} | null {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let validated = false;
			if (fileExtensions) {
				const ext = control.value.split('.').pop().toLowerCase();
				fileExtensions.forEach(extension => {
					if (ext === extension) { validated = true; }
				});
				return !control.value || validated ? null : INVALIDFILEEXTENSION(fileExtensions);
			}
		};
	}

	export function fileSize(fileMaxSize: any): {[key: string]: any} | null {
		return (control: AbstractControl): {[key: string]: any} | null => {
			const validated = !control.value || ((control.value.size / 1024) < fileMaxSize) ? null : INVALIDFILESIZE;
			return validated;
		};
	}
}
