import { Input, EventEmitter, Output } from '@angular/core';

import { TableConfiguration } from '../../types/generics/table-configuration';
import { TableRow } from '../../types/generics/table-row';

export abstract class TableComponent<T> {

	@Input() public configuration: TableConfiguration;
	@Output() public selectItem = new EventEmitter<T>();

	public detailedItems: Array<TableRow<T>>;
	protected displayedColumns: Array<string> = [];
	protected curentSelection: TableRow<T>;

	@Input()
	public set items(items: Array<T>) {
		this.detailedItems = items && items.reduce((acc, item) => {
			const row1 = { item: item, state: 'close' };
			const row2 = { item: item, parent: row1 };
			this.conf('expandableRow', true) ? acc.push(row1, row2) : acc.push(row1);
			return acc;
		}, []);
	}

	// Handle Expansion Capability
	public expand(row: TableRow<T>) {
		row.state = (row.state === 'close') ? 'open' : 'close';

		// Single expansion mode
		if (this.conf('singleExpansionMode', true) && this.curentSelection && this.curentSelection !== row) {
			this.curentSelection.state = 'close';
		}
		this.curentSelection = row;
	}

	public isExpansion(i: number, row: TableRow<T>): boolean {
		return !row.state;
	}

	public get visibleColumns(): Array<string> {
		const hidden: Array<string> = this.conf('hideColumns');
		return this.displayedColumns.filter((col => !hidden || !hidden.includes(col)));
	}

	public get rowSelectable(): boolean {
		return this.conf('rowSelectable');
	}

	public select(row: TableRow<T>) {
		if (this.rowSelectable) {
			this.selectItem.emit(row.item);
		}
	}

	// Handle configuration
	protected conf(key: keyof TableConfiguration, fallback?: any): any {
		return (this.configuration && this.configuration[key] !== undefined) ? this.configuration[key] : fallback;
	}
}
