import { Months, WeekDays } from '@mdib/utils';

export class DaysAndMonthsHelper {
	private static readonly weekDaysList: WeekDayMapper[] = [
		{ 'number': '1', 'day': WeekDays.monday },
		{ 'number': '2', 'day': WeekDays.tuesday },
		{ 'number': '3', 'day': WeekDays.wednesday },
		{ 'number': '4', 'day': WeekDays.thursday },
		{ 'number': '5', 'day': WeekDays.friday },
		{ 'number': '6', 'day': WeekDays.saturday },
		{ 'number': '7', 'day': WeekDays.sunday },
	];
	private static readonly monthsList: MonthMapper[] = [
		{ 'number': '1', 'month': Months.january },
		{ 'number': '2', 'month': Months.february },
		{ 'number': '3', 'month': Months.march },
		{ 'number': '4', 'month': Months.april },
		{ 'number': '5', 'month': Months.may },
		{ 'number': '6', 'month': Months.june },
		{ 'number': '7', 'month': Months.july },
		{ 'number': '8', 'month': Months.august },
		{ 'number': '9', 'month': Months.september },
		{ 'number': '10', 'month': Months.october },
		{ 'number': '11', 'month': Months.november },
		{ 'number': '12', 'month': Months.december },
	];

	public static dayToXcl(day: string): string {
		const tuple = this.weekDaysList.find((element: WeekDayMapper) => element.day === day);
		return tuple ? tuple.number : null;
	}

	public static dayFromXcl(numberDay: string): string {
		const tuple = this.weekDaysList.find((element: WeekDayMapper) => element.number === numberDay);
		return tuple ? tuple.day : '';
	}

	public static monthToXcl(month: string): string {
		const tuple = this.monthsList.find((element: MonthMapper) => element.month === month);
		return tuple ? tuple.number : null;
	}

	public static monthFromXcl(numberMonth: string): string {
		const tuple = this.monthsList.find((element: MonthMapper) => element.number === numberMonth);
		return tuple ? tuple.month : '';
	}
}

interface WeekDayMapper {
	number: string;
	day: string;
}

interface MonthMapper {
	number: string;
	month: string;
}
