import { AbstractControl } from '@angular/forms';
import { InputError } from '../model/utils.typings';

export namespace PhoneValidator {

	export const INVALIDPHONE = {phoneFormat: <InputError> {translationKey: 'utilsModule.phoneValidator.invalidPhone'}};

	export function validate(control: AbstractControl): {[key: string]: any} | null {
		// const basicPhoneRegex = /^\s*(\(?\s?\+?[0-9]{2,4}\s?\)?\s?)?\d{0,5}\s?\/?\s?((\d{1,4}[\s\.\-\(\)]?){3,5}\d{2,4}){1}\s*$/;
		const phoneRegex = /^(0|(\+\d{2}))\d{8,9}$/;
		return !control.value || (control.value.length > 6 && phoneRegex.test(control.value)) ? null : INVALIDPHONE;
	}
}
