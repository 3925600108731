import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { DigipassAuthenticationService } from './service/digipass-authentication.service';
import { DigipassAuthenticationServiceProxy } from './service/digipass-authentication.service.proxy';
import { OtpAuthenticationService } from './service/otp-authentication.service';
import { OtpAuthenticationServiceProxy } from './service/otp-authentication.service.proxy';
import { PasswordAuthenticationService } from './service/password-authentication.service';
import { PasswordAuthenticationServiceProxy } from './service/password-authentication.service.proxy';

@NgModule({
	imports: [
		ReactiveFormsModule,
	],
})
export class SessionsModesModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: SessionsModesModule,
			providers: [
				{ provide: PasswordAuthenticationService, useClass: PasswordAuthenticationServiceProxy },
				{ provide: DigipassAuthenticationService, useClass: DigipassAuthenticationServiceProxy },
				{ provide: OtpAuthenticationService, useClass: OtpAuthenticationServiceProxy },
			],
		};
	}
}
