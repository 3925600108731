import { Injectable } from '@angular/core';

import { BeneficiariesServiceV2 as BeneficiariesService, BeneficiaryV2 as Beneficiary } from '@mdib/beneficiaries';
import { BackendOperation, SearchCriteria, SearchResult } from '@mdib/core/adapters';

import { CreateOperationMemory, RetrieveOperationMemory, UpdateOperationMemory, DeleteOperationMemory, SearchOperationMemory, SearchCriteriaMemory } from '@mdib-memory/core';

import { BeneficiariesMock } from '../mocks/beneficiaries.mock';

@Injectable({
	providedIn: 'root'
})
export class BeneficiariesMemoryService extends BeneficiariesService {

	public create(): BackendOperation<Beneficiary, Beneficiary> {
		return new CreateOperationMemory(BeneficiariesMock, 'id', Beneficiary);
	}

	public retrieve(): BackendOperation<any, Beneficiary> {
		return new RetrieveOperationMemory(BeneficiariesMock, 'id');
	}

	public update(): BackendOperation<Partial<Beneficiary>, Beneficiary> {
		return new UpdateOperationMemory(BeneficiariesMock, 'id');
	}

	public delete(): BackendOperation<any, Beneficiary> {
		return new DeleteOperationMemory(BeneficiariesMock, 'id');
	}

	public search(): BackendOperation<SearchCriteria<SearchCriteriaMemory>, SearchResult<Beneficiary>> {
		return new SearchOperationMemory(BeneficiariesMock);
	}

}
