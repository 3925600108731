import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ConfigService } from '@mdib/config';
import { isValid } from 'iban';

import { UtilsHelper } from '../helper/utils-helper';
import { InputError } from '../model/utils.typings';

export namespace IbanValidator {

	export const FORMAT = {ibanFormat: <InputError> {translationKey: 'utilsModule.ibanValidator.invalidFormat'}};
	export const MAXLENGHT = {ibanFormat: <InputError> {translationKey: 'utilsModule.ibanValidator.maxLenght'}};
	export const MINLENGHT = {ibanFormat: <InputError> {translationKey: 'utilsModule.ibanValidator.minLenght'}};
	export const DUTCHIBAN = {ibanFormat: <InputError> {translationKey: 'utilsModule.ibanValidator.dutchIbanRequired'}};

	export function validateIban(control: AbstractControl): ValidationErrors | null {
		const config = new ConfigService();
		if (!!control.value && String(control.value).length >= config.ibanMinLenght && String(control.value).length <= config.ibanMaxLength) {
			try {
				return isValid(control.value) ? null : IbanValidator.FORMAT;
			} catch (e) {
				return IbanValidator.FORMAT;
			}

		}
		return null;
	}

	export function validateIbanForUrgentPayment(control: AbstractControl): ValidationErrors | null {
		if (!!control.value && !String(control.value).startsWith('NL')) {
			return IbanValidator.DUTCHIBAN;
		}

		return null;
	}

	export function maxLenght(control: AbstractControl): ValidationErrors | null {
		if (!!control.value) {
			return UtilsHelper.onlyAlpha(control.value).length <= new ConfigService().ibanMaxLength ? null : IbanValidator.MAXLENGHT;
		}
		return null;
	}

	export function minLenght(control: AbstractControl): ValidationErrors | null {
		if (!!control.value) {
			return UtilsHelper.onlyAlpha(control.value).length >= new ConfigService().ibanMinLenght ? null : IbanValidator.MINLENGHT;
		}
		return null;
	}
}
