import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { CommonValidator } from '@mdib/utils';

export class PersonForm {

	private form: FormGroup;

	constructor(private fb: FormBuilder) {
		this.create();
	}

	private create() {
		this.form = this.fb.group({
			id: [''],
			name: ['', [CommonValidator.required]],
		});
	}

	get group(): AbstractControl {
		return this.form;
	}

	get id(): AbstractControl {
		return this.form.get('id');
	}

	get name(): AbstractControl {
		return this.form.get('name');
	}
}
