import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { AccountStatement, AccountStatementsCommonService, AccountStatementsPageFilter, AccountStatementStatus } from '@mdib/account-statements';
import { ParameterModel } from '@mdib/http';
import { SessionsService, SessionUtilsService } from '@mdib/sessions';
import { MdibFile, ServiceResponse, UtilsHelper } from '@mdib/utils';
import { XclRepresentedClientModel } from '@mdib-xcl/customers';
import { AbstractOrderXCLModel, FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, MultigoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { FileEntryXcl, FileMapperXclService, XclDateFormatter } from '@mdib-xcl/utils';

import { XclAccountStatementHelper } from '../helper/xcl-account-statement-helper';
import { AccountStatementsMapperXclService } from './account-statements-mapper-xcl.service';
import { AccountStatementXcl } from '../model/account-statement-xcl';

@Injectable({
	providedIn: 'root',
})
export class AccountStatementsXclService extends AccountStatementsCommonService {

	constructor(private xclAccountStatementsMapperService: AccountStatementsMapperXclService,
		private xclHttpService: XclHttpService,
		private sessionService: SessionsService,
		private feedbacksExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private fileMapperXclService: FileMapperXclService,
		private sessionUtilsService: SessionUtilsService,
	) {
		super();
	}

	public list(index?: number, count?: number, filter?: AccountStatementsPageFilter): Observable<ServiceResponse<AccountStatement[]>> {
		const params: ParameterModel[] = [
			new ParameterModel('start', (index || 0).toString()),
			new ParameterModel('transmissionMedium', XclAccountStatementHelper.getXclCodeFromFileFormat(filter.fileFormat)),
			new ParameterModel('accountNature', 'E'),
		];

		// When no maxResults parameter is provided, the CBS returns all the values
		if (count !== null) {
			params.push(new ParameterModel('maxResults', count.toString()));
		}

		if (!UtilsHelper.nullOrUndefined(filter.representedClientNumber)) {
			params.push(new ParameterModel('representedClientNumber', filter.representedClientNumber));
		}

		// Filter
		this.fillFilterCriteria(params, filter);

		const xclObservable = this.xclHttpService.execute('account-statements-list', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<AccountStatementXcl>>;

		// Extract the account statement list from the root "goalList" property returned by the XCL
		// Return an array of account statement (functional model) corresponding to
		// the array of account statement returned by the XCL (technical model)
		return xclObservable.pipe(map((xclOrder: MultigoalOrderXCLModel<AccountStatementXcl>) =>
			new ServiceResponse<AccountStatement[]>(
				this.xclAccountStatementsMapperService.accountStatementXclToAccountStatementList(xclOrder.goalList),
				this.feedbacksExtractor.extract(xclOrder),
				{ totalCount: xclOrder.rowCount },
			),
		));
	}

	public count(filter?: AccountStatementsPageFilter): Observable<ServiceResponse<number>> {
		throw new Error('Method not needed anymore');
	}


	public get(name: string): Observable<ServiceResponse<MdibFile>> {
		let xclObservable: Observable<MonogoalOrderXCLModel<FileEntryXcl>>;
		xclObservable = this.xclHttpService.execute(
			'file-loader', XclHttpService.GET, [<ParameterModel>{ par: 'fileName', val: name }],
		) as Observable<MonogoalOrderXCLModel<FileEntryXcl>>;

		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<FileEntryXcl>) =>
			new ServiceResponse<MdibFile>(
				this.fileMapperXclService.fileXclToMdibFile(xclOrder.goal),
				this.feedbacksExtractor.extract(xclOrder),
			),
		));
	}

	public update(accountStatement: AccountStatement): Observable<ServiceResponse<AccountStatement>> {
		const params: ParameterModel[] = [
			new ParameterModel('identifier', accountStatement.identifier),
		];

		const body = {
			'accountNature': 'E',
			'accountNumber': accountStatement.accountNumber,
			'statementYear': accountStatement.statementNumberYear,
			'addressSequenceNumber': accountStatement.sequenceNumber,
			'transmissionMedium': XclAccountStatementHelper.getXclCodeFromFileFormat(accountStatement.file.format),
			'statementNumber': accountStatement.statementNumber,
		};

		const xclObservable = this.xclHttpService
			.execute('account-statements-update-init', XclHttpService.PUT, params).pipe(
				mergeMap((order: MonogoalOrderXCLModel<AccountStatementXcl>) =>
					this.xclHttpService.execute(
						'account-statements-update-retrieve',
						XclHttpService.PUT,
						[<ParameterModel>{ par: 'reference', val: order.reference }],
					)),
				mergeMap((order: MonogoalOrderXCLModel<AccountStatementXcl>) =>
					this.xclHttpService.execute(
						'account-statements-update-validate',
						XclHttpService.PUT,
						[<ParameterModel>{ par: 'reference', val: order.reference }],
						body,
					)),
				mergeMap((order: MonogoalOrderXCLModel<AccountStatementXcl>) =>
					this.xclHttpService.execute(
						'account-statements-update-confirm',
						XclHttpService.PUT,
						[<ParameterModel>{ par: 'reference', val: order.reference }],
					)),
			) as Observable<MonogoalOrderXCLModel<AccountStatementXcl>>;

		// Extract the account statement from the root "goal" property returned by the XCL
		// Return a account statement (functional model) corresponding to account statement returned by the XCL (technical model)
		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<AccountStatementXcl>) =>
			new ServiceResponse<AccountStatement>(
				this.xclAccountStatementsMapperService.accountStatementXclToAccountStatement(xclOrder.goal),
				this.feedbacksExtractor.extract(xclOrder),
			)));
	}

	public getRepresentedClientNumber(): Observable<ServiceResponse<string>> {
		const params: ParameterModel[] = [
			new ParameterModel('groupName', '02'),
			new ParameterModel('principalIdentification', this.sessionUtilsService.getSessionActiveUserId()),
			new ParameterModel('defaultRepresentedClientSwitch', 'true'),
		];
		const xclObservable = this.xclHttpService.execute('person-mandates', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<XclRepresentedClientModel>>;
		return xclObservable.pipe(map((xclOrder: MultigoalOrderXCLModel<XclRepresentedClientModel>) =>
			new ServiceResponse<string>(
				this.getDefaultRepresentedClient(xclOrder.goalList),
				this.feedbacksExtractor.extract(xclOrder),
			),
		));
	}

	private getDefaultRepresentedClient(list: XclRepresentedClientModel[]): string {
		let representedClientIdentity = '';
		list.forEach((client: XclRepresentedClientModel) => {
			if (client.defaultRepresentedClientSwitch) {
				representedClientIdentity = client.representedClientIdentity;
			}
		});
		return representedClientIdentity;
	}

	private fillFilterCriteria(params: ParameterModel[], statementFilter: AccountStatementsPageFilter | undefined) {
		if (statementFilter == null) {
			return;
		}
		if (!UtilsHelper.nullOrUndefined(statementFilter.cashAccount) && statementFilter.cashAccount.number) {
			params.push(new ParameterModel('accountNumber', statementFilter.cashAccount.number));
		}
		if (statementFilter.status != null) {
			const statusList: string[] = this.xclAccountStatementsMapperService.statusToStatusXcl(statementFilter.status);
			statusList.forEach((status: string) =>
				params.push(new ParameterModel('statusList', status)),
			);
		}
		if (statementFilter.accountingDateFrom != null) {
			params.push(new ParameterModel('firstDate', XclDateFormatter.convertDateToXCLDateFormat(statementFilter.accountingDateFrom)));
		} else if (statementFilter.accountingDateTo != null) {
			params.push(new ParameterModel('firstDate', XclDateFormatter.convertDateToXCLDateFormat(new Date(-8640000000000000))));
		}
		if (statementFilter.accountingDateTo != null) {
			params.push(new ParameterModel('lastDate', XclDateFormatter.convertDateToXCLDateFormat(statementFilter.accountingDateTo)));
		} else if (statementFilter.accountingDateFrom != null) {
			params.push(new ParameterModel('lastDate', XclDateFormatter.convertDateToXCLDateFormat(new Date())));
		}
		if (statementFilter.statementNumberFrom != null) {
			params.push(new ParameterModel('firstStatementNumber', statementFilter.statementNumberFrom.toString()));
		}
		if (statementFilter.statementNumberTo != null) {
			params.push(new ParameterModel('lastStatementNumber', statementFilter.statementNumberTo.toString()));
		}
		if (statementFilter.statementYear != null) {
			params.push(new ParameterModel('statementYear', statementFilter.statementYear.toString()));
		}
	}

}
