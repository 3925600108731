import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { ServiceResponse, ConfigurationService, CacheService, Feedback, FeedbackTypes } from '@mdib/utils';
import { Authentication } from '@mdib/sessions';
import { DigipassAuthenticationService } from '@mdib/sessions-modes';
import { AuthorizationsService } from '@mdib/core/security';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationXclService } from './authentication-xcl.service';
import { XclHttpService } from '../../http/service/xcl-http.service';
import { ParameterSetsService } from '@mdib/core/customization';

@Injectable({
	providedIn: 'root'
})
export class DigipassAuthenticationXclService extends AuthenticationXclService implements DigipassAuthenticationService {

	protected AUTHENTICATION_TYPE = 'TWO';

	constructor(
		private cacheService: CacheService,
		protected xclHttpService: XclHttpService,
		protected configurationService: ConfigurationService,
		protected authorizationsService: AuthorizationsService,
		protected translateService: TranslateService,
		protected config: ConfigurationService,
		protected parameterSetsService: ParameterSetsService,
	) {
		super(
			xclHttpService,
			configurationService,
			authorizationsService,
			translateService,
			config,
			parameterSetsService,
		);
	}

	public authenticate(username: string, digipassResponse: string): Observable<ServiceResponse<Authentication>> {
		return super.prepareAuthentication(username).pipe(mergeMap(response => {
			const authentication = response.getModel();
			return super.doAuthenticate(authentication, `${digipassResponse}`);
		}));
	}

	public loadChallenge(): Observable<ServiceResponse<string>> {
		const currentLanguage = this.translateService.currentLang;

		const requestBody = {
			'language': currentLanguage.match('^[a-z]{2}$') ? currentLanguage : 'nl',
			'securityContext': {
				'authenticationType': this.AUTHENTICATION_TYPE,
				'deviceType': 'DSK',
				'company': this.configurationService.instant('xcl.company.id')
			}
		};

		// Calls
		return this.cacheService.get(this.cacheService.generateCacheKeyFromParams('digipass-authentication-challenge'), this.xclHttpService.execute('session-init', XclHttpService.POST, [], requestBody).pipe(
			mergeMap(result => {
				// In case a session is still open, close it first
				if (result.state === '4000') {
					return this.xclHttpService
						.execute('session-close', XclHttpService.POST)
						.pipe(mergeMap(order => this.xclHttpService.execute('session-init', XclHttpService.POST, [], requestBody)));
				} else {
					return of(result);
				}
			}),
			map(result => {
				if (result.credentials && result.credentials.challenge) {
					return new ServiceResponse(result.credentials.challenge);
				} else {
					this.cacheService.invalidate('digipass-authentication-challenge');
					throw ServiceResponse.emptyWithOnefeedback(new Feedback('loadChallengeIssue', FeedbackTypes.BACKEND_ERROR, 'An issue occurred during the loading of the challenge.'));
				}
			})
		));
	}
}
