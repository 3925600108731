import { ILayout } from '../interfaces/layout.interface';

export class MinimalLayout implements ILayout {
	cols = 3;
	numpadKeys = [
		[1, 2, 3],
		[4, 5, 6],
		[7, 8, 9],
		[undefined, 0, 'Backspace'],
	];
}
