import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AsideModule } from '@mdib/aside/aside.module';
import { AsideComponent } from '@mdib/aside/component/aside/aside.component';
import { HeaderModule } from '@mdib/header/header.module';
import { FooterModule } from '@mdib/footer/footer.module';
import { HeaderComponent } from '@mdib/header/component/header/header.component';
import { FooterComponent } from '@mdib/footer/component/footer/footer.component';
import { DashboardPageComponent } from './component/dashboard-page/dashboard-page.component';
import { SessionGuardService } from '@mdib/sessions';

const ROUTES = [
	{
		path: 'dashboard',
		canActivate: [SessionGuardService],
		data: { authorizedTokens: ['dashboard'] },
		children: [
			{
				path: '',
				component: DashboardPageComponent,
			},
			{
				path: '',
				component: HeaderComponent,
				outlet: 'header'
			},
			{
				path: '',
				component: AsideComponent,
				outlet: 'aside'
			},
			{
				path: '',
				component: FooterComponent,
				outlet: 'footer'
			},
		]
	},
];

@NgModule({
	imports: [
		CommonModule,
		HeaderModule,
		AsideModule,
		FooterModule,
		RouterModule.forChild(ROUTES),
	],
	exports: [RouterModule]
})

export class DashboardRoutingModule { }
