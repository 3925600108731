import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericPagesModule } from '@mdib-pages/generic';
// Third party
import { TranslateModule } from '@ngx-translate/core';
// Transversal modules
import { StylingModule } from '@mdib/styling';
import { UtilsModule } from '@mdib/utils';
import { CashAccountsModule } from '@mdib/cash-accounts';

// Our components
import { AccountValuationsRoutingModule } from './account-valuations-routing.module';
import { AccountValuationsPageComponent } from './component/account-valuations-page/account-valuations-page.component';
import { AccountValuationsPageFilterComponent } from './component/account-valuations-page/account-valuations-page-filter/account-valuations-page-filter.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		StylingModule,
		UtilsModule,
		CashAccountsModule,
		AccountValuationsRoutingModule,
		GenericPagesModule,
	],
	declarations: [
		AccountValuationsPageComponent,
		AccountValuationsPageFilterComponent,
	],
})
export class AccountValuationsPagesModule {}
