import { ContactAddress } from '@mdib/customers';
import { CommunicationTypes } from '@mdib/commons';
import { OperationStatus, StandingOrder, StandingOrderExecutionCode, StandingOrderFrequency } from '@mdib/standing-orders';
import { StandingOrderAmountType, StandingOrderPeriodicity, WeekDays } from '@mdib/standing-orders/model/standing-orders.enum';
import * as moment from 'moment/moment';

export class MockStandingOrders {

	static getMockStandingOrders(): StandingOrder[] {
		return [
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAA',
				ordererAccountNumber: 'BE56305177635588',
				ordererAccountAlias: 'Daily',
				counterpartyAccountNumber: 'BE11736988971648',
				counterpartyName: 'Pierre Gewelt',
				executionCode: StandingOrderExecutionCode.BeginningOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2018-06-29').toDate(),
				status: OperationStatus.Ongoing,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Fixed,
				typeIdentification: '01',
				fixedAmount: 7.85,
				targetBalance: null,
				minimumPaymentAmount: null,
				maximumPaymentAmount: null,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAB',
				ordererAccountNumber: 'BE95750642304658',
				ordererAccountAlias: 'For the kids',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Gary Cox',
				executionCode: StandingOrderExecutionCode.BeginningOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2018-06-10').toDate(),
				status: OperationStatus.Unknown,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Variable,
				typeIdentification: '05',
				fixedAmount: null,
				targetBalance: 6.55,
				minimumPaymentAmount: 10,
				maximumPaymentAmount: 100,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAC',
				ordererAccountNumber: 'BE95750642304658',
				ordererAccountAlias: 'For the kids',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Steven Hill',
				executionCode: StandingOrderExecutionCode.EndOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2018-03-21').toDate(),
				status: OperationStatus.AwaitingValidation,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Variable,
				typeIdentification: '05',
				fixedAmount: null,
				targetBalance: 6.55,
				minimumPaymentAmount: 10,
				maximumPaymentAmount: 100,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAD',
				ordererAccountNumber: 'BE56305177635588',
				ordererAccountAlias: 'Daily',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Chester Matis',
				executionCode: StandingOrderExecutionCode.BeginningOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2018-02-28').toDate(),
				status: OperationStatus.AwaitingValidation,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Fixed,
				typeIdentification: '01',
				fixedAmount: 2.28,
				targetBalance: null,
				minimumPaymentAmount: null,
				maximumPaymentAmount: null,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAE',
				ordererAccountNumber: 'BE95750642304658',
				ordererAccountAlias: 'For the kids',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Edgar Gossman',
				executionCode: StandingOrderExecutionCode.EndOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2018-01-26').toDate(),
				status: OperationStatus.Ongoing,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Variable,
				typeIdentification: '05',
				fixedAmount: null,
				targetBalance: 6.55,
				minimumPaymentAmount: 10,
				maximumPaymentAmount: 100,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAF',
				ordererAccountNumber: 'BE56305177635588',
				ordererAccountAlias: 'Daily',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Wilma Sheard',
				executionCode: StandingOrderExecutionCode.EndOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2017-11-25').toDate(),
				status: OperationStatus.AwaitingValidation,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Fixed,
				typeIdentification: '01',
				fixedAmount: 12.20,
				targetBalance: null,
				minimumPaymentAmount: null,
				maximumPaymentAmount: null,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAG',
				ordererAccountNumber: 'BE56305177635588',
				ordererAccountAlias: 'Daily',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Roger Roque',
				executionCode: StandingOrderExecutionCode.EndOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2017-11-13').toDate(),
				status: OperationStatus.AwaitingValidation,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Fixed,
				typeIdentification: '01',
				fixedAmount: 28.10,
				targetBalance: null,
				minimumPaymentAmount: null,
				maximumPaymentAmount: null,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAH',
				ordererAccountNumber: 'BE56305177635588',
				ordererAccountAlias: 'Daily',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Pierre Gewelt',
				executionCode: StandingOrderExecutionCode.BeginningOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2017-10-15').toDate(),
				status: OperationStatus.Ongoing,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Variable,
				typeIdentification: '05',
				fixedAmount: null,
				targetBalance: 6.55,
				minimumPaymentAmount: 10,
				maximumPaymentAmount: 100,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAI',
				ordererAccountNumber: 'BE56305177635588',
				ordererAccountAlias: 'Daily',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Malcolm Pressnell',
				executionCode: StandingOrderExecutionCode.BeginningOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2017-10-04').toDate(),
				status: OperationStatus.Ongoing,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Fixed,
				typeIdentification: '01',
				fixedAmount: 3.00,
				targetBalance: null,
				minimumPaymentAmount: null,
				maximumPaymentAmount: null,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAJ',
				ordererAccountNumber: 'BE56305177635588',
				ordererAccountAlias: 'Daily',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Mark Flores',
				executionCode: StandingOrderExecutionCode.EndOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2017-09-15').toDate(),
				status: OperationStatus.AwaitingValidation,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Variable,
				typeIdentification: '05',
				fixedAmount: null,
				targetBalance: 6.55,
				minimumPaymentAmount: 10,
				maximumPaymentAmount: 100,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAK',
				ordererAccountNumber: 'BE95750642304658',
				ordererAccountAlias: 'For the kids',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Shellie Trevino',
				executionCode: StandingOrderExecutionCode.BeginningOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2017-08-27').toDate(),
				status: OperationStatus.AwaitingValidation,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Variable,
				typeIdentification: '05',
				fixedAmount: null,
				targetBalance: 6.55,
				minimumPaymentAmount: 10,
				maximumPaymentAmount: 100,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAL',
				ordererAccountNumber: 'BE56305177635588',
				ordererAccountAlias: 'Daily',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Paulina Torres',
				executionCode: StandingOrderExecutionCode.EndOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2017-07-13').toDate(),
				status: OperationStatus.Ongoing,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Fixed,
				typeIdentification: '01',
				fixedAmount: 8.10,
				targetBalance: null,
				minimumPaymentAmount: null,
				maximumPaymentAmount: null,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAM',
				ordererAccountNumber: 'BE56305177635588',
				ordererAccountAlias: 'Daily',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Jessica Salas',
				executionCode: StandingOrderExecutionCode.BeginningOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2017-05-04').toDate(),
				status: OperationStatus.Ongoing,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Fixed,
				typeIdentification: '01',
				fixedAmount: null,
				targetBalance: null,
				minimumPaymentAmount: null,
				maximumPaymentAmount: null,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAN',
				ordererAccountNumber: 'BE56305177635588',
				ordererAccountAlias: 'Daily',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'William Bryan',
				executionCode: StandingOrderExecutionCode.BeginningOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2017-04-14').toDate(),
				status: OperationStatus.Unknown,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Variable,
				typeIdentification: '05',
				fixedAmount: null,
				targetBalance: 6.55,
				minimumPaymentAmount: 10,
				maximumPaymentAmount: 100,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
			<StandingOrder>{
				reference: 'B5S01PGNGJ4FWRAO',
				ordererAccountNumber: 'BE56305177635588',
				ordererAccountAlias: 'Daily',
				counterpartyAccountNumber: 'NL02ABNA0123456789',
				counterpartyName: 'Chelsea Hinkle',
				executionCode: StandingOrderExecutionCode.EndOfDay,
				startDate: moment('2017-01-02').toDate(),
				periodicity: StandingOrderPeriodicity.Weekly,
				weekDay: WeekDays.Monday,
				dayOfTheMonth1: null,
				dayOfTheMonth2: null,
				paymentDay: null,
				startingMonth: null,
				nextPaymentDate: moment('2017-01-23').toDate(),
				status: OperationStatus.Ongoing,
				frequency: StandingOrderFrequency.Unknown,
				type: StandingOrderAmountType.Fixed,
				typeIdentification: '01',
				fixedAmount: 15.70,
				targetBalance: null,
				minimumPaymentAmount: null,
				maximumPaymentAmount: null,
				currency: 'EUR',
				maturityDate: moment('2017-02-01').toDate(),
				counterpartyAddress: new ContactAddress(),
				communicationType: CommunicationTypes.free,
				communication: 'Mock communication',
				saveBeneficiary: false,
			},
		];
	}
}
