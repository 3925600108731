import { Injectable } from '@angular/core';
import { AccountValuation, AccountValuationsPageFilter } from '@mdib/account-valuations';
import { MdibFile, ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';

/**
 * Abstracts the xcl calls related to {@link AccountValuation}
 */
@Injectable()
export abstract class AccountValuationsService {

	/**
	 * @param {number} offset Offset to start from (by default 0, i.e. from the beginning)
	 * @param {number} limit Maximum number of operations to retrieve (by default 0, i.e. no limit)
	 * @param {AccountValuationsFilter} filter Object containing properties on which the result must be filtered
	 * @example
	 * list(accountNumber, offset, limit)
	 *     .subscribe(
	 *         (serviceResponse: ServiceResponse<AccountValuation[]>) => this.accountValuations = serviceResponse.getModel()
	 *     );
	 */
	public abstract list(offset?: number,
		limit?: number,
		filter?: AccountValuationsPageFilter): Observable<ServiceResponse<AccountValuation[]>>;

	/**
	 * Count the list of account valuations from XCL related to the current principal
	 * @param {AccountValuationsFilter} [filter] Object containing properties on which the result must be filtered
	 * @returns {Observable<ServiceResponse<number>>} The number of account valuations related to principal
	 * @memberof AccountValuationsService
	 */
	public abstract count(filter: AccountValuationsPageFilter): Observable<ServiceResponse<number>>;

	/**
	 * Download the file related to an account valuation
	 * @param {AccountValuation}
	 * @returns {Observable<ServiceResponse<any>>}
	 * @memberof AccountValuationsService
	 */
	public abstract downloadAccountValuationFile(accountValuation: AccountValuation): Observable<ServiceResponse<MdibFile>>;

}
