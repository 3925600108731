import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Signable, Signature } from '@mdib/signature';
import { ServiceResponse } from '@mdib/utils';

@Injectable()
export abstract class DigipassSignatureService {

	/**
	 * Prepares the signable and initialize a signature.
	 *
	 * @param object The object that needs to be signed
	 * @returns An observable to a signature
	 */
	public abstract prepareSignatureFor(object: Signable): Observable<ServiceResponse<Signature>>;

	/**
	 * Creates a signature for an object using the given token.
	 *
	 * @param object The object that needs to be signed
	 * @param password The password to use for creating the signature
	 * @returns An observable to the generated signature
	 */
	public abstract createSignatureFor(object: Signable, token: string): Observable<ServiceResponse<Signature>>;

}
