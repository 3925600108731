import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CashAccount, CashAccountForm } from '@mdib/cash-accounts';
import { CommunicationForm } from '@mdib/commons';
import { ConfigService } from '@mdib/config';
import { AddressForm } from '@mdib/customers';
import { CommonValidator, DataFormattingService, IbanValidator, UtilsHelper } from '@mdib/utils';

import { BeneficiaryType } from '../types/enums/beneficiary-type.enum';
import { Beneficiary } from './beneficiary';

/** @deprecated */
export class BeneficiaryForm {

	private form: FormGroup;
	private readonly address?: AddressForm;
	private readonly _account?: CashAccountForm;
	private readonly communication?: CommunicationForm;

	constructor(
		private fb: FormBuilder,
		private config: ConfigService,
		private format: DataFormattingService,
		private readonly beneficiary?: Beneficiary,
		subscribe = false,
	) {
		this.beneficiary = beneficiary || new Beneficiary();
		this.address = new AddressForm(fb, subscribe, this.beneficiary.address);
		this._account = new CashAccountForm(fb);
		if (!this.beneficiary.account) {
			this.beneficiary.account = new CashAccount();
		}
		this._account.group.patchValue(this.beneficiary.account);
		this._account.number.setValue(this.format.formatIban(this._account.number.value));
		this.communication = new CommunicationForm(fb, this.format, this.config, subscribe, this.beneficiary.communication);
		this.create(subscribe);
	}

	create(subscribe: boolean) {
		this.form = this.fb.group({
			'type': [this.beneficiary.type],
			'fullName': [this.beneficiary.fullName, [Validators.maxLength(this.config.nameMaxLength)]],
			'alias': [this.beneficiary.alias, Validators.maxLength(this.config.nameMaxLength)],
			'communication': this.communicationForm.group,
			'address': this.address.group,
		});
		if (this.beneficiary.type !== BeneficiaryType.INTERNAL) {
			this.addAccountForm();
			this.fullName.setValidators([this.fullName.validator, CommonValidator.required]);
		}
		if (subscribe) { this.subscriptions(); }
	}

	addAccountForm() {
		if (!this.form.contains('account')) {
			this.form.addControl('account', this._account.group);
		}
		if (this.type.value === BeneficiaryType.SEPA) {
			this._account.number.setValidators([CommonValidator.required, IbanValidator.validateIban, IbanValidator.minLenght, IbanValidator.maxLenght]);
		} else if (this.type.value === BeneficiaryType.INTERNATIONAL) {
			this._account.number.setValidators(CommonValidator.required);
		}
	}

	removeAccountForm() {
		if (this.form.contains('account')) {
			this.form.removeControl('account');
			this.clear();
		}
	}

	disable() {
		this.form.disable();
	}

	enable() {
		this.form.enable();
	}

	clear(): void {
		this.fullName.reset();
		this.alias.reset();
		this._account.group.reset();
		this.address.group.reset();
		this.form.updateValueAndValidity();
	}

	private subscriptions() {
		this.addressForm.group.valueChanges.subscribe(() => {
			this.beneficiary.address = this.address.model;
		});
		this.communication.group.valueChanges.subscribe(() => {
			this.beneficiary.communication = this.communication.model;
		});
		this.fullName.valueChanges.subscribe(fullName => this.beneficiary.fullName = fullName);
		this.type.valueChanges.subscribe(type => {
			this.beneficiary.type = type;
			this.type.value === BeneficiaryType.INTERNAL ? this.fullName.setValidators([Validators.maxLength(this.config.nameMaxLength)]) : this.fullName.setValidators([this.fullName.validator, CommonValidator.required]);
		});
		this.alias.valueChanges.subscribe(alias => this.beneficiary.alias = alias);
		this._account.group.valueChanges.subscribe(account => {
			if (UtilsHelper.objectNotEmpty(account)) {
				if (!this.beneficiary.account) {
					this.beneficiary.account = new CashAccount();
				}
				this.beneficiary.account.number = this.format.parseIban(account.number);
				if (this.type.value === BeneficiaryType.INTERNATIONAL) {
					this.beneficiary.account.bic = account.bic;
				}
			}
		});
	}

	get group(): AbstractControl {
		return this.form;
	}

	get model(): Beneficiary {
		return this.beneficiary;
	}

	get addressForm(): AddressForm {
		return this.address;
	}

	get accountForm(): CashAccountForm {
		return this._account;
	}

	get account(): AbstractControl {
		return this.form.get('account');
	}

	get communicationForm(): CommunicationForm {
		return this.communication;
	}

	get fullName(): AbstractControl {
		return this.form.get('fullName');
	}

	get alias(): AbstractControl {
		return this.form.get('alias');
	}

	get type(): AbstractControl {
		return this.form.get('type');
	}
}
