import { Injectable } from '@angular/core';
import { XclSignatureContext, XclSignatureModes } from '@mdib-xcl/core';
import { FileUploadersXclService } from '@mdib-xcl/file-uploaders';
import { AbstractOrderXCLModel, FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, MultigoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { PaymentFileFailureXcl } from '../model/payment-file-failure-xcl';
import { OrderStateHelper, XclAPI, XclDateFormatter } from '@mdib-xcl/utils';
import { ParameterModel } from '@mdib/http';
import { PaymentFile, PaymentFileFailure, PaymentFilesCommonService, PaymentFilesFilter } from '@mdib/payment-files';

import { SignableOperation } from '@mdib/signature';
import { Feedback, FeedbackTypes, MdibFile, ServiceResponse, Status } from '@mdib/utils';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { PaymentFileXcl } from '../model/payment-file-xcl';
import { PaymentFilesMapperXclService } from './payment-files-mapper-xcl.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentFilesXclService extends PaymentFilesCommonService {

	private currentOrderReference: string;

	constructor(private xclHttpService: XclHttpService,
		private feedbacksExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private paymentFilesMapperXclService: PaymentFilesMapperXclService,
		private fileUploadersXclService: FileUploadersXclService,
	) {
		super();
	}

	count(filter?: PaymentFilesFilter): Observable<ServiceResponse<number>> {
		const params: ParameterModel[] = [];
		this.fillFilterCriteria(params, filter);

		return this.xclHttpService.execute('payment-files', XclHttpService.GET, params).pipe(map((xclOrder: AbstractOrderXCLModel) =>
			new ServiceResponse<number>(
				xclOrder.rowCount,
				this.feedbacksExtractor.extract(xclOrder),
			),
		));
	}

	list(index: number, count: number, filter?: PaymentFilesFilter): Observable<ServiceResponse<PaymentFile[]>> {
		const params: ParameterModel[] = [];
		params.push(new ParameterModel('start', (index || 0).toString()));
		if (count) {
			params.push(new ParameterModel('maxResults', count.toString()));
		}

		// Filter
		this.fillFilterCriteria(params, filter);

		const xclObservable = this.xclHttpService.execute('payment-files', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<PaymentFileXcl>>;

		return xclObservable.pipe(map((xclOrder: MultigoalOrderXCLModel<PaymentFileXcl>) =>
			new ServiceResponse<PaymentFile[]>(
				this.paymentFilesMapperXclService.paymentFilesXclListToPaymentFilesList(xclOrder.goalList),
				this.feedbacksExtractor.extract(xclOrder),
				{ totalCount: xclOrder.rowCount },
			),
		));
	}

	confirm(): Observable<ServiceResponse<PaymentFile>> {
		const xclObservable = this.xclHttpService.execute('payment-files-confirm', XclHttpService.PUT,
			[<ParameterModel>{ par: 'reference', val: this.currentOrderReference }]) as Observable<MonogoalOrderXCLModel<PaymentFileXcl>>;

		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<PaymentFileXcl>) =>
			new ServiceResponse<PaymentFile>(
				this.paymentFilesMapperXclService.paymentFilesXclToPaymentFiles(xclOrder.goal),
				this.feedbacksExtractor.extract(xclOrder),
			),
		));
	}

	validate(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		const body = {
			'fileIdentifier': '',
			'fileType': this.paymentFilesMapperXclService.typeToTypeXcl(paymentFile.type),
			'hashAlgorithmCode': this.paymentFilesMapperXclService.hashToHashXcl(paymentFile.hashAlgorithm),
		};

		const xclObservable = this.fileUploadersXclService.upload(paymentFile.file).pipe(
			tap((serviceResponse: ServiceResponse<MdibFile>) => {
				body.fileIdentifier = serviceResponse.getModel().id.toString();
			}),
			mergeMap(() => this.xclHttpService.execute(
				'payment-files', XclHttpService.POST,
			)),
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.xclHttpService.execute(
				'payment-files-retrieve', XclHttpService.PUT,
				[<ParameterModel>{ par: 'reference', val: order.reference }],
			)),
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.xclHttpService.execute(
				'payment-files-validate', XclHttpService.PUT,
				[<ParameterModel>{ par: 'reference', val: order.reference }],
				body,
			))) as Observable<MonogoalOrderXCLModel<PaymentFileXcl>>;

		return xclObservable.pipe(
			tap((xclOrder: MonogoalOrderXCLModel<PaymentFileXcl>) => {
				this.currentOrderReference = xclOrder.reference;
				const file = paymentFile.file.document;
				paymentFile = this.paymentFilesMapperXclService.paymentFilesXclToPaymentFiles(xclOrder.goal);
				paymentFile.file.document = file;
			}), map((xclOrder: MonogoalOrderXCLModel<PaymentFileXcl>) =>
				new ServiceResponse<PaymentFile>(paymentFile, this.feedbacksExtractor.extract(xclOrder)),
			));
	}

	get(fileId: string): Observable<ServiceResponse<PaymentFile>> {
		let xclObservable: Observable<MonogoalOrderXCLModel<PaymentFileXcl>>;

		xclObservable = this.xclHttpService.execute(
			'payment-files-get',
			XclHttpService.GET,
			[<ParameterModel>{ par: 'identifier', val: fileId }],
		) as Observable<MonogoalOrderXCLModel<PaymentFileXcl>>;

		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<PaymentFileXcl>) =>
			new ServiceResponse<PaymentFile>(
				this.paymentFilesMapperXclService.paymentFilesXclToPaymentFiles(xclOrder.goal),
				this.feedbacksExtractor.extract(xclOrder),
			),
		));
	}

	process(fileId: string): Observable<ServiceResponse<SignableOperation<PaymentFile>>> {
		const body = { 'fileIdentifier': fileId };
		let xclObservable: Observable<MonogoalOrderXCLModel<PaymentFileXcl>>;

		xclObservable = this.xclHttpService.execute('payment-files-process',
			XclHttpService.POST).pipe(
				mergeMap((order: MonogoalOrderXCLModel<any>) => this.xclHttpService.execute(
					'payment-files-process-retrieve', XclHttpService.PUT,
					[<ParameterModel>{ par: 'reference', val: order.reference }],
					body,
				)),
				mergeMap((order: MonogoalOrderXCLModel<any>) => this.xclHttpService.execute(
					'payment-files-process-validate', XclHttpService.PUT,
					[<ParameterModel>{ par: 'reference', val: order.reference }],
				))) as Observable<MonogoalOrderXCLModel<PaymentFileXcl>>;

		return xclObservable.pipe(
			tap((xclOrder: MonogoalOrderXCLModel<PaymentFileXcl>) => this.currentOrderReference = xclOrder.reference),
			map((xclOrder: MonogoalOrderXCLModel<PaymentFileXcl>) => this.getFunctionalObservableForSignableOperation(xclOrder)),
		);
	}

	confirmProcess(): Observable<ServiceResponse<PaymentFile>> {
		const xclObservable = this.xclHttpService.execute('payment-files-process-confirm', XclHttpService.PUT,
			[<ParameterModel>{ par: 'reference', val: this.currentOrderReference }]) as Observable<MonogoalOrderXCLModel<PaymentFileXcl>>;

		return this.getFunctionalObservable(xclObservable);
	}

	signProcess(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		const params: ParameterModel[] = this.parameters(this.currentOrderReference, XclAPI.sign);

		const xclObservable: Observable<MonogoalOrderXCLModel<PaymentFileXcl>> =
			this.xclHttpService.execute('payment-files-process', XclHttpService.PUT, params) as Observable<MonogoalOrderXCLModel<PaymentFileXcl>>;

		return this.getFunctionalObservable(xclObservable).pipe(
			tap(() => {
				if (paymentFile.status === Status.Error) {
					throw ServiceResponse.emptyWithOnefeedback(new Feedback('errorWhenSigningProcessPaymentFile', FeedbackTypes.FRONTEND_ERROR, 'Something went wrong when signing the process of the payment file'));
				}
			}));
	}

	delete(fileId: string): Observable<ServiceResponse<SignableOperation<PaymentFile>>> {
		const xclObservable = this.initDeletePaymentFile(fileId).pipe(
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.xclHttpService.execute(
				'payment-files-retrieve', XclHttpService.PUT,
				[<ParameterModel>{ par: 'reference', val: order.reference }],
			)),
		) as Observable<MonogoalOrderXCLModel<PaymentFileXcl>>;

		return xclObservable.pipe(
			tap((xclOrder: MonogoalOrderXCLModel<PaymentFileXcl>) => this.currentOrderReference = xclOrder.reference),
			map((xclOrder: MonogoalOrderXCLModel<PaymentFileXcl>) => this.getFunctionalObservableForSignableOperation(xclOrder)),
		);
	}

	/**
	 * Init deletion of a payment file to get an order reference
	 * @param {string} fileId
	 * @returns {Observable<MonogoalOrderXCLModel<any>>}
	 */
	public initDeletePaymentFile(fileId: string): Observable<MonogoalOrderXCLModel<any>> {

		const xclObservable: Observable<MonogoalOrderXCLModel<any>> = this.xclHttpService.execute('payment-files', XclHttpService.DELETE,
			[<ParameterModel>{ par: 'identifier', val: fileId }]) as Observable<MonogoalOrderXCLModel<any>>;

		return xclObservable;
	}

	public signDelete(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {

		const xclObservable: Observable<MonogoalOrderXCLModel<PaymentFileXcl>> = this.xclHttpService.execute('payment-files-sign', XclHttpService.PUT,
			[<ParameterModel>{ par: 'reference', val: this.currentOrderReference }],
		) as Observable<MonogoalOrderXCLModel<PaymentFileXcl>>;

		return this.getFunctionalObservable(xclObservable).pipe(
			tap(() => {
				if (paymentFile.status === Status.Error) {
					throw ServiceResponse.emptyWithOnefeedback(new Feedback('errorWhenSigningDeletionPaymentFile', FeedbackTypes.FRONTEND_ERROR, 'Something went wrong when signing the deletion of the payment file'));
				}
			}));
	}

	public listFailures(sequenceNumber: string, pageNumber?: number, pageSize?: number): Observable<ServiceResponse<PaymentFileFailure[]>> {
		const params: ParameterModel[] = [];
		params.push(new ParameterModel('sequenceNumber', sequenceNumber));

		const xclObservable = this.xclHttpService.execute('payment-files-failure', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<PaymentFileXcl>>;

		return xclObservable.pipe(map((xclOrder: MultigoalOrderXCLModel<PaymentFileFailureXcl>) =>
			new ServiceResponse<PaymentFileFailure[]>(
				this.paymentFilesMapperXclService.paymentFilesFailureXclListToPaymentFilesFailureList(xclOrder.goalList),
				this.feedbacksExtractor.extract(xclOrder),
			),
		));
	}

	/**
	 * Return a functional observable for a signable payment file
	 * @param {ServiceResponse<PaymentFile>} order
	 * @returns {Observable<ServiceResponse<PaymentFile>>}
	 */
	private getFunctionalObservableForSignableOperation(xclOrder: MonogoalOrderXCLModel<PaymentFileXcl>): ServiceResponse<SignableOperation<PaymentFile>> {
		const signable: SignableOperation<PaymentFile> = new SignableOperation<PaymentFile>(this.paymentFilesMapperXclService.paymentFilesXclToPaymentFiles(xclOrder.goal));
		signable.reference = signable.model.identifier;
		signable.signatureContext = new XclSignatureContext({ signableReference: xclOrder.reference });
		signable.signatureTypesAllowed = xclOrder.signatureTypesAllowed.map(type => XclSignatureModes.fromXclType(type));
		signable.status = OrderStateHelper.getStatusFromXCLOrderStateCode(xclOrder.state);

		return new ServiceResponse<SignableOperation<PaymentFile>>(signable);
	}

	private getFunctionalObservable(technicalObservable: Observable<MonogoalOrderXCLModel<PaymentFileXcl>>): Observable<ServiceResponse<PaymentFile>> {
		return technicalObservable.pipe(map((paymentFileXcl: MonogoalOrderXCLModel<PaymentFileXcl>) => {
			return new ServiceResponse(
				this.paymentFilesMapperXclService.paymentFilesXclToPaymentFiles(paymentFileXcl.goal),
				this.feedbacksExtractor.extract(paymentFileXcl),
			);
		}));
	}

	private parameters(reference: string, phase: string): ParameterModel[] {
		return <ParameterModel[]>[
			new ParameterModel(XclAPI.rest, phase),
			new ParameterModel(XclAPI.reference, reference),
		];
	}

	private fillFilterCriteria(params: ParameterModel[], filter: PaymentFilesFilter | null) {
		if (!filter) { return; }
		if (filter.fileStatus !== null && filter.fileStatus) {
			params.push(new ParameterModel('fileStatus', this.paymentFilesMapperXclService.statusToStatusXcl(filter.fileStatus)));
		}
		if (filter.type !== null && filter.type) {
			params.push(new ParameterModel('fileType', this.paymentFilesMapperXclService.typeToTypeXcl(filter.type)));
		}
		if (filter.uploadDateFrom !== null && filter.uploadDateFrom) {
			params.push(new ParameterModel('firstFileEntryDate', XclDateFormatter.convertDateToXCLDateFormat(filter.uploadDateFrom)));
		} else if (filter.uploadDateTo != null) {
			params.push(new ParameterModel('firstFileEntryDate', XclDateFormatter.convertDateToXCLDateFormat(new Date(-8640000000000000))));
		}
		if (filter.uploadDateTo !== null && filter.uploadDateTo) {
			params.push(new ParameterModel('lastFileEntryDate', XclDateFormatter.convertDateToXCLDateFormat(filter.uploadDateTo)));
		} else if (filter.uploadDateFrom != null) {
			params.push(new ParameterModel('lastFileEntryDate', XclDateFormatter.convertDateToXCLDateFormat(new Date())));
		}
	}

}
