export interface IconButton {
	iconName: string;
	iconLabel: string;
	iconAction?: string;
	link?: string;
	token?: string;
	type?: string;
}

export const DATE_PICKER_FORMATS = {
	parse: {
		dateInput: 'L',
	},
	display: {
		dateInput: 'L',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM',
	},
};

