import { Injectable } from '@angular/core';
import { MockUsers } from '@mdib-memory/sessions';
import { AdapterSwitchService } from '@mdib/core/adapters';
import { ConfigurationService } from '@mdib/utils';

@Injectable()
export class MultiAdaptersService implements AdapterSwitchService {

	constructor(
		private configurationService: ConfigurationService,
	) { }

	public switchForUser(username: string): void {
		if ((Object.values(MockUsers).indexOf(username) >= 0) && this.configurationService.instant('sessioncreation.mockAccessEnabled')) {
			this.configurationService.set('services.activeId', 'memory');
		} else if (username === 'DxpAccess' && this.configurationService.instant('sessioncreation.dxpAccessEnabled')) {
			this.configurationService.set('services.activeId', 'dxp');
		} else {
			this.configurationService.set('services.activeId', 'xcl');
		}
	}
}
