// Mock data for the list of Authentication Statistic Data of the user (in XCL JSON format)
import { AuthenticationStatisticData, AuthenticationStatisticDataBuilder } from '@mdib/sessions';

export class MockAuthenticationStatisticData {
	static getMockAuthenticationStatisticDataList(): AuthenticationStatisticData[] {
		return <AuthenticationStatisticData[]>[
			new AuthenticationStatisticDataBuilder()
				.lastConnectionDateAndTime(new Date('2012-03-02T17:07:00'))
				.authenticationStatisticData,
			new AuthenticationStatisticDataBuilder()
				.lastConnectionDateAndTime(new Date('2017-03-12T20:17:00'))
				.authenticationStatisticData,
			new AuthenticationStatisticDataBuilder()
				.lastConnectionDateAndTime(new Date('2016-03-22T21:45:00'))
				.authenticationStatisticData
		];
	}
}
