export enum SepaDirectDebitPaymentStatus {
	AwaitingProcessing = 'awaitingProcessing',
	AwaitingRejection = 'awaitingRejection',
	AwaitingSettlement = 'awaitingSettlement',
	Executed = 'executed',
	Cancelled = 'cancelled',
	CancelledByRejection = 'cancelledByRejection',
	CancelledByReturn = 'cancelledByReturn',
	CancelledByCancellationRequest = 'cancelledByCancellationRequest',
	CancelledByRecovery = 'cancelledByRecovery',
	CancelledForNotAuthorisedTransfer = 'cancelledForNotAuthorisedTransfer',
	CancelledByRepayment = 'cancelledByRepayment',
	SubjectToRejection = 'subjectToRejection',
	SubjectToReturn = 'subjectToReturn',
	SubjectToCancel = 'subjectToCancel',
	SubjectToRefund = 'subjectToRefund',
	SubjectToNotAuthorisedTransfer = 'subjectToNotAuthorisedTransfer',
	SubjectToRepayment = 'subjectToRepayment'
}
