import { Component, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

import { AdapterSwitchService } from '@mdib/core/adapters';
import { AuthenticationProcessComponent, AuthenticationModesService, Authentication } from '@mdib/sessions';
import { SvgIconPath } from '@mdib/styling';
import { CacheService, CommonValidator, ServiceResponse, ConfigurationService } from '@mdib/utils';

import { DigipassAuthenticationService } from '../../service/digipass-authentication.service';
import { DigipassAuthenticationMemoryService } from '@mdib-memory/sessions';

@Component({
	selector: 'digipass-authentication-process',
	templateUrl: './digipass-authentication-process.component.html',
	styleUrls: ['./digipass-authentication-process.component.scss'],
})
export class DigipassAuthenticationProcessComponent extends AuthenticationProcessComponent implements OnInit {

	// This field is made static to hold the disabled time, even when the component is destroyed
	static disableTime = 0;

	readonly NAMESPACE = 'sessionsModesModule.digipassAuthenticationProcessComponent.';

	public digipassAuthenticationFormGroup: FormGroup;

	public digipassIconPath = SvgIconPath.DIGIPASS;

	public challenge: string;

	public isDigipassAuthenticationDown = false;

	/**
	 * @property Indicates if the curent process is valid
	 */
	public failed = false;

	// This property defines the time for which the login button has to be disabled according to the number of incorrect login attempts
	private definedDisableTime: number[];

	private timer: number;

	constructor(
		private digipassAuthenticationService: DigipassAuthenticationService,
		private formBuilder: FormBuilder,
		private authenticationService: AuthenticationModesService,
		@Optional() private adapterSwitchService: AdapterSwitchService,
		private cacheService: CacheService,
		private configurationService: ConfigurationService,
	) {
		super();
		this.digipassAuthenticationFormGroup = this.formBuilder.group({
			username: [null, [CommonValidator.required]],
			digipassResponse: [null, [CommonValidator.required]],
		});
	}

	public ngOnInit() {
		this.username.setValue(this.user && this.user.person.name);
		this.digipassResponse.setValue('');
		this.challenge = undefined;
		this.isDigipassAuthenticationDown = false;
		this.loadChallenge();
		this.definedDisableTime = this.configurationService.instant('sessioncreation.disableTime');
	}

	public authenticate() {
		const username = this.digipassAuthenticationFormGroup.get('username').value || '';
		this.digipassAuthenticationFormGroup.get('username').setValue(username.trim());

		if (this.digipassAuthenticationFormGroup.invalid) {
			return;
		}

		// TODO Find another way to switch Mock ON/OFF
		if (this.adapterSwitchService) {
			this.adapterSwitchService.switchForUser(this.username.value);
		}

		this.digipassAuthenticationFormGroup.disable();
		this.cacheService.invalidate();

		this.digipassAuthenticationService
			.authenticate(this.username.value, this.digipassResponse.value)
			.subscribe((response: ServiceResponse<Authentication>) => {
				this.onSuccess.emit(response.getModel());
			}, (error: ServiceResponse<Authentication>) => {
				// Form is in error
				this.failed = true;
				this.onEvent.emit(new ErrorEvent('error', { error: error.getFeedbacks()[0].key }));
				let incorrectLoginAttempts = this.authenticationService.getIncorrectAttempts();
				this.authenticationService.setIncorrectAttempts(++incorrectLoginAttempts);
				// To disable login for some time based on the number of login attempts
				this.disableTime = incorrectLoginAttempts <= this.definedDisableTime.length ? this.definedDisableTime[incorrectLoginAttempts - 1] : this.definedDisableTime[this.definedDisableTime.length - 1];
				if (this.disableTime > 0) {
					this.timer = window.setInterval(() => this.decreaseCounter(), 1000);
				}
				this.digipassAuthenticationFormGroup.enable();
			}, () => {
				this.digipassAuthenticationFormGroup.enable();
			},
			);
	}

	public cancel() {
		this.onEvent.emit(new Event('abort'));
	}

	private loadChallenge(): void {
		this.digipassAuthenticationService
			.loadChallenge()
			.subscribe((response: ServiceResponse<string>) => {
				this.challenge = response.getModel();
			}, (error: ServiceResponse<string>) => {
				this.isDigipassAuthenticationDown = true;
				this.onEvent.emit(new ErrorEvent('error', { error: error.getFeedbacks()[0].key }));
			},
			);
	}

	/**
	 * Decreases the disable time by 1 sec if disable time greater than 0, else disables the timer
	 */
	private decreaseCounter(): void {
		this.disableTime === 0 ? clearInterval(this.timer) : this.disableTime--;
	}

	get disableTime(): number {
		return DigipassAuthenticationProcessComponent.disableTime;
	}

	set disableTime(time: number) {
		DigipassAuthenticationProcessComponent.disableTime = time;
	}

	get username(): AbstractControl {
		return this.digipassAuthenticationFormGroup.get('username');
	}

	get digipassResponse(): AbstractControl {
		return this.digipassAuthenticationFormGroup.get('digipassResponse');
	}
}
