import { Component, HostListener } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'mdib-aside',
	templateUrl: './aside.component.html',
	styleUrls: ['./aside.component.scss'],
	animations: [
		trigger('scrollAnimation', [
			state('show', style({ transform: 'translateX(0%)' })),
			state('hide', style({ transform: 'translateX(-100%)' })),
			transition('show => hide', [animate('1000ms ease-in-out')]),
			transition('hide => show', [animate('1000ms ease-in-out')]),
		]),
	]
})
export class AsideComponent {

	lastScrollFromTop = 0;
	showFloatingMenu = 'show';

	@HostListener('window:scroll', [])
	onScrollEvent() {
		const currentScrollPosition = window.pageYOffset;
		this.showFloatingMenu = currentScrollPosition < this.lastScrollFromTop ? 'show' : 'hide';
		this.lastScrollFromTop = currentScrollPosition;
	}
}
