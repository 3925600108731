export namespace NotificationHelper {
	// TODO : duplicated from utils helper to remove when circular dependencies will be managed between notification and utils
	/**
	 * Checks whether a value is null or undefined.
	 * @param {any} value : any value
	 * @returns {string}: true if null or undefined.
	 */
	export function nullOrUndefined(value: any): boolean {
		return value === null || value === undefined;
	}

	/**
	 * Checks if an object or an iterable has at least one defined property.
	 * @param {any} value : any value
	 * @returns {string}: true if the object has at least one defined property and false for any other value
	 */
	export function objectNotEmpty(o: Object): boolean {
		return !!o && typeof o === 'object' ? Object.entries(o).some((key, value) => {
			return !NotificationHelper.nullOrUndefined(key[1]);
		}) : false;
	}
}

