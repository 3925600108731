import { OperationStatus } from '@mdib/payments';

export class OperationStatusHelper {
	private static readonly statusToXCLCodeMappingList: PaymentStateTuple[] = [
		{'code': '0000', 'state': OperationStatus.awaitingProcessing},
		{'code': '0900', 'state': OperationStatus.awaitingProcessing},
		{'code': '1000', 'state': OperationStatus.awaitingProcessing},
		{'code': '1500', 'state': OperationStatus.awaitingProcessing},
		{'code': '1601', 'state': OperationStatus.technicalIncident},
		{'code': '1900', 'state': OperationStatus.transferValidated},
		{'code': '1950', 'state': OperationStatus.memoOrder},
		{'code': '2000', 'state': OperationStatus.awaitingProcessing},
		{'code': '2001', 'state': OperationStatus.awaitingProcessing},
		{'code': '3100', 'state': OperationStatus.awaitingProcessing},
		{'code': '3601', 'state': OperationStatus.awaitingProcessing},
		{'code': '3900', 'state': OperationStatus.awaitingProcessing},
		{'code': '4000', 'state': OperationStatus.awaitingProcessing},
		{'code': '7000', 'state': OperationStatus.awaitingProcessing},
		{'code': '6000', 'state': OperationStatus.awaitingSettlement},
		{'code': '9000', 'state': OperationStatus.executed},
		{'code': '9200', 'state': OperationStatus.cancelled},
		{'code': '9900', 'state': OperationStatus.cancelled},
		{'code': '9904', 'state': OperationStatus.cancelled},
		{'code': '9920', 'state': OperationStatus.cancelled},
	];

	/**
	 * Returns the XCL payment state code based on an OperationStatus enum value
	 * @param operationStatus : OperationStatus enum value
	 * @returns {string} The corresponding XCL status as string or null
	 */
	static paymentStatusToXcl(operationStatus: OperationStatus): string {
		if (operationStatus === OperationStatus.unknown) {
			return null;
		}

		const tuple = OperationStatusHelper.statusToXCLCodeMappingList.find((element: PaymentStateTuple) => element.state === operationStatus);

		return tuple ? tuple.code : null;
	}

	/**
	 * Returns an OperationStatus enum value based on an XCL payment state code
	 * @param orderStateCode
	 * @returns {OperationStatus} The corresponding status or OperationStatus.unknown if the code is not recognized
	 */
	public static paymentStatusFromXcl(operationStatus: string): OperationStatus {
		const tuple = OperationStatusHelper.statusToXCLCodeMappingList.find((element: PaymentStateTuple) => element.code === operationStatus);

		return tuple ? tuple.state : OperationStatus.unknown;
	}
}

interface PaymentStateTuple {
	code: string;
	state: OperationStatus;
}
