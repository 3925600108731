import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnInit, Injector } from '@angular/core';

import { SearchCriteria, SearchPageComponent } from '@mdib/core/adapters';
import { equals, all } from '@mdib/core/adapters/criteria';
import { provideNamespace } from '@mdib/core/customization';

import { Beneficiary } from '../../types/models/beneficiary';
import { BeneficiariesService } from '../../services/beneficiaries/beneficiaries.service';
import { BeneficiaryType } from '../../types/enums/beneficiary-type.enum';
import { BeneficiariesSearch } from '../../types/models/beneficiaries-search';

@Component({
	selector: 'beneficiary-search-modal',
	templateUrl: './beneficiary-search-modal.component.html',
	styleUrls: ['./beneficiary-search-modal.component.scss'],
	viewProviders: [provideNamespace('beneficiaries', 'beneficiarySearchModalComponent')],
})
export class BeneficiarySearchModalComponent extends SearchPageComponent<Beneficiary> implements OnChanges, OnInit {

	@Output() public itemChange = new EventEmitter<Beneficiary>();

	@Input() public type: BeneficiaryType;

	// Dependencies Injection
	constructor(
		injector: Injector,
		beneficiariesService: BeneficiariesService,
	) {
		super(injector, beneficiariesService);
		this.searchModel  = new BeneficiariesSearch();
	}

	public ngOnInit(): void {
		this.fetchData();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.fetchData();
	}

	public selectItem(item: Beneficiary): void {
		this.itemChange.emit(item);
	}

	protected getInternalCriteria(): SearchCriteria<any> {
		return this.type ? equals('type', this.type) : all();
	}

	/** A modal doesn't handle URL changes */
	protected loadSearchModel() { }
	protected saveSearchModel() { }

}
