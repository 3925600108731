import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ManagedCurrencyCodes } from '@mdib/config';
import { AmountTypes, PaymentForm, PaymentTypes } from '@mdib/payments';
import { IconService } from '@mdib/styling';
import { DataFormattingService } from '@mdib/utils';

@Component({
	selector: 'mdib-amount-section',
	templateUrl: './amount-section.component.html',
})
export class AmountSectionComponent {

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.dataGatheringStepComponent.amountSectionComponent.';

	@Input() form: PaymentForm;

	readonly amountTypes = AmountTypes;
	readonly paymentTypes = PaymentTypes;
	readonly currencyCodes = Object.keys(ManagedCurrencyCodes);
	readonly ManagedCurrencyCodes = ManagedCurrencyCodes;

	constructor(private dataFormatting: DataFormattingService,
				private iconService: IconService) {
	}

	selectAmountType(amountType: AmountTypes) {
		if (amountType !== this.form.standingOrderDetailsForm.amountType.value) {
			this.form.standingOrderDetailsForm.amountType.setValue(amountType);
			this.form.standingOrderDetailsForm.amountType.updateValueAndValidity();
		}
	}

	get dataFormat(): DataFormattingService {
		return this.dataFormatting;
	}

	getCurrencyIcon(currency: string): string {
		return this.iconService.getCurrencyIcon(ManagedCurrencyCodes[currency]);
	}

	getConditions(control: AbstractControl) {
		return this.form.group.enabled || (this.form.group.disabled && !!control.value);
	}
}
