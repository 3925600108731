export enum XclAPI {
	sign = 'sign',
	confirm = 'confirm',
	retrieve = 'retrieve',
	validate = 'validate',
	rest = 'restmethod',
	reference = 'reference'
}

export enum XclCommunicationFields {
	free = 'freeCommunication',
	european = 'rfStructuredCommunication',
	structured = 'structuredCommunication'
}
