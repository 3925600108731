import { Injectable } from '@angular/core';
import { XclSignatureContext, XclSignatureModes } from '@mdib-xcl/core';
import { XclDateFormatter, OrderStateHelper } from '@mdib-xcl/utils';
import { ContactAddress } from '@mdib/customers';
import { CommunicationTypes } from '@mdib/commons';
// import { XclDateFormatter } from '@mdib-xcl/utils';
import { OperationStatus, StandingOrder } from '@mdib/standing-orders';
import { ModelMonthToXclMonth, ModelPeriodicityToXclPeriodicity, ModelWeekDayToXclWeekDay, StandingOrderAmountType } from '@mdib/standing-orders/model/standing-orders.enum';
import { isNullOrUndefined } from 'util';
import { StandingOrderXcl } from '../model/standing-order-xcl';

@Injectable()
export class StandingOrdersMapperXclService {

	constructor() {}

	/**
	 * Converts the technical model list into functional model list
	 * StandingOrder[] to XclStandingOrderModel[]
	 * @param StandingOrderXcl[] : source format list.
	 * @returns StandingOrder[] : functional model format list.
	 */
	public standingOrderXclListToStandingOrderList(standingOrderXclList: StandingOrderXcl[]): StandingOrder[] {
		if (!standingOrderXclList) {
			console.log('ERROR: mapping issue from StandingOrderXcl[] to StandingOrder[]');
			return null;
		}

		const standingOrderList: StandingOrder[] = new Array<StandingOrder>();
		for (const standingOrder of standingOrderXclList) {
			standingOrderList.push(this.standingOrderXclToStandingOrder(standingOrder));
		}

		return standingOrderList;
	}

	/**
	 * Parse technical model in functional model
	 * @param StandingOrderXcl : source format.
	 * @returns StandingOrder : parsed functional model format.
	 */
	public standingOrderXclToStandingOrder(standingOrderXcl: StandingOrderXcl, operationReference?: string, signatureTypesAllowed?: string): StandingOrder {
		if (isNullOrUndefined(standingOrderXcl)) {
			console.log('ERROR: standingOrderXcl issue');
			return null;
		}

		const counterpartyAddress = new ContactAddress();
		counterpartyAddress.addressLine1 = standingOrderXcl.beneficiaryAddress;
		counterpartyAddress.addressLine2 = standingOrderXcl.beneficiaryAddress_1;

		const standingOrderModel = new StandingOrder({
			communication: standingOrderXcl.communication,
			communicationType: CommunicationTypes.free,
			counterpartyAccountNumber: standingOrderXcl.beneficiaryAccountNumber,
			counterpartyAddress: counterpartyAddress,
			counterpartyName: standingOrderXcl.beneficiaryIdentificationForBeneficiary,
			currency: standingOrderXcl.orderCurrency,
			dayOfTheMonth1: standingOrderXcl.paymentDate1 ? standingOrderXcl.paymentDate1.toString().padStart(2, '0') : null,
			dayOfTheMonth2: standingOrderXcl.paymentDate2 ? standingOrderXcl.paymentDate2.toString().padStart(2, '0') : null,
			dayOfTheMonth3: standingOrderXcl.paymentDate3 ? standingOrderXcl.paymentDate3.toString().padStart(2, '0') : null,
			executionCode: standingOrderXcl.executionCode,
			fixedAmount: standingOrderXcl.standingOrderFixedAmount,
			frequency: this.xclFrequencyToModelFrequency(standingOrderXcl),
			maturityDate: standingOrderXcl.maturityDate ? XclDateFormatter.stringToDate(standingOrderXcl.maturityDate) : new Date(),
			maximumPaymentAmount: standingOrderXcl.maximumPaymentAmount,
			minimumPaymentAmount: standingOrderXcl.minimumPaymentAmount,
			nextPaymentDate: XclDateFormatter.stringToDate(standingOrderXcl.theoreticalPaymentDate),
			numberOfExecutions: standingOrderXcl.numberPayments,
			operationAmount: standingOrderXcl.operationAmount,
			operationReference: operationReference,
			ordererAccountAlias: standingOrderXcl.ordererIdentity,
			ordererAccountNumber: standingOrderXcl.ordererAccountNumber,
			paymentDay: standingOrderXcl.paymentDay ? standingOrderXcl.paymentDay.toString().padStart(2, '0') : null,
			periodicity: this.xclFrequencyToModelFrequency(standingOrderXcl),
			reference: standingOrderXcl.standingOrderReference,
			saveBeneficiary: standingOrderXcl.saveBeneficiary === 'true',
			signatureTypesAllowed: XclSignatureModes.fromXclType(signatureTypesAllowed) || [],
			startDate: XclDateFormatter.stringToDate(standingOrderXcl.startDate),
			startingMonth: this.xclMonthToModelMonth(standingOrderXcl),
			state: OrderStateHelper.getStatusFromXCLOrderStateCode(standingOrderXcl.state),
			status: this.xclStatusToModelStatus(standingOrderXcl),
			targetBalance: standingOrderXcl.ordererAccountTargetBalance,
			totalAmount: standingOrderXcl.totalAmountToPay,
			type: this.xclTypeToModelType(standingOrderXcl),
			typeIdentification: standingOrderXcl.standingOrderType,
			weekDay: this.xclWeekDayToModelWeekDay(standingOrderXcl),
		});
		return standingOrderModel;
	}

	/**
	 * Parse technical model in functional model
	 * @param StandingOrderXcl : source format.
	 * @returns StandingOrder : parsed functional model format.
	 */
	public standingOrderToStandingOrderXcl(standingOrder: StandingOrder): StandingOrderXcl {
		if (isNullOrUndefined(standingOrder)) {
			console.log('ERROR: standingOrder issue');
			return null;
		}

		const standingOrderXclModel = <StandingOrderXcl>{
			identifier: standingOrder.reference,
			beneficiaryAccountNumber: standingOrder.counterpartyAccountNumber,
			beneficiaryAddress: standingOrder.counterpartyAddress.addressLine1,
			beneficiaryAddress_1: standingOrder.counterpartyAddress.addressLine2,
			beneficiaryIdentification: standingOrder.counterpartyName,
			orderCurrency: standingOrder.currency,
			executionCode: standingOrder.executionCode,
			maturityDate: standingOrder.maturityDate ? XclDateFormatter.dateToString(standingOrder.maturityDate) : null,
			minimumPaymentAmount: this.stringify(standingOrder.minimumPaymentAmount),
			maximumPaymentAmount: this.stringify(standingOrder.maximumPaymentAmount),
			numberPayments: this.stringify(standingOrder.numberOfExecutions),
			operationAmount: this.stringify(standingOrder.operationAmount),
			operationReference: standingOrder.operationReference,
			ordererAccountNumber: standingOrder.ordererAccountNumber,
			ordererAccountTargetBalance: this.stringify(standingOrder.targetBalance),
			ordererIdentity: standingOrder.ordererAccountAlias,
			orderPaymentFrequency: this.modelFrequencyToXclFrequency(standingOrder),
			paymentDate1: standingOrder.dayOfTheMonth1 ? standingOrder.dayOfTheMonth1.toString().replace(/^0+/, '') : null,
			paymentDate2: standingOrder.dayOfTheMonth2 ? standingOrder.dayOfTheMonth2.toString().replace(/^0+/, '') : null,
			paymentDate3: standingOrder.dayOfTheMonth3 ? standingOrder.dayOfTheMonth3.toString().replace(/^0+/, '') : null,
			paymentDay: this.modelWeekDayToXclWeekDay(standingOrder),
			paymentMonthNumber: this.modelMonthToXclMonth(standingOrder),
			saveBeneficiary: this.stringify(standingOrder.saveBeneficiary),
			signatureContext: standingOrder.signatureContext,
			standingOrderFixedAmount: this.stringify(standingOrder.fixedAmount),
			standingOrderReference: standingOrder.reference,
			standingOrderType: standingOrder.typeIdentification,
			startDate: XclDateFormatter.dateToString(standingOrder.startDate),
			status: this.modelStatusToXclStatus(standingOrder),
			theoreticalPaymentDate: XclDateFormatter.dateToString(standingOrder.nextPaymentDate),
			totalAmountToPay: this.stringify(standingOrder.totalAmount)
		};

		return standingOrderXclModel;
	}

	private stringify(param: any): string {
		return isNullOrUndefined(param) ? '' : param.toString();
	}

	private xclStatusToModelStatus(standingOrderXcl: StandingOrderXcl): OperationStatus {
		if (standingOrderXcl.status === '1000') {
			return OperationStatus.AwaitingValidation;
		} else if (standingOrderXcl.status === '2000') {
			return OperationStatus.Ongoing;
		} else {
			return OperationStatus.Unknown;
		}
	}

	private modelStatusToXclStatus(standingOrder: StandingOrder): string {
		if (standingOrder.status === OperationStatus.AwaitingValidation) {
			return '1000';
		} else if (standingOrder.status === OperationStatus.Ongoing) {
			return '2000';
		} else {
			console.log('Standing order status unknown: ', standingOrder.status);
			return '';
		}
	}

	private xclTypeToModelType(standingOrderXcl: StandingOrderXcl): StandingOrderAmountType {
		if (standingOrderXcl.standingOrderType === '21') {
			return StandingOrderAmountType.Fixed;
		} else {
			return StandingOrderAmountType.Variable;
		}
	}

	private xclFrequencyToModelFrequency(standingOrderXcl: StandingOrderXcl): string {
		return this.getKeyHavingValue(ModelPeriodicityToXclPeriodicity, standingOrderXcl.orderPaymentFrequency);
	}

	private modelFrequencyToXclFrequency(standingOrder: StandingOrder): string {
		return ModelPeriodicityToXclPeriodicity[standingOrder.periodicity];
	}

	private xclMonthToModelMonth(standingOrderXcl: StandingOrderXcl): string {
		return this.getKeyHavingValue(ModelMonthToXclMonth, standingOrderXcl.paymentMonthNumber);
	}

	private modelMonthToXclMonth(standingOrder: StandingOrder): string {
		return ModelMonthToXclMonth[standingOrder.startingMonth];
	}

	private xclWeekDayToModelWeekDay(standingOrderXcl: StandingOrderXcl): string {
		return this.getKeyHavingValue(ModelWeekDayToXclWeekDay, standingOrderXcl.paymentDay);
	}

	private modelWeekDayToXclWeekDay(standingOrder: StandingOrder): string {
		return ModelWeekDayToXclWeekDay[standingOrder.weekDay] ? ModelWeekDayToXclWeekDay[standingOrder.weekDay].toString().replace(/^0+/, '') : '';
	}

	private getKeyHavingValue(object: Object, value: any) {
		let res = '';
		Object.keys(object).some((key) => {
			if (object[key] === value) {
				res = key;
				return true; // exit the loop
			}
			return false;
		});
		return res;
	}
}
