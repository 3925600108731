import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceResponse } from '@mdib/utils';
import { ShoppingBasketOperation } from '../model/shopping-basket-operation';
import { ShoppingBasketEnvelope } from '../model/shopping-basket-envelope';

@Injectable()
export abstract class ShoppingBasketService {

	/**
	 * Retrieve the list of shopping basket operations to sign
	 * @param {number} [offset=0] - Offset to start from (by default 0, i.e. from the beginning)
	 * @param {number} [limit=0] - Maximum number of results , that must be returned by list operation
	 * @returns {Observable<ServiceResponse<ShoppingBasketOperation>>} : List of operations to sign
	 */
	public abstract getOperationsToSign(offset?: number, limit?: number): Observable<ServiceResponse<ShoppingBasketOperation[]>>;

	/**
	 * Retrieves the saved shopping basket operation
	 * @param {string} reference - reference of the shopping basket operation
	 * @returns {Observable<ServiceResponse<ShoppingBasketOperation>>} : Operation retrieved
	 */
	public abstract retrieve(reference: string): Observable<ServiceResponse<ShoppingBasketOperation>>;

	/**
	 * Trigger the reload of the operations. The new operations list will be emitted by the operations observable (cf. getOperationsToSign()).
	 */
	public abstract reloadOperations(offset?: number, limit?: number): void;

	/**
	 * Delete a list of shopping basket operations.
	 * @param {ShoppingBasketOperation[]} operationToDelete[] The operations to delete.
	 * @returns {Observable<ServiceResponse<null>>} : A ServiceResponse with the potential errors/feedbacks.
	 */
	public abstract deleteOperations(operationsToDelete: ShoppingBasketOperation[]): Observable<ServiceResponse<null>>;

	/**
	 * Delete a shopping basket operation.
	 * @param {ShoppingBasketOperation} operationToDelete The operation to delete.
	 * @returns {Observable<ServiceResponse<null>>} : A ServiceResponse with the potential errors/feedbacks.
	 */
	public abstract deleteOperation(operationToDelete: ShoppingBasketOperation): Observable<ServiceResponse<ShoppingBasketOperation>>;

	/**
	 * Create an "ready-to-sign" envelope containing the provided operations to sign
	 * @param {ShoppingBasketOperation[]} operationsToSign An array with the shopping basket operations to put in the envelope
	 */
	public abstract createEnvelope(operationsToSign: ShoppingBasketOperation[]): Observable<ServiceResponse<ShoppingBasketEnvelope>>;

	/**
	 * Conclude the signature of the envelope
	 *
	 * @param {ShoppingBasketEnvelope} envelope
	 * @returns {Observable<ServiceResponse<ShoppingBasketEnvelope>>}
	 */
	public abstract signEnvelope(envelope: ShoppingBasketEnvelope): Observable<ServiceResponse<ShoppingBasketEnvelope>>;

	public abstract unsignedOperationsCounter(): Observable<ServiceResponse<number>>;

}
