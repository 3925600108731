import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Pipe({
	name: 'currencyLabel'
})
export class CurrencyLabelPipe implements PipeTransform {

	/**
	 * This function returns, for a given currency code, the label to show for the currency.
	 * @param: The currency code to format
	 * @returns: A string containing the label to show for this currency
	 */
	// TODO: This is a STUB implementation. In the future, it should call a micro-service that would provide such labels.
	transform(currencyCode: string, args?: any): string {

		let currencyLabel = isNullOrUndefined(currencyCode) ? 'Select' : null;

		switch (currencyCode) {
			case 'EUR': { currencyLabel = 'Euro'; break; }
			case 'USD': { currencyLabel = 'USA Dollar'; break; }
			case 'GBP': { currencyLabel = 'UK Pound'; break; }
		}

		return (currencyLabel === null ? currencyCode : (currencyLabel === 'Select' ? currencyLabel : currencyCode + ' - ' + currencyLabel));
	}

}
