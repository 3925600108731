import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationModes } from '../../../model/sessions.enum';
import { AuthenticationMode } from '../../../model/authentication-mode';
import { AuthenticationModesService } from '../../../service/authentication-modes.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
        selector: 'authentication-mode-selector',
        templateUrl: './authentication-mode-selector.component.html',
        styleUrls: ['./authentication-mode-selector.component.scss'],
})
export class AuthenticationModeSelectorComponent implements OnInit {

        readonly NAMESPACE = 'sessionsModule.authenticationsComponent.authenticationModeSelectorComponent.';

        public selectedModeId = null;
        fileContent: SafeHtml = '';
        isContentLoaded: boolean = false;

        /** Authentication modes available to be selected */
        @Input() public modes: any[];

        /** Event emitted when an authentication mode has been selected */
        @Output()
        public onSelected: EventEmitter<AuthenticationMode> = new EventEmitter<AuthenticationMode>();

        /** Event emitted when an event occured (Error, Cancel, etc.) */
        @Output()
        public onEvent: EventEmitter<any> = new EventEmitter<any>();

        public constructor(
                private authenticationsService: AuthenticationModesService,
                private router: Router,
                private http: HttpClient,
                private sanitizer: DomSanitizer) {
        }

        public ngOnInit() {
                if (this.modes) {
                        window.setTimeout(() => {
                                this.selectMode(this.modes[0]);
                        }, 1);
                }

                if (this.router.url === '/session' && !this.isContentLoaded) {
                    this.fetchTextFileContent();
                }
        }

        /**
         * Select an authentication mode and propagate the selection through the onSelected event.
         *
         * @param id Identifier of the selected authentication
         */
        public selectMode(id: any) {
                const mode = this.authenticationsService.getMode(id);
                this.onSelected.emit(mode);
                this.selectedModeId = id;
        }

        public getIconContainerClass(index) {
                const cssClass = 'col-xs-3 menu-item-icon-container';
                return (index > 1) ? cssClass + ' menu-item-with-margin' : cssClass;
        }

        public getTextButtonClass(mode) {
                return (mode === this.selectedModeId) ? 'mode-selected-text-button' : '';
        }

        private fetchTextFileContent() {
            this.http.get('assets/notification-message.txt', { responseType: 'text' }).subscribe(
                (content: string) => {
                    this.fileContent = this.sanitizer.bypassSecurityTrustHtml(content);
                    this.isContentLoaded = true;
                },
                (error) => console.error('Error fetching file content:', error)
            );
        }

        public getTextFabButtonClass(mode) {
                return (mode === this.selectedModeId) ? 'mode-selected-text-fab-button' : '';
        }
}

