export class ParameterModel {

	par: string;
	val: string;

	/**
	 * Create a parameter name and value to be added in a URL.
	 * @param {string} parameterName:
	 * @param {string} parameterValue: If there is only one element, the null has to be added to represent the second element.
	 */
	constructor(parameterName?: string, parameterValue?: string) {
		this.par = parameterName;
		this.val = parameterValue;
	}
}
