import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';

import { ModalDialogComponent } from '../../component/modal-dialog/modal-dialog.component';
import { ModalDialogProperties } from '../../model/generic-pages.typings';

/**
 * Provides the ability to render terms and conditions section
 * @example <mdib-terms-and-conditions-section [namespace]="termsAndConditionsNamespace"
 * [termsAndConditionsObservable]="termDepositObservable"></mdib-terms-and-conditions-section>
 *
 * where, namespace and observable needs to be passed from the calling component
 * and in fallback following labels (for calling component) needs to be defined.
 *
 * "TermsAndConditions": {
 * 		"termsAndConditionsHeader": "Sample header",
 *      "termsAndConditionsBody": "Sample body"
 */
@Component({
	selector: 'mdib-terms-and-conditions-section',
	templateUrl: './terms-and-conditions-section.component.html',
	styleUrls: ['./terms-and-conditions-section.component.scss'],
	animations: [
		trigger('backgroundAnimation', [
			state('out', style({
				backgroundColor: 'transparent',
			})),
			state('in', style({
				backgroundColor: '#FEEEEE',
			})),
			transition('out => in', animate('100ms ease-in')),
			transition('in => out', animate('100ms 500ms ease-out')),
		]),
	],
})
export class TermsAndConditionsSectionComponent implements OnInit {

	@Input() control: FormControl;
	@Input() namespace: string;
	public animationState = 'out';
	private _termsOpened = false;

	constructor(private dialog: MatDialog) {
	}

	ngOnInit() {
		this._termsOpened = this.control.value;
		this.control.valueChanges.subscribe(agreed => this._termsOpened = agreed);
	}

	/**
	 * This method opens a dialog box when "terms and conditions" link is clicked
	 */
	openDialog() {
		this.dialog.open(ModalDialogComponent, <MatDialogConfig<ModalDialogProperties>> {
			maxHeight: '95%',
			maxWidth: '95%',
			disableClose: true,
			data: <ModalDialogProperties> {
				title: this.namespace + 'termsAndConditionsHeader',
				content: this.namespace + 'termsAndConditionsBody',
				rightButtonLabel: 'generic:readingAcknowledgement',
				leftButtonLabel: 'generic:unreadingAcknowledgement',
				isTitleUpperCase: true,
			},
		}).componentInstance.rightButtonClicked.subscribe(() => {
			this.control.setValue(false);
			this._termsOpened = true;
		});
	}

	backgroundAnimationDone() {
		setTimeout(() => {
			this.animationState = 'out';
		}, 500);
	}

	get termsOpened(): boolean {
		if (!this._termsOpened) {
			this.animationState = 'in';
			setTimeout(() => {
				this.animationState = 'out';
			}, 500);
		}
		return this._termsOpened || !(this.control.setValue(true));
	}
}
