import { Directive, TemplateRef, ViewContainerRef, DoCheck } from '@angular/core';
import { ConfigService } from '@mdib/config';

@Directive({
	selector: '[mdibWIP]'
})
/**
 * This directive allows to automatically hide the template from the DOM if the WIP (Work-In-Progress) switch is set to false in the ConfigModule
 */
export class WorkInProgressDirective implements DoCheck {

	private lastShowWIPValue = null;

	constructor(
		private _viewContainer: ViewContainerRef,
		private _templateRef: TemplateRef<any>,
		private _configService: ConfigService,
	) {
		this.refreshWIP();
	}

	ngDoCheck() {
		this.refreshWIP();
	}

	private refreshWIP() {
		const currentShowWIPValue = this._configService.featureToggles.showWIP;
		if (currentShowWIPValue !== this.lastShowWIPValue) {
			this.lastShowWIPValue = currentShowWIPValue;
			this.updateView(currentShowWIPValue);
		}
	}

	private updateView(display: boolean) {
		this._viewContainer.clear();
		if (display) {
			this._viewContainer.createEmbeddedView(this._templateRef, {});
		}
	}

}
