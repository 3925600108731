import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../helper/date-helper';
import { isNullOrUndefined } from 'util';

@Pipe({
	name: 'dateFormatter',
})
export class DateFormatterPipe implements PipeTransform {
	readonly MOBILEFORMAT = 'DD MMM';

	transform(inputDate: Date, mobile = false): string {
		return isNullOrUndefined(inputDate) || !inputDate ? '' : DateHelper.format(inputDate, mobile ? this.MOBILEFORMAT : null);
	}
}
