import { CashAccount } from '@mdib/cash-accounts';
import { Communication, Address } from '@mdib/commons';
import { Status } from '@mdib/utils';

import { BeneficiaryType } from '../types/enums/beneficiary-type.enum';
import { BeneficiaryModel } from '../types/models/beneficiary';

/**
 * @deprecated
 */
export class Beneficiary implements BeneficiaryModel {
	id: string;
	fullName: string;
	alias: string;
	type: BeneficiaryType;
	account: CashAccount;
	communication: Communication;
	address: Address;

	/** @deprecated */
	status?: Status;

	constructor(properties: Partial<BeneficiaryModel> = {}) {
		Object.assign(this, properties);
	}
}
