import { Injectable } from '@angular/core';
import { CashAccountOperation, CashAccountOperationBuilder } from '@mdib/cash-accounts';
import { ContactAddress } from '@mdib/customers';
import { XclCashAccountOperationModel } from '../model/xcl-cash-account-operation-model';
import { isNullOrUndefined } from 'util';
import { XclDateFormatter } from '@mdib-xcl/utils';
import { XclContractType, XclSepaDirectDebitPaymentStatus } from '@mdib-xcl/sepa-direct-debits';
import { UtilsHelper } from '@mdib/utils';

@Injectable()
export class CashAccountOperationMapperXclService {

	/**
	 * Converts an array of technical model cash accounts to an equivalent array of functional model cash accounts
	 *
	 */
	public getFunctionalModelArrayFromTechnicalModelArray(technicalModelArray: XclCashAccountOperationModel[]): CashAccountOperation[] {
		if (UtilsHelper.nullOrUndefined(technicalModelArray)) {
			console.error('Invalid list of cash account operations provided by the backend: ' + JSON.stringify(technicalModelArray));
			return [];
		}
		return technicalModelArray.map((technicalModel: XclCashAccountOperationModel) => (this.XclToFunctional(technicalModel)));
	}

	/**
	 * Feeds the functional cash account operation model object from the data provided by the technical model passed in argument,
	 * and return the object itself (to allow consecutive calls if needed)
	 */
	public XclToFunctional(technicalModel: XclCashAccountOperationModel): CashAccountOperation {
		const cashAccountOperation = new CashAccountOperationBuilder()
			/* Direct mappings */
			.amount(technicalModel.movementAmount)
			.currency(technicalModel.movementCurrency)
			.isDebitOperation((technicalModel.movementSign === 'D'))
			.counterparty(technicalModel.operationCounterparty)
			.counterpartyName(technicalModel.counterpartyName)
			.counterpartyAdress(new ContactAddress())
			.accountNumber(technicalModel.accountNumber)
			.movementNumber(technicalModel.movementNumber)
			.reference(technicalModel.operationReference)
			.operationNature(technicalModel.natureOperation)
			.additionalWording(technicalModel.additionalWording)
			/* Mappings needing a transformation */
			// Dates conversion
			.date(XclDateFormatter.xclStringToDate(technicalModel.accountingDate))
			.valueDate(XclDateFormatter.xclStringToDate(technicalModel.valueDate))
			.isStandingOrder(technicalModel.natureOperation.substring(0, 2) === 'SR')

			// Communication filling
			.communication((this.nvl(technicalModel.communicationPart1, '') + ' ' + this.nvl(technicalModel.communicationPart2, '')).trim())
			.isStructuredCommunication((technicalModel.structuredCommunicationSwitch === 'Y'))
			.paymentScheme((XclContractType.fromXclType(technicalModel.paymentScheme)))
			.operationStatus((XclSepaDirectDebitPaymentStatus.fromXclType(technicalModel.operationStatus)))
			.transactionReference(technicalModel.rtransactionReference)
			.standardWording(technicalModel.standardWording)
			.cashAccountOperation;
		return cashAccountOperation;
	}

	/**
	 * This functions acts as the NVL() function in Oracle. It returns the initial "str" input param, or defaultValue if str is null or undefined
	 * @param str This function ac
	 * @param defaultValue
	 */
	private nvl(str: string, defaultValue: string): string {
		return !str ? defaultValue : str;
	}

}
