import { Observable } from 'rxjs';

import { ServiceResponse } from '@mdib/utils';
import { Authentication } from '@mdib/sessions';

import { OtpAuthenticationService } from './otp-authentication.service';

export class OtpAuthenticationServiceStub extends OtpAuthenticationService {

	public authenticate(authentication: Authentication, answerCode: string): Observable<ServiceResponse<Authentication>> {
		return undefined;
	}

	public initAuth(username: string): Observable<ServiceResponse<Authentication>> {
		return undefined;
	}


}
