import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ServiceResponse } from '@mdib/utils';

import { Contact } from '../../model/contact';
import { ContactsService } from './contacts.service';

@Injectable()
export class ContactsServiceStub extends ContactsService {

	public validateUpdateContact(contacts: Contact[]): Observable<ServiceResponse<Contact[]>> {
		return of();
	}

	get(): Observable<ServiceResponse<Contact[]>> {
		return of();
	}
}
