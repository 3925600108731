import { AdapterProxy } from '@mdib/core/adapters';
import { Injectable } from '@angular/core';
import { ElectronicalStatementsService } from './electronical-statements.service';
import { ElectronicalStatementsServiceStub } from './electronical-statements.service.stub';


@Injectable()
export class ElectronicalStatementsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return ElectronicalStatementsService; }
	protected ServiceDefinition() { return ElectronicalStatementsServiceStub; }
}
