import { SepaDirectDebitPayment, SepaDirectDebitPaymentStatus , SepaDirectDebitPaymentType, Debtor, Creditor, ContractType } from '@mdib/sepa-direct-debits';
import { ContactAddressBuilder } from '@mdib/customers';
import { CommunicationTypes, Communication } from '@mdib/commons';
import * as moment from 'moment/moment';

export class MockSepaDirectDebitPayments {
	static getMockSepaDirectDebitPayments(): SepaDirectDebitPayment[] {
		return new Array<SepaDirectDebitPayment>();
	}
}
