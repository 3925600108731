import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MockAccountValuations } from '@mdib-memory/account-valuations/mock/mock-account-valuations';
import { AccountValuation, AccountValuationsCommonService, AccountValuationsPageFilter } from '@mdib/account-valuations';
import { FileStatus, MdibFile, ServiceResponse } from '@mdib/utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AccountValuationsMemoryService extends AccountValuationsCommonService {

	readonly NAMESPACE = 'AccountValuationsPagesModule.AccountValuationsPageComponent.';
	readonly DEFAULT_DELAY = 400;

	constructor(private translate: TranslateService,
		private http: HttpClient,
	) {
		super();
	}

	public list(offset?: number, limit?: number, filter?: AccountValuationsPageFilter): Observable<ServiceResponse<AccountValuation[]>> {
		const start = offset * limit;
		const end = start + limit;
		return of(new ServiceResponse<AccountValuation[]>(
			filter ? this.getFilteredAccountValuationsListCropped(start, end, filter) : MockAccountValuations.getMockAccountValuations().slice(start, end),
		)).pipe(delay(this.DEFAULT_DELAY));
	}

	public count(filter: AccountValuationsPageFilter): Observable<ServiceResponse<number>> {
		return of(
			new ServiceResponse<number>(
				filter ? this.getFilteredAccountValuationsList(filter).length : MockAccountValuations.getMockAccountValuations().length
			)
		).pipe(delay(this.DEFAULT_DELAY));
	}

	// For memory service, the account valuation instance is not considered
	public downloadAccountValuationFile(accountValuation: AccountValuation): Observable<ServiceResponse<MdibFile>> {
		return this.http.get('/assets/documents/samplePdf.pdf', { responseType: 'blob' }).pipe(map((file: any) => {
			const result = <MdibFile>{
				status: FileStatus.Available,
				id: '1',
				document: file,
			};
			return new ServiceResponse<MdibFile>(result);
		}));
	}

	private getFilteredAccountValuationsListCropped(start: number, end: number, filter: AccountValuationsPageFilter): AccountValuation[] {
		return this.getFilteredAccountValuationsList(filter).slice(start, end);
	}

	private getFilteredAccountValuationsList(filter: AccountValuationsPageFilter): AccountValuation[] {
		return MockAccountValuations.getMockAccountValuations().filter((accountValuation: AccountValuation) => {
			if (filter.accountNumber && !accountValuation.accountNumber.startsWith(filter.accountNumber)) {
				return false;
			}
			if (filter.valuationYear && accountValuation.valuationDate.getFullYear() !== +(filter.valuationYear)) {
				return false;
			}
			return true;
		});
	}
}
