import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParameterModel } from '@mdib/http';
import { AbstractOrderXCLModel } from '../model/abstract-order-xcl-model';

@Injectable()
export class XclHttpServiceStub {
	public execute(endpointKey: string, method: string, parameters: ParameterModel[] = [], data: any = {}, headers: { [name: string]: string | string[]; } = {}): Observable<AbstractOrderXCLModel> {
		return new Observable();
	}
}
