import { XclCommunicationFields } from '@mdib-xcl/utils/model/xcl.enum';
import { CommunicationTypes } from '@mdib/commons';
import { BankConfig, LanguageCodes } from '@mdib/config';
import { PaymentOperation } from '@mdib/payments';
import { UtilsHelper } from '@mdib/utils';
import { Observable } from 'rxjs';

export function parseXclLanguageCode(code: string): LanguageCodes {
	// In the CBS language codes start from index 1
	return BankConfig.languageCodes[parseInt(code, 10) - 1] || BankConfig.languageCodes[0];
}

export function b64toBlob(b64Data = '', contentType = '') {
	const byteCharacters = atob(b64Data.replace(/\s/g, ''));
	const byteArray = new Uint8Array(new Array(byteCharacters.length));
	for (let i = 0; i < byteCharacters.length; i++) {
		byteArray[i] = byteCharacters.charCodeAt(i);
	}
	return new Blob([byteArray], {type: contentType});
}

export function blobToB64(file: Blob): Observable<string> {
	const reader = new FileReader();
	reader.readAsText(file, file.type);

	return Observable.create((observer) => {
		reader.onloadend = () => {
			observer.next(reader.result);
			observer.complete();
		};
	});
}

export function mapToXclCommunication<T>(payment: PaymentOperation<any>, xclModel: T): T {
	if (UtilsHelper.objectNotEmpty(payment.counterParty) && UtilsHelper.objectNotEmpty(payment.counterParty.communication) && !!payment.counterParty.communication.type && !!payment.counterParty.communication.value) {
		switch (payment.counterParty.communication.type) {
			case CommunicationTypes.free: {
				xclModel[XclCommunicationFields.free] = payment.counterParty.communication.value;
				break;
			}
			case CommunicationTypes.european: {
				xclModel[XclCommunicationFields.european] = formatEuropeanCommunication(payment.counterParty.communication.value);
				break;
			}
		}
	}
	return xclModel;
}

export function mapToFunctionalCommunication<T>(payment: PaymentOperation<any>, xclModel: T): PaymentOperation<any> {
	if (!!xclModel[XclCommunicationFields.free]) {
		payment.counterParty.communication.type = CommunicationTypes.free;
		payment.counterParty.communication.value = xclModel[XclCommunicationFields.free];
	} else if (!!xclModel[XclCommunicationFields.european]) {
		payment.counterParty.communication.type = CommunicationTypes.european;
		payment.counterParty.communication.value = xclModel[XclCommunicationFields.european];
	}
	return payment;
}

export function fromXclBoolean(xclCode: string): boolean {
	switch (xclCode && xclCode.toUpperCase()) {
		case 'Y':
			return true;
		case 'N':
			return false;
		default:
			return null;
	}
}

export function toXclBoolean(value: boolean): string {
	switch (value) {
		case true:
			return 'Y';
		case false:
			return 'N';
		default:
			return null;
	}
}

/**
 * Convert orderer identity provided by the XCL into a correct one
 * @param {string} ordererIdentity
 * @returns {string}
 */
export function convertOrdererIdentityFromXcl(ordererIdentity: string): string {
	return ordererIdentity.split('/')[1].trim();
}

/**
 * Convert adress provided by the XCL into city and postcode
 * @param {string[]} beneficiaryTown
 * @returns {string}
 */
export function convertBeneficiaryTownFromXclToCity(beneficiaryTown: string): string[] {
	const adresseSplited = beneficiaryTown.split(' ');
	const town = [];

	town.push(adresseSplited[0]);
	adresseSplited.shift();

	let string = adresseSplited.toString();
	string = string.replace(/,/g, ' ');

	town.push(string.trim());
	return town;
}

/**
 * Convert address provided by the XCL into street name and street number
 * @param {string[]} beneficiaryAddress
 * @returns {string}
 */
export function convertBeneficiaryAddressFromXclToStreet(beneficiaryAddress: string): string[] {
	const addresseSplited = beneficiaryAddress.split(' ');
	const street = [];

	street.push(addresseSplited[addresseSplited.length - 1]);
	addresseSplited.pop();

	let string = addresseSplited.toString();
	string = string.replace(/,/g, ' ');

	street.push(string.trim());
	return street;
}

export function formatEuropeanCommunication(communication: string): string {
	if (communication) { // it is necessary to check if communication is not null or undefined
		communication = communication.indexOf('RF') < 0 ? 'RF' + communication : communication;
	}
	return communication;
}

/**
 * Get the country code from the IBAN of an account
 * @param {string} the IBAN
 * @returns {string} the country code of the IBAN
 */
export function getCountryCodeFromIBAN(iban: string): string {
	return iban.substr(0, 2);
}
