import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ConfigurationStore } from '../configuration-store';

@Injectable()
export class ConfigurationStoreStub extends ConfigurationStore {

	public load(): Observable<boolean> {
		return of();
	}

	public set(key: string, value: any): Observable<any> {
		return of();
	}

	public setDefaults(properties: { [key: string]: any; }) { }

	public instant(key: string): any {
		return undefined;
	}
}
