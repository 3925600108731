import { Component } from '@angular/core';

@Component({
	selector: 'mdib-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
	readonly NAMESPACE = 'headerModule.searchComponent.';
}
