import { BeneficiaryV2 as Beneficiary, BeneficiaryType } from '@mdib/beneficiaries';
import { CashAccount } from '@mdib/cash-accounts';
import { CommunicationTypes, Address } from '@mdib/commons';

export const BeneficiariesMock: Beneficiary[] = [
	// SEPA
	new Beneficiary({
		id: 'REF1',
		account: new CashAccount( {
			bic: 'TFEXFRPP',
			number: 'FR1420041010050500013M02606',
		}),
		fullName: 'Estelle Dupré',
		alias: 'Estelle',
		type: BeneficiaryType.SEPA,
		communication: {
			value: 'This is a communication',
			type: CommunicationTypes.free,
		},
		address: new Address({
			addressLine1: 'Avenue des Combattants, 47',
			addressLine2: '1340, Ottignies',
			country: 'BE',
		}),
	}),
	new Beneficiary({
		id: 'REF2',
		account: new CashAccount( {
			bic: 'AXABBE22XXX',
			number: 'BE95750642304658',
		}),
		fullName: 'Jean Dupont',
		alias: 'JeanJean',
		type: BeneficiaryType.SEPA,
		communication: {
			value: '085192754115',
			type: CommunicationTypes.free,
		},
		address: new Address({
			addressLine1: 'Avenue des Combattants, 47',
			addressLine2: '1340, Ottignies',
			country: 'BE',
		}),
	}),
	new Beneficiary({
		id: 'REF3',
		account: new CashAccount( {
			bic: 'UTUBFRPPMAR',
			number: 'FR14 2004 1010 0505 0001 3M02 606',
		}),
		fullName: 'Bernadette Gislain',
		alias: 'Berta',
		type: BeneficiaryType.SEPA,
		communication: {
			value: 'RF0243256216',
			type: CommunicationTypes.european,
		},
		address: new Address({
			addressLine1: 'Avenue des Combattants, 47',
			addressLine2: '1340, Ottignies',
			country: 'BE',
		}),
	}),
	new Beneficiary({
		id: 'REF4',
		account: new CashAccount( {
			bic: 'DRESDEFF276',
			number: 'DE89370400440532013000',
		}),
		fullName: 'Angela Brandebourg',
		alias: 'Angela',
		type: BeneficiaryType.SEPA,
		address: new Address({
			addressLine1: 'Avenue des Combattants, 47',
			addressLine2: '1340, Ottignies',
			country: 'BE',
		}),
	}),
	new Beneficiary({
		id: 'REF5',
		account: new CashAccount( {
			bic: 'GKCCBEBB',
			number: 'BE68539007547034',
		}),
		fullName: 'Sebastien Delfosse',
		alias: 'Seb',
		type: BeneficiaryType.SEPA,
		address: new Address({
			addressLine1: 'Avenue des Combattants, 47',
			addressLine2: '1340, Ottignies',
			country: 'BE',
		}),
	}),
	new Beneficiary({
		id: 'REF6',
		account: new CashAccount( {
			bic: 'TFEXFRPP',
			number: 'FR1420041010050500013M02606',
		}),
		fullName: 'Charles Raimond',
		alias: 'Charles',
		type: BeneficiaryType.SEPA,
		address: new Address({
			addressLine1: 'Avenue des Combattants, 47',
			addressLine2: '1340, Ottignies',
			country: 'BE',
		}),
	}),
	new Beneficiary({
		id: 'REF7',
		account: new CashAccount({
			bic: 'AXABBE22XXX',
			number: 'BE07456564136566',
		}),
		fullName: 'Annie Legreve',
		alias: 'Minnie',
		type: BeneficiaryType.SEPA,
		communication: {
			value: 'Shopping',
			type: CommunicationTypes.free,
		},
		address: new Address({
			addressLine1: 'Avenue des Combattants, 47',
			addressLine2: '1340, Ottignies',
			country: 'BE',
		}),
	}),
	new Beneficiary({
		id: 'REF8',
		account: new CashAccount( {
			bic: 'AXABBE22XXX',
			number: 'BE89519118798285',
		}),
		fullName: 'Michel Jacket',
		alias: 'Mich',
		type: BeneficiaryType.SEPA,
		communication: {
			value: '085192754115',
			type: CommunicationTypes.free,
		},
		address: new Address({
			addressLine1: 'Rue de Fer, 12',
			addressLine2: '5000, Namur',
			country: 'BE',
		}),
	}),
	// INTERNATIONAL
	new Beneficiary({
		id: 'REF9',
		account: new CashAccount( {
			bic: 'ALLAINBBRPK',
			number: '76301570062553',
		}),
		fullName: 'Rohit Mohan Srivastava',
		alias: 'Rohit',
		type: BeneficiaryType.INTERNATIONAL,
		communication: {
			value: '001331368244',
			type: CommunicationTypes.free,
		},
		address: new Address({
			addressLine1: 'Sector 65, A-112',
			addressLine2: '201301, Noida',
			country: 'IN',
		}),
	}),
	new Beneficiary({
		id: 'REF10',
		account: new CashAccount( {
			bic: 'ABSLRUMM',
			number: '635123621864553299',
		}),
		fullName: 'Vladimir Vladimirovich',
		alias: 'Vladim',
		type: BeneficiaryType.INTERNATIONAL,
		address: new Address({
			addressLine1: 'Place Rouge, 1',
			addressLine2: '6543, Moscow',
			country: 'RU',
		}),
	}),
	new Beneficiary({
		id: 'REF11',
		account: new CashAccount( {
			bic: 'ABENUS31XXX',
			number: '955123621864553285',
		}),
		fullName: 'Donald Smith',
		alias: 'Donald',
		type: BeneficiaryType.INTERNATIONAL,
		address: new Address({
			addressLine1: 'Time square, 35B',
			addressLine2: '10036, Manhattan',
			country: 'US',
		}),
	}),
];
