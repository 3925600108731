import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServiceResponse, Feedback, FeedbackTypes } from '@mdib/utils';
import { Signable, Signature } from '@mdib/signature';

import { SessionUtilsService } from '@mdib/sessions';

import { FunctionalFeedbacksFromXclOrderExtractor, AbstractOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { XclSignatureContext } from '@mdib-xcl/core';

@Injectable()
export class SignatureServiceXcl {

	protected SIGNATURE_TYPE: string;

	constructor(
		protected _xclHttpService: XclHttpService,
		protected _feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		protected sessionUtilsService: SessionUtilsService,
	) { }

	public prepareSignatureFor(object: Signable): Observable<ServiceResponse<Signature>> {
		return this.executeInitRequest(object)
			.pipe(map((response: AbstractOrderXCLModel) =>
				new ServiceResponse(
					{ typeName: this.SIGNATURE_TYPE },
					this._feedbackExtractor.extract(response),
				),
			));
	}

	public createSignatureFor(object: Signable, signatureValue: any): Observable<ServiceResponse<Signature>> {
		// Gather the context
		if (!(object.getSignatureContext() instanceof XclSignatureContext)) {
			return throwError('XclSignatureModule.PasswordProcessXclService.error:wrongSignatureContext');
		}
		const context: XclSignatureContext = object.getSignatureContext();

		// Retrieve the third-party Identifier
		const thirdPartyId = this.sessionUtilsService.getSessionActiveUserPersonId();

		// Execute the operation (Validate of XCL signature)
		return this._xclHttpService
			.execute('signature-validate', XclHttpService.POST, [], {
				'orderReference': context.signableReference,
				'signatureType': this.SIGNATURE_TYPE,
				'signatoryId': thirdPartyId,
				'signatureValue': signatureValue,
			})
			.pipe(map((response: AbstractOrderXCLModel) => {
				const feedbacks: Array<Feedback> = this._feedbackExtractor.extract(response);

				if (response.state === '9000') {
					return new ServiceResponse({ typeName: this.SIGNATURE_TYPE }, feedbacks);
				}

				throw new ServiceResponse<null>(null, feedbacks.concat(new Feedback(
					'signatureNotValidated', FeedbackTypes.FRONTEND_ERROR,
					'Something went wrong when validating the signature',
				)));
			}));
	}

	protected executeInitRequest(object: Signable): Observable<AbstractOrderXCLModel> {
		// Gather the context
		if (!(object.getSignatureContext() instanceof XclSignatureContext)) {
			return throwError('XclSignatureModule.PasswordProcessXclService.error:wrongSignatureContext');
		}
		const context: XclSignatureContext = object.getSignatureContext();

		// Retrieve the third-party Identifier
		const thirdPartyId = this.sessionUtilsService.getSessionActiveUserPersonId();

		const businessRole = this.sessionUtilsService.getSessionActiveBusinessRoleId();

		// Execute the operation (Init of XCL signature)
		return this._xclHttpService
			.execute('signature', XclHttpService.POST, [], {
				'orderReference': context.signableReference,
				'signatureType': this.SIGNATURE_TYPE,
				'signatoryId': thirdPartyId,
				'businessRole': businessRole,
			});
	}

}
