import { Immutable, ItemModel, Item } from '@mdib/core/meta';
import { Communication, Address } from '@mdib/commons';
import { CashAccount } from '@mdib/cash-accounts';

import { BeneficiaryType } from '../enums/beneficiary-type.enum';

export interface BeneficiaryModel extends ItemModel {
	fullName: string;
	alias: string;
	type: BeneficiaryType;
	account: CashAccount;
	communication: Communication;
	address: Address;
}

@Immutable()
export class Beneficiary extends Item<BeneficiaryModel, Beneficiary> implements BeneficiaryModel {
	public readonly fullName: string;
	public readonly alias: string;
	public readonly type: BeneficiaryType;
	public readonly account: CashAccount = new CashAccount();
	public readonly communication: Communication = new Communication();
	public readonly address: Address = new Address();

	constructor(properties: Partial<BeneficiaryModel> = {}) {
		super(Beneficiary);
		Object.assign(this, properties);

		// Ensure proper typing and avoid reference copy
		this.account = new CashAccount(this.account);
		this.address = new Address(this.address);
		this.communication = new Communication(this.communication);
	}

	public setFullName(fullName: string): Beneficiary {
		return this.set('fullName', fullName);
	}

	public setAlias(alias: string): Beneficiary {
		return this.set('alias', alias);
	}

	public setType(type: BeneficiaryType): Beneficiary {
		return this.set('type', type);
	}

	public setAccount(account: CashAccount): Beneficiary {
		return this.set('account', account);
	}

	public setCommunication(communication: Communication): Beneficiary {
		return this.set('communication', communication);
	}

	public setAddress(address: Address): Beneficiary {
		return this.set('address', address);
	}
}
