import { NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { MailboxMessagesService } from '@mdib/mailbox';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';

import { MailboxMessagesMemoryService } from './service/mailbox-messages-memory.service';

@NgModule()
export class MailboxMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(MailboxMessagesService, adapterId, MailboxMessagesMemoryService);
	}
}
