import { NgModule, ModuleWithProviders } from '@angular/core';

import { ShoppingBasketService } from './service/shopping-basket.service';
import { ShoppingBasketServiceProxy } from './service/shopping-basket.service.proxy';

@NgModule()
export class ShoppingBasketModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: ShoppingBasketModule,
			providers: [
				{ provide: ShoppingBasketService, useClass: ShoppingBasketServiceProxy },
			]
		};
	}
}
