import { Injectable } from '@angular/core';
import { StandingOrdersMock } from '../mock/standing-orders-mock';
import { PaymentOperation, StandingOrderCommonService, StandingOrderDetails } from '@mdib/payments';
import { SignatureModes } from '@mdib/signature-modes';
import { ServiceResponse, Status } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { SignableOperation } from '@mdib/signature';

@Injectable({ providedIn: 'root' })
export class StandingOrderMemoryService extends StandingOrderCommonService {


	public validate(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>> {
		payment.amount < 10 ? payment.status = Status.ToConfirm : payment.status = Status.ToSign;
		payment.signatureTypesAllowed = [SignatureModes.PASSWORD];
		payment.counterParty.account.bic = payment.counterParty.account.bic || 'DEUTBEBEXXX';
		payment.paymentDate = payment.paymentDate || new Date();
		payment.executionDetails = new StandingOrderDetails(payment.executionDetails);
		payment.executionDetails.startDate = !!payment.executionDetails.startDate ? payment.executionDetails.startDate : moment();
		return of(new ServiceResponse<PaymentOperation<StandingOrderDetails>>(payment));
	}

	public confirm(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null> | null> {
		payment.status === Status.ToConfirm ? payment.status = Status.Closed : payment.status = Status.InShoppingBasket;
		return of(new ServiceResponse<PaymentOperation<StandingOrderDetails>>(payment));
	}

	public sign(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>> {
		payment.status = Status.Closed;
		return of(new ServiceResponse<PaymentOperation<StandingOrderDetails>>(payment));
	}

	public getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>> {
		return of(new ServiceResponse<PaymentOperation<StandingOrderDetails>>(
			StandingOrdersMock.getMockStandingOrder().find((standingOrder) => standingOrder.reference === reference)),
		);
	}

	public get(reference: string): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>> {
		return of(new ServiceResponse<PaymentOperation<StandingOrderDetails>>(
			StandingOrdersMock.getMockStandingOrder().find((standingOrder) => standingOrder.reference === reference)),
		);
	}

	public initUpdate(reference: string): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>> {
		return of(new ServiceResponse<PaymentOperation<StandingOrderDetails>>(
			StandingOrdersMock.getMockStandingOrder().find((standingOrder) => standingOrder.reference === reference)),
		);
	}

	public validateUpdate(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>> {
		return of(new ServiceResponse<PaymentOperation<StandingOrderDetails>>(payment));
	}

	public delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>> {
		throw new Error('Method not implemented.');
	}
	public signDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>> {
		throw new Error('Method not implemented.');
	}
	public confirmDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>> {
		throw new Error('Method not implemented.');
	}


}
