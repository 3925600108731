import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxpHttpService } from './service/dxp-http.service';

/**
 * Gathers all of the common components related to the DXP.
 */
@NgModule({
	imports: [
		CommonModule,
	],
	providers: [
		DxpHttpService
	],
})
export class DxpHttpModule { }
