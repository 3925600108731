import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@mdib/config';
import { KeyValuePair } from '@mdib/utils';
import { NotificationMessageService, NotificationMessage } from '@mdib/notification-message';
import { SignatureTypeSelectorComponent } from '../signature-type-selector/signature-type-selector.component';
import { SignatureService } from '../../service/signature.service';
import { SignatureType } from '../../interfaces/signature-type';

@Component({
	selector: 'mdib-signature-type-list',
	templateUrl: './signature-type-list.component.html',
	styleUrls: ['./signature-type-list.component.scss'],
})
export class SignatureTypeListComponent extends SignatureTypeSelectorComponent implements OnInit {

	readonly NAMESPACE = 'signatureModule.signatureTypeListComponent.';

	signatureTypes: Array<KeyValuePair<string, SignatureType>> = [];

	constructor(
		private signatureService: SignatureService,
		private configService: ConfigService,
		private notificationMessageService: NotificationMessageService,
	) { super(); }

	ngOnInit() {

		if (this.signatureIsEmpty()) {
			this.showEmptySignatureWarning();
		}

		// If there is only one signature mode available then it should be automatically selected.
		if (this.getSignatureTypes().length === 1) {
			setTimeout(() => { this.selectType(this.getSignatureTypes()[0].value); }, 0);
		}
		this.signatureTypes = this.getSignatureTypes();
	}

	public cancel(): void {
		this.onError.emit(new CustomEvent('abort'));
	}

	public getSignatureTypes(): Array<KeyValuePair<string, SignatureType>> {
		return (this.types || [])
			.map(id => this.signatureService.getType(id))
			.filter(type => type && (this.configService.featureToggles.showWIP || !type.processComponent['isWorkInProgress']))
			.map(type => ({ key: this.NAMESPACE + 'signatureModes:' + type.name, value: type }));
	}

	public set selectedType(type) {
		this.selectType(type);
	}

	signatureIsEmpty(): boolean {
		return this.types.length === 0;
	}

	// Shows the warning message if there are no signature modes available for an operation to sign.
	showEmptySignatureWarning(): void {
		this.notificationMessageService.sendMessage(<NotificationMessage>{
			title: this.NAMESPACE + 'notification:message:warning',
			type: 'warning',
			message: this.NAMESPACE + 'modeWarning',
			scrollToMe: true,
		});

		setTimeout(() => {
			this.cancel();
		}, 0);
	}
}
