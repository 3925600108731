// Angular Module
import { NgModule, Type, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// // Third party
import { TranslateModule } from '@ngx-translate/core';
// Our components
import { PasswordSignatureComponent } from './component/password-signature/password-signature.component';
import { DigipassSignatureProcessComponent } from './component/digipass-signature-process/digipass-signature-process.component';
import { DigipassChallengeComponent } from './component/digipass-challenge/digipass-challenge.component';
import { OtpSignatureComponent } from './component/otp-signature/otp-signature.component';
import { StylingModule } from '@mdib/styling';
import { UtilsModule } from '@mdib/utils';

import { PasswordProcessService } from './service/password-process.service';
import { DigipassSignatureService } from './service/digipass-signature.service';
import { OtpSignatureService } from './service/otp-signature.service';
import { PasswordProcessServiceProxy } from './service/password-sprocess.service.proxy';
import { DigipassSignatureServiceProxy } from './service/digipass-signature.service.proxy';
import { OtpSignatureServiceProxy } from './service/otp-signature.service.proxy';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		TranslateModule,
		StylingModule,
		ReactiveFormsModule,
		UtilsModule
	],
	declarations: [
		PasswordSignatureComponent,
		DigipassSignatureProcessComponent,
		DigipassChallengeComponent,
		OtpSignatureComponent
	],
	exports: [
		PasswordSignatureComponent,
		DigipassSignatureProcessComponent,
		OtpSignatureComponent
	],
	entryComponents: [
		PasswordSignatureComponent,
		DigipassSignatureProcessComponent,
		OtpSignatureComponent
	]
})
export class SignatureModesModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: SignatureModesModule,
			providers: [
				{ provide: PasswordProcessService, useClass: PasswordProcessServiceProxy },
				{ provide: DigipassSignatureService, useClass: DigipassSignatureServiceProxy },
				{ provide: OtpSignatureService, useClass: OtpSignatureServiceProxy },
			]
		};
	}
}
