import { Injectable } from '@angular/core';
import { SepaDirectDebitPayment, Debtor, Creditor } from '@mdib/sepa-direct-debits';
import { XclDateFormatter, XclCommunicationFields } from '@mdib-xcl/utils';
import { SepaDirectDebitPaymentXcl } from '../model/sepa-direct-debit-payment-xcl';
import { XclSepaDirectDebitPaymentStatus } from '../model/xcl-sepa-direct-debit-payment-status';
import { XclSepaDirectDebitPaymentType } from '../model/xcl-sepa-direct-debit-payment-type';
import { UtilsHelper } from '@mdib/utils';
import { ContactAddressBuilder } from '@mdib/customers';
import { CommunicationTypes, Communication } from '@mdib/commons';
import { XclContractType } from '../model/xcl-contract-type';

@Injectable()
export class SepaDirectDebitPaymentsMapperXclService {

	/**
	 * Parse XCL model in functional model
	 * @param sepaDirectDebitPaymentXcl : source format.
	 * @returns SepaDirectDebitPayment : parsed functional model format.
	 */
	public fromXclToFunctional(sepaDirectDebitPaymentXcl: SepaDirectDebitPaymentXcl): SepaDirectDebitPayment {
		const sepaDirectDebitPaymentModel = <SepaDirectDebitPayment>{
			operationDate: UtilsHelper.isNullOrWhiteSpace(sepaDirectDebitPaymentXcl.operationDate) ? null : XclDateFormatter.stringToDate(sepaDirectDebitPaymentXcl.operationDate),
			paymentDate: UtilsHelper.isNullOrWhiteSpace(sepaDirectDebitPaymentXcl.settlementDate) ? null : XclDateFormatter.stringToDate(sepaDirectDebitPaymentXcl.settlementDate),
			creditorReference: sepaDirectDebitPaymentXcl.externalReference,
			transferAmount: sepaDirectDebitPaymentXcl.operationAmount,
			paymentReference: sepaDirectDebitPaymentXcl.operationReference,
			operationCurrency: sepaDirectDebitPaymentXcl.operationCurrency,
			paymentStatus: XclSepaDirectDebitPaymentStatus.fromXclType(sepaDirectDebitPaymentXcl.operationStatus),
			paymentType: XclSepaDirectDebitPaymentType.fromXclType(sepaDirectDebitPaymentXcl.paymentType),
			contractNumber: sepaDirectDebitPaymentXcl.contractNumber,
			contractType: XclContractType.fromXclType(sepaDirectDebitPaymentXcl.paymentScheme),

			debtor: <Debtor>{
				accountNumber: sepaDirectDebitPaymentXcl.counterpartyAccountNumber,
				bicCode: sepaDirectDebitPaymentXcl.counterpartyBicIdentification,
				name: sepaDirectDebitPaymentXcl.counterpartyName
			},
			creditor: <Creditor>{
				reference: sepaDirectDebitPaymentXcl.externalReference,
				identity: sepaDirectDebitPaymentXcl.creditorIdentity,
				accountNumber: sepaDirectDebitPaymentXcl.ordererAccountNumber,
				bicCode: sepaDirectDebitPaymentXcl.ordererBicIdentification,
				name: sepaDirectDebitPaymentXcl.ordererName,
			},
			communication: this.mapToFunctionalCommunication(sepaDirectDebitPaymentXcl)
		};
		sepaDirectDebitPaymentModel.debtor.address ? (sepaDirectDebitPaymentModel.debtor.address = null) : (sepaDirectDebitPaymentModel.debtor.address = new ContactAddressBuilder()
			.addressLine1(UtilsHelper.nullOrUndefined(sepaDirectDebitPaymentXcl.counterpartyAddressPart1) ? null : sepaDirectDebitPaymentXcl.counterpartyAddressPart1)
			.addressLine2(UtilsHelper.nullOrUndefined(sepaDirectDebitPaymentXcl.counterpartyAddressPart2) ? null : sepaDirectDebitPaymentXcl.counterpartyAddressPart2)
			.country(sepaDirectDebitPaymentXcl.counterpartyCountryCode)
			.contactAddress);

		sepaDirectDebitPaymentModel.creditor.address ? (sepaDirectDebitPaymentModel.creditor.address = null) : (sepaDirectDebitPaymentModel.creditor.address = new ContactAddressBuilder()
			.addressLine1(UtilsHelper.nullOrUndefined(sepaDirectDebitPaymentXcl.ordererAddressPart1) ? null : sepaDirectDebitPaymentXcl.ordererAddressPart1)
			.addressLine2(UtilsHelper.nullOrUndefined(sepaDirectDebitPaymentXcl.ordererAddressPart2) ? null : sepaDirectDebitPaymentXcl.ordererAddressPart2)
			.country(sepaDirectDebitPaymentXcl.ordererCountryCode)
			.contactAddress);

		return sepaDirectDebitPaymentModel;
	}

	/**
	 * Converts the XCL model list into functional model list
	 * @param SepaDirectDebitPaymentXcl[] : source format list.
	 * @returns SepaDirectDebitPayment[] : functional model format list.
	 */
	public fromXclModelListToFunctionalModelList(sepaDirectDebitPaymentXclList: SepaDirectDebitPaymentXcl[]): SepaDirectDebitPayment[] {
		if (!sepaDirectDebitPaymentXclList) {
			return null;
		}
		const sepaDirectDebitPaymentList: SepaDirectDebitPayment[] = new Array<SepaDirectDebitPayment>();
		sepaDirectDebitPaymentXclList.forEach((sepaDirectDebitPaymentSummary: SepaDirectDebitPaymentXcl) => {
			const sepaDirectDebitPayment: SepaDirectDebitPayment = this.fromXclToFunctional(sepaDirectDebitPaymentSummary);
			sepaDirectDebitPaymentList.push(sepaDirectDebitPayment);
		});
		return sepaDirectDebitPaymentList;
	}

	/**
	 * Converts the XCL communication model into functional model format
	 * @param SepaDirectDebitPaymentXcl : xcl payment object.
	 * @returns Communication : functional model format communication.
	 */
	private mapToFunctionalCommunication(sepaDirectDebitPaymentXcl: SepaDirectDebitPaymentXcl): Communication {
		const communication = <Communication>{};
		if (sepaDirectDebitPaymentXcl[XclCommunicationFields.free]) {
			communication.type = CommunicationTypes.free;
			communication.value = sepaDirectDebitPaymentXcl[XclCommunicationFields.free];
			return communication;
		}
		if (sepaDirectDebitPaymentXcl[XclCommunicationFields.european]) {
			communication.type = CommunicationTypes.european;
			communication.value = sepaDirectDebitPaymentXcl[XclCommunicationFields.european];
			return communication;
		}
		return null;
	}
}
