import { OperationTypes } from '@mdib/payments';

export interface Filter {
	ordererAccount?: string;
	ordererName?: string;
	beneficiaryAccount?: string;
	beneficiaryName?: string;
	dateStart?: Date;
	dateEnd?: Date;
	amountMin?: number;
	amountMax?: number;
	operationType?: OperationTypes;
}

export interface KeyValuePair<K, V> {
	key: K;
	value: V;
}

export interface InputError {
	translationKey: string;
	params?: Object;
}

export interface FeedBackIconTuple {
	icon: string;
	type: string;
}


export const FeedbackTypes = {
	BACKEND_ERROR: 'error',
	FRONTEND_ERROR: 'frontend_error',
	BACKEND_WARNING: 'warning',
	FRONTEND_WARNING: 'frontend_warning',
	BACKEND_INFORMATION: 'information',
	FRONTEND_INFORMATION: 'frontend_information',
	BACKEND_SUCCESS: 'success',
	FRONTEND_SUCCESS: 'frontend_success',
	UNKNOWN_ERROR: 'unknown_error'
};
