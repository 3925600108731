import { Injectable } from '@angular/core';
import { FunctionalFeedbacksExtractor } from '@mdib/http';
import { AbstractOrderXCLModel } from '../model/abstract-order-xcl-model';
import { FeedbackXCLModel } from '../model/feedback-xcl-model';
import { MappingXCLFeedbackType } from '../model/mapping-xcl-feedback-type';
import { ErrorXCLModel } from '../model/error-xcl-model';
import { Feedback } from '@mdib/utils';

/**
 * I'm responsible of the mapping between functional feedbacks and xcl ones
 *
 * @export
 * @class FunctionalFeedbacksFromXclOrderExtractor
 * @implements {FunctionalFeedbacksExtractor<AbstractOrderXCLModel>}
 */
@Injectable()
export class FunctionalFeedbacksFromXclOrderExtractor implements FunctionalFeedbacksExtractor<AbstractOrderXCLModel> {
	/**
	 * Extract the {FeedbackXCLModel} list from the {AbstractOrderXCLModel} and converts it to a list of {Feedback}
	 *
	 * @param {AbstractOrderXCLModel} technicalContainer
	 * @returns {Feedback[]} Returns an empty list if the input contains no feedback.
	 * @memberof FunctionalFeedbacksFromXclOrderExtractor
	 */
	public extract(technicalContainer: AbstractOrderXCLModel): Array<Feedback> {
		const technicalFeedbacks: FeedbackXCLModel[] =
			technicalContainer
			&& technicalContainer.feedbackList
			&& technicalContainer.feedbackList.feedbacks
			|| [];

		const error: ErrorXCLModel = technicalContainer && technicalContainer.isError && technicalContainer.error;
		const errorAsFeedback: Feedback = error ? this.xclErrorToFeedback(error) : null;

		const convertedFeedbacks =
			technicalFeedbacks
				.map((feedback: FeedbackXCLModel) => new Feedback(
					feedback.code,
					MappingXCLFeedbackType[feedback.feedbackType] || '',
					feedback.message,
					{
						freeZone: (error.errorCode === feedback.code) ? error.freeZone : feedback.feedbackSource,
						[MappingXCLFeedbackType[feedback.feedbackType] + 'Code']: feedback.code,
					},
				))
				.filter((feedback: Feedback) => errorAsFeedback && feedback.key !== errorAsFeedback.key);

		return errorAsFeedback ? convertedFeedbacks.concat(errorAsFeedback) : convertedFeedbacks;
	}

	/**
	 * Extract the {FeedbackXCLModel} list from the {AbstractOrderXCLModel} and converts it to a list of {Feedback}
	 *
	 * @param {AbstractOrderXCLModel} technicalContainer
	 * @returns {Feedback[]} Returns an empty list if the input contains no feedback.
	 * @memberof FunctionalFeedbacksFromXclOrderExtractor
	 */
	public extractFromList(technicalContainers: AbstractOrderXCLModel[]): Array<Feedback> {
		if (!technicalContainers) {
			return [];
		}

		return technicalContainers.reduce<Feedback[]>(
			(previousValue: Feedback[], currentValue: AbstractOrderXCLModel) => previousValue.concat(this.extract(currentValue)), [],
		);
	}

	private xclErrorToFeedback(error: ErrorXCLModel): Feedback {
		return new Feedback(error.errorCode, MappingXCLFeedbackType.ERROR, error.localizedMessage, error);
	}
}
