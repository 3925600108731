export enum OperationStatus {
	Label = 'operation:standingOrder:status:',
	Unknown = 'unknown',
	AwaitingValidation = 'awaitingValidation',
	Ongoing = 'ongoing',
}

export enum StandingOrderFrequency {
	Label = 'operation.periodicity:',
	Unknown = 'Unknown',
}

export enum StandingOrderAmountType {
	Label = 'operation:standingOrder:amount:',
	Fixed = 'fixed',
	Variable = 'variable',
}

export enum StandingOrderExecutionCode {
	Label = 'operation:standingOrder:executionCode:',
	BeginningOfDay = '00',
	EndOfDay = '99',
}

export enum StandingOrderPeriodicity {
	Weekly = 'Weekly',
	ThreeTimesPerMonth = 'ThreeTimesPerMonth',
	TwicePerMonth = 'TwicePerMonth',
	Monthly = 'Monthly',
	EveryTwoMonths = 'EveryTwoMonths',
	EveryThreeMonths = 'EveryThreeMonths',
	EveryFourMonths = 'EveryFourMonths',
	HalfYearly = 'HalfYearly',
	Yearly = 'Yearly',
}

export enum ModelPeriodicityToXclPeriodicity {
	Weekly = '01',
	ThreeTimesPerMonth = '02',
	TwicePerMonth = '03',
	Monthly = '04',
	EveryTwoMonths = '05',
	EveryThreeMonths = '06',
	HalfYearly = '07',
	Yearly = '08',
	None = '09',
	Daily = '10',
	EveryFourMonths = '11',
	DailySRDD = '20'
}

export enum WeekDays {
	Monday = 'Monday',
	Tuesday = 'Tuesday',
	Wednesday = 'Wednesday',
	Thursday = 'Thursday',
	Friday = 'Friday',
	Saturday = 'Saturday',
	Sunday = 'Sunday',
}

export enum ModelWeekDayToXclWeekDay {
	Monday = '1',
	Tuesday = '2',
	Wednesday = '3',
	Thursday = '4',
	Friday = '5',
	Saturday = '6',
	Sunday = '7',
}

export enum Months {
	January = 'January',
	February = 'February',
	March = 'March',
	April = 'April',
	May = 'May',
	June = 'June',
	July = 'July',
	August = 'August',
	September = 'September',
	October = 'October',
	November = 'November',
	December = 'December'
}

export enum ModelMonthToXclMonth {
	January = '1',
	February = '2',
	March = '3',
	April = '4',
	May = '5',
	June = '6',
	July = '7',
	August = '8',
	September = '9',
	October = '10',
	November = '11',
	December = '12'
}
