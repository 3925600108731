import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Signable, Signature } from '@mdib/signature';
import { of } from 'rxjs/internal/observable/of';
import { PasswordProcessService } from './password-process.service';

import { ServiceResponse } from '@mdib/utils';

@Injectable()
export class PasswordProcessServiceStub extends PasswordProcessService {

	public prepareSignatureFor(object: Signable): Observable<ServiceResponse<Signature>> {
		return of();
	}

	public createSignatureFor(object: Signable, password: string): Observable<ServiceResponse<Signature>> {
		return of();
	}
}
