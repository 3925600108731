import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BankConfig } from '@mdib/config';
import { isNullOrUndefined } from 'util';

@Pipe({
	name: 'timeFormatter'
})
export class TimeFormatterPipe implements PipeTransform {

	private timePipe: DatePipe = new DatePipe(BankConfig.baseLocale);

	transform(inputDate: string): string {
		if (isNullOrUndefined(inputDate) || !inputDate) {
			return '';
		}

		return (this.timePipe.transform(inputDate, 'shortTime'));
	}

}
