import { PendingPaymentFrequency, OperationStatus } from './payments.enum';

export class PendingPayment {
	paymentDate: Date;
	ordererAccountNumber: string;
	ordererName: string;
	counterpartyAccountNumber: string;
	counterpartyName: string;
	paymentType: string;
	status: OperationStatus;
	frequency: PendingPaymentFrequency;
	amount: number;
	currency: string;
	isUrgentTransfer: boolean;

	private _reference: string;

	get reference() {
		return this._reference;
	}

	set reference(reference: string) {
		this._reference = reference;
	}
}
