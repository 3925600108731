import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountStatementsPageFilter, AccountStatementStatus } from '@mdib/account-statements';
import { DataFormattingService, DateValidator } from '@mdib/utils';
import { isNullOrUndefined } from 'util';

@Component({
	selector: 'account-statements-page-filter',
	templateUrl: './account-statements-page-filter.component.html',
	styleUrls: ['./account-statements-page-filter.component.scss'],
})

export class AccountStatementsPageFilterComponent implements OnChanges, OnInit {

	readonly NAMESPACE = 'accountStatementsPagesModule.accountStatementsPageFilterComponent.';
	@Input() filter: AccountStatementsPageFilter;
	public AccountStatementStatus = AccountStatementStatus;
	public form: FormGroup;
	protected maxYear: number;
	protected minStatementNumberFrom = 1;
	protected maxStatementNumberFrom = 1;
	protected minStatementNumberTo = 1;

	constructor(private dataFormattingService: DataFormattingService,
				private fb: FormBuilder,
	) {
		this.maxYear = new Date().getFullYear();
	}

	ngOnInit(): void {
		this.form = this.fb.group({
			status: [AccountStatementStatus.New],
			accountingDateFrom: [null, [DateValidator.dateNotAfterToday]],
			accountingDateTo: [null, [DateValidator.dateNotAfterToday]],
			statementNumberFrom: ['', Validators.min(this.minStatementNumberFrom)],
			statementNumberTo: ['', Validators.min(this.minStatementNumberTo)],
			year: ['', Validators.max(this.maxYear)],
		});
		this.initSubscription();
	}

	initSubscription() {
		this.status.valueChanges.subscribe(status => this.filter.status = status);
		this.dateFromControl.valueChanges.subscribe(accountingDateFrom => this.filter.accountingDateFrom = accountingDateFrom);
		this.dateToControl.valueChanges.subscribe(accountingDateTo => this.filter.accountingDateTo = accountingDateTo);
		this.numberFromControl.valueChanges.subscribe((statementNumberFrom: number) => {
			this.filter.statementNumberFrom = statementNumberFrom;
			this.minStatementNumberTo = statementNumberFrom;
		});
		this.numberToControl.valueChanges.subscribe((statementNumberTo: number) => {
			this.filter.statementNumberTo = statementNumberTo;
			this.maxStatementNumberFrom = statementNumberTo;
		});
		this.yearControl.valueChanges.subscribe(statementYear => this.filter.statementYear = statementYear);
	}

	ngOnChanges() {
		// TODO : temporary quickFix, use filter reset button, maybe filter interface architecture
		if (!isNullOrUndefined(this.form)) {
			this.form.reset({
					status: AccountStatementStatus.New,
				},
			);
		}
	}

	/**
	 * This method is called when the user choose "statement number" criterion.
	 * It clears the date fields in the filter and set the statement year at the current year.
	 */
	resetAccountingDateTo() {
		this.form.patchValue({
			statementNumberFrom: 1,
			accountingDateFrom: null,
			accountingDateTo: null,
			year: this.currentYear
		});
	}

	/**
	 * This method is called when the user choose "accounting date" criterion.
	 * It clears the statement number fields in the filter.
	 */
	resetStatementNumber() {
		this.form.patchValue({
			statementNumberFrom: null,
			statementNumberTo: null,
			year: null
		});
	}

	/**
	 * Returns the current year as a string
	 * Used to initialize the accountStatementNumber filter when selected in the page.
	 */
	get currentYear(): number {
		return new Date().getFullYear();
	}

	/**
	 * Returns the current date.
	 * Used to set the maximum date for the date filters.
	 */
	get currentDate(): Date {
		return new Date();
	}

	/**
	 * This method returns the maximum AccountingFromDate based on the to date filter if filled, otherwise it returns the current date.
	 * @returns {Date} maxDate
	 */
	get maxAccountingDateFrom(): Date {
		if (this.dateToControl.value) {
			return 	this.dateToControl.value;
		} else {
			return this.currentDate;
		}
	}

	/**
	 * This method returns the minimum  AccountingToDate based on the to date filter if filled, otherwise it returns the minimum date value possible.
	 * @returns {Date} maxDate
	 */
	get minAccountingDateTo(): Date {
		if (this.dateFromControl.value) {
			return 	this.dateFromControl.value;
		} else {
			return new Date(-8640000000000000);
		}
	}

	get _(): FormGroup {
		return this.form;
	}

	get status(): AbstractControl {
		return this.form.get('status');
	}

	get dateFromControl(): AbstractControl {
		return this.form.get('accountingDateFrom');
	}

	get dateToControl(): AbstractControl {
		return this.form.get('accountingDateTo');
	}

	get numberFromControl(): AbstractControl {
		return this.form.get('statementNumberFrom');
	}

	get numberToControl(): AbstractControl {
		return this.form.get('statementNumberTo');
	}

	get yearControl(): AbstractControl {
		return this.form.get('year');
	}
}
