import { Component, OnInit, HostListener, ElementRef, Renderer2 } from '@angular/core';

import { ILayout } from '../../interfaces/layout.interface';
import { VirtualNumpad } from '../../interfaces/virtual-numpad.interface';
import { VirtualNumpadService } from '../../services/virtual-numpad.service';

@Component({
	selector: 'virtual-numpad',
	templateUrl: './virtual-numpad.component.html',
	styleUrls: ['./virtual-numpad.component.scss'],
})
export class VirtualNumpadComponent implements OnInit, VirtualNumpad {

	toggled = false;
	input: HTMLInputElement = null;
	layout: ILayout;
	maxLenght: number;

	constructor(
		private virtualNumpadService: VirtualNumpadService,
		private renderer2: Renderer2,
		private elementRef: ElementRef,
	) { }

	ngOnInit(): void {
		this.virtualNumpadService.toggle.subscribe(
			(virtualNumpad: VirtualNumpad) => {
				this.toggled = true;
				this.input = virtualNumpad.input;
				this.layout = virtualNumpad.layout;
				this.maxLenght = virtualNumpad.maxLenght;
			}
		);
	}

	@HostListener('body:click', ['$event.srcElement']) onDocumentClick(srcElement: Element): void {
		if (this.toggled) {
			const element: HTMLElement = this.elementRef.nativeElement;
			const clickedInside = element.contains(srcElement);
			if (!clickedInside) {
				const clickOnInput = event.srcElement === this.input || srcElement.firstElementChild === this.input;
				if (!clickOnInput) {
					this.toggled = false;
				}
			}
		}
	}

	onClick(value): void {
		if (this.maxLenght && this.maxLenght === this.input.value.length) {
			return;
		}
		const oldValue = this.input.value;
		if (value === 'Enter') {
			this.simulateEnter();
		} else if (value === 'Backspace') {
			const newValue = oldValue.substring(0, oldValue.length - 1);
			this.renderer2.setProperty(this.input, 'value', newValue);
		} else {
			const newValue = oldValue + value;
			this.renderer2.setProperty(this.input, 'value', newValue);
		}
		this.input.dispatchEvent(new Event('input'));
		if (this.maxLenght && this.maxLenght === this.input.value.length) {
			this.simulateEnter();
		}
	}

	private simulateEnter(): void {
		const inputForm = this.input.form;
		if (inputForm) {
			inputForm.dispatchEvent(new Event('submit'));
		}
		this.toggled = false;
	}
}
