import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { PaymentsService } from './payments.service';
import { PaymentsServiceStub } from './payments.service.stub';

@Injectable()
export class PaymentsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return PaymentsService; }
	protected ServiceDefinition() { return PaymentsServiceStub; }
}
