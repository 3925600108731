import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { WithdrawalSimulation } from '@mdib/cash-accounts';
import { SavingsAccountCommonService } from '@mdib/cash-accounts';
import { ServiceResponse } from '@mdib/utils';

@Injectable({
	providedIn: 'root'
})
export class SavingsAccountMemoryService extends SavingsAccountCommonService {
	private _simulation: WithdrawalSimulation;

	constructor(private httpClient: HttpClient) {
		super();
	}

	public getWithdrawalSimulation(simulation: WithdrawalSimulation): Observable<ServiceResponse<WithdrawalSimulation>> {
		this._simulation = simulation;
		this.result();
		return this.getImage().pipe(mergeMap(
			blob => {
				this._simulation.document = blob;
				return of(new ServiceResponse(this._simulation));
			}
		));
	}

	private getImage(): Observable<Blob> {
		return this.httpClient.get('/edm/withdrawal-simulation-mock.pdf', { responseType: 'blob' });
	}

	private result() {
		this._simulation.result = this._simulation.amount * 0.01 + 1;
	}
}
