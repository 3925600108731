import { Component, Input, HostListener, ViewChild, ElementRef, forwardRef, Output, EventEmitter, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { KeyValuePair } from '@mdib/utils';

/**
 * @deprecated This component is an older version, please use the new {DropdownComponent}.
 * This component will be erased when all forms have been refactored.
 */
@Component({
	selector: 'input-select',
	templateUrl: './input-select.component.html',
	styleUrls: ['./input-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputSelectComponent),
			multi: true,
		},
	],
})
export class InputSelectComponent implements ControlValueAccessor, OnChanges {

	@Input()
	public items: Array<KeyValuePair<string, any>>;

	@Input()
	public placeholder: string;

	@Input()
	public translate = true;

	@Input()
	public isActionRequestOngoing: boolean;

	@Input()
	public autoselect = true;

	@Output()
	public blur = new EventEmitter<null>();

	public isDroppedDown = false;
	public selectedItem = null;
	public onChange: Function;
	public onTouched: Function;

	@ViewChild('dropdown')
	private dropdown: ElementRef;

	constructor() {
		this.onChange = () => null;
		this.onTouched = () => null;
	}

	ngOnChanges() {
		if (this.autoselect && this.items.length === 1) {
			this.select(this.items[0]);
		}
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	// Toggle dropdown
	public toggle() {
		if (!this.isActionRequestOngoing) {
			this.isDroppedDown = !this.isDroppedDown;
		}
	}

	// Hide on outside click
	@HostListener('document:click', ['$event.target'])
	public onclick(target: HTMLElement) {
		if (this.isDroppedDown && !this.dropdown.nativeElement.contains(target)) {
			this.blur.emit();
		}
		this.isDroppedDown = this.isDroppedDown && this.dropdown.nativeElement.contains(target);
	}

	// Model --> View
	public writeValue(item: any): void {
		this.selectedItem = this.items.find((element: KeyValuePair<string, any>) => (element.value === item));
	}

	// View --> Model
	public select(item: KeyValuePair<string, any>) {
		this.selectedItem = item;
		this.onChange(item.value);
	}
}
