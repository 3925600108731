import { Injectable } from '@angular/core';
import { Observable, zip, Observer, forkJoin } from 'rxjs';

import { UtilsHelper } from '@mdib/utils';
import { ContactAddressBuilder } from '@mdib/customers';
import { Creditor, Debtor, SepaDirectDebitContract, ContractStatus, SuspensionPeriod, MaximumFrequencyTypesParam } from '@mdib/sepa-direct-debits';

import { XclDateFormatter } from '@mdib-xcl/utils';

import { SepaDirectDebitContractXcl } from '../model/sepa-direct-debit-contract-xcl';
import { XclContractStatus } from '../model/xcl-contract-status';
import { XclContractType } from '../model/xcl-contract-type';
import { CreditorDetailsXcl } from '../model/creditor-details-xcl';
import { ParameterSetsService } from '@mdib/core/customization';
import { map } from 'rxjs/operators';

@Injectable()
export class SepaDirectDebitContractsMapperXclService {

	constructor(
		private parameterSetsService: ParameterSetsService,
	) { }

	/**
	 * Parse technical model in functional model
	 * @param SepaDirectDebitContractXcl : source format.
	 * @returns SepaDirectDebitContract : parsed functional model format.
	 */
	public sepaDirectDebitContractXclToSepaDirectDebitContract(sepaDirectDebitContractXcl: SepaDirectDebitContractXcl): Observable<SepaDirectDebitContract> {
		const sepaDirectDebitContractModel = <SepaDirectDebitContract>{
			// Mandatory Fields
			debtor: <Debtor>{
				accountNumber: sepaDirectDebitContractXcl.counterpartyAccountNumber,
				bicCode: sepaDirectDebitContractXcl.counterpartyBicCode,
				name: sepaDirectDebitContractXcl.counterpartyName,
				address: new ContactAddressBuilder()
					.addressLine1(UtilsHelper.nullOrUndefined(sepaDirectDebitContractXcl.counterpartyAddressPart1) ? null : sepaDirectDebitContractXcl.counterpartyAddressPart1)
					.addressLine2(UtilsHelper.nullOrUndefined(sepaDirectDebitContractXcl.counterpartyAddressPart2) ? null : sepaDirectDebitContractXcl.counterpartyAddressPart2)
					.country(sepaDirectDebitContractXcl.countryCode)
					.contactAddress
			},
			contractNumber: sepaDirectDebitContractXcl.contractNumber,
			contractStatus: XclContractStatus.fromXclType(sepaDirectDebitContractXcl.contractStatus),
			contractType: XclContractType.fromXclType(sepaDirectDebitContractXcl.paymentScheme),
			contractReference: sepaDirectDebitContractXcl.contractReference,
			creditor: <Creditor>{
				identity: sepaDirectDebitContractXcl.ordererIdentity,
				name: sepaDirectDebitContractXcl.creditorName
			},
			signingDate: UtilsHelper.nullOrUndefined(sepaDirectDebitContractXcl.contractSigningDate) ? null : XclDateFormatter.stringToDate(sepaDirectDebitContractXcl.contractSigningDate),
			contractStartDate: UtilsHelper.nullOrUndefined(sepaDirectDebitContractXcl.contractStartDate) ? null : XclDateFormatter.stringToDate(sepaDirectDebitContractXcl.contractStartDate),
			contractEndDate: UtilsHelper.nullOrUndefined(sepaDirectDebitContractXcl.contractEndDate) ? null : XclDateFormatter.stringToDate(sepaDirectDebitContractXcl.contractEndDate),
		};

		// Non Mandatory Fields
		sepaDirectDebitContractModel.copyRequestDate = UtilsHelper.isNullOrWhiteSpace(sepaDirectDebitContractXcl.contractCopyRequestDate) ? null : XclDateFormatter.stringToDate(sepaDirectDebitContractXcl.contractCopyRequestDate);
		sepaDirectDebitContractModel.suspensionPeriod = <SuspensionPeriod>{
			suspensionStartDate: UtilsHelper.isNullOrWhiteSpace(sepaDirectDebitContractXcl.suspensionStartDate) ? null : XclDateFormatter.stringToDate(sepaDirectDebitContractXcl.suspensionStartDate),
			suspensionEndDate: UtilsHelper.isNullOrWhiteSpace(sepaDirectDebitContractXcl.suspensionEndDate) ? null : XclDateFormatter.stringToDate(sepaDirectDebitContractXcl.suspensionEndDate)
		};
		sepaDirectDebitContractModel.singlePayment = sepaDirectDebitContractXcl.singlePaymentSwitch;
		sepaDirectDebitContractModel.frequencyPeriodEndDate = UtilsHelper.isNullOrWhiteSpace(sepaDirectDebitContractXcl.periodEndDate) ? null : XclDateFormatter.stringToDate(sepaDirectDebitContractXcl.periodEndDate);
		sepaDirectDebitContractModel.numberOfPaymentsForPeriod = sepaDirectDebitContractXcl.numberOfPeriodPayments;
		sepaDirectDebitContractModel.maximumNumberOfPaymentsForPeriod = sepaDirectDebitContractXcl.maximumNumberOfPeriodPayments;
		sepaDirectDebitContractModel.amountLimitActivation = sepaDirectDebitContractXcl.maximumAmountCheckSwitch;
		sepaDirectDebitContractModel.maximumAmount = sepaDirectDebitContractXcl.maximumAmount;

		return this.getMaximumFrequencyTypeFromXclCode(sepaDirectDebitContractXcl.maximumFrequency).pipe(map(maximumFrequency => {
			sepaDirectDebitContractModel.maximumFrequency = maximumFrequency;
			return sepaDirectDebitContractModel;
		}));
	}

	/**
	 * Converts the technical model list into functional model list
	 * @param SepaDirectDebitContractXcl[] : source format list.
	 * @returns SepaDirectDebitContract[] : functional model format list.
	 */
	public sepaDirectDebitContractListXclToSepaDirectDebitContractList(sepaDirectDebitContractXclList: SepaDirectDebitContractXcl[]): Observable<SepaDirectDebitContract[]> {
		if (!sepaDirectDebitContractXclList) {
			console.log('ERROR: mapping issue from SepaDirectDebitContractXcl[] to SepaDirectDebitContract[]');
			return null;
		}
		const observables: Observable<SepaDirectDebitContract>[] = sepaDirectDebitContractXclList.map((item: SepaDirectDebitContractXcl) => this.sepaDirectDebitContractXclToSepaDirectDebitContract(item));
		if (observables.length === 0) {
			return new Observable<SepaDirectDebitContract[]>(
				(observer: Observer<SepaDirectDebitContract[]>) => {
					observer.next([]);
				}
			);
		}
		return forkJoin(observables);
	}


	/**
	 * Parse technical model in functional model
	 * @param SepaDirectDebitContract and CreditorAddressXcl: source format.
	 * @returns SepaDirectDebitContract : parsed functional model format.
	 */
	public xclCreditorDetailsToFunctional(sepaDirectDebitContract: SepaDirectDebitContract, creditorDetailsXcl: CreditorDetailsXcl): SepaDirectDebitContract {
		if (UtilsHelper.nullOrUndefined(sepaDirectDebitContract)) {
			sepaDirectDebitContract = new SepaDirectDebitContract();
		}
		if (UtilsHelper.nullOrUndefined(sepaDirectDebitContract.creditor)) {
			sepaDirectDebitContract.creditor = <Creditor>{};
		}
		sepaDirectDebitContract.creditor.name = creditorDetailsXcl.creditorName;
		sepaDirectDebitContract.creditor.address = new ContactAddressBuilder()
			.addressLine1(creditorDetailsXcl.creditorAddressPart1)
			.addressLine2(creditorDetailsXcl.creditorAddressPart2)
			.country(creditorDetailsXcl.countryCode)
			.contactAddress;

		return sepaDirectDebitContract;
	}

	/**
	 * Parse functional model to technical model.
	 * @param SepaDirectDebitContract : source format.
	 * @returns SepaDirectDebitContractXcl : parsed technical model format.
	 */
	public functionalToXcl(sepaDirectDebitContract: SepaDirectDebitContract): SepaDirectDebitContractXcl {
		const xclModel = <SepaDirectDebitContractXcl>{};
		if (UtilsHelper.nullOrUndefined(sepaDirectDebitContract.contractReference)) {
			return xclModel;
		}
		xclModel.contractReference = sepaDirectDebitContract.contractReference;
		// If the contract is active or reopened , then set suspension as action
		if (!UtilsHelper.nullOrUndefined(sepaDirectDebitContract.suspensionPeriod.suspensionStartDate)) {
			xclModel.suspensionStartDate = XclDateFormatter.convertDateToXCLDateFormat(sepaDirectDebitContract.suspensionPeriod.suspensionStartDate);
			if (!UtilsHelper.nullOrUndefined(sepaDirectDebitContract.contractStatus)) {
				xclModel.contractSuspensionSwitch = sepaDirectDebitContract.contractStatus === ContractStatus.Active ||
					sepaDirectDebitContract.contractStatus === ContractStatus.Reopened;
			}
		}
		// If the contract is suspended or suspended active , then set reactivation as action
		if (!UtilsHelper.nullOrUndefined(sepaDirectDebitContract.suspensionPeriod.suspensionEndDate)) {
			xclModel.suspensionEndDate = XclDateFormatter.convertDateToXCLDateFormat(sepaDirectDebitContract.suspensionPeriod.suspensionEndDate);
			if (!UtilsHelper.nullOrUndefined(sepaDirectDebitContract.contractStatus)) {
				xclModel.contractReactivationSwitch = sepaDirectDebitContract.contractStatus === ContractStatus.Suspended;
			}
		}
		return xclModel;
	}

	private getMaximumFrequencyTypeFromXclCode(backendMapping: string): Observable<string> {
		return this.parameterSetsService.get('sepa-direct-debits-frequencies').pipe(map((frequencies: MaximumFrequencyTypesParam[]) => {
			const frequency = frequencies.find(sf => sf.backendMapping === backendMapping);
			return frequency && frequency.key;
		}));
	}
}
