import { delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Signable, Signature } from '@mdib/signature';
import { DigipassSignatureService, DigipassSignature } from '@mdib/signature-modes';
import { ServiceResponse } from '@mdib/utils';

@Injectable({
	providedIn: 'root'
})
export class DigipassSignatureMemoryService implements DigipassSignatureService {

	public prepareSignatureFor(object: Signable): Observable<ServiceResponse<DigipassSignature>> {
		const signature: DigipassSignature = {
			typeName: 'digipass',
			challenges: [
				{ fieldName: 'counterpartyAccountNumber', fieldValue: 'BE55 0013 3136 8244', challenge: '001331' },
				{ fieldName: 'operationAmount', fieldValue: '23.10 €', challenge: '231000' }
			]
		};
		return of(new ServiceResponse(signature)).pipe(delay(250));
	}

	public createSignatureFor(object: Signable, token: string): Observable<ServiceResponse<DigipassSignature>> {
		return of(new ServiceResponse(<DigipassSignature>{})).pipe(delay(250));
	}

}
