import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material';
import { ListConfigurations } from '@mdib/generic';
import { NotificationMessageService } from '@mdib/notification-message';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'mdib-list-page',
	templateUrl: './list-page.component.html',
	styleUrls: ['./list-page.component.scss'],
})
export class ListPageComponent {

	@Input() listConfigurations: ListConfigurations;
	// this input is there to toggle the search and delete button
	@Input() showButtons = true;
	@Output() clearFilter = new EventEmitter();
	@Output() research = new EventEmitter();
	@Output() pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
	@Output() xlsButtonClicked = new EventEmitter<boolean>();

	panelOpenState = false;
	currentLength = 0;

	constructor(private notificationMessageService: NotificationMessageService) {
	}

	/**
	 * This method is called to know if the list has been initialized.
	 * @returns {boolean}
	 */
	get isListInitialized() {
		return !!(this.listConfigurations.dataSource) && (this.listConfigurations.totalCount > 0);
	}

	/**
	 * This method is called to know if the list is empty.
	 * @returns {Observable<boolean>}
	 */
	get isListEmpty(): Observable<boolean> {
		if (this.listConfigurations.loaded) {
			return this.listConfigurations.dataSource.connect().pipe(
				map((data: Element[]) => data.length === 0),
			);
		}
		return of(false);
	}

	/**
	 * This method is called to know if the spinner is to be shown.
	 * @returns {Observable<boolean>}
	 */
	get showSpinner(): Observable<boolean> {
		if (this.listConfigurations.loaded) {
			return this.listConfigurations.dataSource.connect().pipe(
				map((data: Element[]) => {
					this.currentLength = data.length;
					return this.currentLength > 0 && this.listConfigurations.totalCount === 0;
				}));
		}
		return of(this.currentLength === 0);
	}

	/**
	 * Triggers the event for clear the filter fields.
	 */
	clear() {
		this.notificationMessageService.clearAll();
		this.clearFilter.emit();
	}

	/**
	 * Triggers the event for research with criteria's filter.
	 */
	search() {
		this.notificationMessageService.clearAll();
		this.research.emit();
	}

	/**
	 * This method is called to know if the list is having any error.
	 * @returns {Observable<boolean>}
	 */
	get isListError(): Observable<boolean> {
		if (this.listConfigurations.loaded && (this.listConfigurations.totalCount === -1)) {
			return of(true);
		}
		return of(false);
	}

	xlsActionButton() {
		this.xlsButtonClicked.emit(true);
	}
}
