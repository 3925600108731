import { Injectable } from '@angular/core';
import { SignableOperation } from '@mdib/signature';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { PaymentOperation } from '../types/models/payment-operation';
import { PaymentTypes } from '../types/models/payments.enum';
import { PendingPayment } from '../types/models/pending-payment';
import { PendingPaymentsPageFilter } from '../types/models/pending-payments-page-filter';

/**
 * Holds and update the state of a payment operation during its creation.
 */
@Injectable()
export abstract class PaymentsService {

	/**
	 * Signs and submit the payment operation
	 * @abstract
	 * @param {PaymentOperation<null>} payment
	 * @returns {(Observable<ServiceResponse<PaymentOperation<null>|null>>)}
	 * @memberof PaymentsService
	 * @example
	 * this.paymentsService.signPayment(paymentOperation)
	 *      .subscribe(
	 *            (serviceResponse: ServiceResponse<PaymentOperation<null>>) => this.save(serviceResponse.getModel())
	 *        );
	 */
	public abstract signPayment(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>>;

	public abstract confirmPayment(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null> | null>;

	public abstract validatePayment(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>>;

	/**
	 * Retrieve a payment
	 * @param string reference the reference of the payment
	 * @param PaymentTypes type the type of the payment
	 * @returns {(Observable<ServiceResponse<PaymentOperation<null>|null>>)} the draft payment
	 * @memberof PaymentsService
	 */
	public abstract get(reference: string, type: PaymentTypes): Observable<ServiceResponse<PaymentOperation<null> | null>>;

	/**
	 * Retrieve a draft payment from the shopping basket.
	 * @param string reference of the draft payment
	 * @returns {(Observable<ServiceResponse<PaymentOperation<null>|null>>)} the draft payment
	 * @memberof PaymentsService
	 */
	public abstract getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<null> | null>>;

	/**
	 * Remove a payment from pending payments.
	 * @param {string} reference of the payment
	 * @returns {Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>>} payment operation to be deleted
	 * @memberof PaymentsService
	 */
	public abstract delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>>;

	/**
	 * Signs deletion of a pending payment
	 * @param {signablePaymentOperation: SignableOperation<PaymentOperation<null>>} reference of the payment
	 * @returns {Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>>} the signed payment operation to be deleted
	 * @memberof PaymentsService
	 */
	public abstract signDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>>;

	/**
	 * Adds deletion of a pending payment to shopping basket
	 * @param {signablePaymentOperation: SignableOperation<PaymentOperation<null>>)} signablePaymentOperation to be deleted
	 * @returns {Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>>} confirmed payment operation
	 * @memberof PaymentsService
	 */
	public abstract confirmDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>>;

	/**
	 * @param {PendingPaymentsPageFilter} filter
	 * @returns {Observable<ServiceResponse<number>>} The number of {PendingPayment} matching the filter.
	 */
	public abstract count(filter?: PendingPaymentsPageFilter): Observable<ServiceResponse<number>>;

	/**
	 * Computes a list of {PendingPayments} based on pagination and filters
	 *
	 * @param {number} pageNumber
	 * @param {number} pageSize
	 * @param {PendingPaymentsPageFilter} filter
	 * @returns {Observable<ServiceResponse<PendingPayment[]>>}
	 */
	public abstract list(index: number, count: number, filter?: PendingPaymentsPageFilter): Observable<ServiceResponse<PendingPayment[]>>;


	/**
	 * Gives the payment operation being created/populated.
	 * @returns PaymentOperation : new initialized or under modification.
	 */
	// public abstract get paymentBeingCreated(): PaymentOperation;

}
