import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';

import { ArtificialPerson, NaturalPerson, PersonsService } from '@mdib/customers';
import { AuthenticationDataService, AuthenticationStatisticData } from '@mdib/sessions';
import { ServiceResponse, UtilsHelper } from '@mdib/utils';
import { of } from 'rxjs';
import { Holder } from '@mdib/customers/model/holder';

import { isNullOrUndefined } from 'util';
import { finalize, catchError } from 'rxjs/operators';

@Component({
	selector: 'mdib-person-profile',
	templateUrl: './person-profile.component.html',
	styleUrls: ['./person-profile.component.scss'],
})
export class PersonProfileComponent implements OnInit {

	readonly NAMESPACE = 'headerModule.userProfileComponent.';
	pathToProfilePicture: string;

	@Output() logout = new EventEmitter();
	person: NaturalPerson | ArtificialPerson;
	holderInitials: string;
	authenticationStatisticData: AuthenticationStatisticData;
	isLoadingPersonInfo = false;
	isLoadingAuthenticationInfo = false;

	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

	constructor(
		private personsService: PersonsService,
		private authenticationDataService: AuthenticationDataService,
	) {
	}

	ngOnInit() {
		if (sessionStorage.getItem('profilePicture')) {
			this.pathToProfilePicture = 'data:image/png;base64,' + sessionStorage.getItem('profilePicture');
		} else {
			this.pathToProfilePicture = 'assets/images/backgroundProfile.jpg';
		}

		this.loadPersonData();
		this.loadAuthenticationStatisticData();
	}

	doLogout() {
		this.person = null;
		this.logout.emit();
	}

	/**
	 * Retrieves Most Recent {Authentication Statistic Data} information by sorting based on lastConnectionDateAndTime
	 * passing list of Authentication Statistic Data
	 *
	 * @returns {AuthenticationStatisticData}
	 */
	public retrieveMostRecentConnectionDateAndTime(authenticationStatisticData: AuthenticationStatisticData[]) {
		if (!isNullOrUndefined(authenticationStatisticData) && authenticationStatisticData.length) {
			// Sort then return the first index
			return authenticationStatisticData.sort((a: AuthenticationStatisticData, b: AuthenticationStatisticData) =>
				b.lastConnectionDateAndTime.getTime() - a.lastConnectionDateAndTime.getTime()
			)[0];
		}
	}

	setHolderInitials() {
		this.holderInitials = '';
		if (this.person instanceof NaturalPerson) {
			if (this.person.firstName && this.person.lastName) {
				this.holderInitials = this.person.firstName.charAt(0) + this.person.lastName.charAt(0);
			} else if (this.person.name) {
				const names = this.person.name.split(' ');
				this.holderInitials += names.shift().charAt(0);
				this.holderInitials += names.pop().charAt(0);
			}
		} else {
			// TODO : what to display when corporate
		}
	}

	private loadPersonData(): void {
		this.isLoadingPersonInfo = true;
		this.personsService.get()
			.pipe(finalize(() => {
				this.isLoadingPersonInfo = false;
				this.setHolderInitials();
			}))
			.subscribe((serviceResponse: ServiceResponse<NaturalPerson | ArtificialPerson>) => {
				this.person = serviceResponse.getModel();
			});
	}

	private loadAuthenticationStatisticData(): void {
		this.isLoadingAuthenticationInfo = false;
		this.authenticationDataService.getStatistics().pipe(
			catchError(err => {
				return of([]);
			}),
			finalize(() => {
				this.isLoadingAuthenticationInfo = false;
			})
		).subscribe((serviceResponse: ServiceResponse<AuthenticationStatisticData[]>) => {
			if (UtilsHelper.objectNotEmpty(serviceResponse)) {
				const fliteredData: AuthenticationStatisticData[] = serviceResponse.getModel().filter(goal => goal.lastConnectionDateAndTime !== null);
				// Sort the list bases on most recent last connection date and time.
				this.authenticationStatisticData = this.retrieveMostRecentConnectionDateAndTime(fliteredData);
			}
		});
	}


}
