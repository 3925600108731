import { NgModule, ModuleWithProviders } from '@angular/core';

import { KeyingLimitsService } from './service/keying-limits.service';
import { KeyingLimitsServiceProxy } from './service/keying-limits.service.proxy';

@NgModule()
export class KeyingLimitsModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: KeyingLimitsModule,
			providers: [
				{ provide: KeyingLimitsService, useClass: KeyingLimitsServiceProxy },
			]
		};
	}
}
