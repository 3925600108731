import { AutoUnsubscribeHelper } from './auto-unsubscribe-helper';

export function AutoUnsubscribe() {
	return (target, key) => {
		// Initialize fields to be AutoUnsubscribed
		if (!target['autoUnsubscribeFieldsList']) {
			target['autoUnsubscribeFieldsList'] = [];
			target['savedNgOnDestroy'] = target['ngOnDestroy'] || function () { };
			Object.assign(target, AutoUnsubscribeHelper.prototype);
		}
		// Register field to be AutoUnsubscribed
		target['autoUnsubscribeFieldsList'].push(key);
	};
}
