import { Component, OnInit, OnDestroy } from '@angular/core';
import { DefaultBannerNotificationComponent } from './default-banner-notification.component';

@Component({
	selector: 'mdib-banner-notification',
	templateUrl: './banner-notification.component.html',
	styleUrls: ['./default-banner-notification.component.scss'],
})
export class BannerNotificationComponent extends DefaultBannerNotificationComponent implements OnInit, OnDestroy {
	ngOnDestroy() {
		super.ngOnDestroy();

		this.stateHolder.setState(true);
	}

	protected initMyself() {
		this.stateHolder.setState(false);
	}
}
