import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileFormat } from '@mdib/utils';

@Component({
	selector: 'format-section',
	templateUrl: './format-section.component.html',
	styleUrls: ['./format-section.component.scss'],
})
export class FormatSectionComponent {

	readonly NAMESPACE = 'accountStatementsPagesModule.accountStatementsPageComponent.formatSection.';

	@Input() control: FormControl;

	filExtType = FileFormat;
}
