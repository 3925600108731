/**
 * Generic item.
 */
export interface ItemModel {
	/**
	 * Identifier of the item.
	 */
	id: string;
}

/**
 * Abstract class for a generic item.
 * It must be extended to create new types.
 */
export abstract class Item<M extends ItemModel, T extends Item<M, T>> {
	/**
	 * Identifier of the item.
	 */
	public readonly id: string;

	/**
	 * Constructor taking the item class as builder
	 *
	 * @param builder The class used to build an item
	 */
	constructor(
		private builder: new (properties) => T,
	) { }

	/**
	 * Generic setter on the immutable object.
	 *
	 * @param key Name of the property to modify
	 * @param value New value of the property
	 * @returns A copy of the item with the new value for the property
	 */
	public set(key: keyof M, value: any): T {
		const copy: any = {};
		Object.assign(copy, this);
		copy[key] = value;
		return new this.builder(copy);
	}

	/**
	 * Generic patcher on the immutable object.
	 *
	 * @param properties List of properties to modify
	 * @returns A copy of the item with the new value for the properties
	 */
	public patch(properties: Partial<M> = {}): T {
		const copy: any = {};
		Object.assign(copy, this);
		Object.assign(copy, properties);
		return new this.builder(copy);
	}

	/**
	 * Setter for the item Identifier field
	 *
	 * @param id The new item identifier
	 */
	public setId(id: string): T {
		return this.set('id', id);
	}

}
