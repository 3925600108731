import { AccessRight } from './access-right';
import { Authorization } from './authorization.enum';


export class AccessRightToken extends AccessRight {
	constructor(
		private token: string,
		private positiveAuthorization = Authorization.GRANT,
		private negativeAuthorization = Authorization.DENY
	) { super(); }

	public authorizationFor(tokens: string[]): Authorization {
		// No token required
		if (!tokens || tokens.length === 0 || this.token === '') {
			return this.positiveAuthorization;
		}

		// Check if a token is matching this access right
		return tokens
			.map(token => this.authorizedFor(token + '.'))
			.reduce((prev, next) => (prev || next))
			? this.positiveAuthorization : this.negativeAuthorization;
	}

	private authorizedFor(referenceToken: string): boolean {
		const rightToken = this.token.split('.');
		return referenceToken
			.split('.')
			.map((tokenPart, i) => (i > (rightToken.length - 1) || tokenPart === '*' || tokenPart === rightToken[i]))
			.reduce((prev, next) => (prev && next), true);
	}
}
