import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { AccountValuationsService } from '@mdib/account-valuations';
import { AdaptersService } from '@mdib/core/adapters';
import { AccountValuationsMapperXclService } from './service/account-valuations-mapper-xcl.service';
import { AccountValuationsXclService } from './service/account-valuations-xcl.service';

@NgModule()
export class XclAccountValuationsModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(AccountValuationsService, adapterId, AccountValuationsXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: XclAccountValuationsModule,
			providers: [
				AccountValuationsMapperXclService,
			]
		};
	}
}
