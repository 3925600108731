import { Inject, NgModule } from '@angular/core';
import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';
import { PaymentFilesMemoryService } from './service/payment-files-memory.service';
import { AdaptersService } from '@mdib/core/adapters';
import { PaymentFilesService } from '@mdib/payment-files';


@NgModule()
export class PaymentFilesMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(PaymentFilesService, adapterId, PaymentFilesMemoryService);
	}
}
