import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { KeyingLimitsService } from './keying-limits.service';
import { KeyingLimitsServiceStub } from './keying-limits.service.stub';

@Injectable()
export class KeyingLimitsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return KeyingLimitsService; }
	protected ServiceDefinition() { return KeyingLimitsServiceStub; }
}

