import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { WithdrawalSimulation } from '@mdib/cash-accounts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InputError } from '../model/utils.typings';

export namespace AmountValidator {

	export const NOTPOSITIVE = {notPositive: <InputError> {translationKey: 'utilsModule.amountValidator.notPositive'}};

	export function AMOUNTMAX(control: AbstractControl, resp): {[key: string]: any} {
		return {amountMax: {translationKey: 'utilsModule.amountValidator.amountMax', params: {amount: control.value + '', amountMax: resp.value + ''}}};
	}

	export function checkPositive(control: AbstractControl): {[key: string]: any} | null {
		const cleanedAmount: number = parseFloat(('' + control.value).replace(',', '.'));
		return (!cleanedAmount && cleanedAmount !== 0) || cleanedAmount > 0 ? null : NOTPOSITIVE;
	}

	export function simulationAmountOverAvailableBalance(userService: WithdrawalSimulation): AsyncValidatorFn {
		return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
			return userService.isAmountSuperiorToAvailableBalance().pipe(map(
				resp => {
					return resp.superior ? AMOUNTMAX(control, resp) : null;
				},
			));
		};
	}
}
