
import { Pipe, PipeTransform } from '@angular/core';
import { SpaceFormatterPipe } from '@mdib/utils/pipe/space-formatter.pipe';

@Pipe({
	name: 'creditIdFormatter'
})
export class CreditIdentificationFormatterPipe implements PipeTransform {
	/**
	 * Returns for a given credit identification its formatted value by using the space formatter pipe and passing the index array for having the space after 4th and 7th index of the string.
	 * @param {string} [value] Credit identification number to format
	 * @returns {string} : Formatted credit identification number
	 */
	transform(value: string): string {
		const spaceFormatter = new SpaceFormatterPipe();
		return spaceFormatter.transform(value, [4, 7]);
	}
}
