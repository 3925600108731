import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { XclHttpService } from '@mdib-xcl/http';
import { SessionResetService } from '@mdib/sessions';

@Injectable({
	providedIn: 'root'
})
export class SessionResetXclService extends SessionResetService {

	constructor(private xclHttpService: XclHttpService) {
		super();
	}

	public reset(): Observable<any> {
		return this.xclHttpService.execute('session-reset', XclHttpService.POST);
	}

}
