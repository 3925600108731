import { Directive, Input, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

const resolvedPromise = Promise.resolve(null);

/**
 * @deprecated: this directive should absolutely not be used
 */
@Directive({
	selector: '[mdibNestableForm]'
})
export class NestableFormDirective implements OnInit, OnDestroy {

	static readonly FORM_ARRAY_NAME = 'CHILD_FORMS';

	private currentForm: FormGroup;

	@Input() private formGroup: FormGroup;
	@Input() private disableNestableFormDirective = false;

	constructor(@SkipSelf() @Optional() private parentForm: NestableFormDirective) {
	}

	ngOnInit() {
		if (!this.disableNestableFormDirective) {
			this.executePostponed(() => this.resolveAndRegister());
		}
	}

	ngOnDestroy() {
		if (!this.disableNestableFormDirective) {
			this.executePostponed(() => {
				if (this.parentForm) {
					this.parentForm.removeControl(this.currentForm);
				}
			});
		}
	}

	public registerNestedForm(control: AbstractControl): void {
		if (control === this.currentForm) {
			throw new Error('Trying to add itself! Nestable form can be added only on parent "NgForm" or "FormGroup".');
		}
		(<FormArray>this.currentForm.get(NestableFormDirective.FORM_ARRAY_NAME)).push(control);
	}

	public removeControl(control: AbstractControl): void {
		const array = (<FormArray>this.currentForm.get(NestableFormDirective.FORM_ARRAY_NAME));
		const idx = array.controls.indexOf(control);
		array.removeAt(idx);
	}

	private resolveAndRegister(): void {
		this.currentForm = this.formGroup;
		this.currentForm.addControl(NestableFormDirective.FORM_ARRAY_NAME, new FormArray([]));
		if (this.parentForm) {
			this.parentForm.registerNestedForm(this.currentForm);
		}
	}

	private executePostponed(callback: () => void): void {
		resolvedPromise.then(() => callback());
	}

}
