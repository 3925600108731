import { Person } from './person';

export class ArtificialPerson extends Person {

	constructor(
		// Mandatory
		readonly id: any,
		// Optional
		readonly name?: string,
		readonly category?: string,
		readonly defaultRepresentedClientSwitch?: boolean,
	) {
		super(id);
	}
}
