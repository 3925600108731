export enum ContactType {
	PhoneNumber = 'phoneNumber',
	Email = 'email',
	Address = 'address'
}

export enum NaturalPersonMaritalStatus {
	Married = 'married',
	Single = 'single',
	Divorced = 'divorced',
	Widowed = 'widowed'
}

export enum NaturalPersonGender {
	Male = 'male',
	Female = 'female'
}

