import { User } from './user';
import { Observable } from 'rxjs';

import { ServiceResponse } from '@mdib/utils';

export class Session {
	public activeUser: User;
	public close: () => Observable<ServiceResponse<any>>;
	public keepAlive: () => Observable<ServiceResponse<any>>;

	constructor(
		public id: string = null,
		public type: string
	) { }

}
