import { Injectable } from '@angular/core';
import { ElectronicalStatement, ElectronicalStatementsPageFilter } from '@mdib/electronical-statements';
import { Observable } from 'rxjs';
import { ServiceResponse, MdibFile } from '@mdib/utils';

@Injectable()
export abstract class ElectronicalStatementsService {

	/**
	 * Retrieves the list of electronical statements related to the current principal
	 * @param {number} [offset] Offset to start from (by default 0, i.e. from the beginning)
	 * @param {number} [limit] Maximum number of operations to retrieve (by default 0, i.e. no limit)
	 * @param {ElectronicalStatementsPageFilter} [filter] Object containing properties on which the result must be filtered
	 * @returns {Observable<ServiceResponse<ElectronicalStatement[]>>} List of electronical statements related to principal
	 * @memberof ElectronicalStatementsService
	 */
	public abstract list(offset?: number, limit?: number, filter?: ElectronicalStatementsPageFilter): Observable<ServiceResponse<ElectronicalStatement[]>>;

	/**
	 * Counts the list of electronical statements from XCL related to the current principal
	 * @param {AccountStatementsFilter} [filter] Object containing properties on which the result must be filtered
	 * @returns {Observable<ServiceResponse<number>>} The number of electronical statements related to principal
	 * @memberof AccountStatementsService
	 */
	public abstract count(filter?: ElectronicalStatementsPageFilter): Observable<ServiceResponse<number>>;

	/**
	 * Retrieve a {ElectronicalStatement} based on its unique identifier
	 *
	 * @param {string} file identifier
	 * @returns {Observable<ServiceResponse<MdibFile>>}
	 */
	public abstract get(id: string): Observable<ServiceResponse<ElectronicalStatement>>;

	/**
	 * This method allows to put a electronical statement to status 9000(printed)
	 *
	 * @param {ElectronicalStatement}
	 * @returns {Observable<ServiceResponse<ElectronicalStatement>>}
	 */
	public abstract update(electronicalStatement: ElectronicalStatement): Observable<ServiceResponse<ElectronicalStatement>>;

	public abstract initializeFilter(): ElectronicalStatementsPageFilter;

	public abstract downloadStatement(electronicalStatement: ElectronicalStatement): Observable<ServiceResponse<any>>;

}
