import { Component, Input, OnInit } from '@angular/core';
import { PaymentForm } from '@mdib/payments';
import { Periodicities, WeekDays, Months } from '@mdib/utils';

@Component({
	selector: 'frequency-section',
	templateUrl: './frequency-section.component.html',
})
export class FrequencySectionComponent implements OnInit {

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.dataGatheringStepComponent.frequencySectionComponent.';

	@Input() form: PaymentForm;
	periodicity = Periodicities;
	frequencyTypesList = Object.keys(Periodicities);
	weekDay = Object.keys(WeekDays);
	months = Object.keys(Months);
	paymentDaysChoices = {
		paymentDay: [Periodicities.monthly, Periodicities.biMonthly, Periodicities.trimonthly, Periodicities.quarterly, Periodicities.semiAnnually, Periodicities.yearly],
		weekPaymentDay: [Periodicities.weekly],
		firstPaymentDay: [Periodicities.semiMonthly, Periodicities.demiSemiMonthly],
		secondPaymentDay: [Periodicities.semiMonthly, Periodicities.demiSemiMonthly],
		thirdPaymentDay: [Periodicities.demiSemiMonthly],
		firstMaturityMonth: [Periodicities.yearly, Periodicities.semiAnnually, Periodicities.quarterly, Periodicities.biMonthly, Periodicities.trimonthly],
	};

	ngOnInit() {
		this.frequencyTypesList.splice(this.frequencyTypesList.indexOf('oneMonth'), 1); // The 'oneMonth' value is not needed in the dropdown
	}

	/**
	 * Return a condition which check if an additional field have to be displayed depends of the frequency selected
	 * @param {string} id : identifier of the field
	 * @returns {boolean} : return true if the field have to be displayed
	 */
	getConditions(paymentDaysChoices: string[]): boolean {
		if (!!this.form && !!this.form.standingOrderDetailsForm) {
			return paymentDaysChoices.indexOf(this.form.standingOrderDetailsForm.frequency.value) >= 0;
		}
		return false;
	}
}


