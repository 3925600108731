import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Third party
import { TranslateModule } from '@ngx-translate/core';
// Routing module
import { DashboardRoutingModule } from './dashboard-routing.module';
// Our modules
import { CashAccountsModule } from '@mdib/cash-accounts';
import { CardsModule } from '@mdib/cards/cards.module';
import { StubsModule } from '@mdib/stubs/stubs.module';
// Our components
import { DashboardPageComponent } from './component/dashboard-page/dashboard-page.component';
import { UtilsModule } from '@mdib/utils';
import { StylingModule } from '@mdib/styling';
/**
 * Dashboard pages module configuration
 */
@NgModule({
	imports: [
		// Angular modules
		CommonModule,
		// Third party
		TranslateModule,
		// Routing module
		DashboardRoutingModule,
		// Transversal modules
		CashAccountsModule,
		CardsModule,
		StubsModule,
		UtilsModule,
		StylingModule,
	],
	declarations: [
		DashboardPageComponent,
	],
	exports: [],
})
export class DashboardPagesModule {
}
