import { Injectable } from '@angular/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';
import { PaymentTypes } from '@mdib/payments';
import { ShoppingBasketOperation, ShoppingBasketOperationBuilder } from '@mdib/shopping-basket';
import { OrderStateHelper, XclDateFormatter, XclOrderNature } from '@mdib-xcl/utils';
import { Status, UtilsHelper } from '@mdib/utils';
import { XclShoppingBasketOrderGenericGoal } from '../interface/xcl-shopping-basket-order-generic-goal';
import { XclSignatureModes } from '@mdib-xcl/core';

@Injectable()
export class ShoppingBasketOperationMapperXclService {

	/**
	 *  Feeds the functional shopping basket operation model object from the data provided by the technical model passed in argument,
	 * @param {MonogoalOrderXCLModel<any>} technicalModel : technical model of shopping basket operation resumed
	 * @returns {ShoppingBasketOperation} : functional model of shopping basket operation resumed
	 */
	public xclToFunctional(technicalModel: MonogoalOrderXCLModel<XclShoppingBasketOrderGenericGoal>): ShoppingBasketOperation {
		return new ShoppingBasketOperationBuilder()
			.reference(technicalModel.reference)
			.name(technicalModel.name)
			.orderNatureCode(technicalModel.orderNature.orderNatureCode)
			.orderNatureLabel(this.deduceOrderNatureLabel(technicalModel))
			.isReadyToBeSigned(technicalModel.isReadyToBeSigned)
			.isContentLocked(technicalModel.contentLocked)
			.signatureTypesAllowed(technicalModel.signatureTypesAllowed.map(type => XclSignatureModes.fromXclType(type)))
			.status(OrderStateHelper.getStatusFromXCLOrderStateCode(technicalModel.state))
			.isAlreadySignedByUser(OrderStateHelper.getStatusFromXCLOrderStateCode(technicalModel.state) === Status.NotAuthorizedToSign)
			.ordererAccountNumber(this.getOrdererAccountNumber(technicalModel) || technicalModel.goal.paymentSettlementAccount)
			.counterpartyAccountNumber(technicalModel.goal.counterpartyAccountNumber || technicalModel.goal.receiptSettlementAccount || technicalModel.goal.beneficiaryAccountNumber)
			.amount(technicalModel.goal.operationAmount || technicalModel.goal.orderAmount || technicalModel.goal.calculatedTotalAmount)
			.currency(technicalModel.goal.operationCurrency || technicalModel.goal.orderCurrency)
			.maturityDate(XclDateFormatter.convertXCLToISODateFormat(technicalModel.goal.maturityDate) || XclDateFormatter.convertXCLToISODateFormat(technicalModel.goal.contractStartDate) ||
				XclDateFormatter.convertXCLToISODateFormat(technicalModel.goal.startDate) || XclDateFormatter.convertXCLToISODateFormat(technicalModel.goal.fileEntryDate))
			.modelIdentifier(technicalModel.goal.identifier)
			.encoderUserIdentifier(technicalModel.encoderUserIdentifier)
			.countPastSignatures(technicalModel.countPastSignatures)
			.ordererAccountAlias(technicalModel.goal.participantName)
			.counterPartyName(technicalModel.goal.participantName_2)
			.shoppingBasketOperation;
	}

	// Converts an array of technical model of shopping basket operations to an equivalent array of functional model shopping basket operations
	public getFunctionalModelArrayFromTechnicalModelArray(technicalModelArray: MonogoalOrderXCLModel<any>[]): ShoppingBasketOperation[] {
		if (UtilsHelper.nullOrUndefined(technicalModelArray)) {
			console.error('Invalid list of shopping basket operation provided by the backend: ' + JSON.stringify(technicalModelArray));
			return [];
		}
		return technicalModelArray.map((technicalModel: MonogoalOrderXCLModel<any>) => this.xclToFunctional(technicalModel));
	}

	protected deduceOrderNatureLabel(technicalModel: MonogoalOrderXCLModel<XclShoppingBasketOrderGenericGoal>): string {
		// TODO: THIS IS A TEMPORARY STUB FOR DEFINING LABELS FOR THE OPERATIONS DEPENDING ON THE ORDER NATURE. THIS SHOULD BE DONE IN AN EXTERNAL MICROSERVICE.
		switch (technicalModel.orderNature.orderNatureCode) {
			case XclOrderNature.SEPAPaymentCreation:
				return PaymentTypes.sepaCreditTransfer;
			case XclOrderNature.TermDepositCreation:
				return 'termDeposit';
			case XclOrderNature.BeneficiaryCreation:
				return 'beneficiary';
			case XclOrderNature.StandingOrderCreation:
				return PaymentTypes.europeanStandingOrder;
			case XclOrderNature.InternationalPaymentCreation:
			// since international payments and urgent payment use the same orderNatureCode, we must do a check on the goal as well.
				if (technicalModel.goal.telegiroNewStyleSwitch === 'true') {
					return 'urgentPayment';
				}
				return PaymentTypes.internationalPayment;
			case XclOrderNature.InternationalPaymentDeletion:
				return 'internationalPaymentDeletion';
			case XclOrderNature.PaymentFileProcess:
				return 'paymentFileProcess';
			case XclOrderNature.InstantPayment:
				return PaymentTypes.instantPayment;
			case XclOrderNature.SepaDirectDebitUpdation:
				return 'sepaDirectDebitUpdation';
			default:
				return PaymentTypes.internationalPayment + ' (' + technicalModel.orderNature.actionVerb + ' ' + technicalModel.orderNature.orderName + ')';
		}
	}

	private getOrdererAccountNumber(technicalModel: MonogoalOrderXCLModel<XclShoppingBasketOrderGenericGoal>): string {
		return !!technicalModel.goal.ordererAccountNumber ? technicalModel.goal.ordererAccountNumber : this.getOrdererAccountNumberOnTheBasisOfOrderCode(technicalModel);
	}

	// FIXME : This is a temporary method until the XCL returns a valid orderer account number for each order
	// Should be removed when XCLCB_IRM-968 is resolved
	private getOrdererAccountNumberOnTheBasisOfOrderCode(technicalModel: MonogoalOrderXCLModel<XclShoppingBasketOrderGenericGoal>): string {
		switch (technicalModel.orderNature.orderNatureCode) {
			case XclOrderNature.BeneficiaryCreation:
				return technicalModel.goal.beneficiaryAccountNumber;
			default:
				return null;
		}
	}
}
