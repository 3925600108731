import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mdibAcronym'
})
export class MdibAcronymPipe implements PipeTransform {

	transform(sentence: string): string {
		let words: string[];
		words = sentence.split(' ');
		words = words.map(
			word => word.charAt(0).toUpperCase()
		);
		return words.join('');
	}

}
