import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { SecretQuestionsListsService } from './secret-questions-lists.service';
import { SecretQuestionsListsServiceStub } from './secret-questions-lists.service.stub';

@Injectable()
export class SecretQuestionsListsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return SecretQuestionsListsService; }
	protected ServiceDefinition() { return SecretQuestionsListsServiceStub; }
}
