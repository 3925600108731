import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { CommonValidator, GroupFieldValidator, IbanValidator } from '@mdib/utils';

export class CashAccountForm {

	private form: FormGroup;

	constructor(private fb: FormBuilder) {
		this.create();
	}

	create() {
		this.form = this.fb.group({
			bic: [null],
			number: [null, [CommonValidator.required, GroupFieldValidator.isBeneficiaryDuplicated]],
		});
	}

	get group(): FormGroup {
		return <FormGroup> this.form;
	}

	get number(): AbstractControl {
		return this.form.get('number');
	}

	get bic(): AbstractControl {
		return this.form.get('bic');
	}
}
