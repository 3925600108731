import { DataSource } from '@angular/cdk/table';
import { Observable, of } from 'rxjs';

/** Connect function called by the table to retrieve one stream containing the data to render. */

export class ExpandedDataSource extends DataSource<any> {
	public data: any[];

	constructor(data: any[]) {
		super();
		this.data = data || [];
	}

	/** Connect function called by the table to retrieve one stream containing the data to render. */
	connect(): Observable<Element[]> {
		const rows = [];
		this.data.forEach(row => rows.push(row, {detailRow: true, row}));
		return of(rows);
	}

	disconnect() {
	}
}
