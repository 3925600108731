import { Component, Input } from '@angular/core';
import { CashAccount } from '../../model/cash-account';
import { CashAccountService } from '../../service/cash-account.service';

/**
 * Displays data from a {@link CashAccount}
 * @example
 * <mdib-cash-accounts-dropdown-item [cashAccount]="cashAccount"></mdib-cash-accounts-dropdown-item>
 */
@Component({
	selector: 'mdib-cash-accounts-dropdown-item',
	templateUrl: './cash-accounts-dropdown-item.component.html',
	styleUrls: ['./cash-accounts-dropdown-item.component.scss'],
})
export class CashAccountsDropdownItemComponent {

	@Input() cashAccount: CashAccount;
	@Input() cashAccountDisabled = false;
	@Input() isConsult = false;

	readonly NAMESPACE = 'cashAccountsModule.cashAccountsDropdownItemComponent.';

	constructor(private cashAccountService: CashAccountService) {
	}

	/**
	 * Gives the string value to render appropriate icon.
	 * @returns string: material icons value in string.
	 */
	cashAccountIcon(): string {
		return this.cashAccountService.getIconByCashAccountType(this.cashAccount.type);
	}
}
