import { Injectable } from '@angular/core';

import { MapperPartialService } from '@mdib-xcl/core';
import { formatEuropeanCommunication } from '@mdib-xcl/utils';

import { Communication, CommunicationTypes } from '@mdib/commons';

import { XclBeneficiaryModel } from '../../model/xcl-beneficiary-model';
import { CommunicationTypesMapperService } from './communication-types-mapper.service';
import { UtilsHelper } from '@mdib/utils';

@Injectable({
	providedIn: 'root',
})
export class BeneficiaryCommunicationMapperService extends MapperPartialService<Communication, XclBeneficiaryModel> {

	constructor(
		private communicationTypesMapperService: CommunicationTypesMapperService,
	) {
		super();
	}

	public fromXcl(value: XclBeneficiaryModel): Communication {
		if (!UtilsHelper.isNullOrWhiteSpace(value.communication) || !UtilsHelper.isNullOrWhiteSpace(value.communicationType)) {
			return new Communication({
				value: value.communication,
				type: this.communicationTypesMapperService.fromXcl(value.communicationType),
			});
		} else {
			return new Communication({
				value: null,
				type: CommunicationTypes.free
			});
		}
	}

	public toXcl(value: Communication, target?: XclBeneficiaryModel): Partial<XclBeneficiaryModel> {
		target = target || <any>{};
		if (value.value) {
			target.communicationType = this.communicationTypesMapperService.toXcl(value.type);
			target.communication = value.type === CommunicationTypes.european ? formatEuropeanCommunication(value.value) : value.value;
		} else {
			delete target.communicationType;
			delete target.communication;
		}
		return target;
	}
}
