import { SignatureModes } from '@mdib/signature-modes';
import { Status } from '@mdib/utils';

import { Signable } from '../interfaces/signable';
import { Signature } from '../interfaces/signature';

export class SignableOperation<T> implements Signable {

	model: T;

	reference: string;

	signatureTypesAllowed: SignatureModes[] = [];
	signature: Signature = null;
	signatureContext: any = null;

	status: Status;

	constructor(model: T) {
		this.model = model;
	}

	public isSignatureTypeAllowed(signatureType: any): boolean {
		return (!this.signatureTypesAllowed ? false : this.signatureTypesAllowed.indexOf(signatureType) >= 0);
	}

	public isSigned(): boolean {
		return !!this.signature;
	}

	public addSignature(signature: Signature): void {
		this.signature = signature;
	}

	public getSignatureContext(): any {
		return this.signatureContext;
	}
}
