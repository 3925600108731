import { AccountStatement, AccountStatementStatus } from '@mdib/account-statements';
import { FileStatus, MdibFile, FileFormat } from '@mdib/utils';
import * as jsPDF from 'jspdf';

export class MockAccountStatements {

	static blobType = 'blob';

	static getMockAccountStatements(): AccountStatement[] {
		return [
			<AccountStatement>{
				identifier: '0001',
				accountNumber: 'BE56305177635588',
				sequenceNumber: '0000',
				statementNumber: 3,
				statementNumberYear: 2018,
				status: AccountStatementStatus.New,
				accountingStartDate: new Date(2018, 2, 23),
				file: <MdibFile> {
					id: '1091',
					status: FileStatus.Unavailable,
					name: 'accountStatement_2018_0000_3.pdf',
					format: FileFormat.pdf,
					document: null,
				},
			},
			<AccountStatement>{
				identifier: '0002',
				accountNumber: 'BE56305177635588',
				sequenceNumber: '0000',
				statementNumber: 2,
				statementNumberYear: 2018,
				status: AccountStatementStatus.New,
				accountingStartDate: new Date(2018, 2, 22),
				file: <MdibFile> {
					id: '1089',
					status: FileStatus.Available,
					name: 'accountStatement_2018_0000_2.pdf',
					format: FileFormat.pdf,
					document: this.getPDF().output(this.blobType),
				},
			},
			<AccountStatement>{
				identifier: '0003',
				accountNumber: 'BE56305177635588',
				sequenceNumber: '0000',
				statementNumber: 1,
				statementNumberYear: 2018,
				status: AccountStatementStatus.New,
				accountingStartDate: new Date(2018, 2, 21),
				file: <MdibFile> {
					id: '1088',
					status: FileStatus.Available,
					name: 'accountStatement_2018_0000_1.pdf',
					format: FileFormat.pdf,
					document: this.getPDF().output(this.blobType),
				},
			},
			<AccountStatement>{
				identifier: '0004',
				accountNumber: 'BE95750642304658',
				sequenceNumber: '0000',
				statementNumber: 4,
				statementNumberYear: 2017,
				status: AccountStatementStatus.New,
				accountingStartDate: new Date(2017, 4, 23),
				file: <MdibFile> {
					id: '1085',
					status: FileStatus.Available,
					name: 'accountStatement_2017_0000_4.pdf',
					format: FileFormat.pdf,
					document: this.getPDF().output(this.blobType),
				},
			},
			<AccountStatement>{
				identifier: '0005',
				accountNumber: 'BE11736988971648',
				sequenceNumber: '0001',
				statementNumber: 3,
				statementNumberYear: 2017,
				status: AccountStatementStatus.New,
				accountingStartDate: new Date(2017, 3, 23),
				file: <MdibFile> {
					id: '1072',
					status: FileStatus.Available,
					name: 'accountStatement_2017_0001_3.pdf',
					format: FileFormat.pdf,
					document: this.getPDF().output(this.blobType),
				},
			},
			<AccountStatement>{
				identifier: '0006',
				accountNumber: 'BE56305177635588',
				sequenceNumber: '0001',
				statementNumber: 2,
				statementNumberYear: 2017,
				status: AccountStatementStatus.Read,
				accountingStartDate: new Date(2017, 2, 23),
				file: <MdibFile> {
					id: '1070',
					status: FileStatus.Available,
					name: 'accountStatement_2017_0001_2.pdf',
					format: FileFormat.pdf,
					document: this.getPDF().output(this.blobType),
				},
			},
			<AccountStatement>{
				identifier: '0007',
				accountNumber: 'BE56305177635588',
				sequenceNumber: '0001',
				statementNumber: 1,
				statementNumberYear: 2017,
				status: AccountStatementStatus.Read,
				accountingStartDate: new Date(2017, 1, 23),
				file: <MdibFile> {
					id: '1059',
					status: FileStatus.Unavailable,
					name: 'accountStatement_2017_0001_1.pdf',
					format: FileFormat.pdf,
					document: null,
				},
			},
			<AccountStatement>{
				identifier: '0008',
				accountNumber: 'BE56305177635588',
				sequenceNumber: '0001',
				statementNumber: 5,
				statementNumberYear: 2016,
				status: AccountStatementStatus.Read,
				accountingStartDate: new Date(2016, 7, 23),
				file: <MdibFile> {
					id: '1058',
					status: FileStatus.Available,
					name: 'accountStatement_2016_0001_5_MT940.txt',
					format: FileFormat.mt940,
					document: this.getMT940(),
				},
			},
			<AccountStatement>{
				identifier: '0009',
				accountNumber: 'BE56305177635588',
				sequenceNumber: '0000',
				statementNumber: 4,
				statementNumberYear: 2016,
				status: AccountStatementStatus.Read,
				accountingStartDate: new Date(2016, 6, 23),
				file: <MdibFile> {
					id: '1055',
					status: FileStatus.Available,
					name: 'accountStatement_2016_0000_4_CAMT053.xml',
					format: FileFormat.camt053,
					document: this.camt053(),
				},
			},
			<AccountStatement>{
				identifier: '0010',
				accountNumber: 'BE56305177635588',
				sequenceNumber: '0000',
				statementNumber: 3,
				statementNumberYear: 2016,
				status: AccountStatementStatus.Read,
				accountingStartDate: new Date(2016, 5, 25),
				file: <MdibFile> {
					id: '1042',
					status: FileStatus.Available,
					name: 'accountStatement_2016_0000_3.pdf',
					format: FileFormat.pdf,
					document: this.getPDF().output(this.blobType),
				},
			},
			<AccountStatement>{
				identifier: '0011',
				accountNumber: 'BE56305177635588',
				sequenceNumber: '0000',
				statementNumber: 2,
				statementNumberYear: 2016,
				status: AccountStatementStatus.Read,
				accountingStartDate: new Date(2016, 4, 13),
				file: <MdibFile> {
					id: '1041',
					status: FileStatus.Available,
					name: 'accountStatement_2016_0000_2.pdf',
					format: FileFormat.pdf,
					document: this.getPDF().output(this.blobType),
				},
			},
			<AccountStatement>{
				identifier: '0012',
				accountNumber: 'BE56305177635588',
				sequenceNumber: '0000',
				statementNumber: 1,
				statementNumberYear: 2016,
				status: AccountStatementStatus.Read,
				accountingStartDate: new Date(2016, 4, 2),
				file: <MdibFile> {
					id: '1019',
					status: FileStatus.Unavailable,
					name: 'accountStatement_2016_0000_1.pdf',
					format: FileFormat.pdf,
					document: null,
				},
			},
		];
	}

	static getPDF() {
		const doc = new jsPDF('p', 'mm', 'a4');

		doc.setFontStyle('bold');
		doc.setFontSize(18);
		doc.text(20, 20, 'ACCOUNT STATEMENT MOCK PDF');

		doc.setLineWidth(0.5);
		doc.line(20, 25, 130, 25);

		doc.setFontSize(12);
		doc.setFontStyle('bold');
		doc.text(20, 40, 'Account number');

		doc.setTextColor(100);
		doc.text(80, 40, 'BE56 3051 7763 5588');

		doc.setTextColor(0);
		doc.setFontStyle('bold');
		doc.text(20, 50, 'Amount');

		doc.setTextColor(100);
		doc.text(80, 50, '38.24 €');
		return doc;
	}

	static getMT940() {
		return new Blob(['{1:F01CWCWBEBBAXXX0000000000}{2:I940BCCTIT21X014N3020}{4:\n:20:B6K28MS0D00A0001\n:25:999000793854\n:28C:1/1\n:60F:C161128EUR0,\n:61:1611281128C10,NTRFNONREF//B6K28OAHW95JVYKN\n:62F:C161128EUR10,\n-}\n'], { type: 'text/xml' });
	}

	static camt053() {
		return new Blob(['<?xml version="1.0" encoding="UTF-8"?><Document xmlns="urn:iso:std:iso:20022:tech:xsd:camt.053.001.02" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"><BkToCstmrStmt><GrpHdr><MsgId>B8I10XGCSQ43KK8P</MsgId><CreDtTm>2018-09-11T10:19:23Z</CreDtTm><MsgRcpt><Nm>M. Thomas SMOKE test 935</Nm></MsgRcpt><MsgPgntn><PgNb>00001</PgNb><LastPgInd>true</LastPgInd></MsgPgntn></GrpHdr><Stmt><Id>B8I10XGCSQ43KK8P</Id><ElctrncSeqNb>201800001</ElctrncSeqNb><LglSeqNb>201800001</LglSeqNb><CreDtTm>2018-09-11T10:19:23Z</CreDtTm><FrToDt><FrDtTm>2018-09-10T14:20:08Z</FrDtTm><ToDtTm>2018-09-10T14:20:08Z</ToDtTm></FrToDt><Acct><Id><IBAN>BE11999090304848</IBAN></Id><Ccy>EUR</Ccy><Ownr><Nm>1001 $000001I M. Thomas SMOKE test</Nm><PstlAdr><AdrLine>SMOKE test 935,935</AdrLine><AdrLine>1000 bxl</AdrLine></PstlAdr></Ownr><Svcr><FinInstnId><BIC>CWCWBEBBXXX</BIC></FinInstnId></Svcr></Acct><Bal><Tp><CdOrPrtry><Cd>OPBD</Cd></CdOrPrtry></Tp><Amt Ccy="EUR">85</Amt><CdtDbtInd>CRDT</CdtDbtInd><Dt><Dt>2018-09-10</Dt></Dt></Bal></Stmt></BkToCstmrStmt></Document>'], { type: 'text/xml' });
	}
}
