import { Injectable } from '@angular/core';
import { AccountStatementsPageFilter } from '../model/account-statements-page-filter';
import { AccountStatement } from '../model/account-statement';
import { MdibFile, ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';

/**
 * Abstracts the xcl calls related to {@link AccountStatement}
 */
@Injectable()
export abstract class AccountStatementsService {

	/**
	 * Retrieves the list of account statements related to the current principal
	 * @param {number} [offset] Offset to start from (by default 0, i.e. from the beginning)
	 * @param {number} [limit] Maximum number of operations to retrieve (by default 0, i.e. no limit)
	 * @param {AccountStatementsFilter} [filter] Object containing properties on which the result must be filtered
	 * @returns {Observable<ServiceResponse<AccountStatement[]>>} List of account statements related to principal
	 * @memberof AccountStatementsService
	 */
	public abstract list(index?: number, count?: number, filter?: AccountStatementsPageFilter): Observable<ServiceResponse<AccountStatement[]>>;

	/**
	 * Counts the list of account statements from XCL related to the current principal
	 * @param {AccountStatementsFilter} [filter] Object containing properties on which the result must be filtered
	 * @returns {Observable<ServiceResponse<number>>} The number of account statements related to principal
	 * @memberof AccountStatementsService
	 */
	public abstract count(filter?: AccountStatementsPageFilter): Observable<ServiceResponse<number>>;

	/**
	 * Retrieve a {AccountStatement} based on its unique identifier
	 *
	 * @param {string} file identifier
	 * @returns {Observable<ServiceResponse<MdibFile>>}
	 */
	public abstract get(name: string): Observable<ServiceResponse<MdibFile>>;

	/**
	 * This method allows to put a account statement to status 9000(printed)
	 *
	 * @param {AccountStatement}
	 * @returns {Observable<ServiceResponse<AccountStatement>>}
	 */
	public abstract update(accountStatement: AccountStatement): Observable<ServiceResponse<AccountStatement>>;

	public abstract initializeFilter(): AccountStatementsPageFilter;

	public abstract getRepresentedClientNumber(): Observable<ServiceResponse<string>>;

}
