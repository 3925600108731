export const translations = {
	'fields': {
		'accountNumber': 'Rekeningnummer',
		'address': {
			'streetName': 'Straat',
			'streetNumber': 'Huisnummer',
			'zipCode': 'Postcode',
			'city': 'Stad',
			'country': 'Land',
			'street': 'Straat en huisnummer',
			'cityAndZip': 'Postcode en woonplaats'
		},
		'alias': 'Alias',
		'bic': 'BIC',
		'fullName': 'Naam'
	},
	'placeHolders': {
		'accountNumber': 'Voer hier het rekeningnummer in',
		'address': {
			'streetName': 'Voer hier de straatnaam in',
			'streetNumber': 'Voer hier het huisnummer in',
			'zipCode': 'Voer hier de postcode in',
			'city': 'Voer hier de stad in',
			'country': 'Selecteer een land',
			'street': 'Voer hier straat en huisnummer in',
			'cityAndZip': 'Voer hier postcode en woonplaats in'
		},
		'alias': 'Voer hier een alias in',
		'bic': 'Voer hier het de BIC in',
		'fullName': 'Voer hier de naam in'
	},
	'columns': {
		'accountNumber': 'Rekeningnummer',
		'address': 'Adres',
		'beneficiary': 'Begunstigde',
		'bic': 'BIC',
		'communication': 'Mededeling',
		'country': 'Land',
		'fullName': 'Naam'
	},
	'titles': {
		'address': 'Adres',
		'informations': 'Informatie'
	},
	'beneficiarySearchModalComponent': {
		'pageTitle': 'Begunstigde zoeken'
	},
	'hints': {
		'street': 'Formaat: Straat, Nummer',
		'cityAndZip': 'Formaat: Postcode, Plaats'
	}
};
