import { Component } from '@angular/core';

import { TimeoutComponent } from '../../shared/timeout.component';

@Component({
	selector: 'authentication-timeout',
	templateUrl: './authentication-timeout.component.html',
	styleUrls: ['./authentication-timeout.component.scss']
})
export class AuthenticationTimeoutComponent extends TimeoutComponent {

	readonly NAMESPACE = 'sessionsModule.authenticationTimeoutComponent.';

}
