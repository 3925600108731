import { NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { AccountValuationsService } from '@mdib/account-valuations';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';

import { AccountValuationsMemoryService } from './service/account-valuations-memory.service';

@NgModule()
export class AccountValuationsMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(AccountValuationsService, adapterId, AccountValuationsMemoryService);
	}
}
