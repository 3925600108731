import { ConfigurationStore } from '@mdib/utils';

export function AppLoaderFactory(configStore: ConfigurationStore) {
	return () => {
		return new Promise((resolve, reject) => {
			configStore.load().subscribe(() => {
				resolve();
			});
		});
	};
}
