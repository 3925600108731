import { isNullOrUndefined } from 'util';

export class EnumHelper {

	/**
	 * Give an array of string values from Typescript number Enum.
	 * @param enumeration : to transform, typed with any cause no common ancestor.
	 * @returns Array<string> : only string values or null for undefined, null or any primitive types.
	 */
	static numbersEnumToStringArray(enumeration: any): Array<string> | null {
		return !isNullOrUndefined(enumeration) && typeof enumeration === 'object' ? Object.keys(enumeration).map(key => {
			return enumeration[key];
		}).filter(key => typeof key === 'string') : null;
	}

	/**
	 * Give an array of string values from Typescript string Enum.
	 * @param enumeration : to transform, typed with any cause no common ancestor.
	 * @returns Array<string> : only string values, lable and unknown excluded, or null for undefined, null or any primitive types.
	 */
	static stringEnumToStringArray(enumeration: any): Array<string> | null {
		return !isNullOrUndefined(enumeration) && typeof enumeration === 'object' ?
			Object.values(enumeration).map(v => v.toString()).filter(value => value.toLowerCase() !== 'unknown' && value.indexOf('.') < 0) : null;
	}
}
