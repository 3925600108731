import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Address } from '@mdib/commons';

export class AddressForm {

	private form: FormGroup;

	constructor(
		private fb: FormBuilder,
		private readonly subscribe: boolean = true,
		private readonly address?: Address,
	) {
		this.address = address || new Address();
		this.create();
		if (subscribe) { this.subscriptions(); }
	}

	create() {
		this.form = this.fb.group({
			'addressLine1': [this.address && this.address.addressLine1],
			'addressLine2': [this.address && this.address.addressLine2],
			'country': [this.address && this.address.country],
		});
	}

	private subscriptions() {
		this.addressLine1.valueChanges.subscribe(addressLine1 => this.address.addressLine1 = addressLine1);
		this.addressLine2.valueChanges.subscribe(addressLine2 => this.address.addressLine2 = addressLine2);
		this.country.valueChanges.subscribe(country => this.address.country = country);
	}

	get group(): FormGroup {
		return this.form;
	}

	get model(): Address {
		return this.address;
	}

	get addressLine1(): AbstractControl {
		return this.form.get('addressLine1');
	}

	get addressLine2(): AbstractControl {
		return this.form.get('addressLine2');
	}

	get country(): AbstractControl {
		return this.form.get('country');
	}
}
