import { Injectable } from '@angular/core';

import { fromEvent, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class CordovaService {

	public deviceReady: Observable<boolean>;

	public onPause: Observable<Event>;
	public onResume: Observable<Event>;

	constructor() {
		let isCordovaReady = false;
		this.deviceReady = !window['cordova'] ? of(false) : new Observable(ob => {
			if (isCordovaReady) {
				ob.next(true);
			} else {
				fromEvent(document, 'deviceready').pipe(
					map(() => (isCordovaReady = true)),
					tap(() => this.initEvents())
				).subscribe(ob);
			}
		});
	}

	private initEvents(): void {
		this.onPause = fromEvent(document, 'pause');
		this.onResume = fromEvent(document, 'resume');
	}
}
