/**
 * Holds data about a payment file failure
 */
export class PaymentFileFailure {
	identifier?: string;
	sequenceNumber: string;
	operationSequenceNumber: string;
	batchReference: string;
	wording: string;
	fieldValue: string;
}
