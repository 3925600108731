import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { PersonsService } from './persons.service';
import { ArtificialPerson } from '../../model/artificial-person';
import { NaturalPerson } from '../../model/natural-person';
import { PersonMandate } from '../../model/person-mandate';
import { ServiceResponse } from '../../../utils/index';
import { Observable } from 'rxjs';

@Injectable()
export class PersonsServiceStub extends PersonsService {

	get(): Observable<ServiceResponse<NaturalPerson | ArtificialPerson>> {
		return of();
	}

	getMandates(naturalPersonClientSwitch?: boolean, mandateAuthorityType?: number): Observable<ServiceResponse<PersonMandate[]>> {
		return of();
	}

	getRepresentedClientNumber(): Observable<string> {
		return of();
	}

}
