import { Injectable } from '@angular/core';
import { CordovaPluginDevice } from '@mdib/cordova/types/cordova-plugin-device';

@Injectable({
	providedIn: 'root'
})
export class CordovaPluginDeviceService {

	public get cordova(): string {
		return this.getPluginProperty('cordova', '');
	}

	public get model(): string {
		return this.getPluginProperty('model', navigator.userAgent);
	}

	public get platform(): string {
		return this.getPluginProperty('platform', navigator.platform);
	}

	public get uuid(): string {
		return this.getPluginProperty('uuid', null);
	}

	public get version(): string {
		return this.getPluginProperty('version', navigator.appVersion);
	}

	public get manufacturer(): string {
		return this.getPluginProperty('manufacturer', navigator.userAgent);
	}

	public get isVirtual(): boolean {
		return this.getPluginProperty('isVirtual', false);
	}

	public get serial(): string {
		return this.getPluginProperty('serial', null);
	}

	protected getPluginProperty(property: string, fallback?: any): any {
		const plugin: CordovaPluginDevice = window['device'];
		if (!plugin) {
			return fallback;
		}
		return plugin[property];
	}
}
