import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { CanDeactivate } from '@angular/router';
import { ModalDialogComponent } from '@mdib-pages/generic/component/modal-dialog/modal-dialog.component';
import { ModalDialogProperties } from '@mdib-pages/generic/model/generic-pages.typings';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { CanDeactivateComponent } from './can-deactivate.component';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {

	private message: string;
	private NAMESPACE = 'utilsModule.canDeactivateComponent.';

	constructor(private _translateService: TranslateService,
				private _dialog: MatDialog) {
		this._translateService.get(this.NAMESPACE + 'unsaved_changes').subscribe(message => this.message = message);
	}

	canDeactivate(component: CanDeactivateComponent): Observable<boolean> {
		if (component && !component.canDeactivate()) {
			return this.openDialog().afterClosed().pipe(map(result => {
				return result;
			}), first());
		}
		return of(true);
	}

	openDialog(): MatDialogRef<ModalDialogComponent, any> {
		return this._dialog.open(ModalDialogComponent, <MatDialogConfig<ModalDialogProperties>> {
			width: '500px',
			disableClose: true,
			data: <ModalDialogProperties> {
				title: this.NAMESPACE + 'unsavedChangesTitle',
				content: this.NAMESPACE + 'unsavedChangesContent',
				rightButtonLabel: 'generic:confirm',
				leftButtonLabel: 'generic:cancel',
				alignFooterButtonsRight: true,
			},
		});
	}
}
