import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UtilsModule } from '@mdib/utils';

import { CardsListComponent } from './cards-list/cards-list.component';

/**
 * Cards module configuration.
 */
@NgModule({
	imports: [
		CommonModule,
		UtilsModule,
		TranslateModule,
	],
	declarations: [
		CardsListComponent,
	],
	exports: [
		CardsListComponent,
	]
})
export class CardsModule {
}
