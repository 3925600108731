import { Component, Input } from '@angular/core';
import { CashAccountOperation } from '../../model/cash-account-operation';

/**
 * Displays the content of a {@link CashAccountOperation}.
 * @example
 * <mdib-cash-account-operation [cashAccountOperation]="cashAccountOperation"></mdib-cash-account-operation>
 */
@Component({
	selector: 'mdib-cash-account-operation',
	templateUrl: './cash-account-operation.component.html',
	styleUrls: ['./cash-account-operation.component.scss'],
})
export class CashAccountOperationComponent {

	@Input() cashAccountOperation: CashAccountOperation;
	@Input() cashAccountClientWording: string;
	@Input() index: number;

	expanded = false;

	isNegativeOperation() {
		return this.cashAccountOperation.isDebitOperation;
	}

	isExpanded(): boolean {
		return this.expanded === true;
	}

	public toggleExpanded(): void {

		if (this.expanded === true) {
			this.expanded = false;
		} else {
			this.expanded = true;
		}
	}
}
