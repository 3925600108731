import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[numberMask]',
})
export class NumberMaskDirective {

	private isNumber = /^\d*$/;

	private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Enter',
		'ArrowLeft', 'ArrowRight', 'Delete', 'Insert'];

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		if (this.specialKeys.indexOf(event.key) !== -1 || this.isNumber.test(event.key)) {
			return;
		}
		event.preventDefault();
	}
}
