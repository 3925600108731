import { NgModule } from '@angular/core';

// Dxp Adapters Modules
import { DxpHttpModule } from '@mdib-dxp/http';
import { DxpBeneficiariesModule } from '@mdib-dxp/beneficiaries';

@NgModule({
	imports: [
		DxpHttpModule,
		DxpBeneficiariesModule.forRoot(),
	]
})
export class AppAdaptersDxpImports {
}
