import { AbstractControl } from '@angular/forms';

import { TypedFormGroup } from '@mdib/core/meta';

import { CommunicationModel } from '../models/communication';

export class CommunicationFormGroup extends TypedFormGroup<CommunicationModel> {

	get id(): AbstractControl {
		return this.get('id');
	}

	get type(): AbstractControl {
		return this.get('type');
	}

	get message(): AbstractControl {
		return this.get('value');
	}

}
