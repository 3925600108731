import { CashAccountOperation, CashAccountOperationBuilder } from '@mdib/cash-accounts';
import { ContactAddressBuilder } from '@mdib/customers';
import { ContractType, SepaDirectDebitPaymentStatus } from '@mdib/sepa-direct-debits';
import * as moment from 'moment/moment';

export class CashAccountOperationMock {

	static getMockCashAccountOperations(accountNumber: string): CashAccountOperation[] {

		return new Array<CashAccountOperation>();

	}
}
