import { delay, tap } from 'rxjs/operators';
import { MockShoppingBasketOperation } from './mockdata/mock-shopping-basket-operation';
import { ServiceResponse, Status, ConfigurationService } from '@mdib/utils';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ShoppingBasketCommonService, ShoppingBasketEnvelope, ShoppingBasketOperation } from '@mdib/shopping-basket';
import { SessionUtilsService } from '@mdib/sessions';

@Injectable({
	providedIn: 'root',
})
export class ShoppingBasketMemoryService extends ShoppingBasketCommonService {

	private operations = [...MockShoppingBasketOperation.getMockShoppingBasketOperations()];

	constructor(
		sessionUtilsService: SessionUtilsService,
		configurationService: ConfigurationService,
	) {
		super(sessionUtilsService, configurationService);
	}


	public reloadOperations(offset?: number, limit?: number): void {
		this.operationsStream.next(null);
		setTimeout(() => {
			this.operationsStream.next(new ServiceResponse<ShoppingBasketOperation[]>(this.operations));
		}, 100);
	}

	public deleteOperation(operationToDelete: ShoppingBasketOperation): Observable<ServiceResponse<ShoppingBasketOperation>> {
		this.removeOperations([operationToDelete]);
		operationToDelete.status = Status.Cancelled;
		operationToDelete.isReadyToBeSigned = false;
		return of(new ServiceResponse<ShoppingBasketOperation>(operationToDelete)).pipe(delay(250));
	}

	public retrieve(reference: string): Observable<ServiceResponse<ShoppingBasketOperation>> {
		return of(new ServiceResponse<ShoppingBasketOperation>(this.operations.find((operation: ShoppingBasketOperation) => operation.reference === reference))).pipe(delay(250));
	}

	public createEnvelope(operationsToSign: ShoppingBasketOperation[]): Observable<ServiceResponse<ShoppingBasketEnvelope>> {
		const envelope: ShoppingBasketEnvelope = new ShoppingBasketEnvelope(operationsToSign);
		envelope.reference = 'MOCKREFERENCE123';
		envelope.status = Status.EnvelopeReadyToBeSigned;
		envelope.isReadyToBeSigned = true;
		return of(new ServiceResponse<ShoppingBasketEnvelope>(envelope)).pipe(delay(250));
	}

	public signEnvelope(envelope: ShoppingBasketEnvelope): Observable<ServiceResponse<ShoppingBasketEnvelope>> {
		envelope.isReadyToBeSigned = true;
		return of(new ServiceResponse<ShoppingBasketEnvelope>(envelope)).pipe(
			tap((serviceResponse: ServiceResponse<ShoppingBasketEnvelope>) => this.removeOperations(serviceResponse.getModel().operations)),
			delay(250));
	}

	private removeOperations(operations: ShoppingBasketOperation[]) {
		const isDelta = (operation1: ShoppingBasketOperation) => !operations.some((operation2: ShoppingBasketOperation) => operation1.reference === operation2.reference);
		this.operations = this.operations.filter(isDelta);
	}

}
