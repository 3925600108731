import { Injectable, NgZone } from '@angular/core';

import { ConfigurationService } from '@mdib/utils';
import { MatDialog } from '@angular/material';

import { SessionTimeoutComponent } from '../component/sessions/session-timeout/session-timeout.component';
import { TimeoutService } from './timeout.service';
import { SessionUtilsService } from './session-utils.service';

@Injectable()
export class SessionTimeoutService extends TimeoutService {
	public onPause = false;

	protected configProperties = 'application.session';
	protected dialogComponent = SessionTimeoutComponent;

	constructor(
		protected matDialog: MatDialog,
		protected configurationService: ConfigurationService,
		protected ngZone: NgZone,
		private sessionUtilsService: SessionUtilsService
	) {
		super(matDialog, configurationService, ngZone);
	}

	protected isShowWarning(): boolean {
		return this.sessionUtilsService.isActiveUserConnected() && !this.onPause;
	}
}
