import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ForgotPasswordService, ForgotPasswordModel } from '@mdib/forgot-password';
import { ServiceResponse, Status } from '@mdib/utils';
import { SignableOperation } from '@mdib/signature';
import { MockForgotPasswordModel } from '../mock/mock-forgot-password';

@Injectable({
	providedIn: 'root'
})
export class ForgotPasswordMemoryService extends ForgotPasswordService {

	public init(userId: string): Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>> {
		const forgotPasswordOperation = new SignableOperation<ForgotPasswordModel>(MockForgotPasswordModel.getMockForgotPasswordModel());
		forgotPasswordOperation.status = Status.ToConfirm;
		return of(new ServiceResponse<SignableOperation<ForgotPasswordModel>>(forgotPasswordOperation));
	}

	public confirm(forgotPasswordOperation: SignableOperation<ForgotPasswordModel>): Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>> {
		forgotPasswordOperation.status = Status.Closed;
		return of(new ServiceResponse<SignableOperation<ForgotPasswordModel>>(forgotPasswordOperation));
	}
}
