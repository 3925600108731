import { Injectable } from '@angular/core';

import { AuthorizationsService, AccessRightToken } from '@mdib/core/security';
import * as XclRolesMapping from 'assets/configuration/security/authorizations-mapping.json';
import { AccessRightTokenXclDefinition, AccessRightTokenXcl } from '@mdib-xcl/utils';

@Injectable()
export abstract class AuthenticationMemoryService {
	constructor(
		private authorizationsService: AuthorizationsService,
	) { }

	protected setAccessRights(username?: string) {
		this.authorizationsService.flushAccessRights();

		// Access rights are assigned in MockAccess based on a role defined in authorizations-mapping.json
		const accessRights: Array<string | AccessRightTokenXclDefinition> = [];
		const roleMapping: Array<string | AccessRightTokenXclDefinition> = XclRolesMapping['ADMINISTRATOR'] || [];
		roleMapping.forEach(token => {
			this.authorizationsService.addAccessRight(
				new AccessRightTokenXcl(token)
			);
		});
	}
}
