import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ManagedCurrencyCodes } from '@mdib/config';
import { SvgIconName, SvgIconPath } from '../helpers/styling.enum';

@Injectable()
export class IconService {

	/**
	 * This service is build providing all defaults icons to be supplied {@link supplySvgIcons}
	 * @param {MatIconRegistry} iconRegistry : to register icons names in the scope.
	 * @param {DomSanitizer} sanitizer : to clean and escape dangerous characters then bypass angular security on image.
	 */
	constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
		this.supplySvgIcons(SvgIconName);
	}

	/**
	 * Gives the font awesome icon name for managed currencies who have one.
	 * @param {@link ManagedCurrencyCodes} currency code to use to retrieve Font Awesome
	 * @return {string}: Font Awesome icon codes for currencies
	 */
	public getCurrencyIcon(currency: ManagedCurrencyCodes): string {
		switch (currency) {
			case ManagedCurrencyCodes.EUR :
				return 'fa-euro';
			case ManagedCurrencyCodes.USD :
				return 'fa-usd';
			case ManagedCurrencyCodes.GBP :
				return 'fa-gbp';
			default:
				return null;
		}
	}

	/**
	 * This methods sanitizes SVG and supply them in the scope to be referenced with specified names.
	 * @param {Object} iconsToLoad : list of icons names defined in enum, array or object.
	 * @use SvgIconPath : where you need to define the path to your SVG to be supplied.
	 */
	public supplySvgIcons(iconsToLoad: Object): void {
		Object.values(iconsToLoad).forEach(name => {
			this.iconRegistry.addSvgIcon(
				iconsToLoad[name],
				this.sanitizer.bypassSecurityTrustResourceUrl(SvgIconPath[iconsToLoad[name]]));
		});
	}
}
