import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfigurationService } from '@mdib/utils';
import { Router } from '@angular/router';

import { AuthenticationTimeoutComponent } from '../component/authentications/authentication-timeout/authentication-timeout.component';
import { TimeoutService } from './timeout.service';

@Injectable()
export class AuthenticationTimeoutService extends TimeoutService {

	protected configProperties = 'application.authentication';
	protected dialogComponent = AuthenticationTimeoutComponent;

	constructor(
		protected matDialog: MatDialog,
		protected configurationService: ConfigurationService,
		protected ngZone: NgZone,
		private router: Router
	) {
		super(matDialog, configurationService, ngZone);
	}

	protected isShowWarning(): boolean {
		return this.router.url === '/session';
	}

}
