import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { ShoppingBasketService } from './shopping-basket.service';
import { ShoppingBasketServiceStub } from './shopping-basket.service.stub';

@Injectable()
export class ShoppingBasketServiceProxy extends AdapterProxy {
	protected ServiceToken() { return ShoppingBasketService; }
	protected ServiceDefinition() { return ShoppingBasketServiceStub; }
}

