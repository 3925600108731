import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { SepaDirectDebitContract } from '@mdib/sepa-direct-debits';

import { BackendUpdateOperationXcl } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';

import { SepaDirectDebitContractXcl } from '../../model/sepa-direct-debit-contract-xcl';
import { SepaDirectDebitContractsMapperXclService } from '../../service/sepa-direct-debit-contracts-mapper-xcl.service';

export class SepaDirectDebitContractsUpdateXcl extends BackendUpdateOperationXcl<SepaDirectDebitContract, SepaDirectDebitContract, SepaDirectDebitContractXcl> {

	protected sepaDirectDebitContractMapperXclService: SepaDirectDebitContractsMapperXclService;

	protected xclEndpoint = 'xcl.sepaDirectDebit.contracts';

	constructor(injector: Injector) {
		super(injector);
		this.sepaDirectDebitContractMapperXclService = injector.get(SepaDirectDebitContractsMapperXclService);
	}

	protected handleResponse(order: MonogoalOrderXCLModel<SepaDirectDebitContractXcl>): Observable<SepaDirectDebitContract> {
		return super.handleResponse(order).pipe(mergeMap(() => {
			return this.sepaDirectDebitContractMapperXclService.sepaDirectDebitContractXclToSepaDirectDebitContract(order.goal);
		}));
	}

	protected getItemIdentifier(input: SepaDirectDebitContract): string {
		return input.contractReference;
	}

	protected getValidateData(input: SepaDirectDebitContract): SepaDirectDebitContractXcl {
		return this.sepaDirectDebitContractMapperXclService.functionalToXcl(input);
	}

}
