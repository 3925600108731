import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';

import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, MultigoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { CashAccount, CashAccountBuilder, CashAccountCreation, CashAccountCommonService, CashAccountTypeParameter } from '@mdib/cash-accounts';
import { HttpStatusActionModel, HttpStatusManagementService, ParameterModel } from '@mdib/http';
import { SessionUtilsService } from '@mdib/sessions';
import { CacheService, ServiceResponse, UtilsHelper } from '@mdib/utils';
import { ParameterSetsService } from '@mdib/core/customization';

import { XclCashAccountModel } from '../model/xcl-cash-account-model';
import { CashAccountCreationXclService } from './cash-account-creation-xcl.service';

@Injectable({
	providedIn: 'root',
})
export class CashAccountXclService extends CashAccountCommonService {

	public httpStatusAction: HttpStatusActionModel = new HttpStatusActionModel();

	private cashAccountTypes: CashAccountTypeParameter[];

	constructor(
		private sessionUtilsService: SessionUtilsService,
		private xclHttpService: XclHttpService,
		private httpStatusManagementService: HttpStatusManagementService,
		private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private xclCashAccountCreationService: CashAccountCreationXclService,
		private cacheService: CacheService,
		private parameterSetsService: ParameterSetsService,
	) {
		super();
		this.httpStatusAction.action = () => {
		};

		this.httpStatusAction.httpStatus = /2\d\d/;
		this.httpStatusAction.endpoint = 'accounts';

		this.httpStatusManagementService.setActionOnHttpStatus(this.httpStatusAction);
	}

	public validate(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		return this.xclCashAccountCreationService.initCashAccount().pipe(
			mergeMap((order: MonogoalOrderXCLModel<any>) =>
				this.xclCashAccountCreationService.retrieveCashAccount(order.reference),
			), mergeMap((order: MonogoalOrderXCLModel<any>) =>
				this.xclCashAccountCreationService.validateCashAccount(order.reference, cashAccountCreation),
			));
	}

	public confirm(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		return this.xclCashAccountCreationService.confirmCashAccount(cashAccountCreation);
	}

	public sign(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		return this.xclCashAccountCreationService.signCashAccount(cashAccountCreation);
	}

	public list(offset?: number, limit?: number): Observable<ServiceResponse<CashAccount[]>> {
		// Retrieve the principal Identifier
		const principalId = this.sessionUtilsService.getSessionActiveUserId();

		const params: ParameterModel[] = [
			new ParameterModel('principalIdentification', principalId),
			new ParameterModel('retrieveAvailableAccountBalance', 'true'),
			new ParameterModel('retrieveNewAccountStatementExistence', 'true'),
			new ParameterModel('retrieveClientData', 'true')
		];

		if (!UtilsHelper.nullOrUndefined(offset)) {
			params.push(new ParameterModel('start', offset.toString()));
		}
		if (!UtilsHelper.nullOrUndefined(limit)) {
			params.push(new ParameterModel('maxResults', limit.toString()));
		}

		// Extract the operations list from the root "goalList" property returned by the XCL
		// TODO: Manage the case where the goalList is null or undefined
		// Return an array of cash accounts (functional model) corresponding to
		// the array of cash accounts returned by the XCL (technical model)

		return this.loadParameters().pipe(mergeMap(() => {
			return this.cacheService.get(
				this.cacheService.generateCacheKeyFromParams('accounts', params),
				this.xclHttpService.execute('accounts', XclHttpService.GET, params)
					.pipe(
						map((xclOrder: MultigoalOrderXCLModel<XclCashAccountModel>) =>
							new ServiceResponse<CashAccount[]>(
								this.getFunctionalModelArrayFromTechnicalModelArray(xclOrder.goalList),
								this.feedbackExtractor.extract(xclOrder),
							),
						),
						catchError(error => of(error)),
					),
			);
		}));
	}
	// Placeholder for get operation
	public get(): Observable<ServiceResponse<CashAccount>> {
		return of();
	}

	/*** TECHNICAL-FUNCTIONAL MAPPING METHODS */

	/**
	 * Converts an array of technical model cash accounts to an equivalent array of functional model cash accounts
	 */
	private getFunctionalModelArrayFromTechnicalModelArray(technicalModelArray: XclCashAccountModel[]): CashAccount[] {
		if (UtilsHelper.nullOrUndefined(technicalModelArray)) {
			console.error('Invalid list of accounts provided by the backend: ' + JSON.stringify(technicalModelArray));
			return [];
		}
		technicalModelArray.sort((a, b) => a.orderNumber.localeCompare(b.orderNumber));
		return technicalModelArray.map((technicalModel: XclCashAccountModel) => this.feedFromTechnicalModel(technicalModel));
	}

	/**
	 * Feeds the functional cash account model object from the data provided by the technical model passed in argument,
	 * @returns CashAccount: himself so consecutive calls are allowed
	 *
	 * @param technicalModel
	 */
	private feedFromTechnicalModel(technicalModel: XclCashAccountModel): CashAccount {

		const cashAccountBuilder: CashAccountBuilder = new CashAccountBuilder()
			.accountNumber(technicalModel.accountNumber)
			.productCode(technicalModel.bankProduct)
			.clientWording(technicalModel.cashAccountClientWording)
			.clientFullName(technicalModel.clientShortName)
			.currentBalance(technicalModel.currentBalance)
			.availableBalance(technicalModel.availableBalance)
			.valuationCurrency(technicalModel.valuationCurrency)
			.isNewStatement(technicalModel.isExistNewAccountStatement)
			.type(this.getCashAccountTypeFromXclCode(technicalModel.bankProduct));

		return cashAccountBuilder.cashAccount;
	}

	private loadParameters(): Observable<boolean> {
		return this.parameterSetsService.get('cash-account-types').pipe(map((params: CashAccountTypeParameter[]) => {
			this.cashAccountTypes = params;
			return true;
		}));
	}

	private getCashAccountTypeFromXclCode(xclCode: string): string {
		const tuple = this.cashAccountTypes.find((parameter: CashAccountTypeParameter) => parameter.backendMapping === xclCode);
		return tuple ? tuple.key : null;
	}
}
