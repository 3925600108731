import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BackendOperationState, BackendOperationStep } from '@mdib/core/adapters';
import { SepaDirectDebitContract } from '@mdib/sepa-direct-debits';

import { BackendOperationXcl, DecisionTree, XclCallConfiguration } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';

import { SepaDirectDebitContractsMapperXclService } from '../sepa-direct-debit-contracts-mapper-xcl.service';
import { CreditorDetailsXcl } from '../../model/creditor-details-xcl';

export class SepaDirectDebitCreditorDetailsRetrieveXcl extends BackendOperationXcl<SepaDirectDebitContract, SepaDirectDebitContract, CreditorDetailsXcl> {

	sepaDirectDebitContractMapperXclService: SepaDirectDebitContractsMapperXclService;
	sepaDDContract: SepaDirectDebitContract;

	protected xclEndpoint = 'sepa-direct-debit-contracts-creditor-address';

	// State machine
	protected decision: DecisionTree = {
		'____': {
			triggers: { 'retrieve': this.retrieve },
			defaultTrigger: 'retrieve',
			expectedStates: ['9000'],
		},
	};

	constructor(injector: Injector) {
		super(injector);
		this.sepaDirectDebitContractMapperXclService = injector.get(SepaDirectDebitContractsMapperXclService);
	}

	public retrieve(trigger: string, sepaDirectDebitContract?: SepaDirectDebitContract): Observable<BackendOperationStep<SepaDirectDebitContract>> {
		this.sepaDDContract = sepaDirectDebitContract;
		return this.calls(trigger, [
			new XclCallConfiguration({ resourceId: sepaDirectDebitContract.creditor.identity, method: XclHttpService.GET, state: BackendOperationState.SUCCEEDED })
		]);
	}

	protected handleResponse(order: MonogoalOrderXCLModel<CreditorDetailsXcl>): Observable<SepaDirectDebitContract> {
		return super.handleResponse(order).pipe(map(() => {
			return this.sepaDirectDebitContractMapperXclService.xclCreditorDetailsToFunctional(this.sepaDDContract, order.goal);
		}));
	}
}
