export enum PaymentTypes {
	label = 'operation:payment:type:',
	unknown = 'unknown',
	sepaDirectDebit = 'sepaDirectDebit',
	sepaCreditTransfer = 'sepaCreditTransfer',
	europeanStandingOrder = 'europeanStandingOrder',
	internationalPayment = 'internationalPayment',
	instantPayment = 'instantPayment',
	urgentPayment = 'urgentPayment'
}

export enum OperationTypes {
	label = 'operation:types:',
	unknown = 'unknown',
	debit = 'debit',
	credit = 'credit',
}

export enum OperationStatus {
	label = 'operation:payment:status:',
	unknown = 'unknown',
	pending = 'pending',
	inShoppingBasket = 'inShoppingBasket',
	awaitingProcessing = 'awaitingProcessing',
	technicalIncident = 'technicalIncident',
	transferValidated = 'transferValidated',
	memoOrder = 'memoOrder',
	executed = 'executed',
	cancelled = 'cancelled',
	awaitingSettlement = 'awaitingSettlement',
}

export enum PendingPaymentFrequency {
	Label = 'operation:periodicities:',
	Unknown = 'unknown',
}

export enum AmountTypes {
	fixed = 'fixed',
	variable = 'variable',
}

export enum CostsTypes {
	Shared
}
