import { Injectable } from '@angular/core';
import { ForgotPasswordModel } from '@mdib/forgot-password';
import { XclForgotPasswordModel } from '../model/xcl-forgot-password-model';
import { XclSecretQuestionModel } from '@mdib-xcl/customers';
import { SecretQuestion } from '@mdib/customers';
import { SignableOperation } from '@mdib/signature';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';
import { OrderStateHelper } from '@mdib-xcl/utils';


@Injectable()
export class ForgotPasswordMapperXclService {
	/**
	 * Parse technical model in functional model
	 * @param { XclForgotPasswordModel } xclForgotPasswordOrder: source format.
	 * @returns SignableOperation<ForgotPasswordModel> : parsed functional model format.
	 */
	public toFunctionalForgotPasswordModel(xclForgotPasswordOrder: MonogoalOrderXCLModel<XclForgotPasswordModel>, functionalForgotPasswordOperation: SignableOperation<ForgotPasswordModel>): SignableOperation<ForgotPasswordModel> {
		functionalForgotPasswordOperation.model.userId = xclForgotPasswordOrder.goal.userName;
		functionalForgotPasswordOperation.model.secretQuestions = this.toFunctionalSecretQuestionModel(xclForgotPasswordOrder.goal);
		functionalForgotPasswordOperation.status = OrderStateHelper.getStatusFromXCLOrderStateCode(xclForgotPasswordOrder.state);
		functionalForgotPasswordOperation.reference = xclForgotPasswordOrder.reference;
		return functionalForgotPasswordOperation;
	}

	/**
	 * Parse functional model in technical model
	 * @param { SignableOperation<ForgotPasswordModel> } forgotPasswordOperation: source format.
	 * @returns MonogoalOrderXCLModel<XclForgotPasswordModel> : parsed technical model format.
	 */
	public toTechnicalForgotPasswordModel(forgotPasswordOperation: SignableOperation<ForgotPasswordModel>): XclForgotPasswordModel {
		return <XclForgotPasswordModel>{
			userName: forgotPasswordOperation.model.userId,
			listSecuritySecretQuestionAnswers: this.toTechnicalSecretQuestionModel(forgotPasswordOperation.model)
		};
	}

	/**
	 * Fetches functional secret question model from technical forgot password model
	 * @param { XclForgotPasswordModel } xclForgotPasswordModel: technical forgot password model instance.
	 * @returns SecretQuestion : functional secret question model
	 */
	private toFunctionalSecretQuestionModel(xclForgotPasswordModel: XclForgotPasswordModel): SecretQuestion[] {
		const functionalSecretQuestionModel: SecretQuestion[] = [];
		xclForgotPasswordModel.listSecuritySecretQuestionAnswers.forEach((xclSecurityQuestion: XclSecretQuestionModel) => {
			const secretQuestion = new SecretQuestion({
				id: xclSecurityQuestion.questionIdentifier,
				question: 'secretQuestions.questions.xcl.' + xclSecurityQuestion.questionIdentifier,
				answer: xclSecurityQuestion.answer,
			});
			functionalSecretQuestionModel.push(secretQuestion);
		});
		return functionalSecretQuestionModel;
	}

	/**
	 * Fetches technical secret question model from functional forgot password model
	 * @param { ForgotPasswordModel } forgotPasswordModel: functional forgot password model instance.
	 * @returns XclSecretQuestionModel : technical secret question model
	 */
	private toTechnicalSecretQuestionModel(forgotPasswordModel: ForgotPasswordModel): XclSecretQuestionModel[] {
		const technicalSecretQuestionModel: XclSecretQuestionModel[] = [];
		forgotPasswordModel.secretQuestions.forEach((secretQuestion: SecretQuestion) => {
			const xclSecretQuestionModel = <XclSecretQuestionModel>{
				questionIdentifier: secretQuestion.id,
				answer: secretQuestion.answer
			};
			technicalSecretQuestionModel.push(xclSecretQuestionModel);
		});
		return technicalSecretQuestionModel;
	}
}
