import { Injectable } from '@angular/core';

import { Address } from '@mdib/commons';

import { MapperPartialService } from '@mdib-xcl/core';

import { XclBeneficiaryModel } from '../../model/xcl-beneficiary-model';

@Injectable({
	providedIn: 'root'
})
export class BeneficiaryAddressMapperService extends MapperPartialService<Address, XclBeneficiaryModel> {

	public fromXcl(value: XclBeneficiaryModel): Address {
		const addressLine1 = (value.beneficiaryAddress1 || '');
		const addressLine2 = (value.beneficiaryAddress2 || '');
		return new Address({
			addressLine1: addressLine1,
			addressLine2: addressLine2,
			country: value.beneficiaryCountryCode ? value.beneficiaryCountryCode.trim() : value.beneficiaryCountryCode
		});
	}

	public toXcl(value: Address, target?: XclBeneficiaryModel): Partial<XclBeneficiaryModel> {
		target = target || <any>{};
		this.setIfDefined(target, 'beneficiaryAddress1', value.addressLine1 || undefined);
		this.setIfDefined(target, 'beneficiaryAddress2', value.addressLine2 || undefined);
		this.setIfDefined(target, 'beneficiaryCountryCode', value.country || undefined);
		console.log(value);
		return target;
	}
}
