import { MessageTypes } from '../model/mailbox.enum';
import { isNullOrUndefined } from 'util';

export class MailboxConversationSummary {
	public identifier: string;
	public type: MessageTypes;
	public correspondentName: string;
	public title: string;
	public isImportant = false;
	public isRead = false;
	public isWaitingResponse = false;
	public isAnswered = false;
	public sendingDate: Date;

	public isTimeDefined(): boolean {
		return !isNullOrUndefined(this.sendingDate)
			&& (this.sendingDate.getHours() > 0
				|| this.sendingDate.getMinutes() > 0
				|| this.sendingDate.getSeconds() > 0
				|| this.sendingDate.getMilliseconds() > 0);
	}
}
