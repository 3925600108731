import { MockUsers } from '@mdib-memory/sessions';
import { ShoppingBasketOperation, ShoppingBasketOperationBuilder } from '@mdib/shopping-basket';
import { SignatureModes } from '@mdib/signature-modes';
import { Status } from '@mdib/utils';

// Mock data for the list of shopping basket operations
export class MockShoppingBasketOperation {

	static getMockShoppingBasketOperations(): ShoppingBasketOperation[] {
		return [
			new ShoppingBasketOperationBuilder()
				.reference('B7J25CWUIK83QDEH')
				.status(Status.InShoppingBasket)
				.isAlreadySignedByUser(false)
				.name('paymentOperationOrder')
				.orderNatureCode('PGCC0101')
				.orderNatureLabel('SEPA betaling')
				.counterpartyAccountNumber('BE95750642304658')
				.ordererAccountNumber('BE74945239392707')
				.amount(0.99)
				.currency('EUR')
				.maturityDate('20171025')
				.isReadyToBeSigned(false)
				.isContentLocked(true)
				.signatureTypesAllowed([SignatureModes.PASSWORD])
				.modelIdentifier('B7J25CWUIK83QDEH')
				.encoderUserIdentifier(MockUsers.mock)
				.countPastSignatures(3)
				.shoppingBasketOperation,
			new ShoppingBasketOperationBuilder()
				.reference('B5625CWUIK8324L8')
				.status(Status.InShoppingBasket)
				.isAlreadySignedByUser(false)
				.name('instantPayment')
				.orderNatureCode('PAYC0101')
				.orderNatureLabel('instantPayment')
				.counterpartyAccountNumber('BE95750642304658')
				.ordererAccountNumber('BE56305177635588')
				.amount(10)
				.currency('EUR')
				.maturityDate('20171027')
				.isReadyToBeSigned(true)
				.isContentLocked(true)
				.signatureTypesAllowed([SignatureModes.PASSWORD])
				.modelIdentifier('B5625CWUIK8324L8')
			.counterPartyName('John Fitzgerald Kennedy')
				.encoderUserIdentifier(MockUsers.mock)
			.ordererAccountAlias('John Doe')
			.shoppingBasketOperation,
			new ShoppingBasketOperationBuilder()
				.reference('B7J27CWWFJ9F7RI8')
				.status(Status.InShoppingBasket)
				.isAlreadySignedByUser(false)
				.name('paymentOperationOrder')
				.orderNatureCode('PGCC0101')
				.orderNatureLabel('Niet-SEPA betaling')
				.ordererAccountNumber('BE56305177635588')
				.counterpartyAccountNumber('0260-0959-3')
				.amount(12.34)
				.currency('USD')
				.maturityDate('20180605')
				.isReadyToBeSigned(true)
				.isContentLocked(true)
				.signatureTypesAllowed([SignatureModes.PASSWORD])
				.modelIdentifier('B7J27CWWFJ9F7RI8')
				.ordererAccountAlias('John Doe')
				.counterPartyName('Annastasia Podosky')
				.shoppingBasketOperation,
			new ShoppingBasketOperationBuilder()
				.reference('B7K14CWKMD6RK1F9')
				.status(Status.InShoppingBasket)
				.isAlreadySignedByUser(false)
				.name('paymentOperationOrder')
				.orderNatureCode('PGCC0101')
				.orderNatureLabel('Spoedbetaling')
				.ordererAccountNumber('BE75999000036951')
				.counterpartyAccountNumber('NL01SOPR0116688253')
				.modelIdentifier('B7K14CWKMD6RK1F9')
				.amount(10)
				.currency('EUR')
				.maturityDate('20170124')
				.isReadyToBeSigned(true)
				.isContentLocked(true)
				.signatureTypesAllowed([
					SignatureModes.PASSWORD,
					SignatureModes.OTP,
					SignatureModes.UCR,
					SignatureModes.DIGIPASS,
				])
				.ordererAccountAlias('John Doe')
				.counterPartyName('John Fitzgerald Kennedy')
				.shoppingBasketOperation,
			new ShoppingBasketOperationBuilder()
			.reference('B7K27CWSIO4FPH3Q')
				.status(Status.ToSign)
				.isAlreadySignedByUser(false)
				.name('paymentOperationOrder')
				.orderNatureCode('PGCC0101')
				.orderNatureLabel('Periodieke opdracht')
				.ordererAccountNumber('BE56305177635588')
				.counterpartyAccountNumber('BE75999000036951')
				.amount(0.05)
				.currency('EUR')
				.maturityDate('20171127')
				.isReadyToBeSigned(false)
				.isContentLocked(true)
				.modelIdentifier('B7K27CWSIO4FPH3Q')
				.signatureTypesAllowed([SignatureModes.PASSWORD])
				.ordererAccountAlias('John Doe')
				.encoderUserIdentifier(MockUsers.mock)
				.counterPartyName('John Fitzgerald Kennedy')
				.encoderUserIdentifier(MockUsers.mock)
				.countPastSignatures(2)
				.shoppingBasketOperation,
			new ShoppingBasketOperationBuilder()
				.reference('B4K48CWZZP0J9K5O')
				.status(Status.InShoppingBasket)
				.isAlreadySignedByUser(false)
				.name('paymentStandingOrder')
				.orderNatureCode('SRCC0101')
				.orderNatureLabel('europeanStandingOrder')
				.amount(55.99)
				.currency('EUR')
				.maturityDate('20180902')
				.modelIdentifier('B4K48CWZZP0J9K5O')
				.counterpartyAccountNumber('BE11736988971648')
				.ordererAccountNumber('BE56305177635588')
				.isReadyToBeSigned(true)
				.isContentLocked(true)
				.signatureTypesAllowed([
					SignatureModes.PASSWORD,
				])
				.encoderUserIdentifier(MockUsers.mockb2b)
				.shoppingBasketOperation,
			new ShoppingBasketOperationBuilder()
				.reference('B4K48CWZZP0J9555')
				.status(Status.InShoppingBasket)
				.isAlreadySignedByUser(false)
				.name('paymentStandingOrder')
				.orderNatureCode('SRCC0101')
				.orderNatureLabel('europeanStandingOrder')
				.currency('EUR')
				.maturityDate('20181023')
				.modelIdentifier('B4K48CWZZP0J9555')
				.counterpartyAccountNumber('BE12123412341234')
				.ordererAccountNumber('BE56305177635588')
				.isReadyToBeSigned(true)
				.isContentLocked(true)
				.signatureTypesAllowed([
					SignatureModes.PASSWORD,
				])
				.encoderUserIdentifier(MockUsers.mockb2b)
				.shoppingBasketOperation,
			new ShoppingBasketOperationBuilder()
				.reference('B4K48CWZZP0J9687')
				.status(Status.InShoppingBasket)
				.isAlreadySignedByUser(false)
				.name('paymentStandingOrder')
				.amount(50)
				.orderNatureCode('SRCC0101')
				.orderNatureLabel('europeanStandingOrder')
				.currency('EUR')
				.maturityDate('20180923')
				.modelIdentifier('B4K48CWZZP0J9687')
				.counterpartyAccountNumber('BE11736988971648')
				.ordererAccountNumber('BE56305177635588')
				.isReadyToBeSigned(true)
				.isContentLocked(true)
				.signatureTypesAllowed([
					SignatureModes.PASSWORD,
				])
				.encoderUserIdentifier(MockUsers.mock)
				.shoppingBasketOperation,
			new ShoppingBasketOperationBuilder()
				.reference('P9D64KSFGZ4D8C6M')
				.status(Status.InShoppingBasket)
				.isAlreadySignedByUser(false)
				.name('paymentInternationalOrder')
				.orderNatureCode('RECC0101')
				.orderNatureLabel('internationalPayment')
				.modelIdentifier('P9D64KSFGZ4D8C6M')
				.ordererAccountNumber('BE64999010018352')
				.counterpartyAccountNumber('US593331234456898956')
				.maturityDate('20180822')
				.amount(209.77)
				.currency('USD')
				.isReadyToBeSigned(true)
				.isContentLocked(true)
				.signatureTypesAllowed([
					SignatureModes.PASSWORD,
				])
				.encoderUserIdentifier(MockUsers.mockb2b)
				.shoppingBasketOperation,
			new ShoppingBasketOperationBuilder()
				.reference('98D64KSFGZ4D8C6M')
				.status(Status.InShoppingBasket)
				.isAlreadySignedByUser(false)
				.name('paymentFileProcessOrder')
				.orderNatureCode('EXCC0301')
				.orderNatureLabel('paymentFileProcess')
				.modelIdentifier('000000011')
				.maturityDate('20180602')
				.amount(44597.02)
				.currency('EUR')
				.isReadyToBeSigned(true)
				.isContentLocked(true)
				.signatureTypesAllowed([
					SignatureModes.PASSWORD,
				])
				.encoderUserIdentifier(MockUsers.mock)
				.shoppingBasketOperation,
		];
	}

}
