import { Observable, zip, Subject, merge, interval } from 'rxjs';
import { map, filter, mergeMap } from 'rxjs/operators';

import { ServiceResponse, Feedback, ConfigurationService, UtilsHelper } from '@mdib/utils';

import { ShoppingBasketService } from './shopping-basket.service';
import { ShoppingBasketOperation } from '../model/shopping-basket-operation';
import { SessionUtilsService } from '@mdib/sessions';

export abstract class ShoppingBasketCommonService extends ShoppingBasketService {

	/** A stream (a type of subject) that will emit an operation every time there is a new version of this */
	protected operationsStream: Subject<ServiceResponse<ShoppingBasketOperation[]>> = new Subject();

	protected unsignedOperationsCounterUpdateInterval = 30000;
	private roles;

	constructor(
		private sessionUtilsService: SessionUtilsService,
		configurationService: ConfigurationService,
	) {
		super();
		this.roles = configurationService.instant('user.roles');
		const counterUpdateInterval = configurationService.instant('application.notifications.shopping_basket_operations_update.interval');
		if (counterUpdateInterval > this.unsignedOperationsCounterUpdateInterval) {
			this.unsignedOperationsCounterUpdateInterval = counterUpdateInterval;
		}
	}

	public getOperationsToSign(offset?: number, limit?: number): Observable<ServiceResponse<ShoppingBasketOperation[]>> {
		return merge(this.operationsStream.asObservable(), new Observable<null>(subscriber => {
			this.reloadOperations(offset, limit);
			subscriber.complete();
		}));
	}

	public deleteOperations(operationsToDelete: ShoppingBasketOperation[]): Observable<ServiceResponse<null>> {
		// Delete each operation individually
		const results: Observable<Feedback[]>[] = [];
		operationsToDelete.forEach(operation => results.push(this.deleteOperation(operation).pipe(map(
			(response: ServiceResponse<any>) => response.getFeedbacks()
		))));

		// Success with all the feedbacks
		return zip(results).pipe(map(feedbacksList => [].concat.apply([], feedbacksList)));
	}

	public unsignedOperationsCounter(): Observable<ServiceResponse<number>> {
		const activeUserBusinessRole = this.sessionUtilsService.getSessionActiveBusinessRole();
		if (this.roles.b2b && activeUserBusinessRole === this.roles.b2b) {
			return merge(this.countUnsignedOperations(), interval(this.unsignedOperationsCounterUpdateInterval)).pipe(mergeMap(() => this.countUnsignedOperations()));
		} else {
			return this.countUnsignedOperations();
		}
	}

	private countUnsignedOperations(): Observable<ServiceResponse<number>> {
		const currentUser = this.sessionUtilsService.getSessionActiveUserId();
		return this.getOperationsToSign().pipe(
			filter((response) => !UtilsHelper.nullOrUndefined(response)),
			map((response: ServiceResponse<ShoppingBasketOperation[]>) => response.getModel()),
			map((operations: ShoppingBasketOperation[]) => operations.filter(operation => {
				if (operation.encoderUserIdentifier === currentUser) {
					return operation;
				} else if (operation.countPastSignatures > 0) {
					return operation;
				}
			})),
			map((operations: ShoppingBasketOperation[]) => new ServiceResponse<number>(operations.length))
		);
	}
}
