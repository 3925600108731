import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SecurityGuardService } from '@mdib/core/security';
import { AsideComponent } from '@mdib/aside/component/aside/aside.component';
import { FooterComponent } from '@mdib/footer/component/footer/footer.component';
import { HeaderComponent } from '@mdib/header/component/header/header.component';
import { SessionGuardService } from '@mdib/sessions';

import { PaymentsConsultationPageComponent } from './component/payments-consultation-page/payments-consultation-page.component';
import { CreatePaymentPageComponent } from './component/create-payment-page/create-payment-page.component';
import { PendingPaymentsPageComponent } from './component/pending-payments-page/pending-payments-page.component';

const routes: Routes = [
	{ path: '', outlet: 'header', component: HeaderComponent },
	{ path: '', outlet: 'footer', component: FooterComponent },
	{ path: '', outlet: 'aside', component: AsideComponent },
	// Create
	{
		path: 'create',
		pathMatch: 'full',
		component: CreatePaymentPageComponent,
		canActivate: [SessionGuardService, SecurityGuardService],
		data: { authorizedTokens: ['payments.create'] }
	},
	// Duplicate
	{
		path: 'create/:reference',
		pathMatch: 'full',
		component: CreatePaymentPageComponent,
		canActivate: [SessionGuardService, SecurityGuardService],
		data: { authorizedTokens: ['payments.create'] }
	},
	// Retrieve
	{
		path: 'retrieve/:operationType/:reference/:paymentType',
		pathMatch: 'full',
		component: PaymentsConsultationPageComponent,
		canActivate: [SessionGuardService, SecurityGuardService],
		data: { authorizedTokens: ['payments.consult'] }
	},
	// Delete
	{
		path: 'delete/:operationType/:reference/:paymentType',
		pathMatch: 'full',
		component: PaymentsConsultationPageComponent,
		canActivate: [SessionGuardService, SecurityGuardService],
		data: { authorizedTokens: ['payments.delete'] }
	},
	// Search
	{
		path: 'search/pending',
		pathMatch: 'full',
		component: PendingPaymentsPageComponent,
		canActivate: [SessionGuardService, SecurityGuardService],
		data: { authorizedTokens: ['payments.search.pending'] }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class PaymentsRoutingModule {
}
