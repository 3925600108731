import { Injectable } from '@angular/core';

import { NavigationFilter, NavigationItem } from '@mdib/core/navigation';
import { SessionsService } from '@mdib/sessions';
import { UtilsHelper } from '@mdib/utils';

@Injectable()
export class NavigationFilterBusinessRoleService implements NavigationFilter {

	constructor(
		private sessionService: SessionsService,
	) { }

	public filterItem(item: NavigationItem): boolean {
		// Business Role
		const session = this.sessionService.getSession();
		let role;
		if (session && session.activeUser && session.activeUser.businessRole) {
			role = session.activeUser.businessRole;
		}

		return !item['businessRole']
			|| UtilsHelper.objectsEqual(item['businessRole'], role)
			|| (item['businessRole'].indexOf && item['businessRole'].indexOf(role) >= 0);
	}
}
