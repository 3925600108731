import { Periodicities } from '@mdib/utils';
import { Moment } from 'moment';
import { AmountTypes } from './payments.enum';

export class StandingOrderDetails {
	private _startDate: Moment;
	private _frequency: string;
	private _firstPaymentDay: number;
	private _secondPaymentDay: number;
	private _thirdPaymentDay: number;

	private _endDate: Moment;
	private _iteration: number;
	private _amountType: AmountTypes;
	private _minBalance: number;
	private _minAmount: number;
	private _maxAmount: number;

	private _totalAmount: number;

	private _monthPayment: string;
	private _weekDayPayment: string;

	private _communication: string;

	constructor(execution?: Partial<StandingOrderDetails>) {
		Object.assign(this, execution);
	}

	get monthPayment(): string {
		return this._monthPayment;
	}

	set monthPayment(paymentMonthNumber: string) {
		this._monthPayment = paymentMonthNumber;
	}

	get weekDayPayment(): string {
		return this._weekDayPayment;
	}

	set weekDayPayment(paymentDay: string) {
		this._weekDayPayment = paymentDay;
	}

	get minBalance(): number {
		return this._minBalance;
	}

	set minBalance(minBalance: number) {
		this._minBalance = minBalance;
	}

	get minAmount(): number {
		return this._minAmount;
	}

	set minAmount(minAmount: number) {
		this._minAmount = minAmount;
	}

	get maxAmount(): number {
		return this._maxAmount;
	}

	set maxAmount(maxAmount: number) {
		this._maxAmount = maxAmount;
	}

	get startDate(): Moment {
		return this._startDate;
	}

	set startDate(startDate: Moment) {
		this._startDate = startDate;
	}

	get endDate(): Moment {
		return this._endDate;
	}

	set endDate(endDate: Moment) {
		this._endDate = endDate;
	}

	get amountType(): AmountTypes {
		return this._amountType;
	}

	set amountType(amountType: AmountTypes) {
		this._amountType = amountType;
	}

	get frequency(): Periodicities | string {
		return this._frequency;
	}

	set frequency(frequency: Periodicities | string) {
		this._frequency = frequency;
	}

	get firstPaymentDay(): number {
		return this._firstPaymentDay;
	}

	set firstPaymentDay(day: number) {
		this._firstPaymentDay = day;
	}

	get secondPaymentDay(): number {
		return this._secondPaymentDay;
	}

	set secondPaymentDay(day: number) {
		this._secondPaymentDay = day;
	}

	get thirdPaymentDay(): number {
		return this._thirdPaymentDay;
	}

	set thirdPaymentDay(day: number) {
		this._thirdPaymentDay = day;
	}

	get totalAmount(): number {
		return this._totalAmount;
	}

	set totalAmount(totalAmount: number) {
		this._totalAmount = totalAmount;
	}

	get iteration(): number {
		return this._iteration;
	}

	set iteration(iteration: number) {
		this._iteration = iteration;
	}

	get communication(): string {
		return this._communication;
	}

	set communication(communication: string) {
		this._communication = communication;
	}
}
