import { Signable, Signature } from '@mdib/signature';
import { Observable, Subscribable, Subject, PartialObserver, Subscription } from 'rxjs';

import { BackendOperationState } from './backend-operation-state.enum';
import { BackendOperationType } from './backend-operation-type.enum';
import { BackendOperationStep } from './backend-operation-step';

type ObserverOrNext<T> = PartialObserver<T> | ((value: T) => void);

export abstract class BackendOperation<IN, OUT> implements Signable, Subscribable<BackendOperationStep<OUT>> {

	public state: BackendOperationState = BackendOperationState.WAITING_ACTION;
	public signatureContext: any;
	public allowedSignatures: Array<any> = [];
	public curentStep: BackendOperationStep<OUT> = new BackendOperationStep(null, []);

	protected signatures: Array<Signature> = [];

	protected stepSubject: Subject<BackendOperationStep<OUT>> = new Subject();

	constructor(
		public type: BackendOperationType
	) { }

	public isSignatureTypeAllowed(signatureType: any): boolean {
		return this.allowedSignatures.indexOf(signatureType) !== -1;
	}

	public isSigned(): boolean {
		return this.signatures.length > 0;
	}

	public getSignatureContext(): any {
		return this.signatureContext;
	}

	public addSignature(signature: Signature): void {
		this.signatures.push(signature);
	}

	public subscribe(observer?: PartialObserver<BackendOperationStep<OUT>>): Subscription;
	public subscribe(next?: (value: BackendOperationStep<OUT>) => void, error?: (error: any) => void, complete?: () => void): Subscription;

	public subscribe(observerOrNext?: ObserverOrNext<BackendOperationStep<OUT>>, error?: (error: any) => void, complete?: () => void): Subscription {
		if (typeof observerOrNext === 'function') {
			return this.stepSubject.subscribe(observerOrNext, error, complete);
		} else {
			return this.stepSubject.subscribe(observerOrNext);
		}
	}

	public abstract execute(input: IN, trigger?: string): Observable<BackendOperationStep<OUT>>;

}
