import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BeneficiaryV2 as Beneficiary } from '@mdib/beneficiaries';

import { BackendRetrieveOperationXcl } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';

import { XclBeneficiaryModel } from '../../model/xcl-beneficiary-model';
import { BeneficiaryMapperService } from '../mappers/beneficiary-mapper.service';

export class BeneficiaryRetrieveXclOperation extends BackendRetrieveOperationXcl<Beneficiary, XclBeneficiaryModel> {

	protected xclEndpoint = 'xcl.beneficiaries.resource';

	private beneficiaryMapperService: BeneficiaryMapperService;

	// Dependencies
	public constructor(injector: Injector) {
		super(injector);

		this.beneficiaryMapperService = injector.get(BeneficiaryMapperService);
	}

	protected getParameters(input: string): any {
		return {
			principalIdentification: this.sessionUtilsService.getSessionActiveUserId(),
		};
	}

	protected handleResponse(order: MonogoalOrderXCLModel<XclBeneficiaryModel>): Observable<Beneficiary> {
		return super.handleResponse(order).pipe(map(() => {
			return this.beneficiaryMapperService.fromXcl(order.goal);
		}));
	}
}
