import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { StylingModule } from '@mdib/styling';
import { UtilsModule } from '@mdib/utils';
import { PaymentsService } from './service/payments.service';
import { PaymentsServiceProxy } from './service/payments.service.proxy';
import { StandingOrderProxyService } from './service/standing-orders/standing-order-proxy.service';
import { StandingOrderService } from './service/standing-orders/standing-order.service';
import { PaymentUpdateService } from '@mdib/payments/service/payment-update.service';
import { InternationalPaymentsService } from './service/international-payments/international-payments.service';
import { InternationalPaymentsServiceProxy } from './service/international-payments/international-payments.service.proxy';
import { InstantPaymentsService } from './service/instant-payments/instant-payments.service';
import { InstantPaymentsServiceProxy } from './service/instant-payments/instant-payments.service.proxy';

@NgModule({
	imports: [
		CommonModule,
		UtilsModule,
		TranslateModule,
		StylingModule,
	],
	providers: [PaymentUpdateService]
})
export class PaymentsModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: PaymentsModule,
			providers: [
				{ provide: PaymentsService, useClass: PaymentsServiceProxy },
				{ provide: StandingOrderService, useClass: StandingOrderProxyService },
				{ provide: PaymentUpdateService, useClass: PaymentUpdateService },
				{ provide: InternationalPaymentsService, useClass: InternationalPaymentsServiceProxy },
				{ provide: InstantPaymentsService, useClass: InstantPaymentsServiceProxy },
			],
		};
	}
}
