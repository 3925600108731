import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { DigipassAuthenticationService } from './digipass-authentication.service';
import { DigipassAuthenticationServiceStub } from './digipass-authentication.service.stub';

@Injectable()
export class DigipassAuthenticationServiceProxy extends AdapterProxy {
	protected ServiceToken() { return DigipassAuthenticationService; }
	protected ServiceDefinition() { return DigipassAuthenticationServiceStub; }
}
