import { SearchCriteria, SearchClass } from '@mdib/core/adapters';
import { and, equals, contains } from '@mdib/core/adapters/criteria';

import { BeneficiaryType } from '../enums/beneficiary-type.enum';

export interface BeneficiariesSearchModel {
	type?: BeneficiaryType;
	fullName?: string;
	alias?: string;
	accountNumber?: string;
}

export class BeneficiariesSearch implements SearchClass, BeneficiariesSearchModel {
	public type?: BeneficiaryType;
	public fullName?: string;
	public alias?: string;
	public accountNumber?: string;

	constructor(properties: Partial<BeneficiariesSearchModel> = {}) {
		Object.assign(this, properties);
	}

	public asCriteria(): SearchCriteria<any> {
		const ands = [];
		// Type
		if (this.type) {
			ands.push(equals('type', this.type));
		}

		// Full Name
		if (this.fullName) {
			ands.push(contains('fullName', this.fullName));
		}

		// Alias
		if (this.alias) {
			ands.push(contains('alias', this.alias));
		}

		// Account Number
		if (this.accountNumber) {
			ands.push(contains('account.number', this.accountNumber));
		}

		return and(...ands);
	}
}
