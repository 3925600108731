import { ContactAddress } from '@mdib/customers';
import { UtilsHelper } from '@mdib/utils';
import { CashAccountOperation } from '../model/cash-account-operation';
import { ContractType, SepaDirectDebitPaymentStatus } from '@mdib/sepa-direct-debits';

export class CashAccountOperationBuilder {
	private readonly _cashAccountOperation: CashAccountOperation;

	constructor(cashAccountOperation?: CashAccountOperation) {
		this._cashAccountOperation = UtilsHelper.objectNotEmpty(cashAccountOperation) ? cashAccountOperation : new CashAccountOperation();
	}

	/**
	 * Initiates a builder based on an empty {@link CashAccountOperation}
	 * @returns {CashAccountOperationBuilder}
	 */
	static empty(): CashAccountOperationBuilder {
		return new CashAccountOperationBuilder();
	}

	/**
	 * Initiates a builder based on an already existing {@link CashAccountOperation}.
	 * The {@link CashAccountOperation} will be cloned.
	 *
	 * @param {CashAccountOperation} cashAccountOperation
	 * @returns {CashAccountOperationBuilder}
	 */
	static from(cashAccountOperation: CashAccountOperation): CashAccountOperationBuilder {
		return new CashAccountOperationBuilder(cashAccountOperation);
	}

	public accountNumber(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.accountNumber = value;
		return this;
	}

	public date(value: Date): CashAccountOperationBuilder {
		this._cashAccountOperation.date = value;
		return this;
	}

	public valueDate(value: Date): CashAccountOperationBuilder {
		this._cashAccountOperation.valueDate = value;
		return this;
	}

	public amount(value: number): CashAccountOperationBuilder {
		this._cashAccountOperation.amount = value;
		return this;
	}

	public currency(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.currency = value;
		return this;
	}

	public isDebitOperation(value: boolean): CashAccountOperationBuilder {
		this._cashAccountOperation.isDebitOperation = value;
		return this;
	}

	public counterparty(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.counterpartyAccount = value;
		return this;
	}

	public counterpartyName(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.counterpartyName = value;
		return this;
	}

	public counterpartyAdress(value: ContactAddress): CashAccountOperationBuilder {
		this._cashAccountOperation.counterpartyAddress = value;
		return this;
	}

	public isStructuredCommunication(value: boolean): CashAccountOperationBuilder {
		this._cashAccountOperation.isStructuredCommunication = value;
		return this;
	}

	public communication(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.communication = value;
		return this;
	}

	public movementNumber(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.movementNumber = value;
		return this;
	}

	public reference(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.reference = value;
		return this;
	}

	public operationNature(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.operationNature = value;
		return this;
	}

	public frequency(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.frequency = value;
		return this;
	}

	public duration(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.duration = value;
		return this;
	}

	public startDate(value: Date): CashAccountOperationBuilder {
		this._cashAccountOperation.startDate = value;
		return this;
	}

	public maturityDate(value: Date): CashAccountOperationBuilder {
		this._cashAccountOperation.paymentDate = value;
		return this;
	}

	public interruptionPeriodFrom(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.interruptionPeriodFrom = value;
		return this;
	}

	public interruptionPeriodTo(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.interruptionPeriodTo = value;
		return this;
	}

	public isStandingOrder(value: boolean): CashAccountOperationBuilder {
		this._cashAccountOperation.isStandingOrder = value;
		return this;
	}

	public paymentScheme(value: ContractType): CashAccountOperationBuilder {
		this._cashAccountOperation.paymentScheme = value;
		return this;
	}

	public operationStatus(value: SepaDirectDebitPaymentStatus): CashAccountOperationBuilder {
		this._cashAccountOperation.operationStatus = value;
		return this;
	}

	public transactionReference(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.transactionReference = value;
		return this;
	}

	public additionalWording(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.additionalWording = value;
		return this;
	}

	public standardWording(value: string): CashAccountOperationBuilder {
		this._cashAccountOperation.standardWording = value;
		return this;
	}

	get cashAccountOperation(): CashAccountOperation {
		return this._cashAccountOperation;
	}
}
