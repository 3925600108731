import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [],
	providers: [],
})
export class DxpBeneficiariesModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: DxpBeneficiariesModule,
			providers: []
		};
	}
}
