import { Injectable } from '@angular/core';
import { ServiceResponse, UtilsHelper } from '@mdib/utils';
import { Observable } from 'rxjs';

import { CashAccount } from '../model/cash-account';
import { CashAccountOrder } from '../model/cash-account-order';
import { CashAccountSortOrder } from '../model/cash-account-sort-order';

/**
 * Abstracts the xcl calls related to {@link AccountSortOrder}
 */
@Injectable()
export abstract class CashAccountSortOrderService {
	/**
	 * This method allows to put a account statement to status 9000(printed)
	 *
	 * @param {CashAccountSortOrder}
	 * @returns {Observable<ServiceResponse<CashAccountSortOrder>>}
	 */
	public abstract update(accountSortOrder: CashAccountSortOrder): Observable<ServiceResponse<CashAccountSortOrder>>;

	/**
	 * This method maps list of cash accounts into CashAccountSortOrder
	 *
	 * @param {CashAccount[]}
	 * @returns {CashAccountSortOrder}
	 */
	public map(cashAccountList: CashAccount[]): CashAccountSortOrder {
		const accountSortOrder: CashAccountSortOrder = {
			listAccountFreeSortOrder: [],
		};
		if (!cashAccountList) {
			console.error('Invalid list of accounts returned by the front: ' + JSON.stringify(cashAccountList));
			return accountSortOrder;
		}
		cashAccountList.forEach((cashAccount: CashAccount) => {
			if (!UtilsHelper.nullOrUndefined(cashAccount)) {
				accountSortOrder.listAccountFreeSortOrder.push(new CashAccountOrder({accountNumber: cashAccount.number}));
			}
		});

		return accountSortOrder;
	}
}
