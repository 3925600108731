import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { CashAccountOperationService } from './cash-account-operation.service';
import { CashAccountOperationServiceStub } from './cash-account-operation.service.stub';

@Injectable()
export class CashAccountOperationServiceProxy extends AdapterProxy {
	protected ServiceToken() { return CashAccountOperationService; }
	protected ServiceDefinition() { return CashAccountOperationServiceStub; }
}
