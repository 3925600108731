export const properties = [
	{
		name: 'fields.fullName.maxLength',
		description: 'Maximum length of the beneficiary name',
		default: null,
	},
	{
		name: 'fields.alias.maxLength',
		description: 'Maximum length of the beneficiary alias',
		default: null,
	},
];
