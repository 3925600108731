import { NgModule, ModuleWithProviders, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NAVIGATION_MENUS, NavigationService, NAVIGATION_FILTERS } from './services/navigation.service';

interface NavigationModuleParameters {
	menusLoader?: Function;
	menusFilters?: Array<Provider>;
}

@NgModule({
	imports: [
		CommonModule
	],
	declarations: []
})
export class NavigationModule {
	public static forRoot(parameters: NavigationModuleParameters = {}): ModuleWithProviders {
		return {
			ngModule: NavigationModule,
			providers: [
				{ provide: NAVIGATION_MENUS, useFactory: parameters.menusLoader, useValue: (parameters.menusLoader ? undefined : []) },
				NavigationService,
				...(parameters.menusFilters || [{ provide: NAVIGATION_FILTERS, useValue: [] }]),
			]
		};
	}
}
