import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from '@mdib/config';
import { DataFormattingService, EmailValidator, PhoneValidator, UtilsHelper } from '@mdib/utils';
import { CommunicationHelpers } from '../helpers/communication-helpers';
import { CommunicationTypes } from '../model/commons.enum';
import { Communication } from '../model/communication';
import { CommunicationValidator } from '../validators/communication-validator';

export class CommunicationForm {

	private form: FormGroup;

	constructor(private fb: FormBuilder,
				private dataFormattingService: DataFormattingService,
				private configService: ConfigService,
				private readonly subscribe: boolean = true,
				private readonly communication?: Communication,
	) {
		this.communication = communication || this.initCommunication();
		this.create();
		if (subscribe) {this.subscriptions(); }
	}

	disable() {
		this.form.disable({emitEvent: false});
	}

	enable() {
		this.form.enable({emitEvent: false});
	}

	private create() {
		this.form = this.fb.group({
			'type': [this.communication.type],
			'value': [this.communication.value || null],
			'phone': [this.communication.notificationPhoneNumber, [PhoneValidator.validate]],
			'email': [this.communication.notificationEmailRecipient, [EmailValidator.checkFormat]],
		});
		this.updateCommunicationValidators();
	}

	private updateCommunicationValidators(): void {
		this.value.clearValidators();
		this.form.updateValueAndValidity();
		switch (this.communication.type) {
			case CommunicationTypes.european:
				this.value.setValidators([Validators.required, Validators.maxLength(this.configService.europeanCommunicationMaxLength)]);
				break;
			default:
				this.value.setValidators([Validators.maxLength(this.configService.freeCommunicationMaxLength)]);
		}
	}

	/**
	 * Create and initialize default value of the communication.
	 */
	private initCommunication(): Communication {
		const communication = new Communication();
		communication.type = CommunicationTypes.free;
		return communication;
	}

	private subscriptions() {
		this.email.valueChanges.subscribe(email => this.communication.notificationEmailRecipient = email);
		this.phone.valueChanges.subscribe(phone => this.communication.notificationPhoneNumber = phone);

		this.value.valueChanges.subscribe(value => this.communication.value = value);

		this.type.valueChanges.subscribe(type => {
			const oldCom = this.communication.type;
			this.communication.type = type;
			if (oldCom !== type) {
				this.value.reset();
				this.updateCommunicationValidators();
			}
		});
	}

	get group(): AbstractControl {
		return this.form;
	}

	get model(): Communication {
		return this.communication;
	}

	get value(): AbstractControl {
		return this.form.get('value');
	}

	get type(): AbstractControl {
		return this.form.get('type');
	}

	get phone(): AbstractControl {
		return this.form.get('phone');
	}

	get email(): AbstractControl {
		return this.form.get('email');
	}
}
