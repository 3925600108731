import { KeyingLimitTypes } from './keying-limit-type.enum';

export class KeyingLimit {
	limitType: KeyingLimitTypes;
	keyingLimitPerOperation: number;
	keyingLimitPerDay: number;
	keyingLimitPerWeek: number;
	keyingLimitForStockExchange: number;
	dailyAvailableLimit: number;
	weeklyAvailableLimit: number;
}
