import { Injectable } from '@angular/core';
import { KeyingLimit, KeyingLimitTypes } from '@mdib/keying-limits';
import { XclKeyingLimitModel } from '../model/xcl-keying-limit-model';

@Injectable()
export class KeyingLimitsMapperXclService {
	constructor() {}
	/**
	 * Parse technical model in functional model
	 * @param { XclKeyingLimitModel } xclKeyingLimitModel: source format.
	 * @returns KeyingLimit : parsed functional model format.
	 */
	public toKeyingLimt(xclKeyingLimitModel: XclKeyingLimitModel): KeyingLimit {
		const keyingLimitModel = <KeyingLimit>{
			keyingLimitPerOperation: xclKeyingLimitModel.maximumClientPaymentAmountPerOperation,
			keyingLimitPerDay: xclKeyingLimitModel.maximumClientPaymentAmountPerDay,
			keyingLimitPerWeek: xclKeyingLimitModel.maximumClientPaymentAmountPerWeek,
			keyingLimitForStockExchange: xclKeyingLimitModel.maximumClientPurchaseAmountForStockExchange,
			weeklyAvailableLimit: xclKeyingLimitModel.weeklyStillAvailableLimit,
			dailyAvailableLimit: xclKeyingLimitModel.dailyStillAvailableLimit,
			limitType: this.toFunctionalLimitType(xclKeyingLimitModel.limitPrincipalIdentificationType)
		};

		return keyingLimitModel;
	}
	/**
	 * Converts the technical model list into functional model list
	 * @param { XclKeyingLimitModel[] } xclKeyingLimitModelList : List of keying limits as returned by XCL.
	 * @returns KeyingLimit[] : functional model keying limits list.
	 */
	public toKeyingLimitsList(xclKeyingLimitModelList: XclKeyingLimitModel[]): KeyingLimit[] {
		if (!xclKeyingLimitModelList) {
			console.log('ERROR: mapping issue from XclKeyingLimitModel[] to KeyingLimit[]');
			return null;
		}

		const keyingLimitList: KeyingLimit[] = new Array<KeyingLimit>();
		for (const xclKeyingLimitModel of xclKeyingLimitModelList) {
			const accountStatement: KeyingLimit = this.toKeyingLimt(xclKeyingLimitModel);
			keyingLimitList.push(accountStatement);
		}
		return keyingLimitList;
	}
	/**
	 * Converts Xcl limit type to functional limit type
	 * @param {string} xclLimitType : limit type as returned by XCL.
	 * @returns KeyingLimitTypes : functional limit type.
	 */
	public toFunctionalLimitType(xclLimitType: string): KeyingLimitTypes {
		switch (xclLimitType) {
			case '01':
				return KeyingLimitTypes.InternetBanking;
			case '02':
				return KeyingLimitTypes.Smartphone;
			case '03':
				return KeyingLimitTypes.Tablet;
			default:
				return null;
		}
	}
}
