import { SignableOperation } from '@mdib/signature';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { PaymentFilesService } from './payment-files.service';
import { PaymentFilesFilter } from '../model/payment-files-filter';
import { PaymentFile } from '../model/payment-file';
import { PaymentFileFailure } from '../model/payment-file-failure';

export class PaymentFilesServiceStub extends PaymentFilesService {

	list(index: number, count: number, filter?: PaymentFilesFilter): Observable<ServiceResponse<PaymentFile[]>> {
		return of();
	}

	count(filter?: PaymentFilesFilter): Observable<ServiceResponse<number>> {
		return of();
	}

	confirm(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		return of();
	}

	validate(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		return of();
	}

	get(fileId: string): Observable<ServiceResponse<PaymentFile>> {
		return of();
	}

	delete(fileId: string): Observable<ServiceResponse<SignableOperation<PaymentFile>>> {
		return of();
	}

	signDelete(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		return of();
	}

	process(fileId: string): Observable<ServiceResponse<SignableOperation<PaymentFile>>> {
		return of();
	}

	confirmProcess(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		return of();
	}

	signProcess(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		return of();
	}

	listFailures(sequenceNumber: string, pageNumber?: number, pageSize?: number, ): Observable<ServiceResponse<PaymentFileFailure[]>> {
		return of();
	}
}
