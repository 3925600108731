import { PersonsService } from '@mdib/customers';
import { Observable, throwError, of } from 'rxjs';
import { tap, mergeMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { XclSignatureContext } from '@mdib-xcl/core';
import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { XclAPI } from '@mdib-xcl/utils';
import { ParameterModel } from '@mdib/http';
import { InternationalPaymentDetails, InternationalPaymentsService, PaymentOperation } from '@mdib/payments';
import { SignableOperation } from '@mdib/signature';
import { Feedback, FeedbackTypes, ServiceResponse, Status } from '@mdib/utils';
import { InternationalPaymentXcl } from '../../model/international-payment-xcl';
import { InternationalPaymentsMapperXclService } from './international-payments-mapper-xcl.service';

@Injectable({
	providedIn: 'root',
})
export class InternationalPaymentsXclService extends InternationalPaymentsService {

	readonly baseUri = 'international-payments';

	constructor(private xclHttpService: XclHttpService,
				private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
				private mapper: InternationalPaymentsMapperXclService,
				private personsService: PersonsService,
	) {
		super();
	}

	public validate(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>> {
		return this.init().pipe(
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.retrieve(order.reference)),
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.validateInternationalPayment(order.reference, payment)));
	}

	public confirm(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null> | null> {

		const params: ParameterModel[] = this.parameters(payment.reference, XclAPI.confirm);

		const xclObservable: Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toXcl(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, params, paymentXcl) as Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>>;
				}),
			);

		return this.getFunctionalObservable(xclObservable, payment);
	}

	public sign(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>> {
		const params: ParameterModel[] = this.parameters(payment.reference, XclAPI.sign);

		const xclObservable: Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toXcl(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, params, paymentXcl) as Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>>;
				}),
			);

		return this.getFunctionalObservable(xclObservable, payment).pipe(
			tap(() => {
				if (payment.status === Status.Error) {
					throw ServiceResponse.emptyWithOnefeedback(new Feedback('errorWhenSigningPayment', FeedbackTypes.FRONTEND_ERROR, 'Something went wrong when signing the international payment operation'));
				}
			}));
	}

	/**
	 * Init payment operation
	 * @return {Observable<MonogoalOrderXCLModel<any>>}
	 */
	public init(): Observable<MonogoalOrderXCLModel<any>> {
		const xclObservable: Observable<MonogoalOrderXCLModel<any>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.POST) as Observable<MonogoalOrderXCLModel<any>>;
		return xclObservable;
	}

	/**
	 * Retrieve payment operation
	 * @param {MonogoalOrderXCLModel<any>} order
	 * @returns {Observable<MonogoalOrderXCLModel<any>>}
	 */
	public retrieve(reference: string): Observable<MonogoalOrderXCLModel<any>> {
		const params: ParameterModel[] = this.parameters(reference, XclAPI.retrieve);
		const xclObservable: Observable<MonogoalOrderXCLModel<any>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, params) as Observable<MonogoalOrderXCLModel<any>>;
		return xclObservable;
	}

	/**
	 * Initialize delete for an international payment in pending payment
	 * @param {string} reference
	 * @returns {Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>>}
	 */
	public initDeleteInternationalPaymentOperation(reference: string): Observable<MonogoalOrderXCLModel<any>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('reference', reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<any>> = this.xclHttpService.execute(
			this.baseUri + '-get', XclHttpService.DELETE, parameters,
		) as Observable<MonogoalOrderXCLModel<any>>;

		return xclObservable;
	}

	public delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>>>> {
		return this.initDeleteInternationalPaymentOperation(reference).pipe(
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.retrieve(order.reference)),
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.validateDelete(order.reference)),
			mergeMap((order: ServiceResponse<PaymentOperation<InternationalPaymentDetails>>) => {
				return this.getFunctionalObservableForSignableOperation(order);
			}));
	}

	public get(reference: string): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>> {

		const paymentOperation = new PaymentOperation<InternationalPaymentDetails>();

		const parameters: ParameterModel[] = [
			new ParameterModel('reference', reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>> = this.xclHttpService.execute(
			this.baseUri + '-get', XclHttpService.GET, parameters, paymentOperation,
		) as Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>>;

		return this.getFunctionalObservable(xclObservable, paymentOperation);

	}

	public getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>> {

		let draftPaymentStream: Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>>;

		const params: ParameterModel[] = [
			new ParameterModel('orderReference', reference),
		];

		draftPaymentStream = this.xclHttpService.execute('order', XclHttpService.GET, params, null).pipe(
			map((xclOrder: MonogoalOrderXCLModel<any>) => {
				return new ServiceResponse<PaymentOperation<InternationalPaymentDetails>>(
					this.mapper.fromXcl(new PaymentOperation<InternationalPaymentDetails>(), xclOrder),
					this.feedbackExtractor.extract(xclOrder));
			}));

		return draftPaymentStream;

	}

	public validateInternationalPayment(reference: string, payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>> {

		const params: ParameterModel[] = this.parameters(reference, XclAPI.validate);

		const xclObservable: Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toXcl(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, params, paymentXcl) as Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>>;
				}),
			);

		return this.getFunctionalObservable(xclObservable, payment).pipe(
			tap(() => {
				payment.signatureContext = new XclSignatureContext({signableReference: payment.reference});
				if (payment.status === Status.NotAuthorizedToSign) {
					throwError('Payment is not authorized to sign !');
				}
			}));
	}

	public validateDelete(reference: string): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>> {
		const payment = new PaymentOperation<InternationalPaymentDetails>();
		const params: ParameterModel[] = this.parameters(reference, XclAPI.validate);

		const xclObservable: Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>> =
			this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, params) as Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>>;

		return this.getFunctionalObservable(xclObservable, payment);
	}

	public confirmDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>>>> {

		const params: ParameterModel[] = this.parameters(signablePaymentOperation.signatureContext.signableReference, XclAPI.confirm);
		const payment = new PaymentOperation<InternationalPaymentDetails>();
		const xclObservable: Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, params, null,
		) as Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>>;

		const order = this.getFunctionalObservable(xclObservable, payment);

		return order.pipe(mergeMap((internationalPayment: ServiceResponse<PaymentOperation<InternationalPaymentDetails>>) => {
			return this.getFunctionalObservableForSignableOperation(internationalPayment);
		}));
	}

	public signDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>>>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('reference', (signablePaymentOperation.signatureContext.signableReference)),
		];
		const payment = new PaymentOperation<InternationalPaymentDetails>();

		const xclObservable: Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>> = this.xclHttpService.execute(
			'international-payments-sign', XclHttpService.PUT, parameters, null,
		) as Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>>;

		const order = this.getFunctionalObservable(xclObservable, payment);

		return order.pipe(mergeMap((internationalPayment: ServiceResponse<PaymentOperation<InternationalPaymentDetails>>) => {
			return this.getFunctionalObservableForSignableOperation(internationalPayment);
		}));
	}

	/**
	 * Return a functional observable for a signable international payment operation
	 * @param {ServiceResponse<PaymentOperation<InternationalPaymentDetails>>} order
	 * @returns {Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>>>>}
	 */
	private getFunctionalObservableForSignableOperation(order: ServiceResponse<PaymentOperation<InternationalPaymentDetails>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>>>> {
		const signable: SignableOperation<PaymentOperation<InternationalPaymentDetails>> = new SignableOperation<PaymentOperation<InternationalPaymentDetails>>(order.getModel());
		signable.reference = order.getModel().reference;
		signable.signatureContext = order.getModel().signatureContext;
		signable.signatureTypesAllowed = order.getModel().signatureTypesAllowed;
		signable.status = order.getModel().status;
		return of(new ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>>>(signable));
	}

	private parameters(reference: string, phase: string): ParameterModel[] {
		return <ParameterModel[]>[
			new ParameterModel(XclAPI.rest, phase),
			new ParameterModel(XclAPI.reference, reference),
		];
	}

	private getFunctionalObservable(technicalObservable: Observable<MonogoalOrderXCLModel<InternationalPaymentXcl>>, paymentOperation: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>> {
		return technicalObservable.pipe(map((internationalPaymentXcl: MonogoalOrderXCLModel<InternationalPaymentXcl>) => {
			return new ServiceResponse(
				this.mapper.fromXcl(paymentOperation, internationalPaymentXcl),
				this.feedbackExtractor.extract(internationalPaymentXcl),
			);
		}));
	}
}
