import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';
import { ContactsService } from './contacts.service';
import { ContactsServiceStub } from './contacts.service.stub';

@Injectable()
export class ContactsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return ContactsService; }
	protected ServiceDefinition() { return ContactsServiceStub; }
}
