import { Component, Input, EventEmitter, Output, OnInit, OnChanges, Optional, Inject } from '@angular/core';

import { Feedback } from '@mdib/utils';
import { Signature } from '@mdib/signature';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';

import { BackendOperation } from '../../types/operations/backend-operation';
import { BackendOperationState } from '../../types/operations/backend-operation-state.enum';
import { BackendOperationType } from '../../types/operations/backend-operation-type.enum';
import { OperationPlaybarOptions } from '../../types/operation-playbar/operation-playbar-options';
import { OperationPlaybarButton } from '../../types/operation-playbar/operation-playbar-button';

@Component({
	selector: 'operation-playbar',
	templateUrl: './operation-playbar.component.html',
	styleUrls: ['./operation-playbar.component.scss']
})
export class OperationPlaybarComponent implements OnInit, OnChanges {

	public readonly NAMESPACE = 'OperationPlaybarComponent.';

	public BackendOperationState = BackendOperationState;

	@Input()
	public operation: BackendOperation<any, any>;

	@Input()
	public model: any;

	@Input()
	public options: OperationPlaybarOptions = {};

	@Output()
	public onEvent = new EventEmitter<Event>();

	public isSigning = false;

	public buttons: Array<OperationPlaybarButton> = [];

	private defaultButtons: { [trigger: string]: OperationPlaybarButton } = {};

	constructor(
		private notificationsMapper: ServiceResponseNotificationMessagesMapperService,
		@Optional() @Inject(OperationPlaybarButton) PLAYBAR_BUTTONS: Array<OperationPlaybarButton>,
	) {
		(PLAYBAR_BUTTONS || []).forEach(button => {
			this.defaultButtons[button.trigger] = button;
		});
	}

	public ngOnInit() {
		if (this.operation) {
			this.operation.subscribe(() => this.updateButtons());
		}
		this.updateButtons();
	}

	public ngOnChanges() {
		if (this.operation) {
			this.operation.subscribe(() => this.updateButtons());
		}
		this.updateButtons();
	}

	/*
	** Handle operation steps
	*/
	public updateButtons() {
		const triggersButtons: { [trigger: string]: OperationPlaybarButton } = {};

		// Operation triggers
		if (this.operation) {
			this.operation.curentStep.availableTriggers
				.map(trigger => {
					// Create a default button
					const buttonData: OperationPlaybarButton = {
						priority: 1,
						trigger: trigger,
						label: 'operation:' + trigger,
						type: 'primary',
						click: () => this.execute(trigger)
					};

					// Specific actions
					if (trigger === 'sign') {
						buttonData.priority = 2;
						buttonData.click = () => this.startSigning();
					}

					return buttonData;
				})
				.forEach(button =>
					triggersButtons[button.trigger] = button
				);
		}

		// Special buttons
		if (!triggersButtons['cancel']) {
			triggersButtons['back'] = {
				trigger: 'back',
				label: 'operation:goBack',
				type: 'secondary',
				enabled: true,
				click: () => window.history.back(),
			};
		}

		if (this.operation && this.operation.state === BackendOperationState.SUCCEEDED && this.operation.type === BackendOperationType.CREATE) {
			triggersButtons['restart'] = {
				trigger: 'restart',
				label: 'operation:startNew',
				type: 'primary',
				click: () => this.restart()
			};
		}

		// Apply global default options
		Object.keys(this.defaultButtons).forEach(trigger => {
			if (triggersButtons[trigger]) {
				Object.assign(triggersButtons[trigger], this.defaultButtons[trigger]);
			}
		});

		// Additional buttons
		if (this.options && this.options.buttons) {
			Object.keys(this.options.buttons).forEach(trigger => {
				if (!triggersButtons[trigger]) {
					triggersButtons[trigger] = this.options.buttons[trigger];
				} else {
					Object.assign(triggersButtons[trigger], this.options.buttons[trigger]);
				}
			});
		}

		// Filter and sort buttons to render
		this.buttons = Object.values(triggersButtons)
			.filter(button => !button.hidden)
			.sort((a, b) => {
				const aPriority = a.priority || 0;
				const bPriority = b.priority || 0;
				if (aPriority === bPriority) {
					return 0;
				}
				return (aPriority < bPriority ? -1 : 1);
			});
	}

	public execute(trigger: string) {
		this.operation.execute(this.model, trigger);
		this.onEvent.next(new Event(trigger));
	}

	public restart() {
		this.onEvent.next(new Event('reset'));
	}

	/*
	** Signature management
	*/

	public startSigning() {
		this.isSigning = true;
	}

	public handleSignature(signature: Signature) {
		this.isSigning = false;
		this.operation.addSignature(signature);
		this.execute('sign');
	}

	public handleSignatureEvent(feedback: Feedback) {
		this.isSigning = false;
		if (feedback) {
			this.notificationsMapper.sendFeedback(feedback);

		}
	}

}
