/**
 * Tuple holding an object and a numeric stepped progression
 */
export class SteppedOperation {
	currentStep: number;
	model: any;

	constructor(currentStep: number, model: any) {
		this.currentStep = currentStep;
		this.model = model;
	}

	public incrementStep(): SteppedOperation {
		this.currentStep++;
		return this;
	}

	public decrementStep(): SteppedOperation {
		if (--this.currentStep < 0) {
			this.currentStep = 0;
		}
		return this;
	}
}
