import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';
import { InternationalPaymentsService } from './international-payments.service';
import { InternationalPaymentsServiceStub } from './international-payments.service.stub';


@Injectable()
export class InternationalPaymentsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return InternationalPaymentsService; }
	protected ServiceDefinition() { return InternationalPaymentsServiceStub; }
}
