import { Injectable } from '@angular/core';
import { KeyingLimitsService, KeyingLimit } from '@mdib/keying-limits';
import { Observable } from 'rxjs';
import { ServiceResponse } from '@mdib/utils';
import { ParameterModel } from '@mdib/http';
import { SessionUtilsService } from '@mdib/sessions';
import { isNullOrUndefined } from 'util';
import { XclHttpService, MultigoalOrderXCLModel, FunctionalFeedbacksFromXclOrderExtractor, AbstractOrderXCLModel } from '@mdib-xcl/http';
import { XclKeyingLimitModel } from '../model/xcl-keying-limit-model';
import { KeyingLimitsMapperXclService } from './keying-limits-mapper-xcl.service';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class KeyingLimitsXclService extends KeyingLimitsService {
	constructor(private sessionUtilsService: SessionUtilsService,
		private _xclHttpService: XclHttpService,
		private _xclKeyingLimitsMapperService: KeyingLimitsMapperXclService,
		private _feedbacksExtractor: FunctionalFeedbacksFromXclOrderExtractor,
	) {
		super();
	}
	public list(index?: number, count?: number): Observable<ServiceResponse<KeyingLimit[]>> {
		const params: ParameterModel[] = [
			new ParameterModel('start', (index || 0).toString()),
			new ParameterModel('principalIdentification', this.sessionUtilsService.getSessionActiveUserId())
		];

		// When no maxResults parameter is provided, the CBS returns all the values
		if (!isNullOrUndefined(count)) {
			params.push(new ParameterModel('maxResults', count.toString()));
		}

		const xclObservable = this._xclHttpService.execute('keying-limits-list', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<XclKeyingLimitModel>>;
		return xclObservable.pipe(map((xclOrder: MultigoalOrderXCLModel<XclKeyingLimitModel>) =>
			new ServiceResponse<KeyingLimit[]>(
				this._xclKeyingLimitsMapperService.toKeyingLimitsList(xclOrder.goalList),
				this._feedbacksExtractor.extract(xclOrder),
				{ totalCount: xclOrder.rowCount },
			)
		));
	}

	public count(): Observable<ServiceResponse<number>> {
		const params: ParameterModel[] = [
			new ParameterModel('principalIdentification', this.sessionUtilsService.getSessionActiveUserId()),
		];
		return this._xclHttpService.execute('keying-limits-list-count', XclHttpService.GET, params)
			.pipe(map((xclOrder: AbstractOrderXCLModel) =>
				new ServiceResponse<number>(
					xclOrder.rowCount,
					this._feedbacksExtractor.extract(xclOrder),
				)
			));
	}

}
