import { ContactAddressBuilder, ContactEmail, ContactPhoneNumber } from '@mdib/customers';

export const contactsMock = [
	new ContactAddressBuilder().label('legalAddress').addressLine2('1150, Brussels').addressLine1('Avenue de Tervuren').country('BE').contactAddress,
	new ContactAddressBuilder().label('secondAddress').addressLine2('75116, Paris').addressLine1('Rue de Presbourg, 9').country('FR').contactAddress,
	new ContactEmail('j.doe@soprabanking.com', 'firstAddress'),
	new ContactEmail('john@soprabanking.com', 'secondAddress'),
	new ContactEmail('jdoe@soprabanking.com', 'thirdAddress'),
	new ContactPhoneNumber('0104567890', 'home'),
	new ContactPhoneNumber('0348458988', 'home'),
	new ContactPhoneNumber('0711266887', 'home'),
	new ContactPhoneNumber('0994589875', 'home'),
];
