import { environment } from '../../../../../environments/environment';

/**
 * Gets an environment variable by name.
 * @returns {boolean}
 */
export function getEnv(variableName: string): any {
	return environment[variableName];
}

/**
 * Get an environment variable as boolean.
 * @param {string} variableName
 * @returns {boolean} Casted variable into boolean.
 */
export function checkEnv(variableName: string): boolean {
	return !!getEnv(variableName);
}
