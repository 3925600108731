import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ElectronicalStatementsPageFilter, ElectronicalStatementsFormat } from '@mdib/electronical-statements';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';

@Component({
	selector: 'electronical-statements-page-filter',
	templateUrl: './electronical-statements-page-filter.component.html',
	styleUrls: ['./electronical-statements-page-filter.component.scss']
})

export class ElectronicalStatementsPageFilterComponent implements OnInit {

	readonly NAMESPACE = 'electronicalStatementsPagesModule.electronicalStatementsPageComponent.electronicalStatementsPageFilterComponent.';

	public ElectronicalStatementsFormat = ElectronicalStatementsFormat;
	public form: FormGroup;


	@Input() filter: ElectronicalStatementsPageFilter;
	@Output() onFormatChanged = new EventEmitter<any>();
	constructor(private fb: FormBuilder) {
	}


	initSubscription() {
		this.format.valueChanges.subscribe(format => {
			this.filter.format = format;
			this.onFormatChanged.emit();
		});
	}

	ngOnInit() {
		this.form = this.fb.group({
			format: [ElectronicalStatementsFormat.MT940],
		});
		this.initSubscription();
	}

	get format(): AbstractControl {
		return this.form.get('format');
	}

}
