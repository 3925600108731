import { Injectable } from '@angular/core';
import { MdibFile, ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { AccountStatementsPageFilter } from '@mdib/account-statements';
import { PdfStatementSummary, PdfStatement } from '@mdib/pdf-statements/model/pdf-statement';
import { PdfStatementsPageFilter } from '@mdib/pdf-statements/model/pdf-statement-page-filter';

/**
 * Abstracts the xcl calls related to {@link PdfStatement}
 */
@Injectable()
export abstract class PdfStatementsService {

	/**
	 * Retrieves the list of account statements related to the current principal
	 * @param {number} [offset] Offset to start from (by default 0, i.e. from the beginning)
	 * @param {number} [limit] Maximum number of operations to retrieve (by default 0, i.e. no limit)
	 * @param {AccountStatementsFilter} [filter] Object containing properties on which the result must be filtered
	 * @returns {Observable<ServiceResponse<PdfStatementSummary[]>>} List of account statements related to principal
	 * @memberof PdfStatementsService
	 */
	public abstract list(index?: number, count?: number, filter?: PdfStatementsPageFilter): Observable<ServiceResponse<PdfStatementSummary[]>>;

	/**
	 * Counts the list of account statements from XCL related to the current principal
	 * @param {AccountStatementsFilter} [filter] Object containing properties on which the result must be filtered
	 * @returns {Observable<ServiceResponse<number>>} The number of account statements related to principal
	 * @memberof AccountStatementsService
	 */
	public abstract count(filter?: PdfStatementsPageFilter): Observable<ServiceResponse<number>>;

	/**
	 * Retrieve a {PdfStatementSummary} based on its unique identifier
	 *
	 * @param {PdfStatementSummary} PdfStatementSummary PdfStatementSummary
	 * @returns {Observable<ServiceResponse<PdfStatement>>}
	 */
	public abstract get(accountStatementSummary?: PdfStatementSummary, previousStatement?: PdfStatement, filter?: PdfStatementsPageFilter): Observable<ServiceResponse<PdfStatement>>;

	/**
	 * This method allows to put a account statement to status 9000(printed)
	 *
	 * @param {PdfStatement}
	 * @returns {Observable<ServiceResponse<PdfStatement>>}
	 */
	public abstract update(PdfStatement: PdfStatement): Observable<ServiceResponse<PdfStatement>>;

	public abstract initializeFilter(): PdfStatementsPageFilter;

	public abstract getRepresentedClientNumber(): Observable<ServiceResponse<string>>;

}
