import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NotifactionMessageComponent } from './component/notification-message.component';
import { NotificationMessageService } from './service/notification-message.service';
import { ServiceResponseNotificationMessagesMapperService } from './service/service-response-notification-messages-mapper.service';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
	],
	declarations: [
		NotifactionMessageComponent,
	],
	providers: [
		ServiceResponseNotificationMessagesMapperService,
	],
	exports: [
		NotifactionMessageComponent,
	],
})
export class NotificationMessageModule {

	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: NotificationMessageModule,
			providers: [
				NotificationMessageService,
			]
		};
	}

}
