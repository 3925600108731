import { CashAccount } from '../model/cash-account';

/**
 * Tool to generate a {@link CashAccount}
 *
 * @example
 * CashAccountBuilder.empty() // Initiates an empty {@link CashAccount}
 *   .clientWording('test') // Fill some fields thanks to chaining
 *   .type('regulated_savings_account') // ...
 *   .CashAccount; // Retrieves the generated {@link CashAccount}
 */
export class CashAccountBuilder {
	private _cashAccount: CashAccount;

	constructor(cashAccount?: CashAccount) {
		this._cashAccount = new CashAccount();
		if (cashAccount) {
			this.accountNumber(cashAccount.number).availableBalance(cashAccount.availableBalance).productCode(cashAccount.productCode)
				.clientWording(cashAccount.clientWording).bic(cashAccount.bic).currentBalance(cashAccount.currentBalance).valuationCurrency(cashAccount.valuationCurrency)
				.type(cashAccount.type).isPendingOperations(cashAccount.isPendingOperations);
		}
	}

	/**
	 * Initiates a builder based on an empty {@link CashAccount}
	 * @returns {CashAccountBuilder}
	 */
	static empty(): CashAccountBuilder {
		return new CashAccountBuilder();
	}

	/**
	 * Initiates a builder based on an already existing {@link CashAccount}.
	 * The {@link CashAccount} will be cloned.
	 *
	 * @param {CashAccount} cashAccount
	 * @returns {CashAccountBuilder}
	 */
	static from(cashAccount: CashAccount): CashAccountBuilder {
		return new CashAccountBuilder(cashAccount);
	}

	public accountNumber(value: string): CashAccountBuilder {
		this._cashAccount.number = value;
		return this;
	}

	public availableBalance(value: number): CashAccountBuilder {
		this._cashAccount.availableBalance = value;
		return this;
	}

	public productCode(value: string): CashAccountBuilder {
		this._cashAccount.productCode = value;
		return this;
	}

	public clientWording(value: string): CashAccountBuilder {
		this._cashAccount.clientWording = value;
		return this;
	}

	public clientFullName(value: string): CashAccountBuilder {
		this._cashAccount.clientFullName = value;
		return this;
	}

	public currentBalance(value: number): CashAccountBuilder {
		this._cashAccount.currentBalance = value;
		return this;
	}

	public valuationCurrency(value: string): CashAccountBuilder {
		this._cashAccount.valuationCurrency = value;
		return this;
	}

	public type(value: string): CashAccountBuilder {
		this._cashAccount.type = value;
		return this;
	}

	public isPendingOperations(value: boolean): CashAccountBuilder {
		this._cashAccount.isPendingOperations = value;
		return this;
	}

	public isNewStatement(value: boolean): CashAccountBuilder {
		this._cashAccount.isNewStatement = value;
		return this;
	}

	get cashAccount(): CashAccount {
		return this._cashAccount;
	}

	public bic(value: string): CashAccountBuilder {
		this._cashAccount.bic = value;
		return this;
	}
}
