import { Injectable } from '@angular/core';
import { BicService } from '@mdib/cash-accounts';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';

@Injectable({
	providedIn: 'root',
})
export class BicXclService extends BicService {

	public get(account: string): Observable<ServiceResponse<string> | null> {
		console.log(`Your asking for the BIC of ${account} account`);
		return of(new ServiceResponse(null));
	}
}
