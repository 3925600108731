import { Injectable } from '@angular/core';
import { CashAccountOperationsFilter } from '@mdib/cash-accounts';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { CashAccountOperation } from '../model/cash-account-operation';
import { CashAccountOperationService } from './cash-account-operation.service';

@Injectable()
export class CashAccountOperationServiceStub extends CashAccountOperationService {

	public list(accountNumber: string, index?: number, count?: number, filter?: CashAccountOperationsFilter): Observable<ServiceResponse<CashAccountOperation[]>> {
		return of();
	}

	public count(filter?: CashAccountOperationsFilter): Observable<ServiceResponse<number>> {
		return of();
	}
}
