import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { ServiceResponse, Feedback, FeedbackTypes } from '@mdib/utils';
import { AuthenticationStatisticData } from '@mdib/sessions';
import { XclAuthenticationStatisticDataModel } from '../model/xcl-authentication-statistic-data-model';
import { XclDateFormatter } from '../../utils';

@Injectable()
export class AuthenticationDataMapperXclService {
	/**
	 * Converts an array of technical model of authentication statistic data into equivalent functional model array of AuthenticationStatisticData
	 * @param XclAuthenticationStatisticDataModel[]: technical model array of authentication statistic data
	 * @return { AuthenticationStatisticData[] }
	 */
	getFunctionalModelArrayFromTechnicalModelArray(technicalModelArray: XclAuthenticationStatisticDataModel[]): AuthenticationStatisticData[] {
		if (isNullOrUndefined(technicalModelArray)) {
			throw new ServiceResponse<null>(null, [new Feedback(
				'errorWhileRetrievingAuthenticationStatisticData', FeedbackTypes.FRONTEND_ERROR,
				'Something went wrong while retrieving list of authentication statistic data',
			)]);
		}

		return technicalModelArray.map((technicalModel: XclAuthenticationStatisticDataModel) => this.xclModelToFunctionalModel(technicalModel));
	}

	/**
	 * Converts technical model of authentication statistic data into equivalent functional model of AuthenticationStatisticData
	 * @param XclAuthenticationStatisticDataModel: technical model  of authentication statistic data
	 * @return { AuthenticationStatisticData }
	 */
	xclModelToFunctionalModel(xclMessage: XclAuthenticationStatisticDataModel): AuthenticationStatisticData {
		const dateStr = XclDateFormatter.convertXCLToISODateFormat(xclMessage.lastConnectionDate) + 'T' +
					XclDateFormatter.convertXCLToISOTimeFormat(xclMessage.previousSuccessfulLoginTime);
		return {
				lastConnectionDateAndTime: new Date(dateStr),
		};

	}
}
