import { Observable, of } from 'rxjs';

import { TranslationLoaderService } from './translation-loader.service';

export class TranslationLoaderServiceStub implements TranslationLoaderService {

	public load(language: string): Observable<any> {
		return of({});
	}
}
