export namespace UtilsHelper {

	/**
	 * Removes any other characters than numbers from an alphanumeric string.
	 * @param {string} : a string that could hold alphanumeric value.
	 * @returns {string}: a string with only numbers.
	 */
	export function alphaToNumber(value: string): string {
		return value ? value.replace(/[^\d]/g, '') : '';
	}
	/**
	 * Removes any other characters than numbers from an alphanumeric string.
	 * @param {string} : a string that could hold alphanumeric value.
	 * @returns {string}: a string with only numbers.
	 */
	export function onlyAlpha(value: string): string {
		return value ? value.toUpperCase().replace(/\W/g, '') : null;
	}

	/**
	 * Checks whether a value is null or undefined.
	 * @param {any} value : any value
	 * @returns {string}: true if null or undefined.
	 */
	export function nullOrUndefined(value: any): boolean {
		return value === null || value === undefined;
	}

	/**
	 * Checks if an object or an iterable has at least one defined property.
	 * @param {any} value : any value
	 * @returns {string}: true if the object has at least one defined property and false for any other value
	 */
	export function objectNotEmpty(o: Object): boolean {
		return !!o && typeof o === 'object' ? Object.entries(o).some((key) => {
			return !!key[1];
		}) : false;
	}

	export function objectsEqual(o1: any, o2: any): boolean {
		if (o1 === o2 || (!o1 && !o2)) {
			return true;
		}
		if (typeof o1 === 'object' && typeof o2 === 'object') {
			return [...Object.keys(o1), ...Object.keys(o2)].every(key => objectsEqual(o1[key], o2[key]));
		}
		return false;
	}

	/**
	 * Get the width of current device.
	 * @returns {number}
	 */
	export function getWidthDevice(): number {
		return window.screen.width;
	}

	/**
	 * This method check if a string is null, undefined or empty.
	 * isNullOrWhiteSpace(null) = true
	 * isNullOrWhiteSpace(undefined) = true
	 * isNullOrWhiteSpace('') = true
	 */
	export function isNullOrWhiteSpace(str: string): boolean {
		return (!str || str.length === 0 || /^\s*$/.test(str));
	}

	/**
	 * Get descriptor for a property
	 */
	export function getPropertyDescriptor(o, name) {
		let proto = o, descriptor;
		while (proto && !(descriptor = Object.getOwnPropertyDescriptor(proto, name))) {
			proto = proto.__proto__;
		}
		return descriptor;
	}


	/**
	 * Scroll to app container by default or to specified selector
	 * @param {string} selector: optional selector to grab another element
	 */
	export function scrollTo(selector?: string) {
		const content = document.querySelector(selector || 'mat-sidenav-content');
		if (content && typeof content.scroll === 'function') {
			content.scroll(0, 0);
		}
	}

	/**
	 * Escape HTML to display it safely as text
	 * @param {string} html HTML string to escape
	 * @returns {string} The escaped text
	 */
	export function escapeHtml(html: string): string {
		return (typeof html === 'string' && html || '')
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#039;');
	}

	/**
	 * Converts a string amount to number.
	 * @param string: amount which could be formatted with coma
	 * @return number: parsed amount
	 */
	export function amountToNumber(amount: string): number | null {
		return amount ? parseFloat(amount.replace(',', '.')) : null;
	}

	/**
	 * Merge objects into one resulting object.
	 * Each source can potentially override the previous values.
	 *
	 * @param sources List of objects to merge
	 */
	export function mergeObjects(...sources: any[]) {
		const target = {};
		sources.forEach(source => {
			if (source === null || source === undefined) {
				return;
			}

			for (const key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					if (typeof source[key] === 'object' && source[key] !== null) {
						target[key] = mergeObjects(target[key], source[key]);
					} else {
						target[key] = source[key];
					}
				}
			}
		});
		return target;
	}
}
