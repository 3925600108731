/**
 * Holds data about a cash account
 */
export class CashAccount {

	number: string;
	bic: string;
	availableBalance?: number;
	productCode?: string;
	clientWording?: string;
	clientFullName: string;
	currentBalance?: number;
	valuationCurrency?: string;
	type?: string;
	isPendingOperations?: boolean;
	isNewStatement?: boolean;

	constructor(account?: Partial<CashAccount>) {
		Object.assign(this, account);
	}
}
