import { Injectable } from '@angular/core';
import { KeyingLimit } from '../model/keying-limit';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { KeyingLimitsService } from './keying-limits.service';

@Injectable()
export class KeyingLimitsServiceStub extends KeyingLimitsService {
	public list(index?: number, count?: number): Observable<ServiceResponse<KeyingLimit[]>> {
		return of();
	}
	public count(): Observable<ServiceResponse<number>> {
		return of();
	}
}
