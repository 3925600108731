import { isNullOrUndefined } from 'util';
import { Signable, Signature } from '@mdib/signature';
import { Status } from '@mdib/utils';
import { ShoppingBasketOperation } from './shopping-basket-operation';

export class ShoppingBasketEnvelope implements Signable {
	// Fields provided by the backend
	reference: string;
	status: Status;
	isReadyToBeSigned: boolean;

	// Front fields
	operations: ShoppingBasketOperation[] = [];
	signature: Signature = null;
	signatureContext: any = null;

	/**
	 * Constructor of a Shopping Basket Envelope.
	 * @param operations An optional list of operations that will be contained by the envelope.
	 */
	constructor(operations?: ShoppingBasketOperation[]) {
		if (!isNullOrUndefined(operations) && operations.length > 0) {
			this.operations.push.apply(this.operations, operations);
		}
	}

	getOrderReferences(): string[] {
		return this.operations.map((operation: ShoppingBasketOperation) => operation.reference);
	}

	/**
	 * Returns true if the provided signature type is allowed by all the operations contained in the envelope.
	 * @param signatureType The signature type to check.
	 */
	isSignatureTypeAllowed(signatureType: any): boolean {
		let isSignatureTypeAllowedByAllOperations = true;
		this.operations.forEach((operation: ShoppingBasketOperation) => {
			if (isNullOrUndefined(operation.signatureTypesAllowed) || !operation.signatureTypesAllowed.length || operation.signatureTypesAllowed.indexOf(signatureType) < 0) {
				isSignatureTypeAllowedByAllOperations = false;
			}
		});
		return isSignatureTypeAllowedByAllOperations;
	}
	isSigned(): boolean {
		return !isNullOrUndefined(this.signature);
	}
	addSignature(signature: Signature): void {
		this.signature = signature;
	}

	public getSignatureContext(): any {
		return this.signatureContext;
	}
}
