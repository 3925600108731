import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { ForgotPasswordService } from './forgot-password.service';
import { ForgotPasswordServiceStub } from './forgot-password.service.stub';

@Injectable()
export class ForgotPasswordServiceProxy extends AdapterProxy {
	protected ServiceToken() { return ForgotPasswordService; }
	protected ServiceDefinition() { return ForgotPasswordServiceStub; }
}
