import { ElectronicalStatementsService } from './electronical-statements.service';
import { ElectronicalStatement } from '../model/electronical-statement';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { ElectronicalStatementsPageFilter } from '../model/electronical-statements-page-filter';


export class ElectronicalStatementsServiceStub extends ElectronicalStatementsService {
	list(offset?: number, limit?: number, filter?: ElectronicalStatementsPageFilter): Observable<ServiceResponse<ElectronicalStatement[]>> {
		return of();
	}

	count(filter?: ElectronicalStatementsPageFilter): Observable<ServiceResponse<number>> {
		return of();
	}

	get(id: string): Observable<ServiceResponse<ElectronicalStatement>> {
		return of();
	}

	update(accountStatement: ElectronicalStatement): Observable<ServiceResponse<ElectronicalStatement>> {
		return of();
	}

	initializeFilter(): ElectronicalStatementsPageFilter {
		return undefined;
	}

	downloadStatement() {
		return undefined;
	}

}
