import { Person } from '@mdib/customers';
import { Signature } from '@mdib/signature';
import { SignatureModes } from '@mdib/signature-modes';
import { Status } from '@mdib/utils';
import { CashAccountCreation } from '../model/cash-account-creation';

/**
 * Tool to generate a {@link CashAccountCreation}
 *
 * @example
 * CashAccountCreationBuilder.empty() // Initiates an empty {@link CashAccountCreation}
 *   .clientWording('test') // Fill some fields thanks to chaining
 *   .type(CashAccountCreationType.RegulatedSavingsAccounts) // ...
 *   .CashAccountCreation; // Retrieves the generated {@link CashAccountCreation}
 */
export class CashAccountCreationBuilder {
	private _cashAccountCreation: CashAccountCreation;

	constructor(cashAccountCreation?: CashAccountCreation) {
		this._cashAccountCreation = cashAccountCreation || new CashAccountCreation();
	}

	/**
	 * Initiates a builder based on an empty {@link CashAccountCreation}
	 * @returns {CashAccountCreationBuilder}
	 */
	static empty(): CashAccountCreationBuilder {
		return new CashAccountCreationBuilder();
	}

	/**
	 * Initiates a builder based on an already existing {@link CashAccountCreation}.
	 * The {@link CashAccountCreation} will be cloned.
	 *
	 * @param {CashAccountCreation} cashAccountCreation
	 * @returns {CashAccountCreationBuilder}
	 */
	static from(cashAccountCreation: CashAccountCreation): CashAccountCreationBuilder {
		return new CashAccountCreationBuilder(cashAccountCreation);
	}

	public reference(value: string): CashAccountCreationBuilder {
		this._cashAccountCreation.reference = value;
		return this;
	}

	public status(value: Status): CashAccountCreationBuilder {
		this._cashAccountCreation.status = value;
		return this;
	}

	public accountNumber(value: string): CashAccountCreationBuilder {
		this._cashAccountCreation.number = value;
		return this;
	}

	public availableBalance(value: number): CashAccountCreationBuilder {
		this._cashAccountCreation.availableBalance = value;
		return this;
	}

	public productCode(value: string): CashAccountCreationBuilder {
		this._cashAccountCreation.productCode = value;
		return this;
	}

	public clientWording(value: string): CashAccountCreationBuilder {
		this._cashAccountCreation.clientWording = value;
		return this;
	}

	public currentBalance(value: number): CashAccountCreationBuilder {
		this._cashAccountCreation.currentBalance = value;
		return this;
	}

	public valuationCurrency(value: string): CashAccountCreationBuilder {
		this._cashAccountCreation.valuationCurrency = value;
		return this;
	}

	public type(value: string): CashAccountCreationBuilder {
		this._cashAccountCreation.type = value;
		return this;
	}

	public isPendingOperations(value: boolean): CashAccountCreationBuilder {
		this._cashAccountCreation.isPendingOperations = value;
		return this;
	}

	public isNewStatement(value: boolean): CashAccountCreationBuilder {
		this._cashAccountCreation.isNewStatement = value;
		return this;
	}

	public termsAccepted(value: boolean): CashAccountCreationBuilder {
		this._cashAccountCreation.termsAccepted = value;
		return this;
	}

	public clientCounterparty(value: Person): CashAccountCreationBuilder {
		this._cashAccountCreation.holder = value;
		return this;
	}

	public usResident(value: boolean): CashAccountCreationBuilder {
		this._cashAccountCreation.usResident = value;
		return this;
	}

	public signature(value: Signature): CashAccountCreationBuilder {
		this._cashAccountCreation.signature = value;
		return this;
	}

	public signatureTypesAllowed(value: SignatureModes[]): CashAccountCreationBuilder {
		this._cashAccountCreation.signatureTypesAllowed = value;
		return this;
	}

	public signatureContext(value: any): CashAccountCreationBuilder {
		this._cashAccountCreation.signatureContext = value;
		return this;
	}

	get cashAccountCreation(): CashAccountCreation {
		return this._cashAccountCreation;
	}
}
