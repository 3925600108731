import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CashAccountsModule } from '@mdib/cash-accounts';

import { provideTranslations, CustomizationModule } from '@mdib/core/customization';
import { StylingModule } from '@mdib/styling';
import { UtilsModule, provideConfiguration } from '@mdib/utils';
import { CommonsModule } from '@mdib/commons';

import { BeneficiarySearchFormComponent } from '../components/beneficiary-search-form/beneficiary-search-form.component';
import { BeneficiariesTableComponent } from '../components/beneficiaries-table/beneficiaries-table.component';
import { BeneficiarySearchModalComponent } from '../components/beneficiary-search-modal/beneficiary-search-modal.component';
import { BeneficiaryFormComponent } from '../components/beneficiary-form/beneficiary-form.component';

import { BeneficiariesService as BeneficiariesServiceV2 } from '../services/beneficiaries/beneficiaries.service';
import { BeneficiariesServiceProxy as BeneficiariesServiceProxyV2 } from '../services/beneficiaries/beneficiaries.service.proxy';


// TODO When the application uses angular 7, import from a json file (without typings)
import { translations } from './translations';
import { properties } from './properties';

// Deprecated
import { MdibBeneficiaryFormComponent } from '../deprecated/mdib-beneficiary-form/beneficiary-form.component';

/**
 * List of Components, Pipes and Directives to export
 */
const exportedDeclarations = [
	BeneficiarySearchFormComponent,
	BeneficiariesTableComponent,
	BeneficiarySearchModalComponent,
	BeneficiaryFormComponent,
	MdibBeneficiaryFormComponent,
];

/**
 * Beneficiaries Feature Module
 */
@NgModule({
	imports: [
		// Libraries
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		// Shared modules
		StylingModule,
		CommonsModule,
		UtilsModule,
		CashAccountsModule,
		CustomizationModule,
	],
	declarations: [
		...exportedDeclarations,
	],
	exports: exportedDeclarations,
	entryComponents: [
		BeneficiarySearchModalComponent,
	],
})
export class BeneficiariesModule {
	/**
	 * Provide the module with the global services
	 */
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: BeneficiariesModule,
			providers: [
				{ provide: BeneficiariesServiceV2, useClass: BeneficiariesServiceProxyV2 },
				provideTranslations(translations, 'beneficiaries'),
				provideConfiguration(properties, 'beneficiaries'),
			],
		};
	}
}
