// Modules
export { BeneficiariesModule } from './metadata/beneficiaries.module';

// Types > Enums
export { BeneficiaryType } from './types/enums/beneficiary-type.enum';

// Types > Models
export { Beneficiary as BeneficiaryV2, BeneficiaryModel } from './types/models/beneficiary';
export { BeneficiariesSearch } from './types/models/beneficiaries-search';

// Types > Forms
export { BeneficiaryFormBuilder } from './types/forms/beneficiary-form.builder';
export { BeneficiaryFormGroup } from './types/forms/beneficiary-form-group';

// Services
export { BeneficiariesService as BeneficiariesServiceV2 } from './services/beneficiaries/beneficiaries.service';

// Services > Stubs
export { BeneficiariesServiceStub as BeneficiariesServiceStubV2 } from './services/beneficiaries/beneficiaries.service.stub';

// Components > Modals
export { BeneficiarySearchModalComponent } from './components/beneficiary-search-modal/beneficiary-search-modal.component';

// Deprecated
export { Beneficiary } from './deprecated/beneficiary';
export { BeneficiaryForm } from './deprecated/beneficiary-form';
