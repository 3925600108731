import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';

import { ConfigurationService } from '@mdib/utils';

import { TranslationLoaderService } from './translation-loader.service';

@Injectable()
export class TranslationHttpLoaderService implements TranslationLoaderService {

	private cachedTranslations: any = {};

	private isLoading: Subject<any> = null;

	constructor(
		private httpClient: HttpClient,
		private configurationService: ConfigurationService,
	) { }

	public load(language: string): Observable<any> {
		// Check in the cache
		if (this.cachedTranslations[language]) {
			return of(this.cachedTranslations[language]);
		}

		// Build the translation file URL
		let translationUrl: string = this.configurationService.instant('translation.endpoint.domain') || '';
		if (!translationUrl.endsWith('/')) {
			translationUrl += '/';
		}
		translationUrl += language + '.json';

		// Fetch the translations from the server
		if (!this.isLoading) {
			const observable = this.httpClient.get(translationUrl).pipe(
				tap((translations: any) => {
					this.cachedTranslations[language] = translations;
				}),
				catchError(() => {
					return of({});
				}),
				finalize(() => {
					this.isLoading = null;
				})
			);

			this.isLoading = new Subject();
			observable.subscribe(this.isLoading);
		}

		return this.isLoading;
	}

}
