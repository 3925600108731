import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';

// Simple utility function
function isDefined(value: any): boolean {
	return typeof value !== 'undefined' && value !== null;
}

@Injectable()
export class GenericTranslateParser extends TranslateDefaultParser {

	/**
	 * Find the best matching translation for a key. The key is a serie
	 * of identifiers seperated by a dot. The last identifier is the
	 * translation symbol to find, while the preceeding identifiers are
	 * the path, form the most generic to the more specific.
	 *
	 * @example path.to.some.symbol
	 *
	 * The translation symbol can be a simple string or a composed symbol
	 * with the colon.
	 *
	 * @example path.to.some.error:undefined
	 *
	 * The parser always search for the full symbol, but the path is
	 * searched iteratively up to the root of the target.
	 *
	 * @param target The associative array to search in
	 * @param key The full key to search
	 *
	 * @returns The translation corresponding to the key
	 */
	public getValue(target: any, key: string): any {
		const keys = key.split('.');
		const translationSymbol = keys.pop();

		// Search for the most precise translation
		let curentValue: any;
		do {
			// Cannot search if not an object
			if (!isDefined(target) || typeof target !== 'object') { break; }

			// Search for the full key
			const fullKey: string = keys.join('.') + '.' + translationSymbol;
			if (keys.length && isDefined(target[fullKey]) && typeof target[fullKey] !== 'object') {
				curentValue = target[fullKey];
			}

			// Search for the translationSymbol
			const localValue = this.getSymbolValue(target, translationSymbol);
			if (isDefined(localValue)) {
				curentValue = localValue;
			}

			// Try the next stage
			if (keys.length) {
				const nextPart = keys.shift();
				target = target[nextPart];
			} else {
				break;
			}
		} while (true);

		return curentValue;
	}

	private getSymbolValue(target: object, symbol: string): string | object {
		// Explode symbol to keys
		const keys: string[] = symbol.split(':');

		// Explore target to find the symbol or die trying
		let value: any = target;
		for (const key of keys) {
			if (typeof value === 'object' && isDefined(value[key])) {
				value = value[key];
			} else {
				return undefined;
			}
		}
		return value;
	}

}
