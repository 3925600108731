import { Injectable } from '@angular/core';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';

import { MailboxConversation } from '../model/mailbox-conversation';
import { MailboxConversationSummary } from '../model/mailbox-conversation-summary';
import { MailboxMessage } from '../model/mailbox-message';
import { MailboxMessagesService } from './mailbox-messages.service';

@Injectable()
export class MailboxMessagesServiceStub implements MailboxMessagesService {

	public getConversations(): Observable<ServiceResponse<MailboxConversationSummary[]>> {
		return of();
	}

	public getConversationDetails(conversationIdentifier: string): Observable<ServiceResponse<MailboxConversation>> {
		return of();
	}

	public reloadConversations(): void {
	}

	public countUnreadMessages(): Observable<ServiceResponse<number>> {
		return of();
	}

	public reloadConversation(conversationIdentifier: string): void {
	}

	public addMessage(message: MailboxMessage): Observable<ServiceResponse<MailboxMessage>> {
		return of();
	}

	public deleteConversations(conversationIdentifiers: string[], reloadConversationsUponCompletion?: boolean): Observable<ServiceResponse<null>> {
		return of();
	}

	public deleteConversation(conversationIdentifier: string, reloadConversationsUponCompletion?: boolean): Observable<ServiceResponse<null>> {
		return of();
	}

	public markConversationsAsReadOrUnread(conversationIdentifiers: string[], isRead: boolean): Observable<ServiceResponse<null>> {
		return of();
	}

	public markConversationAsReadOrUnread(conversationIdentifier: string, isRead: boolean): Observable<ServiceResponse<null>> {
		return of();
	}

	public unreadMessagesCounter(): Observable<ServiceResponse<number>> {
		return of();
	}
}
