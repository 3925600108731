import { Injectable } from '@angular/core';
import { FileFormat } from '@mdib/utils';
import { AccountStatementsPageFilter } from '../model/account-statements-page-filter';
import { AccountStatementsService } from './account-statements.service';

@Injectable()
export abstract class  AccountStatementsCommonService extends AccountStatementsService {

	public initializeFilter(): AccountStatementsPageFilter {

		const filter: AccountStatementsPageFilter = {
			cashAccount: null,
			fileFormat: FileFormat.pdf,
			status: null,
			accountingDateFrom: null,
			accountingDateTo: null,
			statementNumberFrom: null,
			statementNumberTo: null,
			statementYear: null,
		};
		return filter;
	}
}
