import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule } from '@mdib/config';

import { HttpModule } from '@mdib/http';
import { StylingModule } from '@mdib/styling';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderComponent } from './component/header/header.component';
import { NavigationComponent } from './component/navigation/navigation.component';
import { SearchComponent } from './component/search/search.component';
import { UserNotificationsComponent } from './component/user-notifications/user-notifications.component';
import { PersonProfileComponent } from './component/person-profile/person-profile.component';
import { LanguageSelectorComponent } from './component/language-selector/language-selector.component';
import { UtilsModule } from '@mdib/utils';
import { SecurityModule } from '@mdib/core/security';

@NgModule({
	imports: [
		// Standard modules
		CommonModule,
		RouterModule,
		// Our modules
		HttpModule,
		ConfigModule,
		StylingModule,
		// Third party
		TranslateModule,
		UtilsModule,
		SecurityModule,
	],
	declarations: [
		HeaderComponent,
		NavigationComponent,
		SearchComponent,
		UserNotificationsComponent,
		PersonProfileComponent,
		LanguageSelectorComponent,
	],
	exports: [
		HeaderComponent,
		NavigationComponent,
	]
})
export class HeaderModule { }

