import { CommunicationTypes, Address } from '@mdib/commons';
import { ManagedCurrencyCodes } from '@mdib/config';
import { InternationalPaymentDetails, OperationStatus, PaymentOperation, PaymentOperationBuilder, PaymentTypes } from '@mdib/payments';
import { Status } from '@mdib/utils';

export class InternationalPaymentsMock {

	static getMockInternationalPayments(): PaymentOperation<InternationalPaymentDetails>[] {
		return [
			new PaymentOperationBuilder<InternationalPaymentDetails>()
				.reference('B7K274875O4FPTG5')
				.operationDate(new Date())
				.maturityDate(new Date())
				.ordererAccountNumber('BE56305177635588')
				.ordererName('Daily')
				.amount(10000)
				.currency(ManagedCurrencyCodes.USD)
				.counterpartyAccountNumber('NL91ABNA0417164300')
				.counterpartyBicCode('ABNANL2AXXX')
				.counterpartyName('Hans-Jeroen Van der Steenbrillen')
				.counterpartyAddress(new Address({
					addressLine1: 'Tervuren Avenue',
					addressLine2: '1575',
					country: 'BE',
				}))
				.communicationType(CommunicationTypes.free)
				.communication('This is a very long communication for testing purposes - 66 chars')
				.executionDetails(new InternationalPaymentDetails({
					bankCountry: 'BE',
				}))
				.status(Status.ToValidate)
				.operationStatus(OperationStatus.awaitingProcessing)
				.type(PaymentTypes.internationalPayment).paymentOperation,
			new PaymentOperationBuilder<InternationalPaymentDetails>()
				.reference('B72H74875O4F78G6')
				.operationDate(new Date())
				.maturityDate(new Date())
				.ordererAccountNumber('BE56305177635588')
				.ordererName('Daily')
				.amount(5000)
				.currency(ManagedCurrencyCodes.USD)
				.counterpartyAccountNumber('NL91ABNA0417164300')
				.counterpartyBicCode('ABNANL2AXXX')
				.counterpartyName('Hans-Jeroen Van der Steenbrillen')
				.counterpartyAddress(new Address({
					addressLine1: 'Tervuren Avenue',
					addressLine2: '1575',
					country: 'BE',
				}))
				.communicationType(CommunicationTypes.free)
				.communication('This is a very long communication for testing purposes - 66 chars')
				.executionDetails(new InternationalPaymentDetails({
					bankCountry: 'BE',
				}))
				.status(Status.ToValidate)
				.operationStatus(OperationStatus.technicalIncident)
				.type(PaymentTypes.internationalPayment)
				.paymentOperation,
			new PaymentOperationBuilder<InternationalPaymentDetails>()
				.reference('B72HABV75O8798G7')
				.operationDate(new Date())
				.maturityDate(new Date())
				.ordererAccountNumber('BE56305177635588')
				.ordererName('Daily')
				.amount(200)
				.currency(ManagedCurrencyCodes.USD)
				.counterpartyAccountNumber('NL91ABNA0417164300')
				.counterpartyBicCode('ABNANL2AXXX')
				.counterpartyName('Hans-Jeroen Van der Steenbrillen')
				.counterpartyAddress(new Address({
					addressLine1: 'Tervuren Avenue',
					addressLine2: '1575',
					country: 'BE',
				}))
				.communicationType(CommunicationTypes.free)
				.communication('This is a very long communication for testing purposes - 66 chars')
				.status(Status.ToValidate)
				.operationStatus(OperationStatus.awaitingProcessing)
				.type(PaymentTypes.internationalPayment)
				.executionDetails(new InternationalPaymentDetails({
					bankCountry: 'BE',
				}))
				.paymentOperation,
			new PaymentOperationBuilder<InternationalPaymentDetails>()
				.reference('P9D64KSFGZ4D8C6M')
				.operationDate(new Date())
				.maturityDate(new Date())
				.ordererAccountNumber('BE56305177635588')
				.ordererName('Daily')
				.amount(209.77)
				.currency(ManagedCurrencyCodes.USD)
				.counterpartyAccountNumber('US593331234456898956')
				.counterpartyBicCode('ABNANL2AXXX')
				.counterpartyName('Mr Smith')
				.counterpartyAddress(new Address({
					addressLine1: 'Tervuren Avenue',
					addressLine2: '1575',
					country: 'BE',
				}))
				.status(Status.InShoppingBasket)
				.operationStatus(OperationStatus.inShoppingBasket)
				.communicationType(CommunicationTypes.free)
				.communication('This international payment works')
				.executionDetails(new InternationalPaymentDetails({
					bankCountry: 'BE',
				}))
				.type(PaymentTypes.internationalPayment)
				.paymentOperation,

		];

	}

}
