import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AuthorizationsService } from '@mdib/core/security';
import { ServiceResponse } from '@mdib/utils';
import { Session, SessionRestorationService } from '@mdib/sessions';

import { AccessRightTokenXclDefinition, AccessRightTokenXcl } from '@mdib-xcl/utils';
import { XclHttpService } from '@mdib-xcl/http';

@Injectable()
export class SessionRestorationXclService extends SessionRestorationService {

	constructor(
		private _xclHttpService: XclHttpService,
		protected authorizationsService: AuthorizationsService,
	) { super(); }

	public restore(session: Session): boolean {
		// Not an XCL session
		if (session.type !== 'XCL') {
			return false;
		}

		// Restore access rights
		this.authorizationsService.flushAccessRights();
		const xclRights: Array<AccessRightTokenXclDefinition> = session.activeUser.accessRights || [];
		this.authorizationsService.addAccessRight(...xclRights.map(rightDef => new AccessRightTokenXcl(rightDef)));

		// Set the close callback
		session.close = () => {
			const response = new ServiceResponse();
			return this._xclHttpService.execute('session-close', XclHttpService.POST).pipe(map(() => response));
		};
		session.keepAlive = () => {
			const response = new ServiceResponse();
			return this._xclHttpService.execute('session-keepAlive', XclHttpService.GET).pipe(map(() => response));
		};
		return true;
	}

}
