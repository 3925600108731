import { Injectable } from '@angular/core';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { StandingOrder } from '../model/standing-order';
import { StandingOrdersPageFilter } from '../model/standing-orders-page-filter';

/**
 * Abstracts the xcl calls related to {@link StandingOrder}
 */
@Injectable()
export abstract class StandingOrdersService {

	/**
	 * @param {number} offset Offset to start from (by default 0, i.e. from the beginning)
	 * @param {number} limit Maximum number of operations to retrieve (by default 0, i.e. no limit)
	 * @param {StandingOrdersFilter} filter Object containing properties on which the result must be filtered
	 * @example
	 * list(accountNumber, offset, limit)
	 *     .subscribe(
	 *         (serviceResponse: ServiceResponse<StandingOrder[]>) => this.standingOrders = serviceResponse.getModel()
	 *     );
	 */
	public abstract list(offset?: number,
		limit?: number,
		filter?: StandingOrdersPageFilter): Observable<ServiceResponse<StandingOrder[]>>;

	/**
	 * Count the list of standing orders from XCL related to the current principal
	 * @param {StandingOrdersFilter} [filter] Object containing properties on which the result must be filtered
	 * @returns {Observable<ServiceResponse<number>>} The number of standing orders related to principal
	 * @memberof StandingOrdersService
	 */
	public abstract count(filter: StandingOrdersPageFilter): Observable<ServiceResponse<number>>;

	/**
	 * Retrieve a standing order
	 * @param {string} reference Standing order reference
	 * @returns {(Observable<ServiceResponse<PaymentOperation|null>>)} The standing order required
	 * @memberof StandingOrdersService
	 */
	public abstract get(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>>;

	public abstract validateStandingOrder(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>>;

	public abstract signStandingOrder(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>>;

}
