import { Injectable } from '@angular/core';
import { HttpStatusManagementService, ParameterModel } from '@mdib/http';
import { FunctionalFeedbacksFromXclOrderExtractor, MultigoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { Observable } from 'rxjs';
import { ServiceResponse, Feedback, FeedbackTypes } from '@mdib/utils';
import { CashAccountOperation, CashAccountOperationCommonService, CashAccountOperationsFilter } from '@mdib/cash-accounts';
import { isNullOrUndefined } from 'util';
import { XclCashAccountOperationModel } from '../model/xcl-cash-account-operation-model';
import { CashAccountOperationMapperXclService } from './cash-account-operation-mapper-xcl.service';
import { XclDateFormatter } from '@mdib-xcl/utils';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class CashAccountOperationXclService extends CashAccountOperationCommonService {

	constructor(private _xclHttpService: XclHttpService,
		private _httpStatusManagementService: HttpStatusManagementService,
		private _feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private _xclCashAccountOperationMapperService: CashAccountOperationMapperXclService) {
		super();
	}

	public list(
		accountNumber: string,
		index?: number,
		count?: number, filter?: CashAccountOperationsFilter,
	): Observable<ServiceResponse<CashAccountOperation[]>> {
		const params: ParameterModel[] = [
			new ParameterModel('accountNumber', accountNumber),
		];

		if (!isNullOrUndefined(index) && !isNullOrUndefined(count)) {
			params.push(new ParameterModel('start', index.toString()));
			params.push(new ParameterModel('maxResults', count.toString()));
		}

		// Apply filter
		const feedbacks = this.fillFilterCriteria(params, filter);

		let xclObservable: Observable<MultigoalOrderXCLModel<XclCashAccountOperationModel>>;

		xclObservable = this._xclHttpService.execute('movements', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<XclCashAccountOperationModel>>;

		return xclObservable
			// Extract the operations list from the root "goalList" property returned by the XCL
			// Return an array of cash account operations (functional model) corresponding to
			// the array of cash account operations returned by the XCL (technical model)
			.pipe(map(
				(xclOrder: MultigoalOrderXCLModel<XclCashAccountOperationModel>) =>
					new ServiceResponse<CashAccountOperation[]>(
						this._xclCashAccountOperationMapperService.getFunctionalModelArrayFromTechnicalModelArray(xclOrder.goalList),
						feedbacks.concat(this._feedbackExtractor.extract(xclOrder)),
						{ totalCount: xclOrder.rowCount },
					)
			));
	}

	public count(filter?: CashAccountOperationsFilter): Observable<ServiceResponse<number>> {

		// Workarount to force xcl to do only the count
		const params: ParameterModel[] = [
			new ParameterModel('accountNumber', filter.cashAccount.number),
			new ParameterModel('maxResults', '0'),
			new ParameterModel('start', '1'),
		];

		// Apply filter
		this.fillFilterCriteria(params, filter);

		let xclObservable: Observable<MultigoalOrderXCLModel<XclCashAccountOperationModel>>;

		xclObservable = this._xclHttpService.execute('movements', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<XclCashAccountOperationModel>>;

		return xclObservable
			.pipe(map(
				(xclOrder: MultigoalOrderXCLModel<XclCashAccountOperationModel>) =>
					new ServiceResponse<number>(
						xclOrder.rowCount,
						this._feedbackExtractor.extract(xclOrder)
					)
			));
	}

	private fillFilterCriteria(params: ParameterModel[], filter: CashAccountOperationsFilter | undefined): Feedback[] {
		const feedbacks = [];

		if (isNullOrUndefined(filter)) {
			return feedbacks;
		}
		if (!isNullOrUndefined(filter.credit)) {
			params.push(new ParameterModel('creditOperation', filter.credit ? 'Y' : 'N'));
		}
		if (!isNullOrUndefined(filter.debit)) {
			params.push(new ParameterModel('debitOperation', filter.debit ? 'Y' : 'N'));
		}
		if (!isNullOrUndefined(filter.minAmount) && filter.minAmount) {
			params.push(new ParameterModel('minimumAmount', filter.minAmount.toString()));
		}
		if (!isNullOrUndefined(filter.maxAmount) && filter.maxAmount) {
			params.push(new ParameterModel('maximumAmount', filter.maxAmount.toString()));
		}
		if (!isNullOrUndefined(filter.startDate) && filter.startDate) {
			params.push(new ParameterModel('startAccountingDate', XclDateFormatter.convertDateToXCLDateFormat(filter.startDate)));
		}
		if (!isNullOrUndefined(filter.endDate) && filter.endDate) {
			params.push(new ParameterModel('endAccountingDate', XclDateFormatter.convertDateToXCLDateFormat(filter.endDate)));
		}
		if (!isNullOrUndefined(filter.counterPartyName) && filter.counterPartyName) {
			params.push(new ParameterModel('counterpartyName', filter.counterPartyName));
		}
		if (!isNullOrUndefined(filter.counterPartyAccountNumber) && filter.counterPartyAccountNumber) {
			// Partial BE IBAN not supported
			const partialIbanBE = /^(BE[0-9]{0,13})$/i;
			if (partialIbanBE.test(filter.counterPartyAccountNumber)) {
				feedbacks.push(new Feedback('ibanFormatNotSupportedBE', FeedbackTypes.BACKEND_WARNING, 'Partial belgian IBAN format is not supported for the search'));
			}

			const fullIbanBE = /^(BE[0-9]{14})$/i;
			if (fullIbanBE.test(filter.counterPartyAccountNumber)) {
				params.push(new ParameterModel('operationCounterparty', filter.counterPartyAccountNumber));
			} else {
				params.push(new ParameterModel('operationCounterparty', filter.counterPartyAccountNumber + '%'));
			}
		}

		return feedbacks;
	}
}
