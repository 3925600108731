import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { mergeMap, map, toArray, catchError } from 'rxjs/operators';

import { ParameterModel } from '@mdib/http';
import { Contact, ContactsService } from '@mdib/customers';
import { SessionUtilsService } from '@mdib/sessions';
import { CacheService, Feedback, ServiceResponse } from '@mdib/utils';

import { MultigoalOrderXCLModel } from '@mdib-xcl/http';
import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';

import { XclAddress } from '../types/models/xcl-address';
import { ContactMapperXclService } from './contacts-mapper-xcl.service';

@Injectable({
	providedIn: 'root',
})
export class ContactsXclService extends ContactsService {

	constructor(
		private xclHttpService: XclHttpService,
		private contactMapperXclService: ContactMapperXclService,
		private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private cacheService: CacheService,
		private sessionUtilsService: SessionUtilsService,
	) {
		super();
	}

	validateUpdateContact(contacts: Contact[]): Observable<ServiceResponse<Contact[]>> {
		let feedbacks: Feedback[] = new Array<Feedback>();
		const buildResponse = (order: MonogoalOrderXCLModel<XclAddress>) => {
			feedbacks = feedbacks.concat(this.feedbackExtractor.extract(order));
			return this.contactMapperXclService.fromXclAddressGoalToContact(order);
		};
		return from(contacts).pipe(
			mergeMap(c => this.validationStream(c)),
			map(c => {
				return buildResponse(c);
			}),
			toArray(),
			map(c => new ServiceResponse<Contact[]>(c, feedbacks))
		);

	}

	get(): Observable<ServiceResponse<Contact[]>> {
		const params = <ParameterModel[]>[];
		params.push(new ParameterModel('thirdPartyNumber', this.sessionUtilsService.getSessionActiveUserPersonId()));

		return this.cacheService.generateCacheKeyFromParams('client-address', params),
			this.xclHttpService.execute('client-address', XclHttpService.GET, params).pipe(
				map((xclOrder: MultigoalOrderXCLModel<XclAddress>) => {
					return new ServiceResponse<Contact[]>(
						this.contactMapperXclService.fromXclAddressArrayToContactArray(xclOrder.goalList),
						this.feedbackExtractor.extract(xclOrder),
					);
				}),
				catchError(error => of(error))
			);
	}

	private validationStream(holderContact: Contact): Observable<MonogoalOrderXCLModel<XclAddress>> {
		const xclHolderContact: XclAddress = this.contactMapperXclService.fromContactToXclAddress(holderContact);

		let xclObservable: Observable<MonogoalOrderXCLModel<XclAddress>>;

		xclObservable = this.xclHttpService
			.execute('person-contact-init', XclHttpService.PUT, [<ParameterModel>{ par: 'identifier', val: xclHolderContact.identifier }]).pipe(
				mergeMap((order: MonogoalOrderXCLModel<XclAddress>) => {
					return this.xclHttpService.execute(
						'person-contact-retrieve',
						XclHttpService.PUT,
						[<ParameterModel>{ par: 'reference', val: order.reference }],
						order.goal,
					);
				}),
				mergeMap((order: MonogoalOrderXCLModel<XclAddress>) => {
					return this.xclHttpService.execute(
						'person-contact-validate',
						XclHttpService.PUT,
						[<ParameterModel>{ par: 'reference', val: order.reference }],
						this.updateGoalWithContact(order.goal, xclHolderContact),
					) as Observable<MonogoalOrderXCLModel<XclAddress>>;
				})
			);

		return xclObservable;
	}

	private updateGoalWithContact(goal: XclAddress, contact: XclAddress): XclAddress {
		goal.city = contact.city;
		goal.contactNumber = contact.contactNumber;
		goal.countryCode = contact.countryCode;
		goal.emailAddress = contact.emailAddress;
		goal.postalCode = contact.postalCode;
		goal.street = contact.street;
		goal.streetNumber = contact.streetNumber;
		return goal;
	}
}
