import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { ServiceResponse } from '@mdib/utils';
import { ModifyAccountNameOperation, CashAccount, ModifyAccountNameCommonService } from '@mdib/cash-accounts';

@Injectable({
	providedIn: 'root'
})
export class ModifyAccountNameMemoryService extends ModifyAccountNameCommonService {

	public update(cashAccount: CashAccount): Observable<ServiceResponse<ModifyAccountNameOperation | null>> {

		return of(new ServiceResponse<ModifyAccountNameOperation>(
			this.createModifyAccountNameOperation(cashAccount)
		)).pipe(delay(250));
	}

	/**
	 * Creates an instance of {@link ModifyAccountNameOperation} according to cash account provided
	 * @param {CashAccount} cashAccount account instance
	 * @return {ModifyAccountNameOperation}: instance of {@link ModifyAccountNameOperation}
	 */
	private createModifyAccountNameOperation(cashAccount: CashAccount): ModifyAccountNameOperation {
		const modifyAccountNameOperation = new ModifyAccountNameOperation();
		modifyAccountNameOperation.accountNumber = cashAccount.number;
		modifyAccountNameOperation.accountWording = cashAccount.clientWording;
		modifyAccountNameOperation.accountType = cashAccount.type;

		return modifyAccountNameOperation;
	}
}
