import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Signature, SignatureProcessComponent } from '@mdib/signature';
import { CommonValidator, Feedback, ServiceResponse } from '@mdib/utils';
import { PasswordProcessService } from '../../service/password-process.service';

@Component({
	selector: 'mdib-password-signature',
	templateUrl: './password-signature.component.html',
	styleUrls: ['./password-signature.component.scss']
})
export class PasswordSignatureComponent extends SignatureProcessComponent implements OnInit {

	readonly NAMESPACE = 'signatureModule.passwordSignatureComponent.';

	/**
	 * @property Hols the curent signature state
	 */
	signature: Signature = null;

	/**
	 * @property Indicates if the curent signature process is valid
	 */
	failed = false;

	/**
	 * @property Indicates if the signature is ready
	 */
	preparingSignature = true;

	/**
	 * @property Form containing password input by the user
	 */
	passwordSignatureFormGroup: FormGroup;

	/**
	 * Construct the component by injecting dependencies
	 *
	 * @param passwordProcessService Service required to process the signature
	 * @param formBuilder Form builder to build the passwordSignatureFormGroup
	 */

	capsLockKeyDown = <boolean> false;

	constructor(private _passwordProcessService: PasswordProcessService, private _formBuilder: FormBuilder) {
		super();
		this.passwordSignatureFormGroup = this._formBuilder.group({
			password: ['', CommonValidator.required]
		});
	}

	/**
	 * Setup the view and the PasswordProcessService
	 */
	ngOnInit() {
		// Reset view
		this.password = '';
		this.failed = false;

		// Prepare the signature
		this.passwordSignatureFormGroup.disable();
		this.preparingSignature = true;

		this._passwordProcessService.prepareSignatureFor(this.signable).subscribe(
			(wrappedSignature: ServiceResponse<Signature>) => {
				this.signature = wrappedSignature.getModel();
				this.passwordSignatureFormGroup.enable();
				this.preparingSignature = false;
				this.emitEvents(wrappedSignature);
			},
			(wrappedError: ServiceResponse<null>) => {
				this.passwordSignatureFormGroup.enable();
				this.preparingSignature = false;
				this.failed = true;
				this.emitEvents(wrappedError);
			}
		);
	}

	/**
	 * Calls the PasswordProcessService to create a signature.
	 */
	public sign() {
		this.failed = false;
		this.passwordSignatureFormGroup.disable();

		this._passwordProcessService.createSignatureFor(this.signable, this.password).subscribe(
			(wrappedSignature: ServiceResponse<Signature>) => {
				this.signable.addSignature(this.signature);
				this.onSuccess.emit(this.signable);
				this.emitEvents(wrappedSignature);
			},
			(wrappedError: ServiceResponse<Signature>) => {
				this.failed = true;
				this.emitEvents(wrappedError);
				this.passwordSignatureFormGroup.enable();
			}, () => this.passwordSignatureFormGroup.enable()
		);
	}

	/**
	 * Cancels the signature
	 */
	public cancel() {
		this.onEvent.emit(new CustomEvent('abort'));
	}

	@HostListener('document:keydown', ['$event'])
	onCapsLockKeyEvent(event: KeyboardEvent) {
		this.capsLockKeyDown = event.getModifierState && event.getModifierState('CapsLock');
	}

	private emitEvents(serviceResponse: ServiceResponse<any>) {
		serviceResponse.getFeedbacks().forEach(
			(feedback: Feedback) => {
				this.onEvent.emit(feedback);
			}
		);
	}

	get password(): string {
		return this.passwordSignatureFormGroup.get('password').value;
	}

	set password(password: string) {
		this.passwordSignatureFormGroup.patchValue({ 'password': password });
	}

	/**
	 * Return the adequate loading text according to the ongoing action.
	 */
	get loadingText(): string {
		return this.NAMESPACE + (this.preparingSignature ? 'preparingSignature' : 'signing');
	}


}
