import { NgModule } from '@angular/core';

import { PARAMETER_SETS, ParameterSetJsonLoader, ParameterSetYamlLoader } from '@mdib/core/customization';

@NgModule({
	providers: [
		{ provide: PARAMETER_SETS, multi: true, useValue: new ParameterSetJsonLoader('secret-questions', 'assets/configuration/parameters/secret-questions.json') },
		{ provide: PARAMETER_SETS, multi: true, useValue: new ParameterSetYamlLoader('cash-account-types', 'assets/configuration/parameters/cash-account-types.yaml') },
		{ provide: PARAMETER_SETS, multi: true, useValue: new ParameterSetYamlLoader('sepa-direct-debits-config', 'assets/configuration/parameters/sepa-direct-debits-config.yaml') },
		{ provide: PARAMETER_SETS, multi: true, useValue: new ParameterSetYamlLoader('sepa-direct-debits-frequencies', 'assets/configuration/parameters/sepa-direct-debits-frequencies.yaml') },
		{ provide: PARAMETER_SETS, multi: true, useValue: new ParameterSetYamlLoader('mailbox/conversation-types', 'assets/configuration/parameters/mailbox/conversation-types.yaml') },
		{ provide: PARAMETER_SETS, multi: true, useValue: new ParameterSetJsonLoader('xcl/authorizations', 'assets/configuration/security/authorizations-mapping.json', false) },
	]
})
export class AppCustomizationModule { }
