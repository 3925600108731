import { ModuleWithProviders, NgModule, Inject } from '@angular/core';
import { AdaptersService } from '@mdib/core/adapters';
import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { SepaDirectDebitContractsXclService } from './service/sepa-direct-debit-contracts-xcl.service';
import { SepaDirectDebitContractsService, SepaDirectDebitPaymentsService } from '@mdib/sepa-direct-debits';
import { SepaDirectDebitContractsMapperXclService } from './service/sepa-direct-debit-contracts-mapper-xcl.service';
import { SepaDirectDebitPaymentsMapperXclService } from './service/sepa-direct-debit-payments-mapper-xcl.service';
import { SepaDirectDebitPaymentsXclService } from './service/sepa-direct-debit-payments-xcl.service';

@NgModule()
export class XclSepaDirectDebitsModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(SepaDirectDebitContractsService, adapterId, SepaDirectDebitContractsXclService);
		adaptersService.setAdapter(SepaDirectDebitPaymentsService, adapterId, SepaDirectDebitPaymentsXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: XclSepaDirectDebitsModule,
			providers: [SepaDirectDebitContractsMapperXclService,
				SepaDirectDebitPaymentsMapperXclService]
		};
	}
}
