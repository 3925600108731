import { NgModule } from '@angular/core';

/******************************************************************************
 * WARNING : Pages should be Lazy-Loaded
 *   Only some core pages should really be package in the root module.
 *   For features pages, they should be lazy-loaded in the app-routing module.
 *****************************************************************************/

// MDIB Pages
import { ErrorsPagesModule } from '@mdib-pages/errors';
import { DashboardPagesModule } from '@mdib-pages/dashboard';
import { SessionsPagesModule } from '@mdib-pages/sessions';
import { AccountValuationsPagesModule } from '@mdib-pages/account-valuations';
import { StandingOrdersPagesModule } from '@mdib-pages/standing-orders';
import { AccountStatementsPagesModule } from '@mdib-pages/account-statements';

const pagesModules = [
	ErrorsPagesModule,
	DashboardPagesModule,
	SessionsPagesModule,
	AccountValuationsPagesModule,
	StandingOrdersPagesModule,
	AccountStatementsPagesModule,
];

@NgModule({
	imports: pagesModules,
	exports: pagesModules,
})
export class AppPagesImports { }
