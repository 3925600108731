import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConfigService } from '@mdib/config';
import { MailboxMessagesService } from '@mdib/mailbox';
import { ServiceResponse, UtilsHelper } from '@mdib/utils';
import { ShoppingBasketOperation, ShoppingBasketService } from '@mdib/shopping-basket';
import { SessionUtilsService } from '@mdib/sessions';
import { BusinessRolesSignAuthorizations } from '@mdib-xcl/sessions/model/sessions-types';
import { filter, map, tap } from 'rxjs/operators';

@Component({
	selector: 'mdib-usernotifications',
	templateUrl: './user-notifications.component.html',
	styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent implements OnInit, OnDestroy {

	readonly NAMESPACE = 'headerModule.userNotificationsComponent.';
	public unreadMessagesCount: number;
	public unsignedOperationsCount: number;

	private subscription: Subscription = new Subscription();

	constructor(
		private configService: ConfigService,
		private mailboxMessagesService: MailboxMessagesService,
		private shoppingBasketService: ShoppingBasketService,
		private sessionUtilsService: SessionUtilsService
	) { }

	ngOnInit(): void {
		const currentUser = this.sessionUtilsService.getSessionActiveUserId();
		this.subscription.add(this.mailboxMessagesService.unreadMessagesCounter().subscribe(
			(response: ServiceResponse<number>) => this.unreadMessagesCount = response.getModel()
		));

		this.shoppingBasketService.getOperationsToSign().pipe(
			filter((response) => !UtilsHelper.nullOrUndefined(response)), // When receiving null values, stop processing here
			map((response: ServiceResponse<ShoppingBasketOperation[]>) => response.getModel()),
			tap((operations: ShoppingBasketOperation[]) => {
				const myOperations = [];
				const otherOperations = [];
				operations.forEach(operation => {
					if (operation.encoderUserIdentifier === currentUser && !operation.isAlreadySignedByUser) {
						myOperations.push(operation);
					} else if (operation.countPastSignatures > 0 && !operation.isAlreadySignedByUser && this.authorizedToSign) {
						otherOperations.push(operation);
					}
				});
				this.unsignedOperationsCount = myOperations.length + otherOperations.length;
			})
		).subscribe();
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	// This is used to check whether the current logged in user is authorizd to sign any SB operations. If this is not the case, no operations can be signed and the counter for unsigned operations will be set to zero.
	get authorizedToSign(): boolean {
		for (let i = 0; i < BusinessRolesSignAuthorizations.allowed.length; i++) {
			const role = BusinessRolesSignAuthorizations.allowed[i];
			if (role === this.sessionUtilsService.getSessionActiveBusinessRoleId()) {
				return true;
			}
		}
		return false;
	}
}

