import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SecretQuestionsList } from '@mdib/customers';

import { BackendUpdateOperationXcl } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';

import { XclSecretQuestionsModel } from '../../types/models/xcl-secret-questions-model';
import { SecretQuestionsMapperXclService } from '../secret-questions-mapper-xcl.service';

export class SecretQuestionsListUpdateXcl extends BackendUpdateOperationXcl<SecretQuestionsList, SecretQuestionsList, XclSecretQuestionsModel> {

	protected xclEndpoint = 'xcl.secretQuestions.update';

	private secretQuestionsMapperXclService: SecretQuestionsMapperXclService;

	constructor(injector: Injector) {
		super(injector);
		this.secretQuestionsMapperXclService = injector.get(SecretQuestionsMapperXclService);
	}

	protected getItemIdentifier(input: SecretQuestionsList) {
		return this.sessionUtilsService.getSessionActiveUserId();
	}

	protected getValidateData(input: SecretQuestionsList): any {
		return this.secretQuestionsMapperXclService.getXclDataFromFunctionalData(input, this.sessionUtilsService.getSessionActiveUserId());
	}

	protected handleResponse(order: MonogoalOrderXCLModel<XclSecretQuestionsModel>): Observable<SecretQuestionsList> {
		return super.handleResponse(order).pipe(map(() => {
			return this.secretQuestionsMapperXclService.getFunctionalDataFromXclData(order);
		}));
	}

}
