import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { CashAccountService } from './cash-account.service';
import { CashAccountServiceStub } from './cash-account.service.stub';

@Injectable()
export class CashAccountServiceProxy extends AdapterProxy {
	protected ServiceToken() { return CashAccountService; }
	protected ServiceDefinition() { return CashAccountServiceStub; }
}
