export class XclKeyingLimitModel {
	maximumClientPaymentAmountPerDay: number;
	maximumClientPaymentAmountPerOperation: number;
	maximumClientPaymentAmountPerWeek: number;
	maximumClientPurchaseAmountForStockExchange: number;
	maximumPaymentAmountPerDay: number;
	maximumPaymentAmountPerOperation: number;
	maximumPaymentAmountPerWeek: number;
	maximumPurchaseAmountForStockExchange: number;
	principalIdentification: string;
	limitPrincipalIdentificationType: string;
	dailyStillAvailableLimit: number;
	weeklyStillAvailableLimit: number;
	lastTransferDate: string;
	firstDayWeek: string;
	partialTotalTransfersMadeThisWeek: number;
	identifier: string;
}
