import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationService } from '../../service/configuration.service';

@Component({
	selector: 'mdib-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {

	@Input() image: string;

	imageUrl: string;
	imageLink: string = null;

	constructor(
		private configurationService: ConfigurationService,
	) {
	}

	ngOnInit(): void {
		this.imageUrl = this.configurationService.instant('image.' + this.image + '.url');
		this.imageLink = this.configurationService.instant('image.' + this.image + '.link');
	}

}
