import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/**
 * Component used to select a required value.
 */
@Component({
	selector: 'type-selection',
	templateUrl: './type-selection.component.html',
})
export class TypeSelectionComponent {

	readonly NAMESPACE = 'pages.generic.typeSelection.';

	// TODO : see to correct this
	@Input() typeControl: Map<string, AbstractControl>;

	@Input() readonly isActionRequestOngoing: boolean;
	@Input() readonly typesLabel: string;
	@Input() readonly typesList: string[];
	@Input() readonly namespace: string;

	get control(): AbstractControl {
		return this.typeControl.get(this.controlName);
	}

	get controlName(): string {
		return this.typeControl.keys().next().value;
	}
}
