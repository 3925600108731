import { isNullOrUndefined, isUndefined } from 'util';
import { KeyingLimit } from '../model/keying-limit';
import { KeyingLimitTypes } from '@mdib/keying-limits';

export class KeyingLimitsBuilder {
	private _keyingLimit: KeyingLimit;

	constructor(keyingLimit?: KeyingLimit) {
		this._keyingLimit = isNullOrUndefined(keyingLimit) ? new KeyingLimit() : keyingLimit;
	}

	/**
	 * Initiates a builder based on an empty {@link KeyingLimit}
	 * @returns {KeyingLimit}
	 */
	static empty(): KeyingLimitsBuilder {
		return new KeyingLimitsBuilder();
	}

	/**
	 * Initiates a builder based on an already existing {@link KeyingLimit}.
	 * The {@link KeyingLimit} will be cloned.
	 *
	 * @param {KeyingLimit} keyingLimit
	 * @returns {KeyingLimitsBuilder}
	 */
	static from(keyingLimit: KeyingLimit): KeyingLimitsBuilder {
		return new KeyingLimitsBuilder(keyingLimit);
	}

	public limitType(value: KeyingLimitTypes): KeyingLimitsBuilder {
		if (!isUndefined(value)) {
			this._keyingLimit.limitType = value;
		}
		return this;
	}

	public keyingLimitPerOperation(value: number): KeyingLimitsBuilder {
		if (!isUndefined(value)) {
			this._keyingLimit.keyingLimitPerOperation = value;
		}

		return this;
	}

	public keyingLimitPerDay(value: number): KeyingLimitsBuilder {
		if (!isUndefined(value)) {
			this._keyingLimit.keyingLimitPerDay = value;
		}
		return this;
	}

	public keyingLimitPerWeek(value: number): KeyingLimitsBuilder {
		if (!isUndefined(value)) {
			this._keyingLimit.keyingLimitPerWeek = value;
		}
		return this;
	}

	public keyingLimitForStockExchange(value: number): KeyingLimitsBuilder {
		if (!isUndefined(value)) {
			this._keyingLimit.keyingLimitForStockExchange = value;
		}
		return this;
	}

	public dailyAvailableLimit(value: number): KeyingLimitsBuilder {
		if (!isUndefined(value)) {
			this._keyingLimit.dailyAvailableLimit = value;
		}
		return this;
	}

	public weeklyAvailableLimit(value: number): KeyingLimitsBuilder {
		if (!isUndefined(value)) {
			this._keyingLimit.weeklyAvailableLimit = value;
		}
		return this;
	}

	get keyingLimit(): KeyingLimit {
		return this._keyingLimit;
	}
}
