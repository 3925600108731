import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Signature, SignatureProcessComponent } from '@mdib/signature';
import { CommonValidator, Feedback, ServiceResponse } from '@mdib/utils';

import { OtpSignatureService } from '../../service/otp-signature.service';

@Component({
	selector: 'mdib-otp-signature',
	templateUrl: './otp-signature.component.html',
	styleUrls: ['./otp-signature.component.scss'],
})
export class OtpSignatureComponent extends SignatureProcessComponent implements OnInit {

	readonly NAMESPACE = 'signatureModule.otpSignatureComponent.';

	/**
	 * @property Hols the curent signature state
	 */
	public signature: Signature = null;

	/**
	 * @property Indicates if the curent signature process is valid
	 */
	public failed = false;

	/**
	 * @property Indicates if the signature is ready
	 */
	preparingSignature = true;

	/**
	 * @property Form containing the token input by the user
	 */
	otpSignatureFormGroup: FormGroup;

	/**
	 * Construct the component by injecting dependencies
	 *
	 * @param otpSignatureService Service required to process the signature
	 * @param formBuilder Form builder to build the otpSignatureFormGroup
	 */
	constructor(private _otpSignatureService: OtpSignatureService,
				private _formBuilder: FormBuilder) {
		super();
		this.otpSignatureFormGroup = this._formBuilder.group({
			otp: ['', CommonValidator.required],
		});
	}

	ngOnInit() {
		// Reset view
		this.failed = false;

		// Prepare the signature
		this.otpSignatureFormGroup.disable();
		this.preparingSignature = true;

		this._otpSignatureService.prepareSignatureFor(this.signable).subscribe(
			(wrappedSignature: ServiceResponse<Signature>) => {
				this.signature = wrappedSignature.getModel();
				this.otpSignatureFormGroup.enable();
				this.preparingSignature = false;
				this.emitEvents(wrappedSignature);
			},
			(wrappedError: ServiceResponse<null>) => {
				this.otpSignatureFormGroup.enable();
				this.preparingSignature = false;
				this.failed = true;
				this.emitEvents(wrappedError);
			}, () => this.otpSignatureFormGroup.enable()
		);
	}

	/**
	 * Calls the PasswordProcessService to create a signature.
	 *
	 * @param password The password to use for the signature
	 */
	public sign() {
		this.failed = false;
		this.otpSignatureFormGroup.disable();

		this._otpSignatureService.createSignatureFor(this.signable, this.otp).subscribe(
			(wrappedSignature: ServiceResponse<Signature>) => {
				this.signable.addSignature(this.signature);
				this.onSuccess.emit(this.signable);
				this.emitEvents(wrappedSignature);
			},
			(wrappedError: ServiceResponse<Signature>) => {
				this.failed = true;
				this.emitEvents(wrappedError);
				this.otpSignatureFormGroup.enable();
			},
		);
	}

	/**
	 * Cancels the signature
	 */
	public cancel() {
		this.onEvent.emit(new Event('abort'));
	}

	private emitEvents(serviceResponse: ServiceResponse<any>) {
		serviceResponse.getFeedbacks().forEach(
			(feedback: Feedback) => {
				this.onEvent.emit(feedback);
			},
		);
	}

	get otp(): string {
		return this.otpSignatureFormGroup.get('otp').value;
	}

	set otp(otp: string) {
		this.otpSignatureFormGroup.patchValue({ 'otp': otp });
	}

	/**
	 * Return the adequate loading text according to the ongoing action.
	 */
	get loadingText(): string {
		return this.NAMESPACE + (this.preparingSignature ? 'preparingSignature' : 'signing');
	}
}
