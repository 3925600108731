import { Injectable } from '@angular/core';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { SepaDirectDebitPayment } from '../model/sepa-direct-debit-payment';
import { SepaDirectDebitContract } from '../model/sepa-direct-debit-contract';
import { BackendService } from '@mdib/core/adapters';

/**
 * Abstracts the calls related to {@link SepaDirectDebitPayment}
 */
@Injectable()
export abstract class SepaDirectDebitPaymentsService extends BackendService<SepaDirectDebitPayment> {
	// FIXME - To be removed and included in search operation
	/**
	 * Retrieves the list of sepa direct debit payments
	 * @param {SepaDirectDebitContract} [sepaDirectDebitContract] sepa direct debit contract
	 * @param {number} [offset] Offset to start from (by default 0, i.e. from the beginning)
	 * @param {number} [limit] Maximum number of operations to retrieve (by default 0, i.e. no limit)
	 * @returns {Observable<ServiceResponse<SepaDirectDebitPayment[]>>} List of sepa direct debit payments
	 * @memberof SepaDirectDebitPaymentsService
	 */
	public abstract list(sepaDirectDebitContract: SepaDirectDebitContract, index?: number, count?: number): Observable<ServiceResponse<SepaDirectDebitPayment[]>>;

	/**
	 * Counts the list of sepa direct debit payments on the basis of given input
	 * @param {SepaDirectDebitContract} [sepaDirectDebitContract] sepa direct debit contract
	 * @memberof SepaDirectDebitPaymentsService
	 */
	public abstract count(sepaDirectDebitContract: SepaDirectDebitContract): Observable<ServiceResponse<number>>;
}
