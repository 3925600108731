import { Injectable } from '@angular/core';
import { SignatureModes } from '@mdib/signature-modes';
import { PendingPaymentsMock } from '../mock/pending-payments-mock';
import { MockShoppingBasketOperation } from '../../shopping-basket/service/mockdata/mock-shopping-basket-operation';
import { InstantPaymentsService, PaymentOperation } from '@mdib/payments';
import { ServiceResponse, Status } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { SignableOperation } from '@mdib/signature';

@Injectable({
	providedIn: 'root',
})
export class InstantPaymentsMemoryService extends InstantPaymentsService {

	readonly pendingPayments = PendingPaymentsMock.pendingPayments;
	readonly draftPayments = MockShoppingBasketOperation.getMockShoppingBasketOperations();
	readonly DEFAULT_DELAY = 400;

	confirm(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null> | null> {
		payment.status === Status.ToConfirm ? payment.status = Status.Closed : payment.status = Status.InShoppingBasket;
		return of(new ServiceResponse<PaymentOperation<null>>(payment));
	}

	sign(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>> {
		payment.status = Status.Closed;
		return of(new ServiceResponse<PaymentOperation<null>>(payment));
	}

	validate(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>> {
		payment.amount < 100 ? payment.status = Status.ToConfirm : payment.status = Status.ToSign;
		payment.signatureTypesAllowed = [SignatureModes.PASSWORD];
		payment.paymentDate = payment.paymentDate || new Date();
		return of(new ServiceResponse<PaymentOperation<null>>(payment));
	}

	public getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<null>>> {
		return of(new ServiceResponse<PaymentOperation<null>>());
	}

	get(reference: string): Observable<ServiceResponse<PaymentOperation<null> | null >> {
		return of();
	}

	delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {
		return of();
	}


	confirmDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {
		return of();
	}

	signDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {
		return of();
	}
}
