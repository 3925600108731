import { NgModule, ModuleWithProviders } from '@angular/core';

import { ForgotPasswordService } from './service/forgot-password.service';
import { ForgotPasswordServiceProxy } from './service/forgot-password.service.proxy';

@NgModule()
export class ForgotPasswordModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: ForgotPasswordModule,
			providers: [
				{ provide: ForgotPasswordService, useClass: ForgotPasswordServiceProxy },
			]
		};
	}
}
