import { Observable, of } from 'rxjs';
import { CashAccount } from '../model/cash-account';
import { CashAccountTypesGroup } from '@mdib/cash-accounts';

// TODO : this class should move (in it's own file) after if => we find ability to pass multiple dynamic parameter to asynchronous validators, 2 fields could be then used for and method name changed
export class WithdrawalSimulation {
	account: WithdrawalSimulationAccount;
	date: Date;
	amount: number;
	result?: number;
	document?: Blob;
	documentName?: string;
	currency?: string;

	// todo: change typing and method according to previous comment
	public isAmountSuperiorToAvailableBalance(): Observable<any> {
		return of({superior: this.amount > (this.account && this.account.availableBalance), value: this.account && this.account.availableBalance});
	}

	public resetSimulationResult() {
		this.result = null;
		this.documentName = null;
		this.document = null;
	}
}

export type WithdrawalSimulationAccount = Pick<CashAccount, 'valuationCurrency' | 'number' | 'availableBalance'>;

export interface CashAccountTypesParam {
	key: string;
	backendMapping: string;
	cashAccountTypesGroup: CashAccountTypesGroup;
}
