import { isUndefined } from 'util';
import { XclCashAccountCreationModel } from './xcl-cash-account-creation';

export class XclCashAccountCreationBuilder {
	private _xclCashAccountCreation: XclCashAccountCreationModel;

	constructor(xclCashAccountCreation?: XclCashAccountCreationModel) {
		this._xclCashAccountCreation = new XclCashAccountCreationModel();
		if (xclCashAccountCreation) {
			this.accountNumber(xclCashAccountCreation.accountNumber)
				.cashAccountHolderIdentity(xclCashAccountCreation.cashAccountHolderIdentity)
				.bankProduct(xclCashAccountCreation.bankProduct)
				.accountCurrency(xclCashAccountCreation.accountCurrency)
				.accountTitle(xclCashAccountCreation.accountTitle)
				.portfolio(xclCashAccountCreation.portfolio)
				.usTaxResidenceCode(xclCashAccountCreation.usTaxResidenceCode);
		}
	}

	static empty(): XclCashAccountCreationBuilder {
		return new XclCashAccountCreationBuilder();
	}

	static from(xclCashAccountCreation: XclCashAccountCreationModel): XclCashAccountCreationBuilder {
		return new XclCashAccountCreationBuilder(xclCashAccountCreation);
	}

	public accountNumber(value: string) {
		if (!isUndefined(value)) {
			this._xclCashAccountCreation.accountNumber = value;
		}
		return this;
	}

	public cashAccountHolderIdentity(value: string) {
		if (!isUndefined(value)) {
			this._xclCashAccountCreation.cashAccountHolderIdentity = value;
		}
		return this;
	}

	public bankProduct(value: string) {
		if (!isUndefined(value)) {
			this._xclCashAccountCreation.bankProduct = value;
		}
		return this;
	}

	public accountCurrency(value: string) {
		if (!isUndefined(value)) {
			this._xclCashAccountCreation.accountCurrency = value;
		}
		return this;
	}

	public accountTitle(value: string) {
		if (!isUndefined(value)) {
			this._xclCashAccountCreation.accountTitle = value;
		}
		return this;
	}

	public portfolio(value: string) {
		if (!isUndefined(value)) {
			this._xclCashAccountCreation.portfolio = value;
		}
		return this;
	}

	public usTaxResidenceCode(value: string) {
		if (!isUndefined(value)) {
			this._xclCashAccountCreation.usTaxResidenceCode = value;
		}
		return this;
	}

	public get xclCashAccountCreation() {
		return this._xclCashAccountCreation;
	}
}
