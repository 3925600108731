import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BankConfig, CurrenciesSpecificDecimals } from '@mdib/config';
import { printFormat } from 'iban';

import { UtilsHelper } from '../helper/utils-helper';

@Injectable()
export class DataFormattingService {

	readonly europeanCommunicationMaxLength = 25;

	// TODO : finish to test, document, clean and review this service when we have time
	/**
	 * Gives the decimal mark according to the Base Localisation
	 * @return string
	 */
	get decimalMark(): string {
		const decimalMark = new DecimalPipe(BankConfig.baseLocale);
		return decimalMark.transform(1, '1.1') === '1.0' ? '.' : ',';
	}

	/**
	 * converts the amount (dot replaced by base localisation mark)
	 * @param string
	 * @return string
	 */
	formatAmount(amount: string | number): string {
		return amount && (typeof amount === 'number' || typeof amount === 'string' ) ? ('' + amount).replace('.', this.decimalMark) : '';
	}

	amountToViewFormat(amount: string | number, currency?: string ): string {
		const cleanAmount =  typeof amount === 'string' ? this.parseAmount(amount + '') : amount;
		return UtilsHelper.nullOrUndefined(cleanAmount)  ? '' : this.applyDecimalMark(cleanAmount.toFixed(CurrenciesSpecificDecimals[currency] || BankConfig.decimals));
	}

	/**
	 * Replace dot by base localisation mark
	 * @param string: amount
	 * @return string: amount with base localisation mark, 5.1 => 5,1
	 * @example : dataFormat.applyDecimalMark(5.1) or dataFormat.applyDecimalMark('5.1')
	 */
	applyDecimalMark(amount: string | number): string {
		return UtilsHelper.nullOrUndefined(amount) || amount === '.' || amount === ',' ? '' : ('' + amount).replace('.', this.decimalMark);
	}

	/**
	 * converts the amount (base localisation mark remplaced by dot)
	 * will be use to give a amount for the model
	 * @param string
	 * @return number
	 */
	parseAmount(amount: string): number | null {
		if (amount === ',' || amount === '.') {
			amount = '';
		}
		return amount ? parseFloat(amount.replace(',', '.')) : null;
	}

	get amountPlaceholder(): string {
		return `0${this.decimalMark}00`;
	}

	/**
	 * parses an iban account number and returns only the chars (in upper case) and digits.
	 * @param string
	 * @returns string
	 */
	parseIban(account: string): string | null {
		return account ? account.toUpperCase().replace(/[^0-9A-Z]/gi, '') : null;
	}

	/**
	 *  Removes any characters which are not numbers or letters from an alphanumeric string
	 * @param {string} value: a string that could hold alphanumeric value
	 * @returns {string}: string with only numbers and letters
	 */
	alphaToNumberAndLetters(value: string): string {
		return value ? value.replace(/[^0-9A-Z]/gi, '') : '';
	}

	/**
	 * Returns a formatted iban account number
	 * @param {string} value
	 * @returns {string}
	 */
	formatIban(value: string): string {
		const toFormat = UtilsHelper.onlyAlpha(value);
		try {
			return printFormat(toFormat, ' ');
		} catch (e) {
			return null;
		}
		return null;
	}

	/**
	 * Cleans input and return structured communication with a display format
	 * @param {string} value: should be a valid structured communication
	 * @returns {string}: a structured communication which formatting is adapted for display
	 */
	formatStructured(value: string): string {
		const cleaned = UtilsHelper.alphaToNumber(value);
		return cleaned ? '+++'.concat(value.substr(0, 3)).concat('/').concat(value.substr(3, 4))
			.concat('/').concat(value.substr(7, 5)).concat('+++') : '';
	}

	/**
	 * returns a formatted european communication
	 * @param {string} value: a valid european communication
	 * @returns {string}
	 */
	formatEuropean(value: string): string {
		let formattedReference = 'RF';
		formattedReference = formattedReference.concat(value.charAt(0).replace(/[^\d]/g, ''));
		formattedReference = formattedReference.concat(value.charAt(1).replace(/[^\d]/g, ''));

		for (let i = 2; i < value.length && i < this.europeanCommunicationMaxLength - 2; i++) {
			formattedReference = formattedReference.concat(value.charAt(i));
		}
		return formattedReference;
	}
}
