import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConfigService } from './service/config.service';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
	],
})
export class ConfigModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: ConfigModule,
			providers: [
				ConfigService,
			],
		};
	}
}
