import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { FileUploadersXclService } from '@mdib-xcl/file-uploaders';
import { AdaptersService } from '@mdib/core/adapters';
import { PaymentFilesService } from '@mdib/payment-files';
import { PaymentFilesMapperXclService } from './service/payment-files-mapper-xcl.service';
import { PaymentFilesXclService } from './service/payment-files-xcl.service';

@NgModule()
export class PaymentFilesXclModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(PaymentFilesService, adapterId, PaymentFilesXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: PaymentFilesXclModule,
			providers: [
				PaymentFilesMapperXclService,
				FileUploadersXclService
			]
		};
	}
}
