import { Observable, of, throwError } from 'rxjs';

import { BackendOperation, BackendOperationStep, BackendOperationType, BackendOperationState } from '@mdib/core/adapters';
import { SignatureModes } from '@mdib/signature-modes';

export class DeleteOperationMemory<T> extends BackendOperation<string, T> {

	private defaultTrigger = 'delete';

	constructor(
		protected datas: Array<T> = [],
		protected indexKey: string = null,
	) {
		super(BackendOperationType.DELETE);
		this.curentStep = new BackendOperationStep(null, ['delete']);
	}

	public execute(input: string, trigger?: string): Observable<BackendOperationStep<T>> {
		switch (trigger || this.defaultTrigger) {
			case 'cancel':
				return this.cancel(input, trigger || this.defaultTrigger);
			case 'delete':
				return this.delete(input, trigger || this.defaultTrigger);
			case 'confirm':
			case 'save':
			case 'sign':
				return this.done(input, trigger || this.defaultTrigger);
			default:
				return this.error(new Error('The trigger ' + trigger + ' does not exist'));
		}
	}

	protected error(error: Error): Observable<BackendOperationStep<T>> {
		this.state = BackendOperationState.FAILED;
		this.stepSubject.error(error);
		return throwError(error);
	}

	protected cancel(input: string, trigger?: string): Observable<BackendOperationStep<T>> {
		this.state = BackendOperationState.CANCELLED;
		this.defaultTrigger = null;
		this.curentStep = new BackendOperationStep<T>(trigger, []);
		this.stepSubject.next(this.curentStep);
		return of(this.curentStep);
	}

	protected delete(input: string, trigger?: string): Observable<BackendOperationStep<T>> {
		this.allowedSignatures = [SignatureModes.PASSWORD];
		this.state = BackendOperationState.WAITING_ACTION;
		this.defaultTrigger = 'sign';
		this.curentStep = new BackendOperationStep(trigger, ['cancel', 'sign', 'save']);
		this.stepSubject.next(this.curentStep);
		return of(this.curentStep);
	}

	protected done(input: string, trigger?: string): Observable<BackendOperationStep<T>> {
		// Delete the item from the datas
		if (this.indexKey) {
			const itemIndex = this.datas.findIndex(d => d[this.indexKey] === input);
			this.datas.splice(itemIndex, 1);
		}

		// Operation done
		this.state = BackendOperationState.SUCCEEDED;
		this.defaultTrigger = '';
		this.curentStep = new BackendOperationStep(trigger);
		this.stepSubject.next(this.curentStep);
		return of(this.curentStep);
	}
}
