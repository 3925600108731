import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { AccountStatement, AccountStatementsCommonService, AccountStatementsPageFilter, AccountStatementStatus } from '@mdib/account-statements';
import { MdibFile, ServiceResponse } from '@mdib/utils';

import { MockAccountStatements } from '../mock/mock-account-statements';

@Injectable({
	providedIn: 'root',
})
export class AccountStatementsMemoryService extends AccountStatementsCommonService {

	readonly DEFAULT_DELAY = 400;

	public list(index?: number, count?: number, filter?: AccountStatementsPageFilter): Observable<ServiceResponse<AccountStatement[]>> {
		const results = this.getFilteredAccountStatementsList(filter);
		return of(new ServiceResponse<AccountStatement[]>(
			results.slice(index || 0, (index + count) || undefined),
			[],
			{ totalCount: results.length }
		)).pipe(delay(this.DEFAULT_DELAY));
	}

	public count(filter?: AccountStatementsPageFilter): Observable<ServiceResponse<number>> {
		return of(new ServiceResponse<number>(
			filter ? this.getFilteredAccountStatementsList(filter).length : MockAccountStatements.getMockAccountStatements().length)).pipe(delay(this.DEFAULT_DELAY));
	}

	public get(name: string): Observable<ServiceResponse<MdibFile>> {
		return of(new ServiceResponse<MdibFile>(
			MockAccountStatements.getMockAccountStatements().find((statement) => statement.file.name === name).file),
		).pipe(delay(this.DEFAULT_DELAY));
	}

	public update(accountStatement: AccountStatement): Observable<ServiceResponse<AccountStatement>> {
		const update = MockAccountStatements.getMockAccountStatements().find((statement) => statement.identifier === accountStatement.identifier);
		update.status = AccountStatementStatus.Read;
		return of(new ServiceResponse<AccountStatement>(update)).pipe(delay(this.DEFAULT_DELAY));
	}

	getRepresentedClientNumber(): Observable<ServiceResponse<string>> {
		return of((new ServiceResponse<string>('mockb2b')));
	}

	private getFilteredAccountStatementsList(filter: AccountStatementsPageFilter): AccountStatement[] {
		return MockAccountStatements.getMockAccountStatements().filter((accountStatement: AccountStatement) => {
			if (!filter) { return true; }
			if (filter.fileFormat && accountStatement.file.format !== filter.fileFormat) {
				return false;
			}
			if (filter.cashAccount && !accountStatement.accountNumber.startsWith(filter.cashAccount.number)) {
				return false;
			}
			if (filter.status === AccountStatementStatus.New || filter.status === AccountStatementStatus.Read) {
				if (filter.status && accountStatement.status !== filter.status) {
					return false;
				}
			}
			if (filter.accountingDateFrom && accountStatement.accountingStartDate < filter.accountingDateFrom) {
				return false;
			}
			if (filter.accountingDateTo && accountStatement.accountingStartDate > filter.accountingDateTo) {
				return false;
			}
			if (filter.statementNumberFrom && accountStatement.statementNumber < filter.statementNumberFrom) {
				return false;
			}
			if (filter.statementNumberTo && accountStatement.statementNumber > filter.statementNumberTo) {
				return false;
			}
			if (filter.statementYear && accountStatement.statementNumberYear !== filter.statementYear) {
				return false;
			}
			return true;
		});
	}

	private getFilteredAccountStatementsListCropped(start: number, end: number, filter: AccountStatementsPageFilter): AccountStatement[] {
		return this.getFilteredAccountStatementsList(filter).slice(start, end);
	}
}
