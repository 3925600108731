import { InjectionToken } from '@angular/core';

// Types
export { XclSignatureContext } from './signatures/types/xcl-signature-context';
export { XclSignatureModes } from './signatures/types/xcl-signature-modes';

export { DecisionTree } from './adapters/types/decision-tree';
export { BackendOperationXcl } from './adapters/types/backend-operation-xcl';
export { XclCallConfiguration, XclCallConfigurationType } from './adapters/types/xcl-call-configuration';
export { SearchOperatorsXcl, SearchCriteriaXcl, XclFieldMapper } from './adapters/criteria/search-operators-xcl';

// Types > Mappers
export { MapperPartialService } from './adapters/types/mapper-partial.service';

// Types > Operations > CRUDS
export { BackendCreationOperationXcl } from './adapters/types/backend-creation-operation-xcl';
export { BackendRetrieveOperationXcl } from './adapters/types/backend-retrieve-operation-xcl';
export { BackendUpdateOperationXcl } from './adapters/types/backend-update-operation-xcl';
export { BackendDeletionOperationXcl } from './adapters/types/backend-deletion-operation-xcl';
export { BackendSearchOperationXcl } from './adapters/types/backend-search-operation-xcl';

// Tokens
export const XCL_ADAPTER_IDENTIFIER = new InjectionToken<string>('XCL_ADAPTER_IDENTIFIER');
