import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StylingModule } from '@mdib/styling';
import { UtilsModule } from '@mdib/utils';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { ListPageComponent } from './component/list-page/list-page.component';
import { ModalDialogComponent } from './component/modal-dialog/modal-dialog.component';
import { TermsAndConditionsSectionComponent } from './component/terms-and-conditions-section/terms-and-conditions-section.component';
import { TypeSelectionComponent } from './component/type-selection/type-selection.component';
import { YearSelectionComponent } from './component/year-selection/year-selection.component';

/**
 * Holds a set of front end tools related to banking applications.
 */
@NgModule({
	imports: [
		CommonModule,
		UtilsModule,
		StylingModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		PdfViewerModule,
	],
	declarations: [
		ModalDialogComponent,
		ListPageComponent,
		TermsAndConditionsSectionComponent,
		TypeSelectionComponent,
		YearSelectionComponent,
	],
	exports: [
		ModalDialogComponent,
		ListPageComponent,
		TermsAndConditionsSectionComponent,
		TypeSelectionComponent,
		YearSelectionComponent,
	],
	entryComponents: [
		ModalDialogComponent,
	],
})
export class GenericPagesModule {}
