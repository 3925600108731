import { Component, Input } from '@angular/core';
import { Status } from '@mdib/utils';

@Component({
	selector: 'mdib-transaction-status',
	templateUrl: './transaction-status.component.html',
	styleUrls: ['./transaction-status.component.scss']
})
export class TransactionStatusComponent {

	@Input() paymentStatus: Status;
	Status = Status;

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.confirmationStepComponent.transactionStatusComponent.';
}
