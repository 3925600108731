import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { HashAlgorithms, PaymentFile, PaymentFileFailure, PaymentFilesCommonService, PaymentFilesFilter, PaymentFileStatus } from '@mdib/payment-files';
import { SignableOperation } from '@mdib/signature';
import { SignatureModes } from '@mdib/signature-modes';
import { ServiceResponse, Status } from '@mdib/utils';

import { MockPaymentFiles } from '../mock/mock-payment-files';

@Injectable({
	providedIn: 'root',
})
export class PaymentFilesMemoryService extends PaymentFilesCommonService {

	readonly DEFAULT_DELAY = 400;
	private paymentFilesList: PaymentFile[];

	constructor() {
		super();
		this.paymentFilesList = MockPaymentFiles.getPaymentFilesList();
	}

	count(filter?: PaymentFilesFilter): Observable<ServiceResponse<number>> {
		return of(new ServiceResponse<number>(
			filter ? this.getFilteredPaymentFilesList(filter).length : this.paymentFilesList.length)).pipe(delay(this.DEFAULT_DELAY));
	}

	list(index: number, count: number, filter?: PaymentFilesFilter): Observable<ServiceResponse<PaymentFile[]>> {
		const results = this.getFilteredPaymentFilesList(filter);
		return of(new ServiceResponse(
			results.slice(index || 0, (index + count) || undefined),
			[],
			{ totalCount: results.length },
		)).pipe(delay(this.DEFAULT_DELAY));
	}

	confirm(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		return of(new ServiceResponse<PaymentFile>(paymentFile)).pipe(delay(this.DEFAULT_DELAY));
	}

	validate(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		paymentFile.hashAlgorithm === HashAlgorithms.sha1 ? paymentFile.hashValue = '56857cfc709d3996f057252c16ec46fG' : paymentFile.hashValue = '578957cfc709d3996f057252c16ec46fG56857cfc709d3996f057252c16ec46fG';
		return of(new ServiceResponse<PaymentFile>(paymentFile)).pipe(delay(this.DEFAULT_DELAY));
	}

	get(fileId: string): Observable<ServiceResponse<PaymentFile>> {
		return of(new ServiceResponse<PaymentFile>(
			this.paymentFilesList.find((paymentFile: PaymentFile) => paymentFile.identifier === fileId)),
		).pipe(delay(this.DEFAULT_DELAY));
	}

	process(fileId: string): Observable<ServiceResponse<SignableOperation<PaymentFile>>> {
		const signable: SignableOperation<PaymentFile> = new SignableOperation<PaymentFile>(this.paymentFilesList.find((paymentFile: PaymentFile) => paymentFile.identifier === fileId));
		signable.signatureTypesAllowed = [SignatureModes.PASSWORD];
		signable.status = Status.ToSign;
		signable.signatureContext = 'B8G30CWLIZ02ETA4';
		return of(new ServiceResponse<SignableOperation<PaymentFile>>(signable)).pipe(delay(this.DEFAULT_DELAY));
	}

	confirmProcess(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		paymentFile.status = Status.InShoppingBasket;
		return of(new ServiceResponse<PaymentFile>(paymentFile)).pipe(delay(this.DEFAULT_DELAY));
	}

	signProcess(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		paymentFile.status = Status.Closed;
		paymentFile.fileStatus = PaymentFileStatus.signed;

		const result = this.findPaymentFile(paymentFile.identifier);
		if (result.paymentFile) {
			this.paymentFilesList.splice(result.id, 1, paymentFile);
		}

		return of(new ServiceResponse<PaymentFile>(paymentFile)).pipe(delay(this.DEFAULT_DELAY));
	}

	delete(fileId: string): Observable<ServiceResponse<SignableOperation<PaymentFile>>> {
		let paymentFile;
		paymentFile = this.paymentFilesList.filter(paymenFile => paymenFile.identifier === fileId).pop();

		const signableOperation = new SignableOperation<PaymentFile>(paymentFile);
		signableOperation.status = Status.ToSign;
		signableOperation.signatureTypesAllowed = [SignatureModes.PASSWORD];
		return of(new ServiceResponse<SignableOperation<PaymentFile>>(signableOperation)).pipe(delay(100));

	}

	public signDelete(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>> {
		paymentFile.status = Status.Closed;
		paymentFile.fileStatus = PaymentFileStatus.rejectedByUser;
		return of(new ServiceResponse<PaymentFile>(paymentFile)).pipe(delay(this.DEFAULT_DELAY));
	}

	listFailures(sequenceNumber: string, pageNumber?: number, pageSize?: number): Observable<ServiceResponse<PaymentFileFailure[]>> {
		let start, end;
		if (pageNumber && pageSize) {
			start = pageNumber * pageSize;
			end = start + pageSize;
		}
		return of(new ServiceResponse<PaymentFileFailure[]>(
			MockPaymentFiles.getFailuresList()
				.filter((failure: PaymentFileFailure) => { return failure.sequenceNumber === sequenceNumber ? true : false; })
				.slice(start, end),
		),
		).pipe(delay(this.DEFAULT_DELAY));
	}

	private getFilteredPaymentFilesList(filter: PaymentFilesFilter): PaymentFile[] {
		return this.paymentFilesList.filter((paymentFile: PaymentFile) => {
			if (!filter) { return true; }
			if ((filter.uploadDateFrom && paymentFile.uploadDate < filter.uploadDateFrom) ||
				(filter.uploadDateTo && paymentFile.uploadDate > filter.uploadDateTo) ||
				(filter.type && paymentFile.type !== filter.type) ||
				(filter.fileStatus && paymentFile.fileStatus !== filter.fileStatus)
			) {
				return false;
			}
			return true;
		});
	}

	private findPaymentFile(id: string) {
		const result = { id: null, paymentFile: null };
		result.paymentFile = this.paymentFilesList.find(
			(msg, i, list) => {
				if (msg.identifier === id) {
					result.id = i;
					return true;
				} else {
					return false;
				}
			},
		);
		return result;
	}
}
