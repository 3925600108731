import { SepaDirectDebitPaymentStatus } from '@mdib/sepa-direct-debits';

export class XclSepaDirectDebitPaymentStatus {
	private static mapper = {
		'1000': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'1500': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'1600': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'1601': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'1800': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'1809': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'1950': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'2000': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'2500': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'2600': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'2609': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'2700': SepaDirectDebitPaymentStatus.AwaitingRejection,
		'2850': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'2900': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'3100': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'3150': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'3600': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'3900': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'4100': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'6000': SepaDirectDebitPaymentStatus.AwaitingSettlement,
		'6009': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'6200': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'6300': SepaDirectDebitPaymentStatus.AwaitingRejection,
		'6600': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'6609': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'6700': SepaDirectDebitPaymentStatus.AwaitingRejection,
		'7000': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'7600': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'9001': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'9002': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'9901': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'9902': SepaDirectDebitPaymentStatus.AwaitingProcessing,
		'9000': SepaDirectDebitPaymentStatus.Executed,
		'9900': SepaDirectDebitPaymentStatus.Cancelled,
		'9910': SepaDirectDebitPaymentStatus.CancelledByRejection,
		'9920': SepaDirectDebitPaymentStatus.CancelledByReturn,
		'991A': SepaDirectDebitPaymentStatus.SubjectToRejection,
		'9930': SepaDirectDebitPaymentStatus.CancelledByCancellationRequest,
		'9950': SepaDirectDebitPaymentStatus.CancelledForNotAuthorisedTransfer,
		'9940': SepaDirectDebitPaymentStatus.CancelledByRecovery,
		'992A': SepaDirectDebitPaymentStatus.SubjectToReturn,
		'993A': SepaDirectDebitPaymentStatus.SubjectToCancel,
		'994A': SepaDirectDebitPaymentStatus.SubjectToRefund,
		'995A': SepaDirectDebitPaymentStatus.SubjectToNotAuthorisedTransfer,
		'9960': SepaDirectDebitPaymentStatus.CancelledByRepayment,
		'996A': SepaDirectDebitPaymentStatus.SubjectToRepayment
	};

	/**
	 * Converts XCL sepa direct debit payment status to functional sepa direct debit payment status
	 * @param {string} [type] XCL sepa direct debit payment status
	 * @returns {SepaDirectDebitPaymentStatus} sepa direct debit payment status
	 * @memberof XclSepaDirectDebitPaymentStatus
	 */
	public static fromXclType(type: string): SepaDirectDebitPaymentStatus {
		return XclSepaDirectDebitPaymentStatus.mapper[type];
	}
}
