export enum XclPaymentTypes {
	unknown = 'unknown',
	internalPayment = 'internalPayment',
	sepaPayment = 'sepaPayment',
	nationalPayment = 'nationalPayment',
	internationalPayment = 'internationalPayment',
	sepaCreditTransfer = 'sepaCreditTransfer',
	sepaDirectDebit = 'sepaDirectDebit',
	germanPayment = 'germanPayment',
	remittance = 'remittance',
	nationalStandingOrder = 'nationalStandingOrder',
	internationalStandingOrder = 'internationalStandingOrder',
	europeanStandingOrder = 'europeanStandingOrder',
	moneyMoverStandingOrder = 'moneyMoverStandingOrder',
	instantPayment = 'instantPayment',
	urgentPayment = 'urgentPayment'
}
