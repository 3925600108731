import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ArtificialPerson, NaturalPerson, PersonMandate, PersonsService } from '@mdib/customers';
import { ParameterModel } from '@mdib/http';
import { SessionUtilsService } from '@mdib/sessions';
import { CacheService, ServiceResponse, ConfigurationService } from '@mdib/utils';

import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, MultigoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';

import { XclRepresentedClientModel } from '../types/models/xcl-represented-client-model';
import { XclClientModel } from '../types/models/xcl-client-model';
import { PersonsMapperXclService } from './persons-mapper-xcl.service';

@Injectable({
	providedIn: 'root',
})
export class PersonsXclService extends PersonsService {

	private roles: any;

	constructor(
		private xclHttpService: XclHttpService,
		private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private cacheService: CacheService,
		private personMapperXclService: PersonsMapperXclService,
		private sessionUtilsService: SessionUtilsService,
		private configurationService: ConfigurationService,
	) {
		super();
		this.roles = this.configurationService.instant('user.roles');
	}

	get(): Observable<ServiceResponse<NaturalPerson | ArtificialPerson>> {

		const params = <ParameterModel[]>[];
		params.push(new ParameterModel('clientIdentifier',
			this.sessionUtilsService.getSessionActiveUserPersonId()));

		return this.cacheService.get(
			'client-details',
			this.xclHttpService.execute('client-details', XclHttpService.GET, params).pipe(
				map((xclOrder: MonogoalOrderXCLModel<XclClientModel>) => {
					const person = this.personMapperXclService.xclClientToPerson(xclOrder.goal);
					return new ServiceResponse<NaturalPerson | ArtificialPerson>(person, this.feedbackExtractor.extract(xclOrder));
				}),
				catchError(error => of(error))
			)
		);
	}

	getMandates(): Observable<ServiceResponse<PersonMandate[]>> {

		const params: Array<ParameterModel> = [
			new ParameterModel('retrieveClientData', 'true'),
			new ParameterModel('naturalPersonClientSwitch', 'false'),
			new ParameterModel('mandateAuthorityType', '03'),
		];

		return this.cacheService.get(
			'person-mandates',
			this.xclHttpService.execute('person-mandates', XclHttpService.GET, params)
				.pipe(map((xclOrder: MultigoalOrderXCLModel<XclRepresentedClientModel>) => {
					const personsList = this.personMapperXclService.xclRepresentedClientsListToPersonsList(xclOrder.goalList);
					const personMandatesList = <PersonMandate[]>[];
					for (const person of personsList) {
						personMandatesList.push(
							<PersonMandate>{ person: person });
					}
					return new ServiceResponse<PersonMandate[]>(personMandatesList, this.feedbackExtractor.extract(xclOrder));
				}),
					catchError(error => of(error)),
				));
	}

	getRepresentedClientNumber(): Observable<string> {
		const activeUserBusinessRole = this.sessionUtilsService.getSessionActiveBusinessRole();
		if (this.roles.b2b && this.roles.b2b === activeUserBusinessRole) {
			return this.getMandates().pipe(
				map((response: ServiceResponse<PersonMandate[]>) => {
					return response.getModel().find(mandate => {
						return mandate.person instanceof NaturalPerson && mandate.person.defaultRepresentedClientSwitch;
					}).person.id;
				}),
			);
		} else {
			return of(null);
		}
	}

}
