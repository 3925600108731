import { Injectable } from '@angular/core';
import { BusinessRoles } from '../model/sessions.enum';

import { SessionUtilsService } from './session-utils.service';

@Injectable()
export class SessionUtilsServiceStub extends SessionUtilsService {

	constructor() {
		super(null);
	}

	public getSessionActiveUserId(): string {
		return 'userId';
	}

	public getSessionActiveUserPersonId(): any {
		return 'personId';
	}

	public isActiveUserConnected(): boolean {
		return false;
	}

	public getSessionActiveBusinessRole(): BusinessRoles {
		return BusinessRoles.b2c;
	}

}
