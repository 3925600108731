export abstract class OperationPlaybarButton {
	trigger?: string;
	priority?: number;
	label?: string;
	icon?: string;
	type?: 'primary' | 'secondary';
	disabled?: boolean;
	enabled?: boolean;
	hidden?: boolean;
	click?: () => void;
}
