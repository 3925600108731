import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AsideComponent } from '@mdib/aside';
import { ElectronicalStatementsPageComponent } from '@mdib-pages/electronical-statements/electronical-statements-page/electronical-statements-page.component';
import { FooterComponent } from '@mdib/footer';
import { HeaderComponent } from '@mdib/header';
import { SessionGuardService } from '@mdib/sessions';
import { AccountStatementsPageComponent } from '@mdib-pages/account-statements/component/account-statements-page/account-statements-page.component';
import { PdfStatementsPageComponent } from '@mdib-pages/account-statements/component/pdf-statements-page/pdf-statements-page.component';



const routes: Routes = [
	{
		path: 'pdf-statements',
		canActivate: [SessionGuardService],
		data: { authorizedTokens: ['accounts.statements.list'] },
		children: [
			{ path: '', component: HeaderComponent, outlet: 'header' },
			{ path: '', component: AsideComponent, outlet: 'aside' },
			{ path: '', component: FooterComponent, outlet: 'footer' },
			{
				path: '',
				component: PdfStatementsPageComponent,
				children: [
					{ path: '', component: PdfStatementsPageComponent, pathMatch: 'full' },
				],
			},
			{
				path: ':accountNumber',
				component: PdfStatementsPageComponent,
				pathMatch: 'full'
			},
		],
	},
	{
		path: 'electronical-statements',
		canActivate: [SessionGuardService],
		data: { authorizedTokens: ['accounts.electronical-statements.list'] },
		children: [
			{ path: '', component: HeaderComponent, outlet: 'header' },
			{ path: '', component: AsideComponent, outlet: 'aside' },
			{ path: '', component: FooterComponent, outlet: 'footer' },
			{
				path: '',
				component: AccountStatementsPageComponent,
				children: [
				],
			},
			{
				path: ':accountNumber',
				component: AccountStatementsPageComponent,
				pathMatch: 'full'
			},
		],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AccountStatementsRoutingModule {
}
