import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MailboxMessagesService } from './service/mailbox-messages.service';
import { MailboxMessagesServiceProxy } from './service/mailbox-messages.service.proxy';

@NgModule()
export class MailboxModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: MailboxModule,
			providers: [
				{ provide: MailboxMessagesService, useClass: MailboxMessagesServiceProxy },
			]
		};
	}
}
