import { NgModule, Inject, ModuleWithProviders } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { SessionRestorationService, SessionResetService } from '@mdib/sessions';
import { PasswordAuthenticationService, DigipassAuthenticationService, OtpAuthenticationService } from '@mdib/sessions-modes';

import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { AuthenticationDataService } from '@mdib/sessions';

import { SessionRestorationXclService } from './service/session-restoration-xcl.service';
import { PasswordAuthenticationXclService } from './service/password-authentication-xcl.service';
import { DigipassAuthenticationXclService } from './service/digipass-authentication-xcl.service';
import { OtpAuthenticationXclService } from './service/otp-authentication-xcl.service';
import { AuthenticationDataMapperXclService } from './service/authentication-data-mapper-xcl.service';
import { AuthenticationDataXclService } from './service/authentication-data-xcl.service';
import { SessionResetXclService } from './service/session-reset-xcl.service';

@NgModule({
	providers: [
		{ provide: SessionRestorationService, useClass: SessionRestorationXclService, multi: true },
	]
})
export class SessionsXclModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(PasswordAuthenticationService, adapterId, PasswordAuthenticationXclService);
		adaptersService.setAdapter(DigipassAuthenticationService, adapterId, DigipassAuthenticationXclService);
		adaptersService.setAdapter(OtpAuthenticationService, adapterId, OtpAuthenticationXclService);
		adaptersService.setAdapter(AuthenticationDataService, adapterId, AuthenticationDataXclService);
		adaptersService.setAdapter(SessionResetService, adapterId, SessionResetXclService);
	}

	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: SessionsXclModule,
			providers: [
				AuthenticationDataMapperXclService,
			]
		};
	}
}
