import { Component } from '@angular/core';
import { ErrorTypes } from '@mdib/errors';

@Component({
	selector: 'mdib-access-denied-page',
	templateUrl: './access-denied-page.component.html',
	styleUrls: ['./access-denied-page.component.scss']
})
export class AccessDeniedPageComponent {
	ErrorTypes = ErrorTypes;
}
