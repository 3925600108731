/**
 * Represents and address provided by the XCL
 */
export class XclAddress {
	addressReference: string;
	addressType: string;
	callingCode: string;
	city: string;
	contactNumber: string;
	contactType: string;
	countryCode: string;
	emailAddress: string;
	identifier: string;
	postalCode: string;
	relationReference: string;
	street: string;
	streetNumber: string;
	thirdPartyNumber: string;
	addressLine1: string;
	addressLine2: string;
}
