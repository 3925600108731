import { Injectable } from '@angular/core';
import { ManagedCurrencyCodes } from '@mdib/config';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { CashAccount } from '../model/cash-account';
import { CashAccountCreation } from '../model/cash-account-creation';

/**
 * Abstracts the calls related to {@link CashAccount} and {@link CashAccountCreation}
 */
@Injectable()
export abstract class CashAccountService {

	/**
	 * @param {number} offset Offset to start from (by default 0, i.e. from the beginning)
	 * @param {number} limit Maximum number of operations to retrieve (by default 0, i.e. no limit)
	 * @returns {Observable<ServiceResponse<CashAccount[]>>} The user {@link CashAccount}
	 */
	public abstract list(offset?: number, limit?: number): Observable<ServiceResponse<CashAccount[]>>;

	/**
	 * @param {string} accountNumber The account number to index the list
	 * @returns {Observable<ServiceReponse<CashAccount>>} a single {@link CashAccount}
 	*/
	public abstract get(accountNumber: string): Observable<ServiceResponse<CashAccount>>;

	/**
	 * Used to dynamically change account icon according to his type.
	 * @param {string} cashAccountType : type of cash account to get corresponding icon name.
	 * @returns {string} corresponding to material icon name.
	 */
	public abstract getIconByCashAccountType(cashAccountType: string): string;

	/**
	 * Calculate the total amounts of cash accounts in bank's base currency.
	 * @param {CashAccount[]} cashAccounts : cash accounts
	 * @param {string} baseCurrencyCode : Bank's base currency
	 * @return {number} : total amount
	 */
	public abstract getTotalAmount(cashAccounts: CashAccount[], baseCurrencyCode?: ManagedCurrencyCodes): number ;

	/**
	 * Asks to backend if {@link CashAccountCreation} is valid.
	 *
	 * @abstract
	 * @param {CashAccountCreation} cashAccountCreation
	 * @returns {Observable<ServiceResponse<CashAccountCreation>>} The validated {@link CashAccountCreation} as a new reference.
	 * @memberof CashAccountService
	 */
	public abstract validate(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>>;

	/**
	 * Submit the cash account creation to the backend witout signature
	 *
	 * @abstract
	 * @param {CashAccountCreation} cashAccountCreation
	 * @returns {Observable<ServiceResponse<CashAccountCreation>>}
	 * @memberof CashAccountService
	 */
	public abstract confirm(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>>;

	/**
	 * Signs and submit the cash account creation
	 *
	 * @abstract
	 * @param {CashAccountCreation} cashAccountCreation
	 * @returns {Observable<ServiceResponse<CashAccountCreation>>}
	 * @memberof CashAccountService
	 */
	public abstract sign(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>>;
}

