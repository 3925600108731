import { Injectable } from '@angular/core';
import { XclDateFormatter } from '@mdib-xcl/utils';
import { ConfigService } from '@mdib/config';
import { HashAlgorithms, PaymentFile, PaymentFileError, PaymentFileFailure, PaymentFileStatus, PaymentFileTypes } from '@mdib/payment-files';
import { MdibFile, UtilsHelper } from '@mdib/utils';
import { PaymentFileFailureXcl } from '../model/payment-file-failure-xcl';
import { PaymentFileXcl } from '../model/payment-file-xcl';

@Injectable()
export class PaymentFilesMapperXclService {

	private statusMappingList: CodeXcl[] = [
		{'xcl': '1000', 'functional': PaymentFileStatus.awaitingProcessing},
		{'xcl': '1100', 'functional': PaymentFileStatus.beingProcessed},
		{'xcl': '1200', 'functional': PaymentFileStatus.unformatted},
		{'xcl': '1300', 'functional': PaymentFileStatus.checkingContent},
		{'xcl': '1400', 'functional': PaymentFileStatus.checkingContent},
		{'xcl': '2000', 'functional': PaymentFileStatus.controlled},
		{'xcl': '2500', 'functional': PaymentFileStatus.signed},
		{'xcl': '3000', 'functional': PaymentFileStatus.inProgress},
		{'xcl': '9000', 'functional': PaymentFileStatus.executed},
		{'xcl': '9900', 'functional': PaymentFileStatus.rejectedByUser},
		{'xcl': '9901', 'functional': PaymentFileStatus.cancelled},
		// Old statuses not returned by backend, but coul still be present in migrated data.
		{'xcl': '0500', 'functional': PaymentFileStatus.incorrectFormat},
		{'xcl': '0900', 'functional': PaymentFileStatus.riskOfDuplicates},
		{'xcl': '1500', 'functional': PaymentFileStatus.contentToCheck},
		{'xcl': '3502', 'functional': PaymentFileStatus.rejected},
		{'xcl': '3602', 'functional': PaymentFileStatus.rejected},
		{'xcl': '9903', 'functional': PaymentFileStatus.cancelError},



	];

	private typeMappingList: CodeXcl[] = [
		{'xcl': 'PGV11', 'functional': PaymentFileTypes.sctInCu2Fi},
	];

	private hashMappingList: CodeXcl[] = [
		{'xcl': '01', 'functional': HashAlgorithms.sha1},
		{'xcl': '02', 'functional': HashAlgorithms.sha2},
	];

	private errorMappingList: CodeXcl[] = [
		{'xcl': '00', 'functional': PaymentFileError.noError},
		{'xcl': 'N1', 'functional': PaymentFileError.riskOfDuplicates},
		{'xcl': 'N2', 'functional': PaymentFileError.riskOfDuplicates},
		{'xcl': 'N3', 'functional': PaymentFileError.riskOfDuplicates},
		{'xcl': 'N4', 'functional': PaymentFileError.accountNotLinkedToThePrincipal},
		{'xcl': 'N5', 'functional': PaymentFileError.accessRightsProblem},
		{'xcl': 'N6', 'functional': PaymentFileError.accessRightsProblem},
		{'xcl': 'N7', 'functional': PaymentFileError.voucherProblem},
		{'xcl': 'N8', 'functional': PaymentFileError.voucherProblem},
		{'xcl': 'N9', 'functional': PaymentFileError.voucherProblem},
		{'xcl': 'NA', 'functional': PaymentFileError.incorrectLayout},
		{'xcl': 'NB', 'functional': PaymentFileError.b2bFile},
		{'xcl': 'NC', 'functional': PaymentFileError.notB2bFile},
		{'xcl': 'ND', 'functional': PaymentFileError.incorrectHeaderCode},
		{'xcl': 'NE', 'functional': PaymentFileError.incorrectMessageCode},
		{'xcl': 'NF', 'functional': PaymentFileError.incorrectApplicationCode},
		{'xcl': 'NG', 'functional': PaymentFileError.incorrectOrderCode},
		{'xcl': 'NH', 'functional': PaymentFileError.incorrectCurrencyCode},
		{'xcl': 'NI', 'functional': PaymentFileError.incorrectNumberOfBatches},
		{'xcl': 'NJ', 'functional': PaymentFileError.differentChecksums},
		{'xcl': 'NK', 'functional': PaymentFileError.incorrectResubmissionNumber},
		{'xcl': 'NL', 'functional': PaymentFileError.incorrectAwlClient},
		{'xcl': 'NM', 'functional': PaymentFileError.incorrectMemberIdentity},
		{'xcl': 'NN', 'functional': PaymentFileError.incorrectFileDigitalSignature},
		{'xcl': 'Z1', 'functional': PaymentFileError.incorrectFileFormat},
		{'xcl': 'Z2', 'functional': PaymentFileError.incorrectOperationsNumber},
		{'xcl': 'Z3', 'functional': PaymentFileError.incorrectOperationsAmount},
		{'xcl': 'Z4', 'functional': PaymentFileError.duplicateMessageID},
		{'xcl': 'Z5', 'functional': PaymentFileError.limitReached},
		{'xcl': 'Z6', 'functional': PaymentFileError.formattingError},
		{'xcl': 'Z7', 'functional': PaymentFileError.noPain},
		{'xcl': 'ZA', 'functional': PaymentFileError.instantPayments}
	];

	constructor(private configService: ConfigService) {
	}

	/**
	 * Converts the technical model array into functional model array
	 * @param paymentFilesXclList : source format array.
	 * @returns PaymentFile[] : functional model format array.
	 */
	paymentFilesXclListToPaymentFilesList(paymentFilesXclList: PaymentFileXcl[]): PaymentFile[] {
		if (!paymentFilesXclList) {
			console.error('Invalid list of accounts returned by the XCL: ' + JSON.stringify(paymentFilesXclList));
			return null;
		}
		return paymentFilesXclList.map((technicalModel: PaymentFileXcl) => this.paymentFilesXclToPaymentFiles(technicalModel));
	}

	/**
	 * Parse technical model in functional model
	 * @param PaymentFileXcl : source format.
	 * @returns PaymentFile : parsed functional model format.
	 */
	public paymentFilesXclToPaymentFiles(paymentFileXcl: PaymentFileXcl): PaymentFile {
		const paymentFile = <PaymentFile> {
			fileStatus: this.statusXclToStatus(paymentFileXcl.fileStatus),
			type: this.typeXclToType(paymentFileXcl.fileType),
			uploadDate: XclDateFormatter.xclStringToDate(paymentFileXcl.fileEntryDate),
			sequenceNumber: paymentFileXcl.sequenceNumber,
			batchesCount: paymentFileXcl.calculatedNumberOfBatch,
			paymentsCount: paymentFileXcl.calculatedNumberOfTransfer,
			totalAmount: paymentFileXcl.calculatedTotalAmount,
			hashValue: paymentFileXcl.digitalSignature,
			hashAlgorithm: this.hashXclToHash(paymentFileXcl.hashAlgorithmCode),
			currency: this.configService.baseCurrencyCode,
			identifier: paymentFileXcl.fileIdentifier,
			errorsInformation: this.errorXclToError(paymentFileXcl.loadingErrorCode),
			file: <MdibFile>{
				name: paymentFileXcl.logicalFilename,
			},
		};

		if (paymentFileXcl.fileEntryTime) {
			paymentFile.uploadDate.setHours(+paymentFileXcl.fileEntryTime.substring(0, 2), +paymentFileXcl.fileEntryTime.substring(2, 4));
		}
		return paymentFile;
	}

	/**
	 * Converts the technical model array into functional model array
	 * @param paymentFilesXclList : source format array.
	 * @returns PaymentFile[] : functional model format array.
	 */
	paymentFilesFailureXclListToPaymentFilesFailureList(paymentFilesFailureXclList: PaymentFileFailureXcl[]): PaymentFileFailure[] {
		if (!paymentFilesFailureXclList) {
			console.error('Invalid list of accounts returned by the XCL: ' + JSON.stringify(paymentFilesFailureXclList));
			return null;
		}
		return paymentFilesFailureXclList.map((technicalModel: PaymentFileFailureXcl) => this.paymentFileFailureXclToPaymentFileFailure(technicalModel));
	}

	/**
	 * Parse technical model in functional model
	 * @param PaymentFileXcl : source format.
	 * @returns PaymentFile : parsed functional model format.
	 */
	public paymentFileFailureXclToPaymentFileFailure(paymentFileFailureXcl: PaymentFileFailureXcl): PaymentFileFailure {
		const paymentFileFailure = <PaymentFileFailure> {
			batchReference: paymentFileFailureXcl.batchReference,
			sequenceNumber: paymentFileFailureXcl.sequenceNumber,
			wording: paymentFileFailureXcl.errorWording,
			fieldValue: paymentFileFailureXcl.errorFieldValue,
			operationSequenceNumber: paymentFileFailureXcl.operationSequenceNumber,
			identifier: paymentFileFailureXcl.identifier,
		};
		return paymentFileFailure;
	}

	/**
	 * Parse functional payment file status into correct format for the xcl status parameter
	 * @param {PaymentFileStatus} paymentFileStatus
	 * @returns {string[]}
	 */
	public statusToStatusXcl(paymentFileStatus: PaymentFileStatus): string {
		const status = this.statusMappingList.find((element: CodeXcl) => element.functional === paymentFileStatus);
		return status && status.xcl;
	}

	/**
	 * Parse PaymentFileXclStatus into PaymentFileStatus
	 * @param {string} statusXcl
	 * @returns {PaymentFileStatus}
	 */
	public statusXclToStatus(statusXcl: string): PaymentFileStatus {
		const status = this.statusMappingList.find((element: CodeXcl) => element.xcl === statusXcl);
		return status && status.functional;
	}

	/**
	 * Parse functional payment file type into correct format for the xcl type parameter
	 * @param {PaymentFileTypes} paymentFileType
	 * @returns {string}
	 */
	public typeToTypeXcl(paymentFileType: PaymentFileTypes): string {
		const type = this.typeMappingList.find((element: CodeXcl) => element.functional === paymentFileType);
		return type && type.xcl;
	}

	/**
	 * Parse PaymentFileXclType into PaymentFileTypes
	 * @param {string} typeXcl
	 * @returns {PaymentFileTypes}
	 */
	public typeXclToType(typeXcl: string): PaymentFileTypes {
		const type = this.typeMappingList.find((element: CodeXcl) => element.xcl === typeXcl);
		return type && type.functional;
	}

	/**
	 * Parse functional hash Algorithm into correct format for the xcl hash algorithm parameter
	 * @param {HashAlgorithms} hashAlgorithm
	 * @returns {string}
	 */
	public hashToHashXcl(hashAlgorithm: HashAlgorithms): string {
		const hash = this.hashMappingList.find((element: CodeXcl) => element.functional === hashAlgorithm);
		return hash && hash.xcl;
	}

	/**
	 * Parse Xcl hash algorithm into HashAlgorithms
	 * @param {string} hashXcl
	 * @returns {HashAlgorithms}
	 */
	public hashXclToHash(hashXcl: string): HashAlgorithms {
		const hash = this.hashMappingList.find((element: CodeXcl) => element.xcl === hashXcl);
		return hash && hash.functional;
	}

	/**
	 * Parse PaymentFileXclError into PaymentFileError
	 * @param {string} errorXcl
	 * @returns {PaymentFileError}
	 */
	public errorXclToError(errorXcl: string): PaymentFileError {
		let error = this.errorMappingList.find((element: CodeXcl) => element.xcl === errorXcl);
		if (UtilsHelper.nullOrUndefined(error)) {
			error = {'xcl': '00', 'functional': PaymentFileError.noError};
		}
		return error && error.functional;
	}
}

interface CodeXcl {
	xcl: string;
	functional: any;
}
