import { ElectronicalStatementsFormat } from '@mdib/electronical-statements';
import { AccountStatementStatus } from '@mdib/account-statements';
import { CashAccount } from '@mdib/cash-accounts';

export class ElectronicalStatementsPageFilter {
	accountNumber: string;
	status: AccountStatementStatus;
	format: ElectronicalStatementsFormat;
	cashAccount: CashAccount;
}
