import { BackendOperation, BackendOperationStep, BackendOperationType } from '@mdib/core/adapters';
import { SepaDirectDebitContract } from '@mdib/sepa-direct-debits';
import { Observable, of } from 'rxjs';
import { MockSepaDirectDebitContracts } from '../../mock/mock-sepa-direct-debit-contracts';

export class SepaDirectDebitContractsRetrieveMemory extends BackendOperation<string, SepaDirectDebitContract> {

	constructor() {
		super(BackendOperationType.RETRIEVE);
	}

	execute(contractReference: string, trigger?: string): Observable<BackendOperationStep<SepaDirectDebitContract>> {
		const contract: SepaDirectDebitContract = MockSepaDirectDebitContracts.getMockSepaDirectDebitContracts().filter((sepacontract: SepaDirectDebitContract) => sepacontract.contractReference === contractReference).pop();
		return of(new BackendOperationStep<SepaDirectDebitContract>(trigger, [], contract));
	}
}
