import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SidenavService {

	private _SidenavOpened = new Subject<boolean>();

	/**
	 * Set the state of the sidenav
	 * @param {boolean} sidenavOpen
	 */
	set state(sidenavOpen: boolean) {
		this._SidenavOpened.next(sidenavOpen);
	}

	/**
	 * Get the state of the sidenav
	 * @returns {Observable<boolean>}
	 */
	get sidenavToggle(): Observable<boolean> {
		return this._SidenavOpened.asObservable();
	}

	clearData() {
		this._SidenavOpened.next();
	}
}
