import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Themes, ThemeService } from '@mdib/styling';
import { AuthenticationModesService } from '../../../service/authentication-modes.service';
import { User } from '../../../model/user';
import { Authentication } from '../../../model/authentication';

@Component({
	selector: 'authentication',
	template: ''
})
export class AuthenticationComponent implements OnInit {

	/**
	 * @property (Input) The user to authenticate or empty if any
	 */
	@Input()
	public user: User;

	/**
	 * @property (Input) The mode of authentication to use
	 */
	@Input('mode')
	public set authenticationMode(mode: any) {
		this.selectedMode = mode;
		this.updateScreen();
	}

	/**
	 * @property (Output) Emits the signed object
	 */
	@Output()
	public onSuccess: EventEmitter<Authentication> = new EventEmitter<Authentication>();

	/**
	 * @property (Output) Emits events on errors or cancellation
	 */
	@Output()
	public onEvent: EventEmitter<Event> = new EventEmitter<Event>();

	/* Internal properties */
	private selectedMode: any;

	/* Constructor */
	constructor(
		private viewContainerRef: ViewContainerRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private authenticationsService: AuthenticationModesService,
		private theme: ThemeService,
	) { }

	ngOnInit() {
		this.updateScreen();
	}

	/**
	 * Display the authentication process corresponding to the selected mode.
	 */
	private updateScreen() {
		// Get the mode to display
		this.viewContainerRef.clear();
		const mode = this.authenticationsService.getMode(this.selectedMode) || this.authenticationsService.getDefaultMode();
		if (!mode) {
			return;
		}

		// Update View
		const componentType = mode.processComponent;
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
		const componentRef = this.viewContainerRef.createComponent(componentFactory);

		// Bindings
		componentRef.instance.user = this.user;
		componentRef.instance.onSuccess.subscribe((result) => this.emitSuccess(result));
		componentRef.instance.onEvent.subscribe((event) => this.emitEvent(event));
	}

	/**
	 * Trigger an event when somthing happened in the authentication process.
	 *
	 * @param result The occuring event
	 */
	private emitEvent(event: Event) {
		this.onEvent.emit(event);
	}

	/**
	 * When the dynamic authentication process succeeds, the onSuccess event is triggered
	 * with the resulting authentication.
	 *
	 * @param result The resulting authentication
	 */
	private emitSuccess(result: Authentication) {
		this.theme.setTheme(Themes[result.user.businessRole.name]);
		this.onSuccess.emit(result);
	}
}
