import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { XclCashAccountSortOrderModel } from '../model/xcl-cash-account-sort-order-model';
import { CashAccountSortOrderMapperXclService } from './cash-account-sort-order-mapper-xcl.service';
import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { CashAccountSortOrder, CashAccountSortOrderService } from '@mdib/cash-accounts';
import { HttpStatusActionModel, HttpStatusManagementService, ParameterModel } from '@mdib/http';
import { SessionUtilsService } from '@mdib/sessions';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CashAccountSortOrderXclService extends CashAccountSortOrderService {
	public httpStatusAction: HttpStatusActionModel = new HttpStatusActionModel();

	constructor(private sessionUtilsService: SessionUtilsService,
				private xclHttpService: XclHttpService,
				private httpStatusManagementService: HttpStatusManagementService,
				private feedbacksExtractor: FunctionalFeedbacksFromXclOrderExtractor,
				private xclAccountSortOrderMapperService: CashAccountSortOrderMapperXclService) {
		super();

		// FIXME: change useless action set
		this.httpStatusAction.action = () => {
			console.log('HttpStatusAction defined in AccountSortOrderService constructor');
		};

		this.httpStatusAction.httpStatus = /1\d\d/;
		this.httpStatusAction.endpoint = 'accountsortorders';

		this.httpStatusManagementService.setActionOnHttpStatus(this.httpStatusAction);
	}
	public update(accountSortOrder: CashAccountSortOrder): Observable<ServiceResponse<CashAccountSortOrder>> {
		const xclObservable = this.xclHttpService.execute(
			'account-sort-order',
			XclHttpService.PUT, [<ParameterModel>{ par: 'identifier', val: this.sessionUtilsService.getSessionActiveUserId() }]).pipe(
			mergeMap((order: MonogoalOrderXCLModel<XclCashAccountSortOrderModel>) =>
				this.xclHttpService.execute(
					'account-sort-order-retrieve',
					XclHttpService.PUT, [<ParameterModel>{ par: 'reference', val: order.reference }]
				)),
			mergeMap((order: MonogoalOrderXCLModel<XclCashAccountSortOrderModel>) => {
				const body: XclCashAccountSortOrderModel = order.goal;
				body.listAccountFreeSortOrder = this.xclAccountSortOrderMapperService.getTechnicalModelFromFunctionalModel(accountSortOrder).listAccountFreeSortOrder;
				return this.xclHttpService.execute(
					'account-sort-order-validate',
					XclHttpService.PUT, [<ParameterModel>{ par: 'reference', val: order.reference }], body);
			}),
			mergeMap((order: MonogoalOrderXCLModel<XclCashAccountSortOrderModel>) =>
				this.xclHttpService.execute(
					'account-sort-order-confirm',
					XclHttpService.PUT, [<ParameterModel>{ par: 'reference', val: order.reference }]
				))
		) as Observable<MonogoalOrderXCLModel<XclCashAccountSortOrderModel>>;

		// Extract the account sort order from the root "goal" property returned by the XCL
		// Return a account sort order (functional model) corresponding to account sort order returned by the XCL (technical model)
		return xclObservable.pipe(
			map((xclOrder: MonogoalOrderXCLModel<XclCashAccountSortOrderModel>) =>
				new ServiceResponse<CashAccountSortOrder>(
					this.xclAccountSortOrderMapperService.getFunctionalModelFromTechnicalModel(xclOrder.goal),
					this.feedbacksExtractor.extract(xclOrder)
				))
		);
	}
}
