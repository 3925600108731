import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LiveTourComponent } from './live-tour/live-tour.component';
import { LiveTourService } from './live-tour.service';
import { LiveTourStepComponent } from './live-tour-step/live-tour-step.component';
import { StylingModule } from '@mdib/styling';

@NgModule({
	imports: [
		CommonModule,
		StylingModule
	],
	declarations: [LiveTourComponent, LiveTourStepComponent],
	entryComponents: [LiveTourStepComponent],
	exports: [LiveTourComponent],
	providers: [LiveTourService]
})
export class LiveTourModule { }
