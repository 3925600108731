import { Injectable } from '@angular/core';
import { XclAccountStatementHelper } from '../../account-statements/helper/xcl-account-statement-helper';
import { FileMapperXclService, XclDateFormatter } from '@mdib-xcl/utils';
import { AccountStatement, AccountStatementStatus } from '@mdib/account-statements';
import { MdibFile } from '@mdib/utils';
import { AccountStatementXcl } from '../model/account-statement-xcl';

@Injectable()
export class AccountStatementsMapperXclService {

	constructor(private fileService: FileMapperXclService,
	) {
	}

	/**
	 * Parse technical model in functional model
	 * @param AccountStatementXcl : source format.
	 * @returns AccountStatement : parsed functional model format.
	 */
	public accountStatementXclToAccountStatement(accountStatementXcl: AccountStatementXcl): AccountStatement {
		const accountStatementModel = <AccountStatement>{
			// FIXME: reset the identifier to just reflect the accountStatementXcl.identifier as soon as R&D has come up with a fix for this.
			identifier: accountStatementXcl.identifier + accountStatementXcl.statementYear + accountStatementXcl.addressSequenceNumber + accountStatementXcl.statementNumber,
			accountNumber: accountStatementXcl.accountNumber,
			sequenceNumber: accountStatementXcl.addressSequenceNumber,
			statementNumber: +accountStatementXcl.statementNumber,
			statementNumberYear: +accountStatementXcl.statementYear,
			accountingStartDate: XclDateFormatter.stringToDate(accountStatementXcl.firstAccountingDate),
			status: this.statusXclToStatus(accountStatementXcl.statementStatus), // {1000, 4000 }: NEW, { 9000 }: READ
			file: <MdibFile>{
				id: accountStatementXcl.fileId,
				name: this.getFileName(accountStatementXcl),
				status: this.fileService.toStatus(accountStatementXcl.fileStatus),
				format: XclAccountStatementHelper.getFileFormatFromXclCode(accountStatementXcl.transmissionMedium),
			},
		};
		return accountStatementModel;
	}

	/**
	 * Converts the technical model list into functional model list
	 * AccountStatement[] to XclAccountStatementModel[]
	 * @param AccountStatementXcl[] : source format list.
	 * @returns AccountStatement[] : functional model format list.
	 */
	public accountStatementXclToAccountStatementList(accountStatementXclList: AccountStatementXcl[]): AccountStatement[] {
		if (!accountStatementXclList) {
			console.log('ERROR: mapping issue from AccountStatementXcl[] to AccountStatement[]');
			return null;
		}

		const accountStatementList: AccountStatement[] = new Array<AccountStatement>();
		for (const accountStatementSummary of accountStatementXclList) {
			const accountStatement: AccountStatement = this.accountStatementXclToAccountStatement(accountStatementSummary);
			accountStatementList.push(accountStatement);
		}
		return accountStatementList;
	}

	/**
	 * Parse functional account statement status into correct format for the xcl status parameter
	 * @param {AccountStatementStatus} accountStatementStatus
	 * @returns {string[]}
	 */
	public statusToStatusXcl(accountStatementStatus: AccountStatementStatus): string[] {
		const statementStatus: string[] = [];

		switch (accountStatementStatus) {
			case AccountStatementStatus.New: {
				statementStatus.push('1000');
				statementStatus.push('4000');
				break;
			}
			case AccountStatementStatus.Downloaded: {
				statementStatus.push('9000');
				statementStatus.push('');
				break;
			}
			default: {
				statementStatus.push('1000');
				statementStatus.push('4000');
				statementStatus.push('9000');
				break;
			}
		}
		return statementStatus;
	}

	/**
	 * Parse accountStatementStatusXcl into accountStatementStatus
	 * @param {string} accountStatementStatus
	 * @returns {AccountStatementStatus}
	 */
	public statusXclToStatus(accountStatementStatus: string): AccountStatementStatus {
		let statementStatus: AccountStatementStatus;
		statementStatus = (accountStatementStatus === '9000' ? AccountStatementStatus.Downloaded : AccountStatementStatus.New);
		return statementStatus;

	}

	/**
	 * Generate filename in front if fileformat is mt940, since Centric is using a different transmissionMedium for mt940 (Z01 vs SWF)
	 * @param {AccountStatementXcl} accountStatementXcl
	 * @returns {string} returns the filename
	 */
	public getFileName(accountStatementXcl: AccountStatementXcl): string {
		if (XclAccountStatementHelper.getFileFormatFromXclCode(accountStatementXcl.transmissionMedium) === 'mt940') {
			return accountStatementXcl.accountNumber + '_' + accountStatementXcl.statementGenerationDate + '_MT940.txt';
		} else {
			return accountStatementXcl.fileName;
		}
	}

}
