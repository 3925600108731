import { NgModule, Inject } from '@angular/core';
import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';
import { AdaptersService } from '@mdib/core/adapters';
import { SepaDirectDebitContractsService, SepaDirectDebitPaymentsService } from '@mdib/sepa-direct-debits';
import { SepaDirectDebitContractsMemoryService } from './service/sepa-direct-debit-contracts-memory.service';
import { SepaDirectDebitPaymentsMemoryService } from './service/sepa-direct-debit-payments-memory.service';

@NgModule()
export class SepaDirectDebitsMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(SepaDirectDebitContractsService, adapterId, SepaDirectDebitContractsMemoryService);
		adaptersService.setAdapter(SepaDirectDebitPaymentsService, adapterId, SepaDirectDebitPaymentsMemoryService);
	}
}
