import { Injectable } from '@angular/core';

import { VirtualNumpad } from '../interfaces/virtual-numpad.interface';
import { Subject } from 'rxjs';
import { ILayout } from '@mdib/virtual-numpad/interfaces/layout.interface';
import { Layout } from '@mdib/virtual-numpad/configs/layout.config';
import { MinimalLayout } from '@mdib/virtual-numpad/configs/minimal-layout.config';

@Injectable()
export class VirtualNumpadService {

	toggle = new Subject<VirtualNumpad>();

	open(input: HTMLInputElement): void {
		let layout: ILayout;
		let maxLength: number;
		if (input.maxLength === -1) {
			layout = new Layout();
		} else  {
			layout = new MinimalLayout();
			maxLength = input.maxLength;
		}
		this.toggle.next({
			input: input,
			layout: layout,
			maxLenght: maxLength,
		});
	}

}
