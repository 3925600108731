import { Pipe, PipeTransform } from '@angular/core';
import { DataFormattingService } from '@mdib/utils';
import { CommunicationTypes } from '../model/commons.enum';

/**
 * My role is to adapt communication based on {@link CommunicationTypes}
 * @example
 * communication | mdibCommunication:communicationType
 */
@Pipe({
	name: 'mdibCommunication'
})
export class MdibCommunicationPipe implements PipeTransform {

	constructor(private _dataFormattingService: DataFormattingService) {}

	transform(communication: string, type?: CommunicationTypes): string {

		if (type === CommunicationTypes.european) {
			communication = this._dataFormattingService.formatEuropean(communication);
		}
		return communication;
	}
}
