import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Beneficiary, BeneficiaryForm, BeneficiaryType } from '@mdib/beneficiaries';
import { CashAccount } from '@mdib/cash-accounts';
import { Communication, CommunicationTypes } from '@mdib/commons';
import { ConfigService } from '@mdib/config';
import { AmountValidator, CommonValidator, DataFormattingService, DateValidator, GroupFieldValidator, UtilsHelper, IbanValidator } from '@mdib/utils';
import { filter } from 'rxjs/operators';

import { PaymentOperation } from '../models/payment-operation';
import { PaymentOperationBuilder } from '../models/payment-operation-builder';
import { PaymentTypes } from '../models/payments.enum';
import { InternationalPaymentDetailsForm } from './international-payment-details-form';
import { StandingOrderDetailsForm } from './standing-order-details-form';

export class PaymentForm {

	private form: FormGroup;
	private _beneficiaryForm?: BeneficiaryForm;
	private _standingOrderDetailsForm?: StandingOrderDetailsForm;
	private internationalPaymentDetailsForm?: InternationalPaymentDetailsForm;

	constructor(private fb: FormBuilder,
				private dataFormattingService: DataFormattingService,
				private configService: ConfigService,
				private subscribe = false,
				private paymentOperation?: PaymentOperation<any>,
				private isConsult?,
	) {

		this.init(paymentOperation);
	}

	reset() {
		this.subscribe = true;
		this.init();
	}

	init(paymentOperation?: PaymentOperation<any>) {
		this.paymentOperation = paymentOperation || this.initPayment();
		this._beneficiaryForm = this.initBeneficiaryForm();
		this.create();
		if (this.subscribe) {
			this.subscriptions();
		}
	}

	clearCounterParty(): void {
		this.saveBeneficiary.setValue(false);
		switch (this._beneficiaryForm.type.value) {
			case BeneficiaryType.INTERNAL:
				this._beneficiaryForm.clear();
				this._beneficiaryForm.removeAccountForm();
				this.manageCounterParty(null);
				this.form.setValidators(GroupFieldValidator.isDuplicated('ordererAccount', 'counterPartyAccount'));
				break;
			default:
				this.manageBeneficiary();
		}
	}

	manageBeneficiary() {
		this.form.clearValidators();
		this.counterPartyAccount.reset();
		this.form.removeControl('counterPartyAccount');
		this._beneficiaryForm.addAccountForm();
		this._beneficiaryForm.clear();
		this.form.setValidators(GroupFieldValidator.isDuplicated('ordererAccount', 'beneficiary.account.number'));
	}

	disable() {
		this.form.disable();
		this._beneficiaryForm.disable();
		if (this.type.value === PaymentTypes.europeanStandingOrder && this._standingOrderDetailsForm) {
			this._standingOrderDetailsForm.group.disable();
		} else if (this.type.value === PaymentTypes.internationalPayment && this.internationalPaymentDetailsForm) {
			this.internationalPaymentDetailsForm.group.disable();
		}
	}

	enable() {
		this.form.enable();
		this._beneficiaryForm.enable();
		if (this.type.value === PaymentTypes.internationalPayment) {
			this._beneficiaryForm.communicationForm.type.disable();
		}
		if (this.type.value === PaymentTypes.europeanStandingOrder && this._standingOrderDetailsForm) {
			this._standingOrderDetailsForm.group.enable();
		} else if (this.type.value === PaymentTypes.internationalPayment && this.internationalPaymentDetailsForm) {
			this.internationalPaymentDetailsForm.group.enable();
		}
		if (this.saveBeneficiary.value !== true) {
			this.beneficiaryForm.alias.disable();
		}
		this.form.updateValueAndValidity();
	}

	private initBeneficiaryForm(): BeneficiaryForm {
		if (!UtilsHelper.objectNotEmpty(this.paymentOperation.counterParty)) {
			this.paymentOperation.counterParty = new Beneficiary();
		}
		return new BeneficiaryForm(this.fb, this.configService, this.dataFormattingService, this.paymentOperation.counterParty, this.subscribe);
	}

	private initStandingOrderDetailsForm(): void {
		this._standingOrderDetailsForm = new StandingOrderDetailsForm(this.fb, this.dataFormattingService, this.paymentOperation.executionDetails, this.subscribe);
		this.setDetails(this._standingOrderDetailsForm.group);
		this.paymentOperation.executionDetails = this._standingOrderDetailsForm.model;
		this._standingOrderDetailsForm.group.valueChanges.subscribe(details => this.paymentOperation.executionDetails = this._standingOrderDetailsForm.model);
	}

	private initInternationalPaymentDetailsForm(): void {
		this.internationalPaymentDetailsForm = new InternationalPaymentDetailsForm(this.fb, this.paymentOperation.executionDetails);
		this.setDetails(this.internationalPaymentDetailsForm.group);
		this.internationalPaymentDetailsForm.group.valueChanges.subscribe(details => this.paymentOperation.executionDetails = details);
	}

	private create() {
		this.form = this.fb.group({
			amount: [!!this.paymentOperation.amount ? this.dataFormattingService.amountToViewFormat(this.paymentOperation.amount) : null, [AmountValidator.checkPositive, CommonValidator.required]],
			currency: [this.paymentOperation.currency, [CommonValidator.required]],
			type: [this.paymentOperation.type || PaymentTypes.sepaCreditTransfer, [CommonValidator.required]],
			ordererAccount: [this.paymentOperation.ordererAccount, [CommonValidator.required]],
			paymentDate: [this.paymentOperation.paymentDate, [DateValidator.dateFromToday]],
			saveBeneficiary: [this.paymentOperation.saveBeneficiary],
			beneficiary: this._beneficiaryForm.group,
			reference: [this.paymentOperation.reference || ''],
			status: [this.paymentOperation.status || ''],
			operationDate: [this.paymentOperation.operationDate || ''],
		}, {
			validator: GroupFieldValidator.isDuplicated('ordererAccount', 'counterPartyAccount'),
		});
		if (this.paymentOperation.type === PaymentTypes.europeanStandingOrder) {
			this.initStandingOrderDetailsForm();
			this._standingOrderDetailsForm.updateAmountFields(this.amount);
		} else if (this.paymentOperation.type === PaymentTypes.internationalPayment) {
			this.initInternationalPaymentDetailsForm();
		}
		if (this.paymentOperation.counterParty.type === BeneficiaryType.INTERNAL) {
			this.manageCounterParty(UtilsHelper.objectNotEmpty(this.paymentOperation.counterParty) && this.paymentOperation.counterParty.type === BeneficiaryType.INTERNAL && UtilsHelper.objectNotEmpty(this.paymentOperation.counterParty.account) ? this.paymentOperation.counterParty.account : null);
		}
		this.beneficiaryForm.alias.setValidators([Validators.maxLength(this.configService.nameMaxLength), CommonValidator.required]);
		if (this.paymentOperation.saveBeneficiary !== true) {
			this.beneficiaryForm.alias.disable();
		}
	}

	/**
	 * Create and initialize default value of the payment operation.
	 */
	private initPayment(): PaymentOperation<null> {
		return new PaymentOperationBuilder<null>(
			<PaymentOperation<null>>{
				counterParty: new Beneficiary({type: BeneficiaryType.INTERNAL, communication: new Communication({type: CommunicationTypes.free})}),
				type: PaymentTypes.sepaCreditTransfer,
				currency: this.configService.baseCurrencyCode,
			}).paymentOperation;
	}

	private manageCounterParty(value: CashAccount) {
		if (!this.counterPartyAccount) {
			this.form.addControl('counterPartyAccount', new FormControl(value, [CommonValidator.required]));
			if (this.subscribe) {
				this.counterPartyAccount.valueChanges.subscribe(account => this.paymentOperation.counterParty.account = account);
			}
		}
	}

	private subscriptions() {
		this.ordererAccount.valueChanges.subscribe((account: CashAccount) => {
			if (UtilsHelper.objectNotEmpty(account)) {
				this.paymentOperation.ordererAccount = account;
			}
		});

		this.type.valueChanges.pipe(
			filter(paymentType => this.paymentOperation.type !== paymentType),
		).subscribe(paymentType => {
			// No changes in the form if this is a switch between instant payment and SEPA payment
			if (this.paymentOperation.type !== PaymentTypes.instantPayment) {
				this.paymentOperation.type = paymentType;
				switch (paymentType) {
					case PaymentTypes.internationalPayment:
						this.initInternationalPaymentDetailsForm();
						this.currency.setValue(null);
						this._beneficiaryForm.type.setValue(BeneficiaryType.INTERNATIONAL);
						this.manageBeneficiary();
						this._beneficiaryForm.accountForm.bic.setValidators(CommonValidator.required);
						this._beneficiaryForm.addressForm.country.setValidators(CommonValidator.required);
						this._beneficiaryForm.communicationForm.type.setValue(CommunicationTypes.free);
						this._beneficiaryForm.communicationForm.type.disable();
						this.setAmountValidators();
						break;
					case PaymentTypes.europeanStandingOrder:
						this.initStandingOrderDetailsForm();
						this.beneficiaryForm.type.setValue(!!this._beneficiaryForm.type.value && this._beneficiaryForm.type.value !== BeneficiaryType.INTERNATIONAL ? this._beneficiaryForm.type.value : BeneficiaryType.INTERNAL);
						this.beneficiaryForm.accountForm.bic.clearValidators();
						this.beneficiaryForm.addressForm.country.clearValidators();
						this.beneficiaryForm.communicationForm.type.enable({emitEvent: false});
						this.setAmountValidators();
						this.clearCounterParty();
						this._standingOrderDetailsForm.amountType.valueChanges.subscribe(() => this._standingOrderDetailsForm.updateAmountFields(this.amount));
						break;
					case PaymentTypes.sepaCreditTransfer:
						this.form.removeControl('executionDetails');
						this.beneficiaryForm.type.setValue(BeneficiaryType.INTERNAL);
						this.beneficiaryForm.accountForm.bic.clearValidators();
						this.beneficiaryForm.accountForm.group.updateValueAndValidity();
						this.beneficiaryForm.addressForm.country.clearValidators();
						this.beneficiaryForm.addressForm.group.updateValueAndValidity();
						this.beneficiaryForm.communicationForm.type.enable();
						this.clearCounterParty();
						this.setAmountValidators();
						break;
				}
			} else {
				this.paymentOperation.type = paymentType;
			}
			},
		);
		this.amount.valueChanges.subscribe(amount => {
			this.paymentOperation.amount = this.dataFormattingService.parseAmount(amount);
		});
		this.paymentDate.valueChanges.subscribe(date => {
			this.paymentOperation.paymentDate = date;
			if (!this.paymentDate.touched) {
				this.paymentDate.markAsTouched();
			}
		});

		this.currency.valueChanges.subscribe(currency => this.paymentOperation.currency = currency);
		this.saveBeneficiary.valueChanges.subscribe(saveBeneficiary => {
			this.paymentOperation.saveBeneficiary = saveBeneficiary;
			saveBeneficiary ? this.beneficiaryForm.alias.enable() : this.beneficiaryForm.alias.disable();
			if (saveBeneficiary && !this.beneficiaryForm.alias.value) {
				this.beneficiaryForm.alias.setValue(this.beneficiaryForm.fullName.value);
			}
		});
	}

	private setAmountValidators() {
		this.amount.setValidators([AmountValidator.checkPositive, CommonValidator.required]);
		this.amount.updateValueAndValidity();
	}

	private setDetails(group: AbstractControl) {
		if (this.executionDetails) {
			this.form.setControl('executionDetails', group);
		} else {
			this.form.addControl('executionDetails', group);
		}
	}

	get beneficiaryForm(): BeneficiaryForm {
		return this._beneficiaryForm;
	}

	get group(): AbstractControl {
		return this.form;
	}

	get model(): PaymentOperation<any> {
		return this.paymentOperation;
	}

	get executionDetails(): AbstractControl {
		return this.form.get('executionDetails');
	}

	get type(): AbstractControl {
		return this.form.get('type');
	}

	get ordererAccount(): AbstractControl {
		return this.form.get('ordererAccount');
	}

	get counterPartyAccount(): AbstractControl {
		return this.form.get('counterPartyAccount');
	}

	get paymentDate(): AbstractControl {
		return this.form.get('paymentDate');
	}

	get amount(): FormControl {
		return <FormControl>this.form.get('amount');
	}

	get currency(): AbstractControl {
		return this.form.get('currency');
	}

	get saveBeneficiary(): AbstractControl {
		return this.form.get('saveBeneficiary');
	}

	get standingOrderDetailsForm(): StandingOrderDetailsForm {
		return this._standingOrderDetailsForm;
	}

	get details(): FormControl {
		return <FormControl>this.form.get('executionDetails');
	}

	get operationDate(): AbstractControl {
		return this.form.get('operationDate');
	}

	get reference(): AbstractControl {
		return this.form.get('reference');
	}

	get status(): AbstractControl {
		return this.form.get('status');
	}

	get isConsultation(): boolean {
		return this.isConsult ? true : false;
	}
}
