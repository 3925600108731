import { Immutable } from '@mdib/core/meta';

@Immutable()
export class SearchResult<T> {
	constructor(
		// Results
		public readonly items: Array<T> = [],
		public readonly total = 0,

		// Pagination
		public readonly index = 0,
		public readonly count = undefined,
	) { }
}
