import { Injectable } from '@angular/core';
import { HttpErrorResponse,  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { isNullOrUndefined } from 'util';
import { DxpException } from '@mdib-dxp/http';
import { ServiceResponse, Feedback, ConfigurationService } from '@mdib/utils';
import {
	HttpStatusManagementService,
	RestHttpService,
	HttpService,
	EndPointModel,
	ParameterModel,
} from '@mdib/http';

@Injectable()
export class DxpHttpService<M> extends RestHttpService<M> {
	public static readonly TOKEN = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlblR5cGUiOiJQU1VfQllfVFBQIiwidHBwSW5mb3JtYXRpb24iOiJ7XCJpZFRwcFwiOlwiRUJBWVwiLFwibmFtZVwiOlwiTU9DSyBUUFAgTkFNRVwiLFwiYWNjcmVkaXRhdGlvbnNcIjpbXCJQSVNcIixcIkFJU1wiXX0iLCJ1c2VyX25hbWUiOiJjYmFyaWxsZXQiLCJzaWduYXR1cmVJbmZvcm1hdGlvbiI6eyJpZFBzdSI6IlNNSVRIIiwidHlwZVNpZ25hdHVyZSI6Ik0yIiwic2lnbmF0dXJlRGF0ZSI6eyJ5ZWFyIjoyMDE3LCJtb250aCI6IlNFUFRFTUJFUiIsImRheU9mTW9udGgiOjExLCJkYXlPZldlZWsiOiJNT05EQVkiLCJkYXlPZlllYXIiOjI1NCwibW9udGhWYWx1ZSI6OSwiaG91ciI6NywibWludXRlIjoxOSwic2Vjb25kIjozNywibmFubyI6MTAwMDAwMDAsImNocm9ub2xvZ3kiOnsiaWQiOiJJU08iLCJjYWxlbmRhclR5cGUiOiJpc284NjAxIn19LCJzaWduYXR1cmVUb2tlbiI6IlNpZ25hdHVyZVRva2VuIFRlc3QifSwic2NvcGUiOlsiQUlTIl0sImNvbm5lY3RvckluZm9ybWF0aW9uIjoie1wiY2JzVXNlckxvZ2luXCI6XCJNT0NLIENCUyBVU0VSIExPR0lOXCIsXCJqU2Vzc2lvbklkXCI6XCJNT0NLIEpTRVNTSU9OSURcIixcInNpZFwiOlwiTU9DSyBTSURcIn0iLCJleHAiOjY1MDYxMDg5NzYsImF1dGhvcml0aWVzIjpbIlVTRVIiLCJBRE1JTiJdLCJqdGkiOiI1YmUxYmZkNi01YTUxLTQzNmEtOGE1NS0wZTBiNzkxNDQ3NGEiLCJjbGllbnRfaWQiOiJiYW5raW40IiwiY3VzdG9tRmllbGQiOiJjb20uc29wcmFiYW5raW5nLmR4cC5hdXRoLmNvbmZpZy5DdXN0b21Vc2VyQDdmYmY3NmU1W3VzZXJuYW1lPWNiYXJpbGxldCxhdXRob3JpdGllcz1bQURNSU4sIFVTRVJdXSIsInBzdUluZm9ybWF0aW9uIjoie1wiaWRQc3VcIjpcIjAwMDAwMDlUXCIsXCJuYW1lXCI6XCJNT0NLIFBTVSBOQU1FXCJ9In0.NHXHa-QhK5RfkMPUW75V1kD4pKy5krK_Us7DibCCskkYlosBe8LkQN7DomP_WKLmb6YW_biRyFbZ8YSI2rJM-2sZq_A-Og9MgZQgXC2YnAKVRWufYSdTUlG0yOq3QCBvuML20a_m7zaqqx3d2h324PlVrESW0ok67xql5r6mlWTkzvETzVwTa1E7NP_9F0pH9EiBAN6R7MbjpRcryAh0ulITBXsf8ryZ_RhsBuE7JpArBvuR3ro-N0zdf9MgLrU2VqpxyRrDZlUjcTU5Chdmf6EE2iuRYRh9GH_SJW5vcI6sztUYVXpOFq_uyfNBk3b9Gnndp2Gcl1mdksI_-NMavA';

	constructor(
		httpStatusManagementService: HttpStatusManagementService,
		httpService: HttpService,
		configurationService: ConfigurationService
	) {
		super(httpStatusManagementService, httpService, configurationService);
	}

	protected createUrl(ep: EndPointModel, parameters: ParameterModel[], method: string): Observable<string> {
		const keyId = this.configurationService.instant('dxp.api.key');

		return super.createUrl(ep, parameters, method).pipe(
			map((url) => {
				return url + ((url.indexOf('?') > 0) ? '&' : '?') + 'KeyId=' + keyId;
			}));
	}

	protected addHeaders(headers: { [name: string]: string | string[]; }): { [name: string]: string | string[]; } {
		super.addHeaders(headers);
		headers['KeyId'] = this.configurationService.instant('dxp.api.key');
		return headers;
	}

	protected throwResponseOnHttpCallFailure(err: HttpErrorResponse): Observable<string> {
		const exception: DxpException = err.error;
		if (isNullOrUndefined(exception.error.code)
			|| isNullOrUndefined(exception.error.message)) {
			return super.throwResponseOnHttpCallFailure(err);
		}

		const message = exception.error.code + ': ' + exception.error.message;
		const serviceResponse = new ServiceResponse<null>();
		serviceResponse.pushFeedback(<Feedback>{
			defaultMessage: message,
			key: `error_${err.status}`,
			type: 'error'
		});

		return throwError(serviceResponse);
	}
}
