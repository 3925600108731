import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[noCopyPaste]'
})
export class NoCopyPasteDirective {

	@HostListener('copy', ['$event'])
	@HostListener('paste', ['$event'])
	@HostListener('cut', ['$event'])
	public prevent(event: KeyboardEvent) {
		event.preventDefault();
	}

}
