import { Injectable } from '@angular/core';
import { InternationalPaymentDetails, PaymentOperation } from '@mdib/payments';
import { SignableOperation } from '@mdib/signature';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';

@Injectable()
export abstract class InternationalPaymentsService {

	/**
	 * Validate international payment operation
	 * @param {PaymentOperation<StandingOrderDetails>} payment
	 * @return {Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>}
	 */
	public abstract validate(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>>;

	/**
	 * Confirm international payment operation
	 * @param {PaymentOperation<InternationalPaymentDetails>} payment
	 * @return {Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null> | null>}
	 */
	public abstract confirm(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null> | null>;

	/**
	 * Get international payment operation
	 * @param {string} reference
	 * @returns {Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>>}
	 */
	public abstract get(reference: string): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>>;

	/**
	 * Get an international payment operation from the shopping basket
	 * @param {string} reference
	 * @returns {Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>>}
	 */
	public abstract getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>>;

	/**
	 * Sign international payment operation
	 * @param {PaymentOperation<InternationalPaymentDetails>} payment
	 * @returns {Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>>}
	 */
	public abstract sign(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>>;

	/**
	 * Delete an international payment in pending payments
	 * @param {string} reference of the payment
	 * @returns {Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>>}
	 */
	public abstract delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>> | null>> ;

	/**
	 * Sign the deletion of an international payment
	 * @param {SignableOperation<any>} signablePaymentOperation
	 * @returns {Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>> | null>>}
	 */
	public abstract signDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>> | null>> ;

	/**
	 * Confirm the deletion of an international payment
	 * @param {string} reference
	 * @returns {Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>> | null>>}
	 */
	public abstract confirmDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>> | null>> ;
}

