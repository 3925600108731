import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XclHttpService } from './service/xcl-http.service';
import { FunctionalFeedbacksFromXclOrderExtractor } from './service/functional-feedbacks-from-xcl-order-extractor.service';

/**
 * Gathers all of the common components related to the XCL adaptor.
 */
@NgModule({
	imports: [
		CommonModule,
	],
	providers: [
		XclHttpService,
		FunctionalFeedbacksFromXclOrderExtractor,
	],
})
export class XclHttpModule { }
