import { ManagedCurrencyCodes } from '@mdib/config';

export class PendingPaymentsPageFilter {
	ordererAccountNumber: string;
	counterpartyName: string;
	counterpartyAccountNumber: string;
	maturityDateFrom: Date;
	maturityDateTo: Date;
	amountFrom: number;
	amountTo: number;
	currency: ManagedCurrencyCodes;
}
