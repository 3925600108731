import { AccountValuation } from '@mdib/account-valuations';
import * as moment from 'moment/moment';

export class MockAccountValuations {

	static getMockAccountValuations(): AccountValuation[] {
		return [
			<AccountValuation>{
				identifier: '0001',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2018-05-29').toDate(),
				totalInterest: '+3.61',
				operationReference: 'A1I30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0002',
				accountNumber: 'BE95750642304658',
				valuationDate: moment('2018-05-10').toDate(),
				totalInterest: '+2.48',
				operationReference: 'A2I30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0003',
				accountNumber: 'BE95750642304658',
				valuationDate: moment('2018-02-21').toDate(),
				totalInterest: '-1.10',
				operationReference: 'A3I30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0004',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2018-01-30').toDate(),
				totalInterest: '+3.11',
				operationReference: 'A4I30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0005',
				accountNumber: 'BE95750642304658',
				valuationDate: moment('2017-12-26').toDate(),
				totalInterest: '-2.51',
				operationReference: 'A5I30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0006',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2017-10-25').toDate(),
				totalInterest: '+0.88',
				operationReference: 'A6I30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0007',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2017-10-13').toDate(),
				totalInterest: '+2.51',
				operationReference: 'A7I30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0008',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2017-09-15').toDate(),
				totalInterest: '-0.22',
				operationReference: 'A8I30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0009',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2017-09-04').toDate(),
				totalInterest: '-2.19',
				operationReference: 'A9I30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0010',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2017-08-15').toDate(),
				totalInterest: '+3.10',
				operationReference: 'A0H30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0011',
				accountNumber: 'BE95750642304658',
				valuationDate: moment('2017-07-27').toDate(),
				totalInterest: '+1.10',
				operationReference: 'A1H30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0012',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2017-06-13').toDate(),
				totalInterest: '+4.22',
				operationReference: 'A2H30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0013',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2017-04-04').toDate(),
				totalInterest: '+0.30',
				operationReference: 'A3H30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0014',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2017-03-14').toDate(),
				totalInterest: '-0.55',
				operationReference: 'A4H30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0015',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2016-12-23').toDate(),
				totalInterest: '-3.10',
				operationReference: 'A5H30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0016',
				accountNumber: 'BE95750642304658',
				valuationDate: moment('2016-11-21').toDate(),
				totalInterest: '-1.10',
				operationReference: 'A6H30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0017',
				accountNumber: 'BE95750642304658',
				valuationDate: moment('2016-11-14').toDate(),
				totalInterest: '+0.33',
				operationReference: 'A7H30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0018',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2016-10-10').toDate(),
				totalInterest: '+2.97',
				operationReference: 'A8H30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0019',
				accountNumber: 'BE95750642304658',
				valuationDate: moment('2016-07-18').toDate(),
				totalInterest: '+2.62',
				operationReference: 'A9H30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0020',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2016-07-11').toDate(),
				totalInterest: '-0.98',
				operationReference: 'A0L30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0021',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2016-04-19').toDate(),
				totalInterest: '-0.39',
				operationReference: 'A1L30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0022',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2016-04-18').toDate(),
				totalInterest: '+2.04',
				operationReference: 'A2L30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0023',
				accountNumber: 'BE95750642304658',
				valuationDate: moment('2016-02-09').toDate(),
				totalInterest: '+4.30',
				operationReference: 'A3L30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0024',
				accountNumber: 'BE56305177635588',
				valuationDate: moment('2015-11-30').toDate(),
				totalInterest: '-3.41',
				operationReference: 'A4L30IF0000A000U'
			},
			<AccountValuation>{
				identifier: '0025',
				accountNumber: 'BE95750642304658',
				valuationDate: moment('2015-11-06').toDate(),
				totalInterest: '-2.45',
				operationReference: 'A5L30IF0000A000U'
			},
		];
	}
}
