import { Feedback } from './feedback';

/**
 * @export
 * @class ServiceResponse
 * @template T
 * @description My role is to wrap functional Model and Feedbacks
 */
export class ServiceResponse<T> {

	constructor(
		private model: T = null,
		private feedbacks: Array<Feedback> = [],
		public properties: any = {},
	) { }

	public static emptyWithOnefeedback(feedback: Feedback): ServiceResponse<null> {
		return new ServiceResponse<null>(null, [feedback]);
	}

	/**
	 * @returns {T}
	 * @memberof ServiceResponse
	 */
	public getModel(): T {
		return this.model;
	}

	/**
	 * @param {T} model
	 * @returns {ServiceResponse<T>}
	 * @memberof ServiceResponse
	 */
	public setModel(model: T): ServiceResponse<T> {
		this.model = model;

		return this;
	}

	/**
	 * @param {Feedback} feedback
	 * @returns {ServiceResponse<T>}
	 * @memberof ServiceResponse
	 */
	public pushFeedback(feedback: Feedback): ServiceResponse<T> {
		this.feedbacks.push(feedback);

		return this;
	}

	/**
	 * @returns {Array<Feedback>}
	 * @memberof ServiceResponse
	 */
	public getFeedbacks(): Array<Feedback> {
		return this.feedbacks;
	}

	/**
	 * @param {string} type The type to filter on
	 * @returns {Array<Feedback>}
	 * @memberof ServiceResponse
	 */
	public getFeedbacksByType(type: string): Array<Feedback> {
		return this.feedbacks.filter((e: Feedback) => e.type === type);
	}
}
