import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';

@Injectable()
export class TranslationLoaderMock implements TranslateLoader {

	getTranslation(lang: string): Observable<any> {
		return of({});
	}
}
