import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
	selector: 'app-signature-type-selector',
	templateUrl: './signature-type-selector.component.html',
	styleUrls: ['./signature-type-selector.component.scss']
})
export class SignatureTypeSelectorComponent implements OnInit {

	@Input()
	public types: any[];

	@Output()
	public onSelected: EventEmitter<string> = new EventEmitter<string>();

	@Output()
	public onError: EventEmitter<any> = new EventEmitter<any>();

	ngOnInit() {
		// Auto Select the only type
		if (this.types.length === 1) {
			window.setTimeout(() => { this.selectType(this.types[0]); }, 1);
		}
	}

	selectType(type) {
		this.onSelected.emit(type);
	}

}
