import { Component, Input } from '@angular/core';
import { CashAccountOperation } from '../../model/cash-account-operation';

/**
 * Displays the content of a {@link CashAccountOperation}
 * @example
 * <mdib-cash-account-operation-detail [cashAccountOperation]="cashAccountOperation"></mdib-cash-account-operation-detail>
 */
@Component({
	selector: 'mdib-cash-account-operation-detail',
	templateUrl: './cash-account-operation-detail.component.html',
	styleUrls: ['./cash-account-operation-detail.component.scss'],
})
export class CashAccountOperationDetailComponent {

	@Input() cashAccountOperation: CashAccountOperation;
	@Input() cashAccountClientWording: string;

	readonly NAMESPACE = 'cashAccountsModule.cashAccountOperationDetailComponent.';

	/**
	 * Check if the duplication of payment operation is allowed or not.
	 * @returns boolean: "true"  if it is a Debit operation  (outgoing payment)
	 *                   "false" if it is a Credit operation (incoming payment)
	 */
	isDuplicationAllowed() {
		return this.cashAccountOperation.isDebitOperation;
	}
}
