import { NgModule, Inject, ModuleWithProviders } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { PasswordProcessService, OtpSignatureService, DigipassSignatureService } from '@mdib/signature-modes';

import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';

import { PasswordProcessXclService } from './service/password-process-xcl.service';
import { DigipassSignatureXclService } from './service/digipass-signature-xcl.service';
import { OtpSignatureXclService } from './service/otp-signature-xcl.service';

@NgModule()
export class SignaturesXclModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(PasswordProcessService, adapterId, PasswordProcessXclService);
		adaptersService.setAdapter(DigipassSignatureService, adapterId, DigipassSignatureXclService);
		adaptersService.setAdapter(OtpSignatureService, adapterId, OtpSignatureXclService);
	}

	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: SignaturesXclModule
		};
	}
}
