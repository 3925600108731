import { Injectable } from '@angular/core';
import { AuthenticationStatisticData } from '../../model/authentication-statistic-data';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';

@Injectable()
export abstract class AuthenticationDataService {

	/**
	 * Retrieves statistic data about authentication, like the last connection time.
	 * @returns {Observable<ServiceResponse<AuthenticationStatisticData[]>>}
	 */
	abstract getStatistics(): Observable<ServiceResponse<AuthenticationStatisticData[]>>;
}
