import { Component, OnInit, Injector } from '@angular/core';
import { GenericListPage } from '@mdib/generic';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { ServiceResponse, UtilsHelper } from '@mdib/utils';
import { IconButton } from '@mdib/styling';
import { CommonDataSource } from '@mdib/generic';
import { ElectronicalStatement, ElectronicalStatementsService, ElectronicalStatementsPageFilter, ElectronicalStatementsFormat } from '@mdib/electronical-statements';
import { saveAs } from 'file-saver/FileSaver';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AccountStatementStatus } from '@mdib/account-statements';
import { CashAccount } from '@mdib/cash-accounts';
import { map, tap } from 'rxjs/operators';

@Component({
	selector: 'electronical-statements-page',
	templateUrl: './electronical-statements-page.component.html',
	styleUrls: ['./electronical-statements-page.component.scss']
})

export class ElectronicalStatementsPageComponent extends GenericListPage implements OnInit {

	readonly NAMESPACE = 'electronicalStatementsPagesModule.electronicalStatementsPageComponent.';

	public filter = <ElectronicalStatementsPageFilter>{
		accountNumber: null,
		status: AccountStatementStatus.New,
		format: ElectronicalStatementsFormat.MT940,
		cashAccount: null
	};

	public displayedColumns = ['account', 'format', 'date', 'number', 'status', 'actions'];
	protected iconArray: Array<IconButton> = [{ iconName: 'file_download', iconLabel: 'download' }];
	protected serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService;
	protected accountNumber: string;

	constructor(private electronicalStatementsService: ElectronicalStatementsService,
		private route: ActivatedRoute,
		injector: Injector) {
		super(injector);
		this.dataSourceType = CommonDataSource;
		this.listConfigurations.listTitle = this.NAMESPACE + 'electronicalStatementsTitle';
		this.listConfigurations.selectorTitle = this.NAMESPACE + 'accountLabel';
		this.listConfigurations.listEmptyMessage = this.NAMESPACE + 'listEmptyMessage';
		this.listConfigurations.filterOpenedByDefault = true;
		this.listConfigurations.showFilterHeader = false;
		this.listConfigurations.showSelector = false;
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.loadSearchModel(params, this.filter);
			this.fetchData();
		});
	}

	consultRow(row: any): void {
		// Nothing to do
	}

	clearFilter(): void {
		this.filter = {
			accountNumber: this.filter ? this.filter.accountNumber : '',
			status: AccountStatementStatus.New,
			format: ElectronicalStatementsFormat.MT940,
			cashAccount: null
		};

		this.applyFilter();
	}

	downloadFile(electronicalStatement: ElectronicalStatement): void {
		this.electronicalStatementsService.downloadStatement(electronicalStatement)
			.subscribe((serviceResponse: ServiceResponse<any>) => {
			this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponse, this.NAMESPACE);
			if (!serviceResponse) {
				this.serviceResponseNotifMapper.sendResponseFeedbacks(new ServiceResponse('No data file to download for electronical statement ' + electronicalStatement.identifier));
			} else {
				saveAs(serviceResponse.getModel(), electronicalStatement.file.name);
			}
			electronicalStatement.status = AccountStatementStatus.Downloaded;
		},
		(serviceResponseError: ServiceResponse<null>) => {
			this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
		});
	}

	/**
	 * This method is called to retrieve a file of an account statement.
	 * @param row
	 * @returns {Observable<MdibFile>}
	 */
	getElectronicalStatement(row: any): Observable<ElectronicalStatement> {
		const sendFeedbacks = (response: ServiceResponse<null>) => this.serviceResponseNotifMapper.sendResponseFeedbacks(response, this.NAMESPACE);
		const extractStatement = (serviceResponse: ServiceResponse<ElectronicalStatement>) => serviceResponse.getModel();

		return this.electronicalStatementsService.get(row.identifier)
			.pipe(tap(sendFeedbacks, sendFeedbacks), map(extractStatement));
	}

	onCashAccountChange(cashAccount: CashAccount): void {
		this.accountNumber = (cashAccount != null) ? cashAccount.number : null;
		this.applyFilter();
	}

	protected load(index?: number, count?: number): Observable<ServiceResponse<any>> {
		return this.electronicalStatementsService.list(index, count, this.filter);
	}

}
