import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthorizationsService } from '../services/authorizations.service';

@Directive({
	selector: '[mdibIfNotAuthorized]'
})
export class IfNotAuthorizedDirective {

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private authorizationService: AuthorizationsService,
	) { }

	@Input()
	public set mdibIfNotAuthorized(tokens) {
		if (this.authorizationService.authorized(tokens)) {
			this.viewContainer.clear();
		} else {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}

}
