import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ServiceResponse } from '@mdib/utils';

import { Customer } from '../../model/customer';

@Injectable()
export abstract class CustomersService {

	/**
	 * Retrieve a {Customer} based on its unique identifier
	 * The first element from the list of {Holders} must be the main {Holder} linked to the {Customer}
	 *
	 * @param id The unique identifier of the customer
	 * @param offset Offset to start from
	 * @param limit - Maximum number of results , that must be returned by list operation
	 * @returns {Observable<ServiceResponse<Customer>>}
	 */
	public abstract get(id: string, offset?: number, limit?: number): Observable<ServiceResponse<Customer>>;

}
