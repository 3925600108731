import { Injectable } from '@angular/core';
import * as jsPDF from 'jspdf';

@Injectable()
export class AccountStatementsGenerationFileXclService {

	public generationPDF(file: string): Blob {
		const doc = new jsPDF();
		const splitFile = doc.splitTextToSize(file, 240);
		doc.setFontSize(12);
		doc.text(10, 10, splitFile);

		return doc.output('blob');
	}

	public generationMT940(file: string): Blob {
		const doc = new jsPDF();
		return doc.output('blob');
	}

	public generationCAMT053(file: string): Blob {
		const doc = new jsPDF();
		return doc.output('blob');
	}
}
