import { delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
// import { MockCashAccountOperation } from '@mdib/cash-accounts/testing/mock/mock-xcl-cash-account-operation';
import { CashAccountOperation, CashAccount } from '@mdib/cash-accounts';
import { ConfigService } from '@mdib/config';
import { PaymentsCommonService, PaymentOperation, PaymentTypes, PendingPayment, PendingPaymentsPageFilter } from '@mdib/payments';
import { Communication, CommunicationTypes } from '@mdib/commons';
import { SignableOperation } from '@mdib/signature';
import { SignatureModes } from '@mdib/signature-modes';
import { ServiceResponse, Status } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { DraftPaymentsMock } from '../mock/draft-payments-mock';
import { PaymentOperationMock } from '../mock/payment-operation-mock';
import { PendingPaymentsMock } from '../mock/pending-payments-mock';
import { ContactAddress } from '@mdib/customers';
import { Beneficiary } from '@mdib/beneficiaries';
import { CashAccountBuilder } from '@mdib/cash-accounts';
import { MockCashAccountOperation } from '@mdib-xcl/cash-accounts/testing/mock/mock-xcl-cash-account-operation';


@Injectable({
	providedIn: 'root'
})
export class PaymentMemoryService extends PaymentsCommonService {

	readonly draftPayments = new DraftPaymentsMock().draftPayments;
	readonly pendingPayments = PendingPaymentsMock.pendingPayments;
	readonly DEFAULT_DELAY = 400;

	public get(reference: string, type: PaymentTypes): Observable<ServiceResponse<PaymentOperation<any>>> {
		let operation = new PaymentOperation<null>();
		operation.type = PaymentTypes.sepaCreditTransfer;
		if (reference) {
			operation = PaymentOperationMock.retrievePaymentOperation(operation);
		} else {
			const pendingPayment: PendingPayment = this.pendingPayments.filter(
				payment => payment.reference === reference,
			).pop();
			operation = new PaymentOperation<null>(<PaymentOperation<null>>{
				amount: pendingPayment.amount,
				counterParty: <Beneficiary>{
					fullName: pendingPayment.counterpartyName,
					account: new CashAccountBuilder().accountNumber(pendingPayment.counterpartyAccountNumber).clientWording(pendingPayment.ordererName).cashAccount,
					communication: new Communication(),
				},
				currency: pendingPayment.currency,
				paymentDate: pendingPayment.paymentDate,
				ordererAccount: new CashAccountBuilder().accountNumber(pendingPayment.ordererAccountNumber).cashAccount,
				type: type,
				reference: pendingPayment.reference,
				operationStatus: pendingPayment.status,
			});
		}
		return of(new ServiceResponse<PaymentOperation<any>>(operation)).pipe(delay(500));
	}

	public validatePayment(paymentOperation: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>> {
		if (paymentOperation.amount < 10) {
			paymentOperation.status = Status.ToConfirm;
		} else {
			paymentOperation.status = Status.ToSign;
		}
		paymentOperation.signatureTypesAllowed = [SignatureModes.PASSWORD];
		paymentOperation.counterParty.account.bic = paymentOperation.counterParty.account.bic || 'DEUTBEBEXXX';
		if (!paymentOperation.paymentDate) {
			paymentOperation.paymentDate = paymentOperation.paymentDate || new Date();
		}
		return of(new ServiceResponse<PaymentOperation<null>>(paymentOperation));
	}

	public confirmPayment(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>> {
		if (payment.status === Status.ToConfirm) {
			payment.status = Status.Closed;
		} else {
			payment.status = Status.InShoppingBasket;
		}
		return of(new ServiceResponse<PaymentOperation<null>>(payment));
	}

	public getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<null> | null>> {
		const paymentOperation = this.draftPayments.find((payment: PaymentOperation<null>) => payment.reference === reference);
		return of(new ServiceResponse<PaymentOperation<null>>(paymentOperation));
	}

	public signPayment(paymentOperation: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>> {
		paymentOperation.status = Status.Closed;
		return of(new ServiceResponse<PaymentOperation<null>>(paymentOperation));
	}

	public delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>> {



		let operation;
		operation = this.pendingPayments.filter(
			payment => payment.reference === reference,
		).pop();

		operation = <PaymentOperation<null>>{
			amount: operation.amount,
			counterParty: <Beneficiary>{
				fullName: operation.counterpartyName,
				account: new CashAccountBuilder().accountNumber(operation.counterpartyAccountNumber).clientWording(operation.ordererName).cashAccount,
				communication: new Communication({
					value: operation.communication,
					type: operation.communicationType,
				}),
			},
					// counterpartyAccountNumber: operation.counterpartyAccountNumber,
					// counterpartyName: operation.counterpartyName,
			currency: operation.currency,
			paymentDate: operation.maturityDate,
			ordererAccount: operation.ordererAccountNumber,
			type: PaymentTypes.sepaCreditTransfer,

			reference: operation.reference,

			status: operation.status,
		};

		const signableOperation = new SignableOperation<PaymentOperation<null>>(operation);
		// The following is done to make the signature component visible when pending payment is deleted
		signableOperation.status = Status.ToSign;
		signableOperation.signatureTypesAllowed = [SignatureModes.PASSWORD];
		return of(new ServiceResponse<SignableOperation<PaymentOperation<null>>>(signableOperation)).pipe(delay(100));

	}

	public signDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {

		this.pendingPayments.splice(this.pendingPayments.findIndex((pendingPayment: PendingPayment) => pendingPayment.reference === signablePaymentOperation.model.reference), 1);
		signablePaymentOperation.status = Status.Closed;
		return of(new ServiceResponse<SignableOperation<PaymentOperation<null>>>(signablePaymentOperation));
	}

	public confirmDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>> {
		signablePaymentOperation.model.status = (signablePaymentOperation.model.status === Status.ToConfirm) ? Status.Closed : Status.InShoppingBasket;
		return of(new ServiceResponse<SignableOperation<PaymentOperation<null>>>(signablePaymentOperation)).pipe(delay(this.DEFAULT_DELAY));
	}

	count(filter?: PendingPaymentsPageFilter): Observable<ServiceResponse<number>> {
		return of(new ServiceResponse<number>(
			filter ? this.getFilteredPendingPaymentsList(filter).length : this.pendingPayments.length)).pipe(delay(this.DEFAULT_DELAY));
	}

	list(index: number, count: number, filter?: PendingPaymentsPageFilter): Observable<ServiceResponse<PendingPayment[]>> {
		const results = this.getFilteredPendingPaymentsList(filter);
		return of(new ServiceResponse(
			results.slice(index || 0, (index + count) || undefined),
			[],
			{ totalCount: results.length },
		)).pipe(delay(this.DEFAULT_DELAY));
	}

	getFilteredPendingPaymentsList(filter: PendingPaymentsPageFilter): PendingPayment[] {
		return this.pendingPayments.filter(
			(pendingPayment: PendingPayment) => {
				if (!filter) { return true; }
				if (filter.ordererAccountNumber && !pendingPayment.ordererAccountNumber.startsWith(filter.ordererAccountNumber)) {
					return false;
				}
				if (filter.maturityDateFrom && pendingPayment.paymentDate < filter.maturityDateFrom) {
					return false;
				}
				if (filter.maturityDateTo && pendingPayment.paymentDate > filter.maturityDateTo) {
					return false;
				}
				if (filter.amountFrom && pendingPayment.amount < filter.amountFrom) {
					return false;
				}
				if (filter.amountTo && pendingPayment.amount > filter.amountTo) {
					return false;
				}
				if (filter.counterpartyName && pendingPayment.counterpartyName.toUpperCase().indexOf(filter.counterpartyName.toUpperCase()) < 0) {
					return false;
				}
				if (filter.counterpartyAccountNumber && !pendingPayment.counterpartyAccountNumber.startsWith(filter.counterpartyAccountNumber)) {
					return false;
				}
				return true;
			});
	}

	getFilteredPendingPaymentsListCropped(start: number, end: number, filter: PendingPaymentsPageFilter): PendingPayment[] {
		return this.getFilteredPendingPaymentsList(filter).slice(start, end);
	}
}
