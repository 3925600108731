import { Injectable } from '@angular/core';
import { ElectronicalStatementsPageFilter } from '../model/electronical-statements-page-filter';
import { ElectronicalStatementsService } from './electronical-statements.service';
import { AccountStatementStatus } from '@mdib/account-statements';

@Injectable()
export abstract class ElectronicalStatementsCommonService extends ElectronicalStatementsService {

	public initializeFilter(): ElectronicalStatementsPageFilter {

		const filter: ElectronicalStatementsPageFilter = {
			accountNumber: '',
			status: AccountStatementStatus.New,
			format: null,
			cashAccount: null
		};
		return filter;
	}
}
