import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { PasswordProcessService } from './password-process.service';
import { PasswordProcessServiceStub } from './password-process.service.stub';

@Injectable()
export class PasswordProcessServiceProxy extends AdapterProxy {
	protected ServiceToken() { return PasswordProcessService; }
	protected ServiceDefinition() { return PasswordProcessServiceStub; }
}

