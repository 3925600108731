import { NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { ShoppingBasketService } from '@mdib/shopping-basket';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';
import { ShoppingBasketMemoryService } from './service/shopping-basket-memory.service';

@NgModule()
export class ShoppingBasketMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(ShoppingBasketService, adapterId, ShoppingBasketMemoryService);
	}
}
