import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BackendOperationStep, BackendOperationState } from '@mdib/core/adapters';
import { SepaDirectDebitPayment } from '@mdib/sepa-direct-debits';

import { BackendOperationXcl, DecisionTree, XclCallConfiguration } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';

import { SepaDirectDebitPaymentXcl } from '../../model/sepa-direct-debit-payment-xcl';
import { SepaDirectDebitPaymentsMapperXclService } from '../sepa-direct-debit-payments-mapper-xcl.service';

export class SepaDirectDebitPaymentsRetrieveXcl extends BackendOperationXcl<string, SepaDirectDebitPayment, SepaDirectDebitPaymentXcl> {

	protected sepaDirectDebitPaymentsMapperXclService: SepaDirectDebitPaymentsMapperXclService;

	protected xclEndpoint = 'xcl.sepaDirectDebit.payments.retrieve';
	// State machine
	protected decision: DecisionTree = {
		'____': {
			triggers: { 'retrieve': this.retrieve },
			defaultTrigger: 'retrieve',
			expectedStates: ['9000'],
		},
	};

	constructor(injector: Injector) {
		super(injector);
		this.sepaDirectDebitPaymentsMapperXclService = injector.get(SepaDirectDebitPaymentsMapperXclService);
	}

	protected handleResponse(order: MonogoalOrderXCLModel<SepaDirectDebitPaymentXcl>): Observable<SepaDirectDebitPayment> {
		return super.handleResponse(order).pipe(map(() => {
			return this.sepaDirectDebitPaymentsMapperXclService.fromXclToFunctional(order.goal);
		}));
	}

	protected retrieve(trigger: string, paymentReference?: string): Observable<BackendOperationStep<SepaDirectDebitPayment>> {
		return this.calls(trigger, [
			new XclCallConfiguration({ resourceId: paymentReference, method: XclHttpService.GET, state: BackendOperationState.SUCCEEDED })
		]);
	}

}
