import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ArtificialPerson, NaturalPerson, PersonMandate, PersonsService } from '@mdib/customers';
import { ServiceResponse } from '@mdib/utils';

import { activeUserPerson, activeUserPersonMandate1, activeUserPersonMandate2 } from '../mock/persons-mock';

@Injectable({
	providedIn: 'root'
})
export class PersonsMemoryService extends PersonsService {


	get(): Observable<ServiceResponse<NaturalPerson | ArtificialPerson>> {
		return of(new ServiceResponse<NaturalPerson>(activeUserPerson));
	}

	getMandates(): Observable<ServiceResponse<PersonMandate[]>> {
		const personMandates = [
			<PersonMandate>{ person: activeUserPerson },
			<PersonMandate>{ person: activeUserPersonMandate1 },
			<PersonMandate>{ person: activeUserPersonMandate2 },
		];
		return of(new ServiceResponse<PersonMandate[]>(personMandates));
	}

	getRepresentedClientNumber(): Observable<string> {
		return of('00000ABC');
	}

}
