export enum SvgIconPath {
	DIGIPASS = 'assets/images/digipass.png',
	EID = 'assets/images/eid.svg',
	FINGERPRINT = 'assets/images/fingerprint.svg',
	MAIL = 'assets/images/mail.svg',
	PASSWORD = 'assets/images/password.svg',
	SMS = 'assets/images/sms.svg',
	UCR = 'assets/images/ucr.svg',
	NWBLOGO = '/assets/images/nwbColoredLogo.svg',
	SMALL_LOGO = 'assets/images/small-logo.svg',
	OTP = 'assets/images/sms.svg',
}

export enum SvgIconName {
	NWBLOGO = 'NWBLOGO',
}

export enum CurrenciesIconNames {
	EUR = 'euro_symbol',
	USD = 'attach-money',
}

/**
 * Account Icons names from material library.
 */
export enum AccountTypesIcons {
	CurrentAccount = 'account_balance_wallet',
	NoticeAccount = 'pan_tool',
	SavingsAccount = 'account_balance',
	Unknown = 'live_help',
}

export enum Themes {
	b2b = 'b2b-theme',
	b2c = 'b2c-theme'
}
