import { AbstractControl, FormGroup } from '@angular/forms';
import { BankConfig } from '@mdib/config';
import * as moment from 'moment';
import { Moment } from 'moment';
import { isNullOrUndefined } from 'util';
import { UtilsHelper } from '../helper/utils-helper';
import { InputError } from '../model/utils.typings';

export namespace DateValidator {

	export const NAMESPACE = 'notification:errors:inputs:dates:';
	export const DATEFROMTODAY = {dateFromToday: <InputError> {translationKey: 'notification:errors:inputs:dates:dateFromToday'}};
	export const DATENOTAFTERTODAY = {dateNotAfterToday: <InputError> {translationKey: 'notification:errors:inputs:dates:dateNotAfterToday'}};
	export const INVALIDFORMAT = {dateNotAfterToday: <InputError> {translationKey: 'notification:errors:inputs:dates:invalidFormat'}};
	export const ISBEFORE = {isBefore: <InputError> {translationKey: 'notification:errors:inputs:dates:isBefore'}};
	export const DATEMAX = {dateMax: <InputError> {translationKey: 'notification:errors:inputs:dates:dateMax', params: {dateMax: BankConfig.withdrawalSimulationDelay + ''}}};

	export function dateFromToday(control: AbstractControl): {[key: string]: any} | null {
		return UtilsHelper.isNullOrWhiteSpace(control.value) || moment(control.value).isSameOrAfter(new Date(), 'day') ? null : DATEFROMTODAY;
	}

	/**
	 * Checks if entered date is not after today
	 * @param {AbstractControl} control : containing either selected or manually entered dates
	 * @return {{[p: string]: any} | null}
	 */
	export function dateNotAfterToday(control: AbstractControl): {[key: string]: any} | null {
		if (!UtilsHelper.isNullOrWhiteSpace(control.value)) {
			const tested: Moment = moment(control.value);
			return tested.isSameOrBefore(new Date(), 'day') ? null : DATENOTAFTERTODAY;
		}
		return null;
	}

	/**
	 * Check if the first date is before second
	 * @param value1
	 * @param value2
	 * @returns {(group: FormGroup) => {[p: string]: any}}
	 */
	export function isBefore(path1: string, path2: string) {
		return (group: FormGroup): {[key: string]: any} => {
			const start: Moment = group.controls[path1] && group.controls[path1].value;
			const end: Moment = group.controls[path2] && group.controls[path2].value;
			return !!start && !!end && start.isAfter(end) ? ISBEFORE : null;
		};
	}

	/**
	 * This is format checker for string typed in date input if you want to be more restrictive
	 * @param {AbstractControl} control : containing either selected or manually entered dates
	 * @return {{[p: string]: any} | null}
	 */
	export function checkFormat(control: AbstractControl): {[key: string]: any} | null {
		if (!UtilsHelper.isNullOrWhiteSpace(control.value) && typeof control.value._i === 'string') {
			const dateRegex = /^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/g;
			return control.value.isValid() && dateRegex.test(control.value._i) ? null : INVALIDFORMAT;
		}
		return null;
	}

	/**
	 * Check if a withdrawal simulation is not selected after the delay
	 * @param {AbstractControl} control : containing either selected or manually entered dates
	 * @return {{[p: string]: any} | null} : error related to whitdrawal date selected after max date
	 */
	export function withdrawalMaxDate(control: AbstractControl): {[key: string]: any} | null {
		const tested: Moment = moment(control.value);
		const maxDate = moment().add(BankConfig.withdrawalSimulationDelay, 'M').toDate();
		return tested.isSameOrBefore(maxDate, 'day') ? null : DATEMAX;
	}

	export function controlHasValue(control: AbstractControl): boolean {
		return !isNullOrUndefined(control.value) && String(control.value).length > 0;
	}
}
