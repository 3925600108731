import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// External parts
import { HeaderComponent } from '@mdib/header/component/header/header.component';
import { FooterComponent } from '@mdib/footer/component/footer/footer.component';

// Internal pages
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AccessDeniedPageComponent } from '../errors/components/access-denied-page/access-denied-page.component';
import { DefaultErrorPageComponent } from '../errors/components/default-error-page/default-error-page.component';

// Defines all the routing
const routes: Routes = [
	{
		path: 'errors',
		children: [
			{
				path: 'page-not-found',
				children: [
					// Content
					{ path: '', component: PageNotFoundComponent },
				]
			},
			{
				path: 'access-denied',
				children: [
					// Header/Footer/Menus
					{ path: '', component: HeaderComponent, outlet: 'header' },
					{ path: '', component: FooterComponent, outlet: 'footer' },
					// Content
					{ path: '', component: AccessDeniedPageComponent },
				]
			},
			{
				path: '**',
				children: [
					// Header/Footer/Menus
					{ path: '', component: HeaderComponent, outlet: 'header' },
					{ path: '', component: FooterComponent, outlet: 'footer' },
					// Content
					{ path: '', component: DefaultErrorPageComponent },
				]
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ErrorsPagesRoutingModule { }
