import { NgModule, ModuleWithProviders } from '@angular/core';

import { AccountStatementsService } from './service/account-statements.service';
import { AccountStatementsServiceProxy } from './service/account-statements.service.proxy';

@NgModule()
export class AccountStatementsModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: AccountStatementsModule,
			providers: [
				{ provide: AccountStatementsService, useClass: AccountStatementsServiceProxy },
			]
		};
	}
}
