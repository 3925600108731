export enum ContractStatus {
	BeingEntered = 'beingEntered',
	ToBeValidated = 'toBeValidated',
	Reopened = 'reopened',
	Suspended = 'suspended',
	Validated = 'validated',
	Active = 'active',
	Closed = 'closed',
	Deleted = 'deleted',
}
