import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// In case of page not found (404), redirect to the login page.
// This special wildcard-routing module should only contain the general wilcard route !
const ROUTES = [
	{
		path: '**', redirectTo: 'session',
	}
];

@NgModule({
	imports: [
		RouterModule.forChild(ROUTES)
	],
	declarations: [
	],
	exports: [
		RouterModule
	]
})
export class AppWildcardRoutingModule { }
