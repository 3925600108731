import { Injectable } from '@angular/core';
import { Authentication } from '@mdib/sessions';
import { PasswordAuthenticationService } from '@mdib/sessions-modes';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';

import { SessionHelper } from '../helpers/session-helper';
import { AuthenticationMemoryService } from './authentication-memory.service';

@Injectable({
	providedIn: 'root',
})
export class PasswordAuthenticationMemoryService extends AuthenticationMemoryService implements PasswordAuthenticationService {

	public authenticate(username: string, password: string): Observable<ServiceResponse<Authentication>> {
		this.setAccessRights(username);
		return of(new ServiceResponse(SessionHelper.mockEnticate(username)));
	}

}
