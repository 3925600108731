export type SearchCriteriaCompiler<T> = (...parameters: Array<any>) => T;

export class SearchCriteria<T> {
	public constructor(
		public type: Function,
		public parameters: Array<any> = [],
	) { }

	public compile(searchOperators: Map<Function, SearchCriteriaCompiler<T>>): T {
		// Find the compiler for the Criteria Type
		const compiler = searchOperators.get(this.type);
		if (!compiler) {
			throw new Error('No compiler for the criteria ' + this.type);
		}

		// Execute it
		return compiler(...this.parameters);
	}
}
