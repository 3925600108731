import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { ConfigService } from '@mdib/config';
import { Chart, ChartData, ChartDataSets, ChartOptions, ChartTooltipItem } from 'chart.js';

import { UtilsHelper } from '../../helper/utils-helper';
import { ChartTypes } from '../../model/utils.enum';
import { MdibCurrencyPipe } from '../../pipe/mdib-currency.pipe';
import { ChartsConfig } from './charts-config';

/**
 * Provides the ability to display chart on the basis of data provided
 * @example
 * <mdib-charts
 *        [data]='chartData'
 *        [label]='chartLabel'
 *        [labelForXAxis]='labelForXAxis'
 *        [type]='ChartTypes.line'
 *        [isDataAmount]='true'>
 * </mdib-charts>
 */

@Component({
	selector: 'mdib-charts',
	templateUrl: './charts.component.html',
	styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent implements OnChanges {

	/**
	 * This parameter indicates which type of chart is to be displayed
	 */
	@Input() chartType: ChartTypes;

	/**
	 * This parameter defines the data for which the chart is displayed
	 */
	@Input() data: number[][];

	/**
	 * This parameter defines the labels of the chart
	 */
	@Input() labels: string[];

	/**
	 * This parameter indicates the label of the X-Axis points of charts
	 * This parameter is not required for pie and doughnut charts
	 */
	@Input() xAxisLabel: string[];

	/**
	 * This boolean parameter indicates whether the input data provided is an amount
	 * If it is ,then it will be formatted and currency symbol will be attached
	 */
	@Input() isDataAmount = false;

	/**
	 * This boolean parameter indicates whether stepped lines for line chart is to be displayed
	 */
	@Input() steppedLine = false;

	@ViewChild('chart') chartElement: ElementRef;

	context: any;
	constructor(private _configService: ConfigService) {
	}

	ngOnChanges(): void {
		const chartData = {
			labels: this.xAxisLabel || this.labels,
			datasets: this.getDatasets()
		};

		this.context = !UtilsHelper.nullOrUndefined(this.context) ? this.context : this.chartElement.nativeElement.getContext('2d');

		this.chartElement.nativeElement = new Chart(
			this.context, {
				'type': this.chartType,
				'data': chartData,
				'options': this.chartOptions,
			}
		);
	}

	/**
	 * Return the datasets based on the data provided in input
	 * @returns ChartDataSets[] : Array of datasets
	 */
	getDatasets(): ChartDataSets[] {
		let datasets = [];
		if (this.data && this.data.length) {
			if (Array.isArray(this.data[0])) {
				datasets = this.data.map((data: number[], index: number) => {
					const color = index < ChartsConfig.chartDefaultColors.length ? ChartsConfig.chartDefaultColors[index] : this.dynamicColors();
					return {
						data,
						label: this.labels[index],
						borderColor: color,
						borderWidth: ChartsConfig.chartBorderSize,
						backgroundColor: this.chartType !== ChartTypes.line ? color : 'transparent',
						steppedLine: this.chartType !== ChartTypes.line ? false : this.steppedLine
					};
				});
			} else {
				datasets = [{
					data: this.data,
					label: this.labels,
					backgroundColor: this.backgroundColors
				}];
			}
		}
		return datasets;
	}

	/**
	 * Return the options or configuration of charts
	 * @returns ChartOptions : configuration of chart
	 */
	private get chartOptions(): ChartOptions {
		let options: ChartOptions = {
			legend: {
				position: 'bottom',
				labels: {
					fontSize: ChartsConfig.chartLegendFontSize,
					fontColor: ChartsConfig.chartLegendFontColor,
					fontFamily: ChartsConfig.chartLegendFontFamily,
				},
			},
		};
		// Set the options for chart which require display of scales e.g. line , radar
		if (this.chartType !== ChartTypes.pie && this.chartType !== ChartTypes.doughnut) {
			const chartsWithScaleOptions: ChartOptions = {
				scales: {
					xAxes: [{
						gridLines: {
							drawOnChartArea: false,
						},
						ticks: {
							fontSize: ChartsConfig.chartFontSize,
							fontColor: ChartsConfig.chartFontColor,
							fontFamily: ChartsConfig.chartFontFamily,
						},
					}],
					yAxes: [{
						ticks: {
							fontSize: ChartsConfig.chartFontSize,
							fontColor: ChartsConfig.chartFontColor,
							fontFamily: ChartsConfig.chartFontFamily,
							callback: this.formatYAxisLabels,
						},
					}],
				},
				elements: {
					line: {
						// This field provides the functionality of line being curved between points
						// When it is set to 0 , the lines in the line graphs will be straight lines
						tension: ChartsConfig.lineChartTension,
					},
					point: {
						radius: 0,
					}
				},
				tooltips: {
					callbacks: {
						label: this.formatToolTipForScaledCharts
					}
				}
			};
			options = Object.assign(options, chartsWithScaleOptions);
		} else {
			// Set the options for chart which do not require display of scales (pie, doughnut)
			const chartWithoutScaleOptions: ChartOptions = {
				tooltips: {
					callbacks: {
						label: this.formatToolTipForUnscaledCharts
					}
				}
			};
			options = Object.assign(options, chartWithoutScaleOptions);
		}
		return options;
	}

	/**
	 * Return a random color
	 * @returns string : random color in rgb format
	 */
	dynamicColors(): string {
		return 'rgb(' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ')';
	}

	/**
	 * Return an array of random colors with length equal to the length of data provided in input
	 * @returns string[] : array of colors, if the length of data is greater ,than the number of defaul colors in app config,it will also contain then the random colors
	 */
	get backgroundColors(): string[] {
		const backgroundColors = [];
		this.data.forEach((element: number[], index: number) => {
			(index < ChartsConfig.chartDefaultColors.length) ? backgroundColors.push(ChartsConfig.chartDefaultColors[index]) : backgroundColors.push(this.dynamicColors());
		});
		return backgroundColors;
	}

	/**
	 * Returns the formatted Y Axis Label , when the data provided is amount
	 * @returns string : formatted Y Axis Label
	 */
	formatYAxisLabels = (amount: number): string | number => {
		return this.isDataAmount ? new MdibCurrencyPipe().transform(amount, this._configService.baseCurrencyCode, true, 0) : amount;
	}

	/**
	 * Returns the formatted tooltip, when data is amount for scaled charts(when axes are present)
	 * @returns string : formatted Y Axis Label
	 */
	formatToolTipForScaledCharts = (tooltipItems: ChartTooltipItem, data: ChartData): string | string[] => {
		if (this.isDataAmount) {
			const amount = new MdibCurrencyPipe().transform(parseInt(tooltipItems.yLabel, 10), this._configService.baseCurrencyCode, true);
			return data.datasets[tooltipItems.datasetIndex].label + ' : ' + amount;
		}
		return data.datasets[tooltipItems.datasetIndex].label + ' : ' + tooltipItems.yLabel;
	}

	/**
	 * Returns the formatted tooltip, when data is amount for unscaled charts(when axes are missing)
	 * @returns string : formatted Y Axis Label
	 */
	formatToolTipForUnscaledCharts = (tooltipItems: ChartTooltipItem, data: ChartData): string | string[] => {
		if (this.isDataAmount) {
			const amount = parseInt((data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]).toString(), 10);
			return data.labels[tooltipItems.index] + ' : ' + new MdibCurrencyPipe().transform(amount, this._configService.baseCurrencyCode, true);
		}
		return data.labels[tooltipItems.index] + ' : ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
	}

}
