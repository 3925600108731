import { mergeMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ParameterModel } from '@mdib/http';
import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { CashAccount, ModifyAccountNameOperation, ModifyAccountNameCommonService } from '@mdib/cash-accounts';
import { XclModifyAccountNameOperationModel } from '../model/xcl-modify-account-name-operation-model';
import { ModifyAccountNameOperationMapperXclService } from './modify-account-name-operation-mapper-xcl.service';
import { SessionUtilsService } from '@mdib/sessions';

@Injectable({
	providedIn: 'root'
})
export class ModifyAccountNameXclService extends ModifyAccountNameCommonService {

	// This field is expected by XCL as account type , 'E' states it is a cash account
	private static readonly cashAccountType = 'E';

	private accountNameToBeModified: string;

	constructor(
		private sessionUtilsService: SessionUtilsService,
		private xclHttpService: XclHttpService,
		private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private xclModifyAccountNameOperationMapperService: ModifyAccountNameOperationMapperXclService,
	) {
		super();
	}

	/**
	 * Invokes call to XCL to initiate init of modify account name operation
	 * @param {ModifyAccountNameOperation} modifyAccountNameOperation Instance of {@link ModifyAccountNameOperation} containing information of account and account name to be modified
	 * @return {Observable<ServiceResponse<ModifyAccountNameOperation>} Functional observable of {@link ModifyAccountNameOperation}
	 */
	public initAccountNameModification(modifyAccountNameOperation: ModifyAccountNameOperation): Observable<ServiceResponse<ModifyAccountNameOperation>> {
		// Retrieve the principal Identifier
		const principalId = this.sessionUtilsService.getSessionActiveUserId();

		const parameters: ParameterModel[] = [
			// FIX-ME Right now appending of 'µ' in PUT parameter is not implemented in http service, when done it must be implemented in the same way as commented code
			new ParameterModel('parameters', principalId + 'µ' + modifyAccountNameOperation.accountNumber + 'µ' + ModifyAccountNameXclService.cashAccountType),
			// new ParameterModel('accountNumber', modifyAccountNameOperation.accountNumber),
			// new ParameterModel('accountType', 'E')
		];
		this.accountNameToBeModified = modifyAccountNameOperation.accountWording;
		const xclObservable: Observable<MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>> =
			this.xclHttpService.execute(
				'modifyaccountname', XclHttpService.PUT, parameters,
				this.xclModifyAccountNameOperationMapperService.functionalToXcl(modifyAccountNameOperation),
			) as Observable<MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>>;
		return this.getFunctionalObservable(xclObservable, modifyAccountNameOperation);
	}

	/**
	 * Invokes call to XCL to perform retrieve of modify account name operation
	 * @param {ModifyAccountNameOperation} modifyAccountNameOperation Instance of {@link ModifyAccountNameOperation} containing information of account and account name to be modified
	 * @return {Observable<ServiceResponse<ModifyAccountNameOperation>} Functional observable of {@link ModifyAccountNameOperation}
	 */
	public retrieveAccountNameModification(modifyAccountNameOperation: ModifyAccountNameOperation): Observable<ServiceResponse<ModifyAccountNameOperation>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'retrieve'),
			new ParameterModel('reference', modifyAccountNameOperation.reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>> = this.xclHttpService.execute(
			'modifyaccountname', XclHttpService.PUT, parameters, this.xclModifyAccountNameOperationMapperService.functionalToXcl(modifyAccountNameOperation),
		) as Observable<MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>>;
		return this.getFunctionalObservable(xclObservable, modifyAccountNameOperation);
	}

	/**
	 * Invokes call to XCL to perform validate of modify account name operation
	 * @param {ModifyAccountNameOperation} modifyAccountNameOperation Instance of {@link ModifyAccountNameOperation} containing information of account and account name to be modified
	 * @return {Observable<ServiceResponse<ModifyAccountNameOperation>} Functional observable of {@link ModifyAccountNameOperation}
	 */
	public validateAccountNameModification(modifyAccountNameOperation: ModifyAccountNameOperation): Observable<ServiceResponse<ModifyAccountNameOperation>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'validate'),
			new ParameterModel('reference', modifyAccountNameOperation.reference),
		];
		modifyAccountNameOperation.accountWording = this.accountNameToBeModified;
		const xclObservable: Observable<MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>> =
			this.xclHttpService.execute(
				'modifyaccountname', XclHttpService.PUT, parameters,
				this.xclModifyAccountNameOperationMapperService.functionalToXcl(modifyAccountNameOperation),
			) as Observable<MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>>;

		return this.getFunctionalObservable(xclObservable, modifyAccountNameOperation);
	}

	/**
	 * Invokes call to XCL to perform confirm of modify account name operation
	 * @param {ModifyAccountNameOperation} modifyAccountNameOperation Instance of {@link ModifyAccountNameOperation} containing information of account and account name to be modified
	 * @return {Observable<ServiceResponse<ModifyAccountNameOperation>} Functional observable of {@link ModifyAccountNameOperation}
	 */
	public confirmAccountNameModification(modifyAccountNameOperation: ModifyAccountNameOperation): Observable<ServiceResponse<ModifyAccountNameOperation>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'confirm'),
			new ParameterModel('reference', modifyAccountNameOperation.reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>> =
			this.xclHttpService.execute(
				'modifyaccountname', XclHttpService.PUT, parameters,
				this.xclModifyAccountNameOperationMapperService.functionalToXcl(modifyAccountNameOperation),
			) as Observable<MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>>;
		return this.getFunctionalObservable(xclObservable, modifyAccountNameOperation);
	}

	/**
	 * Invokes call to XCL to modfy account name
	 * @param {CashAccount} cashAccount {@link CashAccount} instance of the account whose name is to be modified
	 * @return {Observable<ServiceResponse<ModifyAccountNameOperation | null>} Functional observable of {@link ModifyAccountNameOperation} or null in case of error
	 */
	update(cashAccount: CashAccount): Observable<ServiceResponse<ModifyAccountNameOperation | null>> {

		const modifyAccountNameOperation = this.createModifyAccountNameOperation(cashAccount);
		let modifyAccountNameConfirmationStream: Observable<ServiceResponse<ModifyAccountNameOperation | null>>;
		modifyAccountNameConfirmationStream = this.initAccountNameModification(modifyAccountNameOperation).pipe(
			mergeMap(
				(serviceResponse: ServiceResponse<ModifyAccountNameOperation>) =>
					this.retrieveAccountNameModification(serviceResponse.getModel()),
			),
			mergeMap(
				(serviceResponse: ServiceResponse<ModifyAccountNameOperation>) =>
					this.validateAccountNameModification(serviceResponse.getModel()),
			),
			mergeMap(
				(serviceResponse: ServiceResponse<ModifyAccountNameOperation>) =>
					this.confirmAccountNameModification(serviceResponse.getModel()),
			));
		return modifyAccountNameConfirmationStream;
	}

	/**
	 * Converts functional observable from technical observable
	 * @param {Observable<MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>>} technicalObservable technical observable
	 * @param {ModifyAccountNameOperation} modifyAccountNameOperation {@link ModifyAccountNameOperation} instance as received in input
	 * @return {Observable<ServiceResponse<ModifyAccountNameOperation>>}: functional observable
	 */
	private getFunctionalObservable(technicalObservable: Observable<MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>>,
		modifyAccountNameOperation: ModifyAccountNameOperation): Observable<ServiceResponse<ModifyAccountNameOperation>> {
		return technicalObservable
			.pipe(map((accountNameModificationXCLModel: MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>) => {
				return new ServiceResponse(
					this.xclModifyAccountNameOperationMapperService.xclToFunctional(accountNameModificationXCLModel),
					this.feedbackExtractor.extract(accountNameModificationXCLModel),
				);
			}));
	}

	/**
	 * Creates an instance of {@link ModifyAccountNameOperation} as expected by XCL, according to cash account provided
	 * @param {CashAccount} cashAccount cash account instance
	 * @return {ModifyAccountNameOperation}: instance of {@link ModifyAccountNameOperation}
	 */
	private createModifyAccountNameOperation(cashAccount: CashAccount): ModifyAccountNameOperation {
		const modifyAccountNameOperation = new ModifyAccountNameOperation();
		modifyAccountNameOperation.accountNumber = cashAccount.number;
		modifyAccountNameOperation.accountWording = cashAccount.clientWording;
		modifyAccountNameOperation.accountType = ModifyAccountNameXclService.cashAccountType;

		return modifyAccountNameOperation;
	}
}
