import { CashAccountSortOrder } from '../model/cash-account-sort-order';
import { CashAccountSortOrderService } from './cash-account-sort-order.service';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';

export class CashAccountSortOrderServiceStub extends CashAccountSortOrderService {
	update(accountSortOrder: CashAccountSortOrder): Observable<ServiceResponse<CashAccountSortOrder>> {
		return of();
	}
}
