import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SessionResetService } from './session-reset.service';

export class SessionResetServiceStub extends SessionResetService {

	public reset(): Observable<any> {
		return of();
	}

}
