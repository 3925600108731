import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class ConfigurationServiceStub {

	public get(key: string): Observable<any> {
		return of();
	}

	public instant(key: string) {
		return undefined;
	}

	public set(key: string, value: any): Observable<any> {
		return of();
	}
}
