import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SearchCriteria } from '@mdib/core/adapters';
import { and, equals } from '@mdib/core/adapters/criteria';
import { BeneficiaryV2 as Beneficiary } from '@mdib/beneficiaries';
import { SessionsService } from '@mdib/sessions';
import { PersonsService } from '@mdib/customers';
import { ConfigurationService } from '@mdib/utils';

import { BackendSearchOperationXcl, SearchOperatorsXcl, SearchCriteriaXcl } from '@mdib-xcl/core';
import { XclBeneficiaryModel } from '../../model/xcl-beneficiary-model';
import { BeneficiaryMapperService } from '../mappers/beneficiary-mapper.service';
import { BeneficiaryCriteriaMapper } from '../mappers/beneficiary-criteria-mapper.service';

export class BeneficiariesSearchXclOperation extends BackendSearchOperationXcl<Beneficiary, XclBeneficiaryModel> {

	protected xclEndpoint = 'xcl.beneficiaries.generic';

	private beneficiaryMapperService: BeneficiaryMapperService;
	private beneficiaryCriteriaMapper: BeneficiaryCriteriaMapper;
	private sessionService: SessionsService;
	private personsService: PersonsService;
	private configurationService: ConfigurationService;

	public constructor(
		injector: Injector,
	) {
		super(injector);

		// Dependencies
		this.beneficiaryMapperService = injector.get(BeneficiaryMapperService);
		this.beneficiaryCriteriaMapper = injector.get(BeneficiaryCriteriaMapper);
		this.sessionService = injector.get(SessionsService);
		this.personsService = injector.get(PersonsService);
		this.configurationService = injector.get(ConfigurationService);
	}

	// Parse search criteria
	protected getSearchData(input: SearchCriteria<SearchCriteriaXcl>): any {
		let criteria = and(input, equals('principalIdentification', this.sessionUtilsService.getSessionActiveUserId()));

		return this.personsService.getRepresentedClientNumber().pipe(
			map((client: string) => {
				if (client) {
					criteria = and(criteria, equals('representedClientNumber', client));
				}
				let tuple = {};
				this.getCompiledCriteria(criteria).subscribe(
					event => {
						tuple = event;
					},
				);
				return tuple;
			}),
		);
	}

	protected convertResponse(response: XclBeneficiaryModel): Beneficiary {
		return this.beneficiaryMapperService.fromXcl(response);
	}

	private getCompiledCriteria(criteria): any {
		const compiled = criteria.compile(SearchOperatorsXcl);
		const mapper = this.beneficiaryCriteriaMapper.mapFields.bind(this.beneficiaryCriteriaMapper);
		return compiled(mapper);
	}
}
