import { PersonsService } from '@mdib/customers';
import { SignableOperation } from '@mdib/signature';
import { throwError, Observable } from 'rxjs';
import { of } from 'rxjs/index';
import { tap, mergeMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { XclSignatureContext } from '@mdib-xcl/core';
import { XclAPI } from '@mdib-xcl/utils';
import { ParameterModel } from '@mdib/http';
import { PaymentOperation, StandingOrderCommonService, StandingOrderDetails } from '@mdib/payments';
import { Feedback, FeedbackTypes, ServiceResponse, Status } from '@mdib/utils';
import { StandingOrderMapperXclService } from './standing-order-mapper-xcl.service';
import { StandingOrderXcl } from '../../model/standing-order-xcl';

@Injectable({
	providedIn: 'root',
})
export class StandingOrderXclService extends StandingOrderCommonService {

	readonly baseUri = 'standing-orders';

	constructor(private xclHttpService: XclHttpService,
				private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
				private mapper: StandingOrderMapperXclService,
				private personsService: PersonsService,
	) {
		super();
	}

	/**
	 *
	 * @param {PaymentOperation<StandingOrderDetails>} payment
	 * @return {Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>}
	 */
	public validate(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>> {
		return this.init().pipe(
			mergeMap((order: MonogoalOrderXCLModel<StandingOrderXcl>) => this.retrieve(order.reference)),
			mergeMap((order: MonogoalOrderXCLModel<StandingOrderXcl>) => this.validateStandingOrder(order.reference, payment)));
	}

	/**
	 * Confirm
	 * @param {PaymentOperation<StandingOrderDetails>} payment
	 * @return {Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null> | null>}
	 */
	public confirm(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null> | null> {

		const params: ParameterModel[] = this.parameters(payment.reference, XclAPI.confirm);

		const xclObservable: Observable<MonogoalOrderXCLModel<StandingOrderXcl>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toXcl(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, params, paymentXcl) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;
				}),
			);

		return this.getFunctionalObservable(xclObservable, payment);
	}

	public sign(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>> {
		const params: ParameterModel[] = this.parameters(payment.signatureContext.signableReference, XclAPI.sign);

		const xclObservable: Observable<MonogoalOrderXCLModel<StandingOrderXcl>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toXcl(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, params, paymentXcl) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;
				}),
			);
		return this.getFunctionalObservable(xclObservable, payment, XclAPI.sign).pipe(
			tap(() => {
				if (payment.status === Status.Error) {
					throw ServiceResponse.emptyWithOnefeedback(new Feedback('errorWhenSigningPayment', FeedbackTypes.FRONTEND_ERROR, 'Something went wrong when signing the standing order operation'));
				}
			}));
	}

	/**
	 * Init standing order operation
	 * @return {Observable<MonogoalOrderXCLModel<StandingOrderXcl>>}
	 */
	public init(): Observable<MonogoalOrderXCLModel<StandingOrderXcl>> {
		const xclObservable: Observable<MonogoalOrderXCLModel<StandingOrderXcl>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.POST) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;
		return xclObservable;
	}

	/**
	 * Retrieve standing order operation
	 * @param {string} reference
	 * @returns {Observable<MonogoalOrderXCLModel<StandingOrderXcl>>}
	 */
	public retrieve(reference: string): Observable<MonogoalOrderXCLModel<StandingOrderXcl>> {
		const params: ParameterModel[] = this.parameters(reference, XclAPI.retrieve);
		const xclObservable: Observable<MonogoalOrderXCLModel<StandingOrderXcl>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, params) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;
		return xclObservable;
	}

	public validateStandingOrder(reference: string, payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>> {

		const params: ParameterModel[] = this.parameters(reference, XclAPI.validate);

		const xclObservable: Observable<MonogoalOrderXCLModel<StandingOrderXcl>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toXcl(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, params, paymentXcl) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;
				}),
			);
		return this.getFunctionalObservable(xclObservable, payment, XclAPI.validate).pipe(
			tap(() => {
				payment.signatureContext = new XclSignatureContext({signableReference: payment.reference});
				if (payment.status === Status.NotAuthorizedToSign) {
					throwError('Payment is not authorized to sign !');
				}
			}));
	}

	public getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>> {

		let draftPaymentStream: Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>;

		const params: ParameterModel[] = [
			new ParameterModel('orderReference', reference),
		];

		draftPaymentStream = this.xclHttpService.execute('order', XclHttpService.GET, params, null).pipe(
			map((xclOrder: MonogoalOrderXCLModel<any>) => {
				return new ServiceResponse<PaymentOperation<StandingOrderDetails>>(
					this.mapper.fromXcl(new PaymentOperation<StandingOrderDetails>(), xclOrder),
					this.feedbackExtractor.extract(xclOrder));
			}));

		return draftPaymentStream;

	}

	public get(reference: string): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>> {
		const paymentOperation = new PaymentOperation<null>();
		const parameters: ParameterModel[] = [
			new ParameterModel('reference', reference),
		];
		const xclObservable = this.xclHttpService.execute(this.baseUri + '-get', XclHttpService.GET, parameters) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;

		return this.getFunctionalObservable(xclObservable, paymentOperation);
	}

	/**
	 * Initialize delete for a standing order in pending payment
	 * @param {string} reference
	 * @returns {Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>>}
	 */
	public initDeleteStandingOrder(reference: string): Observable<MonogoalOrderXCLModel<any>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('reference', reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<any>> = this.xclHttpService.execute(
			this.baseUri + '-get', XclHttpService.DELETE, parameters,
		) as Observable<MonogoalOrderXCLModel<any>>;

		return xclObservable;
	}

	public delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>> {
		return this.initDeleteStandingOrder(reference).pipe(
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.retrieve(order.reference)),
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.validateDelete(order.reference)),
			mergeMap((order: ServiceResponse<PaymentOperation<StandingOrderDetails>>) => {
				return this.getFunctionalObservableForSignableOperation(order);
			}));
	}

	public validateDelete(reference: string): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>> {
		const payment = new PaymentOperation<StandingOrderDetails>();
		const params: ParameterModel[] = this.parameters(reference, XclAPI.validate);

		const xclObservable: Observable<MonogoalOrderXCLModel<StandingOrderXcl>> =
			this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, params) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;

		return this.getFunctionalObservable(xclObservable, payment);
	}

	public confirmDelete(signablePaymentOperation: SignableOperation<PaymentOperation<StandingOrderDetails>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>> {

		const params: ParameterModel[] = this.parameters(signablePaymentOperation.signatureContext.signableReference, XclAPI.confirm);
		const payment = new PaymentOperation<StandingOrderDetails>();
		const xclObservable: Observable<MonogoalOrderXCLModel<StandingOrderXcl>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, params, null,
		) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;

		const order = this.getFunctionalObservable(xclObservable, payment);

		return order.pipe(mergeMap((standingOrder: ServiceResponse<PaymentOperation<StandingOrderDetails>>) => {
			return this.getFunctionalObservableForSignableOperation(standingOrder);
		}));
	}

	public signDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('reference', (signablePaymentOperation.signatureContext.signableReference)),
		];
		const payment = new PaymentOperation<StandingOrderDetails>();

		const xclObservable: Observable<MonogoalOrderXCLModel<StandingOrderXcl>> = this.xclHttpService.execute(
			this.baseUri + '-sign', XclHttpService.PUT, parameters, null,
		) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;

		const order = this.getFunctionalObservable(xclObservable, payment);

		return order.pipe(mergeMap((standingOrder: ServiceResponse<PaymentOperation<StandingOrderDetails>>) => {
			return this.getFunctionalObservableForSignableOperation(standingOrder);
		}));
	}

	public initUpdate(payment: PaymentOperation<StandingOrderDetails>): Observable<MonogoalOrderXCLModel<StandingOrderXcl>> {
		const parameters: ParameterModel[] = [
			new ParameterModel('reference', payment.reference),
		];
		const xclObservable = this.xclHttpService.execute( this.baseUri + '-get', XclHttpService.PUT, parameters, this.mapper.toXcl(payment) ) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;
		return xclObservable;
	}

	/**
	 *
	 * @param {PaymentOperation<StandingOrderDetails>} payment
	 * @return {Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>}
	 */
	public validateUpdate(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>> {
		return this.initUpdate(payment).pipe(
			mergeMap((order: MonogoalOrderXCLModel<StandingOrderXcl>) => this.retrieve(order.reference)),
			mergeMap((order: MonogoalOrderXCLModel<StandingOrderXcl>) => this.validateStandingOrder(order.reference, payment)));
	}

	private parameters(reference: string, phase: string): ParameterModel[] {
		return <ParameterModel[]>[
			new ParameterModel(XclAPI.rest, phase),
			new ParameterModel(XclAPI.reference, reference),
		];
	}

	private getFunctionalObservable(technicalObservable: Observable<MonogoalOrderXCLModel<StandingOrderXcl>>, paymentOperation: PaymentOperation<StandingOrderDetails>, phase: string = XclAPI.retrieve): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>> {
		return technicalObservable.pipe(map((standingOrderXcl: MonogoalOrderXCLModel<StandingOrderXcl>) => {
			return new ServiceResponse(
				this.mapper.fromXcl(paymentOperation, standingOrderXcl, phase),
				this.feedbackExtractor.extract(standingOrderXcl),
			);
		}));
	}

	/**
	 * Return a functional observable for a signable standing order
	 * @param {ServiceResponse<PaymentOperation<StandingOrderDetails>>} order
	 * @returns {Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>>}
	 */
	private getFunctionalObservableForSignableOperation(order: ServiceResponse<PaymentOperation<StandingOrderDetails>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>> {
		const signable: SignableOperation<PaymentOperation<StandingOrderDetails>> = new SignableOperation<PaymentOperation<StandingOrderDetails>>(order.getModel());
		signable.reference = order.getModel().reference;
		signable.signatureContext = order.getModel().signatureContext;
		signable.signatureTypesAllowed = order.getModel().signatureTypesAllowed;
		signable.status = order.getModel().status;
		return of(new ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>(signable));
	}

}
