import { BackendOperationStep } from '@mdib/core/adapters';
import { SepaDirectDebitPayment } from '@mdib/sepa-direct-debits';
import { Observable } from 'rxjs';
import { UpdateOperationMemory } from '@mdib-memory/core';
import { MockSepaDirectDebitPayments } from '../../mock/mock-sepa-direct-debit-payments';


export class SepaDirectDebitPaymentsUpdateMemory extends UpdateOperationMemory<SepaDirectDebitPayment> {

	constructor() {
		super();
	}

	protected validate(input: SepaDirectDebitPayment, trigger?: string): Observable<BackendOperationStep<SepaDirectDebitPayment>> {
		const payment = MockSepaDirectDebitPayments.getMockSepaDirectDebitPayments().filter((sepapayment: SepaDirectDebitPayment) => sepapayment.paymentReference === input.paymentReference).pop();
		return super.validate(payment);
	}
}
