import { NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { PersonsService, SecretQuestionsListsService, ContactsService } from '@mdib/customers';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';

import { PersonsMemoryService } from './service/persons-memory.service';
import { SecretQuestionsListsMemoryService } from './service/secret-questions-lists-memory.service';
import { ContactsMemoryService } from './service/contacts-memory.service';

@NgModule()
export class CustomersMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(ContactsService, adapterId, ContactsMemoryService);
		adaptersService.setAdapter(PersonsService, adapterId, PersonsMemoryService);
		adaptersService.setAdapter(SecretQuestionsListsService, adapterId, SecretQuestionsListsMemoryService);
	}
}
