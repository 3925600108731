import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { SepaDirectDebitPaymentsService } from './sepa-direct-debit-payments.service';
import { SepaDirectDebitPayment } from '../model/sepa-direct-debit-payment';
import { SepaDirectDebitContract } from '../model/sepa-direct-debit-contract';
import { BackendOperation, BackendOperationStub, BackendOperationType, SearchCriteria, SearchResult } from '@mdib/core/adapters';

export class SepaDirectDebitPaymentsServiceStub extends SepaDirectDebitPaymentsService {
	list(sepaDirectDebitContract: SepaDirectDebitContract, index?: number, count?: number): Observable<ServiceResponse<SepaDirectDebitPayment[]>> {
		return of();
	}

	count(sepaDirectDebitContract: SepaDirectDebitContract): Observable<ServiceResponse<number>> {
		return of();
	}

	public create(): BackendOperation<SepaDirectDebitPayment, SepaDirectDebitPayment> {
		return new BackendOperationStub(BackendOperationType.CREATE);
	}

	public retrieve(): BackendOperation<string, SepaDirectDebitPayment> {
		return new BackendOperationStub(BackendOperationType.RETRIEVE);
	}

	public update(): BackendOperation<SepaDirectDebitPayment, SepaDirectDebitPayment> {
		return new BackendOperationStub(BackendOperationType.UPDATE);
	}

	public delete(): BackendOperation<SepaDirectDebitPayment, SepaDirectDebitPayment> {
		return new BackendOperationStub(BackendOperationType.DELETE);
	}

	public search(): BackendOperation<SearchCriteria<any>, SearchResult<SepaDirectDebitPayment>> {
		return new BackendOperationStub(BackendOperationType.SEARCH);
	}
}
