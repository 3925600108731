import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';

import { ContactAddress, Contact, ContactEmail, ContactPhoneNumber } from '@mdib/customers';

import { XclSignatureModes } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';
import { OrderStateHelper } from '@mdib-xcl/utils';

import { XclAddress } from '../types/models/xcl-address';
import { XclContactTypes } from '../types/enums/xcl-contact-types.enum';

// Placeholder before actual implementation
@Injectable({
	providedIn: 'root'
})
export class ContactMapperXclService {

	fromXclAddressArrayToContactArray(technicalModelArray: XclAddress[]): Contact[] {
		if (isNullOrUndefined(technicalModelArray)) {
			console.error('Invalid list of addresses returned by the XCL: ' + JSON.stringify(technicalModelArray));
			return [];
		}
		const functionalModelArray: Contact[] = [];
		technicalModelArray.forEach((technicalModel: XclAddress) => {
			const contact = this.fromXclAddressToContact(technicalModel);
			if (contact) {
				functionalModelArray.push(contact);
			}
		});
		return functionalModelArray;
	}

	fromContactToXclAddress(contact: Contact): XclAddress {
		let xclAddress: XclAddress;
		if (contact instanceof ContactAddress) {
			xclAddress = this.fromContactAddressToXclAddress(contact);
		} else if (contact instanceof ContactPhoneNumber) {
			// TODO when phone number will be taken into account
		} else if (contact instanceof ContactEmail) {
			xclAddress = this.mapEmailAddressToTechnical(contact);
		}
		return xclAddress;
	}

	fromXclAddressGoalToContact(order: MonogoalOrderXCLModel<XclAddress>): Contact {
		const xclAddress = order.goal;
		let contact: Contact;
		if (xclAddress.addressType === XclContactTypes.PostalAddress) {
			contact = this.fromXclAddressToContactAddress(xclAddress);
		} else if (xclAddress.addressType === XclContactTypes.Email) {
			contact = this.mapEmailToFunctional(xclAddress);
		} else {
			// when phone number will be taken into account
			contact = new ContactAddress();
		}
		contact.identifier = xclAddress.identifier;
		contact.reference = order.reference;
		contact.signatureTypesAllowed = order.signatureTypesAllowed.map(type => XclSignatureModes.fromXclType(type));
		contact.status = OrderStateHelper.getStatusFromXCLOrderStateCode(order.state);
		return contact;
	}

	private fromXclAddressToContact(xclAddress: XclAddress): Contact {
		let contact: Contact;
		switch (xclAddress.addressType) {
			case XclContactTypes.PostalAddress: {
				contact = this.fromXclAddressToContactAddress(xclAddress);
				break;
			}
			case XclContactTypes.PhoneNumber: {
				if (xclAddress.callingCode) {
					contact = new ContactPhoneNumber('+' + xclAddress.callingCode + ' ' + xclAddress.contactNumber.replace(/\s/g, ''), 'home');
				} else {
					contact = new ContactPhoneNumber(xclAddress.contactNumber.replace(/\s/g, ''), 'home');
				}
				break;
			}
			case XclContactTypes.MobilePhone: {

				if (xclAddress.callingCode) {
					contact = new ContactPhoneNumber('+' + xclAddress.callingCode + ' ' + xclAddress.contactNumber.replace(/\s/g, ''), 'mobile');
				} else {
					contact = new ContactPhoneNumber(xclAddress.contactNumber.replace(/\s/g, ''), 'mobile');
				}
				break;
			}

			case XclContactTypes.Email: {
				contact = this.mapEmailToFunctional(xclAddress);
				break;
			}
			default: {
				return null;
			}
		}
		contact.identifier = xclAddress.identifier;
		return contact;
	}

	private fromXclAddressToContactAddress(xclAddress: XclAddress): ContactAddress {
		const contactAddress: ContactAddress = new ContactAddress();
		if (xclAddress.streetNumber == null) {
			contactAddress.addressLine1 = xclAddress.street;
		} else {
			contactAddress.addressLine1 = xclAddress.street + ' ' + xclAddress.streetNumber;
		}
		contactAddress.addressLine2 = xclAddress.postalCode + ' ' + xclAddress.city;
		contactAddress.country = xclAddress.countryCode;

		if (xclAddress.contactType === '01') {
			contactAddress.label = 'legalAddress';
		} else {
			contactAddress.label = 'secondAddress';
		}

		return contactAddress;
	}

	private fromContactAddressToXclAddress
	(contactAddress: ContactAddress): XclAddress {

		const xclAddress: XclAddress = new XclAddress();
		xclAddress.addressLine1 = contactAddress.addressLine1;
		xclAddress.addressLine2 = contactAddress.addressLine2;
		xclAddress.identifier = contactAddress.identifier;
		xclAddress.addressType = '01';
		xclAddress.countryCode = contactAddress.country;

		if (contactAddress.label === 'legalAddress') {
			xclAddress.contactType = '01';
		} else {
			// TODO: We only manage legal addresses for now
		}
		return xclAddress;
	}

	private mapEmailToFunctional(xclAddress: XclAddress): ContactEmail {
		const holderContactEmail: ContactEmail = new ContactEmail(xclAddress.emailAddress, 'email');
		return holderContactEmail;
	}

	private mapEmailAddressToTechnical(holderContactEmail: ContactEmail): XclAddress {
		const xclAddress: XclAddress = new XclAddress();
		xclAddress.addressType = '03';
		xclAddress.contactType = '06';
		xclAddress.emailAddress = holderContactEmail.email;
		xclAddress.identifier = holderContactEmail.identifier;
		return xclAddress;
	}
}

