import { NgModule, ModuleWithProviders } from '@angular/core';
import { SepaDirectDebitContractsService } from './service/sepa-direct-debit-contracts.service';
import { SepaDirectDebitContractsServiceProxy } from './service/sepa-direct-debit-contracts.service.proxy';
import { SepaDirectDebitPaymentsService } from './service/sepa-direct-debit-payments.service';
import { SepaDirectDebitPaymentsServiceProxy } from './service/sepa-direct-debit-payments.service.proxy';
import { ReactiveFormsModule } from '@angular/forms';
import { StylingModule } from '@mdib/styling';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CreditIdentificationFormatterPipe } from './pipes/credit-identification-formatter.pipe';
import { UtilsModule } from '@mdib/utils';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		UtilsModule,
		TranslateModule,
		StylingModule.forRoot(),
	],
	declarations: [
		CreditIdentificationFormatterPipe
	],
	exports: [
		CreditIdentificationFormatterPipe
	],
})
export class SepaDirectDebitsModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: SepaDirectDebitsModule,
			providers: [
				{ provide: SepaDirectDebitContractsService, useClass: SepaDirectDebitContractsServiceProxy },
				{ provide: SepaDirectDebitPaymentsService, useClass: SepaDirectDebitPaymentsServiceProxy },
			]
		};
	}
}
