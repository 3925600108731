import { SearchCriteriaCompiler, SearchCriteria } from '@mdib/core/adapters';
import { all, page, and, contains, equals } from '@mdib/core/adapters/criteria';

export interface SearchCriteriaMemory {
	// Pageination
	index?: number;
	count?: number;

	// Filtering
	filter: (item: any) => boolean;
}

export const CriteriaOperatorsMemory: Map<Function, SearchCriteriaCompiler<SearchCriteriaMemory>> = new Map();

/**
 * No filtering
 */
function allMemory() {
	return {
		filter: () => true
	};
}

/**
 * Memory Pagination
 * @param criterium Criterium to apply the pagination to
 * @param index Starting index
 * @param count Maximum number of elements to return
 */
function pageMemory(criterium: SearchCriteria<SearchCriteriaMemory>, index: number, count: number): SearchCriteriaMemory {
	// Compile criteria
	const compiled = criterium.compile(CriteriaOperatorsMemory);

	// Add pagination
	compiled.index = index;
	compiled.count = count;
	return compiled;
}

/**
 * Memory implementation of the AND Search Criterium
 */
function andMemory(...criteria: Array<SearchCriteria<SearchCriteriaMemory>>): SearchCriteriaMemory {
	// Compile criteria
	const compiled = criteria.map(criterium => criterium.compile(CriteriaOperatorsMemory));

	// Execute every criterium
	return {
		filter: (item: any) => {
			return compiled.reduce((acc, next) => {
				return acc && next.filter(item);
			}, true);
		}
	};
}

/**
 * Memory implementation of the CONTAINS Search Criterium
 */
function equalsMemory(fieldName: string, value: string, caseSensistive: boolean) {
	return {
		filter: (item: any) => {
			// Find property
			let property = item;
			fieldName.split('.').forEach(path => property = (property || {})[path]);

			// Case sensitivity
			if (typeof property === 'string' && typeof value === 'string' && !caseSensistive) {
				property = property.toUpperCase();
				value = value.toUpperCase();
			}

			return property === value;
		}
	};
}

/**
 * Memory implementation of the CONTAINS Search Criterium
 */
function containsMemory(fieldName: string, value: string, caseSensistive: boolean) {
	return {
		filter: (item: any) => {
			// Find property
			let property = item;
			fieldName.split('.').forEach(path => property = (property || {})[path]);

			// Case sensitivity
			if (!caseSensistive && property) {
				property = property.toUpperCase();
				value = value.toUpperCase();
			}

			return property && property.indexOf && property.indexOf(value) >= 0;
		}
	};
}

CriteriaOperatorsMemory.set(all, allMemory);
CriteriaOperatorsMemory.set(and, andMemory);
CriteriaOperatorsMemory.set(equals, equalsMemory);
CriteriaOperatorsMemory.set(contains, containsMemory);
CriteriaOperatorsMemory.set(page, pageMemory);
