import { CachedSeries } from './cached-series';
import { SequenceCacheOptions } from './sequence-cache-options';

/**
 * Cache management for sequences of items.
 */
export class SequenceCache<T> {

	private first: CachedSeries<T> | null = null;

	constructor(
		private options: SequenceCacheOptions = {},
	) { }

	/**
	 * Add a series of elements in the cache.
	 *
	 * @param elements Elements to add to the cache.
	 * @param index Position of the first element to add.
	 *
	 * @returns The number of added elements.
	 */
	public set(elements: T[], index = 0): number {
		if (elements.length === 0) {
			return 0;
		}
		const cache = new CachedSeries(elements, index);
		return this.insert(cache).count;
	}

	/**
	 * Retrieve a series of elements from the cache.
	 *
	 * @param index Position of the first element to retrieve. Default is 0.
	 * @param count Number of elements to retrieve. Default is 1.
	 *
	 * @returns The series of elements, or null if the full series is not cached.
	 */
	public get(index = 0, count = 1): T[] | null {
		let cache = this.first;
		for (; cache && cache.lastIndex < index; cache = cache.next) { }

		if (count === 0) {
			return [];
		}

		// Missing the start
		if (!cache || cache.index > index) {
			return null;
		}

		// Gather all the elements
		const elements = [];
		do {
			// Gather available data in the series
			const i = Math.max(0, index - cache.index);
			const c = Math.min(count - elements.length, cache.count - i);
			elements.push(...cache.elements.slice(i, i + c));

			// Full data was found
			if (elements.length === count) {
				return elements;
			}

			// Gap in the data
			if (cache.next && (cache.next.index - cache.lastIndex > 1)) {
				return null;
			}

			cache = cache.next;
		} while (cache);

		return (elements.length === count) ? elements : null;
	}

	/**
	 * Checks if a series of elements is available in the cache.
	 *
	 * @param index Position of the first element. Default is 0.
	 * @param count Number of elements. Default is 1.
	 *
	 * @returns {boolean} true if the series is cached, false otherwise.
	 */
	public has(index = 0, count = 1): boolean {
		return this.get(index, count) !== null;
	}

	/**
	 * Invalidate a series of elements from the cache.
	 *
	 * @param index Position of the first element. Default is 0.
	 * @param count Number of elements or undefined for all.
	 */
	public invalidate(index = 0, count?: number) {
		// Create an empty pointer at the starting point
		const cache = new CachedSeries([], index);
		this.insert(cache);
		if (count === undefined) {
			cache.next = null;
		} else {
			// Create an empty pointer at the ending point
			const endCache = new CachedSeries([], index + count);
			this.insert(endCache);
			endCache.previous = cache;
			cache.next = cache;
			this.remove(endCache);
		}
		this.remove(cache);
	}

	/**
	 * Removed expired data from the cache.
	 */
	public cleanup(): number {
		// No limitation for the caches validity
		if (!this.options.validityLimit) {
			return 0;
		}

		// Check each series to remove the expired one
		let removed = 0;
		for (let cache = this.first; cache; cache = cache.next) {
			// Expired
			if (cache.isExpired(this.options.validityLimit)) {
				removed += this.remove(cache);
			}
		}
		return removed;
	}

	private insert(cache: CachedSeries<T>): CachedSeries<T> {
		// Initialize
		cache.next = this.first;
		if (this.first && this.first.index < cache.index) {
			cache.previous = this.first;
		} else {
			this.first = cache;
		}

		// Find the area where the elements must be happended
		while (cache.previous && cache.previous.next && (cache.previous.next.index < cache.index)) {
			cache.previous = cache.previous.next;
		}

		if (cache.previous) {
			cache.next = cache.previous;
		}
		while (cache.next && (cache.next.lastIndex <= cache.lastIndex)) {
			cache.next = cache.next.next;
		}

		// Previous and Next are the same
		if (cache.previous && cache.previous === cache.next) {
			cache.next = new CachedSeries(cache.previous.elements, cache.previous.index, cache, cache.previous.next);
		}

		// Strip overlapping elements
		if (cache.previous) {
			cache.previous.next = cache;
			const rmsize = cache.previous.lastIndex - cache.index + 1;
			if (rmsize > 0) {
				cache.previous.elements.splice(cache.index - cache.previous.index, rmsize);
			}
		}

		if (cache.next) {
			cache.next.previous = cache;
			const rmsize = cache.lastIndex - cache.next.index + 1;
			if (rmsize > 0) {
				cache.next.elements.splice(0, rmsize);
				cache.next.index += rmsize;
			}
		}

		return cache;
	}

	private remove(cache: CachedSeries<T>): number {
		if (cache.previous) {
			cache.previous.next = cache.next;
		} else {
			this.first = cache.next;
		}
		if (cache.next) {
			cache.next.previous = cache.previous;
		}
		return cache.count;
	}
}
