import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'mdib-year-selection',
	templateUrl: './year-selection.component.html',
	styleUrls: ['./year-selection.component.scss'],
})
export class YearSelectionComponent {

	readonly NAMESPACE = 'pages.generic.yearSelection.';
	public yearSelectorDropped = false;
	@Input() form: FormGroup;
	@Input() numYears = 7;
	@Input() public set isActionRequestOngoing(value: boolean) {
		this.isActionRequestOngoingValue = value;
		if (value) {
			this.form.disable();
		} else {
			if (this.form) {
				this.form.enable();
			}
		}
	}

	private isActionRequestOngoingValue: boolean;

	constructor() {}

	/**
	 * This method returns the array containing the last <size> years in reverse order (current year is included)
	 * @param size (optional)
	 * @returns {Array}
	 * @example
	 * getYearsArray() => [2018,2017,2016,2015]
	 */
	getYearsArray(size = this.numYears) {
		const currentYear = (new Date()).getFullYear();
		return Array.from(new Array(size), (x, i) => currentYear + 1 - size + i).reverse();
	}

	dropList(drop: boolean): void {
		if (!this.isActionRequestOngoingValue) {
			this.yearSelectorDropped = drop;
		}
	}

	toggleYearSelectorDropped() {
		if (!this.isActionRequestOngoingValue) {
			this.yearSelectorDropped = !this.yearSelectorDropped;
		}
	}

	selectYear(year: string): void {
		this.form.patchValue({
			year: year,
		});
		this.yearSelectorDropped = false;
	}

}
