import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
	name: 'spaceFormatterPipe'
})
export class SpaceFormatterPipe implements PipeTransform {
	/**
	 * Returns for a given string its formatted value with a space after defined positions in the space index array of pipe.If any specified index is larger than the length
	 * of the value then that index is simply ignored.
	 * @param {string} [value] The value to format
	 * @param {number[]} [spaceIndexArray] The space index array which holds positive integer value of index after which space needs to be provided
	 * @returns {string} : Formatted value
	 */
	transform(value: string, spaceIndexArray: number[]): string {
		let updatedVal = '';
		let startIndex = 0;
		if (value != null && spaceIndexArray != null) {
			spaceIndexArray.forEach((position: number) => {
				const tempVal = value.slice(startIndex, position) + ' ';
				updatedVal = updatedVal.concat(tempVal);
				startIndex = position;
			});
			updatedVal = updatedVal.concat(value.slice(startIndex));
			value = updatedVal.trim();
			return value;
		}
		return value;
	}
}
