import { NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { KeyingLimitsService } from '@mdib/keying-limits';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';

import { KeyingLimitsMemoryService } from './service/keying-limits-memory.service';

@NgModule()
export class KeyingLimitsMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(KeyingLimitsService, adapterId, KeyingLimitsMemoryService);
	}
}
