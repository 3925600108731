import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ServiceResponse } from '@mdib/utils';

import { StandingOrderService } from './standing-order.service';
import { PaymentOperation } from '../../types/models/payment-operation';
import { StandingOrderDetails } from '../../types/models/standing-order-details';

@Injectable()
export abstract class StandingOrderCommonService extends StandingOrderService {

	public abstract validate(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>;

	public abstract confirm(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null> | null>;

	public abstract sign(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>;

}
