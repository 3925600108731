import { Inject, NgModule } from '@angular/core';
import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';
import { BicService, CashAccountOperationService, CashAccountService, CashAccountSortOrderService, ModifyAccountNameService, SavingsAccountService } from '@mdib/cash-accounts';

import { AdaptersService } from '@mdib/core/adapters';
import { BicMemoryService } from './service/bic-memory.service';
import { CashAccountMemoryService } from './service/cash-account-memory.service';
import { CashAccountOperationMemoryService } from './service/cash-account-operation-memory.service';
import { CashAccountSortOrderMemoryService } from './service/cash-account-sort-order-memory.service';
import { ModifyAccountNameMemoryService } from './service/modify-account-name-memory.service';
import { SavingsAccountMemoryService } from './service/savings-account-memory.service';

@NgModule()
export class CashAccountsMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(CashAccountService, adapterId, CashAccountMemoryService);
		adaptersService.setAdapter(CashAccountOperationService, adapterId, CashAccountOperationMemoryService);
		adaptersService.setAdapter(SavingsAccountService, adapterId, SavingsAccountMemoryService);
		adaptersService.setAdapter(ModifyAccountNameService, adapterId, ModifyAccountNameMemoryService);
		adaptersService.setAdapter(CashAccountSortOrderService, adapterId, CashAccountSortOrderMemoryService);
		adaptersService.setAdapter(BicService, adapterId, BicMemoryService);
	}
}
