import { Observable } from 'rxjs';

import { ServiceResponse } from '@mdib/utils';
import { Authentication } from '@mdib/sessions';

import { PasswordAuthenticationService } from './password-authentication.service';

export class PasswordAuthenticationServiceStub extends PasswordAuthenticationService {

	public authenticate(username: string, password: string): Observable<ServiceResponse<Authentication>> {
		return undefined;
	}

}
