import { User } from './user';
import { Observable } from 'rxjs';

import { ServiceResponse } from '@mdib/utils';

export class Authentication {

	public type: string;
	public user: User;
	public close: () => Observable<ServiceResponse<any>>;
	public keepAlive: () => Observable<ServiceResponse<any>>;

	constructor(properties: Partial<Authentication> = {}) {
		Object.assign(this, properties);
	}
}
