import { Inject, Injectable, Type } from '@angular/core';

import { SignatureTypeSelectorComponent } from '../component/signature-type-selector/signature-type-selector.component';
import { SignatureType } from '../interfaces/signature-type';
import { SIGNATURE_DEFAULT, SIGNATURE_SELECTOR, SIGNATURE_TYPES } from '../signature.const';
import { environment } from 'environments/environment';

/**
 * Generic Signature service handling multiple processes and
 * dynamic display of process components.
 */
@Injectable()
export class SignatureService {

	private typesMap: Map<any, SignatureType> = new Map<any, SignatureType>();

	constructor(
		@Inject(SIGNATURE_TYPES) private signatureTypes: SignatureType[][],
		@Inject(SIGNATURE_DEFAULT) private defaultType: any,
		@Inject(SIGNATURE_SELECTOR) private selectorComponent: Type<SignatureTypeSelectorComponent>,
	) {
		signatureTypes.forEach((types) => {
			types.forEach(type => {
				// Disable password authentication and signature when environment is not dev
				if (!environment.dev && type.id === 'password') {
					return;
				}
				this.typesMap.set(type.id, type);
			});
		});
	}

	/**
	 * Find a signature process type based on its name
	 *
	 * @param name The name of the process
	 * @returns The process type, or undefined
	 */
	public getType(id: any): SignatureType {
		return this.typesMap.get(id);
	}

	/**
	 * Find all process types available
	 *
	 * @returns An iterator on the signature types
	 */
	public getTypes(): IterableIterator<SignatureType> {
		return this.typesMap.values();
	}

	/**
	 * Retrieve the default process to use.
	 * This should can be used to display a missing type or use a default signature process.
	 *
	 * @returns The default process type
	 */
	public getDefaultType(): SignatureType {
		return this.getType(this.defaultType);
	}

	/**
	 * Retrieve the component to use for selecting the type of signature to use.
	 * It will be used even when 0 or one type is allowed.
	 *
	 * @returns The component class to use as a process type selector
	 */
	public getSelectorComponent(): Type<SignatureTypeSelectorComponent> {
		return this.selectorComponent;
	}
}
