import { Injectable } from '@angular/core';
import { ForgotPasswordModel } from '../model/forgot-password-model';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { SignableOperation } from '@mdib/signature';
import { isNullOrUndefined } from 'util';

@Injectable()
export abstract class ForgotPasswordService {

	forgotPasswordModel: SignableOperation<ForgotPasswordModel> = null;

	/**
	 * Returns shared instance of forgot password operation
	 * @returns {SignableOperation<ForgotPasswordModel>} shared instance of forgot password operation
	 * @memberof ForgotPasswordService
	 */
	public getForgotPasswordOperation(): SignableOperation<ForgotPasswordModel> {
		this.forgotPasswordModel = !isNullOrUndefined(this.forgotPasswordModel) ? this.forgotPasswordModel : new SignableOperation(new ForgotPasswordModel());
		return this.forgotPasswordModel;
	}

	/**
	 * Initiates reset of password
	 * @param userId user id for which the password has to be reset
	 * @returns {Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>>} the forgot password model
	 * @memberof ForgotPasswordService
	 */
	public abstract init(userId: string): Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>>;

	/**
	 * Confirms reset of password
	 * @param forgotPasswordOperation model containing user id and secret question and answers
	 * @returns {Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>>} the forgot password model
	 * @memberof ForgotPasswordService
	 */
	public abstract confirm(forgotPasswordOperation: SignableOperation<ForgotPasswordModel>): Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>>;
}
