import { ContactAddress } from '@mdib/customers';
import { ContractType, SepaDirectDebitPaymentStatus } from '@mdib/sepa-direct-debits';

/**
 * Holds data about a cash account operation
 */
export class CashAccountOperation {

	date: Date;
	valueDate: Date;
	amount: number;
	currency: string;
	isDebitOperation: boolean;
	counterpartyAccount: string;
	counterpartyName: string;
	isStructuredCommunication: boolean;
	communication = '';
	accountNumber: string;
	movementNumber: string;
	reference: string;
	operationNature: string;
	frequency: string;
	duration: string;
	startDate: Date;
	paymentDate: Date;
	interruptionPeriodFrom: string;
	interruptionPeriodTo: string;
	isStandingOrder: boolean;
	paymentScheme: ContractType;
	additionalWording: string;
	operationStatus: SepaDirectDebitPaymentStatus;
	transactionReference: string;
	standardWording: string;

	// TODO : manage address correctly wher needed
	private _counterpartyAddress: ContactAddress = new ContactAddress();

	get counterpartyAddress(): ContactAddress {
		return this._counterpartyAddress;
	}

	set counterpartyAddress(counterpartyAddress: ContactAddress) {
		this._counterpartyAddress = counterpartyAddress;
	}
}


