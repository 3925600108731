import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ConfigurationService } from '@mdib/utils';

import { SessionUtilsService } from './session-utils.service';
import { SessionTimeoutService } from './session-timeout.service';
import { SessionsService } from './sessions.service';

@Injectable()
export class SessionBackgroundTimeoutService {

	private triggerPauseTime: number;
	private timeout: number;

	constructor(
		private sessionUtilsService: SessionUtilsService,
		private sessionTimeoutService: SessionTimeoutService,
		private sessionsService: SessionsService,
		private router: Router,
		private configurationService: ConfigurationService,
	) { }

	public init(startEvent$: Observable<Event>, endEvent$: Observable<Event>) {
		this.timeout = this.configurationService.instant('application.session.timeout');
		startEvent$.subscribe(
			() => this.startTimer()
		);
		endEvent$.subscribe(
			() => this.endTimer()
		);
	}

	private startTimer(): void {
		if (this.sessionUtilsService.isActiveUserConnected()) {
			this.sessionTimeoutService.onPause = true;
			this.triggerPauseTime = Date.now();
		}
	}

	private endTimer(): void {
		if (this.sessionUtilsService.isActiveUserConnected()) {
			this.sessionTimeoutService.onPause = false;
			if (this.isOverTimeout()) {
				this.sessionsService.removeSession().subscribe(
					() => {
						this.sessionsService.setSessionsAndActiveSessionIdInStorage(null);
						this.router.navigate(['/session-logout']);
					}
				);
			}
		}
	}

	private isOverTimeout(): boolean {
		return (Date.now() - this.triggerPauseTime) > this.timeout;
	}
}
