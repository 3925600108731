import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorsPagesRoutingModule } from './errors-pages-routing.module';
import { GenericErrorComponent } from './components/generic-error/generic-error.component';
import { StylingModule } from '@mdib/styling';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TranslateModule } from '@ngx-translate/core';
import { AccessDeniedPageComponent } from './components/access-denied-page/access-denied-page.component';
import { DefaultErrorPageComponent } from './components/default-error-page/default-error-page.component';
import { ApplicationOutdatedPageComponent } from './pages/application-outdated-page/application-outdated-page.component';

@NgModule({
	imports: [
		CommonModule,
		ErrorsPagesRoutingModule,
		StylingModule,
		TranslateModule,
	],
	declarations: [
		GenericErrorComponent,
		PageNotFoundComponent,
		AccessDeniedPageComponent,
		DefaultErrorPageComponent,
		ApplicationOutdatedPageComponent,
	],
	exports: [
		ApplicationOutdatedPageComponent,
	]
})
export class ErrorsPagesModule { }
