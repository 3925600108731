import { CostsTypes } from '@mdib/payments/types/models/payments.enum';

export class InternationalPaymentDetails {

	private _costs: CostsTypes.Shared;
	private _bankCountry: string;

	constructor(execution?: Partial<InternationalPaymentDetails>) {
		Object.assign(this, execution);
	}

	get costs(): CostsTypes {
		return this._costs;
	}

	set costs(value: CostsTypes) {
		this._costs = value;
	}

	get bankCountry(): string {
		return this._bankCountry;
	}

	set bankCountry(value: string) {
		this._bankCountry = value;
	}
}
