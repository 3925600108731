import { Injectable } from '@angular/core';
import { MockStandingOrders } from '@mdib-memory/standing-orders/mock/mock-standing-orders';
import { StandingOrder, StandingOrdersCommonService, StandingOrdersPageFilter } from '@mdib/standing-orders';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { SignatureModes } from '@mdib/signature-modes';
import { delay } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class StandingOrdersMemoryService extends StandingOrdersCommonService {

	readonly DEFAULT_DELAY = 400;

	constructor() {
		super();
	}

	public list(offset?: number, limit?: number, filter?: StandingOrdersPageFilter): Observable<ServiceResponse<StandingOrder[]>> {
		const start = offset * limit;
		const end = start + limit;
		return of(new ServiceResponse<StandingOrder[]>(
			filter ? this.getFilteredStandingOrdersListCropped(start, end, filter) : MockStandingOrders.getMockStandingOrders().slice(start, end),
		)).pipe(delay(this.DEFAULT_DELAY));
	}

	public count(filter: StandingOrdersPageFilter): Observable<ServiceResponse<number>> {
		return of(new ServiceResponse<number>(
			filter ? this.getFilteredStandingOrdersList(filter).length : MockStandingOrders.getMockStandingOrders().length))
			.pipe(delay(this.DEFAULT_DELAY));
	}

	public get(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>> {
		return of(new ServiceResponse<StandingOrder | null>(
			MockStandingOrders.getMockStandingOrders().find((mockStandingOrder: StandingOrder) => mockStandingOrder.reference === standingOrder.reference)
		));
	}

	public validateStandingOrder(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>> {
		standingOrder.signatureTypesAllowed = [SignatureModes.PASSWORD];
		return of(new ServiceResponse<StandingOrder>(standingOrder));
	}

	public signStandingOrder(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>> {
		return of(new ServiceResponse<StandingOrder>(standingOrder));
	}

	private getFilteredStandingOrdersListCropped(start: number, end: number, filter: StandingOrdersPageFilter): StandingOrder[] {
		return this.getFilteredStandingOrdersList(filter).slice(start, end);
	}

	private getFilteredStandingOrdersList(filter: StandingOrdersPageFilter): StandingOrder[] {
		return MockStandingOrders.getMockStandingOrders().filter((standingOrder: StandingOrder) => {
			if (filter.ordererAccountNumber && !standingOrder.ordererAccountNumber.startsWith(filter.ordererAccountNumber)) {
				return false;
			}
			return true;
		});
	}
}
