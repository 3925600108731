import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CashAccount } from '@mdib/cash-accounts';
import { StandingOrder, StandingOrdersService } from '@mdib/standing-orders';
import { DataFormattingService, Status } from '@mdib/utils';
import { StandingOrderDetails, PaymentOperation, PaymentForm, PaymentTypes, OperationStatus } from '@mdib/payments';
import { ConfigService } from '@mdib/config';

@Component({
	selector: 'mdib-data-gathering-step-update-standing-order',
	templateUrl: './data-gathering-step.component.html',
	styleUrls: ['./data-gathering-step.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataGatheringStepComponent {

	readonly NAMESPACE = 'standingOrdersPagesModule.updateStandingOrderPageComponent.dataGatheringStepComponent.';

	@Input() paymentForm: PaymentForm;
	@Input() selectedStep = 0;
	@Input() isConsultation = false;

	operationStatus = OperationStatus;
	status = Status;
	paymentTypes = PaymentTypes;

	constructor(
		private configService: ConfigService,
	) {}

	public onCashAccountChange(cashAccount: CashAccount): void {
		// this.paymentForm.ordererAccount.setValue(cashAccount);
	}

	get displayCommunication(): boolean {
		return this.paymentForm.beneficiaryForm.communicationForm.group.enabled || (this.paymentForm.beneficiaryForm.communicationForm.group.disabled && !!this.paymentForm.beneficiaryForm.communicationForm.type.value && !!this.paymentForm.beneficiaryForm.communicationForm.value.value);
	}

	get displayDateSection(): boolean {
		return (this.paymentForm.group.enabled) ||
			((this.paymentForm.type.value !== PaymentTypes.europeanStandingOrder) && this.paymentForm.paymentDate.disabled && this.paymentForm.paymentDate.value) ||
			(this.paymentForm.type.value === PaymentTypes.europeanStandingOrder && this.paymentForm.standingOrderDetailsForm.group.disabled &&
				!!this.paymentForm.standingOrderDetailsForm.startDate.value ||
				!!this.paymentForm.standingOrderDetailsForm.endDate.value ||
				!!this.paymentForm.standingOrderDetailsForm.iteration.value ||
				!!this.paymentForm.standingOrderDetailsForm.totalAmount.value);
	}

	get displayFrequencySection(): boolean {
		return this.paymentForm.type.value === PaymentTypes.europeanStandingOrder;
	}

	get accountCurrency(): string {
		return this.paymentForm.model.type === PaymentTypes.internationalPayment ? null : this.configService.baseCurrencyCode;
	}

	showStatus(): boolean {
		return this.isConsultation && !(this.paymentForm.model.operationStatus === OperationStatus.inShoppingBasket || this.paymentForm.model.operationStatus === OperationStatus.unknown);
	}

	showOperationDate(): boolean {
		return this.isConsultation && !(this.paymentForm.model.operationStatus === OperationStatus.inShoppingBasket || this.paymentForm.model.operationStatus === OperationStatus.unknown) && (this.paymentForm.model.type !== this.paymentTypes.europeanStandingOrder);
	}

}
