import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { OverlayModule } from '@angular/cdk/overlay';

import { StylingModule } from '@mdib/styling';

import { VirtualNumpadComponent } from './components/virtual-numpad/virtual-numpad.component';
import { VirtualNumpadDirective } from './directives/virtual-numpad.directive';
import { VirtualNumpadService } from './services/virtual-numpad.service';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		StylingModule.forRoot(),
		OverlayModule
	],
	exports: [
		VirtualNumpadComponent,
		VirtualNumpadDirective
	],
	entryComponents: [
		VirtualNumpadComponent
	],
	declarations: [
		VirtualNumpadComponent,
		VirtualNumpadDirective
	],
	providers: [
		VirtualNumpadService
	]
})
export class VirtualNumpadModule { }
