import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { b64toBlob, XclDateFormatter } from '@mdib-xcl/utils';
import { SavingsAccountCommonService, WithdrawalSimulation } from '@mdib/cash-accounts';
import { ParameterModel } from '@mdib/http';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { WithdrawalSimulationXcl } from '../../model/withdrawal-simulation-xcl';

@Injectable({
	providedIn: 'root',
})
export class SavingsAccountXclService implements SavingsAccountCommonService {

	private treatedSimulation: WithdrawalSimulation;

	constructor(
		private xclHttpService: XclHttpService,
		private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
	) { }

	public getWithdrawalSimulation(withdrawalSimulation: WithdrawalSimulation): Observable<ServiceResponse<WithdrawalSimulation>> {
		this.treatedSimulation = withdrawalSimulation;
		const parameters: ParameterModel[] = [
			new ParameterModel('accountNumber', withdrawalSimulation.account.number),
			new ParameterModel('outputChannel', 'C'),
			new ParameterModel('callMode', '03'),
			new ParameterModel('withdrawalAmount', withdrawalSimulation.amount.toString()),
			new ParameterModel('effectiveDate', XclDateFormatter.convertDateToXCLDateFormat(withdrawalSimulation.date)),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<WithdrawalSimulationXcl>> = this.xclHttpService.execute(
			'withdrawalsimulationdocument', XclHttpService.GET, parameters ) as Observable<MonogoalOrderXCLModel<WithdrawalSimulationXcl>>;
		return this.xclToFunctionalObservable(xclObservable);
	}

	private xclToFunctionalObservable(technicalObservable: Observable<MonogoalOrderXCLModel<WithdrawalSimulationXcl>>): Observable<ServiceResponse<WithdrawalSimulation>> {
		return technicalObservable.pipe(map( (goalSimulation: MonogoalOrderXCLModel<WithdrawalSimulationXcl>) => {
			return new ServiceResponse(this.xclObservableToFunctionalModel(goalSimulation), this.feedbackExtractor.extract(goalSimulation));
		}));
	}

	private xclObservableToFunctionalModel(goalSimulation: MonogoalOrderXCLModel<WithdrawalSimulationXcl>): WithdrawalSimulation {
		const xclSimulation: WithdrawalSimulationXcl = goalSimulation.goal;
		this.treatedSimulation.result = Number(xclSimulation.impactedPremiumAmount);
		this.treatedSimulation.documentName = xclSimulation.file.name + '.' + xclSimulation.file.extension;
		this.treatedSimulation.document = b64toBlob(xclSimulation.file && xclSimulation.file.content, xclSimulation.file && xclSimulation.file.contentType);
		return this.treatedSimulation;
	}
}
