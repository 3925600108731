import { ModuleWithProviders, NgModule } from '@angular/core';
import { FileMapperXclService } from '@mdib-xcl/utils';

@NgModule()
export class FileUploadersXclModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: FileUploadersXclModule,
			providers: [
				FileMapperXclService,
			]
		};
	}
}
