import { Immutable } from '@mdib/core/meta';
import { BackendOperationState } from '@mdib/core/adapters';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS';

export interface XclCallConfigurationType {
	resourceId: string;
	action: string;
	method: HttpMethod;
	useReference: boolean;
	body: any;
	state: BackendOperationState;
}

@Immutable()
export class XclCallConfiguration implements XclCallConfigurationType {

	public readonly resourceId: string = null;
	public readonly action: string = null;
	public readonly method: HttpMethod = 'PUT';
	public readonly useReference: boolean = false;
	public readonly body: any = undefined;
	public readonly state: BackendOperationState = BackendOperationState.WAITING_ACTION;

	constructor(properties: Partial<XclCallConfigurationType> = {}) {
		Object.assign(this, properties);
	}

	public set(key: string, value: any) {
		const copy: any = {};
		Object.assign(copy, this);
		copy[key] = value;
		return new XclCallConfiguration(copy);
	}

	public setResourceId(resourceId: string): XclCallConfiguration {
		return this.set('resourceId', resourceId);
	}

	public setAction(action: string): XclCallConfiguration {
		return this.set('action', action);
	}

	public setMethod(method: string): XclCallConfiguration {
		return this.set('method', method);
	}

	public setUseReference(useReference: boolean): XclCallConfiguration {
		return this.set('useReference', useReference);
	}

	public setBody(body: any): XclCallConfiguration {
		return this.set('body', body);
	}

	public setState(state: BackendOperationState): XclCallConfiguration {
		return this.set('state', state);
	}
}
