import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { CashAccount, CashAccountCommonService, CashAccountCreation, CashAccountCreationBuilder } from '@mdib/cash-accounts';
import { SignatureModes } from '@mdib/signature-modes';
import { ServiceResponse, Status, UtilsHelper } from '@mdib/utils';

import { MockCashAccount } from '../mock/mock-cash-account';
import { CashAccountSortOrder } from '@mdib/cash-accounts';

@Injectable({
	providedIn: 'root',
})
export class CashAccountMemoryService extends CashAccountCommonService {
	static readonly LATENCY = 1000;

	private cashAccounts: CashAccount[];
	private cashAccount: CashAccount;

	public validate(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		return of(new ServiceResponse(CashAccountCreationBuilder
			.from(cashAccountCreation)
			.reference('A0000000000000001')
			.accountNumber('BE33732015307732')
			.status(Status.ToSign)
			.signatureTypesAllowed([SignatureModes.PASSWORD])
			.cashAccountCreation,
		)).pipe(delay(CashAccountMemoryService.LATENCY));
	}

	public confirm(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		return of(new ServiceResponse(CashAccountCreationBuilder
			.from(cashAccountCreation)
			.status(Status.Closed)
			.cashAccountCreation,
		)).pipe(delay(CashAccountMemoryService.LATENCY));
	}

	public sign(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		cashAccountCreation.status = Status.Closed;
		return of(new ServiceResponse(cashAccountCreation)).pipe(
			delay(CashAccountMemoryService.LATENCY));
	}

	public list(): Observable<ServiceResponse<CashAccount[]>> {
		if (UtilsHelper.nullOrUndefined(this.cashAccounts)) {
			this.cashAccounts = MockCashAccount.getMockCashAccounts();
		}

		return of(
			new ServiceResponse<CashAccount[]>(this.cashAccounts),
		);
	}

	public updateSortOrder(accountSortOrder: CashAccountSortOrder): void {
		const updatedAccountList: CashAccount[] = [];
		accountSortOrder.listAccountFreeSortOrder.forEach(acc => {
			const nextAccount: CashAccount = this.cashAccounts.find(account => account.number === acc.accountNumber);
			updatedAccountList.push(nextAccount);
		});
		this.cashAccounts = updatedAccountList;
	}

	public get(accountNumber: string): Observable<ServiceResponse<CashAccount>> {
		if (this.cashAccount) {
			this.cashAccount = MockCashAccount.getMockCashAccounts().find(x => x.number === accountNumber);
		}

		return of(
			new ServiceResponse<CashAccount>(this.cashAccount)
		).pipe(delay(250));
	}
}
