import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ConfigService, CountryCodes } from '@mdib/config';
import { printFormat, fromBBAN, isValidBBAN, isValid } from 'iban';

import { UtilsHelper } from '../helper/utils-helper';

@Directive({
	selector: '[ibanMask]',
})
export class IbanMaskDirective {

	readonly IBAN_FORMAT = /^[A-Z]{2}[0-9]{2}[0-9A-Z]{11,30}$/;
	readonly country: string;
	private elem;

	constructor(private ref: ElementRef,
				private config: ConfigService,
				private control: NgControl) {
		this.elem = this.ref.nativeElement;
		this.country = CountryCodes[this.config.bankCountry];
	}

	@HostListener('input') onInput() {
		this.tryFormat(String(this.ref.nativeElement.value).toUpperCase());
	}

	@HostListener('blur') onBlur() {
		this.tryFormat(UtilsHelper.onlyAlpha(this.ref.nativeElement.value));
		this.valueToControl();
	}

	tryFormat(value: string) {
		try {
			if (value.length === this.config.bbanLenght && isValidBBAN(this.country, value) && this.verifyBelgianBban(value)) {
				this.ref.nativeElement.value = printFormat(fromBBAN(this.country, value), ' ');
			} else if (value.length >= this.config.ibanMinLenght && value.length <= this.config.ibanMaxLength && this.IBAN_FORMAT.test(value) && isValid(value)) {
				this.ref.nativeElement.value = printFormat(value, ' ' );
			}
		} catch (e) {
			this.ref.nativeElement.value = this.ref.nativeElement.value;
		} finally {
			this.valueToControl();
		}
	}

	private verifyBelgianBban(value: string) {
		return Number(value.substring(0, 10)) % 97 === Number(value.substring(10));
	}

	private valueToControl(): void {
		if (UtilsHelper.objectNotEmpty(this.control) && UtilsHelper.objectNotEmpty(this.control.control)) {
			this.control.control.setValue(this.ref.nativeElement.value);
		}
	}
}
