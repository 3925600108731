import { Injectable, Injector } from '@angular/core';

import { BackendOperation, SearchCriteria, SearchResult } from '@mdib/core/adapters';
import { BeneficiariesServiceV2 as BeneficiariesService, BeneficiaryV2 as Beneficiary } from '@mdib/beneficiaries';

import { BeneficiaryCreationXclOperation } from './operations/beneficiary-creation-xcl.operation';
import { BeneficiaryRetrieveXclOperation } from './operations/beneficiary-retrieve-xcl.operation';
import { BeneficiaryUpdateXclOperation } from './operations/beneficiary-update-xcl.operation';
import { BeneficiaryDeletionXclOperation } from './operations/beneficiary-deletion-xcl.operation';
import { BeneficiariesSearchXclOperation } from './operations/beneficiaries-search-xcl.operation';

@Injectable({
	providedIn: 'root',
})
export class BeneficiariesXclService extends BeneficiariesService {

	constructor(
		private injector: Injector,
	) { super(); }

	public create(): BackendOperation<Beneficiary, Beneficiary> {
		return new BeneficiaryCreationXclOperation(this.injector);
	}

	public retrieve(): BackendOperation<string, Beneficiary> {
		return new BeneficiaryRetrieveXclOperation(this.injector);
	}

	public update(): BackendOperation<Partial<Beneficiary>, Beneficiary> {
		return new BeneficiaryUpdateXclOperation(this.injector);
	}

	public delete(): BackendOperation<string, Beneficiary> {
		return new BeneficiaryDeletionXclOperation(this.injector);
	}

	public search(): BackendOperation<SearchCriteria<any>, SearchResult<Beneficiary>> {
		return new BeneficiariesSearchXclOperation(this.injector);
	}
}
