import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { SepaDirectDebitContract } from '@mdib/sepa-direct-debits';
import { BackendOperationState, BackendOperationStep } from '@mdib/core/adapters';

import { MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { BackendOperationXcl, DecisionTree, XclCallConfiguration } from '@mdib-xcl/core';

import { SepaDirectDebitContractsMapperXclService } from '../sepa-direct-debit-contracts-mapper-xcl.service';
import { SepaDirectDebitCreditorDetailsRetrieveXcl } from '../../service/operations/sepa-direct-debit-creditor-details-retrieve-xcl';
import { SepaDirectDebitContractXcl } from '../../model/sepa-direct-debit-contract-xcl';

export class SepaDirectDebitContractsRetrieveXcl extends BackendOperationXcl<string, SepaDirectDebitContract, SepaDirectDebitContractXcl> {

	sepaDirectDebitContractMapperXclService: SepaDirectDebitContractsMapperXclService;

	protected xclEndpoint = 'sepa-direct-debit-contracts-retrieve';
	// State machine
	protected decision: DecisionTree = {
		'____': {
			triggers: { 'retrieve': this.retrieve },
			defaultTrigger: 'retrieve',
			expectedStates: ['9000'],
		},
	};

	constructor(private injector: Injector) {
		super(injector);
		this.sepaDirectDebitContractMapperXclService = injector.get(SepaDirectDebitContractsMapperXclService);
	}

	protected handleResponse(order: MonogoalOrderXCLModel<SepaDirectDebitContractXcl>): Observable<SepaDirectDebitContract> {
		return super.handleResponse(order).pipe(mergeMap(() => {
			return this.sepaDirectDebitContractMapperXclService.sepaDirectDebitContractXclToSepaDirectDebitContract(order.goal);
		}));
	}

	protected retrieve(trigger: string, contractReference?: string): Observable<BackendOperationStep<SepaDirectDebitContract>> {
		return this.calls(trigger, [
			new XclCallConfiguration({ resourceId: contractReference, method: XclHttpService.GET, state: BackendOperationState.SUCCEEDED })
		]).pipe(mergeMap((operation: BackendOperationStep<SepaDirectDebitContract>) => {
			return new SepaDirectDebitCreditorDetailsRetrieveXcl(this.injector).retrieve('retrieve', operation.result);
		}));
	}


}
