import { Injectable } from '@angular/core';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { ShoppingBasketOperation } from '../model/shopping-basket-operation';
import { ShoppingBasketEnvelope } from '../model/shopping-basket-envelope';
import { ShoppingBasketService } from './shopping-basket.service';

@Injectable()
export class ShoppingBasketServiceStub extends ShoppingBasketService {

	public getOperationsToSign(offset?: number, limit?: number): Observable<ServiceResponse<ShoppingBasketOperation[]>> {
		return of();
	}

	public reloadOperations(): void { }

	public deleteOperations(operationsToDelete: ShoppingBasketOperation[]): Observable<ServiceResponse<null>> {
		return of();
	}

	public deleteOperation(operationToDelete: ShoppingBasketOperation): Observable<ServiceResponse<ShoppingBasketOperation>> {
		return of();
	}

	public createEnvelope(operationsToSign: ShoppingBasketOperation[]): Observable<ServiceResponse<ShoppingBasketEnvelope>> {
		return of();
	}

	public signEnvelope(envelope: ShoppingBasketEnvelope): Observable<ServiceResponse<ShoppingBasketEnvelope>> {
		return of();
	}

	public retrieve(reference: string): Observable<ServiceResponse<ShoppingBasketOperation>> {
		return of();
	}

	public unsignedOperationsCounter(): Observable<ServiceResponse<number>> {
		return of();
	}

}
