
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, mergeMap, tap, map } from 'rxjs/operators';

import { ConfigurationService, Feedback, ServiceResponse } from '@mdib/utils';
import { IAdvHttpError } from '@mdib/cordova';

import { EndPointModel, HttpResponseModel, ParameterModel } from '../model';
import { HttpStatusManagementService } from './http-status-management.service';
import { HttpService } from './http.service';

@Injectable()
export abstract class RestHttpService<M> {
	public static readonly GET = 'GET';
	public static readonly POST = 'POST';
	public static readonly PUT = 'PUT';
	public static readonly DELETE = 'DELETE';
	public static readonly PATCH = 'PATCH';
	public static readonly OPTIONS = 'OPTIONS';
	public static readonly HEAD = 'HEAD';

	private static readonly DEFAULT_DOMAIN: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

	public httpStatusBehavior: BehaviorSubject<HttpResponseModel> = new BehaviorSubject<HttpResponseModel>(new HttpResponseModel());

	constructor(
		private _httpStatusManagementService: HttpStatusManagementService,
		private _httpService: HttpService,
		protected configurationService: ConfigurationService
	) {
		this._httpStatusManagementService.init(this.httpStatusBehavior);
	}

	/**
	 * This method calls the provided endpoint through the Angular's HTTP client.
	 * @param {string} endpointKey, the key to the url of the endpoint
	 * @param {string} method, the HTTP method (GET, POST, ...)
	 * @param {ParameterModel[]} parameters, the parameters to be appended to the URL
	 * @param data, the payload of the HTTP request
	 * @param {{ [name: string]: string | string[]; }} headers
	 * @returns {Observable<M>} where M is a generic type for the functional model
	 */
	public execute(endpointKey: string, method: string, parameters: ParameterModel[] = [], data?: any, headers: { [name: string]: string | string[]; } = {}): Observable<M> {
		return of(this.configurationService.instant(endpointKey)).pipe(
			catchError(() => this.throwResponseOnUnknownEndpointKey(endpointKey)),
			mergeMap((endpoint: EndPointModel) => this.createUrl(endpoint, parameters, method)),
			mergeMap(
				(url: string) => {
					headers = this.addHeaders(headers);
					return this._httpService.execute(method, url, data, headers);
				}
			),
			catchError((err: HttpErrorResponse | IAdvHttpError) => {
				this.sendHttpStatus(err, endpointKey);
				return this.throwResponseOnHttpCallFailure(err);
			}),
			tap(res => this.sendHttpStatus(res, endpointKey)),
			mergeMap(this.convertHttpResponseToModel),
			map((m: M) => this.inspectModel(m))
		);
	}

	protected addHeaders(headers: { [name: string]: string | string[]; }): { [name: string]: string | string[]; } {
		headers['Content-Type'] = 'application/json';
		return headers;
	}

	protected addParametersToUrl(url: string, parameters: ParameterModel[], method: string): string {
		const addParametersToGetUrl = () => {
			for (let i = 0; i < parameters.length; i++) {
				url += (i === 0 ? '?' : '&') + parameters[i].par + '=' + parameters[i].val;
			}
			return url;
		};

		const addParametersToPutUrl = () => {
			for (let i = 0; i < parameters.length; i++) {
				url += '/' + parameters[i].val;
			}
			return url;
		};

		if (parameters === undefined || parameters === null) {
			return url;
		}

		if (RestHttpService.GET === method) {
			return addParametersToGetUrl();
		} else if (RestHttpService.PUT === method || RestHttpService.DELETE === method) {
			return addParametersToPutUrl();
		}
		return url;
	}

	protected checkRequiredParameters(ep: EndPointModel, parameters: ParameterModel[]) {
		for (let i = 0; i < ep.requiredParameters.length; i++) {
			const requiredParameter = ep.requiredParameters[i];
			let present = false;
			for (let j = 0; j < parameters.length; j++) {
				if (parameters[j].par === requiredParameter) {
					present = true;
					break;
				}
			}
			if (!present) {
				console.log('parameter >>>' + requiredParameter + '<<< missing');
				// TODO error management (or try/catch)
			}
		}
	}

	protected convertHttpResponseToModel(res: any): Observable<M> {
		const model: M = res;
		return of(model);
	}

	protected createUrl(ep: EndPointModel, parameters: ParameterModel[], method: string): Observable<string> {
		if (parameters) {
			this.checkRequiredParameters(ep, parameters);
		}

		const concatDomain = (domain: string) => (isNullOrUndefined(domain) ? RestHttpService.DEFAULT_DOMAIN : domain) + ep.uri;

		const applyParameters = (url: string) => {
			const parameterKeysToRemove: string[] = [];
			parameters.forEach((param: ParameterModel) => {
				const urlParameterPattern = '\\${' + param.par + '}';
				if (url.match(urlParameterPattern)) {
					url = url.replace(new RegExp(urlParameterPattern, 'g'), param.val);
					parameterKeysToRemove.push(param.par);
				}
			});
			parameterKeysToRemove.forEach((parameterKeyToRemove: string) => {
				parameters.splice(parameters.findIndex((param: ParameterModel) => param.par === parameterKeyToRemove), 1);
			});

			return this.addParametersToUrl(url, parameters, method);
		};

		const urlStream = of(this.configurationService.instant('services.endpoint.domain'))
			.pipe(map(concatDomain));

		if (!parameters) {
			return urlStream;
		}

		return urlStream.pipe(map(applyParameters));
	}

	protected inspectModel(model: M): M {
		return model;
	}

	protected sendHttpStatus(res: any, endpointKey: string) {
		const httpResponse: HttpResponseModel = new HttpResponseModel();
		httpResponse.endpoint = endpointKey;
		httpResponse.status = res && res.status;
		this.httpStatusBehavior.next(httpResponse);
	}

	protected throwResponseOnUnknownEndpointKey(endpointKey: string): Observable<any> {
		return throwError(
			new ServiceResponse<null>(
				null,
				[<Feedback>{
					defaultMessage: `The endpoint ${endpointKey} is not defined`,
					key: 'error_ENDPOINT_UNDEFINED',
					type: 'error'
				}]
			)
		);
	}

	protected throwResponseOnHttpCallFailure(err: HttpErrorResponse | IAdvHttpError): Observable<any> {
		const statusCodeAsText = '' + err.status;

		return throwError(
			new ServiceResponse<null>(null, [<Feedback>{
				defaultMessage: statusCodeAsText,
				key: `error_${statusCodeAsText}`,
				type: 'error'
			}])
		);
	}
}
