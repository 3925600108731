import { OnInit, Injectable } from '@angular/core';
import { Unsubscribable } from 'rxjs';

import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { Feedback, FeedbackTypes } from '@mdib/utils';

import { BackendRetrieveService, BackendDeletionService } from '../../types/backends/backend-service';
import { BackendOperation } from '../../types/operations/backend-operation';
import { BackendOperationStep } from '../../types/operations/backend-operation-step';

export abstract class DeletePageComponent<T> implements OnInit {

	public model: T;
	public operation: BackendOperation<T, T>;

	public isLoadingData = false;
	public itemIdentifier;

	protected subscription: Unsubscribable;

	public constructor(
		protected backendService: BackendRetrieveService<T> & BackendDeletionService<T>,
		protected notificationsMapper: ServiceResponseNotificationMessagesMapperService,
	) { }

	public ngOnInit(): void {
		this.initializeModel();
		this.createOperation();
		this.operation.execute(this.itemIdentifier);
	}

	public handleOperationEvent(event: Event): void { }

	protected initializeModel(): void {
		this.isLoadingData = true;

		const retrieveOperation = this.backendService.retrieve();
		retrieveOperation.execute(this.itemIdentifier).subscribe(
			(step) => {
				this.notificationsMapper.sendFeedbacks(step.feedbacks);
				this.model = step.result;
				this.isLoadingData = false;
			},
			(error) => this.handleOperationError(error)
		);
	}

	protected createOperation(): void {
		// Clean any previous one
		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		// Create and listen to a new operation
		this.operation = this.backendService.delete();
		this.subscription = this.operation.subscribe(this.handleOperationStep.bind(this), this.handleOperationError.bind(this));
	}

	protected handleOperationStep(step: BackendOperationStep<T>): void {
		this.notificationsMapper.clearAll();
		this.notificationsMapper.sendFeedbacks(step.feedbacks);
	}

	protected handleOperationError(error: any): void {
		this.notificationsMapper.clearAll();
		this.createOperation();
		if (error.feedbacks) {
			this.notificationsMapper.sendFeedbacks(error.feedbacks);
		} else {
			this.notificationsMapper.sendFeedback(new Feedback('unexpectedError', FeedbackTypes.BACKEND_ERROR, 'An unexpected error occured'));
		}
	}
}
