export class XclCashAccountCreationModel {
	accountCurrency: string; // The account currency (format: ISO 4217)
	aggregationSwitch: string; // When this switch is true, it indicates that the credit operations generated by a transfer are aggregated for this account. Aggregation consists of effecting only one accounting movement for an amount equal to the sum of the individual operations comprising the batch of transfers received (format: Y/N)
	authorityCheck: string; // When this switch is true, it indicates that client signature authority must be checked for any movement on this account. Authorisation suspension will systematically occur for each operation (format: Y/N)
	bankProduct: string; // The bank product to identify and characterise the product represented by the account (6 characters)
	entity: string; // The bank entity that manages the client account (8 characters)
	franchiseMultiplier: string; // Multiplication of the deductible of 1440 euros of interest not subject to withholding tax on deposit books in certain special situations. The multiplier must be a whole number between 0 and 9. For example, it is set to 0 in the case of companies deposit books (possible values: 00-99)
	languageCode: string; // The language code used to get the wording associated to the component type (an argument of parameter table 056)
	messageClass: string; // This code to identify the class to which the message belongs.The message class is a subdivision of messages established according to the processing that must be applied to them (an argument of parameter table 397)
	portfolio: string; // The number of portfolio manager to which the account is linked (2 characters)
	privateUseSwitch: string; // When this switch is true, it indicates that the account is for private use (format: Y/N)
	identifier?: string; // The accountNumber
	accountNumber?: string; // The account number (format: IBAN)
	accountTitle?: string; // A free description of the account which is not mandatory to fill. This field allows the client to personalize the account and is totally free. E.g.: 'DUPONT VS DURAND’, 'LLN Location Account’, etc. (max 35 characters)
	addressType?: string; // The type to qualify the address (an argument of parameter table PY5)
	applicableSelfCertificationSwitch?: boolean; // default: false. When this switch is true, it indicates that self-certification can be applied
	bankCardSwitch?: string; // When this switch is true, it indicates that there is a card for the account (format: Y/N)
	blockingReason?: string; // The code corresponding to the blocking reason. This briefly qualifies the reason for blocking the account. A more literary comment can be made in a memo (an argument of parameter table 067)
	cashAccountHolderIdentity?: string; // The client identity of the holder of the account (8 characters)
	chargedAccountNumber?: string; // The account on which the interest and costs amounts are posted. By default, the account to post is the same as the original account. This account to post does not necessarily belong to the same client as the original account (30 characters)
	clientIdentity?: string; // The client identity (8 characters)
	communicationChannel?: string; // The channel of the operation: B2C (3 characters)
	context?: string; // Technical field
	costType?: string; // The type of costs deducted from the account (e.g. account management, debit operations, etc.). The deduction takes place during the interest calculation (an argument of parameter table 185)
	entityWording?: string; // The wording of the bank entity
	fixedPriceCode?: string; // The type of fixed amount applied to this account. This fixed amount is used to charge for the different operations carried out on this account (an argument of parameter table 382)
	insuranceCode?: string; // The code to identify the type of insurance linked to the account. This is used for current accounts and savings accounts where the clients have taken out insurance (e.g. a 12-month guarantee insurance) (an argument of parameter table 134)
	insuranceEndDate?: string; // The date on which the insurance policy comes to an end (format?: YYYYMMDD)
	insurancePremiumPaymentDate?: string; // The date on which the insurance premium was paid (format?: YYYYMMDD)
	insuranceStartDate?: string; // The date on which insurance rate takes effect. The field is mandatory if the account includes insurance (e.g. a 12-month guarantee insurance) (format?: YYYYMMDD)
	interestPaymentMonth?: string; // The month in which the interest payment takes place (e.g. 01 for January). The possibility of freely defining the interest payment month depends on a switch which must be filled by each bank. If the switch is true the possibility exists. Otherwise it is impossible for the user to choose freely the interest payment month (2 characters)
	isDirectDebitsAllowed?: boolean; // default: false. When this switch is true, it indicates that the direct debits are allowed
	isExistingInternetSubscription?: boolean; // default: false. When this switch is true, it indicates that internet subscription exist
	memoReference?: string; // The reference to the memo in which additional information concerning the account is written (16 characters)
	messageCategory?: string; // The code to identify the category to which the message belongs. This category must be specified where the sending instruction is valid for a given category of messages. A category covers several message types
	messageType?: string; // The code to identify the message type. This must be specified when the sending instruction is specific for a single message type (Eg: a mortgage payment reminder letter)
	sendingFrequency?: string; // The periodicity for sending account statements. It is linked to the transmission medium
	sendingInstructionSequenceNumber?: string; // The sequence number of the sending instruction
	sendingMode?: string; // The code to identify the sending mode that is used to send the letter to the third party (automatic franking, internal delivery, etc.).The message system will be divided into various packets, each corresponding to a sending mode. This information only concerns ‘paper’ addresses
	sendingType?: string; // The code to identify the type of delivery. The type indicates whether the addressee is the principal addressee or whether he receives a copy
	sendingUser?: string; // If the field is filled, the delivery of the mail to the final address is performed through a sending user. This information only concerns ‘paper’ addresses (3 characters)
	settlementDate?: string; // The date on which the account was liquidated (format: YYYYMMDD)
	signatureCheck?: string; // When this switch is true, it indicates that must check the client signature for any movement on this account. Authorisation suspension will systematically occur for each operation (format: Y/N)
	standingOrderSwitch?: string; // When this switch is true, it indicates that there are standing orders on the account (format: Y/N)
	startDate?: string; // The date on which the contract starts (format: YYYYMMDD)
	statementGenerationDay?: string; // The day on which the account statements will be generated. These account statements will be sent on the following day. This variable is not taken into account in the management of account statements on request unless the sending period is reached or exceeded, in which case account statements will be generated automatically. This variable must be specified if the sending frequency of account statements is weekly, monthly, quarterly, half-yearly or annual (possible values: 01-07)
	statementGenerationMonth?: string; // The month during which account statements are to be generated. This variable is not taken into account in the management of account statements on request unless the sending period is reached or exceeded, in which case account statements will be generated automatically. This variable must be specified if the sending frequency of account statements is quarterly, half-yearly or annual (possible values: 01-12)
	statementGenerationSwitch?: string; // When this switch is true, it indicates that account statements will be generated for this account (format: Y/N). When creating an account, this switch is automatically set (to SWIT02 OF ZZ200)
	switchOnRequest?: string; // When this switch is true, it indicates that account statements will be generated by batch processing. If this switch is false, account statements will be generated according to sending orders (depending on the sending frequency and on sending instructions) and on the batch processing. In the specific case where account statements are generated on request but the sending period is reached or exceeded, the account statements will be generated automatically via the classical chain (based on sending orders) (format: Y/N)
	transmissionMedium?: string; // The code to identify the transmission medium on which the account statements (paper, e-mail, etc.) are wanted (an argument of parameter table 083)
	usTaxResidenceCode?: string; // The code used to identify the tax residence of the client
	valuationCode?: string; // The interest rate calculation applied to the account
	valueDateCondition?: string; // The code that indicates the value date that must be applied for the account
}
