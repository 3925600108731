import { delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PasswordProcessService } from '@mdib/signature-modes';
import { Signable, Signature } from '@mdib/signature';
import { ServiceResponse } from '@mdib/utils';

@Injectable({
	providedIn: 'root'
})
export class PasswordProcessMemoryService implements PasswordProcessService {

	public prepareSignatureFor(object: Signable): Observable<ServiceResponse<Signature>> {
		return of(new ServiceResponse(<Signature>{})).pipe(delay(250));
	}

	public createSignatureFor(object: Signable, password: string): Observable<ServiceResponse<Signature>> {
		return of(new ServiceResponse(<Signature>{})).pipe(delay(250));
	}

}
