import { PaymentOperation } from '@mdib/payments';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { InstantPaymentsService } from './instant-payments.service';
import { SignableOperation } from '@mdib/signature';

export class InstantPaymentsServiceStub extends InstantPaymentsService {

	confirm(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null> | null> {
		return of();
	}

	sign(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>> {
		return of();
	}

	validate(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>> {
		return of();
	}

	getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<null> | null>> {
		return of();
	}

	get(reference: string): Observable<ServiceResponse<PaymentOperation<null> | null >> {
		return of();
	}

	delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {
		return of();
	}

	confirmDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {
		return of();
	}

	signDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {
		return of();
	}

}
