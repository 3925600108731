import { Injectable } from '@angular/core';
import { SignableOperation } from '@mdib/signature';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { PaymentFilesFilter } from '../model/payment-files-filter';
import { PaymentFile } from '../model/payment-file';
import { PaymentFileFailure } from '../model/payment-file-failure';

@Injectable()
export abstract class PaymentFilesService {

	/**
	 * Retrieves the list of payment files.
	 * @param {number} To start from (by default 0, i.e. from the beginning)
	 * @param {number} Maximum number of operations to retrieve (by default 0, i.e. no limit)
	 * @param {PaymentFilesFilter} Object containing properties on which the result must be filtered
	 * @returns {Observable<ServiceResponse<PaymentFile[]>>}
	 */
	public abstract list(index: number, count: number, filter?: PaymentFilesFilter): Observable<ServiceResponse<PaymentFile[]>>;

	/**
	 * Total counts of payment files.
	 * @param {PaymentFilesFilter} Object containing properties on which the result must be filtered
	 * @returns {Observable<ServiceResponse<number>>}
	 */
	public abstract count(filter?: PaymentFilesFilter): Observable<ServiceResponse<number>>;

	/**
	 * Initialize a payment file, the backend provides a reference and a hash value.
	 * @param {PaymentFile} paymentFile
	 * @returns {Observable<ServiceResponse<PaymentFile>>}
	 */
	public abstract validate(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>>;

	/**
	 * Confirm and create a payment file.
	 * @param {PaymentFile} paymentFile
	 * @returns {Observable<ServiceResponse<PaymentFile>>}
	 */
	public abstract confirm(paymentFile?: PaymentFile): Observable<ServiceResponse<PaymentFile>>;

	/**
	 * Get a payment file from its file identifier.
	 * @param fileId
	 * @returns {Observable<ServiceResponse<PaymentFile>>}
	 */
	public abstract get(fileId: string): Observable<ServiceResponse<PaymentFile>>;

	/**
	 * Process a controlled payment file from its file identifier.
	 * @param {string} fileId
	 * @returns {Observable<ServiceResponse<PaymentFile>>}
	 */
	public abstract process(fileId: string): Observable<ServiceResponse<SignableOperation<PaymentFile>>>;

	/**
	 * Confirm the process of a controlled payment file and put it in the shopping basket.
	 * @param {PaymentFile} paymentFile
	 * @returns {Observable<ServiceResponse<PaymentFile>>}
	 */
	public abstract confirmProcess(paymentFile?: PaymentFile): Observable<ServiceResponse<PaymentFile>>;

	/**
	 * Sign the process of a controlled payment file.
	 * @param {PaymentFile} paymentFile
	 * @returns {Observable<ServiceResponse<PaymentFile>>}
	 */
	public abstract signProcess(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>>;

	/**
	 * Delete a payment file
	 * @param {PaymentFile} paymentFile
	 * @returns {Observable<ServiceResponse<PaymentFile>>}
	 */
	public abstract delete(fileId: string): Observable<ServiceResponse<SignableOperation<PaymentFile>>>;

	/**
	 * Sign the deletion of a payment file.
	 * @param {PaymentFile} paymentFile
	 * @returns {Observable<ServiceResponse<PaymentFile>>}
	 */
	public abstract signDelete(paymentFile: PaymentFile): Observable<ServiceResponse<PaymentFile>>;

	/**
	 * Retrieve the list of failures of a payment file.
	 * @param {number} To start from (by default 0, i.e. from the beginning)
	 * @param {number} Maximum number of failures to retrieve (by default 0, i.e. no limit)
	 * @param {string} sequenceNumber
	 * @returns {Observable<ServiceResponse<PaymentFileFailure[]>>}
	 */
	public abstract listFailures(sequenceNumber: string, pageNumber?: number, pageSize?: number): Observable<ServiceResponse<PaymentFileFailure[]>>;

}
