import { Observable, of, throwError } from 'rxjs';

import { BackendOperation, BackendOperationStep, BackendOperationType, BackendOperationState } from '@mdib/core/adapters';
import { SignatureModes } from '@mdib/signature-modes';

export class UpdateOperationMemory<T> extends BackendOperation<Partial<T>, T> {

	private defaultTrigger = 'validate';

	constructor(
		protected datas: Array<T> = [],
		protected indexKey: string = null,
	) {
		super(BackendOperationType.UPDATE);
		this.curentStep = new BackendOperationStep(null, ['validate']);
	}

	public execute(input: T, trigger?: string): Observable<BackendOperationStep<T>> {
		switch (trigger || this.defaultTrigger) {
			case 'cancel':
				return this.cancel(input, trigger || this.defaultTrigger);
			case 'validate':
				return this.validate(input, trigger || this.defaultTrigger);
			case 'confirm':
			case 'save':
			case 'sign':
				return this.done(input, trigger || this.defaultTrigger);
			default:
				return this.error(new Error('The trigger ' + trigger + ' does not exist'));
		}
	}

	protected error(error: Error): Observable<BackendOperationStep<T>> {
		this.state = BackendOperationState.FAILED;
		this.stepSubject.error(error);
		return throwError(error);
	}

	protected cancel(input: T, trigger?: string): Observable<BackendOperationStep<T>> {
		this.state = BackendOperationState.CANCELLED;
		this.defaultTrigger = null;
		this.curentStep = new BackendOperationStep<T>(trigger, []);
		this.stepSubject.next(this.curentStep);
		return of(this.curentStep);
	}

	protected validate(input: T, trigger?: string): Observable<BackendOperationStep<T>> {
		this.allowedSignatures = [SignatureModes.PASSWORD];
		this.state = BackendOperationState.WAITING_ACTION;
		this.defaultTrigger = 'sign';
		this.curentStep = new BackendOperationStep(trigger, ['cancel', 'sign', 'save'], input);
		this.stepSubject.next(this.curentStep);
		return of(this.curentStep);
	}

	protected done(input: T, trigger?: string): Observable<BackendOperationStep<T>> {
		// Update the datas
		if (this.indexKey) {
			const itemIndex = this.datas.findIndex(d => d[this.indexKey] === input[this.indexKey]);
			if (itemIndex >= 0) {
				this.datas[itemIndex] = input;
			} else {
				return this.error(new Error('The item does not exist'));
			}
		}

		// Operation done
		this.state = BackendOperationState.SUCCEEDED;
		this.defaultTrigger = '';
		this.curentStep = new BackendOperationStep(trigger, [], input);
		this.stepSubject.next(this.curentStep);
		return of(this.curentStep);
	}
}
