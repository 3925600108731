import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { AuthenticationDataService } from './authentication-data.service';
import { AuthenticationDataServiceStub } from './authentication-data.service.stub';

@Injectable()
export class AuthenticationDataServiceProxy extends AdapterProxy {
	protected ServiceToken() { return AuthenticationDataService; }
	protected ServiceDefinition() { return AuthenticationDataServiceStub; }
}
