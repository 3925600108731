import { registerLocaleData } from '@angular/common';
import localeNLNL from '@angular/common/locales/nl';

import { CountryCodes, LanguageCodes, ManagedCurrencyCodes } from './config.enum';

registerLocaleData(localeNLNL);

/**
 * @deprecated Use the ConfigurationService
 */
export class BankConfig {

	static readonly baseCountryCode: CountryCodes = CountryCodes.NL;

	static readonly baseLocalisation = 'nl';
	static readonly bbanLenght: number = 18;

	static readonly baseLocale = 'nl-NL';

	static readonly baseDateFormat = 'DD/MM/YYYY';

	static readonly decimals = 2;

	static readonly baseCurrencyCode = ManagedCurrencyCodes.EUR;

	static readonly nameMaxLength = 70;

	static readonly phoneNumberPlaceholder: string = null;

	static readonly robotLoanAdvisorURL = 'https://rlo.eu-gb.mybluemix.net/?skip_intro&lang=en-us';

	static readonly ibanMinLength: number = 15;

	static readonly ibanMaxLength: number = 34;

	static readonly freeCommunicationMaxLength = 140;

	static readonly emailMaxLength = 40;

	static readonly accountNameMaxLength = 20;

	static readonly beneficiaryBicCodePlaceholder = 'BBRUBEBB';

	static readonly beneficiaryStreetNamePlaceholder = 'Voer straat in';

	static readonly beneficiaryStreetNumberPlaceholder = 'Vb. 1';

	static readonly beneficiaryPostalCodePlaceholder = 'Voer postcode en plaats in';

	static readonly beneficiaryCityPlaceholder = 'Brussels';

	static readonly beneficiaryCountryPlaceholder = 'Selecteer';

	static readonly withdrawalSimulationDelay = 3;

	static readonly languageCodes = [
		LanguageCodes.en, LanguageCodes.fr, LanguageCodes.nl, LanguageCodes.de,
	];

	static readonly translationsPath = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/translations/';

	// Cache config

	static readonly isCacheActivated = true;

	static readonly cacheDefaultExpiryTime = 600; // In seconds

}



