import { Injectable, Inject, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigurationStore } from './configuration-store';
import { CONFIGURATION_DEFAULTS } from '../model/tokens/configuration-tokens';
import { ConfigurationProperty } from '../model/configuration-property';

@Injectable({
	providedIn: 'root'
})
export class ConfigurationService {

	constructor(
		private store: ConfigurationStore,
		@Inject(CONFIGURATION_DEFAULTS) @Optional() defaultProperties: [string, ConfigurationProperty[]][],
	) {
		(defaultProperties || []).forEach(properties => {
			const defaults = properties[1].reduce((o, n) => {
				const path = ((properties[0] ? properties[0] + '.' : '') + n.name).split('.');
				const key = path.pop();
				let target = o;
				path.forEach(k => target = target[k] || (target[k] = {}));
				target[key] = n.default;
				return o;
			}, {});
			this.setDefaults(defaults);
		});
	}

	public instant(key: string) {
		return this.store.instant(key);
	}

	public set(key: string, value: any): Observable<any> {
		return this.store.set(key, value);
	}

	public setDefaults(properties: any) {
		this.store.setDefaults(properties);
	}
}
