import { ModuleWithProviders, NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { FileMapperXclService } from '@mdib-xcl/utils';
import { AccountStatementsService } from '@mdib/account-statements';

import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';

import { AccountStatementsGenerationFileXclService } from './service/account-statements-generation-file-xcl.service';
import { AccountStatementsMapperXclService } from './service/account-statements-mapper-xcl.service';
import { AccountStatementsXclService } from './service/account-statements-xcl.service';

@NgModule()
export class AccountStatementsXclModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(AccountStatementsService, adapterId, AccountStatementsXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AccountStatementsXclModule,
			providers: [
				AccountStatementsMapperXclService,
				AccountStatementsGenerationFileXclService,
				FileMapperXclService
			]
		};
	}
}
