import { Injector } from '@angular/core';
import { Subscription, Observable, Subject, of, throwError, zip } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { BackendOperation, BackendOperationType, BackendOperationStep, BackendOperationState } from '@mdib/core/adapters';
import { ParameterModel } from '@mdib/http';
import { Feedback } from '@mdib/utils';
import { SessionUtilsService } from '@mdib/sessions';

import { XclHttpService, FunctionalFeedbacksFromXclOrderExtractor, AbstractOrderXCLModel } from '@mdib-xcl/http';

import { XclSignatureContext } from '../../signatures/types/xcl-signature-context';
import { XclSignatureModes } from '../../signatures/types/xcl-signature-modes';
import { XclCallConfiguration } from './xcl-call-configuration';
import { DecisionTree } from './decision-tree';

export class BackendOperationXcl<IN, OUT, X> extends BackendOperation<IN, OUT> {

	// XCL properties
	protected xclState = '____';
	protected xclReference: string;
	protected xclEndpoint: string;

	// Dependencies
	protected backendXclService: XclHttpService;
	protected feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor;
	protected sessionUtilsService: SessionUtilsService;

	// Internal state
	protected onGoingRequests: Subscription[] = [];
	protected decision: DecisionTree = {};

	constructor(injector: Injector) {
		super(BackendOperationType.CREATE);

		// Fetch dependencies
		this.backendXclService = injector.get(XclHttpService);
		this.feedbackExtractor = injector.get(FunctionalFeedbacksFromXclOrderExtractor);
		this.sessionUtilsService = injector.get(SessionUtilsService);
	}

	public execute(input: IN, trigger?: string): Observable<BackendOperationStep<OUT>> {
		// Ensure the operation has a valid state
		switch (this.state) {
			// Can execute
			case BackendOperationState.BUSY:
			case BackendOperationState.WAITING_DATA:
			case BackendOperationState.WAITING_ACTION:
				break;
			// Cannot execute
			default:
				const error = this.handleError(new Error('The operation is not executable'));
				this.stepSubject.error(error);
				return error;
		}

		// Decision
		const machineState = this.decision[this.xclState];
		const func = machineState && machineState.triggers[trigger || machineState.defaultTrigger];
		if (!func) {
			const error = this.handleError(new Error('The trigger ' + trigger + ' does not exist for state ' + this.xclState));
			this.stepSubject.error(error);
			return error;
		} else {
			const call = func.call(this, trigger, input);
			const newSubscription =
				// Ignore completion and manage subscriptions
				call.subscribe(
					(r) => { this.stepSubject.next(r); },
					(e) => { this.stepSubject.error(e); this.onGoingRequests = this.onGoingRequests.filter(s => s !== newSubscription); },
					() => { this.onGoingRequests = this.onGoingRequests.filter(s => s !== newSubscription); }
				);
			this.onGoingRequests.push(newSubscription);
			return call;
		}
	}

	protected handleResponse(order: AbstractOrderXCLModel): Observable<OUT> {
		// Signature
		this.signatureContext = new XclSignatureContext({ signableReference: order.reference });
		this.allowedSignatures = order.signatureTypesAllowed.map(type => XclSignatureModes.fromXclType(type));
		// By default, nothing is returned
		return of(null);
	}

	protected handleFeedbacks(order: AbstractOrderXCLModel): Observable<Feedback[]> {
		return of(this.feedbackExtractor.extract(order));
	}

	protected handleError(error: any): Observable<BackendOperationStep<OUT>> {
		return throwError(error);
	}

	protected handleTriggers(order: AbstractOrderXCLModel): Observable<string[]> {
		const machineState = this.decision[this.xclState];
		const triggers = Object.keys((machineState && machineState.triggers) || {});
		return of(triggers.filter(trigger => {
			switch (trigger) {
				case 'save': return !order.orderNature.isSignableAlone;
				case 'sign': return order.orderNature.isSignatureRequired;
				default: return true;
			}
		}));
	}

	protected calls(trigger: string, callConfig: Array<XclCallConfiguration>): Observable<BackendOperationStep<OUT>> {
		const sub = new Subject<BackendOperationStep<OUT>>();
		let call = of(null);
		callConfig.forEach(config => {
			call = call.pipe(mergeMap((curentStep: BackendOperationStep<OUT>) => {
				// Parameters (action and reference)
				const parameters: ParameterModel[] = [];
				if (config.resourceId) { parameters.push(new ParameterModel('resourceId', config.resourceId)); }
				if (config.action) { parameters.push(new ParameterModel('restmethod', config.action)); }
				if (config.useReference) { parameters.push(new ParameterModel('reference', this.xclReference)); }

				// Execute one call
				this.state = BackendOperationState.BUSY;
				const subcall = this.backendXclService
					.execute(this.xclEndpoint, config.method, parameters, config.body)
					.pipe(
						mergeMap(order => {
							this.xclReference = order.reference;
							this.xclState = order.state;
							return zip(this.handleResponse(order), this.handleFeedbacks(order), this.handleTriggers(order));
						}),
						map(response => {
							// Construct the step
							const curentFeedbacks = (curentStep && curentStep.feedbacks) || [];
							const step = new BackendOperationStep(trigger, response[2], response[0], response[1].concat(curentFeedbacks));
							this.curentStep = step;
							this.state = config.state;
							return step;
						}),
						catchError(error => {
							this.state = BackendOperationState.FAILED;
							return this.handleError(error);
						})
					);
				return subcall;
			}));
		});
		call.pipe(catchError(error => {
			this.state = BackendOperationState.FAILED;
			sub.error(error);
			return this.handleError(error);
		}));
		call.subscribe(sub);
		return sub.asObservable();
	}

	protected cancel(trigger: string, input?: IN): Observable<BackendOperationStep<TextEvent>> {
		// Update curent state
		this.state = BackendOperationState.CANCELLED;
		// Cancel all requests
		this.onGoingRequests.forEach(s => s.unsubscribe());
		this.onGoingRequests = [];
		this.stepSubject.complete();
		// No next step
		return of(new BackendOperationStep(trigger));
	}
}
