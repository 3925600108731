import { Injectable } from '@angular/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';
import { XclSignatureContext, XclSignatureModes } from '@mdib-xcl/core';
import { OperationStatusHelper } from '@mdib-xcl/payments/helper/operation-status-helper';
import { mapToXclCommunication, OrderStateHelper, XclDateFormatter, convertBeneficiaryAddressFromXclToStreet, XclAPI } from '@mdib-xcl/utils';
import { Beneficiary, BeneficiaryType } from '@mdib/beneficiaries';
import { Communication, CommunicationTypes, Address } from '@mdib/commons';
import { BankConfig } from '@mdib/config';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { PaymentTypes, PaymentOperation } from '@mdib/payments';
import { SignableOperation } from '@mdib/signature';
import { TranslateService } from '@ngx-translate/core';
import { SEPAPaymentOperationXCLModel } from '../model/xcl-sepa-payment-operation';
import { CashAccount, CashAccountService } from '@mdib/cash-accounts';
import { ServiceResponse, UtilsHelper } from '@mdib/utils';

@Injectable()
export class PaymentsOperationMapperXclService {

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.';

	private userCashAccounts: CashAccount[];

	constructor(private translateService: TranslateService,
				private cashAccountService: CashAccountService,
				private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService) {
	}

	public toSEPAPaymentOperationGoalXCLModel(payment: PaymentOperation<null>): SEPAPaymentOperationXCLModel {

		let xclModel = <SEPAPaymentOperationXCLModel>{
			// Mandatory fields
			counterpartyAccountNumber: payment.counterParty.account.number,
			operationAmount: payment.amount,
			ordererAccountNumber: payment.ordererAccount.number,
			participantName: payment.ordererAccount.clientFullName,
			participantName_2: payment.counterParty.type === BeneficiaryType.INTERNAL ? payment.counterParty.account.clientFullName : payment.counterParty.fullName,
			operationCurrency: BankConfig.baseCurrencyCode,
			paymentType: '01',
			paymentScheme: '10',

			paymentChannelType: '02', // desktop = 02, tablet = 51, smartphone = 50 --> as we are responsive, use 02 by default.
		};

		xclModel.counterpartyParticipantName = xclModel.participantName_2;

		xclModel.operationReference = payment.reference;

		if (!!payment.counterParty && payment.counterParty.type !== BeneficiaryType.INTERNAL) {
			xclModel.participantBicIdentification = payment.counterParty.account.bic;
		}

		if (!!payment.paymentDate) {
			xclModel.maturityDate = XclDateFormatter.convertDateToXCLDateFormat(payment.paymentDate);
		}

		if (!!payment.counterParty && !!payment.counterParty.address) {
			const address = payment.counterParty.address;
			xclModel.addressPart1_1 = address.addressLine1;
			xclModel.addressPart2_1 = address.addressLine2;
			xclModel.countryCode_1 = !payment.counterParty.address.country ? '' : payment.counterParty.address.country;
		}

		if (payment.saveBeneficiary) {
			xclModel.saveBeneficiary = true;
			xclModel.beneficiaryIdentificationForBeneficiary = payment.counterParty.alias;
		}

		xclModel = mapToXclCommunication<SEPAPaymentOperationXCLModel>(payment, xclModel);

		return xclModel;
	}

	public feedFromSEPAPaymentOperationOrderXCLModel(payment: PaymentOperation<null>, sepaPaymentOrderXCLModel: MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>, phase: string): PaymentOperation<null> {

		const sepaPaymentXCLModel: SEPAPaymentOperationXCLModel = sepaPaymentOrderXCLModel.goal;

		payment.reference = sepaPaymentXCLModel.operationReference;

		if (UtilsHelper.nullOrUndefined(payment.ordererAccount)) {
			payment.ordererAccount = new CashAccount({
				clientWording: sepaPaymentXCLModel.participantName,
				number: sepaPaymentXCLModel.ordererAccountNumber,
			});
		}

		payment.amount = sepaPaymentXCLModel.operationAmount;
		payment.type = PaymentTypes.sepaCreditTransfer;
		payment.currency = sepaPaymentXCLModel.operationCurrency;
		payment.paymentDate = !sepaPaymentXCLModel.maturityDate ? null : XclDateFormatter.xclStringToDate(sepaPaymentXCLModel.maturityDate);
		payment.operationDate = !sepaPaymentXCLModel.operationDate ? null : XclDateFormatter.xclStringToDate(sepaPaymentXCLModel.operationDate);
		payment.saveBeneficiary = sepaPaymentXCLModel.saveBeneficiary;

		if (phase === XclAPI.retrieve) {

			this.cashAccountService.list().subscribe(
				(response: ServiceResponse<CashAccount[]>) => {
					this.userCashAccounts = response.getModel();
				},
				(serviceResponseError: ServiceResponse<null>) => {
					this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
				},
				() => {

					// Check if the counterparty account is an account of the connected user
					const beneficiaryCashAccount = this.userCashAccounts.find((cashAccount: CashAccount) => cashAccount.number === sepaPaymentXCLModel.counterpartyAccountNumber);
					const beneficiaryType = UtilsHelper.nullOrUndefined(beneficiaryCashAccount) ? BeneficiaryType.SEPA : BeneficiaryType.INTERNAL;

					payment.counterParty = new Beneficiary({
						account: new CashAccount({
							number: sepaPaymentXCLModel.counterpartyAccountNumber,
							bic: sepaPaymentXCLModel.participantBicIdentification_1,
						}),
						address: new Address(),
						fullName: sepaPaymentXCLModel.participantName_2,
						alias: sepaPaymentXCLModel.beneficiaryIdentificationForBeneficiary,
						type: beneficiaryType,
					});

					if (!!sepaPaymentXCLModel.countryCode_1) {
						payment.counterParty.address.country = sepaPaymentXCLModel.countryCode_1;
					}

					if (!!sepaPaymentXCLModel.addressPart1_1) {
						payment.counterParty.address.addressLine1 = sepaPaymentXCLModel.addressPart1_1;
					}
					if (!!sepaPaymentXCLModel.addressPart2_1) {
						payment.counterParty.address.addressLine2 = sepaPaymentXCLModel.addressPart2_1;
					}

					payment.counterParty.communication = new Communication();
					if (!!sepaPaymentXCLModel.rfStructuredCommunication) {
						payment.counterParty.communication.type = CommunicationTypes.european;
						payment.counterParty.communication.value = sepaPaymentXCLModel.rfStructuredCommunication;
					} else {
						payment.counterParty.communication.type = CommunicationTypes.free;
						payment.counterParty.communication.value = !sepaPaymentXCLModel.freeCommunication ? '' : sepaPaymentXCLModel.freeCommunication;
					}

				});

		}

		payment.signatureTypesAllowed = sepaPaymentOrderXCLModel.signatureTypesAllowed.map(type => XclSignatureModes.fromXclType(type));
		payment.signatureContext = new XclSignatureContext({signableReference: sepaPaymentOrderXCLModel.reference});
		payment.status = OrderStateHelper.getStatusFromXCLOrderStateCode(sepaPaymentOrderXCLModel.state);
		payment.operationStatus = OperationStatusHelper.paymentStatusFromXcl(sepaPaymentOrderXCLModel.goal.operationStatus);

		return payment;
	}

	// TODO - This method will be removed when refactoring with SignableOperation wrapper is done
	feedFromSEPAPaymentOperationOrderXCLModelToSignableOperation(signablePaymentOperation: SignableOperation<PaymentOperation<null>>, sepaPaymentOrderXCLModel: MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>): SignableOperation<PaymentOperation<null>> {

		const sepaPaymentXCLModel: SEPAPaymentOperationXCLModel = sepaPaymentOrderXCLModel.goal;
		signablePaymentOperation.reference = sepaPaymentOrderXCLModel.reference;
		signablePaymentOperation.model.type = PaymentTypes.sepaCreditTransfer;
		signablePaymentOperation.model.reference = sepaPaymentXCLModel.operationReference;
		signablePaymentOperation.model.status = OrderStateHelper.getStatusFromXCLOrderStateCode(sepaPaymentOrderXCLModel.state);
		signablePaymentOperation.model.signatureTypesAllowed = sepaPaymentOrderXCLModel.signatureTypesAllowed.map(type => XclSignatureModes.fromXclType(type));

		signablePaymentOperation.model.ordererAccount = new CashAccount();
		signablePaymentOperation.model.ordererAccount.clientWording = sepaPaymentXCLModel.participantName;
		signablePaymentOperation.model.amount = sepaPaymentXCLModel.operationAmount;
		signablePaymentOperation.model.currency = sepaPaymentXCLModel.operationCurrency;
		signablePaymentOperation.model.paymentDate = !sepaPaymentXCLModel.maturityDate ? null : XclDateFormatter.xclStringToDate(sepaPaymentXCLModel.maturityDate);
		signablePaymentOperation.model.operationDate = !sepaPaymentXCLModel.operationDate ? null : XclDateFormatter.xclStringToDate(sepaPaymentXCLModel.operationDate);
		signablePaymentOperation.model.counterParty = new Beneficiary();
		signablePaymentOperation.model.counterParty.fullName = sepaPaymentXCLModel.participantName_2;
		signablePaymentOperation.model.counterParty.account = new CashAccount();
		signablePaymentOperation.model.counterParty.account.bic = sepaPaymentXCLModel.participantBicIdentification;
		signablePaymentOperation.model.counterParty.address = new Address();
		signablePaymentOperation.model.counterParty.address.addressLine1 = sepaPaymentXCLModel.addressPart1_1;
		signablePaymentOperation.model.counterParty.address.addressLine2 = sepaPaymentXCLModel.addressPart2_1;
		signablePaymentOperation.model.saveBeneficiary = sepaPaymentXCLModel.saveBeneficiary;
		signablePaymentOperation.model.counterParty.alias = sepaPaymentXCLModel.saveBeneficiary ? sepaPaymentXCLModel.beneficiaryIdentificationForBeneficiary : null;

		if (!!sepaPaymentXCLModel.countryCode_1) {
			this.translateService.get('countries.' + sepaPaymentXCLModel.countryCode_1).subscribe(
				translation => {
					signablePaymentOperation.model.counterParty.address.country = translation;
				},
				e => {
					signablePaymentOperation.model.counterParty.address.country = sepaPaymentXCLModel.countryCode_1;
				});
		}
		signablePaymentOperation.model.counterParty.communication = new Communication();
		if (!!sepaPaymentXCLModel.rfStructuredCommunication) {
			signablePaymentOperation.model.counterParty.communication.type = CommunicationTypes.european;
			signablePaymentOperation.model.counterParty.communication.value = sepaPaymentXCLModel.rfStructuredCommunication;
		} else {
			signablePaymentOperation.model.counterParty.communication.type = CommunicationTypes.free;
			signablePaymentOperation.model.counterParty.communication.value = !sepaPaymentXCLModel.freeCommunication ? '' : sepaPaymentXCLModel.freeCommunication;
		}
		return signablePaymentOperation;
	}
}
