import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { ConfigService } from '@mdib/config/service/config.service';
import { GenericListPage } from '@mdib/generic';
import { PendingPaymentsPageFilter, OperationStatus, PaymentTypes, PendingPaymentFrequency, PaymentsService } from '@mdib/payments';
import { ServiceResponse } from '@mdib/utils';
import { IconButton } from '@mdib/styling';
import { CashAccount } from '@mdib/cash-accounts';
import { isNullOrUndefined } from 'util';

@Component({
	selector: 'pending-payments-page',
	templateUrl: './pending-payments-page.component.html',
	styleUrls: ['./pending-payments-page.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
			state('expanded', style({ height: '*', visibility: 'visible' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})

export class PendingPaymentsPageComponent extends GenericListPage implements OnInit {

	readonly NAMESPACE = 'paymentsPagesModule.pendingPaymentsPageComponent.';
	readonly operationStatus = OperationStatus;
	readonly paymentTypes = PaymentTypes;
	readonly frequency = PendingPaymentFrequency;

	filter = <PendingPaymentsPageFilter>{
		ordererAccountNumber: null,
		counterpartyAccountNumber: '',
		counterpartyName: '',
		maturityDateFrom: null,
		maturityDateTo: null,
		amountFrom: null,
		amountTo: null,
		currency: this.configService.baseCurrencyCode,
	};

	public displayedColumns = ['expander', 'date', 'counterparty', 'paymentType', 'status', 'frequency', 'amount', 'actions'];
	public expandedDetailColumns = ['expandedColumn'];
	protected day = 0;
	protected month = '';

	protected iconArray: Array<IconButton> = [
		{ iconName: 'delete', iconLabel: 'delete', link: '/payments/delete/pending', token: 'payments.delete' },
		{ iconName: 'visibility', iconLabel: 'consult', link: '/payments/retrieve/pending', token: 'payments.consult' }
	];

	constructor(private paymentsService: PaymentsService,
		private configService: ConfigService,
		private activatedRoute: ActivatedRoute,
		injector: Injector,
	) {
		super(injector);
		this.listConfigurations.listTitle = this.NAMESPACE + 'pages:titles:pendingPayments';
		this.listConfigurations.listEmptyMessage = this.NAMESPACE + 'listEmptyMessage';
		this.listConfigurations.selectorTitle = this.NAMESPACE + 'accountLabel';
	}

	ngOnInit() {
		this.clearFilter();
		// Load filter from URL
		this.activatedRoute.queryParams.subscribe(params => {
			this.loadSearchModel(params, this.filter);
			if (!isNullOrUndefined(this.filter.ordererAccountNumber)) {
				this.fetchData();
			}
		});
	}

	consultRow(row: any): void {
	}

	clearFilter(): void {
		this.filter = {
			ordererAccountNumber: null,
			counterpartyAccountNumber: '',
			counterpartyName: '',
			maturityDateFrom: null,
			maturityDateTo: null,
			amountFrom: null,
			amountTo: null,
			currency: this.configService.baseCurrencyCode,
		};
	}

	isDeletable(status: OperationStatus): boolean {
		switch (status) {
			case this.operationStatus.awaitingProcessing:
			case this.operationStatus.memoOrder:
				return true;
			case this.operationStatus.transferValidated: // orderValidation: TODO
				return true;
			default:
				return false;
		}
	}

	remove(row: any) {
		// TODO STORY B2C_BLM-1153
		console.log('delete : ' + row.reference);
	}

	onCashAccountChanged(cashAccount: CashAccount) {
		this.filter.ordererAccountNumber = (cashAccount != null) ? cashAccount.number : null;
		this.applyFilter();
	}

	onClearFilter() {
		this.clearFilter();
		this.applyFilter();
	}

	protected load(index?: number, count?: number): Observable<ServiceResponse<any>> {
		return this.paymentsService.list(index, count, this.filter);
	}
}
