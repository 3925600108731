import { UtilsHelper } from '@mdib/utils';

export namespace PaymentHelpers {
	/**
	 * This helpers aims to switch from free communication to Belgian structured if it's one or only a part
	 * @param {string} communication: to check
	 * @return {boolean}: if communication is part or an entire Belgian structured one
	 * @function switchToStructured
	 * @memberof PaymentHelpers
	 */
	export function switchToStructured(communication: string): boolean {
		const structuredCommunicationFirstDigits = /^((\+{3}\d)+(\d{2}\/\d{4}\/\d{5})?|(\+{3})?(\d{3}\/?\d{4}\/?\d{5})+)(\+{3})?$/i;
		return PaymentHelpers.isStructured(communication) || structuredCommunicationFirstDigits.test(communication);
	}

	export function isStructured(communication: string) {
		if (!!communication && communication.length === 12) {
			const referenceNumber = parseInt(communication.substr(0, 10), 10);
			const checksumDigits = parseInt(communication.substr(10, 2), 10);
			const validation = ((referenceNumber % 97) === checksumDigits);
			return validation;
		} else {
			return false;
		}
	}
}
