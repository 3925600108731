import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ibanFormatter'
})
export class IbanFormatterPipe implements PipeTransform {

	transform(ibanInternal: string): string {

		// TEMPORARY - Checking whether the account number is in BBAN, and convert it to IBAN format if it's the case
		// This is temporary, as the XCL should (at the end) return only IBAN formats
		if (!isNaN(parseInt(ibanInternal, 10)) && !!ibanInternal && ibanInternal.length === 12) {
			ibanInternal = this.convertBbanToIban(ibanInternal);
		}

		/*
		 This logic transforms the account number from IBAN internal to IBAN external format
		 i.e. adds spaces after every four character and the last block may be of variable length.
		 */
		ibanInternal = !!ibanInternal ? ibanInternal.replace(/ /g, '') : null;
		let ibanExternal = ibanInternal;
		let spacesAdded = 0;

		for (let i = 4; i < (!!ibanInternal ? ibanInternal.length : 0); i = i + 4) {

			ibanExternal = ibanExternal.substring(0, i + spacesAdded) + ' ' + ibanInternal.substring(i, ibanInternal.length);
			spacesAdded++;

		}

		return ibanExternal;
	}

	// TEMPORARY -This function converts a BBAN account number to its corresponding IBAN number
	convertBbanToIban(ibanInternal: string): string {
		let cdIban = (98 - (parseInt(ibanInternal.substring(10, 12) + ibanInternal.substring(10, 12) + '111400', 10) % 97)).toString();

		if (parseInt(cdIban, 10) < 10) {
			cdIban = '0' + cdIban;
		}

		return ('BE' + cdIban + ibanInternal);
	}

}
