import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CommonsModule } from '@mdib/commons';
import { CashAccountsModule } from '@mdib/cash-accounts';
import { PaymentsModule } from '@mdib/payments';
import { SignatureModule } from '@mdib/signature';
import { StylingModule } from '@mdib/styling';
import { UtilsModule } from '@mdib/utils';
import { HeaderModule } from '@mdib/header';
import { FooterModule } from '@mdib/footer';
import { AsideModule } from '@mdib/aside';
import { BeneficiariesModule } from '@mdib/beneficiaries';

import { GenericPagesModule } from '@mdib-pages/generic';

import { ConfirmationStepComponent } from './component/create-payment-page/confirmation-step/confirmation-step.component';
import { TransactionStatusComponent } from './component/create-payment-page/confirmation-step/transaction-status/transaction-status.component';
import { CreatePaymentPageComponent } from './component/create-payment-page/create-payment-page.component';
import { CurrencyExchangeComponent } from './component/create-payment-page/data-gathering-step/amount-section/currency-exchange/currency-exchange.component';
import { PaymentsConsultationPageComponent } from './component/payments-consultation-page/payments-consultation-page.component';
import { PendingPaymentsPageFilterComponent } from './component/pending-payments-page/pending-payments-page-filter/pending-payments-page-filter.component';
import { PendingPaymentsPageComponent } from './component/pending-payments-page/pending-payments-page.component';
import { PaymentsRoutingModule } from './payments-routing.module';
import { PaymentsGatheringPageModule } from './payments-gathering-page.module';
import { SecurityModule } from '@mdib/core/security';

@NgModule({
	imports: [
		PaymentsRoutingModule,
		CommonModule,
		CommonsModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		UtilsModule,
		SignatureModule,
		PaymentsModule,
		CashAccountsModule,
		StylingModule,
		BeneficiariesModule,
		GenericPagesModule,
		HeaderModule,
		FooterModule,
		AsideModule,
		PaymentsGatheringPageModule,
		SecurityModule,
	],
	declarations: [
		ConfirmationStepComponent,
		TransactionStatusComponent,
		CurrencyExchangeComponent,
		PendingPaymentsPageFilterComponent,
		PendingPaymentsPageComponent,
		PaymentsConsultationPageComponent,
		CreatePaymentPageComponent,
	],
	exports: [
		ConfirmationStepComponent
	]
})
export class PaymentsPagesModule { }
