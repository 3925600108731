import { NavigationItem } from './navigation-item';

export abstract class NavigationFilter {

	/**
	 * Filter a Navigation menu item to determine if it must be shown
	 *
	 * @param navigationItems The navigation item to filter
	 * @returns true if displayed, false otherwize
	 */
	public abstract filterItem(navigationItem: NavigationItem): boolean;

}
