export namespace ChartsConfig {
	// Defines the number of months for which the chart data will be displayed
	export const lineChartMonths = 3;

	// Defines the number of weeks for which the chart data will be displayed
	export const lineChartWeeks = 3;

	// Configuration for chart
	export const lineChartTension = 0;

	export const chartFontColor = '#707070';

	export const chartFontSize = 14;

	export const chartFontFamily = 'Montserrat';

	export const chartLegendFontColor = '#333333';

	export const chartLegendFontSize = 16;

	export const chartLegendFontFamily = 'Source Sans Pro';

	export const chartDefaultColors = ['#FF1D42', '#EF7D00', '#A30121', '#7B2C9F', '#707070', '#63B1E6', '#8C7CA9', '#252325', '#FF9900', '#329262'];

	export const chartBorderSize = 1.5;
}
