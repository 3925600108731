import { Injectable } from '@angular/core';
import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { blobToB64, FileEntryXcl, FileMapperXclService } from '@mdib-xcl/utils';
import { ParameterModel } from '@mdib/http';
import { MdibFile, ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { tap, mergeMap, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class FileUploadersXclService {

	constructor(private xclHttpService: XclHttpService,
		private feedbacksExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private fileMapperXclService: FileMapperXclService,
	) { }

	/**
	 * This method upload the payment file of user in a data base and create a file id.
	 * @param {MdibFile} file
	 * @returns {Observable<ServiceResponse<MdibFile>>}
	 */
	upload(file: MdibFile): Observable<ServiceResponse<MdibFile>> {
		const body = { 'file': { 'name': file.name, 'contentType': file.document.type, 'content': '' } };

		const xclObservable = blobToB64(file.document).pipe(
			tap((data: string) => body.file.content = data),
			mergeMap(() => this.xclHttpService.execute('file-uploaders', XclHttpService.POST)),
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.xclHttpService.execute(
				'file-uploaders-retrieve', XclHttpService.PUT,
				[<ParameterModel>{ par: 'reference', val: order.reference }],
			)),
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.xclHttpService.execute(
				'file-uploaders-validate', XclHttpService.PUT,
				[<ParameterModel>{ par: 'reference', val: order.reference }],
				body,
			)),
			mergeMap((order: MonogoalOrderXCLModel<FileEntryXcl>) => this.xclHttpService.execute(
				'file-uploaders-confirm', XclHttpService.PUT,
				[<ParameterModel>{ par: 'reference', val: order.reference }],
			)),
		) as Observable<MonogoalOrderXCLModel<FileEntryXcl>>;

		return xclObservable.pipe(
			map((xclOrder: MonogoalOrderXCLModel<FileEntryXcl>) =>
				new ServiceResponse<MdibFile>(
					this.fileMapperXclService.fileXclToMdibFile(xclOrder.goal),
					this.feedbacksExtractor.extract(xclOrder),
				),
			)
		);
	}
}
