import { CommonModule } from '@angular/common';
import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { StandingOrdersXclService } from './service/standing-orders-xcl.service';
import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { AdaptersService } from '@mdib/core/adapters';
import { StandingOrdersService } from '@mdib/standing-orders';
import { StandingOrdersMapperXclService } from './service/standing-orders-mapper-xcl.service';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [],
	providers: [],
})
export class XclStandingOrdersModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(StandingOrdersService, adapterId, StandingOrdersXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: XclStandingOrdersModule,
			providers: [
				StandingOrdersMapperXclService,
			]
		};
	}
}
