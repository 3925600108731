import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DefaultNotificationBannerStateHolderService {

	private state: Subject<boolean> = new Subject();

	public getState(): Subject<boolean> {
		return this.state;
	}

	public setState(state: boolean): void {
		this.state.next(state);
	}
}
