import { Holder } from './holder';
import { AuthenticationStatisticData } from '@mdib/sessions';

export class Customer {
	public id: string;
	/**
	 * The list of {Holders} linked to the {Customer}. The main {Holder} is the first in the list.
	 */
	public holders: Array<Holder>;

	/**
	 * The list of {Authentication Statistic Data} linked to the {Customer}.
	 */
	public authenticationStatisticDataList: Array<AuthenticationStatisticData>;
}
