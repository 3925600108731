import * as moment from 'moment';
import { isNullOrUndefined } from 'util';

export namespace XclDateFormatter {

	export const XCLDATEFORMAT = 'YYYYMMDD';

	/** Converts a date from the XCL format (YYYYMMDD) to the ISO format (YYYY-MM-DD).
	 * @param {string} xclDate
	 * @returns {string}
	 */
	export function convertXCLToISODateFormat(xclDate: string): string {
		// In case of invalid input, don't transform the data
		if (isNullOrUndefined(xclDate)
			|| !/^[0-9]{8}$/.test(xclDate)
		) {
			return xclDate;
		}

		// If the input data is correct, convert the XCL date format to the ISO date format
		const year = xclDate.substring(0, 4);
		const month = xclDate.substring(4, 6);
		const day = xclDate.substring(6, 8);
		return year + '-' + month + '-' + day;
	}

	/** Converts a date from the ISO format (YYYY-MM-DD) to the XCL format (YYYYMMDD).
	 * @param {string} isoDate
	 * @returns {string}
	 */
	export function convertISOToXCLDateFormat(isoDate: string): string {
		// In case of invalid input, don't transform the data
		if (isNullOrUndefined(isoDate)
			|| !/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(isoDate)
		) {
			return isoDate;
		}

		// If the input data is correct, convert the XCL date format to the ISO date format
		const year = isoDate.substring(0, 4);
		const month = isoDate.substring(5, 7);
		const day = isoDate.substring(8, 10);
		return year + month + day;
	}

	/** Converts a {@link} object to the XCL format (YYYYMMDD).
	 * @param {Date} date
	 * @returns {string}
	 */
	export function convertDateToXCLDateFormat(date: Date): string {
		return moment(date).format(XCLDATEFORMAT);
	}

	export function xclStringToDate(date: string): Date {
		return moment(date, XCLDATEFORMAT).toDate();
	}

	export function stringToDate(date: string): Date {
		return moment(date, XCLDATEFORMAT).toDate();
	}

	export function dateToString(date: Date): string {
		return moment(date).format(XCLDATEFORMAT);
	}

	/** Converts a time from the XCL format (hhmmssnn) to the ISO format (hh:mm:ss).
	 * @param {string} xclTime
	 * @returns {string} A time in the ISO format (hh:mm:ss)
	 */
	export function convertXCLToISOTimeFormat(xclTime: string): string {
		// In case of invalid input, don't transform the data
		if (isNullOrUndefined(xclTime)
			|| !/^[0-9]{8}$/.test(xclTime)
		) {
			return xclTime;
		}

		// If the input data is correct, convert the XCL date format to the ISO date format
		const hour = xclTime.substring(0, 2);
		const minute = xclTime.substring(2, 4);
		const second = xclTime.substring(4, 6);
		return hour + ':' + minute + ':' + second;
	}

	/**
	 * Compares two dates until day level: hours and lower levels are not compared.
	 * To be used to make sure we don't have issues with summer times vs winter times.
	 * @param {Date} date1
	 * @param {Date} date2
	 * @returns {boolean}
	 */
	export function isSameDate(date1: Date, date2: Date) {
		return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDay() === date2.getDay();
	}
}
