import { ForgotPasswordModelBuilder, ForgotPasswordModel } from '@mdib/forgot-password';
import { SecretQuestion } from '@mdib/customers';

export class MockForgotPasswordModel {
	static getMockForgotPasswordModel(): ForgotPasswordModel {
		return new ForgotPasswordModelBuilder()
		.userId('MockAccess')
		.secretQuestions(<SecretQuestion[]>[ { id: '01' }, { id: '02' }, { id: '03' } ])
		.forgotPasswordModel;
	}
}
