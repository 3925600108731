import { Injectable } from '@angular/core';
import { OperationStatusHelper } from '../helper/operation-status-helper';
import { XclDateFormatter } from '@mdib-xcl/utils';
import { PendingPayment, PendingPaymentFrequency } from '@mdib/payments';
import { UtilsHelper } from '@mdib/utils';

import { XclPaymentTypes } from '../model/xcl-payments-enum';
import { XclPendingPayment } from '../model/xcl-pending-payment';

@Injectable()
export class PendingPaymentsMapperXclService {

	private readonly paymentsTypesCode = [
		{'code': 'NB01', 'value': XclPaymentTypes.nationalPayment},
		{'code': 'NT01', 'value': XclPaymentTypes.nationalPayment},
		{'code': 'PG01', 'value': XclPaymentTypes.sepaCreditTransfer},
		{'code': 'PG02', 'value': XclPaymentTypes.sepaDirectDebit},
		{'code': 'PG03', 'value': XclPaymentTypes.germanPayment},
		{'code': 'RE01', 'value': XclPaymentTypes.internationalPayment},
		{'code': 'SR01', 'value': XclPaymentTypes.nationalStandingOrder},
		{'code': 'SR02', 'value': XclPaymentTypes.internationalStandingOrder},
		{'code': 'SR03', 'value': XclPaymentTypes.europeanStandingOrder},
		{'code': 'SR04', 'value': XclPaymentTypes.moneyMoverStandingOrder},
		{'code': 'PG05', 'value': XclPaymentTypes.instantPayment},
		
	];

	getPendingPaymentArrayFromTechnicalModelArray(technicalModelArray: XclPendingPayment[]): PendingPayment[] {
		if (UtilsHelper.nullOrUndefined(technicalModelArray)) {
			console.error('Invalid list of pending payments provided by the backend: ' + JSON.stringify(technicalModelArray));
			return [];
		}
		return technicalModelArray.map((technicalModel: XclPendingPayment) => this.feedPendingPaymentFromTechnicalModel(technicalModel));
	}

	private feedPendingPaymentFromTechnicalModel(xclPendingPayment: XclPendingPayment): PendingPayment {
		const pendingPayment = new PendingPayment();

		pendingPayment.reference = this.transformReferenceIntoIdentifier(xclPendingPayment);
		pendingPayment.paymentDate = XclDateFormatter.xclStringToDate(xclPendingPayment.maturityDate);
		pendingPayment.ordererAccountNumber = xclPendingPayment.ordererAccountNumber;
		pendingPayment.ordererName = this.formatOrdererName(xclPendingPayment.ordererName);
		pendingPayment.counterpartyAccountNumber = xclPendingPayment.counterpartyAccountNumber;
		pendingPayment.counterpartyName = xclPendingPayment.counterpartyName;
		// TODO : Frequency
		pendingPayment.frequency = this.mapPendingPaymentPaymentFrequency('-');
		pendingPayment.status = OperationStatusHelper.paymentStatusFromXcl(xclPendingPayment.status);
		pendingPayment.paymentType = this.mapPendingPaymentPaymentType(xclPendingPayment);
		pendingPayment.amount = xclPendingPayment.amount;
		pendingPayment.currency = xclPendingPayment.currency;
		pendingPayment.isUrgentTransfer = xclPendingPayment.isUrgentTransfer;

		return pendingPayment;
	}

	/**
	 * Transform the reference into an identifier which can be used for the "get" service of international payment
	 * @param {XclPendingPayment} xclPendingPayment
	 * @returns {string} identifier for "get" service of international payment
	 */
	private transformReferenceIntoIdentifier(xclPendingPayment: XclPendingPayment): string {
		if (this.mapPendingPaymentPaymentType(xclPendingPayment) === XclPaymentTypes.urgentPayment || this.mapPendingPaymentPaymentType(xclPendingPayment) === XclPaymentTypes.internationalPayment) {
			return xclPendingPayment.operationReference + 'µ' + xclPendingPayment.counterpartyAccountNumber;
		}
		return xclPendingPayment.operationReference;
	}

	private mapPendingPaymentPaymentType(payment: XclPendingPayment): XclPaymentTypes {
		if (payment.isUrgentTransfer) {
			return XclPaymentTypes.urgentPayment;
		}
		const tuple = this.paymentsTypesCode.find((element: any) => element.code === payment.paymentType);
		return tuple ? tuple.value : XclPaymentTypes.unknown;
	}

	private mapPendingPaymentPaymentFrequency(frequency: string): PendingPaymentFrequency {
		switch (frequency) {
			default: {
				return PendingPaymentFrequency.Unknown;
			}
		}
	}

	private formatOrdererName(name: string): string {
		const split = name.split(' ').slice(2).toString();
		return split.replace(new RegExp(/,/, 'g'), ' ');
	}

}
