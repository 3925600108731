import { OnDestroy } from '@angular/core';

// Helper class to unsubscribe all the subscriptions passed
export class AutoUnsubscribeHelper implements OnDestroy {
	autoUnsubscribeFieldsList;
	savedNgOnDestroy;
	/**
	 * To unsubscribe all the listed subscriptions
	 */
	autoUnsubscriber(): void {
		this.autoUnsubscribeFieldsList.forEach(property => {
			if (this[property]) {
				this[property].unsubscribe();
			}
		});
	}

	ngOnDestroy() {
		this.savedNgOnDestroy();
		this.autoUnsubscriber();
	}
}
