import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthorizationsService } from '../services/authorizations.service';

@Directive({
	selector: '[mdibIfAuthorized]'
})
export class IfAuthorizedDirective {

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private authorizationService: AuthorizationsService,
	) { }

	@Input()
	public set mdibIfAuthorized(tokens: Array<string>) {
		if (this.authorizationService.authorized(tokens)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}

}
