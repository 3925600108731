import { Injectable } from '@angular/core';

@Injectable()
export class UniqueIdentifierService {

	public getHexId(length: number): string {
		let id = '';
		for (let i = 0; i < length; ++i) {
			id += Math.floor(Math.random() * 0x10).toString(16);
		}
		return id;
	}

	public getUUID(): string {
		const S4 = () => this.getHexId(4);
		return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4());
	}

}
