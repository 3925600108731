import { ModuleWithProviders, NgModule, Inject } from '@angular/core';


import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { AdaptersService } from '@mdib/core/adapters';

import { ElectronicalStatementsService } from '@mdib/electronical-statements/';
import { ElectronicalStatementsXclService } from '@mdib-xcl/electronical-statements/service/electronical-statements-xcl.service';

@NgModule()
export class ElectonicalStatementXclModule {

	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(ElectronicalStatementsService, adapterId, ElectronicalStatementsXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: ElectonicalStatementXclModule,
			providers: [
				ElectronicalStatementsXclService,
			]
		};
	}

}
