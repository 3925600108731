import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Contact, ContactsService } from '@mdib/customers';
import { SignatureModes } from '@mdib/signature-modes';
import { ServiceResponse } from '@mdib/utils';

import { contactsMock } from '../mock/contacts-mock';

@Injectable({
	providedIn: 'root'
})
export class ContactsMemoryService extends ContactsService {

	validateUpdateContact(contacts: Contact[]): Observable<ServiceResponse<Contact[]>> {
		contacts.forEach(c => {
			c.signatureTypesAllowed = [SignatureModes.PASSWORD];
		});
		return of(new ServiceResponse<Contact[]>(contacts));
	}

	get(): Observable<ServiceResponse<Contact[]>> {
		return of(new ServiceResponse<Contact[]>(contactsMock));
	}
}
