import { ModuleWithProviders, NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { PersonsService, SecretQuestionsListsService, ContactsService } from '@mdib/customers';

import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { AuthenticationDataMapperXclService } from '@mdib-xcl/sessions';

import { SecretQuestionsMapperXclService } from './services/secret-questions-mapper-xcl.service';
import { SecretQuestionsListsXclService } from './services/secret-questions-lists-xcl.service';
import { ContactsXclService } from './services/contacts-xcl.service';
import { ContactMapperXclService } from './services/contacts-mapper-xcl.service';
import { PersonsXclService } from './services/persons-xcl.service';
import { PersonsMapperXclService } from './services/persons-mapper-xcl.service';

@NgModule()
export class XclCustomersModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(ContactsService, adapterId, ContactsXclService);
		adaptersService.setAdapter(SecretQuestionsListsService, adapterId, SecretQuestionsListsXclService);
		adaptersService.setAdapter(PersonsService, adapterId, PersonsXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: XclCustomersModule,
			providers: [
				AuthenticationDataMapperXclService,
				ContactMapperXclService,
				SecretQuestionsMapperXclService,
				PersonsMapperXclService,
			],
		};
	}
}
