export enum XclOrderNature {
	SEPAPaymentCreation = 'PGCC0101',
	TermDepositCreation = 'TDCC0103',
	BeneficiaryCreation = 'BICC0101',
	StandingOrderCreation = 'SRCC0101',
	InternationalPaymentCreation = 'RECC0301',
	InternationalPaymentDeletion = 'RECD0301',
	PaymentFileProcess = 'EXCC0301',
	InstantPayment = 'PAYC0101',
	SepaDirectDebitUpdation = 'PGCU0A02',
}
