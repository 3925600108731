import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ParameterSetsService } from '@mdib/core/customization';

import { SecretQuestion, SecretQuestionModel } from '../../types/secret-question';
import { SecretQuestionParameter } from '../../types/models/secret-question-parameter';

@Component({
	selector: 'mdib-input-secret-questions',
	templateUrl: './input-secret-questions.component.html',
	styleUrls: ['./input-secret-questions.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputSecretQuestionsComponent),
			multi: true,
		},
	],
})
export class InputSecretQuestionsComponent implements ControlValueAccessor {

	public readonly NAMESPACE = 'customersModule.inputSecretQuestionsComponent.';
	public questions: SecretQuestionParameter[];

	@Input() formDisabled = false;
	@Input() questionNumber: number;
	public isVisible = false;
	public model: SecretQuestionModel = { id: '', question: '', answer: '' };

	public _onTouched: Function;
	public _onChange: Function;

	constructor(
		parameterSetsService: ParameterSetsService,
	) {
		this._onChange = () => { };
		this._onTouched = _ => { };
		parameterSetsService.get<SecretQuestionParameter[]>('secret-questions').subscribe(questions => this.questions = questions);
	}

	/**
	 * Write a value to the inputs
	 * @param {SecretQuestionModel} obj
	 */
	public writeValue(obj: SecretQuestionModel): void {
		Object.assign(this.model, obj);
	}

	/**
	 * Register a function to tell Angular when the value of the inputs changes
	 * @param {(_: any) => void} fn
	 */
	public registerOnChange(fn: (_: any) => void): void {
		this._onChange = fn;
	}

	/**
	 * Register a function to tell Angular when the inputs has been touched
	 * @param {() => void} fn
	 */
	public registerOnTouched(fn: () => void): void {
		this._onTouched = fn;
	}

	/**
	 * Disable the inputs
	 * @param {boolean} isDisabled
	 */
	public setDisabledState?(isDisabled: boolean): void {
		this.formDisabled = isDisabled;
	}

	/**
	 * Display chars of password in the input
	 */
	public showPassword() {
		this.isVisible = !this.isVisible;
	}

	/**
	 * Send the value of inputs
	 * @param {SecretQuestionModel} obj
	 */
	public sendValue(obj: SecretQuestionModel) {
		this._onChange(new SecretQuestion(obj));
	}
}
