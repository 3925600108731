import { Beneficiary, BeneficiaryType } from '@mdib/beneficiaries';
import { CashAccount } from '@mdib/cash-accounts';
import { CommunicationTypes, Address } from '@mdib/commons';
import { ManagedCurrencyCodes } from '@mdib/config';
import * as Moment from 'moment';
import { AmountTypes, OperationStatus, PaymentOperation, PaymentOperationBuilder, PaymentTypes, StandingOrderDetails } from '@mdib/payments';
import { Months, Periodicities, Status, WeekDays } from '@mdib/utils';

export class StandingOrdersMock {

	static getMockStandingOrder(): PaymentOperation<StandingOrderDetails>[] {
		return [
			new PaymentOperationBuilder<StandingOrderDetails>()
				.reference('B4K48CWZZP0J9K5O')
				.operationDate(new Date())
				.maturityDate(new Date())
				.ordererAccountNumber('BE56305177635588')
				.ordererName('John Doe')
				.ordererAccountAlias('Daily')
				.amount(50)
				.currency(ManagedCurrencyCodes.EUR)
				.counterpartyAccountNumber('BE11736988971648')
				.counterpartyName('For the kids')
				.communicationType(CommunicationTypes.free)
				.communication('Test communication')
				.executionDetails(new StandingOrderDetails({
					startDate: Moment('20180921'),
					endDate: Moment('20181221'),
					frequency: Periodicities.weekly,
					weekDayPayment: WeekDays.friday,
					amountType: AmountTypes.fixed,
				}))
				.counterparty(new Beneficiary({
					account: new CashAccount({
						number: 'BE11736988971648',
					}),
					type: BeneficiaryType.INTERNAL,
					fullName: 'For the kids',
				}))
				.status(Status.InShoppingBasket)
				.operationStatus(OperationStatus.inShoppingBasket)
				.type(PaymentTypes.europeanStandingOrder).paymentOperation,
			new PaymentOperationBuilder<StandingOrderDetails>()
				.reference('B4K48CWZZP0J9555')
				.operationDate(new Date())
				.maturityDate(new Date())
				.ordererAccountNumber('BE56305177635588')
				.ordererName('John Doe')
				.ordererAccountAlias('Daily')
				.currency(ManagedCurrencyCodes.EUR)
				.counterparty(new Beneficiary({
					account: new CashAccount({
						number: 'BE12123412341234',
					}),
					address: new Address({
						addressLine1: 'Tervuren Avenue, 27',
						addressLine2: '10000, Paris',
						country: 'FR',
					}),
					type: BeneficiaryType.SEPA,
					fullName: 'Annie Legreve',
				}))
				.communicationType(CommunicationTypes.free)
				.communication('Test communication')
				.executionDetails(new StandingOrderDetails({
					startDate: Moment('20181023'),
					minBalance: 50,
					minAmount: 30,
					maxAmount: 70,
					endDate: Moment('20190305'),
					frequency: Periodicities.quarterly,
					firstPaymentDay: 1,
					monthPayment: Months.october,
					amountType: AmountTypes.variable,
				}))
				.status(Status.InShoppingBasket)
				.operationStatus(OperationStatus.inShoppingBasket)
				.type(PaymentTypes.europeanStandingOrder).paymentOperation,
			new PaymentOperationBuilder<StandingOrderDetails>()
				.reference('B4K48CWZZP0J9687')
				.operationDate(new Date())
				.maturityDate(new Date())
				.ordererAccountNumber('BE56305177635588')
				.ordererName('John Doe')
				.ordererAccountAlias('Daily')
				.amount(50)
				.currency(ManagedCurrencyCodes.EUR)
				.counterpartyAccountNumber('BE11736988971648')
				.counterpartyName('For the kids')
				.communicationType(CommunicationTypes.free)
				.communication('Test communication')
				.executionDetails(new StandingOrderDetails({
					startDate: Moment('20180923'),
					iteration: 5,
					totalAmount: 250,
					frequency: Periodicities.daily,
					amountType: AmountTypes.fixed,
				}))
				.counterparty(new Beneficiary({
					account: new CashAccount({
						number: 'BE11736988971648',
					}),
					type: BeneficiaryType.INTERNAL,
					fullName: 'For the kids',
				}))
				.status(Status.InShoppingBasket)
				.operationStatus(OperationStatus.inShoppingBasket)
				.type(PaymentTypes.europeanStandingOrder).paymentOperation,
			new PaymentOperationBuilder<StandingOrderDetails>()
				.reference('B4K48CWZZP0J9878')
				.operationDate(new Date())
				.maturityDate(new Date())
				.ordererAccountNumber('BE56305177635588')
				.ordererName('John Doe')
				.ordererAccountAlias('Daily')
				.amount(25)
				.currency(ManagedCurrencyCodes.EUR)
				.counterparty(new Beneficiary({
					account: new CashAccount({
						number: 'FR1420041010050500013M02606',
					}),
					address: new Address({
						addressLine1: 'Place de l\'Iris, 5',
						addressLine2: '92400, Courbevoie',
						country: 'FR',
					}),
					type: BeneficiaryType.SEPA,
					fullName: 'John Smith',
				}))
				.status(Status.ToValidate)
				.operationStatus(OperationStatus.awaitingProcessing)
				.communicationType(CommunicationTypes.free)
				.communication('This is a very long communication for testing purposes - 66 chars')
				.executionDetails(new StandingOrderDetails({
					startDate: Moment(),
					iteration: 5,
					totalAmount: 125,
					frequency: Periodicities.trimonthly,
					firstPaymentDay: 1,
					amountType: AmountTypes.fixed,
					monthPayment: Months.july,
				}))
				.type(PaymentTypes.europeanStandingOrder).paymentOperation,
		];
	}
}
