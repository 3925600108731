export class MemoryStorage implements Storage {

	[key: string]: any;

	private store = new Map<string, string>();

	public get length(): number {
		return this.store.size;
	}

	public clear(): void {
		this.store.clear();
	}

	public getItem(key: string): string {
		return this.store.get(key);
	}

	public key(index: number): string {
		throw new Error('Method not implemented.');
	}

	public removeItem(key: string): void {
		this.store.delete(key);
	}

	public setItem(key: string, value: string): void {
		this.store.set(key, value);
	}
}
