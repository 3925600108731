import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthorizationsService } from './authorizations.service';

@Injectable({
	providedIn: 'root'
})
export class SecurityGuardService implements CanActivate {

	constructor(
		private router: Router,
		private authorizationsService: AuthorizationsService,
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		const authorized = this.authorizationsService.authorized(route.data['authorizedTokens']);
		if (!authorized) {
			this.router.navigate(['/errors/unauthorized']);
		}
		return authorized;
	}

}
