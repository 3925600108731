import { ArtificialPerson, NaturalPerson, NaturalPersonBuilder, NaturalPersonGender, NaturalPersonMaritalStatus } from '@mdib/customers';

import { parseXclLanguageCode, XclDateFormatter } from '@mdib-xcl/utils';

import { XclClientModel } from '../types/models/xcl-client-model';
import { XclRepresentedClientModel } from '../types/models/xcl-represented-client-model';

export class PersonsMapperXclService {

	/**
	 * Transforms a XCL client into a Person
	 *
	 * @param {XclClientModel} xclClient
	 * @returns {NaturalPerson | ArtificialPerson}
	 */
	xclClientToPerson(xclClient: XclClientModel): NaturalPerson | ArtificialPerson {

		// TODO: how to see if it's a natural person or an artificial person ?
		const personBuilder = new NaturalPersonBuilder(
			{
				id: xclClient.thirdPartyNumber,
			})
			.setName(xclClient.shortName)
			.setBirthCity(xclClient.birthPlace)
			.setBirthCountry((xclClient.birthCountryCode))
			.setBirthDate(new Date(XclDateFormatter.convertXCLToISODateFormat(xclClient.birthDate)))
			.setFirstName(xclClient.firstName)
			.setGender(this.xclGenderToFunctionalGender(xclClient.gender))
			.setLastName(xclClient.lastName)
			.setLanguage(parseXclLanguageCode(xclClient.languageCode))
			.setMaritalStatus(this.xclMaritalStatusToFunctionalMaritalStatus(xclClient.maritalStatus))
			.setNationality(xclClient.nationality);

		return personBuilder.build();
	}

	/**
	 * Transforms a list of XCL represented client into a list of Persons
	 *
	 * @param {XclRepresentedClientModel[]} xclRepresentedClientsList
	 * @returns {NaturalPerson[] | ArtificialPerson[]}
	 */
	xclRepresentedClientsListToPersonsList(xclRepresentedClientsList: XclRepresentedClientModel[]): NaturalPerson[] | ArtificialPerson[] {
		return xclRepresentedClientsList.map((xclRepresentedClient: XclRepresentedClientModel) =>
			this.xclRepresentedClientToPerson(xclRepresentedClient));
	}

	/**
	 * Transforms a XCL represented client into a Person
	 *
	 * @param {XclRepresentedClientModel} xclRepresentedClient
	 * @returns {NaturalPerson | ArtificialPerson}
	 */
	private xclRepresentedClientToPerson(xclRepresentedClient: XclRepresentedClientModel): NaturalPerson | ArtificialPerson {
		// TODO: how to see if it's a natural person or an artificial person ?
		return new NaturalPersonBuilder({
			id: xclRepresentedClient.clientRoot,
		})
			.setName(xclRepresentedClient.clientShortName)
			.setBirthDate(xclRepresentedClient.birthDate
				? new Date(XclDateFormatter.convertXCLToISODateFormat(xclRepresentedClient.birthDate))
				: undefined)
			.setFirstName(xclRepresentedClient.clientFirstName)
			.setLastName(xclRepresentedClient.clientName)
			.setLanguage(parseXclLanguageCode(xclRepresentedClient.languageCode))
			.setDefaultRepresentedClientSwitch(xclRepresentedClient.defaultRepresentedClientSwitch)
			.build();
	}

	private xclGenderToFunctionalGender(xclGender: string): NaturalPersonGender {
		switch (xclGender) {
			case '1':
				return NaturalPersonGender.Male;
			case '2':
				return NaturalPersonGender.Female;
			default:
				return undefined;
		}
	}

	private xclMaritalStatusToFunctionalMaritalStatus(xclMaritalStatus: string): NaturalPersonMaritalStatus {
		switch (xclMaritalStatus) {
			case '01':
				return NaturalPersonMaritalStatus.Single;
			case '02':
				return NaturalPersonMaritalStatus.Married;
			case '05':
				return NaturalPersonMaritalStatus.Widowed;
			case '06':
				return NaturalPersonMaritalStatus.Divorced;
			default:
				return undefined;
		}
	}

}
