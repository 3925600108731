import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

export type EnhancedFormGroup<T> = {
	[P in keyof T]: AbstractControl;
};

export abstract class TypedFormControl<T> extends FormControl {

	readonly value: T;

	setValue(value: T, options?: {
		onlySelf?: boolean;
		emitEvent?: boolean;
	}): void {
		super.setValue(value, options);
	}

	patchValue(value: T, options?: {
		onlySelf?: boolean;
		emitEvent?: boolean;
		emitModelToViewChange?: boolean;
		emitViewToModelChange?: boolean;
	}): void {
		super.patchValue(value, options);
	}
}

export abstract class TypedFormGroup<T> extends FormGroup {

	readonly value: T;

	setValue(value: T, options?: {
		onlySelf?: boolean;
		emitEvent?: boolean;
	}): void {
		super.setValue(value, options);
	}

}
