import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class BeneficiariesSearchFormBuilder {

	constructor(
		private formBuilder: FormBuilder,
	) { }

	public build(): FormGroup {
		// Build an form empty with validation
		return this.formBuilder.group({
			type: [],
			fullName: [],
			alias: [],
			accountNumber: [],
		});
	}
}
