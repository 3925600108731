import { ModuleWithProviders, NgModule } from '@angular/core';
import { PaymentFilesServiceProxy } from './service/payment-files.service.proxy';
import { PaymentFilesService } from './service/payment-files.service';
import { NUMBER_OF_DIGITS_TO_CHECK } from './payment-files.const';

@NgModule()
export class PaymentFilesModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: PaymentFilesModule,
			providers: [
				{ provide: PaymentFilesService, useClass: PaymentFilesServiceProxy },
				{ provide: NUMBER_OF_DIGITS_TO_CHECK, useValue: 5 },
			]
		};
	}
}
