import { ContractStatus } from '@mdib/sepa-direct-debits';

export class XclContractStatus {
	private static mapper = {
		'0000': ContractStatus.BeingEntered,
		'1000': ContractStatus.ToBeValidated,
		'2000': ContractStatus.Reopened,
		'2100': ContractStatus.Reopened,
		'3000': ContractStatus.Suspended,
		'3100': ContractStatus.Suspended,
		'4000': ContractStatus.Validated,
		'4100': ContractStatus.Active,
		'9000': ContractStatus.Closed,
		'9900': ContractStatus.Deleted,
	};

	public static fromXclType(type: string): ContractStatus {
		return XclContractStatus.mapper[type];
	}
}
