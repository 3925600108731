import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { UtilsHelper } from '../helper/utils-helper';
import { InputError } from '../model/utils.typings';

export namespace CommonValidator {

	export const REQUIRED = { required: <InputError> { translationKey: 'utilsModule.commonValidator.required' } };
	export const NON_EMPTY = { 'utilsModule.commonValidator.nonEmpty': true };
	export function MIN(minimum: number): { [key: string]: any } {
		return { minimum: <InputError> { translationKey: 'notification:errors:minimum', params: { minimum: minimum + '' } } };
	}

	export function MAX(maximum: number): { [key: string]: any } {
		return { maximum: <InputError> { translationKey: 'notification:errors:maximum', params: { maximum: maximum + '' } } };
	}

	export function required(control: AbstractControl): { [key: string]: any } | null {
		if (typeof control.value === 'object') {
			return !!control.value && UtilsHelper.objectNotEmpty(control.value) && control.value !== false && control.value !== 0 ? null : REQUIRED;
		} else {
			return !!control.value || control.value === false || control.value === 0 ? null : REQUIRED;
		}
	}

	export function requiredTrue(control: AbstractControl): { [p: string]: any } | null {
		return !!control.value && Validators.requiredTrue(control.value) ? null : REQUIRED;
	}

	export function notEmpty(control: AbstractControl): {[key: string]: any} | null {
		return (!control.value || control.value.trim() === '') ? NON_EMPTY : null;
	}

	export function max(limiter: number | AbstractControl): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const numberToCheck: number = typeof control.value === 'string' ? Number(control.value.replace(',', '.')) : control.value;
			const maxLimit: number = typeof limiter === 'number' ? limiter : Number(limiter.value ? limiter.value.replace(',', '.') : null);
			return UtilsHelper.nullOrUndefined(numberToCheck) || numberToCheck <= maxLimit ? null : MAX(maxLimit);
		};
	}

	export function min(limiter: number | AbstractControl): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const numberToCheck: number = typeof control.value === 'string' ? Number(control.value.replace(',', '.')) : control.value;
			const minLimit: number = typeof limiter === 'number' ? limiter : Number(limiter.value ? limiter.value.replace(',', '.') : null);
			return UtilsHelper.nullOrUndefined(numberToCheck) || numberToCheck >= minLimit ? null : MIN(minLimit);
		};
	}
}
