import { Component, OnInit, HostListener, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AdapterSwitchService } from '@mdib/core/adapters';
import { AuthenticationProcessComponent, AuthenticationModesService } from '@mdib/sessions';
import { CacheService, ConfigurationService, ServiceResponse } from '@mdib/utils';

import { PasswordAuthenticationService } from '../../service/password-authentication.service';

@Component({
	selector: 'mdib-password-authentication-process',
	templateUrl: './password-authentication-process.component.html',
	styleUrls: ['./password-authentication-process.component.scss'],
})
export class PasswordAuthenticationProcessComponent extends AuthenticationProcessComponent implements OnInit {

	// This field is made static to hold the disabled time, even when the component is destroyed
	public static disableTime = 0;

	public NAMESPACE = 'sessionsModesModule.passwordAuthenticationProcessComponent.';

	/**
	 * @property Indicates if the curent process is valid
	 */
	public failed = false;

	/**
	 * @property Indicates whether a service operation is ongoing (true) or not (false)
	 */
	public isLoading = false;

	public passwordAuthenticationFormGroup: FormGroup;

	public capsLockKeyDown = false;
	// This property defines the time for which the login button has to be disabled according to the number of incorrect login attempts
	private definedDisableTime: number[];
	private timer: NodeJS.Timer;

	constructor(
		private passwordAuthenticationService: PasswordAuthenticationService,
		private formBuilder: FormBuilder,
		private cacheService: CacheService,
		private authenticationService: AuthenticationModesService,
		@Optional() private adapterSwitchService: AdapterSwitchService,
		private configurationService: ConfigurationService,
	) {
		super();
	}

	public ngOnInit() {
		this.passwordAuthenticationFormGroup = this.formBuilder.group({
			username: [this.user && this.user.person.name, [Validators.required]],
			password: ['', [Validators.required]],
		});

		this.failed = false;
		this.isLoading = true;
		this.definedDisableTime = this.configurationService.instant('sessioncreation.disableTime');
	}

	public authenticate() {
		// Trim the useless trailing spaces of the username
		const username = this.passwordAuthenticationFormGroup.get('username').value || '';
		this.passwordAuthenticationFormGroup.get('username').setValue(username.trim());

		if (this.passwordAuthenticationFormGroup.invalid) {
			return;
		}

		// TODO Find another way to switch Mock ON/OFF
		if (this.adapterSwitchService) {
			this.adapterSwitchService.switchForUser(this.username);
		}

		// Disable the form while we try to log in. It will be enabled again once the call has been done
		this.passwordAuthenticationFormGroup.disable();

		// Clear cache in case previous session was ended by timeout instead of explicit log out
		this.cacheService.invalidate();

		// Try to authenticate
		this.passwordAuthenticationService
			.authenticate(this.username, this.password)
			.subscribe(response => {
				this.onSuccess.emit(response.getModel());
			}, (error: ServiceResponse<any>) => {
				// Form is in error
				this.failed = true;
				this.onEvent.emit(new ErrorEvent('error', { error: error.getFeedbacks()[0].key }));
				let incorrectLoginAttempts = this.authenticationService.getIncorrectAttempts();
				this.authenticationService.setIncorrectAttempts(++incorrectLoginAttempts);
				// To disable login for some time based on the number of login attempts
				this.remainingDisableTime = incorrectLoginAttempts <= this.definedDisableTime.length ? this.definedDisableTime[incorrectLoginAttempts - 1] : this.definedDisableTime[this.definedDisableTime.length - 1];
				if (this.remainingDisableTime > 0) {
					this.timer = setInterval(() => this.decreaseCounter(), 1000);
				}
				this.passwordAuthenticationFormGroup.enable();
			}, () => {
				this.passwordAuthenticationFormGroup.enable();
			}
			);
	}

	public cancel() {
		this.onEvent.emit(new Event('abort'));
	}

	get password(): string {
		return this.passwordAuthenticationFormGroup.get('password').value;
	}

	get username(): string {
		return this.passwordAuthenticationFormGroup.get('username').value;
	}

	@HostListener('document:keydown', ['$event'])
	onCapsLockKeyEvent(event: KeyboardEvent) {
		this.capsLockKeyDown = event.getModifierState && event.getModifierState('CapsLock');
	}

	/**
	 * Decreases the disable time by 1 sec if disable time greater than 0, else disables the timer
	 */
	decreaseCounter(): void {
		this.remainingDisableTime === 0 ? clearInterval(this.timer) : this.remainingDisableTime--;
	}

	get remainingDisableTime(): number {
		return PasswordAuthenticationProcessComponent.disableTime;
	}

	set remainingDisableTime(time: number) {
		PasswordAuthenticationProcessComponent.disableTime = time;
	}
}
