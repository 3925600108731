import { Pipe, PipeTransform } from '@angular/core';

/**
 * My role is to truncate strings for a given size and display aa placeholder of the trunkated content.
 *
 * @export
 * @class TruncatePipe
 * @implements {PipeTransform}
 * @example
 * myBigString | truncate : [50]
 */
@Pipe({
	name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

	/**
	 * @param {string} value the input string
	 * @param {string[]} [args] the first element is the max size, the second is the placeholder. "..." by default
	 * @returns {string}
	 * @memberof TruncatePipe
	 */
	transform(value: string, args?: string[]): string {
		const limit = args.length > 0 ? parseInt(args[0], 10) : 10;
		const trail = args.length > 1 ? args[1] : '...';

		return value.length > limit ? value.substring(0, limit) + trail : value;
	}

}
