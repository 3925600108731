import { Injectable } from '@angular/core';

import { BackendOperation, SearchCriteria, SearchResult } from '@mdib/core/adapters';
import { SecretQuestionsListsService, SecretQuestionsList } from '@mdib/customers';

import { CreateOperationMemory, RetrieveOperationMemory, UpdateOperationMemory } from '@mdib-memory/core';

import { SecretQuestionsListMock } from '../mock/secret-questions-list.mock';

@Injectable({
	providedIn: 'root'
})
export class SecretQuestionsListsMemoryService extends SecretQuestionsListsService {

	public create(): BackendOperation<SecretQuestionsList, SecretQuestionsList> {
		return new CreateOperationMemory();
	}

	public retrieve(): BackendOperation<string, SecretQuestionsList> {
		return new RetrieveOperationMemory(SecretQuestionsListMock);
	}

	public update(): BackendOperation<Partial<SecretQuestionsList>, SecretQuestionsList> {
		return new UpdateOperationMemory<SecretQuestionsList>();
	}

	public delete(): BackendOperation<string, SecretQuestionsList> {
		throw new Error('Method not implemented');
	}

	public search(): BackendOperation<SearchCriteria<any>, SearchResult<SecretQuestionsList>> {
		throw new Error('Method not implemented');
	}
}
