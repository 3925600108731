import { Person } from '@mdib/customers';
import { ObjectBuilder } from '@mdib/utils';
import { User } from './user';
import { BusinessRole } from '@mdib/sessions';

export class UserBuilder extends ObjectBuilder<User> {

	build(): User {
		return new User(
			this.properties.pseudo,
			this.properties.parameters,
			this.properties.id,
			this.properties.connected,
			this.properties.businessRole,
			this.properties.accessRights,
			this.properties.person,
		);
	}

	setBusinessRole(businessRole: BusinessRole): UserBuilder {
		return this.set('businessRole', businessRole);
	}

	setAccessRights(accessRights: Array<any>): UserBuilder {
		return this.set('accessRights', accessRights);
	}

	setPerson(person: Person): UserBuilder {
		return this.set('person', person);
	}
}
