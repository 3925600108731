import { map, tap, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { XclSignatureContext, XclSignatureModes } from '@mdib-xcl/core';
import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { OrderStateHelper } from '@mdib-xcl/utils';
import { fromXclBoolean, toXclBoolean } from '@mdib-xcl/utils/helper/xcl-connector-helpers';
import { CashAccountCreation, CashAccountCreationBuilder, CashAccountTypeParameter } from '@mdib/cash-accounts';
import { Person } from '@mdib/customers';
import { ParameterModel } from '@mdib/http';
import { Feedback, FeedbackTypes, ServiceResponse, Status } from '@mdib/utils';
import { Observable, throwError, of } from 'rxjs';
import { ParameterSetsService } from '@mdib/core/customization';

import { XclCashAccountCreationModel } from '../model/xcl-cash-account-creation';
import { XclCashAccountCreationBuilder } from '../model/xcl-cash-account-creation-builder';

/**
 * The xcl implementation of methods related to {@link CashAccountCreation}
 */
@Injectable({
	providedIn: 'root',
})
export class CashAccountCreationXclService {

	private cashAccountTypes: CashAccountTypeParameter[];

	constructor(
		private xclHttpService: XclHttpService,
		private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private parameterSetsService: ParameterSetsService,
	) { }

	/**
	 * Invokes call to XCL to init the cash account creation.
	 * @return Observable<MonogoalOrderXCLModel<any>>
	 */
	public initCashAccount(): Observable<MonogoalOrderXCLModel<any>> {
		const xclObservable: Observable<MonogoalOrderXCLModel<any>> =
			this.xclHttpService.execute('create-account', XclHttpService.POST) as Observable<MonogoalOrderXCLModel<any>>;
		return xclObservable;
	}

	/**
	 * Invokes call to XCL to retrieve the cash account creation.
	 * @param orderReference to be retrieved.
	 * @return Observable<MonogoalOrderXCLModel<any>>
	 */
	public retrieveCashAccount(orderReference: string): Observable<MonogoalOrderXCLModel<any>> {
		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'retrieve'),
			new ParameterModel('reference', orderReference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<any>> =
			this.xclHttpService.execute('create-account', XclHttpService.PUT, parameters) as Observable<MonogoalOrderXCLModel<any>>;
		return xclObservable;
	}

	/**
	 * Invokes call to XCL to directly validate the cash account creation.
	 * @param {string} orderReference to be retrieved.
	 * @param {CashAccountCreation} cashAccountCreation Instance of CashAccountCreation to be validated.
	 * @returns {Observable<ServiceResponse<CashAccountCreation>>}
	 */
	public validateCashAccount(orderReference: string, cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'validate'),
			new ParameterModel('reference', orderReference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<XclCashAccountCreationModel>> =
			this.loadParameters().pipe(mergeMap(() => {
				return this.xclHttpService.execute(
					'create-account', XclHttpService.PUT, parameters,
					this.toXclCashAccountCreationModel(cashAccountCreation),
				) as Observable<MonogoalOrderXCLModel<XclCashAccountCreationModel>>;
			}));

		cashAccountCreation.signatureContext = new XclSignatureContext({ signableReference: cashAccountCreation.reference });

		return this.loadParameters().pipe(mergeMap(() => {
			return this.getCashAccountCreationFunctionalObservable(xclObservable, cashAccountCreation).pipe(
				tap(() => {
					cashAccountCreation.signatureContext = new XclSignatureContext({ signableReference: cashAccountCreation.reference });
					if (cashAccountCreation.status === Status.NotAuthorizedToSign) {
						throwError('Not authorized to sign !');
					}
				}));
		}));
	}

	/**
	 * Invokes call to XCL to directly confirm the cash account creation.
	 * @param cashAccountCreation Instance of CashAccountCreation to be confirmed.
	 * @return Observable<ServiceResponse<CashAccountCreation>> Functional observable of CashAccountCreation
	 */
	public confirmCashAccount(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'confirm'),
			new ParameterModel('reference', cashAccountCreation.reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<XclCashAccountCreationModel>> =
			this.loadParameters().pipe(mergeMap(() => {
				return this.xclHttpService.execute(
					'create-account', XclHttpService.PUT, parameters,
					this.toXclCashAccountCreationModel(cashAccountCreation),
				) as Observable<MonogoalOrderXCLModel<XclCashAccountCreationModel>>;
			}));
		return this.getCashAccountCreationFunctionalObservable(xclObservable, cashAccountCreation);
	}

	/**
	 * Invokes call to XCL to directly sign the cash account creation.
	 * @param cashAccountCreation Instance of CashAccountCreation to be signed.
	 * @return Observable<ServiceResponse<CashAccountCreation>> Functional observable of CashAccountCreation
	 */
	public signCashAccount(cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'sign'),
			new ParameterModel('reference', cashAccountCreation.reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<XclCashAccountCreationModel>> =
			this.loadParameters().pipe(mergeMap(() => {
				return this.xclHttpService.execute(
					'create-account', XclHttpService.PUT, parameters,
					this.toXclCashAccountCreationModel(cashAccountCreation),
				) as Observable<MonogoalOrderXCLModel<XclCashAccountCreationModel>>;
			}));

		cashAccountCreation.signatureContext = new XclSignatureContext({ signableReference: cashAccountCreation.reference });

		return this.getCashAccountCreationFunctionalObservable(xclObservable, cashAccountCreation).pipe(
			tap(() => {
				if (cashAccountCreation.status !== Status.Closed) {
					throw ServiceResponse.emptyWithOnefeedback(new Feedback('errorWhenSigningCashAccountCreation', FeedbackTypes.FRONTEND_ERROR, 'Something went wrong when signing the cash account'));
				}
			}));
	}

	private toXclCashAccountCreationModel(cashAccountCreation: CashAccountCreation) {
		return XclCashAccountCreationBuilder.empty()
			.cashAccountHolderIdentity(cashAccountCreation.holder.id)
			.accountCurrency(cashAccountCreation.currency)
			.accountTitle(cashAccountCreation.clientWording)
			.portfolio('01')
			.bankProduct(this.getXclCodeFromCashAccountType(cashAccountCreation.type))
			.usTaxResidenceCode(toXclBoolean(cashAccountCreation.usResident)).xclCashAccountCreation;
	}

	private feedFromXclCashAccountCreationModel(cashAccountCreation: CashAccountCreation, xclCashAccountCreationOrder: MonogoalOrderXCLModel<XclCashAccountCreationModel>): CashAccountCreation {
		const xclCashAccountCreationModel: XclCashAccountCreationModel = xclCashAccountCreationOrder.goal;
		return CashAccountCreationBuilder.from(cashAccountCreation)
			.accountNumber(xclCashAccountCreationModel.accountNumber)
			.status(OrderStateHelper.getStatusFromXCLOrderStateCode(xclCashAccountCreationOrder.state))
			.reference(xclCashAccountCreationOrder.reference)
			.signatureTypesAllowed(xclCashAccountCreationOrder.signatureTypesAllowed.map(type => XclSignatureModes.fromXclType(type)))
			// TODO, see B2C_IRM-169
			.clientCounterparty(new Person(xclCashAccountCreationModel.cashAccountHolderIdentity, cashAccountCreation.holder.name))
			.type(this.getCashAccountTypeFromXclCode(xclCashAccountCreationModel.bankProduct))
			.valuationCurrency(xclCashAccountCreationModel.accountCurrency)
			.clientWording(xclCashAccountCreationModel.accountTitle)
			.usResident(fromXclBoolean(xclCashAccountCreationModel.usTaxResidenceCode)).cashAccountCreation;
	}

	private getCashAccountCreationFunctionalObservable(technicalObservable: Observable<MonogoalOrderXCLModel<XclCashAccountCreationModel>>, cashAccountCreation: CashAccountCreation): Observable<ServiceResponse<CashAccountCreation>> {
		return technicalObservable.pipe(
			map((xclCashAccountCreationModel: MonogoalOrderXCLModel<XclCashAccountCreationModel>) => {
				cashAccountCreation.status = OrderStateHelper.getStatusFromXCLOrderStateCode(xclCashAccountCreationModel.state);
				return new ServiceResponse(
					this.feedFromXclCashAccountCreationModel(cashAccountCreation, xclCashAccountCreationModel),
					this.feedbackExtractor.extract(xclCashAccountCreationModel),
				);
			}));
	}

	private loadParameters(): Observable<boolean> {
		return this.parameterSetsService.get('cash-account-types').pipe(map((params: CashAccountTypeParameter[]) => {
			this.cashAccountTypes = params;
			return true;
		}));
	}

	private getCashAccountTypeFromXclCode(xclCode: string): string {
		const tuple = this.cashAccountTypes.find((parameter: CashAccountTypeParameter) => parameter.backendMapping === xclCode);
		return tuple ? tuple.key : null;
	}

	private getXclCodeFromCashAccountType(cashAccountType: string): string {
		const tuple = this.cashAccountTypes.find((parameter: CashAccountTypeParameter) => parameter.key === cashAccountType);
		return tuple ? tuple.backendMapping : null;
	}

}
