import { HostListener, Component } from '@angular/core';

@Component({
	template: ''
})
export class CanDeactivateComponent {
	canDeactivate(): boolean {
		return undefined;
	}

	@HostListener('window:beforeunload', ['$event'])
	unloadNotification($event: any) {
		if (!this.canDeactivate()) {
			$event.returnValue = true;
		}
	}
}
