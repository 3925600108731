import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
// TODO Bad imports from pages and cycling module
import { CommunicationTypes } from '@mdib/commons';
import { CountryCodes } from '@mdib/config';
import { PaymentTypes } from '@mdib/payments/types/models/payments.enum';
import { EnumHelper, UtilsHelper } from '@mdib/utils';
import { printFormat } from 'iban';

import { BeneficiarySearchModalComponent } from '../../components/beneficiary-search-modal/beneficiary-search-modal.component';
import { BeneficiaryType } from '../../types/enums/beneficiary-type.enum';
import { Beneficiary } from '../../types/models/beneficiary';
import { BeneficiaryForm } from '../beneficiary-form';

/** @deprecated */
@Component({
	selector: 'mdib-beneficiary-form',
	templateUrl: './beneficiary-form.component.html',
	styleUrls: ['./beneficiary-form.component.scss']
})
export class MdibBeneficiaryFormComponent {

	readonly NAMESPACE = 'beneficiariesPageModule.beneficiaryFormComponent.';

	@Input() paymentType: PaymentTypes;
	@Input() beneficiaryForm: BeneficiaryForm;

	hideAddress = true;
	countries = EnumHelper.numbersEnumToStringArray(CountryCodes);

	constructor(
		private dialog: MatDialog,
	) {
	}

	toDisplay(control: AbstractControl, group = false): boolean {
		return !control.disabled || (control.disabled && (group ? UtilsHelper.objectNotEmpty(control.value) : !!control.value));
	}

	/**
	 * This method opens a dialog box when "search" button is clicked
	 */
	public openDialog() {
		let beneficiaryType: BeneficiaryType;
		switch (this.paymentType) {
			case 'sepaCreditTransfer':
			case 'europeanStandingOrder':
				beneficiaryType = BeneficiaryType.SEPA;
				break;
			case 'internationalPayment':
				beneficiaryType = BeneficiaryType.INTERNATIONAL;
				break;
			default:
				beneficiaryType = null;
				break;
		}

		const dialogRef = this.dialog.open(BeneficiarySearchModalComponent, {
			disableClose: true,
			maxWidth: '95%',
			maxHeight: '95%',
		});

		dialogRef.componentInstance.type = beneficiaryType;
		dialogRef.componentInstance.itemChange.subscribe((beneficiary: Beneficiary) => {
			this.applyBeneficiary(beneficiary, beneficiaryType);
			dialogRef.close();
		});
	}

	isInternationalPayment() {
		return this.paymentType === PaymentTypes.internationalPayment;
	}

	isUrgentPayment() {
		return this.paymentType === PaymentTypes.urgentPayment;
	}

	/**
	 * Sets beneficiary selected from modal and emits change in information
	 * @param {Beneficiary} beneficiary instance which was selected from modal
	 * @param {BeneficiaryType} beneficiary type
	 * @return void
	 */
	protected applyBeneficiary(beneficiary: Beneficiary, beneficiaryType: BeneficiaryType): void {
		if (UtilsHelper.objectNotEmpty(beneficiary)) {
			this.beneficiaryForm.accountForm.number.setValue(printFormat(beneficiary.account.number, ' '));
			this.beneficiaryForm.accountForm.bic.setValue(beneficiary.account.bic);
			this.beneficiaryForm.accountForm.number.markAsTouched();
			this.beneficiaryForm.fullName.setValue(beneficiary.fullName);
			this.beneficiaryForm.addressForm.country.setValue(beneficiary.address.country);
			this.beneficiaryForm.addressForm.addressLine1.setValue(beneficiary.address.addressLine1);
			this.beneficiaryForm.addressForm.addressLine2.setValue(beneficiary.address.addressLine2);
			if (beneficiaryType === BeneficiaryType.INTERNATIONAL) {
				this.beneficiaryForm.communicationForm.type.setValue(CommunicationTypes.free);
			} else {
				this.beneficiaryForm.communicationForm.type.setValue(beneficiary.communication.type);
			}
			this.beneficiaryForm.communicationForm.value.setValue(beneficiary.communication.value);
		}
	}
}
