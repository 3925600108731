import { ModuleWithProviders, NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { KeyingLimitsService } from '@mdib/keying-limits';

import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';

import { KeyingLimitsMapperXclService } from './service/keying-limits-mapper-xcl.service';
import { KeyingLimitsXclService } from './service/keying-limits-xcl.service';

@NgModule()
export class XclKeyingLimitsModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(KeyingLimitsService, adapterId, KeyingLimitsXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: XclKeyingLimitsModule,
			providers: [KeyingLimitsMapperXclService]
		};
	}
}
