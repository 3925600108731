import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { ConfigService } from '@mdib/config';
import { NotificationMessage, NotificationMessageService, ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { InternationalPaymentsService, PaymentForm, PaymentOperation, PaymentsService, PaymentTypes, StandingOrderService, InstantPaymentsService } from '@mdib/payments';
import { SignableOperation } from '@mdib/signature';
import { CacheService, DataFormattingService, FeedbackTypes, ServiceResponse, Status, UtilsHelper, Feedback } from '@mdib/utils';

@Component({
	templateUrl: './payments-consultation-page.component.html',
	styleUrls: ['./payments-consultation-page.component.scss'],
})

export class PaymentsConsultationPageComponent implements OnInit, OnDestroy {
	readonly NAMESPACE = 'paymentsPagesModule.paymentsConsultationPage.';
	error = false;
	paymentTypes = PaymentTypes;

	public showSignatureComponent = false;
	public isSignableAlone = false;
	public isDeletionOngoing = false;
	public isConfirmationOngoing = false;
	public isSignable = false;


	private _paymentOperation: PaymentOperation<any>;
	private operationReference: string;
	private paymentType: string;
	private url: string;
	private signablePaymentOperation: SignableOperation<PaymentOperation<any>>;
	private isDeleteSuccessful = false;
	private isSavedSuccessful = false;

	constructor(
		private location: Location,
		private paymentsService: PaymentsService,
		private route: ActivatedRoute,
		private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService,
		private cacheService: CacheService,
		private notificationMessageService: NotificationMessageService,
		private internationalPaymentsService: InternationalPaymentsService,
		private standingOrderService: StandingOrderService,
		private instantPaymentsService: InstantPaymentsService
	) { }


	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.operationReference = params.get('reference');
			this.paymentType = params.get('paymentType');
			switch (this.paymentType) {
				case PaymentTypes.internationalPayment :
					this.retrieve(this.internationalPaymentsService);
					break;
				case PaymentTypes.urgentPayment:
					this.retrieve(this.internationalPaymentsService);
					break;
				case PaymentTypes.instantPayment:
					this.retrieve(this.instantPaymentsService);
					break;
				case PaymentTypes.sepaCreditTransfer :
					this.retrieve(this.paymentsService, PaymentTypes.sepaCreditTransfer);
					break;
				case PaymentTypes.europeanStandingOrder :
					this.retrieve(this.standingOrderService);
					break;
				default :
					this._paymentOperation = new PaymentOperation<any>();
					this._paymentOperation.type = PaymentTypes.unknown;
					break;
			}
		});
		this.route.url.subscribe((urlSegments: UrlSegment[]) => this.url = urlSegments[0].path);
	}

	/**
	 * Deletes a pending payment
	 */
	delete(): void {
		this.notificationMessageService.clearAll();
		this.isDeletionOngoing = true;
		switch (this.paymentOperation.type) {
			case PaymentTypes.internationalPayment :
				this.deletePayment(this.internationalPaymentsService);
				break;
			case PaymentTypes.urgentPayment:
				this.deletePayment(this.internationalPaymentsService);
				break;
			case PaymentTypes.instantPayment :
				this.deletePayment(this.instantPaymentsService);
				break;
			case PaymentTypes.europeanStandingOrder :
				this.deletePayment(this.standingOrderService);
				break;
			default :
				this.deletePayment(this.paymentsService);
				break;
		}
	}

	/**
	 * Navigates to previous URL
	 */
	goBack(): void {
		this.location.back();
	}

	/**
	 * Matches the input with current URL
	 * @param {string} value : URL to match
	 * @returns {boolen}: whether the URL matched
	 */
	testUrl(value: string): boolean {
		return value === this.url;
	}

	/**
	 * Displays signature component
	 */
	showSignature(): void {
		this.showSignatureComponent = true;
	}

	/**
	 * Navigates to previous URL when signature is successful
	 */
	handleSignatureSuccess(): void {
		switch (this.paymentOperation.type) {
			case PaymentTypes.internationalPayment :
				this.signDelete(this.internationalPaymentsService);
				break;
			case PaymentTypes.urgentPayment :
				this.signDelete(this.internationalPaymentsService);
				break;
			case PaymentTypes.instantPayment :
				this.signDelete(this.instantPaymentsService);
				break;
			case PaymentTypes.europeanStandingOrder :
				this.signDelete(this.standingOrderService);
				break;
			default :
				this.signDelete(this.paymentsService);
				break;
		}
	}

	/**
	 * Displays the feedback in case of signature failure
	 */
	handleSignatureErrorsOrAbort(feedback: Feedback): void {
		if (UtilsHelper.nullOrUndefined(feedback)) {
			this.serviceResponseNotifMapper.sendFeedback(feedback);
		}
		this.showSignatureComponent = false;
	}

	/**
	 * Saves the pending payment deletion operation in shopping basket
	 */
	saveDeleteOperationToShoppingBasket() {
		this.notificationMessageService.clearAll();
		this.isConfirmationOngoing = true;
		switch (this.signablePaymentOperation.model.type) {
			case PaymentTypes.internationalPayment:
				this.saveDelete(this.internationalPaymentsService);
				break;
			case PaymentTypes.urgentPayment:
				this.saveDelete(this.internationalPaymentsService);
				break;
			case PaymentTypes.sepaCreditTransfer:
				this.saveDelete(this.paymentsService);
				break;
			default:
				break;
		}
	}

	/**
	 * Generate a payment form based on the payment operation for consultation
	 * @returns {PaymentForm}
	 */
	generateFormFromOperation(): PaymentForm {
		const form = new PaymentForm(new FormBuilder(), new DataFormattingService(), new ConfigService(), false, this.paymentOperation, true);
		form.group.disable();
		return form;
	}

	ngOnDestroy(): void {
		// If the deletion was successful, then generate the success notification
		if (this.isDeleteSuccessful === true) {
			// FIXME: Once the notification service is refactored, then there shouldn't be a timeout
			setTimeout(() => this.buildNotification('notification:message:deleteMessageTitle', this.NAMESPACE + '.deleteMessage'), 100);
		}
		if (this.isSavedSuccessful === true) {
			setTimeout(() => this.buildNotification('notification:message:savedMessageTitle', 'notification:message:shoppingBasketSuccessNotification'), 100);
		}
	}

	get paymentOperation() {
		return this._paymentOperation;
	}

	/**
	 * Creates a notification message based on the provided title and message
	 * @param title title of the message
	 * @param message message
	 */
	private buildNotification(title: string, message: string): void {
		this.notificationMessageService.sendMessage(<NotificationMessage>{
			title: title,
			type: FeedbackTypes.FRONTEND_SUCCESS,
			message: message,
			scrollToMe: true,
			clearAll: false,
			hasTimeout: true,
			timeOut: 5000,
			isClosable: true,
		});
	}

	private retrieve(service: any, paymentType?: PaymentTypes): void {
		service.get(this.operationReference, paymentType).subscribe(
			response => this._paymentOperation = response.getModel(),
			serviceResponseError => this.treatResponseError(serviceResponseError),
		);
	}

	private treatResponseError(serviceResponseError: ServiceResponse<any>) {
		this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
		this.error = true;
		this._paymentOperation = new PaymentOperation<any>();
	}

	private saveDelete(service: any): void {
		service
			.confirmDelete(this.signablePaymentOperation)
			.subscribe(
				() => {
					this.isSavedSuccessful = true;
					this.isConfirmationOngoing = false;
					this.isDeletionOngoing = false;
					this.goBack();
				},
				(error: ServiceResponse<null>) => {
					this.serviceResponseNotifMapper.sendResponseFeedbacks(error);
				},
			);
	}

	private signDelete(service: any): void {
		service
			.signDelete(this.signablePaymentOperation)
			.subscribe(
				() => {
					this.isDeleteSuccessful = true;
					this.isDeletionOngoing = false;
					this.goBack();
				},
				(error: ServiceResponse<null>) => {
					this.serviceResponseNotifMapper.sendResponseFeedbacks(error);
				},
			);
	}

	private deletePayment(service: any): void {
		service.delete(this.operationReference).subscribe(
			(serviceResponse: ServiceResponse<SignableOperation<PaymentOperation<null>>>) => {
				this.serviceResponseNotifMapper.sendResponseFeedbacks(
					serviceResponse,
					this.NAMESPACE,
				);
				this.isDeletionOngoing = false;
				this.signablePaymentOperation = serviceResponse.getModel();
				this.cacheService.invalidate('pending-payments');
				if (this.signablePaymentOperation.status === Status.ToSign) {
					this.isSignable = true;
				} else {
					service.confirmDelete(this.signablePaymentOperation).subscribe(
						() => {
							this.isDeleteSuccessful = true;
							this.goBack();
						},
						(serviceResponseError: ServiceResponse<null>) => {
							this.serviceResponseNotifMapper.sendResponseFeedbacks(
								serviceResponseError,
								this.NAMESPACE,
							);
							this.isDeletionOngoing = false;
						},
					);
				}
			},
			(serviceResponseError: ServiceResponse<null>) => {
				this.serviceResponseNotifMapper.sendResponseFeedbacks(
					serviceResponseError,
					this.NAMESPACE,
				);
				this.isDeletionOngoing = false;
			},
		);
	}
}
