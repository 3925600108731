import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { StylingModule } from '@mdib/styling';
import { SignatureModule } from '@mdib/signature';
import { UtilsModule } from '@mdib/utils';

import { OperationPlaybarComponent } from './components/operation-playbar/operation-playbar.component';
import { OperationConfirmationStepComponent } from './components/operation-confirmation-step/operation-confirmation-step.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		StylingModule,
		SignatureModule,
		TranslateModule,
		UtilsModule,
	],
	declarations: [
		OperationPlaybarComponent,
		OperationConfirmationStepComponent,
	],
	exports: [
		OperationPlaybarComponent,
		OperationConfirmationStepComponent,
	]
})
export class AdaptersModule { }
