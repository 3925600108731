import { SepaDirectDebitPaymentType } from '@mdib/sepa-direct-debits';

export class XclSepaDirectDebitPaymentType {
	private static mapper = {
		'10': SepaDirectDebitPaymentType.SingleWithdrawal,
		'11': SepaDirectDebitPaymentType.FirstWithdrawal,
		'12': SepaDirectDebitPaymentType.Withdrawal,
		'13': SepaDirectDebitPaymentType.LastWithdrawal,
		'14': SepaDirectDebitPaymentType.IndividualB2BDebit,
		'15': SepaDirectDebitPaymentType.FirstB2BDebit,
		'16': SepaDirectDebitPaymentType.B2BDebit,
		'17': SepaDirectDebitPaymentType.LastB2BDebit,
		'21': SepaDirectDebitPaymentType.LoanRepayment,
		'OV': SepaDirectDebitPaymentType.GovernmentClaim
	};

	/**
	 * Converts XCL sepa direct debit payment type to functional sepa direct debit payment type
	 * @param {string} [type] XCL sepa direct debit payment type
	 * @returns {SepaDirectDebitPaymentType} sepa direct debit payment type
	 * @memberof XclSepaDirectDebitPaymentType
	 */
	public static fromXclType(type: string): SepaDirectDebitPaymentType {
		return XclSepaDirectDebitPaymentType.mapper[type] === undefined ? SepaDirectDebitPaymentType.Unknown : XclSepaDirectDebitPaymentType.mapper[type];
	}
}
