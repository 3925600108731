export enum SepaDirectDebitPaymentType {
	SingleWithdrawal = 'singleWithdrawal',
	FirstWithdrawal = 'firstWithdrawal',
	Withdrawal = 'withdrawal',
	LastWithdrawal = 'lastWithdrawal',
	IndividualB2BDebit = 'individualB2BDebit',
	FirstB2BDebit = 'firstB2BDebit',
	B2BDebit = 'b2BDebit',
	LastB2BDebit = 'lastB2BDebit',
	LoanRepayment = 'loanRepayment',
	GovernmentClaim = 'governmentClaim',
	Unknown = 'unknown',
}
