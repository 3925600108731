import { Inject, NgModule } from '@angular/core';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';

import { AdaptersService } from '@mdib/core/adapters';
import { InstantPaymentsService, InternationalPaymentsService, PaymentsService, StandingOrderService } from '@mdib/payments';

import { PaymentMemoryService } from './service/payment-memory.service';
import { StandingOrderMemoryService } from './service/standing-order-memory.service';
import { InternationalPaymentsMemoryService } from './service/international-payments-memory.service';
import { InstantPaymentsMemoryService } from './service/instant-payments-memory.service';

@NgModule()
export class PaymentsMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(PaymentsService, adapterId, PaymentMemoryService);
		adaptersService.setAdapter(StandingOrderService, adapterId, StandingOrderMemoryService);
		adaptersService.setAdapter(InternationalPaymentsService, adapterId, InternationalPaymentsMemoryService);
		adaptersService.setAdapter(InstantPaymentsService, adapterId, InstantPaymentsMemoryService);
	}
}
