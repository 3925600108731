import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { ServiceResponse, ChartTypes, UtilsHelper, ChartsConfig } from '@mdib/utils';
import { ConfigService, ManagedCurrencyCodes } from '@mdib/config';
import * as moment from 'moment/moment';

import { CashAccountOperationService } from '../../service/cash-account-operation.service';
import { CashAccountOperation } from '../../model/cash-account-operation';
import { CashAccount } from '../../model/cash-account';
import { CashAccountService } from '../../service/cash-account.service';
import { CashAccountTypesGroup, LineChartDisplayType } from '../../model/cash-accounts.enum';
import { TranslateService } from '@ngx-translate/core';
import { polyfill } from 'mobile-drag-drop';
import { filter, map } from 'rxjs/operators';
import { CashAccountTypeParameter } from '@mdib/cash-accounts';
import { ParameterSetsService } from '@mdib/core/customization';
import { zip } from 'rxjs';

/**
 * Displays a list of {@link CashAccountListItemComponent}
 * @example
 * <mdib-cash-accounts-list [cashAccountTypesGroup]="CashAccountTypesGroup"></mdib-cash-accounts-list>
 */
@Component({
	selector: 'mdib-cash-accounts-list',
	templateUrl: './cash-accounts-list.component.html',
	styleUrls: ['./cash-accounts-list.component.scss'],
})
export class CashAccountsListComponent implements OnInit {
	readonly NAMESPACE = 'cashAccountsModule.cashAccountsListComponent.';
	baseCurrencyCode: ManagedCurrencyCodes;
	totalAmount: number;
	/**
	 * Group of cash account types to display
	 */
	@Input() cashAccountTypesGroup: CashAccountTypesGroup;

	/**
	 * Show favorite accounts only or all accounts
	 */
	@Input() showFavoriteAccountsOnly = false;

	@Output() updatedAccountList = new EventEmitter<CashAccount[]>();
	@Output() isAccountOrderUpdated = new EventEmitter();

	/**
	 * List of cash accounts, provided by the CashAccountService
	 */
	cashAccounts: CashAccount[] = [];

	cashAccountsLoaded = false;

	/**
	 * Wording for the group of cash account types
	 */
	cashAccountTypesGroupWording: string;

	expandedAccountNumber = '';
	displayMode = 'list';
	CashAccountTypesGroup = CashAccountTypesGroup;
	maxResults = 0;
	readonly ID_PREFIX = Math.random() + '_';

	// Input for chart data
	chartData = [];
	chartLabel = [];
	xAxisLabel: string[];
	chartType: ChartTypes;

	lineChartDisplayTypes = Object.values(LineChartDisplayType);
	displayType = LineChartDisplayType.ByMonth;

	// Indicates whether the chart data is calculated
	onGoingCalculationForChartData = false;

	cashAccountToOperationsMap: Map<CashAccount, CashAccountOperation[]>;

	constructor(
		private cashAccountService: CashAccountService,
		private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService,
		private configService: ConfigService,
		private cashAccountOperationService: CashAccountOperationService,
		private translateService: TranslateService,
		private parameterSetsService: ParameterSetsService,
	) {
		this.baseCurrencyCode = configService.baseCurrencyCode;
		polyfill({
			forceApply: true,
			holdToDrag: 300
		});
	}

	ngOnInit() {
		this.defineCashAccountTypesGroupWording();
		this.loadCashAccounts();
	}

	loadCashAccounts() {
		this.cashAccountsLoaded = false;
		// Load the Account Types in a map
		const getParams = this.parameterSetsService.get('cash-account-types').pipe(map((params: CashAccountTypeParameter[]) => {
			return params.reduce((o, p) => { o[p.key] = p; return o; }, {});
		}));

		zip(getParams, this.cashAccountService.list())
			.pipe(filter((response) => !UtilsHelper.nullOrUndefined(response[1])))
			.subscribe((results) => {
				const cashAccountTypes = results[0];
				const serviceResponse = results[1];
				this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponse, this.NAMESPACE);
				const accounts = serviceResponse.getModel() || [];
				// Load the cash accounts and filter them depending on the group of cash account types handled by this list.

				this.cashAccounts = accounts.filter(cashAccount => {
					const cashAccountTypeParam = cashAccountTypes[cashAccount.type];
					switch (this.cashAccountTypesGroup) {
						case CashAccountTypesGroup.CurrentAccounts: {
							// For a "current accounts" group, we only show the accounts of type "CurrentAccount"
							return cashAccountTypeParam ?
								cashAccountTypeParam.cashAccountTypesGroup === CashAccountTypesGroup.CurrentAccounts :
								false;
						}
						case CashAccountTypesGroup.SavingsAndInvestmentsAccounts: {
							// For a "savings and investments accounts" group, we show the accounts of types "SavingsAccount" and "NoticeAccount"
							return cashAccountTypeParam ?
								cashAccountTypeParam.cashAccountTypesGroup === CashAccountTypesGroup.SavingsAndInvestmentsAccounts :
								false;
						}
					}
				});

				// TODO: To remove when the cash account service will be adapted
				if (this.showFavoriteAccountsOnly) {
					this.cashAccounts = this.cashAccounts.slice(0, 4);
				}

				this.updatedAccountList.emit(this.cashAccounts);
				this.cashAccountsLoaded = true;
				this.totalAmount = this.cashAccountService.getTotalAmount(this.cashAccounts);
			},
				(serviceResponseError: ServiceResponse<null>) =>
					this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE),
			);
	}

	/**
	 * Define the wording of the account types group
	 */
	defineCashAccountTypesGroupWording() {
		switch (this.cashAccountTypesGroup) {
			case CashAccountTypesGroup.CurrentAccounts: {
				this.cashAccountTypesGroupWording = this.NAMESPACE + 'currentAccountsLabel';
				break;
			}
			case CashAccountTypesGroup.SavingsAndInvestmentsAccounts: {
				this.cashAccountTypesGroupWording = this.NAMESPACE + 'savingAccounts';
				break;
			}
		}
	}

	isDisplayMode(mode: string): boolean {
		return this.displayMode === mode;
	}

	/**
	 * Switches display to chart mode and loads the chart data, if the display wasn't already in chart mode
	 */
	displayChart(chartType: string): void {
		if (this.displayMode !== chartType) {
			this.displayMode = chartType;
			this.cashAccountTypesGroup === CashAccountTypesGroup.SavingsAndInvestmentsAccounts ? this.loadChartDataForSavingsAndInvestmentsAccounts() : this.loadChartDataForCurrentAccounts();
		}
	}

	/**
	 * Loads the chart data for savings and investment accounts
	 */
	loadChartDataForSavingsAndInvestmentsAccounts(): void {
		this.chartData = [];
		this.chartLabel = [];

		this.chartType = ChartTypes.pie;
		// Filter the accounts with valuation currency as base currency and then retrieve the chart data
		this.cashAccounts.filter(account => account.valuationCurrency === this.baseCurrencyCode).forEach(account => {
			this.chartData.push(account.currentBalance);
			this.chartLabel.push(account.clientWording);
		});
	}

	/**
	 * Loads the chart data for current accounts
	 */
	loadChartDataForCurrentAccounts(): void {
		this.chartData = [];
		this.chartLabel = [];

		this.chartType = ChartTypes.line;
		this.onGoingCalculationForChartData = true;

		// Filter account with the valuation currency as the base currency and fetch their accounting history
		if (UtilsHelper.nullOrUndefined((this.cashAccountToOperationsMap))) {

			// This field will save the cash account to operations map
			this.cashAccountToOperationsMap = new Map<CashAccount, CashAccountOperation[]>();
			// Filter cash account with valuation currency as the base currency
			const filteredCashAccount: CashAccount[] = this.cashAccounts.filter((cashAccount: CashAccount) => cashAccount.valuationCurrency === this.baseCurrencyCode);

			// This variable will be used to check if the calculation for all the cash account is done
			let cashAccountNumber = 0;
			// Loop over all the cash accounts to fetch operations and calculate chart data
			filteredCashAccount.forEach((cashAccount: CashAccount) => {
				this.chartLabel.push(cashAccount.clientWording);
				this.cashAccountOperationService
					.list(cashAccount.number, 0, this.maxResults)
					.subscribe((serviceResponse: ServiceResponse<CashAccountOperation[]>) => {
						this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponse, this.NAMESPACE);
						const operations = serviceResponse.getModel();

						// Put the operations retrieved in the map
						this.cashAccountToOperationsMap.set(cashAccount, operations);

						this.calculateChartData(cashAccount, operations);
						// Check if this was the last account for which the accounting operations were retrieved
						// this.onGoingCalculationForChartData = !(++cashAccountNumber === filteredCashAccount.length);
						if (++cashAccountNumber === filteredCashAccount.length) {
							this.onGoingCalculationForChartData = false;
							if (this.chartData.length > 0) {
								this.chartData.push(this.calculateTotalBalanceForEachInterval(this.chartData));
								this.chartLabel.push(this.translateService.get(this.NAMESPACE + 'total').subscribe(label => this.chartLabel.push(label)));
							}
						}
					},
						(serviceResponseError: ServiceResponse<null>) => {
							this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
							// Check if this was the last account for which the accounting operations were retrieved
							this.onGoingCalculationForChartData = !(++cashAccountNumber === filteredCashAccount.length);
						});
			});
		} else {
			// The following will be executed when the accounting operations of accounts is already fetched
			this.cashAccountToOperationsMap.forEach((operations: CashAccountOperation[], cashAccount: CashAccount) => {
				this.chartLabel.push(cashAccount.clientWording);
				this.calculateChartData(cashAccount, operations);
			});
			if (this.chartData.length > 0) {
				this.chartData.push(this.calculateTotalBalanceForEachInterval(this.chartData));
				this.chartLabel.push(this.translateService.get(this.NAMESPACE + 'total').subscribe(label => this.chartLabel.push(label)));
			}
			this.onGoingCalculationForChartData = false;
		}
	}

	/**
	 * Calculates chart data for a provided cash account
	 * @param CashAccount: cash account for which the chart data is to be calculated
	 * @param CashAccountOperation[]: accounting operations of the provided cash account
	 * @returns number[]: monthly chart data for the provided account
	 */
	calculateChartData(cashAccount: CashAccount, operations: CashAccountOperation[]): void {
		// This field will hold the chart data for a particular account
		const chartDataForCashAccount = this.displayType === LineChartDisplayType.ByMonth ?
			this.calculateChartDataByMonth(cashAccount, operations)
			: this.calculateChartDataByWeek(cashAccount, operations);

		// Calculated balance of a particular account is pushed in chart data
		this.chartData.push(chartDataForCashAccount);
	}

	/**
	 * Calculates monthly chart data for a provided cash account
	 * @param CashAccount: cash account for which the chart data is to be calculated
	 * @param CashAccountOperation[]: accounting operations of the provided cash account
	 * @returns number[]: monthly chart data for the provided account
	 */
	calculateChartDataByMonth(cashAccount: CashAccount, operations: CashAccountOperation[]): number[] {

		const chartDataForCashAccount = [];
		this.xAxisLabel = [];
		// Current month's balance shown in the graph will be equal to the current balance of the account at this moment
		let balanceAtMonthEnd = cashAccount.currentBalance;
		chartDataForCashAccount.push(balanceAtMonthEnd);

		let monthNumber = 0;
		const calculationDate = new Date();

		// On the X- Axis the name of month's will appear
		this.xAxisLabel.push(moment.monthsShort(calculationDate.getMonth()));

		// Calculation of opening balance of each month which will be equal to the balance at previous month's end
		while (monthNumber < ChartsConfig.lineChartMonths - 1) {
			calculationDate.setMonth(new Date().getMonth() - monthNumber);
			let monthOperations = [];
			if (!UtilsHelper.nullOrUndefined(operations)) {
				monthOperations = operations.filter(operation =>
					operation.date.getFullYear() === calculationDate.getFullYear() &&
					operation.date.getMonth() === (calculationDate.getMonth() + 1),
				);
			}
			// Balance at previous month's end will be equal to opening balance of current month
			balanceAtMonthEnd = this.calculateOpeningBalance(monthOperations, balanceAtMonthEnd);
			chartDataForCashAccount.push(balanceAtMonthEnd);
			// Push the name of previous month as label for X Axis
			this.xAxisLabel.push(moment.monthsShort(calculationDate.getMonth() - 1));
			monthNumber++;
		}
		// Chronologically set the data
		this.xAxisLabel.reverse();
		return chartDataForCashAccount.reverse();
	}

	/**
	 * Calculates weekly chart data for a provided cash account
	 * @param CashAccount: cash account for which the chart data is to be calculated
	 * @param CashAccountOperation[]: accounting operations of the provided cash account
	 * @returns number[]: weekly chart data for the provided account
	 */
	calculateChartDataByWeek(cashAccount: CashAccount, operations: CashAccountOperation[]) {
		const chartDataForCashAccount = [];
		this.xAxisLabel = [];
		// Current week's balance shown in the graph will be equal to the current balance of the account at this moment
		let balanceAtWeekEnd = cashAccount.currentBalance;
		chartDataForCashAccount.push(balanceAtWeekEnd);

		// On the X- Axis of the graph the date will appear in DD MMM format
		this.xAxisLabel.push(moment().format('DD MMM'));

		let weekNumber = 0;
		let previousDate = moment();

		while (weekNumber < ChartsConfig.lineChartWeeks - 1) {
			// Calculation date is the previous Saturday(week's end day)
			// The following logic will retieve the date of previous Saturday
			const calculationDate = moment().day(-1 - (weekNumber * 7));

			let weekOperations = [];
			if (!UtilsHelper.nullOrUndefined(operations)) {
				weekOperations = operations.filter(operation =>
					moment(operation.date).isAfter(calculationDate) &&
					moment(operation.date).isSameOrBefore(previousDate),
				);
			}

			// Balance at previous week's end will be equal to opening balance of current week
			balanceAtWeekEnd = this.calculateOpeningBalance(weekOperations, balanceAtWeekEnd);
			chartDataForCashAccount.push(balanceAtWeekEnd);

			// Push the date of previous weekend as label for X Axis
			this.xAxisLabel.push(calculationDate.format('DD MMM'));

			previousDate = calculationDate;
			weekNumber++;
		}
		// Chronologically set the data
		this.xAxisLabel.reverse();
		return chartDataForCashAccount.reverse();
	}

	/**
	 * Calculates opening balance of the month , on the basis of provided cash account operations
	 * @param CashAccountOperation[]: cash account operations of the provided month
	 * @param number: balance at the provided month's end
	 * @returns number: opening balance of the month
	 */
	calculateOpeningBalance(monthOperations: CashAccountOperation[], balanceCurrentMonth: number): number {
		if (!UtilsHelper.nullOrUndefined(monthOperations)) {
			monthOperations.forEach(operation => {
				balanceCurrentMonth = balanceCurrentMonth - operation.amount;
			});
		}
		return balanceCurrentMonth;
	}

	/**
	 * Calculates total balance for each interval (one week, or one month)
	 * @param number[][]: balance for each interval for each account
	 * @returns: total balance of all the accounts for each interval
	 */
	calculateTotalBalanceForEachInterval(balanceByInterval: number[][]): number[] {
		const total: number[] = [];
		if (balanceByInterval.length !== 0 && balanceByInterval[0].length !== 0) {
			balanceByInterval.forEach((balance: number[]) => {
				balance.forEach((amount: number, index: number) => total[index] = UtilsHelper.nullOrUndefined(total[index]) ? amount : total[index] + amount);
			},
			);
		}
		return total;
	}


	onMove($event) {
		this.isAccountOrderUpdated.emit();
		this.updatedAccountList.emit(this.cashAccounts);
	}

	dragenter(ev) {
		event.preventDefault();
	}
}
