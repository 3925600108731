import { Injectable } from '@angular/core';

@Injectable()
export class ExchangeRateService {
	private readonly stubRate = 1.1787;

	getCurrencyExchangeFromTo(currencyFrom: string, currencyTo: string): number {
		return this.stubRate;
	}
}
