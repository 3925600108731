import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StylingModule } from '@mdib/styling';
import { FooterComponent } from './component/footer/footer.component';
// Third party
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		StylingModule
	],
	declarations: [
		FooterComponent
	],
	exports: [
		FooterComponent,
	],
})
export class FooterModule { }
