import { Injectable } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';

import { ConfigurationService } from '@mdib/utils';

import { FormGroupBuilder } from './form-group.builder';
import { CommunicationTypes } from '../../model/commons.enum';
import { CommunicationFormGroup } from './communication-form-group';

@Injectable({
	providedIn: 'root',
})
export class CommunicationFormBuilder implements FormGroupBuilder {

	constructor(
		private configurationService: ConfigurationService,
	) { }

	build(): CommunicationFormGroup {
		const formGroup = new CommunicationFormGroup({
			type: new FormControl(CommunicationTypes.free),
			value: new FormControl(),
		});

		formGroup.valueChanges.subscribe(value => {
			// Set the maximum length of the value depending on the type of communication selected
			const type: string = value.type || 'free';
			const maxLength = this.configurationService.instant('commons.fields.communication_' + type + '.maxLength');
			formGroup.get('value').setValidators([Validators.maxLength(maxLength)]);
		});

		return formGroup;
	}
}
