import { Injectable } from '@angular/core';
import { CashAccountOrder } from '@mdib/cash-accounts/model/cash-account-order';
import { CashAccountSortOrder} from '@mdib/cash-accounts/model/cash-account-sort-order';
import { XclCashAccountOrderModel } from '../model/xcl-cash-account-order-model';
import { XclCashAccountSortOrderModel } from '../model/xcl-cash-account-sort-order-model';

@Injectable()
export class CashAccountSortOrderMapperXclService {
	/**
	 * Parse technical model in functional model
	 * @param XclAccountSortOrderModel : source format.
	 * @returns AccountSortOrder : parsed functional model format.
	 */
	getFunctionalModelFromTechnicalModel(xclAccountSortOrderModel: XclCashAccountSortOrderModel): CashAccountSortOrder {
		const accountSortOrder = new CashAccountSortOrder();
		xclAccountSortOrderModel.listAccountFreeSortOrder = this.getFunctionalModelArrayFromTechnicalModelArray(xclAccountSortOrderModel.listAccountFreeSortOrder);
		return accountSortOrder;
	}
	/**
	 * Converts the technical model array into functional model array
	 * @param technicalModelArray : source format array.
	 * @returns AccountOrder[] : functional model format array.
	 */
	getFunctionalModelArrayFromTechnicalModelArray(technicalModelArray: XclCashAccountOrderModel[]): CashAccountOrder[] {
		if (!technicalModelArray) {
			console.error('Invalid list of accounts returned by the XCL: ' + JSON.stringify(technicalModelArray));
			return [];
		}
		const functionalModelArray: CashAccountOrder[] = [];
		technicalModelArray.forEach((technicalModel: XclCashAccountOrderModel) => {
			const accountOrder = new CashAccountOrder();
			accountOrder.accountNumber = technicalModel.accountNumber;
			functionalModelArray.push(accountOrder);
		});
		return functionalModelArray;
	}
	/**
	 * Convert functional model in technical model
	 * @param AccountSortOrder : source format.
	 * @returns XclAccountSortOrderModel : converted technical model format.
	 */
	getTechnicalModelFromFunctionalModel(accountSortOrder: CashAccountSortOrder): XclCashAccountSortOrderModel {
		const xclAccountSortOrderModel: XclCashAccountSortOrderModel = {
			principalIdentification: '',
			identifier: '',
			listAccountFreeSortOrder: this.getTechnicalModelArrayFromFunctionalModelArray(accountSortOrder.listAccountFreeSortOrder)
		};
		return xclAccountSortOrderModel;
	}
	/**
	 * Convert functional model in technical model
	 * @param AccountOrder : source format.
	 * @returns XclAccountOrderModel : converted technical model format.
	 */
	getTechnicalModelArrayFromFunctionalModelArray(functionalModelArray: CashAccountOrder[]): XclCashAccountOrderModel[] {
		if (!functionalModelArray) {
			console.error('Invalid list of accounts returned by the front: ' + JSON.stringify(functionalModelArray));
			return [];
		}
		const technicalModelArray: XclCashAccountOrderModel[] = [];
		functionalModelArray.forEach((functionalModel: CashAccountOrder) => {
			const xclAccountOrder: XclCashAccountOrderModel = {
				accountNumber: functionalModel.accountNumber,
			};
			technicalModelArray.push(xclAccountOrder);
		});
		return technicalModelArray;
	}
}
