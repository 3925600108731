import { Beneficiary } from '@mdib/beneficiaries';
import { CashAccount } from '@mdib/cash-accounts';
import { OperationStatus, PaymentTypes } from '@mdib/payments';
import { Signable, Signature } from '@mdib/signature';
import { SignatureModes } from '@mdib/signature-modes';
import { Status } from '@mdib/utils';

export class PaymentOperation<T> implements Signable {

	amount: number;
	currency: string;
	paymentDate: Date;
	operationDate: Date;

	saveBeneficiary: boolean;
	ordererAccount: CashAccount;
	ordererName: string;

	reference: string;
	status: Status;
	operationStatus: OperationStatus;

	signature: Signature = null;
	signatureTypesAllowed: SignatureModes[] = [];
	signatureContext: any = null;

	type: PaymentTypes;
	isUrgent: boolean;

	private _counterParty: Beneficiary;
	private _executionDetails?: T;

	constructor(payment?: Partial<PaymentOperation<T>>) {
		Object.assign(this, payment);
	}

	public isSignatureTypeAllowed(signatureType: any): boolean {
		return (!this.signatureTypesAllowed ? false : this.signatureTypesAllowed.indexOf(signatureType) >= 0);
	}

	public isSigned(): boolean {
		return !!this.signature;
	}

	public addSignature(signature: Signature): void {
		this.signature = signature;
	}

	public getSignatureContext(): any {
		return this.signatureContext;
	}

	get executionDetails(): T {
		return this._executionDetails;
	}

	set executionDetails(details: T) {
		this._executionDetails = details;
	}

	get counterParty(): Beneficiary {
		return this._counterParty;
	}

	set counterParty(counterParty: Beneficiary) {
		this._counterParty = counterParty;
	}
}
