import { Injectable, Inject, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TRANSLATIONS } from '../../types/tokens/translate-tokens';
import { LocalizationService } from '../localization/localization.service';
import { TranslationLoaderService } from './translation-loader.service';
import { TranslationParserService } from './translation-parser.service';

@Injectable()
export class TranslationsService {

	private localizedTranslations: { [language: string]: any } = {};

	private onChangeEvents = new Subject<string>();

	constructor(
		@Inject(TRANSLATIONS) @Optional() private defaultTranslations: any = {},
		private translationParser: TranslationParserService,
		private translationLoader: TranslationLoaderService,
		private localizationService: LocalizationService,
		private translateService: TranslateService,
	) {
		localizationService.onChange.subscribe(language => this.load(language).subscribe());
	}

	/**
	 * Returns the translation
	 * @param key The key to translate, in the form `path.to.some.precise:token`
	 * @param params Parameters to use for the string interpolation.
	 * @param language Language to look for, default is the curent selected one.
	 */
	public translate(key: string, params?: any, language?: string): string | any {
		// Look for the translation in the selected language
		language = language || this.localizationService.language;
		let translated = this.translationParser.parse(this.localizedTranslations[language], key);
		if (!translated) {
			translated = this.translationParser.parse(this.defaultTranslations, key);
		}

		if (!translated) {
			return this.translateService.instant(key, params);
		}

		// Interpolate the translation tring with the parameters
		if (typeof translated === 'string') {
			translated = this.translationParser.interpolate(translated, params);
		}

		// Handle missing translation
		if (translated === null || translated === undefined) {
			return key;
		}
		return translated;
	}

	public load(language: string): Observable<string> {
		return this.translationLoader.load(language).pipe(tap(translations => {
			this.localizedTranslations[language] = translations;
			this.onChangeEvents.next(language);
		}));
	}

	public get onChange(): Observable<string> {
		return this.onChangeEvents.asObservable();
	}

}
