import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, combineLatest } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { AuthenticationModes, Session, SessionsService, SessionTimeoutService, SessionBackgroundTimeoutService } from '@mdib/sessions';
import { LocalizationService } from '@mdib/core/customization';
import { IconService, SidenavService, Themes, ThemeService } from '@mdib/styling';
import { ConfigurationService, UtilsHelper } from '@mdib/utils';
import { CordovaPluginIrootService, CordovaService, CordovaPluginDeviceService, CordovaPluginIsDebugService } from '@mdib/cordova';

import { MockUsers } from '@mdib-memory/sessions';

import * as fallback from 'assets/fallback.json';

@Component({
	selector: 'app-root',
	templateUrl: './app-root.component.html',
	styleUrls: ['./app-root.component.scss'],
})
export class AppRootComponent implements OnDestroy {

	public readonly NAMESPACE = 'AppModule.AppRootComponent.';
	public appTheme: string = Themes.b2c;

	public isApplicationOutdated = false;
	public isApplicatonUnsecure = null;

	@ViewChild('sidenav') public sidenav: MatSidenav;

	private sidenavToggle = false;
	private subscription: Subscription;
	private readonly activeSession: Session;

	constructor(
		private router: Router,
		private translate: TranslateService,
		private configurationService: ConfigurationService,
		private sessionsService: SessionsService,
		private sessionTimeoutService: SessionTimeoutService,
		private sessionBackgroundTimeoutService: SessionBackgroundTimeoutService,
		private iconService: IconService,
		private sidenavService: SidenavService,
		private themeService: ThemeService,
		private cordovaService: CordovaService,
		private cordovaPluginIrootService: CordovaPluginIrootService,
		private cordovaPluginDevice: CordovaPluginDeviceService,
		private cordovaPluginIsDebugService: CordovaPluginIsDebugService,
		localizationService: LocalizationService,
	) {
		this.iconService.supplySvgIcons(AuthenticationModes);

		// Sessions Management
		this.sessionTimeoutService.init();
		cordovaService.deviceReady.subscribe(
			(isCordovaReady: boolean) => {
				if (isCordovaReady) {
					this.sessionBackgroundTimeoutService.init(this.cordovaService.onPause, this.cordovaService.onResume);
				}
			}
		);

		this.sessionsService.loadSessions();
		this.activeSession = this.sessionsService.getSession();

		if (this.isMock()) {
			configurationService.set('services.activeId', 'memory');
		}
		this.themeService.theme.subscribe(theme => this.appTheme = theme);

		// Handle routing
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				UtilsHelper.scrollTo();
				this.sidenavService.state = false;
			}
		});

		// We load the fallback translations.
		this.translate.setTranslation('fallback', fallback);
		this.translate.setDefaultLang('fallback');

		// The lang to use, if the lang isn't available, it will use the current loader to get them.
		this.translate.use('fallback').pipe(delay(500)).subscribe(() => {
			const defaultLanguage = this.configurationService.instant('localisation.language.default');
			localizationService.language = defaultLanguage;
			translate.getTranslation(defaultLanguage).subscribe(() => {
				return translate.use(defaultLanguage);
			});
		});

		// Check the application version and security
		this.checkApplicationVersion();
		this.detectUnsecureDevice();

		/*** Will Subscribe the menu click of header to open the sidenav */
		this.subscription = this.sidenavService.sidenavToggle.subscribe(isSidenavOpen =>
			(this.sidenavToggle = isSidenavOpen) ? this.sidenav.open() : this.sidenav.close());
	}

	public get screenToDisplay(): string {
		if (this.isAppUnderMaintenance()) {
			return 'maintenance';
		} else if (this.isDeviceVirtual() || this.isApplicatonUnsecure) {
			return 'unsecured';
		} else if (this.isApplicationOutdated) {
			return 'outdated';
		} else {
			return 'application';
		}
	}

	public closeSidenav() {
		this.sidenavService.state = false;
	}

	public ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	private isAppUnderMaintenance(): boolean {
		return this.configurationService.instant('application.maintenance.active');
	}

	private detectUnsecureDevice() {
		const allowRootedDevices: boolean = this.configurationService.instant('mobile.security.allowRootedDevices');
		const allowDebugMode: boolean = this.configurationService.instant('mobile.security.allowDebugMode');
		combineLatest(
			this.cordovaPluginIrootService.isRooted(),
			this.cordovaPluginIsDebugService.isDebug(),
		).pipe(map(([isRooted, isDebug]) => {
			return (!allowRootedDevices && isRooted) || (!allowDebugMode && isDebug);
		})).subscribe(unsecured => {
			this.isApplicatonUnsecure = unsecured;
		});
	}

	private isDeviceVirtual(): boolean {
		return this.cordovaPluginDevice.isVirtual;
	}

	private isMock(): boolean {
		return this.activeSession && this.activeSession.activeUser && (Object.values(MockUsers).indexOf(this.activeSession.activeUser.id) >= 0);
	}

	private checkApplicationVersion() {
		const curentVersion = this.configurationService.instant('application.version.curent');
		const minimumVersion = this.configurationService.instant('application.version.minimum');
		this.isApplicationOutdated = minimumVersion && curentVersion < minimumVersion;
	}
}
