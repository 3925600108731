import { Injectable } from '@angular/core';

import { CashAccount } from '@mdib/cash-accounts';

import { MapperPartialService } from '@mdib-xcl/core';

import { XclBeneficiaryModel } from '../../model/xcl-beneficiary-model';

@Injectable({
	providedIn: 'root'
})
export class BeneficiaryAccountMapperService extends MapperPartialService<CashAccount, XclBeneficiaryModel> {

	public fromXcl(value: XclBeneficiaryModel): CashAccount {
		return new CashAccount({
			number: value.beneficiaryAccountNumber,
			bic: value.bankBicCode ? value.bankBicCode.toUpperCase() : ''
		});
	}

	public toXcl(value: CashAccount, target?: XclBeneficiaryModel): Partial<XclBeneficiaryModel> {
		target = target || <any>{};
		target.beneficiaryAccountNumber = value.number;
		this.setIfDefined(target, 'bankBicCode', value.bic.toUpperCase());
		return target;
	}
}
