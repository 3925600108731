import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BeneficiaryV2 as Beneficiary } from '@mdib/beneficiaries';

import { BackendUpdateOperationXcl } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';

import { XclBeneficiaryModel } from '../../model/xcl-beneficiary-model';
import { BeneficiaryMapperService } from '../mappers/beneficiary-mapper.service';

export class BeneficiaryUpdateXclOperation extends BackendUpdateOperationXcl<Beneficiary, Beneficiary, XclBeneficiaryModel> {

	protected xclEndpoint = 'xcl.beneficiaries.generic';

	private beneficiaryMapperService: BeneficiaryMapperService;

	// Dependencies
	public constructor(injector: Injector) {
		super(injector);

		this.beneficiaryMapperService = injector.get(BeneficiaryMapperService);
	}

	protected getItemIdentifier(input: Beneficiary) {
		return input.id;
	}

	protected getInitData(input: Beneficiary): XclBeneficiaryModel {
		return null;
	}

	protected getValidateData(input: Beneficiary): XclBeneficiaryModel {
		const xclInput = this.beneficiaryMapperService.toXcl(input);
		xclInput.principalIdentification = this.sessionUtilsService.getSessionActiveUserId();
		delete xclInput.beneficiaryType;
		return xclInput;
	}

	protected handleResponse(order: MonogoalOrderXCLModel<XclBeneficiaryModel>): Observable<Beneficiary> {
		return super.handleResponse(order).pipe(map(() => {
			return this.beneficiaryMapperService.fromXcl(order.goal);
		}));
	}

}
