import { LanguageCodes } from '@mdib/config';
import { NaturalPersonBuilder, NaturalPersonGender, NaturalPersonMaritalStatus } from '@mdib/customers';

export const activeUserPerson = new NaturalPersonBuilder({
	id: '1',
})
	.setName('Mr. John Doe')
	.setBirthCity('Brussels')
	.setBirthDate(new Date(1980, 1, 1))
	.setBirthCountry('BE')
	.setFirstName('John')
	.setLastName('Doe')
	.setLanguage(LanguageCodes.fr)
	.setMaritalStatus(NaturalPersonMaritalStatus.Single)
	.setNationality('BE')
	.setGender(NaturalPersonGender.Male)
	.build();

export const activeUserPersonMandate1 = new NaturalPersonBuilder({
	id: '2',
})
	.setName('Ms. Caroline Herschel')
	.setBirthCity('Hannover')
	.setBirthDate(new Date(1960, 3, 16))
	.setBirthCountry('BE')
	.setFirstName('Caroline')
	.setLastName('Herschel')
	.setLanguage(LanguageCodes.en)
	.setMaritalStatus(NaturalPersonMaritalStatus.Widowed)
	.setNationality('BE')
	.setGender(NaturalPersonGender.Female)
	.build();

export const activeUserPersonMandate2 = new NaturalPersonBuilder({
	id: '3',
})
	.setName('Mr. Enrico Fermi')
	.setBirthCity('Brussels')
	.setBirthDate(new Date(1990, 5, 18))
	.setBirthCountry('BE')
	.setFirstName('Enrico')
	.setLastName('Fermi')
	.setLanguage(LanguageCodes.fr)
	.setMaritalStatus(NaturalPersonMaritalStatus.Single)
	.setNationality('BE')
	.setGender(NaturalPersonGender.Male)
	.build();
