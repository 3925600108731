import { LiveTourStep } from './live-tour-step';
import { LiveTourService } from '../live-tour.service';
import { Observable, Observer } from 'rxjs';

export class InputStep extends LiveTourStep {

	constructor(
		private fieldSelector: string, private fieldValue: string, private charInterval = 0
	) {
		super();
	}

	public execute(service: LiveTourService, liveTourEvents: Observable<Event>): Observable<Event> {
		const input = document.querySelector(this.fieldSelector) as HTMLInputElement;
		input.value = '';

		return Observable.create((obv: Observer<any>) => {
			let pos = 0;
			const timer = window.setInterval(() => {
				// Write next char
				input.value = this.fieldValue.substr(0, ++pos);

				// Done
				if (pos >= this.fieldValue.length) {
					window.clearInterval(timer);
					input.dispatchEvent(new Event('input'));
					obv.next(new Event('stepComplete'));
					return;
				}
			}, this.charInterval);
		});
	}
}
