import { Injectable, Injector } from '@angular/core';
import { AbstractOrderXCLModel, FunctionalFeedbacksFromXclOrderExtractor, MultigoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { BackendOperationStep, BackendOperation, SearchCriteria, SearchResult } from '@mdib/core/adapters';
import { ParameterModel } from '@mdib/http';
import { SepaDirectDebitContract, SepaDirectDebitContractsService, SepaDirectDebitPayment, SepaDirectDebitPaymentsService } from '@mdib/sepa-direct-debits';
import { ServiceResponse, UtilsHelper } from '@mdib/utils';
import { Observable } from 'rxjs';
import { SepaDirectDebitPaymentsMapperXclService } from './sepa-direct-debit-payments-mapper-xcl.service';
import { SepaDirectDebitPaymentsUpdateXcl } from './operations/sepa-direct-debit-payments-update-xcl.service';
import { SepaDirectDebitPaymentXcl } from '../model/sepa-direct-debit-payment-xcl';
import { XclContractType } from '../model/xcl-contract-type';
import { mergeMap, map } from 'rxjs/operators';
import { SepaDirectDebitPaymentsRetrieveXcl } from './operations/sepa-direct-debit-payments-retrieve-xcl.service';

@Injectable({
	providedIn: 'root'
})
export class SepaDirectDebitPaymentsXclService extends SepaDirectDebitPaymentsService {

	constructor(private sepaDirectDebitContractsService: SepaDirectDebitContractsService,
		private sepaDirectDebitPaymentsMapperXclService: SepaDirectDebitPaymentsMapperXclService,
		private xclHttpService: XclHttpService,
		private feedbacksExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private injector: Injector
	) {
		super();
	}

	public list(sepaDirectDebitContract: SepaDirectDebitContract, index?: number, count?: number): Observable<ServiceResponse<SepaDirectDebitPayment[]>> {
		const params: ParameterModel[] = [];
		params.push(new ParameterModel('start', (index || 0).toString()));
		if (count) {
			params.push(new ParameterModel('maxResults', count.toString()));
		}

		if (this.isContractRequestParametersExists(sepaDirectDebitContract)) {
			this.populateParameter(sepaDirectDebitContract, params);
			return this.listData(params);
		} else {
			return this.sepaDirectDebitContractsService.retrieve().execute(sepaDirectDebitContract.contractReference).pipe(mergeMap((response: BackendOperationStep<SepaDirectDebitContract>) => {
				this.populateParameter(response.result, params);
				return this.listData(params);
			}));
		}
	}

	public count(sepaDirectDebitContract: SepaDirectDebitContract): Observable<ServiceResponse<number>> {
		const params: ParameterModel[] = [];
		if (this.isContractRequestParametersExists(sepaDirectDebitContract)) {
			this.populateParameter(sepaDirectDebitContract, params);
			return this.listCount(params);
		}
		return this.sepaDirectDebitContractsService.retrieve().execute(sepaDirectDebitContract.contractReference).pipe(mergeMap((response: BackendOperationStep<SepaDirectDebitContract>) => {
			this.populateParameter(response.result, params);
			return this.listCount(params);
		}));
	}

	public retrieve(): BackendOperation<string, SepaDirectDebitPayment> {
		return new SepaDirectDebitPaymentsRetrieveXcl(this.injector);
	}

	public create(): BackendOperation<SepaDirectDebitPayment, SepaDirectDebitPayment> {
		throw new Error('Method not implemented.');
	}

	public update(): BackendOperation<Partial<SepaDirectDebitPayment>, SepaDirectDebitPayment> {
		return new SepaDirectDebitPaymentsUpdateXcl(this.injector);
	}

	public delete(): BackendOperation<any, SepaDirectDebitPayment> {
		throw new Error('Method not implemented.');
	}

	public search(): BackendOperation<SearchCriteria<any>, SearchResult<SepaDirectDebitPayment>> {
		throw new Error('Method not implemented.');
	}

	private isContractRequestParametersExists(sepaDirectDebitContract: SepaDirectDebitContract, ) {
		if (!UtilsHelper.nullOrUndefined(sepaDirectDebitContract) && !UtilsHelper.nullOrUndefined(sepaDirectDebitContract.creditor) && !UtilsHelper.nullOrUndefined(sepaDirectDebitContract.creditor.identity) && !UtilsHelper.nullOrUndefined(sepaDirectDebitContract.contractNumber) && !UtilsHelper.nullOrUndefined(sepaDirectDebitContract.contractType)) {
			return true;
		}
		return false;
	}

	private populateParameter(sepaDirectDebitContract: SepaDirectDebitContract, params: ParameterModel[]): ParameterModel[] {
		params.push(new ParameterModel('ordererIdentity', sepaDirectDebitContract.creditor.identity));
		params.push(new ParameterModel('contractNumber', sepaDirectDebitContract.contractNumber));
		params.push(new ParameterModel('paymentScheme', XclContractType.toXclType(sepaDirectDebitContract.contractType)));
		return params;
	}

	private listCount(params: ParameterModel[]): Observable<ServiceResponse<number>> {

		return this.xclHttpService.execute('sepa-direct-debit-payments-count', XclHttpService.GET, params)
			.pipe(map((xclOrder: AbstractOrderXCLModel) =>
				new ServiceResponse<number>(
					xclOrder.rowCount,
					this.feedbacksExtractor.extract(xclOrder),
				)
			));
	}

	private listData(params: ParameterModel[]): Observable<ServiceResponse<SepaDirectDebitPayment[]>> {
		// Extract the sepa direct debit payment list from the root 'goalList' property returned by the XCL
		// Return an array of direct debit payment list (functional model) corresponding to
		// the array of direct debit payment list returned by the XCL (technical model)
		return this.xclHttpService.execute('sepa-direct-debit-payments-list', XclHttpService.GET, params).pipe(map((xclOrder: MultigoalOrderXCLModel<SepaDirectDebitPaymentXcl>) =>
			new ServiceResponse<SepaDirectDebitPayment[]>(
				this.sepaDirectDebitPaymentsMapperXclService.fromXclModelListToFunctionalModelList(xclOrder.goalList),
				this.feedbacksExtractor.extract(xclOrder),
				{ totalCount: xclOrder.rowCount },
			)
		));
	}
}
