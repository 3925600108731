import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ServiceResponse } from '@mdib/utils';

import { Contact } from '../../model/contact';

@Injectable()
export abstract class ContactsService {

	abstract validateUpdateContact(contacts: Contact[]): Observable<ServiceResponse<Contact[]>>;

	/**
	 * Retrieves an array of contact means for the active user person
	 *
	 * @returns {Observable<ServiceResponse<Contact[]>>}
	 */
	abstract get(): Observable<ServiceResponse<Contact[]>>;

}
