import { Injectable } from '@angular/core';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { CashAccount } from '../model/cash-account';
import { ModifyAccountNameOperation } from '../model/modify-account-name-operation';
import { ModifyAccountNameService } from './modify-account-name.service';

@Injectable()
export class ModifyAccountNameServiceStub extends ModifyAccountNameService {

	public update(cashAccount: CashAccount): Observable<ServiceResponse<ModifyAccountNameOperation | null>> {
		return undefined;
	}
}
