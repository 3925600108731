import { Component } from '@angular/core';

@Component({
	selector: 'application-outdated-page',
	templateUrl: './application-outdated-page.component.html',
	styleUrls: ['./application-outdated-page.component.scss']
})
export class ApplicationOutdatedPageComponent {

	public readonly NAMESPACE = 'errorsPagesModule.applicationOutdatedPageComponent.';

}
