import { Component, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

import { SessionsService } from '../../../service/sessions.service';
import { TimeoutComponent } from '../../shared/timeout.component';

@Component({
	selector: 'mdib-session-timeout',
	templateUrl: './session-timeout.component.html',
	styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent extends TimeoutComponent {

	readonly NAMESPACE = 'sessionsModule.sessionTimeoutComponent.';

	constructor(
		protected dialogRef: MatDialogRef<SessionTimeoutComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		protected router: Router,
		protected matDialog: MatDialog,
		protected ngZone: NgZone,
		protected sessionsService: SessionsService,
	) {
		super(dialogRef, data, router, matDialog, ngZone);
	}

	public endTimeoutEvent(): void {
		this.sessionsService.removeSession().subscribe(() => {
			this.sessionsService.setSessionsAndActiveSessionIdInStorage(null);
			this.redirectToLogoutPage();
		});
		this.closeDialog();
	}

	public keepAlive(): void {
		// Keep the session alive
		const session = this.sessionsService.getSession();
		if (session && session.keepAlive) {
			session.keepAlive().subscribe();
		}
		super.keepAlive();
	}

}
