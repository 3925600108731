import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionUtilsService } from './session-utils.service';

@Injectable()
export class SessionGuardService implements CanActivate {

	constructor(
		private _router: Router,
		private sessionUtilsService: SessionUtilsService,
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

		// Restriction on connected state
		const isRestricted = !route.data['allowAnonymous'];
		if (!this.sessionUtilsService.isActiveUserConnected() && isRestricted) {
			this._router.navigate(['/session']);
			return false;
		} else {
			return true;
		}
	}

}
