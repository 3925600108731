import { Component, OnInit, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { UtilsHelper } from '@mdib/utils/helper/utils-helper';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogComponent, ModalDialogProperties } from '@mdib-pages/generic';
import { CommonDataSource } from '@mdib/generic';
import { Observable } from 'rxjs';
import { GenericListPage } from '@mdib/generic';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { ServiceResponse, DateHelper, Status, MdibFile, DateFormatterPipe } from '@mdib/utils';
import { IconButton } from '@mdib/styling';
import { saveAs } from 'file-saver/FileSaver';
import { CashAccountService, CashAccount } from '@mdib/cash-accounts';
import { b64toBlob, XclDateFormatter } from '@mdib-xcl/utils';
import { tap, map } from 'rxjs/operators';
import { PdfStatement, PdfStatementSummary } from '@mdib/pdf-statements/model/pdf-statement';
import { PdfStatementsService } from '@mdib/pdf-statements/service/pdf-statements.service';
import { PdfStatementsPageFilter } from '@mdib/pdf-statements/model/pdf-statement-page-filter';
import { PdfStatementStatus } from '@mdib/pdf-statements/model/pdf-statement-status.enum';
import { NotificationMessageService } from '@mdib/notification-message';
import { isNullOrUndefined } from 'util';

const jsPDF = require('jspdf');

@Component({
	selector: 'pdf-statements-page',
	templateUrl: './pdf-statements-page.component.html',
	styleUrls: ['./pdf-statements-page.component.scss']
})
export class PdfStatementsPageComponent extends GenericListPage implements OnInit {

	readonly NAMESPACE = 'accountStatementsPagesModule.newAccountStatementsPageComponent.';

	readonly MAX_MOVEMENTS_PER_FILE = 1000;

	public filter = <PdfStatementsPageFilter> {
		status: PdfStatementStatus.New,
		cashAccount: null,
		startDate: null,
		endDate: null
	};

	public PdfStatementsStatus = PdfStatementStatus;

	public displayedColumns = ['account', 'from', 'until', 'actions'];
	public selectFirstCashAccount = false;
	protected iconArray: Array<IconButton> = [{ iconName: 'file_download', iconLabel: 'download' }];

	protected accountNumber: string;
	protected accountClientWording: string;
	protected selectedAccountStatement: PdfStatement;
	protected statementDownloading: boolean[] = [];
	protected totalRowCount: number;
	protected serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService;

	private dateFormatterPipe = new DateFormatterPipe();


	constructor(private route: ActivatedRoute,
		private pdfStatementsService: PdfStatementsService,
		private cashAccountService: CashAccountService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private notificationMessageService: NotificationMessageService,
		injector: Injector) {
		super(injector);
		this.dataSourceType = CommonDataSource;
		this.listConfigurations.listTitle = this.NAMESPACE + 'newAccountStatementsTitle';
		this.listConfigurations.selectorTitle = this.NAMESPACE + 'accountLabel';
		this.listConfigurations.listEmptyMessage = this.NAMESPACE + 'listEmptyMessage';
	}

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe(params => {
			this.loadSearchModel(params, this.filter);
			this.fetchData();
		});

	}

	load(index?: number, count?: number): Observable<ServiceResponse<any>> {
		return this.pdfStatementsService.list(index, count, this.filter);
	}

	clearFilter(): void { }

	consultRow(row: PdfStatementSummary, index?: number): void {
		this.notificationMessageService.clearAll();
		const dialogRef = this.dialog.open(ModalDialogComponent, {
			width: '1000px',
			maxWidth: '99%',
			data: {
				isModalView: true,
				leftButtonLabel: this.NAMESPACE + 'generic:no',
				rightButtonLabel: this.NAMESPACE + 'generic:yes',
				message: this.NAMESPACE + 'accountStatementMessage',
				showPreview: true
			}
		});

		const dialogData = dialogRef.componentInstance.data;

		this.getAccountStatement(row).subscribe(
			(accountStatement: PdfStatement) => {
				this.selectedAccountStatement = accountStatement;
				accountStatement.originalStartDate = row.firstStatementDate;
				dialogData.document = b64toBlob(accountStatement.file.content, accountStatement.file.contentType);
				dialogData.title = this.translate.instant(this.NAMESPACE + 'modalDialogTitleLine', {
					firstStatementDate: this.dateFormatterPipe.transform(this.selectedAccountStatement.firstStatementDate),
					lastStatementDate: this.dateFormatterPipe.transform(this.selectedAccountStatement.lastStatementDate)
				});
				if (!this.selectedAccountStatement.lastStatementSwitch) {
					dialogData.notification = this.translate.instant(
						this.NAMESPACE + 'multipleFilesMessage');
						dialogRef.componentInstance.data.firstStatementDate = this.dateFormatterPipe.transform(this.selectedAccountStatement.firstStatementDate);
						dialogRef.componentInstance.data.lastStatementDate = this.dateFormatterPipe.transform(this.selectedAccountStatement.lastStatementDate);
					dialogRef.componentInstance.data.showPreview = false;
					dialogRef.componentInstance.pdfLoaded = true;
				} else {
					dialogRef.componentInstance.parseBlob();
				}
				dialogData.documentName = accountStatement.file.name;



				dialogRef.componentInstance.closeOnClick = false;
				dialogRef.componentInstance.rightButtonClicked.subscribe(() => {
					// Check if there is a file attached to this statement, if not close the modal, if yes, try to retrieve next
					if (this.selectedAccountStatement.emptyFileSwitch) {
						dialogRef.close();
						return;
					}

					if (!this.selectedAccountStatement.lastStatementSwitch) {
						this.getNextAccountStatement(this.selectedAccountStatement).subscribe((nextAccountStatement: PdfStatement) => {
							this.selectedAccountStatement = nextAccountStatement;
							dialogData.documentName = '';
							dialogRef.componentInstance.pdfLoaded = true;

							if (!this.selectedAccountStatement.emptyFileSwitch) {
								dialogData.document = b64toBlob(nextAccountStatement.file.content, nextAccountStatement.file.contentType);
								dialogData.documentName = this.selectedAccountStatement.file.name;
								dialogRef.componentInstance.parseBlob();
								dialogRef.componentInstance.data.title = this.translate.instant(this.NAMESPACE + 'modalDialogTitleLine', {
									firstStatementDate: this.dateFormatterPipe.transform(this.selectedAccountStatement.firstStatementDate),
									lastStatementDate: this.dateFormatterPipe.transform(this.selectedAccountStatement.lastStatementDate)
								});
								dialogData.notification = this.translate.instant(this.NAMESPACE + 'multipleFilesMessage');
								dialogRef.componentInstance.data.firstStatementDate = this.dateFormatterPipe.transform(this.selectedAccountStatement.firstStatementDate);
								dialogRef.componentInstance.data.lastStatementDate = this.dateFormatterPipe.transform(this.selectedAccountStatement.lastStatementDate);
							} else {
								dialogRef.close();
								dialogData.notification = null;
							}

							this.fetchData();

						}, (error) => {
							dialogRef.close();
							console.log(error);
						});
					// If the last statement switch is true, no file is attached to the next statement so confirm download, close the modal and refresh the statements list.
					} else {
						dialogRef.close();
						this.getNextAccountStatement(this.selectedAccountStatement).subscribe((nextAccountStatement: PdfStatement) => {
							const data = this.listConfigurations.dataSource.data.filter(statement => statement.accountNumber.indexOf(nextAccountStatement.accountNumber) < 0);
							this.listConfigurations.dataSource = new CommonDataSource(data);
							this.listConfigurations.totalCount = data.length;
						}, (error) => {
							dialogRef.close();
							this.fetchData();
						});
					}
				},
					(error) => {
						dialogRef.close();
						this.fetchData();
						console.log(error);
					});
			},
			(error) => {
				// If there is an error while retrieving a statement, we close the modal view so the user can see the error message clearly
				dialogRef.close();
				this.fetchData();
				console.log(error);
			}
		);
	}

	/**
	 * This method is called to retrieve a file of an account statement.
	 * @param accountStatement
	 * @returns {Observable<AccountStatement>}
	 */
	getAccountStatement(accountStatementSummary: PdfStatementSummary): Observable<PdfStatement> {
		const sendFeedbacks = (response: ServiceResponse<null>) => this.serviceResponseNotifMapper.sendResponseFeedbacks(response, this.NAMESPACE);
		const extractStatement = (serviceResponse: ServiceResponse<PdfStatement>) => serviceResponse.getModel();

		return this.pdfStatementsService.get(accountStatementSummary, null, this.filter)
			.pipe(tap(sendFeedbacks, sendFeedbacks), map(extractStatement));
	}

	getNextAccountStatement(previousStatement: PdfStatement): Observable<PdfStatement> {
		const sendFeedbacks = (response: ServiceResponse<null>) => this.serviceResponseNotifMapper.sendResponseFeedbacks(response, this.NAMESPACE);
		const extractStatement = (serviceResponse: ServiceResponse<PdfStatement>) => serviceResponse.getModel();

		return this.pdfStatementsService.get(null, previousStatement)
			.pipe(tap(sendFeedbacks, sendFeedbacks), map(extractStatement));
	}

	/**
	 * This method update an account statement status to "Read" and refreshes it in the table.
	 * @param row
	 */
	updateStatement(row: any, index: number): void {
		this.listConfigurations.loaded = false;
		this.selectedAccountStatement = row;

		const sendFeedbacks = (response: ServiceResponse<null>) => {
			this.serviceResponseNotifMapper.sendResponseFeedbacks(response, this.NAMESPACE);
		};
		const extractStatement = (serviceResponse: ServiceResponse<PdfStatement>) => serviceResponse.getModel();

		const updateStream = this.pdfStatementsService.update(row)
			.pipe(tap(sendFeedbacks, sendFeedbacks), map(extractStatement));

		this.fetchData();
	}

	onCashAccountChange(cashAccount: CashAccount): void {
		// check if cashaccount has really changed and only then perform action
		if (this.filter.cashAccount !== cashAccount) {
			this.filter.cashAccount = (cashAccount != null) ? cashAccount : null;
			this.onFilterChanged();
		}
	}

	onFilterChanged(event?: any) {
		// First cash account only needs to be selected when user requests duplicates, and no account has been selected yet
		if (this.filter.status === PdfStatementStatus.Downloaded
			&& (isNullOrUndefined(this.filter.cashAccount) || isNullOrUndefined(this.filter.cashAccount.number))) {
			this.selectFirstCashAccount = true;
		} else {
			this.selectFirstCashAccount = false;
		}
		this.applyFilter();
	}
}
