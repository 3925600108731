import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
	selector: '[focus]',
})
export class FocusDirective implements OnChanges {

	@Input() focus: boolean;

	constructor(private ref: ElementRef) {
	}

	ngOnChanges() {
		if (this.focus) {
			setTimeout(() => {
				this.ref.nativeElement.focus();
			});
		}
	}
}
