import { Injectable } from '@angular/core';

import { ConfigService } from '@mdib/config';

import { NavigationFilter } from '../types/navigation-filter';
import { NavigationItem } from '../types/navigation-item';

@Injectable()
export class NavigationFilterWipService implements NavigationFilter {

	constructor(
		private configService: ConfigService,
	) { }

	public filterItem(item: NavigationItem): boolean {
		return this.configService.featureToggles.showWIP || !item['workInProgress'];
	}
}
