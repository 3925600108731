import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '@mdib/utils/service/configuration.service';

@Component({
	selector: 'mdib-widgets',
	templateUrl: './widgets.component.html',
	styleUrls: ['./widgets.component.scss'],
})
export class WidgetsComponent implements OnInit {
	readonly NAMESPACE = 'stubsModule.widgetsComponent.';

	// Widget 1
	widget1TitleKey: string;
	widget1LinkKey: string;
	widget1LinkUrl: string;

	// Widget 2
	widget2TitleKey: string;
	widget2LinkKey: string;
	widget2LinkUrl: string;

	constructor(
		private configurationService: ConfigurationService,
	) {
	}

	ngOnInit(): void {
		// Widget 1
		this.widget1TitleKey = this.configurationService.instant('dashboard.widget1.titleKey');
		this.widget1LinkKey = this.configurationService.instant('dashboard.widget1.linkKey');
		if (this.widget1TitleKey && this.widget1LinkKey) {
			this.widget1LinkUrl = this.configurationService.instant('dashboard.widget1.linkUrl');
		}

		// Widget 2
		this.widget2TitleKey = this.configurationService.instant('dashboard.widget2.titleKey');
		this.widget2LinkKey = this.configurationService.instant('dashboard.widget2.linkKey');
		if (this.widget2TitleKey && this.widget2LinkKey) {
			this.widget2LinkUrl = this.configurationService.instant('dashboard.widget2.linkUrl');
		}
	}
}
