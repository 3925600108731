import { Inject, NgModule } from '@angular/core';
import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';
import { AdaptersService } from '@mdib/core/adapters';
import { AuthenticationDataService, SessionResetService, SessionRestorationService } from '@mdib/sessions';
import { DigipassAuthenticationService, OtpAuthenticationService, PasswordAuthenticationService } from '@mdib/sessions-modes';

import { AuthenticationDataMemoryService } from './service/authentication-data-memory.service';
import { DigipassAuthenticationMemoryService } from './service/digipass-authentication-memory.service';
import { OtpAuthenticationMemoryService } from './service/otp-authentication-memory.service';
import { PasswordAuthenticationMemoryService } from './service/password-authentication-memory.service';
import { SessionResetMemoryService } from './service/session-reset-memory.service';
import { SessionRestorationMemoryService } from './service/session-restoration-memory.service';

@NgModule({
	providers: [
		{provide: SessionRestorationService, useClass: SessionRestorationMemoryService, multi: true},
	],
})
export class SessionsMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(PasswordAuthenticationService, adapterId, PasswordAuthenticationMemoryService);
		adaptersService.setAdapter(DigipassAuthenticationService, adapterId, DigipassAuthenticationMemoryService);
		adaptersService.setAdapter(OtpAuthenticationService, adapterId, OtpAuthenticationMemoryService);
		adaptersService.setAdapter(AuthenticationDataService, adapterId, AuthenticationDataMemoryService);
		adaptersService.setAdapter(SessionResetService, adapterId, SessionResetMemoryService);
	}
}
