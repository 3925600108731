import { LiveTourStep } from './live-tour-step';

export class LiveTour {

	public title: string;
	public description: string;
	private steps: Array<LiveTourStep> = [];

	public getStep(id: number): LiveTourStep {
		return this.steps[id];
	}

	public addStep(step: LiveTourStep): LiveTour {
		this.steps.push(step);
		return this;
	}

	public insertStep(id: number, step: LiveTourStep): LiveTour {
		this.steps.splice(id, 0, step);
		return this;
	}

	public removeStep(id: number) {
		this.steps.splice(id, 1);
	}

	public clear(): LiveTour {
		this.steps = [];
		return this;
	}

	public size(): number {
		return this.steps.length;
	}
}
