import { Injectable } from '@angular/core';

import { CommunicationTypes } from '@mdib/commons';

@Injectable({
	providedIn: 'root'
})
export class CommunicationTypesMapperService {

	private mapping = {
		'99': CommunicationTypes.free,
		'00': CommunicationTypes.european,
	};

	public fromXcl(value: string): CommunicationTypes {
		// When nothing specified, choose free comm
		return !!this.mapping[value] ? this.mapping[value] : CommunicationTypes.free;
	}

	public toXcl(value: CommunicationTypes): string {
		switch (value) {
			case CommunicationTypes.free: return '99';
			case CommunicationTypes.european: return '00';
		}
		// When nothing specified, choose free comm
		return '99';
	}
}
