import { Person } from '@mdib/customers';
import { Authentication, UserBuilder, BusinessRole } from '@mdib/sessions';
import { BusinessRoles, MockUsers } from './sessions-enum';

export namespace SessionHelper {

	export function mockEnticate(username: string): Authentication {
		const authentication = new Authentication({
				type: 'MOCK',
				user: new UserBuilder({
					id: username,
					connected: true,
					person: new Person(username, username),
				}).build(),
			},
		);

		switch (username) {
			case MockUsers.MockAccess:
			case MockUsers.mock:
				authentication.user = new UserBuilder(authentication.user).setBusinessRole(
					<BusinessRole>{
						id: '03',
						name: BusinessRoles.b2c,
					}
				).build();
				break;
			case MockUsers.mockb2b:
				authentication.user = new UserBuilder(authentication.user).setBusinessRole(
					<BusinessRole>{
						id: '04',
						name: BusinessRoles.b2b,
					}
				).build();
				break;
		}
		return authentication;
	}
}
