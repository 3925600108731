import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { GenericPagesModule } from '@mdib-pages/generic';
import { AsideModule } from '@mdib/aside';
import { CashAccountsModule } from '@mdib/cash-accounts';
import { FooterModule } from '@mdib/footer';
import { HeaderModule } from '@mdib/header';

import { StylingModule } from '@mdib/styling';
import { UtilsModule } from '@mdib/utils';
import { TranslateModule } from '@ngx-translate/core';

import { AccountStatementsPageComponent } from './component/account-statements-page/account-statements-page.component';
import { AccountStatementsRoutingModule } from './account-statements-routing.module';
import { AccountStatementsPageFilterComponent } from './component/account-statements-page/account-statements-page-filter/account-statements-page-filter.component';
import { ElectronicalStatementsPageComponent } from '@mdib-pages/electronical-statements/electronical-statements-page/electronical-statements-page.component';
import { FormatSectionComponent } from './component/account-statements-page/format-section/format-section.component';
import { ElectronicalStatementsPageFilterComponent } from '@mdib-pages/electronical-statements/electronical-statements-page/electronical-statements-page-filter/electronical-statements-page-filter.component';
import { PdfStatementsPageComponent } from '@mdib-pages/account-statements/component/pdf-statements-page/pdf-statements-page.component';
import { PdfStatementsPageFilterComponent } from '@mdib-pages/account-statements/component/pdf-statements-page/pdf-statements-page-filter/pdf-statements-page-filter.component';

@NgModule({
	imports: [
		// Third-Party
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		// Routing
		AccountStatementsRoutingModule,
		// Application
		HeaderModule,
		FooterModule,
		AsideModule,
		StylingModule,
		UtilsModule,
		CashAccountsModule,
		GenericPagesModule,
	],
	declarations: [
		AccountStatementsPageComponent,
		AccountStatementsPageFilterComponent,
		PdfStatementsPageComponent,
		PdfStatementsPageFilterComponent,
		ElectronicalStatementsPageComponent,
		ElectronicalStatementsPageFilterComponent,
		FormatSectionComponent,
	],
})
export class AccountStatementsPagesModule {}
