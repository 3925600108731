import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SepaDirectDebitPayment } from '@mdib/sepa-direct-debits';

import { BackendUpdateOperationXcl } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';

import { SepaDirectDebitPaymentXcl } from '../../model/sepa-direct-debit-payment-xcl';
import { SepaDirectDebitPaymentsMapperXclService } from '../../service/sepa-direct-debit-payments-mapper-xcl.service';

export class SepaDirectDebitPaymentsUpdateXcl extends BackendUpdateOperationXcl<SepaDirectDebitPayment, SepaDirectDebitPayment, SepaDirectDebitPaymentXcl> {

	protected sepaDirectDebitPaymentsMapperXclService: SepaDirectDebitPaymentsMapperXclService;

	protected xclEndpoint = 'xcl.sepaDirectDebit.payments';

	constructor(injector: Injector) {
		super(injector);
		this.sepaDirectDebitPaymentsMapperXclService = injector.get(SepaDirectDebitPaymentsMapperXclService);
	}

	protected handleResponse(order: MonogoalOrderXCLModel<SepaDirectDebitPaymentXcl>): Observable<SepaDirectDebitPayment> {
		return super.handleResponse(order).pipe(map(() => {
			return this.sepaDirectDebitPaymentsMapperXclService.fromXclToFunctional(order.goal);
		}));
	}

	protected getItemIdentifier(input: SepaDirectDebitPayment): string {
		return input.paymentReference;
	}

	protected getValidateData(input: SepaDirectDebitPayment): SepaDirectDebitPaymentXcl {
		return null;
	}

}
