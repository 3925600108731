import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as Fingerprint2 from 'fingerprintjs2';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { CordovaPluginDeviceService } from '@mdib/cordova';

@Injectable()
export class FingerprintInterceptorService implements HttpInterceptor {

	private browserId = '';

	constructor(
		private cordovaPluginDeviceService: CordovaPluginDeviceService,
	) { }

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.getFingerprint().pipe(mergeMap(fingerprint => {
			let headers = req.headers;
			headers = headers.set('X-BrowserId', this.browserId);
			const newRequest = req.clone({ headers: headers });
			return next.handle(newRequest);
		}));
	}

	public getFingerprint(): Observable<string> {
		const deviceUuid = this.cordovaPluginDeviceService.uuid || this.browserId;
		if (deviceUuid) {
			return of(deviceUuid);
		} else {
			return new Observable(obs => {
				new Fingerprint2().get(fingerprint => {
					this.browserId = fingerprint;
					obs.next(fingerprint);
					obs.complete();
				});
			});
		}
	}
}
