import { Immutable } from '@mdib/core/meta';

export interface SecretQuestionModel {
	id: string;
	question: string;
	answer: string;
}

@Immutable()
export class SecretQuestion implements SecretQuestionModel {
	public readonly id: string = '';
	public readonly question: string = '';
	public readonly answer: string = '';

	constructor(properties: Partial<SecretQuestionModel> = {}) {
		Object.assign(this, properties);
	}

	public set(key: string, value: any) {
		const copy: any = {};
		Object.assign(copy, this);
		copy[key] = value;
		return new SecretQuestion(copy);
	}

	public setId(id: string): SecretQuestion {
		return this.set('id', id);
	}

	public setQuestion(question: string): SecretQuestion {
		return this.set('question', question);
	}

	public setAnswer(answer: string): SecretQuestion {
		return this.set('answer', answer);
	}
}
