import { Injectable } from '@angular/core';
import { SavingsAccountCommonService } from './savings-account-common.service';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { WithdrawalSimulation } from '../../types/cash-account.typings';

@Injectable()
export class SavingsAccountServiceStub extends SavingsAccountCommonService {
	public getWithdrawalSimulation(simulation: WithdrawalSimulation): Observable<ServiceResponse<WithdrawalSimulation>> {
		return of();
	}
}
