import { Component, Input, OnChanges } from '@angular/core';
import { ConfigService } from '@mdib/config';
import { OperationStatus, PaymentForm, PaymentTypes } from '@mdib/payments';
import { Status } from '@mdib/utils';

@Component({
	selector: 'mdib-data-gathering-step',
	templateUrl: './data-gathering-step.component.html',
	styleUrls: ['./data-gathering-step.component.scss'],
})
export class DataGatheringStepComponent implements OnChanges {

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.dataGatheringStepComponent.';
	@Input() paymentForm: PaymentForm;
	@Input() selectedStep = 0;
	@Input() isConsultation = false;

	selectFirstCashAccount: boolean;
	operationStatus = OperationStatus;
	status = Status;
	paymentTypes = PaymentTypes;
	isUrgentPayment = false;
	slideChecked = false;

	readonly selectablePaymentTypes = [PaymentTypes.sepaCreditTransfer, PaymentTypes.europeanStandingOrder, PaymentTypes.internationalPayment];

	constructor(
		private configService: ConfigService,
	) {
	}

	ngOnChanges(): void {
		this.selectFirstCashAccount = !this.paymentForm.ordererAccount.value;
	}

	selectPaymentType(type?: PaymentTypes) {
		if (!type) {
			type = this.paymentForm.type.value === PaymentTypes.instantPayment ? PaymentTypes.sepaCreditTransfer : PaymentTypes.instantPayment;
			this.paymentForm.type.setValue(type);
		} else if (type !== this.paymentForm.type.value) {
			this.slideChecked = false;
			this.paymentForm.reset();
			this.paymentForm.type.setValue(type);
		}
	}

	/**
	 * This method returns european payment as a type for the tabs if the type of the payment is instant payment.
	 * It allows to keep "European" selected in the tabs when the payment is an instant payment.
	 * @param {PaymentTypes} type
	 * @returns {PaymentTypes}
	 */
	adaptTabs(type: PaymentTypes): PaymentTypes {
		return type === PaymentTypes.instantPayment ? PaymentTypes.sepaCreditTransfer : type;
	}

	get displayCommunication(): boolean {
		return this.paymentForm.beneficiaryForm.communicationForm.group.enabled || (this.paymentForm.beneficiaryForm.communicationForm.group.disabled && !!this.paymentForm.beneficiaryForm.communicationForm.type.value && !!this.paymentForm.beneficiaryForm.communicationForm.value.value);
	}

	get displayDateSection(): boolean {
		return (this.paymentForm.group.enabled) ||
			((this.paymentForm.type.value !== PaymentTypes.europeanStandingOrder) && this.paymentForm.paymentDate.disabled && this.paymentForm.paymentDate.value) ||
			(this.paymentForm.type.value === PaymentTypes.europeanStandingOrder && this.paymentForm.standingOrderDetailsForm.group.disabled &&
				!!this.paymentForm.standingOrderDetailsForm.startDate.value ||
				!!this.paymentForm.standingOrderDetailsForm.endDate.value ||
				!!this.paymentForm.standingOrderDetailsForm.iteration.value ||
				!!this.paymentForm.standingOrderDetailsForm.totalAmount.value);
	}

	get displayFrequencySection(): boolean {
		return this.paymentForm.type.value === PaymentTypes.europeanStandingOrder;
	}

	get accountCurrency(): string {
		return this.paymentForm.model.type === PaymentTypes.internationalPayment ? null : this.configService.baseCurrencyCode;
	}

	showStatus(): boolean {
		return this.isConsultation && !(this.paymentForm.model.operationStatus === OperationStatus.inShoppingBasket || this.paymentForm.model.operationStatus === OperationStatus.unknown);
	}

	showOperationDate(): boolean {
		return this.isConsultation && !(this.paymentForm.model.operationStatus === OperationStatus.inShoppingBasket || this.paymentForm.model.operationStatus === OperationStatus.unknown) && (this.paymentForm.model.type !== this.paymentTypes.europeanStandingOrder);
	}

}
