import { Injectable } from '@angular/core';

import { ConfigurationService } from '@mdib/utils';

@Injectable({
	providedIn: 'root'
})
export class AdaptersService {

	/**
	 * Default id to use when no other is provided.
	 */
	public defaultServiceId: string;

	private adapters: Map<any, Map<string, Array<any>>> = new Map();

	constructor(
		private configurationService: ConfigurationService,
	) { }

	public setAdapter(serviceType: any, adapterId: string, adapter: any) {
		if (!this.adapters.has(serviceType)) {
			this.adapters.set(serviceType, new Map());
		}

		const adapters = this.adapters.get(serviceType);
		adapters.set(adapterId, adapter);
	}

	public getAdapter(serviceType: any, adapterId?: string): any {
		// Default adapter
		if (!adapterId) {
			adapterId = this.getActiveAdapterFor(serviceType.name);
		}

		// Look for the service adapters
		const adapters = this.adapters.get(serviceType);
		if (!adapters) {
			throw new Error('No adapter on ' + serviceType);
		}

		// Look for the specific adapter
		const proxy = adapters.get(adapterId);
		if (!proxy) {
			throw new Error('No proxy on ' + serviceType + ' for ' + adapterId);
		}

		return proxy;
	}

	public getActiveAdapterFor(serviceName = '*'): any {
		const serviceId: string = this.configurationService.instant('services.' + serviceName + '.activeId');
		const servicesId: string = this.configurationService.instant('services.activeId');
		const resultServiceId = serviceId || servicesId || this.defaultServiceId;

		if (!resultServiceId) {
			throw new Error('Cannot find the active adapter for ' + serviceName);
		}
		return resultServiceId;
	}

}
