import { Component, Input } from '@angular/core';

@Component({
	selector: 'mdib-info-box',
	templateUrl: './info-box.component.html',
	styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {

	@Input() title: string;
	@Input() subTitle: string;
	@Input() buttonLabel: string;
	@Input() buttonURL: string;
	@Input() externalURL = false;

}
