import { Injectable } from '@angular/core';
import { AbstractOrderXCLModel, FunctionalFeedbacksFromXclOrderExtractor, MultigoalOrderXCLModel, XclHttpService, MonogoalOrderXCLModel } from '@mdib-xcl/http';
import { ParameterModel } from '@mdib/http';
import { StandingOrder, StandingOrdersCommonService, StandingOrdersPageFilter } from '@mdib/standing-orders';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { StandingOrderXcl } from '../model/standing-order-xcl';
import { StandingOrdersMapperXclService } from './standing-orders-mapper-xcl.service';
import { XclSignatureContext } from '@mdib-xcl/core';

@Injectable({providedIn: 'root' })
export class StandingOrdersXclService extends StandingOrdersCommonService {
	constructor(private xclStandingOrdersMapperService: StandingOrdersMapperXclService,
		private xclHttpService: XclHttpService,
		private feedbacksExtractor: FunctionalFeedbacksFromXclOrderExtractor,
	) {
		super();
	}

	public list(offset?: number, limit?: number, filter?: StandingOrdersPageFilter): Observable<ServiceResponse<StandingOrder[]>> {
		// TODO: if offset is the page number, we should multiply it by maxResults to correctly handle the pagination.
		const params: ParameterModel[] = [
			new ParameterModel('start', offset === undefined ? '0' : offset.toString()),
			new ParameterModel('displayedStatus', '2000'),
			new ParameterModel('isDomesticPayment', 'True'),
			new ParameterModel('isFixedAmount', 'True'),
			new ParameterModel('isLevellingAmount', 'True'),
			new ParameterModel('isRemittance', 'True'),
			new ParameterModel('isSepaOrder', 'True'),
		];

		if (!isNullOrUndefined(filter) && !isNullOrUndefined(filter.ordererAccountNumber)) {
			params.push(new ParameterModel('ordererAccountNumber', filter.ordererAccountNumber));
		}

		if (!isNullOrUndefined(limit)) {
			params.push(new ParameterModel('maxResults', limit.toString()));
		}

		const xclObservable = this.xclHttpService.execute('standing-orders-list', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<StandingOrderXcl>>;

		// Extract the standing orders list from the root "goalList" property returned by the XCL and return an
		// array of standing orders (functional model) corresponding to the one returned by the XCL (technical model)
		return xclObservable.pipe(map((xclOrder: MultigoalOrderXCLModel<StandingOrderXcl>) =>
			new ServiceResponse<StandingOrder[]>(
				this.xclStandingOrdersMapperService.standingOrderXclListToStandingOrderList(xclOrder.goalList),
				this.feedbacksExtractor.extract(xclOrder)
			)
		));
	}

	public count(filter: StandingOrdersPageFilter): Observable<ServiceResponse<number>> {
		const params: ParameterModel[] = [
			new ParameterModel('start', '0'),
			new ParameterModel('displayedStatus', '2000'),
			new ParameterModel('isDomesticPayment', 'True'),
			new ParameterModel('isFixedAmount', 'True'),
			new ParameterModel('isLevellingAmount', 'True'),
			new ParameterModel('isRemittance', 'True'),
			new ParameterModel('isSepaOrder', 'True'),
		];

		if (!isNullOrUndefined(filter) && !isNullOrUndefined(filter.ordererAccountNumber)) {
			params.push(new ParameterModel('ordererAccountNumber', filter.ordererAccountNumber));
		}

		return this.xclHttpService.execute('standing-orders-list', XclHttpService.GET, params)
			.pipe(map((xclOrder: AbstractOrderXCLModel) =>
				new ServiceResponse<number>(
					xclOrder.rowCount,
					this.feedbacksExtractor.extract(xclOrder),
				)
			));
	}

	public get(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>> {
		const params: ParameterModel[] = [
			new ParameterModel('reference', standingOrder.reference),
		];

		const xclObservable = this.xclHttpService.execute('standing-orders-get', XclHttpService.GET, params) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;

		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<StandingOrderXcl>) =>
			new ServiceResponse<StandingOrder>(
				this.xclStandingOrdersMapperService.standingOrderXclToStandingOrder(xclOrder.goal, xclOrder.reference, xclOrder.signatureTypesAllowed.toString()),
				this.feedbacksExtractor.extract(xclOrder)
			)
		));
	}

	public init(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder>> {
		const params: ParameterModel[] = [
			new ParameterModel('reference', standingOrder.reference),
		];

		const xclObservable = this.xclHttpService.execute('standing-orders-init', XclHttpService.PUT, params, this.xclStandingOrdersMapperService.standingOrderToStandingOrderXcl(standingOrder)) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;

		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<StandingOrderXcl>) =>
			new ServiceResponse<StandingOrder>(
				this.xclStandingOrdersMapperService.standingOrderXclToStandingOrder(xclOrder.goal, xclOrder.reference),
				this.feedbacksExtractor.extract(xclOrder)
			)
		));
	}

	public retrieve(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder>> {
		const params: ParameterModel[] = [
			new ParameterModel('reference', standingOrder.operationReference),
		];

		const xclObservable = this.xclHttpService.execute('standing-orders-retrieve', XclHttpService.PUT, params, this.getBodyForRetrieve(standingOrder)) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;

		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<StandingOrderXcl>) =>
			new ServiceResponse<StandingOrder>(
				this.xclStandingOrdersMapperService.standingOrderXclToStandingOrder(xclOrder.goal, xclOrder.reference),
				this.feedbacksExtractor.extract(xclOrder)
			)
		));
	}

	public validateStandingOrder(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>> {
		return this.init(standingOrder).pipe(mergeMap((serviceResponse: ServiceResponse<StandingOrder>) =>
			this.retrieve(serviceResponse.getModel())), mergeMap((serviceResponse: ServiceResponse<StandingOrder>) => this.validateStandingOrderXclCall(serviceResponse.getModel())));
	}

	public validateStandingOrderXclCall(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>> {
		const params: ParameterModel[] = [
			new ParameterModel('reference', standingOrder.operationReference),
		];

		const standingOrderXcl = this.xclStandingOrdersMapperService.standingOrderToStandingOrderXcl(standingOrder);
		const xclObservable = this.xclHttpService.execute('standing-orders-validate', XclHttpService.PUT, params, standingOrderXcl) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;

		standingOrder.signatureContext = new XclSignatureContext({ signableReference: standingOrder.operationReference });

		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<StandingOrderXcl>) =>
			new ServiceResponse<StandingOrder>(
				this.xclStandingOrdersMapperService.standingOrderXclToStandingOrder(xclOrder.goal),
				this.feedbacksExtractor.extract(xclOrder)
			)
		));
	}

	public signStandingOrder(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>> {
		const params: ParameterModel[] = [
			new ParameterModel('reference', standingOrder.operationReference),
		];

		const standingOrderXcl = this.xclStandingOrdersMapperService.standingOrderToStandingOrderXcl(standingOrder);
		const xclObservable = this.xclHttpService.execute('standing-orders-sign', XclHttpService.PUT, params, standingOrderXcl) as Observable<MonogoalOrderXCLModel<StandingOrderXcl>>;

		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<StandingOrderXcl>) =>
			new ServiceResponse<StandingOrder>(
				this.xclStandingOrdersMapperService.standingOrderXclToStandingOrder(xclOrder.goal),
				this.feedbacksExtractor.extract(xclOrder)
			)
		));
	}

	private getBodyForRetrieve(standingOrder: StandingOrder): object {
		return {
			'saveBeneficiary': standingOrder.saveBeneficiary.toString(),
			'standingOrderReference': standingOrder.reference,
			'identifier': standingOrder.reference
		};
	}
}
