import { Component, OnInit, AfterViewInit, Type } from '@angular/core';
import { ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core';
import { UtilsHelper } from '@mdib/utils';

import { LiveTourService } from '../live-tour.service';
import { LiveTour } from '../model/live-tour';
import { LiveTourStep } from '../model/live-tour-step';
import { LiveTourStepComponent } from '@mdib/live-tour/live-tour-step/live-tour-step.component';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'mdib-live-tour',
	templateUrl: './live-tour.component.html',
	styleUrls: ['./live-tour.component.scss']
})
export class LiveTourComponent implements OnInit, AfterViewInit {

	public tour: LiveTour = null;
	public step: LiveTourStep = null;

	@ViewChild('stepview', { read: ViewContainerRef }) stepview: ViewContainerRef;

	private currentTarget: HTMLElement;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private liveTourService: LiveTourService
	) { }

	ngOnInit() {
		this.liveTourService.setTourComponent(this);
	}

	ngAfterViewInit() {
		window.addEventListener('resize', () => this.updateTarget());
		this.targetElement();
		this.showComponent();
	}

	public targetElement(element?: HTMLElement, container?: HTMLElement) {
		this.currentTarget = element;
		this.updateTarget();
	}

	public showComponent(componentType?: Type<LiveTourStepComponent>, options?: object): Observable<Event> {
		this.stepview.clear();
		if (componentType) {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
			const componentRef = this.stepview.createComponent(componentFactory);
			return componentRef.instance.show(options);
		}
		return of();
	}

	private updateTarget() {
		const zoner = document.getElementById('liveTourZoner');
		if (!this.currentTarget) {
			zoner.style.top = '-10px';
			zoner.style.height = '0';
			return;
		}

		// Scroll to element
		let rect = this.currentTarget.getBoundingClientRect();
		const absoluteElementTop = rect.top + window.pageYOffset;
		const middle = absoluteElementTop - (window.innerHeight / 2);
		UtilsHelper.scrollTo('.container-wrapper');

		// Zone around the element
		rect = this.currentTarget.getBoundingClientRect();
		zoner.style.left = (rect.left - 20) + 'px';
		zoner.style.top = (rect.top - 20) + 'px';
		zoner.style.width = (rect.width + 40) + 'px';
		zoner.style.height = (rect.height + 40) + 'px';
	}

}
