import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { BicService, CashAccountOperationService, CashAccountService, CashAccountSortOrderService, ModifyAccountNameService, SavingsAccountService } from '@mdib/cash-accounts';
import { AdaptersService } from '@mdib/core/adapters';

import { BicXclService } from './service/bic/bic-xcl.service';
import { CashAccountCreationXclService } from './service/cash-account-creation-xcl.service';
import { CashAccountOperationMapperXclService } from './service/cash-account-operation-mapper-xcl.service';
import { CashAccountOperationXclService } from './service/cash-account-operation-xcl.service';
import { CashAccountSortOrderMapperXclService } from './service/cash-account-sort-order-mapper-xcl.service';
import { CashAccountSortOrderXclService } from './service/cash-account-sort-order-xcl.service';
import { CashAccountXclService } from './service/cash-account-xcl.service';
import { ModifyAccountNameOperationMapperXclService } from './service/modify-account-name-operation-mapper-xcl.service';
import { ModifyAccountNameXclService } from './service/modify-account-name-xcl.service';
import { SavingsAccountXclService } from './service/savings/savings-account-xcl.service';

@NgModule()
export class XclCashAccountModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(CashAccountService, adapterId, CashAccountXclService);
		adaptersService.setAdapter(CashAccountOperationService, adapterId, CashAccountOperationXclService);
		adaptersService.setAdapter(ModifyAccountNameService, adapterId, ModifyAccountNameXclService);
		adaptersService.setAdapter(SavingsAccountService, adapterId, SavingsAccountXclService);
		adaptersService.setAdapter(CashAccountSortOrderService, adapterId, CashAccountSortOrderXclService);
		adaptersService.setAdapter(BicService, adapterId, BicXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: XclCashAccountModule,
			providers: [
				BicXclService,
				CashAccountCreationXclService,
				CashAccountOperationMapperXclService,
				ModifyAccountNameOperationMapperXclService,
				CashAccountSortOrderMapperXclService,
			],
		};
	}
}
