import { ThemeService } from '@mdib/styling';
import { Observable } from 'rxjs';

import { SessionsService } from './sessions.service';
import { Session } from '../model/session';
import { ConfigurationServiceStub } from '@mdib/utils';

export class SessionsServiceStub extends SessionsService {
	constructor() {
		super([], null, <any>new ConfigurationServiceStub(), new ThemeService());
	}

	public getSession(id?: string): Session {
		return undefined;
	}

	public useSession(id: string): Session {
		return undefined;
	}

	public removeSession(id?: string): Observable<any> {
		return new Observable();
	}

	public setSession(session: Session, id: string): void { }

	public setSessionsAndActiveSessionIdInStorage(sessionId: string): void { }

	public saveSessionsInStorage(): void { }

	public saveSessionIdInStorage(sessionId: string): void { }

	public loadSessions(): void { }
}
