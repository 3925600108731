import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterTestingModule } from '@angular/router/testing';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

import { StylingModule } from '@mdib/styling';
import { UtilsModule, ConfigurationServiceStub, ConfigurationService } from '@mdib/utils';
import { NotificationMessageModule } from '@mdib/notification-message';

const sharedModules = [
	CommonModule,
	NoopAnimationsModule,
	RouterTestingModule,
	ReactiveFormsModule,
	StylingModule,
	UtilsModule,
];

@NgModule({
	imports: [
		...sharedModules,
		TranslateModule.forRoot(),
		NotificationMessageModule.forRoot(),
	],
	exports: [
		...sharedModules,
		TranslateModule,
	],
	providers: [
		{ provide: ConfigurationService, useClass: ConfigurationServiceStub },
	]
})
export class SharedTestsModule { }
