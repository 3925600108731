import { Component, EventEmitter, Output } from '@angular/core';
import { NavigationService } from '@mdib/core/navigation';
import { ConfigurationService } from '@mdib/utils';

@Component({
	selector: 'mdib-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {

	readonly NAMESPACE = 'pages.titles.';

	@Output() logout = new EventEmitter();
	@Output() eventFromNavigationComponent = new EventEmitter<string>();

	constructor(
		private navigationService: NavigationService,
		private configurationService: ConfigurationService,
	) {
	}

	getMenuItemsToShow() {
		return this.navigationService.getMenus().filter(e => !e.hidden);
	}

	get loanAppLink() {
		return this.configurationService.instant('loanAppLink');
	}

	get loanAppLinkTarget() {
		const target = this.configurationService.instant('loanAppLinkTarget');
		return target ? target : '_blank';
	}
}
