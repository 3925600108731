import { MdibFile } from '@mdib/utils';
import { AccountStatementStatus } from './account-statement-status.enum';

export class AccountStatement {
	identifier: string;
	accountNumber: string;
	sequenceNumber: string;
	statementNumber: number;
	statementNumberYear: number;
	status: AccountStatementStatus;
	accountingStartDate: Date;
	file: MdibFile;

	constructor(account?: Partial<AccountStatement>) {
		Object.assign(this, account);
	}
}
