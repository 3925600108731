import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ServiceResponse, Feedback, ConfigurationService } from '@mdib/utils';
import { HttpStatusManagementService, RestHttpService, HttpService } from '@mdib/http';

import { FunctionalFeedbacksFromXclOrderExtractor } from './functional-feedbacks-from-xcl-order-extractor.service';
import { AbstractOrderXCLModel } from '../model/abstract-order-xcl-model';

@Injectable()
export class XclHttpService extends RestHttpService<AbstractOrderXCLModel> {
	constructor(
		httpStatusManagementService: HttpStatusManagementService,
		httpService: HttpService,
		private _feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		configurationService: ConfigurationService,
	) {
		super(httpStatusManagementService, httpService, configurationService);
	}

	public addHeaders(headers: { [name: string]: string | string[]; }): { [name: string]: string | string[]; } {
		super.addHeaders(headers);
		headers['X-Requested-By'] = 'n/a';
		return headers;
	}

	protected convertHttpResponseToModel(res: any): Observable<AbstractOrderXCLModel> {
		res = res && res[Object.keys(res)[0]];
		return super.convertHttpResponseToModel(res);
	}

	protected inspectModel(model: AbstractOrderXCLModel): AbstractOrderXCLModel {
		const feedbackList: Feedback[] = this._feedbackExtractor.extract(model);
		if (model && model.isError) {
			throw new ServiceResponse<AbstractOrderXCLModel>(model, feedbackList);
		}
		return model;
	}
}
