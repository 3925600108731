import { MdibFile, ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { AccountValuationsPageFilter } from '../../../pages/account-valuations/component/account-valuations-page/account-valuations-page-filter/account-valuations-page-filter';
import { AccountValuation } from '../model/account-valuation';
import { AccountValuationsService } from './account-valuations.service';

export class AccountValuationsServiceStub extends AccountValuationsService {

	list(offset?: number,
		limit?: number,
		filter?: AccountValuationsPageFilter): Observable<ServiceResponse<AccountValuation[]>> {
			return of();
		}

	count(filter: AccountValuationsPageFilter): Observable<ServiceResponse<number>> {
		return of();
	}

	downloadAccountValuationFile(accountValuation: AccountValuation): Observable<ServiceResponse<MdibFile>> {
		return of(new ServiceResponse<MdibFile>());
	}

}
