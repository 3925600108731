import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

import { InternationalPaymentDetails } from '../models/international-payment-details';

export class InternationalPaymentDetailsForm {

	private form: FormGroup;

	private readonly internalPaymentDetails;

	constructor(private fb: FormBuilder, internalPaymentDetails?: InternationalPaymentDetails ) {
		this.internalPaymentDetails = internalPaymentDetails || new InternationalPaymentDetails();
		this.create();
		this.subscriptions();
	}

	private create() {
		this.form = this.fb.group({
			'bankCountry': [this.internalPaymentDetails.bankCountry],
		});
	}

	private subscriptions() {
		this.bankCountry.valueChanges.subscribe(bankCountry => this.internalPaymentDetails.bankCountry = bankCountry);
	}

	get group(): AbstractControl {
		return this.form;
	}

	get model(): InternationalPaymentDetails {
		return this.internalPaymentDetails;
	}

	get bankCountry(): AbstractControl {
		return this.form.get('bankCountry');
	}
}
