import {CommunicationTypes} from './commons.enum';

export class Communication {
	value: string;
	type: CommunicationTypes;
	notificationPhoneNumber?: string;
	notificationEmailRecipient?: string;

	constructor(communication?: Partial<Communication>) {
		Object.assign(this, communication);
	}
}
