import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { SepaDirectDebitContractsService } from './sepa-direct-debit-contracts.service';
import { SepaDirectDebitContract } from '../model/sepa-direct-debit-contract';
import { BackendOperation, BackendOperationStub, BackendOperationType, SearchCriteria, SearchResult } from '@mdib/core/adapters';

export class SepaDirectDebitContractsServiceStub extends SepaDirectDebitContractsService {
	// FIXME - To be removed and included in search operation
	public list(index?: number, count?: number, counterpartyAccountNumber?: string): Observable<ServiceResponse<SepaDirectDebitContract[]>> {
		return of();
	}

	public count(counterpartyAccountNumber?: string): Observable<ServiceResponse<number>> {
		return of();
	}

	public create(): BackendOperation<SepaDirectDebitContract, SepaDirectDebitContract> {
		return new BackendOperationStub(BackendOperationType.CREATE);
	}

	public retrieve(): BackendOperation<string, SepaDirectDebitContract> {
		return new BackendOperationStub(BackendOperationType.RETRIEVE);
	}

	public update(): BackendOperation<SepaDirectDebitContract, SepaDirectDebitContract> {
		return new BackendOperationStub(BackendOperationType.UPDATE);
	}

	public delete(): BackendOperation<SepaDirectDebitContract, SepaDirectDebitContract> {
		return new BackendOperationStub(BackendOperationType.DELETE);
	}

	public search(): BackendOperation<SearchCriteria<any>, SearchResult<SepaDirectDebitContract>> {
		return new BackendOperationStub(BackendOperationType.SEARCH);
	}
}
