import { Injectable } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';

import { CommunicationFormBuilder, AddressFormBuilder } from '@mdib/commons';
import { ConfigurationService, CommonValidator } from '@mdib/utils';
import { AccountFormBuilder } from '@mdib/cash-accounts';

import { BeneficiaryFormGroup } from './beneficiary-form-group';

@Injectable({
	providedIn: 'root',
})
export class BeneficiaryFormBuilder {

	constructor(
		private configurationService: ConfigurationService,
		// Other Builders
		private accountFormBuilder: AccountFormBuilder,
		private addressFormBuilder: AddressFormBuilder,
		private communicationFormBuilder: CommunicationFormBuilder,
	) { }

	public build(): BeneficiaryFormGroup {
		// Get configuration parameters
		const fullNameML = this.configurationService.instant('beneficiaries.fields.fullName.maxLength');
		const aliasML = this.configurationService.instant('beneficiaries.fields.alias.maxLength');

		// Build an form empty with validation
		return new BeneficiaryFormGroup({
			id: new FormControl(),
			fullName: new FormControl('', [CommonValidator.required, Validators.maxLength(fullNameML)]),
			alias: new FormControl('', [CommonValidator.required, Validators.maxLength(aliasML)]),
			account: this.accountFormBuilder.build(),
			address: this.addressFormBuilder.build(),
			communication: this.communicationFormBuilder.build(),
		});
	}
}
