import { Injectable } from '@angular/core';
import { AbstractOrderXCLModel, FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, MultigoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { FileEntryXcl, FileMapperXclService } from '@mdib-xcl/utils';
import { AccountValuation, AccountValuationsCommonService, AccountValuationsPageFilter } from '@mdib/account-valuations';
import { ParameterModel } from '@mdib/http';
import { MdibFile, ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { AccountValuationXcl } from '../model/account-valuation-xcl';
import { AccountValuationsMapperXclService } from './account-valuations-mapper-xcl.service';


@Injectable({
	providedIn: 'root'
})
export class AccountValuationsXclService extends AccountValuationsCommonService {
	constructor(private xclAccountValuationsMapperService: AccountValuationsMapperXclService,
		private xclHttpService: XclHttpService,
		private feedbacksExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private fileMapperXclService: FileMapperXclService) {
		super();
	}

	public list(offset?: number, limit?: number, filter?: AccountValuationsPageFilter): Observable<ServiceResponse<AccountValuation[]>> {
		const start = (offset === undefined || limit === undefined) ? '0' : (offset * limit).toString();
		const params: ParameterModel[] = [
			new ParameterModel('start', start),
			new ParameterModel('accountNumber', filter.cashAccount.number),
			new ParameterModel('operationStatus', '9000')
		];

		// When no maxResults parameter is provided, the CBS returns all the values
		if (!isNullOrUndefined(limit)) {
			params.push(new ParameterModel('maxResults', limit.toString()));
		}

		// Filter
		this.fillFilterCriteria(params, filter);

		const xclObservable = this.xclHttpService.execute('account-valuations-list', XclHttpService.GET, params) as Observable<MultigoalOrderXCLModel<AccountValuationXcl>>;

		// Extract the account valuation list from the root "goalList" property returned by the XCL and return an
		// array of account valuation (functional model) corresponding to the one returned by the XCL (technical model)
		return xclObservable.pipe(map((xclOrder: MultigoalOrderXCLModel<AccountValuationXcl>) =>
			new ServiceResponse<AccountValuation[]>(
				this.xclAccountValuationsMapperService.accountValuationXclListToAccountValuationList(xclOrder.goalList),
				this.feedbacksExtractor.extract(xclOrder)
			)
		));
	}

	public count(filter: AccountValuationsPageFilter): Observable<ServiceResponse<number>> {
		const params: ParameterModel[] = [
			new ParameterModel('start', '0'),
			new ParameterModel('accountNumber', filter.cashAccount.number),
			new ParameterModel('operationStatus', '9000')
		];

		if (filter.valuationYear) {
			params.push(new ParameterModel('valuationYear', filter.valuationYear.toString()));
		}

		// Filter
		this.fillFilterCriteria(params, filter);

		return this.xclHttpService.execute('account-valuations-list', XclHttpService.GET, params)
			.pipe(map((xclOrder: AbstractOrderXCLModel) =>
				new ServiceResponse<number>(
					xclOrder.rowCount,
					this.feedbacksExtractor.extract(xclOrder),
				)
			));
	}

	public downloadAccountValuationFile(accountValuation: AccountValuation): Observable<ServiceResponse<MdibFile>> {
		const parameters: ParameterModel[] = [
			new ParameterModel('accountNumber', accountValuation.accountNumber),
			new ParameterModel('valuationYear', accountValuation.valuationDate.toString().substr(0, 4)),
			new ParameterModel('valuationReference', accountValuation.operationReference),
			new ParameterModel('callMode', '01'),
		];

		return this.xclHttpService.execute('account-valuation-document-download', XclHttpService.GET, parameters)
		.pipe(map((xclOrder: MonogoalOrderXCLModel<FileEntryXcl>) =>
			new ServiceResponse<MdibFile>(
				this.fileMapperXclService.fileXclToMdibFile(xclOrder.goal),
				this.feedbacksExtractor.extract(xclOrder),
			)
		));
	}

	private fillFilterCriteria(params: ParameterModel[], filter: AccountValuationsPageFilter | undefined) {
		if (isNullOrUndefined(filter)) {
			return;
		}

		if (!isNullOrUndefined(filter.valuationYear)) {
			params.push(new ParameterModel('valuationYear', filter.valuationYear.toString()));
		}
	}
}
