import { PersonsService } from '@mdib/customers';
import { Observable, throwError } from 'rxjs';
import { tap, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { XclSignatureContext } from '@mdib-xcl/core';
import { XclAPI } from '@mdib-xcl/utils';
import { ConfigService } from '@mdib/config';
import { ParameterModel } from '@mdib/http';
import { PaymentOperation } from '@mdib/payments';
import { SignableOperation } from '@mdib/signature';
import { Feedback, FeedbackTypes, ServiceResponse, Status } from '@mdib/utils';

import { SEPAPaymentOperationXCLModel } from '../model/xcl-sepa-payment-operation';
import { PaymentsOperationMapperXclService } from './payments-operation-mapper-xcl.service';

@Injectable()
export class SepaPaymentXclService {

	private baseUri = 'paymentoperations';

	constructor(private configService: ConfigService,
				private xclHttpService: XclHttpService,
				private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
				private mapper: PaymentsOperationMapperXclService,
				private personsService: PersonsService) {
	}

	public initPaymentOperation(payment: PaymentOperation<null>): Observable<MonogoalOrderXCLModel<any>> {

		const xclObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toSEPAPaymentOperationGoalXCLModel(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.POST, [], paymentXcl) as Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>;
				}),
			);

		return xclObservable;
	}

	public get(reference: string): Observable<ServiceResponse<PaymentOperation<null>>> {
		const paymentOperation = new PaymentOperation<null>();

		const parameters: ParameterModel[] = [
			new ParameterModel('reference', reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> = this.xclHttpService.execute(
			this.baseUri + '-get', XclHttpService.GET, parameters, paymentOperation,
		) as Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>;

		return this.getFunctionalObservable(xclObservable, paymentOperation);
	}

	// This method is used to initiate the creation of a payment, not for the actual retrieve -> see getPaymentOperation
	public retrievePaymentOperation(reference: string): Observable<MonogoalOrderXCLModel<any>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'retrieve'),
			new ParameterModel('reference', reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, parameters,
		) as Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>;
		return xclObservable;
	}

	/**
	 * Invokes call to XCL to initiate deletion of payment operation
	 * @param {string} operationReference
	 * @returns {Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>}
	 */
	public initDeletePaymentOperation(operationReference: string): Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('reference', operationReference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> = this.xclHttpService.execute(
			this.baseUri + '-get', XclHttpService.DELETE, parameters,
		) as Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>;

		return xclObservable;

	}

	public retrieveDeletePaymentOperation(operationReference: string): Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', XclAPI.retrieve),
			new ParameterModel('reference', operationReference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, parameters,
		) as Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>;

		return xclObservable;

	}

	public validateDelete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {

		const signablePaymentOperation = new SignableOperation(new PaymentOperation<null>());

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', XclAPI.validate),
			new ParameterModel('reference', reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, parameters) as Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>;

		return this.getFunctionalObservableWithSignableOperation(xclObservable, signablePaymentOperation);
	}

	public confirmDeletePaymentOperation(operationReference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {

		const signablePaymentOperation = new SignableOperation(new PaymentOperation<null>());

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', XclAPI.confirm),
			new ParameterModel('reference', operationReference),
		];
		const xclObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, parameters,
		) as Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>;

		return this.getFunctionalObservableWithSignableOperation(xclObservable, signablePaymentOperation);
	}

	public signPaymentDeletion(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'sign'),
			new ParameterModel('reference', signablePaymentOperation.reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, parameters, null,
		) as Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>;

		return this.getFunctionalObservableWithSignableOperation(xclObservable, signablePaymentOperation);

	}

	public validatePaymentOperation(reference: string, payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null>>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'validate'),
			new ParameterModel('reference', reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toSEPAPaymentOperationGoalXCLModel(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, parameters, paymentXcl) as Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>;
				}),
			);

		return this.getFunctionalObservable(xclObservable, payment, XclAPI.validate).pipe(
			tap(() => {
				payment.signatureContext = new XclSignatureContext({signableReference: payment.reference});
				if (payment.status === Status.NotAuthorizedToSign) {
					throwError('Payment is not authorized to sign !');
				}
			}));
	}

	public confirmPaymentOperation(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null>>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'confirm'),
			new ParameterModel('reference', payment.reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toSEPAPaymentOperationGoalXCLModel(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, parameters, paymentXcl) as Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>;
				}),
			);

		return this.getFunctionalObservable(xclObservable, payment);
	}

	/**
	 * Invokes call to XCL to directly sign the single payment operation.
	 * @param payment Instance of PaymentOperation<null> to be signed.
	 * @return Observable<ServiceResponse<PaymentOperation<null>>> Functional observable of PaymentOperation<null>
	 */
	public signPaymentOperation(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null>>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'sign'),
			new ParameterModel('reference', payment.reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toSEPAPaymentOperationGoalXCLModel(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, parameters, paymentXcl) as Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>;
				}),
			);

		return this.getFunctionalObservable(xclObservable, payment).pipe(
			tap(() => {
				if (payment.status === Status.Error) {
					throw ServiceResponse.emptyWithOnefeedback(new Feedback('errorWhenSigningPayment', FeedbackTypes.FRONTEND_ERROR, 'Something went wrong when signing the payment operation'));
				}
			}));
	}

	private getFunctionalObservable(technicalObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>, paymentOperation: PaymentOperation<null>, phase = XclAPI.retrieve): Observable<ServiceResponse<PaymentOperation<null>>> {
		return technicalObservable.pipe(
			map((sepaPaymentOperationOrderXCLModel: MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>) => {
				return new ServiceResponse(
					this.mapper.feedFromSEPAPaymentOperationOrderXCLModel(paymentOperation, sepaPaymentOperationOrderXCLModel, phase),
					this.feedbackExtractor.extract(sepaPaymentOperationOrderXCLModel),
				);
			}));
	}

	private getFunctionalObservableWithSignableOperation(technicalObservable: Observable<MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>>, signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {
		return technicalObservable.pipe(
			map((sepaPaymentOperationOrderXCLModel: MonogoalOrderXCLModel<SEPAPaymentOperationXCLModel>) => {
				return new ServiceResponse(
					this.mapper.feedFromSEPAPaymentOperationOrderXCLModelToSignableOperation(signablePaymentOperation, sepaPaymentOperationOrderXCLModel),
					this.feedbackExtractor.extract(sepaPaymentOperationOrderXCLModel),
				);
			}));
	}
}
