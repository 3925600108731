import { Injector } from '@angular/core';
import { BackendOperationState, BackendOperationStep } from '@mdib/core/adapters';
import { Observable } from 'rxjs';
import { BackendOperationXcl } from './backend-operation-xcl';
import { DecisionTree } from './decision-tree';

import { XclCallConfiguration } from './xcl-call-configuration';

export class BackendCreationOperationXcl<IN, OUT, X> extends BackendOperationXcl<IN, OUT, X> {

	// State machine
	protected decision: DecisionTree = {
		'____': {
			triggers: { 'start': this.init, 'validate': this.initAndValidate },
			defaultTrigger: 'start',
			expectedStates: ['1500', '6000', '6200'],
		},
		'1500': {
			triggers: { 'validate': this.validate },
			defaultTrigger: 'validate',
			expectedStates: ['6000', '6200'],
		},
		'6000': {
			triggers: { 'cancel': this.cancel, 'confirm': this.confirm },
			defaultTrigger: 'confirm',
			expectedStates: ['9000'],
		},
		'6200': {
			triggers: { 'cancel': this.cancel, 'save': this.confirm, 'sign': this.sign },
			expectedStates: ['7000', '9000'],
		},
	};

	constructor(injector: Injector) {
		super(injector);

		// Intialize step
		const machineState = this.decision[this.xclState];
		const triggers = Object.keys((machineState && machineState.triggers) || {});
		this.curentStep = new BackendOperationStep(null, triggers);
	}

	protected getInitData(input: IN): any {
		return input;
	}

	protected getValidateData(input: IN): any {
		return input;
	}

	protected init(trigger: string, input?: IN): Observable<BackendOperationStep<OUT>> {
		return this.calls(trigger, [
			new XclCallConfiguration({ action: 'init', method: 'POST', body: this.getInitData(input) }),
			new XclCallConfiguration({ action: 'retrieve', useReference: true, state: BackendOperationState.WAITING_DATA }),
		]);
	}

	protected initAndValidate(trigger: string, input?: IN): Observable<BackendOperationStep<OUT>> {
		return this.calls(trigger, [
			new XclCallConfiguration({ action: 'init', method: 'POST', body: this.getInitData(input) }),
			new XclCallConfiguration({ action: 'retrieve', useReference: true, state: BackendOperationState.WAITING_DATA }),
			new XclCallConfiguration({ action: 'validate', useReference: true, body: this.getValidateData(input), state: BackendOperationState.WAITING_ACTION }),
		]);
	}

	protected validate(trigger: string, input: IN): Observable<BackendOperationStep<OUT>> {
		return this.calls(trigger, [
			new XclCallConfiguration({ action: 'validate', useReference: true, body: this.getValidateData(input), state: BackendOperationState.WAITING_ACTION }),
		]);
	}

	protected confirm(trigger: string, input?: IN): Observable<BackendOperationStep<OUT>> {
		return this.calls(trigger, [
			new XclCallConfiguration({ action: 'confirm', useReference: true, state: BackendOperationState.SUCCEEDED }),
		]);
	}

	protected sign(trigger: string, input?: IN): Observable<BackendOperationStep<OUT>> {
		return this.calls(trigger, [
			new XclCallConfiguration({ action: 'sign', useReference: true, state: BackendOperationState.SUCCEEDED }),
		]);
	}
}
