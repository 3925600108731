import { ModuleWithProviders, NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { BeneficiariesServiceV2 } from '@mdib/beneficiaries';

import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';

import { BeneficiariesXclService as BeneficiariesXclServiceV2 } from './services/beneficiaries-xcl.service';

@NgModule()
export class XclBeneficiariesModule {
	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(BeneficiariesServiceV2, adapterId, BeneficiariesXclServiceV2);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: XclBeneficiariesModule,
			providers: [
			]
		};
	}
}
