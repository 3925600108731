import { Injectable } from '@angular/core';
import { FunctionalFeedbacksFromXclOrderExtractor, MultigoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { XclAuthenticationStatisticDataModel } from '../model/xcl-authentication-statistic-data-model';
import { AuthenticationDataMapperXclService } from './authentication-data-mapper-xcl.service';
import { ParameterModel } from '@mdib/http';
import { SessionUtilsService } from '@mdib/sessions';
import { AuthenticationStatisticData } from '@mdib/sessions/model/authentication-statistic-data';
import { AuthenticationDataService } from '@mdib/sessions/service/authentication-data/authentication-data.service';
import { CacheService, ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationDataXclService extends AuthenticationDataService {

	constructor(private xclHttpService: XclHttpService,
				private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
				private cacheService: CacheService,
				private authenticationDataMapperXclService: AuthenticationDataMapperXclService,
				private sessionUtilsService: SessionUtilsService,
	) {
		super();
	}

	getStatistics(): Observable<ServiceResponse<AuthenticationStatisticData[]>> {

		const params: ParameterModel[] = [];
		params.push(new ParameterModel('principalIdentification', this.sessionUtilsService.getSessionActiveUserId()));

		return this.cacheService.get(
			this.cacheService.generateCacheKeyFromParams('authentication-statistic-data', params),
			this.xclHttpService.execute('authentication-statistic-data', XclHttpService.GET, params)
				.pipe(map((xclOrder: MultigoalOrderXCLModel<XclAuthenticationStatisticDataModel>) => {
					return new ServiceResponse<AuthenticationStatisticData[]>(
						this.authenticationDataMapperXclService.getFunctionalModelArrayFromTechnicalModelArray(xclOrder.goalList),
						this.feedbackExtractor.extract(xclOrder));
				}),
		));
	}

}
