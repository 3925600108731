import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Authentication, AuthenticationModesService, Session, SessionsService, SessionUtilsService, User, SessionResetService, AuthenticationTimeoutService } from '@mdib/sessions';
import { SvgIconPath } from '@mdib/styling';
import { UniqueIdentifierService, ConfigurationService, Feedback, FeedbackTypes, CacheService, ServiceResponse } from '@mdib/utils';
import { ServiceResponseNotificationMessagesMapperService, NotificationMessageService, NotificationMessage } from '@mdib/notification-message';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Component({
	templateUrl: './session-creation-page.component.html',
	styleUrls: ['./session-creation-page.component.scss'],
})
export class SessionCreationPageComponent implements OnInit {

	public NAMESPACE = 'sessionsPagesModule.sessionCreationPageComponent.';

	public selectedUser: User;
	public availableModes: Array<any>;
	public selectedMode: any;

	public errorMessage: string = null;

	public iconPath = SvgIconPath.NWBLOGO;

	constructor(private _activatedRoute: ActivatedRoute,
		private router: Router,
		private uniqueIdService: UniqueIdentifierService,
		private sessionsService: SessionsService,
		private sessionUtilsService: SessionUtilsService,
		private authenticationsService: AuthenticationModesService,
		private configurationService: ConfigurationService,
		private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService,
		private translateService: TranslateService,
		private notificationMessageService: NotificationMessageService,
		private authenticationTimeoutService: AuthenticationTimeoutService,
		private sessionResetService: SessionResetService,
		private cacheService: CacheService
	) {
	}

	public ngOnInit() {
		this.cacheService.invalidate();
		this.authenticationTimeoutService.init();

		// Redirect to the dashboard if connected
		if (this.sessionUtilsService.isActiveUserConnected()) {
			this.router.navigate(['/dashboard']);
		}

		this.sessionResetService.reset().subscribe(
			() => { },
			(error: ServiceResponse<any>) => {
				this.handleEvent(new ErrorEvent('error', { error: error.getFeedbacks()[0].key }));
			});

		// Available modes
		const iterator = this.authenticationsService.getModes();
		this.availableModes = Array.from(iterator).map(mode => mode.id);

		const checkBrowserCompatibility = this.configurationService.instant('application.checkBrowserCompatibility');
		if (checkBrowserCompatibility) {
			this.setBrowserCompatibilityInfo();
		}
	}

	public setBrowserCompatibilityInfo() {
		if (!this.checkClientBrowserCompatibility()) {
			setTimeout(() => {
				const warningMessage: NotificationMessage = <NotificationMessage>{
					title: 'notification.message.' + FeedbackTypes.FRONTEND_WARNING,
					type: FeedbackTypes.FRONTEND_WARNING,
					message: 'notification.message.browserCompatibilityWarning',
					scrollToMe: true
				};
				this.notificationMessageService.sendMessage(warningMessage);
			}, 1);
		}
	}

	// This method detects client's browser checking userAgent string.
	// Since some browsers present strings related to other browsers
	// (e.g. some versions of Opera have the "Chrome" identifier),
	// it's important to keep the order to avoid false positives.
	public checkClientBrowserCompatibility(): Boolean {
		const browserToVersion = this.getBrowserToVersion();

		// Opera
		if ((browserToVersion.has('Opera') || browserToVersion.has('OPR'))) {
			return false;
			// Chrome
		} else if (browserToVersion.has('Chrome')) {
			return browserToVersion.get('Chrome') >= 64;
			// Safari browser
		} else if (browserToVersion.has('Safari')) {
			return false;
			// iOS mobile app
		} else if (browserToVersion.has('AppleWebKit') && !browserToVersion.has('Safari')) {
			return true;
			// Firefox
		} else if (browserToVersion.has('Firefox')) {
			return browserToVersion.get('Firefox') >= 58;
			// Internet Explorer
		} else if (browserToVersion.has('Mozilla')) {
			return browserToVersion.get('Mozilla') >= 5;
			// Other
		} else {
			return false;
		}
	}

	public getBrowserToVersion(): Map<string, number> {
		const browsers = window.navigator.userAgent.split(' ').filter(string => string.indexOf('/') !== -1);
		const browserToVersion = new Map<string, number>();
		browsers.forEach((browser: string) => {
			const browserAndVersion = browser.split('/');
			browserToVersion.set(browserAndVersion[0], +(browserAndVersion[1].split('.')[0]));
		});

		return browserToVersion;
	}

	public setSelectedMode(mode) {
		if (mode && mode.id) {
			this.selectedMode = mode.id;
		}
	}

	public authenticated(auth: Authentication) {
		// Retrieve or create the active session
		let session: Session = this.sessionsService.getSession();
		if (!session) {
			const sessionId = this.uniqueIdService.getHexId(32);
			session = new Session(sessionId, auth.type);
			this.sessionsService.setSession(session, session.id);
			this.sessionsService.useSession(session.id);
		}

		// Switch session user to the authenticated one
		session.activeUser = auth.user;
		session.close = auth.close;
		session.keepAlive = auth.keepAlive;
		this.sessionsService.setSessionsAndActiveSessionIdInStorage(session.id);

		// Redirect to the accounts
		this.router.navigate(['/dashboard']);
	}

	public handleEvent(event: ErrorEvent) {
		this.errorMessage = event && event.error;

		// if errorMessage is still null by now, we check if the event has gone through the IE polyfill
		if (!this.errorMessage) {
			this.errorMessage = event && event.message;
		}

		if (this.errorMessage) {
			this.notificationMessageService.sendMessage(<NotificationMessage>{
				title: 'notification.message.' + FeedbackTypes.FRONTEND_ERROR,
				type: FeedbackTypes.FRONTEND_ERROR,
				message: 'notification.error.' + this.errorMessage,
				scrollToMe: true,
				isClosable: true
			});
		}
	}
}
