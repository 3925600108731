import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@mdib/utils';

import { StandingOrdersService } from './service/standing-orders.service';
import { StandingOrdersServiceProxy } from './service/standing-orders.service.proxy';

/**
 * Holds generic tools related to term deposits.
 */
@NgModule()
export class StandingOrdersModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: StandingOrdersModule,
			providers: [
				{ provide: StandingOrdersService, useClass: StandingOrdersServiceProxy },
			]
		};
	}
}
