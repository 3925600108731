import { Injectable } from '@angular/core';
import { MdibFile, ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FileUploadersXclServiceStub {

	upload(file: MdibFile): Observable<ServiceResponse<MdibFile>> {
		return of();
	}
}
