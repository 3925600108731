import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifactionMessageComponent, NotificationMessageService } from '@mdib/notification-message';
import { TranslateService } from '@ngx-translate/core';
import { FeedBackIconHelper } from '../../model/feedback-icon-helper';
import { FeedbackTypes } from '../../model/utils.typings';
import { DefaultNotificationBannerStateHolderService } from '../../service/default-notification-banner-state-holder.service';

@Component({
	selector: 'mdib-default-banner-notification',
	templateUrl: './default-banner-notification.component.html',
	styleUrls: ['./default-banner-notification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultBannerNotificationComponent extends NotifactionMessageComponent implements OnInit {
	isDefaultBannerVisible: boolean;

	@Input() public notificationTypes: Array<string> = [
		FeedbackTypes.BACKEND_ERROR,
		FeedbackTypes.BACKEND_WARNING,
		FeedbackTypes.BACKEND_INFORMATION,
		FeedbackTypes.BACKEND_SUCCESS,
		FeedbackTypes.FRONTEND_ERROR,
		FeedbackTypes.FRONTEND_WARNING,
		FeedbackTypes.FRONTEND_INFORMATION,
		FeedbackTypes.FRONTEND_SUCCESS,
	];

	constructor(protected stateHolder: DefaultNotificationBannerStateHolderService,
				protected translateService: TranslateService,
				protected notificationMessageService: NotificationMessageService,
				protected elRef: ElementRef,
				private router: Router,
	) {
		super(translateService, notificationMessageService, elRef);
		this.stateHolder.getState().subscribe(
			(newState: boolean) => this.isDefaultBannerVisible = newState,
		);
	}

	ngOnInit() {
		super.ngOnInit();

		this.initMyself();
	}

	icon(type: string): string {
		return FeedBackIconHelper.getIconName(type);
	}

	protected initMyself() {
		this.stateHolder.setState(true);
		this.router.events.subscribe(() => this.clearAllNotifications());
	}

}
