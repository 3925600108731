import { PendingPaymentFrequency, OperationStatus, PaymentTypes, PendingPayment } from '@mdib/payments';

export class PendingPaymentsMock {

	static pendingPayments: PendingPayment[] = <PendingPayment[]> [
		{
			paymentDate: new Date(2018, 1, 2),
			operationDate: new Date(2018, 1, 1),
			reference: 'B7K27CWSIO4FPT3V',
			ordererAccountNumber: 'BE56305177635588',
			ordererAccountAlias: 'Daily',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'BE11736988971648',
			counterpartyName: 'Pierre Gewelt',
			paymentType: PaymentTypes.sepaDirectDebit,
			status: OperationStatus.awaitingSettlement,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 7.85,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 1, 8),
			reference: 'B7K274875O4FPTG5',
			ordererAccountNumber: 'BE20002815723823',
			ordererName: 'John Doe',
			counterpartyAccountNumber: 'NL91ABNA0417164300',
			counterpartyName: 'Hans-Jeroen Van der Steenbrillen',
			paymentType: PaymentTypes.internationalPayment,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 10000.00,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 1, 8),
			operationDate: new Date(2018, 1, 7),
			reference: 'B7K27CWSIO4FPT3W',
			ordererAccountNumber: 'BE56305177635588',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'FR1420041010050500013M02606',
			counterpartyName: 'Estelle Dupré',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 9.05,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 1, 11),
			reference: 'B7K27CWSIO4FPT3Z',
			ordererAccountNumber: 'BE56305177635588',
			ordererAccountAlias: 'Daily',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'BE95750642304658',
			counterpartyName: 'Jean Dupont',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 10.00,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 5, 26),
			reference: 'B4K48CWZZP0J9878',
			ordererAccountNumber: 'BE20002815723823',
			ordererAccountAlias: 'John Doe',
			ordererName: 'John Doe',
			counterpartyAccountNumber: 'BE12123412341234',
			counterpartyName: 'John Smith',
			paymentType: PaymentTypes.europeanStandingOrder,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 25,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 5, 11),
			reference: 'B72H74875O4F78G6',
			ordererAccountNumber: 'BE20002815723823',
			ordererAccountAlias: 'Daily',
			ordererName: 'John Doe',
			counterpartyAccountNumber: 'NL91ABNA0417164300',
			counterpartyName: 'Hans-Jeroen Van der Steenbrillen',
			paymentType: PaymentTypes.internationalPayment,
			status: OperationStatus.technicalIncident,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 5000.00,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 5, 11),
			reference: 'B72HABV75O8798G7',
			ordererAccountNumber: 'BE20002815723823',
			ordererAccountAlias: 'Daily',
			ordererName: 'John Doe',
			counterpartyAccountNumber: 'NL91ABNA0417164300',
			counterpartyName: 'Hans-Jeroen Van der Steenbrillen',
			paymentType: PaymentTypes.internationalPayment,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 200.00,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 1, 14),
			reference: 'B7K27CWSIO4FPH3R',
			ordererAccountNumber: 'BE56305177635588',
			ordererAccountAlias: 'Daily',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'BE74945239392707',
			counterpartyName: 'Online payments',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.technicalIncident,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 25.80,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 1, 14),
			reference: 'B7K27CWSIO4FPR3R',
			ordererAccountNumber: 'BE56305177635588',
			ordererAccountAlias: 'Daily',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'BE74945239392707',
			counterpartyName: 'Online payments',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.memoOrder,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 38.01,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 1, 17),
			reference: 'B7K27CWSIO4FPT3A',
			ordererAccountNumber: 'BE56305177635588',
			ordererAccountAlias: 'Daily',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'DE89370400440532013000',
			counterpartyName: 'Angela Brandebourg',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.memoOrder,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 59.99,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 1, 25),
			reference: 'B7K27CWSIO4FPT3Y',
			ordererAccountNumber: 'BE56305177635588',
			ordererAccountAlias: 'Daily',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'BE74945239392707',
			counterpartyName: 'Online payments',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 102.48,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 1, 28),
			reference: 'B7K27CWSIO4FPT3U',
			ordererAccountNumber: 'BE56305177635588',
			ordererAccountAlias: 'Daily',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'BE95750642304658',
			counterpartyName: 'For the kids',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 204.27,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 1, 28),
			reference: 'B7K27CWSIO4FPT3I',
			ordererAccountNumber: 'BE56305177635588',
			ordererAccountAlias: 'Daily',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'BE11736988971648',
			counterpartyName: 'Pierre Gewelt',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 402.08,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 2, 1),
			reference: 'B7K27CWSIO4FPT3P',
			ordererAccountNumber: 'BE56305177635588',
			ordererAccountAlias: 'Daily',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'BE11736988971648',
			counterpartyName: 'For next holidays',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 1285.00,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 2, 15),
			reference: 'B7K27CWSIO4FPT3Q',
			ordererAccountNumber: 'BE56305177635588',
			ordererAccountAlias: 'Daily',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'BE74945239392707',
			counterpartyName: 'Online payments',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.memoOrder,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 398.02,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 2, 16),
			reference: 'B7K27CWSIO4FPT3D',
			ordererAccountNumber: 'BE95750642304658',
			ordererName: 'For the kids',
			counterpartyAccountNumber: 'BE56305177635588',
			counterpartyName: 'Daily',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.memoOrder,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 5.00,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 2, 19),
			reference: 'B7K27CWSIO4FPT3D',
			ordererAccountNumber: 'BE95750642304658',
			ordererName: 'For the kids',
			counterpartyAccountNumber: 'BE95750642304658',
			counterpartyName: 'Jean Dupont',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 1.98,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 2, 19),
			reference: 'C7K27CWSIO4FPT3V',
			ordererAccountNumber: 'BE95750642304658',
			ordererName: 'For the kids',
			counterpartyAccountNumber: 'BE74945239392707',
			counterpartyName: 'Online payments',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 70.00,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 3, 5),
			reference: 'E7K27CWSIO4FPT3V',
			ordererAccountNumber: 'BE95750642304658',
			ordererName: 'For the kids',
			counterpartyAccountNumber: 'BE74945239392707',
			counterpartyName: 'Online payments',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.memoOrder,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 26.07,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 3, 5),
			reference: 'K7K27CWSIO4FPT3V',
			ordererAccountNumber: 'BE11736988971648',
			ordererName: 'For next holidays',
			counterpartyAccountNumber: 'FR1420041010050500013M02606',
			counterpartyName: 'Online payments',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 309.10,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 3, 5),
			reference: 'L7K27CWSIO4FPT3V',
			ordererAccountNumber: 'BE11736988971648',
			ordererName: 'For next holidays',
			counterpartyAccountNumber: 'FR1420041010050500013M02606',
			counterpartyName: 'Bernadette Gislain',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.memoOrder,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 755.89,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 3, 6),
			reference: 'M7K27CWSIO4FPT3V',
			ordererAccountNumber: 'BE11736988971648',
			ordererName: 'For next holidays',
			counterpartyAccountNumber: 'DE89370400440532013000',
			counterpartyName: 'Angela Brandebourg',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.awaitingProcessing,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 5000.00,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 3, 5),
			reference: 'N7K27CWSIO4FPT3V',
			ordererAccountNumber: 'BE11736988971648',
			ordererName: 'For next holidays',
			counterpartyAccountNumber: 'DE89370400440532013000',
			counterpartyName: 'Angela Brandebourg',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.memoOrder,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 856.00,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 3, 8),
			reference: 'O7K27CWSIO4FPT3V',
			ordererAccountNumber: 'BE11736988971648',
			ordererName: 'For next holidays',
			counterpartyAccountNumber: 'FR1420041010050500013M02606',
			counterpartyName: 'Bernadette Gislain',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.memoOrder,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 0.19,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 3, 15),
			reference: 'P7K27CWSIO4FPT3V',
			ordererAccountNumber: 'BE11736988971648',
			ordererName: 'For next holidays',
			counterpartyAccountNumber: 'FR1420041010050500013M02606',
			counterpartyName: 'Bernadette Gislain',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.technicalIncident,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 10000.00,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 4, 8),
			reference: 'L7K27CWSIO4FPT3V',
			ordererAccountNumber: 'BE11736988971648',
			ordererName: 'For next holidays',
			counterpartyAccountNumber: 'DE89370400440532013000',
			counterpartyName: 'Angela Brandebourg',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.memoOrder,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 37.00,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 4, 10),
			reference: 'Q7K27CWSIO4FPT3V',
			ordererAccountNumber: 'BE11736988971648',
			ordererName: 'For next holidays',
			counterpartyAccountNumber: 'BE74945239392707',
			counterpartyName: 'Online payments',
			paymentType: PaymentTypes.sepaCreditTransfer,
			status: OperationStatus.memoOrder,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 0.58,
			currency: 'EUR',
		},
		{
			paymentDate: new Date(2018, 10, 25),
			reference: 'B7K274875O4FPT44',
			ordererAccountNumber: 'BE56305177635588',
			ordererName: 'Daily',
			counterpartyAccountNumber: 'BE12123412341234',
			counterpartyName: 'Erwan Dupont',
			paymentType: PaymentTypes.instantPayment,
			status: OperationStatus.memoOrder,
			frequency: PendingPaymentFrequency.Unknown,
			amount: 25,
			currency: 'EUR',
		},
	];
}
