import { InjectionToken, Provider, SkipSelf, Optional } from '@angular/core';

import { UtilsHelper } from '@mdib/utils';

export const FEATURE = new InjectionToken('Feature');
export const NAMESPACE = new InjectionToken('Namespace');
export const SELF_NAMESPACE = new InjectionToken('Self Namespace');

export const TRANSLATIONS = new InjectionToken('Translations');
export const SELF_TRANSLATIONS = new InjectionToken('Self Translations');

export function fullNamespace(parent: string, self: string): string {
	return (parent ? parent + '.' : '') + self;
}

export function provideNamespace(feature: string, ns: string): Provider[] {
	return [
		{ provide: FEATURE, useValue: feature },
		{ provide: SELF_NAMESPACE, useValue: ns },
		{
			provide: NAMESPACE,
			useFactory: fullNamespace,
			deps: [[new SkipSelf(), new Optional(), NAMESPACE], SELF_NAMESPACE]
		}
	];
}

export function mergedTranslations(translations: any, selfTranslations: any[] | any) {
	if (!Array.isArray(selfTranslations)) {
		selfTranslations = [selfTranslations];
	}
	const expandedTranslations = selfTranslations.map(selfTranslation =>
		(selfTranslation.path || '').split('.').filter(e => e).reduceRight((o, k) => ({ [k]: o }), selfTranslation.translations)
	);
	return UtilsHelper.mergeObjects(...expandedTranslations, translations);
}

export function provideTranslations(translations: any, path?: string): Provider[] {
	return [
		{ provide: SELF_TRANSLATIONS, multi: true, useValue: { path, translations } },
		{
			provide: TRANSLATIONS,
			useFactory: mergedTranslations,
			deps: [[new SkipSelf(), new Optional(), TRANSLATIONS], SELF_TRANSLATIONS]
		}
	];
}
