import { Injectable } from '@angular/core';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { StandingOrderDetails } from '../../types/models/standing-order-details';
import { PaymentOperation } from '../../types/models/payment-operation';
import { SignableOperation } from '@mdib/signature';

@Injectable()
export abstract class StandingOrderService {

	/**
	 *
	 * @param {PaymentOperation<StandingOrderDetails>} payment
	 * @return {Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>}
	 */
	public abstract validate(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>;

	/**
	 *
	 * @param {PaymentOperation<StandingOrderDetails>} payment
	 * @return {Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null> | null>}
	 */
	public abstract confirm(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null> | null>;

	/**
	 * Signs and submit the payment operation
	 * @abstract
	 * @param {PaymentOperation<StandingOrderDetails>} payment
	 * @returns {(Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>|null>>)}
	 * @memberof PaymentsService
	 * @example
	 * this.paymentsService.sign(paymentOperation).subscribe(
	 *        (serviceResponse: ServiceResponse<PaymentOperation<StandingOrderDetails>>) => this.save(serviceResponse.getModel())
	 * );
	 */
	public abstract sign(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>;

	/**
	 * Get a standing order from the shopping basket
	 * @param {string} reference
	 * @returns {Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>}
	 */
	public abstract getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>;

	/**
	 * Get a standing order
	 * @param {string} reference
	 * @returns {Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>>}
	 */
	public abstract get(reference: string): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>>;


	/**
	 *
	 * @param {PaymentOperation<StandingOrderDetails>} payment
	 * @return {Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>}
	 */
	public abstract validateUpdate(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>>;

	/**
	 * Delete standing order
	 * @param {string} reference
	 * @returns {Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>> | null>>}
	 */
	public abstract delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>> | null>> ;

	/**
	 * Sign the deletion of standing order
	 * @param {SignableOperation<any>} signablePaymentOperation
	 * @returns {Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>> | null>>}
	 */
	public abstract signDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>> | null>> ;

	/**
	 * Confirm the deletion of standing order
	 * @param {SignableOperation<any>} signablePaymentOperation
	 * @returns {Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>> | null>>}
	 */
	public abstract confirmDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>> | null>> ;
}
