import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DigipassSignatureService, DigipassSignature } from '@mdib/signature-modes';
import { Signable } from '@mdib/signature';
import { ServiceResponse } from '@mdib/utils';
import { AbstractOrderXCLModel } from '@mdib-xcl/http';

import { SignatureServiceXcl } from './signature.service-xcl';

@Injectable({
	providedIn: 'root'
})
export class DigipassSignatureXclService extends SignatureServiceXcl implements DigipassSignatureService {

	protected SIGNATURE_TYPE = 'TWO';

	public prepareSignatureFor(object: Signable): Observable<ServiceResponse<DigipassSignature>> {
		return this.executeInitRequest(object)
			.pipe(map((response: AbstractOrderXCLModel) =>
				new ServiceResponse(
					{
						typeName: this.SIGNATURE_TYPE,
						challenges: response['challenges'].map(challenge => ({
							fieldName: challenge['dataToSign']['name'],
							fieldValue: challenge['dataToSign']['value'],
							challenge: challenge['value'],
						}))
					},
					this._feedbackExtractor.extract(response),
				),
			));
	}

}
