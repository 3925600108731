import { Injectable } from '@angular/core';
import { ForgotPasswordService, ForgotPasswordModel } from '@mdib/forgot-password';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceResponse, ConfigurationService } from '@mdib/utils';
import { SignableOperation } from '@mdib/signature';
import { XclHttpService, FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel } from '@mdib-xcl/http';
import { XclForgotPasswordModel } from '../model/xcl-forgot-password-model';
import { ForgotPasswordMapperXclService } from './forgot-password-mapper-xcl.service';
import { ParameterModel } from '@mdib/http';

@Injectable({
	providedIn: 'root'
})
export class ForgotPasswordXclService extends ForgotPasswordService {

	constructor(
		protected configurationService: ConfigurationService,
		private xclHttpService: XclHttpService,
		private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private mapper: ForgotPasswordMapperXclService,
	) {
		super();
	}

	public init(userId: string): Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>> {
		const forgotPasswordOperation = new SignableOperation<ForgotPasswordModel>(new ForgotPasswordModel());

		const initBody = {
			'language': 'nl',
			'securityContext': {
				'businessChannel': 'B2C',
				'company': this.configurationService.instant('xcl.company.id'),
				'authenticationType': 'PWD',
				'communicationMode': this.configurationService.instant('xcl.authentication.communicationMode'),
				'deviceType': 'DSK',
				'project': this.configurationService.instant('xcl.authentication.project')
			},
			'user': {
				'loginName': userId
			}
		};

		const xclObservable: Observable<MonogoalOrderXCLModel<XclForgotPasswordModel>> =
			this.xclHttpService.execute(
				'forgot-password', XclHttpService.POST, [], initBody,
			) as Observable<MonogoalOrderXCLModel<XclForgotPasswordModel>>;

		return this.getFunctionalObservable(xclObservable, forgotPasswordOperation);
	}

	public confirm(forgotPasswordOperation: SignableOperation<ForgotPasswordModel>): Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>> {
		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'confirm'),
			new ParameterModel('reference', forgotPasswordOperation.reference),
		];
		const xclObservable: Observable<MonogoalOrderXCLModel<XclForgotPasswordModel>> = this.xclHttpService.execute(
			'forgot-password', XclHttpService.PUT, parameters, this.mapper.toTechnicalForgotPasswordModel(forgotPasswordOperation),
		) as Observable<MonogoalOrderXCLModel<XclForgotPasswordModel>>;
		return this.getFunctionalObservable(xclObservable, forgotPasswordOperation);
	}

	/**
	 * Converts technical model observable to functional model observable
	 * @param { Observable<MonogoalOrderXCLModel<XclForgotPasswordModel>> }technicalObservable
	 * @param { SignableOperation<ForgotPasswordModel> } functionalForgotPasswordOperation
	 * @returns { Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>> } : functional observable
	 */
	private getFunctionalObservable(technicalObservable: Observable<MonogoalOrderXCLModel<XclForgotPasswordModel>>,
		functionalForgotPasswordOperation: SignableOperation<ForgotPasswordModel>): Observable<ServiceResponse<SignableOperation<ForgotPasswordModel>>> {
		return technicalObservable
			.pipe(map((technicalForgotPasswordOrder: MonogoalOrderXCLModel<XclForgotPasswordModel>) => {
				return new ServiceResponse(
					this.mapper.toFunctionalForgotPasswordModel(technicalForgotPasswordOrder, functionalForgotPasswordOperation),
					this.feedbackExtractor.extract(technicalForgotPasswordOrder),
				);
			}));
	}
}
