export enum MockUsers {
	MockAccess = 'MockAccess',
	mock= 'mock',
	mockb2b = 'mockb2b'
}

export enum BusinessRoles {
	b2c = 'b2c',
	b2b = 'b2b',
}
