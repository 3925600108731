import { Injector, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';

import { SequenceCache } from '@mdib/core/meta';

import { SearchClass } from '../../types/search/search-class';
import { PaginatedSearchParams } from '../../types/pages/paginated-search-params';
import { SearchResult } from '../../types/search/search-result';
import { SequenceCacheStrategyService } from '../../services/sequence-cache-strategy.service';
import { BackendSearchService } from '../../types/backends/backend-service';
import { SearchCriteria } from '../../types/search/search-criteria';
import { all } from '../../types/search/all.criteria';
import { and } from '../../types/search/and.criteria';

export abstract class SearchPageComponent<T> implements OnInit {

	/**
	 * Criteria managed by the search form
	 */
	public searchModel: SearchClass;

	/**
	 * Criteria managed by the component
	 */
	public searchPagination: PaginatedSearchParams = {
		// Pagination
		pageIndex: 0,
		pageSize: 10,
	};

	/**
	 * Resuluts of the curent research
	 */
	public searchResult = new SearchResult(null, 0);
	protected searchCache: SequenceCache<T> = new SequenceCache();

	/**
	 * Dependencies
	 */
	protected router: Router;
	protected activatedRoute: ActivatedRoute;
	protected location: Location;
	protected sequenceCacheStrategyService: SequenceCacheStrategyService;

	constructor(
		injector: Injector,
		private backendService: BackendSearchService<T>,
	) {
		this.router = injector.get(Router);
		this.activatedRoute = injector.get(ActivatedRoute);
		this.location = injector.get(Location);
		this.sequenceCacheStrategyService = injector.get(SequenceCacheStrategyService);
	}

	/**
	 *  Initialization of the list
	 */
	public ngOnInit() {
		// The search criteria may change
		this.activatedRoute.queryParams.subscribe(params => {
			this.loadSearchModel(params);
			this.fetchData();
		});
	}

	/**
	 * Handle pagination
	 */
	public paginate(pageEvent: PageEvent) {
		this.searchPagination.pageIndex = pageEvent.pageIndex;
		this.searchPagination.pageSize = pageEvent.pageSize;
		this.saveSearchModel();

		// Fetch the results
		const index = this.searchPagination.pageIndex * this.searchPagination.pageSize;
		const count = Math.min(this.searchPagination.pageSize, this.searchResult.total - index);
		const results = this.searchCache.get(index, count);
		if (results) {
			this.searchResult = new SearchResult(results, this.searchResult.total, index, count);
		} else {
			this.fetchData();
		}
	}

	/**
	 * Handle new search
	 */
	public search(searchModel: SearchClass) {
		this.searchPagination.pageIndex = 0;
		this.searchModel = searchModel;
		this.searchCache.invalidate();
		this.saveSearchModel();
		this.fetchData();
	}

	/**
	 * Fetch a range of items to render
	 */
	public fetchData() {
		const criteria = and(this.searchModel.asCriteria(), this.getInternalCriteria());
		this.sequenceCacheStrategyService
			.fill(this.searchCache, this.backendService, this.searchPagination, criteria)
			.subscribe(results => {
				this.searchResult = results;
			});
	}

	/**
	 * Returns the internal criteria
	 */
	protected getInternalCriteria(): SearchCriteria<any> {
		return all();
	}

	/**
	 * Initiliaze the search model
	 */
	protected loadSearchModel(params: Params) {
		const parameters: any = {};
		Object.assign(parameters, params);

		// Load the pagination
		this.searchPagination.pageIndex = Number.parseInt(parameters.pageIndex, 10) || 0;
		this.searchPagination.pageSize = Number.parseInt(parameters.pageSize, 10) || 10;
		delete parameters.pageIndex;
		delete parameters.pageSize;

		// Load parameters in the search model
		Object.assign(this.searchModel, parameters);
	}

	protected saveSearchModel() {
		// TODO Use a service to handle this
		const serialize = function (obj) {
			const str = [];
			for (const p in obj) {
				if (obj.hasOwnProperty(p) && obj[p] !== null && obj[p] !== undefined) {
					str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
				}
			}
			return str.join('&');
		};

		// Save the parameters in the URL without triggering a search
		let basePath = this.location.path() + '?';
		basePath = basePath.substring(0, basePath.indexOf('?'));
		this.location.replaceState(basePath, serialize({
			...this.searchModel,
			...this.searchPagination,
		}));
	}
}
