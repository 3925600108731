import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationMessage } from '../model/notification-message';
import { ServiceResponse } from '@mdib/utils/model/service-response';
import { FeedbackTypes } from '@mdib/utils/model/utils.typings';

@Injectable()
export class NotificationMessageService {
	public messageEmitter: Subject<NotificationMessage>;

	constructor() {
		this.messageEmitter = new Subject();
	}

	sendMessage(message: NotificationMessage): void {
		this.messageEmitter.next(message);
	}

	sendMessages(messages: Array<NotificationMessage>): void {
		messages.forEach(m => this.messageEmitter.next(m));
	}

	handleError(serviceResponse: ServiceResponse<any>): void {
		if (serviceResponse.getFeedbacks()[0].type && serviceResponse.getFeedbacks()[0].defaultMessage) {
			const message = <NotificationMessage>({
				title: 'notification.message.' + serviceResponse.getFeedbacks()[0].type, // This will retrieve the error type used as the title of the notification banner.
				type: serviceResponse.getFeedbacks()[0].type,
				message: serviceResponse.getFeedbacks()[0].defaultMessage, // This will retrieve the default error message.
				scrollToMe: true
			});
			this.messageEmitter.next(message);
		} else {
			const message = <NotificationMessage>({
				title: 'notification.message.' + FeedbackTypes.UNKNOWN_ERROR,
				type: FeedbackTypes.BACKEND_ERROR, // BACKEND_ERROR because there is no style for UNKNOWN_ERROR yet
				message: 'notification.message.' + FeedbackTypes.UNKNOWN_ERROR,
				scrollToMe: true
			});
			this.messageEmitter.next(message);
		}
	}

	clearAll(): void {
		this.messageEmitter.next(<NotificationMessage>{ clearAll: true });
	}

	clearType(type: string) {
		this.messageEmitter.next(<NotificationMessage>{ type: 'string', clear: true });
	}
}
