import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IfAuthorizedDirective } from './directives/if-authorized.directive';
import { IfNotAuthorizedDirective } from './directives/if-not-authorized.directive';
import { AuthorizationsService } from './services/authorizations.service';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [IfAuthorizedDirective, IfNotAuthorizedDirective],
	exports: [IfAuthorizedDirective, IfNotAuthorizedDirective]
})
export class SecurityModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: SecurityModule,
			providers: [
				AuthorizationsService
			]
		};
	}
}
