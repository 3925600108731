import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';

import { BicService } from './bic.service';

export class BicServiceStub extends BicService {

	public get(account: string): Observable<ServiceResponse<string> | null> {
		return of();
	}
}
