// Angular Module
import { NgModule, Type, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// Third party
import { TranslateModule } from '@ngx-translate/core';
// Our Module
import { UtilsModule } from '@mdib/utils';
import { StylingModule } from '@mdib/styling';
// Our components
import { SignatureTypeListComponent } from './component/signature-type-list/signature-type-list.component';
import { UnimplementedSignatureComponent } from './component/unimplemented-signature/unimplemented-signature.component';
import { SignatureComponent } from './component/signature/signature.component';
import { SignatureTypeSelectorComponent } from './component/signature-type-selector/signature-type-selector.component';
// Our Services
import { SignatureService } from './service/signature.service';
// Our interfaces
import { SignatureType } from './interfaces/signature-type';

import { SIGNATURE_TYPES, SIGNATURE_DEFAULT, SIGNATURE_SELECTOR } from './signature.const';

@NgModule({
	imports: [
		CommonModule,
		UtilsModule,
		FormsModule,
		TranslateModule,
		StylingModule,
	],
	declarations: [
		SignatureTypeListComponent,
		UnimplementedSignatureComponent,
		SignatureTypeSelectorComponent,
		SignatureComponent,
	],
	exports: [
		SignatureComponent,
	],
	entryComponents: [
		SignatureTypeSelectorComponent,
	]
})
export class SignatureModule {

	/**
	 * Setup and provide the generic services to handle multiple signature processes.
	 * Should only be used in the main Module !
	 *
	 * @param types The list of available signature processes
	 * @param defaultType The name of the default process to use when the type is missing @default null
	 * @param selectorComponent The component class used to let the user select the process type. @default SignatureTypeSelectorComponent
	 */
	public static forRoot(
		types?: SignatureType[],
		defaultType?: any,
		selectorComponent?: Type<SignatureTypeSelectorComponent>
	): ModuleWithProviders {
		// Create module
		return {
			ngModule: SignatureModule,
			providers: [
				SignatureService,
				{ provide: SIGNATURE_TYPES, multi: true, useValue: types },
				{ provide: SIGNATURE_DEFAULT, useValue: defaultType },
				{ provide: SIGNATURE_SELECTOR, useValue: selectorComponent || SignatureTypeSelectorComponent },
				{ provide: SignatureService, useClass: SignatureService, deps: [SIGNATURE_TYPES, SIGNATURE_DEFAULT, SIGNATURE_SELECTOR] }
			],
		};
	}
}
