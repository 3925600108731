import { OnInit, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

export abstract class TimeoutComponent implements OnInit {

	abstract readonly NAMESPACE: string;
	public remainingDelay: Observable<number>;
	protected timerId: number;

	constructor(
		protected dialogRef: MatDialogRef<TimeoutComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		protected router: Router,
		protected matDialog: MatDialog,
		protected ngZone: NgZone
	) { }

	ngOnInit() {
		if (!this.data.timeoutDelay || this.data.timeoutDelay <= 0) {
			return;
		}
		const startingTime = new Date().getTime();
		this.remainingDelay = new Observable(observable => {
			this.timerId = this.ngZone.runOutsideAngular(
				() => {
					return window.setInterval(() => {
						this.ngZone.run(() => {
							const timerMs = new Date().getTime() - startingTime;
							const remainingMs = this.data.timeoutDelay - timerMs;
							if (remainingMs <= 0) {
								this.endTimeoutEvent();
							}
							observable.next(remainingMs / 1000);
							});
					}, 100);
				}
			);
		});
	}

	public endTimeoutEvent(): void {
		this.closeDialog();
		this.redirectToLogoutPage();
	}

	public keepAlive(): void {
		window.clearInterval(this.timerId);
		this.dialogRef.close();
	}

	protected closeDialog(): void {
		window.clearInterval(this.timerId);
		this.matDialog.closeAll();
	}

	protected redirectToLogoutPage(): void {
		this.router.navigate(['/session-logout']);
	}

}
