import { Observable, Observer } from 'rxjs';

import { LiveTourStep } from './live-tour-step';
import { LiveTourService } from '../live-tour.service';

export class WaitEventStep extends LiveTourStep {

	constructor(private eventCondition: Function | string) {
		super();
	}

	public execute(service: LiveTourService, liveTourEvents: Observable<Event>): Observable<Event> {
		return Observable.create((obv: Observer<Event>) => {
			const sub = liveTourEvents.subscribe(event => {
				if (
					(typeof this.eventCondition === 'string' && event.type === this.eventCondition) ||
					(typeof this.eventCondition === 'function' && this.eventCondition(event))
				) {
					sub.unsubscribe();
					obv.next(new Event('nextStep'));
					obv.next(new Event('stepComplete'));
				}
			});
		});
	}

}
