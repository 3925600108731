import {Component, OnDestroy} from '@angular/core';
import { tap, map } from 'rxjs/operators';
import {CashAccount, CashAccountTypesGroup, CashAccountSortOrderService} from '@mdib/cash-accounts';
import { ConfigService } from '@mdib/config';
import { PaymentsService } from '@mdib/payments';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import {CacheService, ServiceResponse} from '@mdib/utils';

@Component({
	templateUrl: './dashboard-page.component.html',
	styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent {
	CashAccountTypesGroup = CashAccountTypesGroup;
	readonly NAMESPACE = 'dashboardPagesModule.dashboardPageComponent.';

	cashAccounts: CashAccount[];
	orderToBeUpdated = false;


	constructor(private _configService: ConfigService,
		private paymentsService: PaymentsService,
		private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService,
		private cacheService: CacheService,
		private cashAccountSortOrderService: CashAccountSortOrderService,
	) {
		const sendFeedbacks = (response: ServiceResponse<null>) => this.serviceResponseNotifMapper.sendResponseFeedbacks(response, this.NAMESPACE);
	}


	updateCashOrder(cashAccounts: CashAccount[]): void {
		this.cashAccounts = cashAccounts;
		this.updateAccountOrder();
	}

	private updateAccountOrder() {
		if (this.orderToBeUpdated === true) {
			this.cashAccountSortOrderService.update(this.cashAccountSortOrderService.map(this.cashAccounts)).subscribe(a => {
				this.cacheService.invalidate('accounts');
			});
		}
	}
}
