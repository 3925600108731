import { NgModule, ModuleWithProviders, Provider } from '@angular/core';

import { TranslatePipe } from './pipes/translate.pipe';

import { ParameterSetsService } from './services/parameters/parameter-sets.service';
import { ParameterSetsServiceStub } from './services/parameters/parameter-sets.service.stub';
import { TranslationsService } from './services/translations/translations.service';
import { TranslationLoaderService } from './services/translations/translation-loader.service';
import { TranslationLoaderServiceStub } from './services/translations/translation-loader.service.stub';
import { TranslationParserService } from './services/translations/translation-parser.service';
import { TranslationDefaultParserService } from './services/translations/translation-default-parser.service';
import { TranslationHttpLoaderService } from './services/translations/translation-http-loader.service';

export interface CustomizationModuleConfig {
	loader?: Provider;
	parser?: Provider;
}

@NgModule({
	declarations: [
		TranslatePipe
	],
	exports: [
		TranslatePipe,
	],
	providers: [
		TranslationsService
	]
})
export class CustomizationModule {

	public static forRoot(config: CustomizationModuleConfig = {}): ModuleWithProviders {
		return {
			ngModule: CustomizationModule,
			providers: [
				config.loader || { provide: TranslationLoaderService, useClass: TranslationHttpLoaderService },
				config.parser || { provide: TranslationParserService, useClass: TranslationDefaultParserService },
			]
		};
	}

	public static forTests(): ModuleWithProviders {
		return {
			ngModule: CustomizationModule,
			providers: [
				{ provide: ParameterSetsService, useClass: ParameterSetsServiceStub },
				{ provide: TranslationLoaderService, useClass: TranslationLoaderServiceStub },
				{ provide: TranslationParserService, useClass: TranslationDefaultParserService },
			]
		};
	}

}
