import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { AccountStatementsService } from './account-statements.service';
import { AccountStatementsServiceStub } from './account-statements.service.stub';

@Injectable()
export class AccountStatementsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return AccountStatementsService; }
	protected ServiceDefinition() { return AccountStatementsServiceStub; }
}
