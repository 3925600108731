import { Injectable } from '@angular/core';
import { XclSignatureContext, XclSignatureModes } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';
import { BankConfig, ManagedCurrencyCodes } from '@mdib/config';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { OperationStatusHelper } from '../../helper/operation-status-helper';
import { InstantPaymentXcl } from '../../model/instant-payment-xcl';
import { getCountryCodeFromIBAN, convertBeneficiaryAddressFromXclToStreet, mapToXclCommunication, OrderStateHelper, XclAPI, XclDateFormatter } from '@mdib-xcl/utils';
import { Beneficiary, BeneficiaryType } from '@mdib/beneficiaries';
import { CashAccount, CashAccountService } from '@mdib/cash-accounts';
import { Address, Communication, CommunicationTypes } from '@mdib/commons';
import { PaymentOperation, PaymentTypes } from '@mdib/payments';
import { ServiceResponse, UtilsHelper } from '@mdib/utils';

@Injectable({
	providedIn: 'root',
})
export class InstantPaymentsMapperXclService {

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.';

	private userCashAccounts: CashAccount[];

	constructor(private cashAccountService: CashAccountService,
				private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService) {
	}

	public toXcl(payment: PaymentOperation<null>): InstantPaymentXcl {

		let xclModel = <InstantPaymentXcl>{
			counterpartyAccountNumber: payment.counterParty.account.number,
			operationAmount: payment.amount,
			ordererAccountNumber: payment.ordererAccount.number,
			participantName_1: payment.ordererAccount.clientWording,
			participantName_2: payment.counterParty.type === BeneficiaryType.INTERNAL ?
				payment.counterParty.account.clientWording : payment.counterParty.fullName,
			operationCurrency: BankConfig.baseCurrencyCode,
			paymentType: '05',
			paymentScheme: '11',
			inputChannel: '02',
     //			counterpartyCountryCode: payment.counterParty.address && !!payment.counterParty.address.country ?
    //				payment.counterParty.address.country : getCountryCodeFromIBAN(payment.counterParty.account.number),
		counterpartyCountryCode: payment.counterParty.address && !!payment.counterParty.address.country ?
				payment.counterParty.address.country : null,

		};

		xclModel.counterpartyName = xclModel.participantName_2;
		xclModel.operationReference = payment.reference;

		if (!!payment.counterParty && payment.counterParty.type !== BeneficiaryType.INTERNAL) {
			xclModel.counterpartyBicIdentification = payment.counterParty.account.bic;
		}

		if (!!payment.paymentDate) {
			xclModel.maturityDate = XclDateFormatter.convertDateToXCLDateFormat(payment.paymentDate);
		}

		if (!!payment.counterParty && !!payment.counterParty.address) {
			const address = payment.counterParty.address;
			xclModel.counterpartyAddressPart1 = address.addressLine1;
			xclModel.counterpartyAddressPart2 = address.addressLine2;
		}

		if (payment.saveBeneficiary) {
			xclModel.saveBeneficiary = true;
			xclModel.beneficiaryIdentificationForBeneficiary = payment.counterParty.alias;
		}

		xclModel = mapToXclCommunication<InstantPaymentXcl>(payment, xclModel);

		return xclModel;

	}

	public fromXcl(payment: PaymentOperation<null>, paymentXcl: MonogoalOrderXCLModel<InstantPaymentXcl>, phase: string): PaymentOperation<null> {

		const xclModel: InstantPaymentXcl = paymentXcl.goal;

		payment.reference = xclModel.operationReference;

		if (UtilsHelper.nullOrUndefined(payment.ordererAccount)) {
			payment.ordererAccount = new CashAccount({
				clientWording: xclModel.ordererName,
				number: xclModel.ordererAccountNumber,
			});
			payment.ordererAccount.clientWording = xclModel.ordererName;
			payment.ordererAccount.number = xclModel.ordererAccountNumber;
		}

		payment.amount = xclModel.operationAmount;
		payment.type = PaymentTypes.instantPayment;
		payment.currency = xclModel.operationCurrency;
		payment.paymentDate = !xclModel.maturityDate ? null : XclDateFormatter.xclStringToDate(xclModel.maturityDate);
		payment.operationDate = !xclModel.operationDate ? null : XclDateFormatter.xclStringToDate(xclModel.operationDate);

		if (phase === XclAPI.retrieve) {

			this.cashAccountService.list().subscribe(
				(response: ServiceResponse<CashAccount[]>) => {
					this.userCashAccounts = response.getModel();
				},
				(serviceResponseError: ServiceResponse<null>) => {
					this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
				},
				() => {

					// Check if the counterparty account is an account of the connected user
					const beneficiaryCashAccount = this.userCashAccounts.find((cashAccount: CashAccount) => cashAccount.number === xclModel.counterPartyAccountNumber);
					const beneficiaryType = UtilsHelper.nullOrUndefined(beneficiaryCashAccount) ? BeneficiaryType.SEPA : BeneficiaryType.INTERNAL;

					payment.counterParty = new Beneficiary({
						account: new CashAccount({
							clientWording: xclModel.counterpartyName,
							valuationCurrency: xclModel.orderCurrency || ManagedCurrencyCodes.EUR,
							number: xclModel.counterPartyAccountNumber,
						}),
						address: new Address(),
						fullName: xclModel.counterpartyName,
						alias: xclModel.beneficiaryIdentificationForBeneficiary,
						type: beneficiaryType,
					});

					if (!!xclModel.counterpartyCountryCode) {
						payment.counterParty.address.country = xclModel.counterpartyCountryCode;
					}

					if (!!xclModel.counterpartyAddressPart1) {
						payment.counterParty.address.addressLine1 = xclModel.counterpartyAddressPart1;
					}
					if (!!xclModel.counterpartyAddressPart2) {
						payment.counterParty.address.addressLine2 = xclModel.counterpartyAddressPart2;
					}

					payment.counterParty.communication = new Communication();
					if (!!xclModel.rfStructuredCommunication) {
						payment.counterParty.communication.type = CommunicationTypes.european;
						payment.counterParty.communication.value = xclModel.rfStructuredCommunication;
					} else {
						payment.counterParty.communication.type = CommunicationTypes.free;
						payment.counterParty.communication.value = !xclModel.freeCommunication ? '' : xclModel.freeCommunication;
					}
				});
		}

		payment.signatureTypesAllowed = paymentXcl.signatureTypesAllowed.map(type => XclSignatureModes.fromXclType(type));
		payment.signatureContext = new XclSignatureContext({signableReference: paymentXcl.reference});
		payment.status = OrderStateHelper.getStatusFromXCLOrderStateCode(paymentXcl.state);
		payment.operationStatus = OperationStatusHelper.paymentStatusFromXcl(paymentXcl.goal.operationStatus);

		return payment;

	}
}


