
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { ConfigurationService } from './configuration.service';
import { BankConfig } from '@mdib/config';

@Injectable()
export class TranslationLoader implements TranslateLoader {

	constructor(
		private httpClient: HttpClient,
		private configurationService: ConfigurationService,
	) { }

	getTranslation(lang: string): Observable<any> {
		const translationDomain = this.configurationService.instant('translation.endpoint.domain');
		const url = translationDomain ? translationDomain : BankConfig.translationsPath;
		return this.httpClient.get(url + lang + '.json').pipe(
			catchError((err: any, caught: Observable<any>): Observable<any> => {
				return of('{}');
			}));
	}
}
