import { ManagedCurrencyCodes } from '@mdib/config';
import { Person } from '@mdib/customers';
import { Signable, Signature } from '@mdib/signature';
import { SignatureModes } from '@mdib/signature-modes';
import { Status } from '@mdib/utils';
import { CashAccount } from './cash-account';

/**
 * Holds data about the cash account creation
 */
export class CashAccountCreation extends CashAccount implements Signable {

	reference?: string;
	status?: Status;

	termsAccepted: boolean;
	holder: Person;
	usResident: boolean;
	type: string;
	currency: ManagedCurrencyCodes;

	signature: Signature = null;
	signatureTypesAllowed: SignatureModes[] = [];
	signatureContext: any = null;

	isSignatureTypeAllowed(signatureType: any): boolean {
		return (!this.signatureTypesAllowed ? false : this.signatureTypesAllowed.indexOf(signatureType) >= 0);
	}

	isSigned(): boolean {
		return !!this.signature;
	}

	addSignature(signature: Signature): void {
		this.signature = signature;
	}

	public getSignatureContext(): any {
		return this.signatureContext;
	}

}
