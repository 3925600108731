import { AccountStatement } from '../model/account-statement';
import { AccountStatementsPageFilter } from '../model/account-statements-page-filter';
import { MdibFile, ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { AccountStatementsService } from './account-statements.service';

export class AccountStatementsServiceStub extends AccountStatementsService {
	list(index?: number, count?: number, filter?: AccountStatementsPageFilter): Observable<ServiceResponse<AccountStatement[]>> {
		return of();
	}

	count(filter?: AccountStatementsPageFilter): Observable<ServiceResponse<number>> {
		return of();
	}

	get(name: string): Observable<ServiceResponse<MdibFile>> {
		return of();
	}

	update(accountStatement: AccountStatement): Observable<ServiceResponse<AccountStatement>> {
		return of();
	}

	initializeFilter(): AccountStatementsPageFilter {
		return undefined;
	}

	getRepresentedClientNumber(): Observable<ServiceResponse<string>> {
		return of();
	}

}
