import { Immutable, Enum } from '@mdib/core/meta';

@Immutable()
export class BackendOperationState extends Enum {
	public static readonly WAITING_DATA = new BackendOperationState('waitingData');
	public static readonly WAITING_ACTION = new BackendOperationState('waitingAction');
	public static readonly BUSY = new BackendOperationState('busy');
	public static readonly SUCCEEDED = new BackendOperationState('succeeded');
	public static readonly FAILED = new BackendOperationState('failed');
	public static readonly CANCELLED = new BackendOperationState('cancelled');
}
