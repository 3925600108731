import { delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SepaDirectDebitContract, SepaDirectDebitPaymentsService, SepaDirectDebitPayment } from '@mdib/sepa-direct-debits';
import { Observable, of } from 'rxjs';
import { ServiceResponse, UtilsHelper } from '@mdib/utils';
import { MockSepaDirectDebitPayments } from '../mock/mock-sepa-direct-debit-payments';
import { BackendOperation, SearchCriteria, SearchResult } from '@mdib/core/adapters';
import { SepaDirectDebitPaymentsUpdateMemory } from './operations/sepa-direct-debit-payments-update-memory';
import { SepaDirectDebitPaymentsRetrieveMemory } from './operations/sepa-direct-debit-payments-retrieve-memory';

@Injectable({
	providedIn: 'root'
})
export class SepaDirectDebitPaymentsMemoryService extends SepaDirectDebitPaymentsService {
	readonly DEFAULT_DELAY = 400;

	constructor() {
		super();
	}

	public list(sepaDirectDebitContract: SepaDirectDebitContract, index?: number, count?: number): Observable<ServiceResponse<SepaDirectDebitPayment[]>> {
		const results = MockSepaDirectDebitPayments
			.getMockSepaDirectDebitPayments()
			.filter((sepaDirectDebitPayment: SepaDirectDebitPayment) => sepaDirectDebitPayment.contractReference === sepaDirectDebitContract.contractReference);
		return of(new ServiceResponse(
			results.slice(index || 0, (index + count) || undefined),
			[],
			{ totalCount: results.length },
		)).pipe(delay(this.DEFAULT_DELAY));
	}

	public count(sepaDirectDebitContract: SepaDirectDebitContract): Observable<ServiceResponse<number>> {
		return of(new ServiceResponse<number>(
			MockSepaDirectDebitPayments.getMockSepaDirectDebitPayments().filter((sepaDirectDebitPayment: SepaDirectDebitPayment) => sepaDirectDebitPayment.contractReference === sepaDirectDebitContract.contractReference).length)).pipe(delay(this.DEFAULT_DELAY));
	}

	retrieve(): BackendOperation<string, SepaDirectDebitPayment> {
		return new SepaDirectDebitPaymentsRetrieveMemory();
	}

	create(): BackendOperation<SepaDirectDebitPayment, SepaDirectDebitPayment> {
		throw new Error('Method not implemented.');
	}

	update(): BackendOperation<Partial<SepaDirectDebitPayment>, SepaDirectDebitPayment> {
		return new SepaDirectDebitPaymentsUpdateMemory();
	}

	delete(): BackendOperation<SepaDirectDebitPayment, SepaDirectDebitPayment> {
		throw new Error('Method not implemented.');
	}

	search(): BackendOperation<SearchCriteria<any>, SearchResult<SepaDirectDebitPayment>> {
		throw new Error('Method not implemented.');
	}
}
