import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { tap } from 'rxjs/operators';

import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { ServiceResponse } from '@mdib/utils';
import { PersonMandate } from '../../model/person-mandate';
import { PersonsService } from '../../service/persons/persons.service';

/**
 * Provides the ability to select a person mandate linked to a user
 */

@Component({
	selector: 'mdib-input-person-mandate',
	templateUrl: './input-person-mandate.component.html',
	styleUrls: ['./input-person-mandate.component.scss'],
})
export class InputPersonMandateComponent implements OnInit {

	readonly NAMESPACE = 'customersModule.inputPersonMandateComponent.';

	@Output() onSelect: EventEmitter<string> = new EventEmitter<string>();
	@Input() isActionRequestOngoing: boolean;
	@Input() control: AbstractControl;

	personMandates: PersonMandate[];

	constructor(
		private personsService: PersonsService,
		private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService,
	) { }

	ngOnInit(): void {
		this.loadPersonMandates();
	}

	selectMandate() {
		return this.control instanceof FormGroup && !!this.control.value && !!this.personMandates ? this.personMandates.filter(value => value.person.name === this.control.value.name).pop() : null;
	}

	/**
	 * Send the value of inputs
	 * @param {string} item
	 */
	public sendValue(event: MatSelectChange) {
		this.control instanceof FormGroup ? this.control.patchValue(event.value.person) : this.control.setValue(event.value.person.name);
		this.onSelect.emit(this.control.value);
	}

	/**
	 * Load mandates that the user has on persons
	 */
	private loadPersonMandates(): void {
		this.personsService.getMandates().pipe(
			tap((response: ServiceResponse<null>) => this.serviceResponseNotifMapper.sendResponseFeedbacks(response, this.NAMESPACE),
				(response: ServiceResponse<null>) => this.serviceResponseNotifMapper.sendResponseFeedbacks(response, this.NAMESPACE)))
			.subscribe((serviceResponse: ServiceResponse<PersonMandate[]>) => {
				this.personMandates = serviceResponse.getModel();
			});
	}
}
