import { ModuleWithProviders, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_LABEL_GLOBAL_OPTIONS, MatAutocompleteModule, MatBadgeModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorIntl, MatPaginatorModule, MatProgressSpinnerModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatStepper, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateModule } from '@ngx-translate/core';
import { DATE_PICKER_FORMATS } from './helpers/styling.type';
import { CustomPaginatorService } from './service/custom-paginator.service';
import { IconService } from './service/icon.service';
import { ThemeService } from './service/theme-service';

@NgModule({
	imports: [
		MatTabsModule,
		MatSelectModule,
		MatStepperModule,
		MatMenuModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatDialogModule,
		MatMomentDateModule,
		MatFormFieldModule,
		MatRadioModule,
		MatCheckboxModule,
		MatInputModule,
		MatIconModule,
		MatTooltipModule,
		MatPaginatorModule,
		TranslateModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatTableModule,
		MatCardModule,
		MatSidenavModule,
		MatDividerModule,
		MatExpansionModule,
		MatToolbarModule,
		MatListModule,
		MatBadgeModule,
		MatSliderModule,
		MatAutocompleteModule,
		MatGridListModule,
		MatSlideToggleModule,
		MatProgressSpinnerModule,
	],
	exports: [
		MatTabsModule,
		MatSelectModule,
		MatStepperModule,
		MatMenuModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatDialogModule,
		MatMomentDateModule,
		MatFormFieldModule,
		MatRadioModule,
		MatCheckboxModule,
		MatInputModule,
		MatIconModule,
		MatTooltipModule,
		MatPaginatorModule,
		TranslateModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatTableModule,
		MatCardModule,
		MatSidenavModule,
		MatDividerModule,
		MatExpansionModule,
		MatToolbarModule,
		MatListModule,
		MatBadgeModule,
		MatSliderModule,
		MatAutocompleteModule,
		MatGridListModule,
		MatSlideToggleModule,
		MatProgressSpinnerModule,
	],
})
export class StylingModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: StylingModule,
			providers: [
				ThemeService,
				{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
				{provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_FORMATS},
				{provide: MatPaginatorIntl, useClass: CustomPaginatorService},
				/** Deactivation of float elements (Material Design) */
				{provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'never'}},
				IconService,
				MatStepper,
			],
		};
	}
}
