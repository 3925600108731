import { FormGroup, FormControl } from '@angular/forms';

import { EnhancedFormGroup, TypedFormGroup, TypedFormControl } from '@mdib/core/meta';
import { CommunicationFormGroup } from '@mdib/commons';

import { BeneficiaryModel } from '../models/beneficiary';
import { BeneficiaryType } from '../enums/beneficiary-type.enum';

export class BeneficiaryFormGroup extends TypedFormGroup<BeneficiaryModel> implements EnhancedFormGroup<BeneficiaryModel> {

	get id(): TypedFormControl<string> {
		return <FormControl>this.get('id');
	}

	get fullName(): TypedFormControl<string> {
		return <FormControl>this.get('fullName');
	}

	get alias(): TypedFormControl<string> {
		return <FormControl>this.get('alias');
	}

	get type(): TypedFormControl<BeneficiaryType> {
		return <FormControl>this.get('type');
	}

	get account(): FormGroup {
		return <FormGroup>this.get('account');
	}

	get communication(): CommunicationFormGroup {
		return <CommunicationFormGroup>this.get('communication');
	}

	get address(): FormGroup {
		return <FormGroup>this.get('address');
	}

}
