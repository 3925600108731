import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { AccountValuationsPageFilter } from './account-valuations-page-filter';

@Component({
	selector: 'account-valuations-page-filter',
	templateUrl: './account-valuations-page-filter.component.html',
	styleUrls: ['./account-valuations-page-filter.component.scss']
})

export class AccountValuationsPageFilterComponent implements OnInit, OnChanges {

	readonly NAMESPACE = 'AccountValuationsPagesModule.AccountValuationsPageFilterComponent.';
	@Input() filter: AccountValuationsPageFilter;
	@Input() currentYear: string;
	@Output() onYearChanged = new EventEmitter<number>();
	public form: FormGroup;

	constructor(private fb: FormBuilder,
	) {	}

	ngOnInit(): void {
		this.form = this.fb.group({
			year: [this.currentYear, null]
		});
		this.valuationYear.valueChanges.subscribe(valuationYear => {
			this.filter.valuationYear = valuationYear;
			this.onYearChanged.emit();
		});
	}

	ngOnChanges() {
		if (!isNullOrUndefined(this.form)) {
			this.form.reset({
				year: this.currentYear
				},
			);
		}
	}

	get valuationYear(): AbstractControl {
		return this.form.get('year');
	}
}
