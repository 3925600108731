import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ManagedCurrencyCodes } from '@mdib/config';
import { IconService } from '@mdib/styling';

@Component({
	selector: 'currencies',
	templateUrl: './currencies.component.html',
	styleUrls: ['./currencies.component.scss'],
})
export class CurrenciesComponent {

	@Input() currency: FormControl;

	constructor(private iconService: IconService) {
	}

	getCurrencyIcon(currency: string): string {
		return this.iconService.getCurrencyIcon(ManagedCurrencyCodes[currency]);
	}
}
