import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { SessionResetService } from './session-reset.service';
import { SessionResetServiceStub } from './session-reset.service.stub';

@Injectable()
export class SessionResetServiceProxy extends AdapterProxy {
	protected ServiceToken() { return SessionResetService; }
	protected ServiceDefinition() { return SessionResetServiceStub; }
}
