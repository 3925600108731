import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { ConfigService } from '@mdib/config';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { ServiceResponse, UtilsHelper } from '@mdib/utils';
import { CashAccount } from '../../model/cash-account';
import { CashAccountOperation } from '../../model/cash-account-operation';
import { ModifyAccountNameOperation } from '../../model/modify-account-name-operation';
import { CashAccountOperationService } from '../../service/cash-account-operation.service';
import { CashAccountService } from '../../service/cash-account.service';
import { ModifyAccountNameService } from '../../service/modify-account-name.service';
import { IconButton } from '@mdib/styling';
import { AuthorizationsService } from '@mdib/core/security/services/authorizations.service';

/**
 * Displays the content of a {@link CashAccount}
 */
@Component({
	selector: 'mdib-cash-accounts-list-item',
	templateUrl: './cash-accounts-list-item.component.html',
	styleUrls: ['./cash-accounts-list-item.component.scss'],
	animations: [
		trigger('slideInOut', [
			state('in', style({ opacity: '0', height: '0px', overflow: 'hidden' })),
			state('out', style({ opacity: '1', height: '*' })),
			transition('in => out', [animate('200ms ease-out')]),
			transition('out => in', [animate('200ms ease-in')]),
		]),
	],
})
export class CashAccountsListItemComponent implements OnChanges, OnInit {

	readonly NAMESPACE = 'cashAccountsModule.cashAccountsListItemComponent.';

	@Input() cashAccount: CashAccount;
	@Input() expandedAccountNumber: string;
	@Input() orderNumber: string;
	@Output() expandedAccountNumberChange = new EventEmitter<string>();
	@Output() isAccountOrderUpdated = new EventEmitter();

	@ViewChild('accountName') accountName: ElementRef;

	/**
	 * List of cash account operations, provided by the CashAccountOperationService
	 */
	operations: CashAccountOperation[] = [];
	operationsLoaded = false;

	expanded = 'in';
	maxDisplayed = 10;

	// Will be used to toggle the display of modify icon
	showModifyIcon = false;
	// Will be set to true when account name field is editable
	isAccountNameModifiable = false;
	// Will be used to save the client wording , in case of error while modification
	savedClientWording: string;
	// States that asynchronous call is still processing for account name modification
	onGoingOperationForAccountNameChange = false;
	// Will be used to toggle the display of drag icon
	showDragIcon = true;

	public iconArray: Array<IconButton> = [
		{ iconName: 'add', iconLabel: 'create-payment', link: '/payments/create', token: 'payments.create'},
		{ iconName: 'priority_high', iconLabel: 'new-statements', link: '/pdf-statements', token: 'accounts.statements.list'},
		{ iconName: 'swap_horiz', iconLabel: 'account-history', link: '/accounts/operations/search', token: 'accounts.history.list'},
		{ iconName: 'euro_symbol', iconLabel: 'account-valuation', link: '/account-valuations', token: 'accounts.valuations.list'},
		{ iconName: 'payment', iconLabel: 'new-electronical-statements', link: '/electronical-statements', token: 'accounts.electronical-statements.list'}
	];

	constructor(private cashAccountOperationService: CashAccountOperationService,
				private cashAccountService: CashAccountService,
				private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService,
				private modifyAccountNameService: ModifyAccountNameService,
				private _configService: ConfigService,
				private authorizationService: AuthorizationsService) {
	}

	ngOnInit() {
		// to restore in case of error while modifying
		this.savedClientWording = this.cashAccount.clientWording;
	}

	get configService() {
		return this._configService;
	}

	cashAccountIcon() {
		return this.cashAccountService.getIconByCashAccountType(this.cashAccount.type);
	}

	ngOnChanges(changes: { [expandedAccountNumber: number]: SimpleChange }) {
		if (this.expandedAccountNumber !== this.cashAccount.number) {
			this.expanded = 'in';
		}
	}

	loadOperations(offset: number, limit: number): void {
		if (this.cashAccount == null) {
			console.log('Unable to load the cash account operations, as the cash account is undefined !');
			return;
		}
		this.operationsLoaded = false;
		this.cashAccountOperationService
			.list(this.cashAccount.number, offset, limit)
			.subscribe((serviceResponse: ServiceResponse<CashAccountOperation[]>) => {
				this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponse, this.NAMESPACE);
				this.operations = serviceResponse.getModel();
				this.operationsLoaded = true;
			},
				(serviceResponseError: ServiceResponse<null>) => {
					this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
				});
	}

	/**
	 * Invokes service to update the account wording
	 * If error , then previous wording(before modification) is set again
	 */
	changeAccountName(): void {
		this.onGoingOperationForAccountNameChange = true;

		this.modifyAccountNameService.update(this.cashAccount).subscribe(
			(serviceResponse: ServiceResponse<ModifyAccountNameOperation>) => {
				this.savedClientWording = this.cashAccount.clientWording;
				this.onGoingOperationForAccountNameChange = false;
			},
			(serviceResponseError: ServiceResponse<null>) => {
				this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
				this.cashAccount.clientWording = this.savedClientWording;
				this.onGoingOperationForAccountNameChange = false;
			},
		);
	}

	isExpanded(): boolean {
		return this.expanded === 'out';
	}

	public toggleExpanded(): void {
		if (this.expanded === 'in') {
			this.expanded = 'out';
			this.expandedAccountNumber = this.cashAccount.number;
			this.expandedAccountNumberChange.emit(this.expandedAccountNumber);
			this.loadOperations(0, this.maxDisplayed);
		} else {
			this.expanded = 'in';
		}
	}

	/**
	 * Called when the account wording field is clicked
	 */
	enableModify(): void {
		this.isAccountNameModifiable = true;
		this.accountName.nativeElement.focus();
	}

	/**
	 * Called when the focus is removed from the input field
	 */
	onClientWordingBlur(): void {

		this.isAccountNameModifiable = false;

		if (!UtilsHelper.isNullOrWhiteSpace(this.cashAccount.clientWording)) {
			this.changeAccountName();
		} else {
			this.cashAccount.clientWording = this.savedClientWording;
		}
	}

	public getContext(context: any): any {
		return { context: btoa(JSON.stringify(context)) };
	}

	onMove($event) {
		this.isAccountOrderUpdated.emit();
	}

	dragenter(ev) {
		event.preventDefault();
	}

	public isAuthorized() {
		const tokens = [];
		this.iconArray.forEach(icon => {
			tokens.push(icon.token);
		});
		return this.authorizationService.authorized(tokens);
	}

}
