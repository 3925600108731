import { CashAccount, CashAccountTypes, CashAccountBuilder } from '@mdib/cash-accounts';

// Mock data for the list of cash accounts of the user
export class MockCashAccount {

	static getMockCashAccounts(): CashAccount[] {
		return [
			new CashAccountBuilder().accountNumber('BE56305177635588')
				.availableBalance(4567.56)
				.productCode('300100')
				.clientWording('Daily')
				.currentBalance(4567.56)
				.valuationCurrency('EUR')
				.type(CashAccountTypes.CurrentAccountOrdinary)
				.isNewStatement(true)
				.cashAccount,
			new CashAccountBuilder().accountNumber('BE95750642304658')
				.availableBalance(2000.01)
				.productCode('300100')
				.clientWording('For the kids')
				.currentBalance(-200)
				.valuationCurrency('EUR')
				.type(CashAccountTypes.CurrentAccountOrdinary)
				.cashAccount,
			new CashAccountBuilder().accountNumber('BE56305177635789')
				.availableBalance(5000)
				.productCode('300100')
				.clientWording('Daily 2')
				.currentBalance(5000)
				.valuationCurrency('CHF')
				.type(CashAccountTypes.CurrentAccountOrdinary)
				.isNewStatement(true)
				.cashAccount,
			new CashAccountBuilder().accountNumber('BE11736988971648')
				.availableBalance(2500.00)
				.clientWording('For next holidays')
				.productCode('320101')
				.currentBalance(88500.00)
				.valuationCurrency('EUR')
				.type(CashAccountTypes.RegulatedSavingsAccounts)
				.cashAccount,
			new CashAccountBuilder().accountNumber('BE74945239392707')
				.availableBalance(86543.12)
				.productCode('310301')
				.clientWording('Online payments')
				.currentBalance(88300.12)
				.valuationCurrency('EUR')
				.type(CashAccountTypes.NoticeAccount30Days)
				.cashAccount,
		];
	}
}
