import { Component } from '@angular/core';
import { SignatureProcessComponent } from '../signature-process.component';

@Component({
	selector: 'mdib-unimplemented-signature',
	templateUrl: './unimplemented-signature.component.html',
	styleUrls: ['./unimplemented-signature.component.scss']
})
export class UnimplementedSignatureComponent extends SignatureProcessComponent {

	public static readonly isWorkInProgress = true;

	readonly NAMESPACE = 'signatureModule.unimplementedSignatureComponent.';

	constructor() {
		super();
	}

	cancel(): void {
		this.onEvent.emit(new Event('abort'));
	}
}
