import { Component, OnInit, Injector } from '@angular/core';
import { CommonDataSource } from '@mdib/generic';
import { GenericListPage } from '@mdib/generic';
import { ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { OperationStatus, StandingOrder, StandingOrderAmountType, StandingOrderExecutionCode, StandingOrderFrequency, StandingOrdersPageFilter, StandingOrdersService } from '@mdib/standing-orders';
import { IconButton } from '@mdib/styling';
import { ServiceResponse } from '@mdib/utils';
import { CashAccount } from '@mdib/cash-accounts';
import { AuthorizationsService } from '@mdib/core/security';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'standing-orders-page',
	templateUrl: './standing-orders-page.component.html',
	styleUrls: ['./standing-orders-page.component.scss']
})

export class StandingOrdersPageComponent extends GenericListPage implements OnInit {

	readonly NAMESPACE = 'standingOrdersPagesModule.standingOrdersPageComponent.';
	readonly operationStatus = OperationStatus;
	readonly standingOrderFrequency = StandingOrderFrequency;
	readonly standingOrderAmountType = StandingOrderAmountType;
	readonly standingOrderExecutionCode = StandingOrderExecutionCode;

	public displayedColumns = ['ordererAccountNumber', 'counterparty', 'frequency',
		'executionCode', 'nextPaymentDate', 'amount', 'status', 'actions'];
	protected iconArray: Array<IconButton> = [
			{ iconName: 'delete', iconLabel: 'delete', link: '/payments/delete/standing-order/', type: 'europeanStandingOrder', token: 'standing-orders.delete'},
			{ iconName: 'visibility', iconLabel: 'consult', link: '/payments/retrieve/standing-order/', type: 'europeanStandingOrder', token: 'standing-orders.consult'}
		];
	// protected filter: StandingOrdersPageFilter;

	constructor(private standingOrdersService: StandingOrdersService,
		private route: ActivatedRoute,
		private authorizationService: AuthorizationsService,
		injector: Injector,
	) {
		super(injector);
		this.dataSourceType = CommonDataSource;
		this.listConfigurations.listTitle = this.NAMESPACE + 'pages:titles:standingOrders';
		this.listConfigurations.selectorTitle = this.NAMESPACE + 'accountLabel';
		this.listConfigurations.listEmptyMessage = this.NAMESPACE + 'listEmptyMessage';
		this.listConfigurations.showFilter = false;

		this.filter = {
			ordererAccountNumber: '',
		};
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.loadSearchModel(params, this.filter);
			this.fetchData();
		});
	}

	clearFilter(): void {
		// Manual filter not present, nothing to do
	}

	consultRow(row: any): void {
		// Nothing to do
	}

	readyToDeleteAndUpdate(row: any) {
		return (row.executionCode === '00');
	}

	onCashAccountChange(cashAccount: CashAccount): void {
		this.filter.ordererAccountNumber = (cashAccount != null) ? cashAccount.number : null;
		this.applyFilter();
	}

	protected load(index?: number, count?: number): Observable<ServiceResponse<any>> {
		return this.standingOrdersService.list(index, count, this.filter);
	}

	protected isAuthorized() {
		const tokens = [];
		this.iconArray.forEach(icon => {
			tokens.push(icon.token);
		});
		return this.authorizationService.authorized(tokens);
	}

}
