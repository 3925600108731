import { Injectable } from '@angular/core';
import { FileEntryXcl } from '../model/file-entry-xcl';
import { b64toBlob } from '../helper/xcl-connector-helpers';
import { FileStatus, MdibFile } from '@mdib/utils';

@Injectable()
export class FileMapperXclService {

	public toStatus(fileStatusXcl: string): FileStatus {
		const fileStatus = (fileStatusXcl === '9000' || '8000' ? FileStatus.Available : FileStatus.Unavailable);
		return fileStatus;
	}

	/**
	 * Parse technical model in functional model
	 * @param FileEntryXcl : source format.
	 * @returns MdibFile : parsed functional model format.
	 */
	public fileXclToMdibFile(fileXcl: FileEntryXcl): MdibFile {
		const file = <MdibFile>{
			status: this.toStatus(fileXcl.fileStatus),
			id: fileXcl.fileId,
			document: b64toBlob(fileXcl.file && fileXcl.file.content, fileXcl.file && fileXcl.file.contentType),
			name: fileXcl.file.name
		};
		return file;
	}
}
