
import { mergeMap, catchError, concatMap, reduce } from 'rxjs/operators';
import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Observable, zip, of, throwError, from } from 'rxjs';

import { ConfigurationStore } from '@mdib/utils';
import { HttpClient } from '@angular/common/http';

export const CONFIGURATION_FILE = new InjectionToken<string>('CONFIGURATION_FILE');

interface Property {
	key: string;
	value: any;
}

@Injectable()
export class FileConfigurationStore implements ConfigurationStore {

	private defaults: object = {};
	private properties: object = {};

	private readonly PROPERTY_NOT_FOUND = new Object();

	constructor(
		private _httpClient: HttpClient,
		@Inject(CONFIGURATION_FILE) private _configurationFiles: string[]
	) {
		// Ensure proper array
		if (!this._configurationFiles) {
			this._configurationFiles = this._configurationFiles || [];
		} else if (typeof this._configurationFiles === 'string') {
			this._configurationFiles = [this._configurationFiles];
		}
	}

	public load(): Observable<boolean> {
		return new Observable(observer => {
			const observables: Array<Observable<Property[]>> = [];

			this._configurationFiles.forEach(filename => {
				observables.push(this.loadConfigurationFile(filename));
			});

			// Once all files have been loaded, save properties in memory
			zip(...observables, (...propertiesArrays) => {
				propertiesArrays.forEach(properties => {
					properties.forEach(property => this.toObject(this.properties, property.key.split('.'), property.value));
				});
			}).subscribe(() => observer.next(true));
		});
	}

	public set(key: string, value: any): Observable<any> {
		this.toObject(this.properties, key.split('.'), value);
		return of(true);
	}

	public setDefaults(properties: { [key: string]: any; }) {
		this.defaults = this.mergeObjects(this.defaults, properties);
	}

	public instant(key: string): any {
		// Find the key in the configuration
		const path = key.split('.');
		let value = this.fromObject(this.properties, path);

		// Find a default value
		if (value === this.PROPERTY_NOT_FOUND) {
			value = this.fromObject(this.defaults, key.split('.'));
		}

		// Not found
		if (value === this.PROPERTY_NOT_FOUND) {
			value = undefined;
		}

		return value;
	}

	private loadConfigurationFile(filename: string, ignoreError = false): Observable<Property[]> {
		return this._httpClient
			.get(filename, { responseType: 'text' }).pipe(
				mergeMap(this.parsePropertiesFile.bind(this, filename)),
				catchError(err => {
					if (ignoreError) {
						return [];
					} else {
						return throwError(err);
					}
				}));
	}

	private parsePropertiesFile(filename: string, fileContent: string): Observable<Property[]> {
		// Create a context
		const context = {
			useJsonValue: 'true',
			absoluteDirectory: filename.substr(0, filename.lastIndexOf('/'))
		};

		// Parse each line
		const parser: (line: string) => Observable<Property[]> = this.parsePropertiesFileLine.bind(this, context);
		const lines = from(fileContent.split(/[\r\n]+/));
		return lines.pipe(
			concatMap(parser),
			reduce((result, props) => result.concat(props), [])
		);
	}

	private parsePropertiesFileLine(context: any, line: string): Observable<Property[]> {
		// Macros
		const macro = /^#!(set|include)(.*)$/gi;
		if (line.match(macro)) {
			const macroLine = macro.exec(line);
			const macroOpts = (macroLine[2] || '').trim().split(/\s+/g);
			switch (macroLine[1]) {
				case 'set':
					context[macroOpts[0]] = macroOpts[1];
					break;
				case 'include':
					let filepathToInclude: string = macroOpts[0];
					const continueOnFailure: boolean = (macroOpts[1] === 'true');
					// Allow relative path if starting with './'
					if (filepathToInclude.startsWith('./')) {
						filepathToInclude = context.absoluteDirectory + filepathToInclude.substr(1);
					}
					return this.loadConfigurationFile(filepathToInclude, continueOnFailure);
			}
			return of();
		}

		// Ignore empty lines & comments
		if (!line.match(/^[^#=]+=.*$/)) {
			return of();
		}

		// Parse the property
		const property = /([^=]+)=(.*)/.exec(line);
		let value = property[2];
		if (context.useJsonValue.toLowerCase() === 'true') {
			try {
				value = JSON.parse(value);
			} catch (error) {
				console.warn(error);
				console.warn('The property [%s] could not be parsed', property);
				return of();
			}
		}
		return of([{ key: property[1], value: value }]);
	}

	private fromObject(object: object, path: Array<string>) {
		let result = object;
		for (const pathToken of path) {
			if (!result || !result[pathToken]) { return this.PROPERTY_NOT_FOUND; }
			result = result[pathToken];
		}
		return result;
	}

	private toObject(object: object, path: Array<string>, value: any): any {
		const lastToken = path.pop();
		let result = object;
		for (const pathToken of path) {
			if (!result[pathToken]) {
				result[pathToken] = {};
			}
			result = result[pathToken];
		}
		result[lastToken] = value;
		return result;
	}

	/**
	 * Merge objects into one resulting object.
	 * Each source can potentially override the previous values.
	 *
	 * @param sources List of objects to merge
	 */
	private mergeObjects(...sources: any[]) {
		const target = {};
		sources.forEach(source => {
			if (source === null || source === undefined) {
				return;
			}

			for (const key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					if (typeof source[key] === 'object' && source[key] !== null) {
						target[key] = this.mergeObjects(target[key], source[key]);
					} else {
						target[key] = source[key];
					}
				}
			}
		});
		return target;
	}
}
