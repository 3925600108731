import { Injectable } from '@angular/core';
import { KeyingLimit } from '../model/keying-limit';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';

@Injectable()
export abstract class KeyingLimitsService {
	/**
	 * Retrieves the list of keying limits related to the current logged in user
	 * @param {number} [offset] Offset to start from (by default 0, i.e. from the beginning)
	 * @param {number} [limit] Maximum number of operations to retrieve (by default 0, i.e. no limit)
	 * @returns {Observable<ServiceResponse<KeyingLimiit>>} Array of keying limits related to logged in user
	 * @memberof KeyingLimitsService
	 */
	public abstract list(index?: number, count?: number): Observable<ServiceResponse<KeyingLimit[]>>;

	/**
	 * Counts the list of keying limits related to the current logged in user
	 * @returns {Observable<ServiceResponse<number>>} The number of keying limits related to principal
	 * @memberof KeyingLimitsService
	 */
	public abstract count(): Observable<ServiceResponse<number>>;
}
