import { Component } from '@angular/core';
import { SvgIconPath } from '@mdib/styling';

@Component({
	templateUrl: './session-logout-page.component.html',
	styleUrls: ['./session-logout-page.component.scss'],
})
export class SessionLogoutPageComponent {

	readonly NAMESPACE = 'sessionsPagesModule.sessionLogoutPageComponent.';
	public iconPath = SvgIconPath.NWBLOGO;
}
