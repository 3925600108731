import { Injectable, Injector } from '@angular/core';

import { UtilsHelper } from '@mdib/utils';

import { AdaptersService } from '../../services/adapters.service';

@Injectable()
export abstract class AdapterProxy {
	constructor(
		protected adaptersService: AdaptersService,
		protected injector: Injector,
	) {
		this.build();
	}

	protected abstract ServiceToken(): any;
	protected abstract ServiceDefinition(): any;

	private build(): any {
		// tslint:disable-next-line:forin
		for (const propertyName in this.ServiceDefinition().prototype) {
			const propertyDescription = UtilsHelper.getPropertyDescriptor(this.ServiceDefinition().prototype, propertyName);

			const getImplementation = () => {
				const serviceToken = this.adaptersService.getAdapter(this.ServiceToken());
				return this.injector.get(serviceToken);
			};

			const proxyValue: any = {};

			if (typeof propertyDescription.value === 'function') {
				proxyValue.value = (...params) => {
					const serviceImpl: any = getImplementation();
					return serviceImpl[propertyName].apply(serviceImpl, params);
				};
			} else {
				proxyValue.get = () => {
					const serviceImpl: any = getImplementation();
					return serviceImpl[propertyName];
				};

				proxyValue.set = (value: any) => {
					const serviceImpl: any = getImplementation();
					serviceImpl[propertyName] = value;
				};
			}

			Object.defineProperty(this, propertyName, proxyValue);
		}
	}
}
