import { NgModule } from '@angular/core';

// MDIB Modules
import { NAVIGATION_FILTERS } from '@mdib/core/navigation';

// XCL Adapters Modules
import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { NavigationFilterBusinessRoleService } from '@mdib-xcl/utils';
import { XclHttpModule } from '@mdib-xcl/http';

import { XclBeneficiariesModule } from '@mdib-xcl/beneficiaries';
import { XclCustomersModule } from '@mdib-xcl/customers';
import { XclPaymentsModule } from '@mdib-xcl/payments';
import { XclShoppingBasketModule } from '@mdib-xcl/shopping-basket';
import { XclCashAccountModule } from '@mdib-xcl/cash-accounts';
import { AccountStatementsXclModule } from '@mdib-xcl/account-statements';
import { XclKeyingLimitsModule } from '@mdib-xcl/keying-limits';
import { XclAccountValuationsModule } from '@mdib-xcl/account-valuations';
import { XclStandingOrdersModule } from '@mdib-xcl/standing-orders';
import { XclForgotPasswordModule } from '@mdib-xcl/forgot-password';
import { MailboxXclModule } from '@mdib-xcl/mailbox';
import { SessionsXclModule } from '@mdib-xcl/sessions';
import { SignaturesXclModule } from '@mdib-xcl/signature';
import { XclSepaDirectDebitsModule } from '@mdib-xcl/sepa-direct-debits';
import { PaymentFilesXclModule } from '@mdib-xcl/payment-files';
import { FileUploadersXclModule } from '@mdib-xcl/file-uploaders';
import { ElectonicalStatementXclModule } from '@mdib-xcl/electronical-statements';
import { PdfStatementsXclModule } from '@mdib-xcl/pdf-statements/pdf-statements-xcl.module';

@NgModule({
	imports: [
		XclHttpModule,
		XclPaymentsModule.forRoot(),
		XclCashAccountModule.forRoot(),
		XclShoppingBasketModule.forRoot(),
		XclBeneficiariesModule.forRoot(),
		AccountStatementsXclModule.forRoot(),
		PdfStatementsXclModule.forRoot(),
		XclCustomersModule.forRoot(),
		XclKeyingLimitsModule.forRoot(),
		XclAccountValuationsModule.forRoot(),
		XclStandingOrdersModule.forRoot(),
		XclForgotPasswordModule.forRoot(),
		MailboxXclModule.forRoot(),
		SessionsXclModule.forRoot(),
		SignaturesXclModule.forRoot(),
		XclSepaDirectDebitsModule.forRoot(),
		PaymentFilesXclModule.forRoot(),
		FileUploadersXclModule.forRoot(),
		ElectonicalStatementXclModule.forRoot(),
	],
	providers: [
		{ provide: XCL_ADAPTER_IDENTIFIER, useValue: 'xcl' },
		{ provide: NAVIGATION_FILTERS, multi: true, useClass: NavigationFilterBusinessRoleService },
	]
})
export class AppAdaptersXclImports {
}
