import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationMessageService, ServiceResponseNotificationMessagesMapperService } from '@mdib/notification-message';
import { PaymentsService, StandingOrderService, PaymentOperation, StandingOrderDetails, PaymentForm, PaymentTypes } from '@mdib/payments';
import { StandingOrder, StandingOrdersService } from '@mdib/standing-orders';
import { ServiceResponse, DataFormattingService, Status, UtilsHelper, CacheService, Feedback } from '@mdib/utils';
import { finalize } from 'rxjs/operators';
import { ConfigService } from '@mdib/config';
import { FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Location } from '@angular/common';

enum WizardSteps { DataGathering, Validation, Confirmation }

@Component({
	selector: 'update-standing-order-page',
	templateUrl: './update-standing-order-page.component.html',
	styleUrls: ['./update-standing-order-page.component.scss'],
})
export class UpdateStandingOrderPageComponent implements OnInit {

	get paymentForm(): PaymentForm {
		return this._paymentForm;
	}

	get paymentOperation(): PaymentOperation<any> {
		return this._paymentOperation;
	}

	readonly NAMESPACE = 'standingOrdersPagesModule.updateStandingOrderPageComponent.';

	readonly Status = Status;
	public errorMessage: string = null;

	actionRequestOngoing = false;
	showSignature: boolean;
	selectedStep = 0;

	private _paymentForm: PaymentForm;
	private _paymentOperation: PaymentOperation<any>;


	constructor(private standingOrdersService: StandingOrderService,
		private route: ActivatedRoute,
		private notifications: NotificationMessageService,
		private serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService,
		private router: Router,
		private notificationMessageService: NotificationMessageService,
		private cacheService: CacheService,
		private configService: ConfigService,
		private location: Location
	) {
		this.paymentFormInit(null, true);
	}

	resetStepper(stepper: MatStepper, paymentFormTypes: PaymentTypes) {
		stepper.previous();
		this.notifications.clearAll();
		this._paymentOperation = this._paymentForm.model;
		this.paymentFormInit(this._paymentOperation, true);
	}

	ngOnInit() {
		const standingOrderReferenceToUpdate = this.route.snapshot.paramMap.get('reference');
		if (standingOrderReferenceToUpdate) {
			this.standingOrdersService.get(standingOrderReferenceToUpdate).subscribe(
				(serviceResponse: ServiceResponse<PaymentOperation<StandingOrderDetails>>) => {
				this._paymentOperation = serviceResponse.getModel();
				this.paymentFormInit(this._paymentOperation, true);
				this.treatResponse(serviceResponse);
			},
		(serviceResponseError) => this.treatResponse(serviceResponseError));
		}
	}

	selectionChange(event: StepperSelectionEvent) {
		this.selectedStep = event.selectedIndex;
		if (this.selectedStep === 0) {
			this.paymentFormInit(this.paymentForm.model, true);
		} else {
			this._paymentForm.disable();
		}
		UtilsHelper.scrollTo();
	}


	public validateStandingOrder(stepper: MatStepper) {
		this.notifications.clearAll();
		this.actionRequestOngoing = true;
		this.standingOrdersService.validateUpdate(this.paymentForm.model).subscribe(
			(serviceResponse: ServiceResponse<PaymentOperation<StandingOrderDetails>>) => {
				this.paymentFormInit(serviceResponse.getModel());
				this.treatResponse(serviceResponse);
				stepper.next();
			},
			(serviceResponseError) => this.treatResponse(serviceResponseError)
		);
	}

	public confirmStandingOrder(stepper: MatStepper) {
		this.notifications.clearAll();
		this.standingOrdersService.confirm(this.paymentForm.model).subscribe(
			(serviceResponse: ServiceResponse<PaymentOperation<null>>) => {
				this.paymentFormInit(serviceResponse.getModel());
				this.treatResponse(serviceResponse);
				stepper.next();
			},
			(serviceResponseError) => this.treatResponse(serviceResponseError)
		);
	}

	signOperation() {
		this.notifications.clearAll();
		this.showSignature = true;
	}

	handleSignatureSuccess(stepper: MatStepper): void {
		this.showSignature = false;
		this.actionRequestOngoing = true;
		this.standingOrdersService.sign(this.paymentForm.model).subscribe(
			(serviceResponse: ServiceResponse<PaymentOperation<null>>) => {
				this.paymentFormInit(serviceResponse.getModel());
				if (serviceResponse.getModel().saveBeneficiary === true) {
					this.cacheService.invalidate('beneficiaries');
				}
				this.treatResponse(serviceResponse);
				stepper.next();
			},
			(serviceResponseError) => this.treatResponse(serviceResponseError)
		);
		this.cacheService.invalidate('accounts');
	}

	handleSignatureErrorsOrAbort(feedback: Feedback): void {
		this.serviceResponseNotifMapper.sendFeedback(feedback);
		this.showSignature = false;
	}

	paymentFormInit(payment?: PaymentOperation<any>, subscribe = false) {
		this._paymentForm = new PaymentForm(new FormBuilder(), new DataFormattingService(), new ConfigService(), subscribe, !!payment ? payment : null);
		this._paymentForm.ordererAccount.disable();
		this._paymentForm.type.disable();

		if (this._paymentForm.counterPartyAccount) {
			this._paymentForm.counterPartyAccount.disable();
		}

		if (this._paymentForm.beneficiaryForm) {
			this._paymentForm.beneficiaryForm.disable();
			this._paymentForm.beneficiaryForm.communicationForm.enable();
		}

		if (this._paymentForm.standingOrderDetailsForm) {
			this._paymentForm.standingOrderDetailsForm.amountType.disable();
			this._paymentForm.standingOrderDetailsForm.startDate.disable();
		}
	}

	goBack() {
		this.location.back();
	}

	private treatResponse(serviceResponse: ServiceResponse<any>) {
		this.serviceResponseNotifMapper.clearAll();
		this.actionRequestOngoing = false;
		UtilsHelper.scrollTo();
		this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponse, this.NAMESPACE);
	}
}
