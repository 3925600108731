export abstract class MapperPartialService<STD, XCL> {

	public abstract fromXcl(value: XCL): STD;

	public abstract toXcl(value: STD, target?: XCL): Partial<XCL>;

	protected setIfDefined(target: any, key: string, value: any) {
		if (value !== undefined) {
			target[key] = value;
		} else {
			delete target[key];
		}
	}
}
