import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BankConfig, CurrenciesSpecificDecimals, ManagedCurrencyCodes } from '@mdib/config';

/**
 * My role is to beautify currencies based on {@link BankConfig.baseLocale}
 * @example
 * amount | mdibCurrency:currency, true, '2.2-4'
 */
@Pipe({
	name: 'mdibCurrency',
})
export class MdibCurrencyPipe implements PipeTransform {

	readonly currency = new CurrencyPipe(BankConfig.baseLocale);

	/**
	 * Use the currencyPipe from Angular to format a number as currency.
	 * @param currencyCode String optional currency code to convert or bank's base currency code is applied.
	 * @param symbolDisplay Boolean indicating whether to use the currency symbol or code.
	 *                - `true`(default): use symbol (e.g. `$`).
	 *                - `false`: use code (e.g. `USD`).
	 * @param decimals  Optional numeric input for number of decimal places to show. Falls back to CurrenciesSpecificDecimals (config.enum.ts) if not specified.
	 *                    If the currency's decimals are not specified in the enum, the default decimal places of Angular's CurrencyPipe will be used.
	 * @return The currency transformed
	 */
	transform(value: any, currencyCode?: ManagedCurrencyCodes, symbolDisplay = true, decimals?: number): string {
		const symbolDisplayOption: 'symbol-narrow' | 'code' = !symbolDisplay ? 'code' : 'symbol-narrow';
		let digitInfo = undefined;
		if (decimals !== undefined) {
			digitInfo = this.decimalToDigitInfo(decimals);
		} else if (CurrenciesSpecificDecimals[currencyCode]) {
			digitInfo = this.decimalToDigitInfo(CurrenciesSpecificDecimals[currencyCode]);
		}
		return this.currency.transform(value, currencyCode ? currencyCode : BankConfig.baseCurrencyCode, symbolDisplayOption, digitInfo);
	}

	/**
	 * Convert a single digit decimal into the specific 'digitInfo' format required by the CurrencyPipe. See {@link DecimalPipe} for detailed description.
	 * @param decimal Numeric input for number of decimals places to show.
	 * @return The decimal converted to 'digitInfo' format. Decimal: 2 becomes digitInfo: '1.2-2'.
	 */
	decimalToDigitInfo(decimal: number) {
		return '1.' + decimal + '-' + decimal;
	}
}
