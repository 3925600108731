import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[mdibPhoneInputMask]'
})
export class PhoneInputMaskDirective {

	constructor(private el: ElementRef) { }

	@HostListener('input')
	onEvent() {
		if (!!this.el.nativeElement.value) {
			this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^0-9+]/g, '');
		}
	}
}
