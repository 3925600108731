import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as moment from 'moment';

import { ServiceResponse } from '@mdib/utils';
import { CashAccountOperation, CashAccountOperationCommonService, CashAccountOperationsFilter } from '@mdib/cash-accounts';

import { CashAccountOperationMock } from '../mock/cash-account-operation-mock';

@Injectable({
	providedIn: 'root'
})
export class CashAccountOperationMemoryService extends CashAccountOperationCommonService {

	public list(accountNumber: string, index?: number, count?: number, filter?: CashAccountOperationsFilter): Observable<ServiceResponse<CashAccountOperation[]>> {
		const results = this.filterList(CashAccountOperationMock.getMockCashAccountOperations(accountNumber), filter);
		return of(new ServiceResponse<CashAccountOperation[]>(
			results.slice(index || 0, (index + count) || undefined),
			[],
			{ totalCount: results.length },
		)).pipe(delay(500));
	}

	public count(filter?: CashAccountOperationsFilter): Observable<ServiceResponse<number>> {
		return of(new ServiceResponse<number>(
			CashAccountOperationMock.getMockCashAccountOperations(filter.cashAccount.number).length
		)).pipe(delay(500));
	}

	private filterList(list: CashAccountOperation[], filter: CashAccountOperationsFilter): CashAccountOperation[] {
		return list.filter((operation: CashAccountOperation) => {
			if (!filter) { return true; }
			if (filter.counterPartyAccountNumber && operation.counterpartyAccount !== filter.counterPartyAccountNumber) {
				return false;
			}

			if (filter.counterPartyName && operation.counterpartyName.toUpperCase().indexOf(filter.counterPartyName.toUpperCase()) < 0) {
				return false;
			}

			if (filter.credit === false && !operation.isDebitOperation) {
				return false;
			}

			if (filter.debit === false && operation.isDebitOperation) {
				return false;
			}

			if (filter.endDate && moment(operation.date, 'YYYYMMDD').isAfter(moment(filter.endDate))) {
				return false;
			}

			if (filter.startDate && moment(operation.date, 'YYYYMMDD').isBefore(moment(filter.startDate))) {
				return false;
			}

			if (filter.maxAmount && Math.abs(operation.amount) > filter.maxAmount) {
				return false;
			}

			if (filter.minAmount && Math.abs(operation.amount) < filter.minAmount) {
				return false;
			}

			return true;
		});
	}

}
