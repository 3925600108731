import { Injectable } from '@angular/core';
import { PaymentOperation } from '@mdib/payments';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { StandingOrderService } from './standing-order.service';
import { StandingOrderDetails } from '../../types/models/standing-order-details';
import { SignableOperation } from '@mdib/signature';

@Injectable()
export class StandingOrderStubService extends StandingOrderService {

	public validate(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>> {
		return of();
	}

	public confirm(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null> | null> {
		return of();
	}

	public sign(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>> {
		return of();
	}

	public getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails> | null>> {
		return of();
	}

	public get(reference: string): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>> {
		return of();
	}

	public initUpdate(reference: string): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>> {
		return of();
	}

	public validateUpdate(payment: PaymentOperation<StandingOrderDetails>): Observable<ServiceResponse<PaymentOperation<StandingOrderDetails>>> {
		return of();
	}

	public delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>> {
		throw new Error('Method not implemented.');
	}

	public signDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>> {
		throw new Error('Method not implemented.');
	}

	public confirmDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<StandingOrderDetails>>>> {
		throw new Error('Method not implemented.');
	}

}
