import { Injectable } from '@angular/core';
import { AdapterProxy } from '@mdib/core/adapters';
import { SepaDirectDebitContractsService } from './sepa-direct-debit-contracts.service';
import { SepaDirectDebitContractsServiceStub } from './sepa-direct-debit-contracts.service.stub';

@Injectable()
export class SepaDirectDebitContractsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return SepaDirectDebitContractsService; }
	protected ServiceDefinition() { return SepaDirectDebitContractsServiceStub; }
}
