import { Pipe, Optional, Inject, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { NAMESPACE, FEATURE } from '../types/tokens/translate-tokens';
import { TranslationsService } from '../services/translations/translations.service';

@Pipe({
	name: 'translate',
	pure: false,
})
export class TranslatePipe implements PipeTransform, OnDestroy {

	private curentKey: string;
	private curentParams: any;
	private curentTranslation: string;

	private subscription: Subscription;

	constructor(
		@Inject(FEATURE) @Optional() private feature: string,
		@Inject(NAMESPACE) @Optional() private namespace: string,
		private translationsService: TranslationsService,
		private changeDetectorRef: ChangeDetectorRef,
	) {
		this.subscription = this.translationsService.onChange.subscribe(this.updateTranslation.bind(this));
	}

	public ngOnDestroy(): void {
		if (this.subscription) {
			this.curentKey = null;
			this.curentParams = null;
			this.curentTranslation = null;
			this.subscription.unsubscribe();
		}
	}

	public transform(translationKey: any, params: any): any {
		// Build the key
		const feature = this.feature ? this.feature + '.' : '';
		const namespace = this.namespace ? this.namespace + '.' : '';
		const key = feature + namespace + translationKey;

		// Refresh translation
		if (this.curentKey !== key || this.curentParams !== params) {
			this.curentKey = key;
			this.curentParams = params;
			this.updateTranslation();
		}

		// Return the translation
		return this.curentTranslation;
	}

	private updateTranslation() {
		if (this.curentKey) {
			this.curentTranslation = this.translationsService.translate(this.curentKey, this.curentParams);
			this.changeDetectorRef.markForCheck();
		}
	}

}
