import { Immutable } from '@mdib/core/meta';

import { SecretQuestion, SecretQuestionModel } from './secret-question';

export interface SecretQuestionsListModel {
	questions: Array<SecretQuestionModel>;
}

@Immutable()
export class SecretQuestionsList implements SecretQuestionsListModel {
	public readonly questions: Array<SecretQuestion> = [];

	constructor(properties: Partial<SecretQuestionsList> = {}) {
		Object.assign(this, properties);
	}

	public set(key: string, value: any): SecretQuestionsList {
		const copy: any = {};
		Object.assign(copy, this);
		copy[key] = value;
		return new SecretQuestionsList(copy);
	}

	public setQuestions(questions: Array<SecretQuestion>): SecretQuestionsList {
		return this.set('questions', questions);
	}
}
