import { Injectable } from '@angular/core';
import { Authentication } from '@mdib/sessions';
import { OtpAuthenticationService } from '@mdib/sessions-modes';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';

import { SessionHelper } from '../helpers/session-helper';
import { AuthenticationMemoryService } from './authentication-memory.service';

@Injectable({
	providedIn: 'root',
})
export class OtpAuthenticationMemoryService extends AuthenticationMemoryService implements OtpAuthenticationService {

	public authenticate(authentication: Authentication, digipassResponse: string): Observable<ServiceResponse<Authentication>> {
		this.setAccessRights(authentication.user.id);
		return of(new ServiceResponse(SessionHelper.mockEnticate('')));
	}

	public initAuth(username: string): Observable<ServiceResponse<Authentication>> {
		return of(new ServiceResponse(SessionHelper.mockEnticate(username)));
	}

}
