import { MdibFile, ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { PdfStatement, PdfStatementSummary } from '@mdib/pdf-statements/model/pdf-statement';
import { PdfStatementsService } from '@mdib/pdf-statements/service/pdf-statements.service';
import { PdfStatementsPageFilter } from '@mdib/pdf-statements/model/pdf-statement-page-filter';

export class PdfStatementsServiceStub extends PdfStatementsService {
	list(index?: number, count?: number, filter?: PdfStatementsPageFilter): Observable<ServiceResponse<PdfStatementSummary[]>> {
		return of();
	}

	count(filter?: PdfStatementsPageFilter): Observable<ServiceResponse<number>> {
		return of();
	}

	get(accountStatementSummary: PdfStatementSummary): Observable<ServiceResponse<PdfStatement>> {
		return of();
	}

	update(accountStatement: PdfStatement): Observable<ServiceResponse<PdfStatement>> {
		return of();
	}

	initializeFilter(): PdfStatementsPageFilter {
		return undefined;
	}

	getRepresentedClientNumber(): Observable<ServiceResponse<string>> {
		return of();
	}

}
