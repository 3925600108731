import { Injectable } from '@angular/core';
import { AdapterProxy } from '@mdib/core/adapters';
import { SepaDirectDebitPaymentsService } from './sepa-direct-debit-payments.service';
import { SepaDirectDebitPaymentsServiceStub } from './sepa-direct-debit-payments.service.stub';

@Injectable()
export class SepaDirectDebitPaymentsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return SepaDirectDebitPaymentsService; }
	protected ServiceDefinition() { return SepaDirectDebitPaymentsServiceStub; }
}
