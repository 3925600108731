import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
	selector: '[mdibCloseOnClick]'
})
export class CloseOnClickDirective {

	// FIXME : Temporary complete drop-down should be created

	@Input() dropped: boolean;
	@Output() drop = new EventEmitter<boolean>();

	constructor(private el: ElementRef) {
	}

	@HostListener('document:click', ['$event.target'])
	public onClick(targetElement) {
		if (!this.el.nativeElement.contains(targetElement) && this.dropped) {
			this.drop.emit(!this.dropped);
		}
	}
}
