import { Pipe, PipeTransform } from '@angular/core';
import * as accounting from 'accounting';

@Pipe({
	name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

	constructor() {
		accounting.settings.currency = {
			symbol: '€',   // default currency symbol €
			format: '%v %s', // controls output: %s = symbol, %v = value/number (can be object: see below)
			decimal: ',',  // decimal separator
			thousand: '.',  // thousands separator
			precision: 2   // decimal places
		};

		accounting.settings.number = {
			precision: 0,  // default precision on numbers is 0
			thousand: '.',
			decimal: ','
		};
	}

	transform(value: number, currency: string): any {
		if (typeof value === 'number' && !value) { return value; }
		let result: any;

		if (currency === 'EUR') {
			result = accounting.formatMoney(value);
		} else {
			result = this.getCurrencyFormat(value, currency);
		}
		return result;
	}

	// Currency list
	private getCurrencyFormat(value: number, currency: string): any {
		if (currency === 'CHF') {
			return this.formatCHF(value);
		} else if (currency === 'GBP') {
			return this.formatGBP(value);
		} else if (currency === 'DDK') {
			return this.formatDDK(value);
		} else if (currency === 'USD') {
			return this.formatUSD(value);
		}
	}


	// Format currency implementation
	private formatCHF(value: number): any {
		return accounting.formatMoney(value, {
			symbol: 'CHF',
			format: '%v %s' // 1.000,00 CHF
		});
	}

	private formatGBP(value: number): any {
		return accounting.formatMoney(value, {
			symbol: '£',
			format: '%s %v' // £ 1.000,00
		});
	}

	private formatDDK(value: number): any {
		return accounting.formatMoney(value, {
			symbol: 'kr',
			format: '%s %v' // kr 1.000,00
		});
	}

	private formatUSD(value: number): any {
		return accounting.formatMoney(value, {
			symbol: '$',
			format: '%s %v' // $ 1.000,00
		});
	}
}
