import { Injectable } from '@angular/core';
import { PaymentOperation } from '@mdib/payments/types/models/payment-operation';
import { Subject } from 'rxjs';

@Injectable()
export class PaymentUpdateService {

	paymentBeingCreatedBroadcast = new Subject<PaymentOperation<any>>();
	currentPayment = this.paymentBeingCreatedBroadcast.asObservable();

	constructor() { }

	public broadcastPaymentChange(payment: PaymentOperation<any>) {
		this.paymentBeingCreatedBroadcast.next(payment);
	}
}
