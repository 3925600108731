import { Injectable } from '@angular/core';
import { PaymentOperation } from '@mdib/payments';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs/index';
import { SignableOperation } from '@mdib/signature';

@Injectable()
export abstract class InstantPaymentsService {

	/**
	 * Validate instant payment operation
	 * @param {PaymentOperation<null>} payment
	 * @return {Observable<ServiceResponse<PaymentOperation<null> | null>>}
	 */
	public abstract validate(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>>;

	/**
	 * Confirm instant payment operation
	 * @param {PaymentOperation<null>} payment
	 * @return {Observable<ServiceResponse<PaymentOperation<null> | null> | null>}
	 */
	public abstract confirm(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null> | null>;

	public abstract confirmDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>>;

	/**
	 * Sign instant payment operation
	 * @param {PaymentOperation<null>} payment
	 * @returns {Observable<ServiceResponse<PaymentOperation<null> | null>>}
	 */
	public abstract sign(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>>;

	/**
	 * Get an instant payment operation from the shopping basket
	 * @param {string} reference, corresponding to the reference of the consulted instant payment
	 * @returns {Observable<ServiceResponse<PaymentOperation<null> | null>>}
	 */
	public abstract getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<null>>>;

	public abstract get(reference: string): Observable<ServiceResponse<PaymentOperation<null>>>;

	public abstract delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>>;

	public abstract signDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>>;
}
