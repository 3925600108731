import { Injectable } from '@angular/core';
import { BicService } from '@mdib/cash-accounts';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';

@Injectable({
	providedIn: 'root'
})
export class BicMemoryService extends BicService {

	public get(account: string): Observable<ServiceResponse<string> | null> {
		return of(new ServiceResponse<string>('GKCCBEBB'));
	}
}
