import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LocalizationService {

	private curentLanguage: string;

	private onChangeEvents = new Subject<string>();

	public get language(): string {
		return this.curentLanguage;
	}

	public set language(language: string) {
		this.curentLanguage = language;
		this.onChangeEvents.next(language);
	}

	public get onChange(): Observable<string> {
		return this.onChangeEvents.asObservable();
	}
}
