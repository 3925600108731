import { Injectable } from '@angular/core';
import { AbstractOrderXCLModel, FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, MultigoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { XclAPI, XclDateFormatter, XclOrderNature } from '@mdib-xcl/utils';
import { ParameterModel } from '@mdib/http';
import { PaymentsCommonService, PaymentOperation, PaymentTypes, PendingPayment, PendingPaymentsPageFilter } from '@mdib/payments';
import { SignableOperation } from '@mdib/signature';
import { Feedback, FeedbackTypes, ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { XclPendingPayment } from '../model/xcl-pending-payment';

import { PaymentsOperationMapperXclService } from './payments-operation-mapper-xcl.service';
import { PendingPaymentsMapperXclService } from './pending-payments-mapper-xcl.service';
import { SepaPaymentXclService } from './sepa-payment-xcl.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentsXclService extends PaymentsCommonService {

	readonly defaultParams = [
		new ParameterModel('isEuropeanPayment', 'true'),
		new ParameterModel('isInternationalPayment', 'true'),
		new ParameterModel('isEuropeanInstantPayment', 'true'),
		new ParameterModel('listAllowedChannels', '01'),
		new ParameterModel('listAllowedChannels', 'null'),
	];

	constructor(private xclHttpService: XclHttpService,
		private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private mapper: PaymentsOperationMapperXclService,
		private xclSepaPaymentsService: SepaPaymentXclService,
		private xclPendingPaymentsMapperService: PendingPaymentsMapperXclService) {
		super();
	}

	public confirmPayment(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null> | null> {
		let confirmPaymentStream: Observable<ServiceResponse<PaymentOperation<null> | null> | null>;

		confirmPaymentStream = this.xclSepaPaymentsService.confirmPaymentOperation(payment);

		return confirmPaymentStream;
	}

	public validatePayment(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>> {

		let paymentValidationStream: Observable<ServiceResponse<PaymentOperation<null> | null>>;

		paymentValidationStream = this.xclSepaPaymentsService.initPaymentOperation(payment).pipe(
			mergeMap(
				(order: MonogoalOrderXCLModel<any>) =>
					this.xclSepaPaymentsService.retrievePaymentOperation(order.reference),
			),
			mergeMap(
				(order: MonogoalOrderXCLModel<any>) =>
					this.xclSepaPaymentsService.validatePaymentOperation(order.reference, payment),
			));

		return paymentValidationStream;
	}

	public get(reference: string, type: PaymentTypes): Observable<ServiceResponse<PaymentOperation<null>>> {
		let paymentValidationStream: Observable<ServiceResponse<PaymentOperation<null>>>;

		paymentValidationStream = this.xclSepaPaymentsService.get(reference);

		return paymentValidationStream;
	}

	public getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<null> | null>> {
		let draftPaymentStream: Observable<ServiceResponse<PaymentOperation<null> | null>>;

		const params: ParameterModel[] = [
			new ParameterModel('orderReference', reference),
		];

		// Retrieve the technical model and convert it to functional one
		draftPaymentStream = this.xclHttpService.execute('order', XclHttpService.GET, params, null).pipe(
			map((xclOrder: MonogoalOrderXCLModel<any>) => {
				switch (xclOrder.orderNature.orderNatureCode) {
					case XclOrderNature.SEPAPaymentCreation:
						return new ServiceResponse<PaymentOperation<null>>(
							this.mapper.feedFromSEPAPaymentOperationOrderXCLModel(new PaymentOperation<null>(), xclOrder, XclAPI.retrieve),
							this.feedbackExtractor.extract(xclOrder),
						);

					default:
						throw ServiceResponse.emptyWithOnefeedback(new Feedback('draftPaymentNotManaged', FeedbackTypes.FRONTEND_ERROR,
							'This payment type is not managed.'));
				}
			}));

		return draftPaymentStream;
	}

	public signPayment(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>> {

		let paymentSignStream: Observable<ServiceResponse<PaymentOperation<null> | null>>;
		paymentSignStream = this.xclSepaPaymentsService.signPaymentOperation(payment);

		return paymentSignStream;
	}

	public delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>> {

		return this.xclSepaPaymentsService.initDeletePaymentOperation(reference).pipe(
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.xclSepaPaymentsService.retrieveDeletePaymentOperation(order.reference)),
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.xclSepaPaymentsService.validateDelete(order.reference)));

	}

	public signDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {

		let paymentSignStream: Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>>;

		paymentSignStream = this.xclSepaPaymentsService.signPaymentDeletion(signablePaymentOperation);
		return paymentSignStream;
	}

	public confirmDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>> {

		let confirmPaymentStream: Observable<ServiceResponse<SignableOperation<PaymentOperation<null> | null> | null>>;

		confirmPaymentStream = this.xclSepaPaymentsService.confirmDeletePaymentOperation(signablePaymentOperation.reference);

		return confirmPaymentStream;
	}

	count(filter?: PendingPaymentsPageFilter): Observable<ServiceResponse<number>> {
		let params: ParameterModel[] = [...this.defaultParams];

		if (filter) {
			params = params.concat(this.fromPendingPaymentsFilterToParamsList(filter));
		}

		return this.xclHttpService.execute('pending-payments-count', XclHttpService.GET, params).pipe(
			map((xclOrder: AbstractOrderXCLModel) =>
				new ServiceResponse<number>(
					xclOrder.rowCount,
					this.feedbackExtractor.extract(xclOrder),
				),
			));
	}

	list(index: number, count: number, filter?: PendingPaymentsPageFilter): Observable<ServiceResponse<PendingPayment[]>> {
		let params: ParameterModel[] = [
			new ParameterModel('start', (index || 0).toString()),
		];

		if (count !== null) {
			params.push(new ParameterModel('maxResults', count.toString()));
		}

		params.push(...this.defaultParams);

		if (filter) {
			params = params.concat(this.fromPendingPaymentsFilterToParamsList(filter));
		}

		return this.xclHttpService.execute('pending-payments', XclHttpService.GET, params).pipe(
			map((xclOrder: MultigoalOrderXCLModel<XclPendingPayment>) =>
				new ServiceResponse<PendingPayment[]>(
					this.xclPendingPaymentsMapperService.getPendingPaymentArrayFromTechnicalModelArray(xclOrder.goalList),
					this.feedbackExtractor.extract(xclOrder),
					{ totalCount: xclOrder.rowCount },
				),
			));
	}

	private fromPendingPaymentsFilterToParamsList(filter: PendingPaymentsPageFilter): ParameterModel[] {

		const params: ParameterModel[] = [];

		if (filter.ordererAccountNumber) {
			params.push(new ParameterModel('ordererAccountNumber', filter.ordererAccountNumber));
		}
		if (filter.counterpartyName) {
			params.push(new ParameterModel('counterpartyName', filter.counterpartyName));
		}
		if (filter.counterpartyAccountNumber) {
			params.push(new ParameterModel('counterpartyAccountNumber', filter.counterpartyAccountNumber));
		}
		if (filter.maturityDateFrom) {
			params.push(new ParameterModel('startMaturityDate', XclDateFormatter.convertDateToXCLDateFormat(filter.maturityDateFrom)));
		}
		if (filter.maturityDateTo) {
			params.push(new ParameterModel('endMaturityDate', XclDateFormatter.convertDateToXCLDateFormat(filter.maturityDateTo)));
		}
		if (filter.amountFrom) {
			params.push(new ParameterModel('minAmount', filter.amountFrom.toString()));
		}
		if (filter.amountTo) {
			params.push(new ParameterModel('maxAmount', filter.amountTo.toString()));
		}
		return params;
	}
}
