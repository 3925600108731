import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { GenericTranslateParser } from '../../service/generic-translate-parser';
import { TranslationLoaderMock } from './translation-loader.mock';

export class TranslateModuleMock {
	public static forRoot(): ModuleWithProviders {
		return TranslateModule.forRoot({
			loader: { provide: TranslateLoader, useClass: TranslationLoaderMock },
			parser: { provide: TranslateParser, useClass: GenericTranslateParser }
		});
	}
}
