import { Injectable } from '@angular/core';

import { AuthorizationsService, AccessRightToken } from '@mdib/core/security';

import { Session, SessionRestorationService } from '@mdib/sessions';
import { AccessRightTokenXclDefinition, AccessRightTokenXcl } from '@mdib-xcl/utils';
import * as XclRolesMapping from 'assets/configuration/security/authorizations-mapping.json';

@Injectable()
export class SessionRestorationMemoryService extends SessionRestorationService {

	constructor(
		private authorizationsService: AuthorizationsService,
	) { super(); }

	public restore(session: Session): boolean {
		// Not an XCL session
		if (session.type !== 'MOCK') {
			return false;
		}

		// Restore access rights
		this.restoreAccessRights();

		return true;
	}

	protected restoreAccessRights() {
		this.authorizationsService.flushAccessRights();
		// Access rights are assigned in MockAccess based on a role defined in authorizations-mapping.json
		const accessRights: Array<string | AccessRightTokenXclDefinition> = [];
		const roleMapping: Array<string | AccessRightTokenXclDefinition> = XclRolesMapping['ADMINISTRATOR'] || [];
		roleMapping.forEach(token => {
			this.authorizationsService.addAccessRight(
				new AccessRightTokenXcl(token)
			);
		});
	}

}
