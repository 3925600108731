import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateValidator } from '../../validator/date-validator';

@Component({
	selector: 'input-errors',
	templateUrl: './input-errors.component.html',
})
export class InputErrorsComponent {

	@Input() control: FormControl;
	@Input() isMatErrorMessage = false;

	constructor(private translate: TranslateService) {
	}

	get errorMessages(): string[] {
		const errors = this.control && this.control.errors;
		const errorTranslations: string [] = [];
		if (!!errors) {
			Object.keys(errors).forEach((error: string) => {
				if (error.startsWith('matDatepicker')) {
					errorTranslations.push(this.translate.instant(DateValidator.NAMESPACE + error, errors[error].params || null));
				} else {
					const key = errors[error].translationKey || ('hints:errors:' + error);
					errorTranslations.push(this.translate.instant(key, errors[error].params || errors[error]));
				}
			});
		}
		return errorTranslations;
	}
}
