import { Component, Input } from '@angular/core';
import { ExchangeRateService } from '@mdib/utils';

@Component({
	selector: 'mdib-currency-exchange',
	templateUrl: './currency-exchange.component.html',
	styleUrls: ['./currency-exchange.component.scss'],
})
export class CurrencyExchangeComponent {

	@Input() currency: string;
	@Input() shipCurrency: string;

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.dataGatheringStepComponent.amountSectionComponent.currencyExchangeComponent.';

	constructor(private _exchanceRateService: ExchangeRateService) {
	}

	// TODO: service should return rate data
	rate(): number {
		return this._exchanceRateService.getCurrencyExchangeFromTo(this.currency, this.shipCurrency);
	}

	date() {
		return new Date();
	}
}
