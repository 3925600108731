import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Beneficiary } from '@mdib/beneficiaries';
import { CashAccountBuilder } from '@mdib/cash-accounts';
import { Communication } from '@mdib/commons';
import { SignableOperation } from '@mdib/signature';
import { InternationalPaymentDetails, InternationalPaymentsService, PaymentOperation, PaymentTypes, PendingPayment } from '@mdib/payments';
import { SignatureModes } from '@mdib/signature-modes';
import { ServiceResponse, Status } from '@mdib/utils';

import { PendingPaymentsMock } from '../mock/pending-payments-mock';
import { MockShoppingBasketOperation } from '@mdib-memory/shopping-basket/service/mockdata/mock-shopping-basket-operation';
import { InternationalPaymentsMock } from '../mock/international-payments-mock';

@Injectable({
	providedIn: 'root',
})
export class InternationalPaymentsMemoryService extends InternationalPaymentsService {

	readonly pendingPayments = PendingPaymentsMock.pendingPayments;
	readonly draftPayments = MockShoppingBasketOperation.getMockShoppingBasketOperations();
	readonly DEFAULT_DELAY = 400;

	public validate(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>> {
		payment.amount < 100 ? payment.status = Status.ToConfirm : payment.status = Status.ToSign;
		payment.signatureTypesAllowed = [SignatureModes.PASSWORD];
		payment.paymentDate = payment.paymentDate || new Date();
		return of(new ServiceResponse<PaymentOperation<InternationalPaymentDetails>>(payment));
	}

	public get(reference: string): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>> {
		return of(new ServiceResponse<PaymentOperation<InternationalPaymentDetails>>(
			InternationalPaymentsMock.getMockInternationalPayments().find((internationalPayment) => internationalPayment.reference === reference)),
		);
	}

	public getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails>>> {
		return of(new ServiceResponse<PaymentOperation<InternationalPaymentDetails>>(
			InternationalPaymentsMock.getMockInternationalPayments().find((internationalPayment) => internationalPayment.reference === reference)),
		);
	}

	public confirm(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null> | null> {
		payment.status === Status.ToConfirm ? payment.status = Status.Closed : payment.status = Status.InShoppingBasket;
		return of(new ServiceResponse<PaymentOperation<InternationalPaymentDetails>>(payment));
	}

	public sign(payment: PaymentOperation<InternationalPaymentDetails>): Observable<ServiceResponse<PaymentOperation<InternationalPaymentDetails> | null>> {
		payment.status = Status.Closed;
		return of(new ServiceResponse<PaymentOperation<InternationalPaymentDetails>>(payment));
	}

	public delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>> | null>> {

		let operation;
		operation = this.pendingPayments.filter(payment => payment.reference === reference).pop();

		operation = <PaymentOperation<InternationalPaymentDetails>>{
			amount: operation.amount,
			counterParty: <Beneficiary> {
				fullName: operation.counterpartyName,
				account: new CashAccountBuilder().accountNumber(operation.counterpartyAccountNumber).clientWording(operation.ordererName).cashAccount,
				communication: new Communication({
					value: operation.communication,
					type: operation.communicationType,
				}),
			},
			currency: operation.currency,
			paymentDate: operation.maturityDate,
			ordererAccount: operation.ordererAccountNumber,
			type: PaymentTypes.internationalPayment,
			reference: operation.reference,
			status: operation.status,
		};

		const signableOperation = new SignableOperation<PaymentOperation<InternationalPaymentDetails>>(operation);
		signableOperation.status = operation.amount >= 10 ? Status.ToSign : Status.ToConfirm;
		signableOperation.signatureTypesAllowed = [SignatureModes.PASSWORD];
		return of(new ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>>>(signableOperation)).pipe(delay(100));

	}

	public signDelete(signablePaymentOperation: SignableOperation<PaymentOperation<InternationalPaymentDetails>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>> | null>> {

		this.pendingPayments.splice(this.pendingPayments.findIndex((pendingPayment: PendingPayment) => pendingPayment.reference === signablePaymentOperation.model.reference), 1);
		signablePaymentOperation.status = Status.Closed;
		return of(new ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>>>(signablePaymentOperation));

	}

	public confirmDelete(signablePaymentOperation: SignableOperation<any>): Observable<ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>> | null>> {

		if (signablePaymentOperation.status === Status.ToConfirm) {
			// Actually remove the payment from the list of mock pending payments
			this.pendingPayments.filter(payment => payment.reference === signablePaymentOperation.model.reference).pop();
			signablePaymentOperation.model = null;
			signablePaymentOperation.status = Status.Closed;
		} else {
			signablePaymentOperation.status = Status.InShoppingBasket;
		}

		return of(new ServiceResponse<SignableOperation<PaymentOperation<InternationalPaymentDetails>>>(signablePaymentOperation)).pipe(delay(this.DEFAULT_DELAY));

	}
}
