import { Injectable } from '@angular/core';
import { NaturalPerson } from '../../model/natural-person';
import { ArtificialPerson } from '../../model/artificial-person';
import { PersonMandate } from '../../model/person-mandate';
import { ServiceResponse } from '../../../utils/index';
import { Observable } from 'rxjs';

@Injectable()
export abstract class PersonsService {

	/**
	 * Returns data (name, etc.) about the person behind the connected user.
	 * @returns {Observable<ServiceResponse<NaturalPerson | ArtificialPerson>>}
	 */
	abstract get(): Observable<ServiceResponse<NaturalPerson | ArtificialPerson>>;

	/**
	 * Returns the list of persons the user has a mandate on.
	 * @returns {Observable<ServiceResponse<PersonMandate[]>>}
	 */
	abstract getMandates(): Observable<ServiceResponse<PersonMandate[]>>;

	/**
	 * Returns the represented client number
	 * @returns {Observable<string>}
	 */
	abstract getRepresentedClientNumber(): Observable<string>;

}
