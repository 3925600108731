import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonDataSource } from '@mdib/generic';
import { AccountValuation, AccountValuationsPageFilter, AccountValuationsService } from '@mdib/account-valuations';
import { GenericListPage } from '@mdib/generic';
import { ServiceResponseNotificationMessagesMapperService, NotificationMessageService } from '@mdib/notification-message';
import { IconButton } from '@mdib/styling';
import { MdibFile, ServiceResponse, UtilsHelper } from '@mdib/utils';
import { saveAs } from 'file-saver/FileSaver';
import * as moment from 'moment';
import { CashAccount } from '@mdib/cash-accounts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'account-valuations-page',
	templateUrl: './account-valuations-page.component.html',
	styleUrls: ['./account-valuations-page.component.scss']
})

export class AccountValuationsPageComponent extends GenericListPage implements OnInit {

	public filter = <AccountValuationsPageFilter>{
		accountNumber: '',
		valuationYear: new Date().getFullYear(),
		cashAccount: null
	};

	readonly NAMESPACE = 'accountValuationsPagesModule.accountValuationsPageComponent.';
	public displayedColumns = ['accountNumber', 'valuationDate', 'totalInterest', 'actions'];
	public currentYear = (new Date()).getFullYear();
	protected iconArray: Array<IconButton> = [{ iconName: 'file_download', iconLabel: 'download' }];
	protected accountNumber: string;
	protected selectedAccountValuation: AccountValuation;
	protected serviceResponseNotifMapper: ServiceResponseNotificationMessagesMapperService;

	constructor(
		private route: ActivatedRoute,
		private accountValuationsService: AccountValuationsService,
		private notificationMessageService: NotificationMessageService,
		injector: Injector
	) {
		super(injector);

		this.dataSourceType = CommonDataSource;
		this.listConfigurations.listTitle = this.NAMESPACE + 'accountValuationsTitle';
		this.listConfigurations.selectorTitle = this.NAMESPACE + 'accountLabel';
		this.listConfigurations.listEmptyMessage = this.NAMESPACE + 'listEmptyMessage';
		this.listConfigurations.filterOpenedByDefault = true;
		this.listConfigurations.showFilterHeader = false;
		this.listConfigurations.showSelector = false;
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.loadSearchModel(params, this.filter);
		});
		this.applyFilter();
	}

	clearFilter(): void {
		this.filter = {
			accountNumber: this.filter.accountNumber,
			valuationYear: this.currentYear,
			cashAccount: null
		};
	}

	consultRow(row: any): void {
		// Nothing to do
	}

	/**
	 * This method is called to download an account valuation file
	 * @param {accountValuation}
	 */
	downloadFile(accountValuation: AccountValuation): void {
		this.notificationMessageService.clearAll();
		this.accountValuationsService.downloadAccountValuationFile(accountValuation)
			.subscribe((serviceResponse: ServiceResponse<MdibFile>) => {
				const file = serviceResponse.getModel();
				if (!file || !file.document) {
					this.serviceResponseNotifMapper.sendResponseFeedbacks(new ServiceResponse('No data file to download for this statement'));
				} else {
					const fileName = 'Renteafrekening_' + accountValuation.accountNumber + '_' + moment(accountValuation.valuationDate).format('YYYY-MM-DD') + '.pdf';
					saveAs(file.document, fileName);
				}
		},
		(serviceResponseError: ServiceResponse<null>) => {
			this.serviceResponseNotifMapper.sendResponseFeedbacks(serviceResponseError, this.NAMESPACE);
		});
	}

	public fetchData() {
		if (UtilsHelper.objectNotEmpty(this.filter) && this.filter.cashAccount && this.filter.cashAccount.number) {
			super.fetchData();
		}
	}

	onCashAccountChanged(account: CashAccount) {
		this.clearFilter();
		this.filter.cashAccount = (account != null) ? account : null;
		this.applyFilter();
	}

	onClearFilter() {
		this.clearFilter();
		this.applyFilter();
	}


	protected load(index?: number, count?: number): Observable<ServiceResponse<any>> {
		return this.accountValuationsService.list(index, count, this.filter);
	}
}
