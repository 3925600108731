import { Injectable } from '@angular/core';
import { ModifyAccountNameOperation } from '@mdib/cash-accounts';
import { XclModifyAccountNameOperationModel } from '../model/xcl-modify-account-name-operation-model';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';

@Injectable()
export class ModifyAccountNameOperationMapperXclService {

	public functionalToXcl(modifyAccountNameOperation: ModifyAccountNameOperation): XclModifyAccountNameOperationModel {
		return <XclModifyAccountNameOperationModel>{
			accountWording: modifyAccountNameOperation.accountWording,
			accountType: modifyAccountNameOperation.accountType,
			accountNumber: modifyAccountNameOperation.accountNumber,
			principalIdentification: modifyAccountNameOperation.principalIdentification,
		};
	}

	public xclToFunctional(technicalModel: MonogoalOrderXCLModel<XclModifyAccountNameOperationModel>): ModifyAccountNameOperation {
		return <ModifyAccountNameOperation>{
			accountWording: technicalModel.goal.accountWording,
			accountType: technicalModel.goal.accountType,
			accountNumber: technicalModel.goal.accountNumber,
			reference: technicalModel.reference,
			principalIdentification: technicalModel.goal.principalIdentification,
		};
	}
}
