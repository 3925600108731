import { Component, DoCheck, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from '@mdib/config';
import { UtilsHelper } from '@mdib/utils';


@Component({
	selector: 'structured-communication',
	templateUrl: './structured-communication.component.html',
	styleUrls: ['./structured-communication.component.scss'],
})
export class StructuredCommunicationComponent implements OnChanges, DoCheck {

	@Input() control: AbstractControl;
	focusFirst = true;
	focusSecond = false;
	focusThird = false;
	private form: FormGroup;

	constructor(
		private fb: FormBuilder,
		private configService: ConfigService,
	) {
		this.createForm();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.splitCommunication();
		if (this.control.disabled) {
			this.group.disable();
		}
		this.subscriptions();
	}

	ngDoCheck() {
		this.splitCommunication();
		if (this.control.enabled) {
			this.group.enable({emitEvent: false});
		}
	}

	private createForm() {
		this.form = this.fb.group({
			'firstPart': ['', Validators.maxLength(3)],
			'secondPart': ['', Validators.maxLength(4)],
			'thirdPart': ['', Validators.maxLength(5)],
		});
	}

	private splitCommunication(): void {
		const cleaned = UtilsHelper.alphaToNumber(this.control.value);
		if (!!cleaned) {
			this.firstPart.setValue(cleaned.substring(0, 3), {onlySelf: true});
			this.secondPart.setValue(cleaned.substring(3, 7), {onlySelf: true});
			this.thirdPart.setValue(cleaned.substring(7, 12), {onlySelf: true});
		}
	}

	private subscriptions() {
		this.firstPart.valueChanges.subscribe(firstPart => {
			if (this.firstPart.value.length === 3) {
				this.focusSecond = true;
			}
			this.firstPart.setValue(UtilsHelper.alphaToNumber(firstPart), {emitEvent: false});
		});
		this.secondPart.valueChanges.subscribe(secondPart => {
			if (this.secondPart.value.length === 4) {
				this.focusSecond = false;
				this.focusThird = true;
			}
			this.secondPart.setValue(UtilsHelper.alphaToNumber(secondPart), {emitEvent: false});
		});
		this.thirdPart.valueChanges.subscribe(thirdPart => {
			this.thirdPart.setValue(UtilsHelper.alphaToNumber(thirdPart), {emitEvent: false});
		});
		this.group.valueChanges.subscribe(() => {
			this.control.setValue(this.value);
		});

	}

	get communicationMaxLength(): number {
		return this.configService.structuredCommunicationMaxLength;
	}

	get value(): string {
		return this.firstPart.value + this.secondPart.value + this.thirdPart.value;
	}

	get group(): AbstractControl {
		return this.form;
	}

	get firstPart(): AbstractControl {
		return this.form.get('firstPart');
	}

	get secondPart(): AbstractControl {
		return this.form.get('secondPart');
	}

	get thirdPart(): AbstractControl {
		return this.form.get('thirdPart');
	}
}
