import { Observable } from 'rxjs';

import { BackendOperationStep } from '@mdib/core/adapters';

import { BackendOperation } from './backend-operation';

export class BackendOperationStub extends BackendOperation<any, any> {

	public execute(input: any, trigger?: string): Observable<BackendOperationStep<any>> {
		return new Observable();
	}
}
