import { NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { BeneficiariesServiceV2 } from '@mdib/beneficiaries';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';

import { BeneficiariesMemoryService as BeneficiariesMemoryServiceV2 } from './services/beneficiaries-memory.service';

@NgModule()
export class BeneficiariesMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(BeneficiariesServiceV2, adapterId, BeneficiariesMemoryServiceV2);
	}
}
