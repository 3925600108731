import { Injectable } from '@angular/core';
import { ElectronicalStatementsCommonService, ElectronicalStatementsPageFilter, ElectronicalStatement } from '@mdib/electronical-statements';
import { ServiceResponse } from '@mdib/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ElectronicalStatementsXclService extends ElectronicalStatementsCommonService {
	public list(offset?: number, limit?: number, filter?: ElectronicalStatementsPageFilter): Observable<ServiceResponse<ElectronicalStatement[]>> {
		throw new Error('Method not implemented.');
	}
	public count(filter?: ElectronicalStatementsPageFilter): Observable<ServiceResponse<number>> {
		throw new Error('Method not implemented.');
	}
	public get(id: string): Observable<ServiceResponse<ElectronicalStatement>> {
		throw new Error('Method not implemented.');
	}
	public update(electronicalStatement: ElectronicalStatement): Observable<ServiceResponse<ElectronicalStatement>> {
		throw new Error('Method not implemented.');
	}
	public downloadStatement(electronicalStatement: ElectronicalStatement): Observable<ServiceResponse<any>> {
		throw new Error('Method not implemented.');
	}
}
