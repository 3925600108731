import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceResponse } from '@mdib/utils';

import { MailboxConversationSummary } from '../model/mailbox-conversation-summary';
import { MailboxConversation } from '../model/mailbox-conversation';
import { MailboxMessage } from '../model/mailbox-message';

@Injectable()
export abstract class MailboxMessagesService {

	/**
	 * Retrieve all the conversations of the mailbox
	 *
	 * @returns {Observable} A Service Response with the list of conversations
	 */
	public abstract getConversations(): Observable<ServiceResponse<MailboxConversationSummary[]>>;

	/**
	 * Retrieve a conversation with all its details and all the messages it contains
	 *
	 * @param conversationIdentifier
	 * @returns {Observable<ServiceResponse<MailboxConversation>>}
	 */
	public abstract getConversationDetails(conversationIdentifier: string): Observable<ServiceResponse<MailboxConversation>>;

	/**
	 * Trigger the reload of the conversations. The new conversations list will be emitted by the conversations observable (cf. getConversations()).
	 */
	public abstract reloadConversations(): void;
	/**
	 * Retrieve the number of unread messages of the mailbox
	 *
	 * @returns {Observable} A Service Response with the number of unread messages
	 */
	public abstract countUnreadMessages(): Observable<ServiceResponse<number>>;

	/**
	 * Retrieve a conversation of the mailbox with all its messages
	 *
	 * @returns {Observable} A Service Response with the conversation
	 */
	public abstract reloadConversation(conversationIdentifier: string): void;

	/**
	 * Send a message to a beneficiary
	 *
	 * @param message The message to send
	 * @returns {Observable} A Service Response with the sent message
	 */
	public abstract addMessage(message: MailboxMessage): Observable<ServiceResponse<MailboxMessage>>;

	/**
	 * Delete multiple conversations from the mailbox.
	 *
	 * @param conversationIdentifiers An array of unique identifiers of the conversations to delete
	 * @param reloadConversationsUponCompletion Indicates whether the list of conversations should be reloaded upon completion or not (true by default)
	 * @returns {Observable} A Service Response which will only emit errors if any
	 */
	public abstract deleteConversations(conversationIdentifiers: string[], reloadConversationsUponCompletion?: boolean): Observable<ServiceResponse<null>>;

	/**
	 * Delete a conversation from the mailbox.
	 *
	 * @param conversationIdentifier Unique identifier of the conversation to delete
	 * @param reloadConversationsUponCompletion Indicates whether the list of conversations should be reloaded upon completion or not (true by default)
	 * @returns {Observable} A Service Response which will only emit errors if any
	 */
	public abstract deleteConversation(conversationIdentifier: string, reloadConversationsUponCompletion?: boolean): Observable<ServiceResponse<null>>;

	/**
	 * Mark mailbox conversations as read or unread (depending on the input switch).
	 *
	 * @param {string[]} conversationIdentifiers Unique identifiers of the conversations to mark as read/unread
	 * @param {boolean} isRead Boolean to indicate whether the conversations are read or not
	 * @returns {Observable<null>}
	 */
	public abstract markConversationsAsReadOrUnread(conversationIdentifiers: string[], isRead: boolean): Observable<ServiceResponse<null>>;

	/**
	 * Mark a mailbox conversation as read or unread (depending on the input switch).
	 *
	 * @param {string[]} conversationIdentifier Unique identifier of the conversation to mark as read/unread
	 * @param {boolean} isRead Boolean to indicate whether the conversation is read or not
	 * @returns {Observable<null>}
	 */
	public abstract markConversationAsReadOrUnread(conversationIdentifier: string, isRead: boolean): Observable<ServiceResponse<null>>;

	/**
	 * Retrieve an observable which emits each time the number of unread messages changed and each x sec.
	 *
	 * @returns {Observable} A Service Response with the conversation
	 */
	public abstract unreadMessagesCounter(): Observable<ServiceResponse<number>>;
}
