import { Injectable } from '@angular/core';
import { XclAccountStatementHelper } from '../../account-statements/helper/xcl-account-statement-helper';
import { FileMapperXclService, XclDateFormatter } from '@mdib-xcl/utils';
import { PdfStatementFile } from '@mdib/utils/model/file-typings';
import { PdfStatementXcl, PdfStatementSummaryXcl } from '@mdib-xcl/pdf-statements/model/pdf-statement-xcl';
import { PdfStatement, PdfStatementSummary } from '@mdib/pdf-statements/model/pdf-statement';

@Injectable()
export class PdfStatementsMapperXclService {

	constructor(private fileService: FileMapperXclService,
	) {
	}

	/**
	 * Parse technical model in functional model
	 * @param pdfStatementXcl : source format.
	 * @returns AccountStatement : parsed functional model format.
	 */
	public pdfStatementXclToAccountStatement(pdfStatementXcl: PdfStatementXcl): PdfStatement {
		const accountStatementModel = <PdfStatement>{
			identifier: pdfStatementXcl.identifier,
			accountNumber: pdfStatementXcl.accountNumber,
			sequenceNumber: pdfStatementXcl.pdfSequenceNumber,
			lastSortFieldValue: pdfStatementXcl.lastSortFieldValue,
			lastStatementSwitch: pdfStatementXcl.lastStatementSwitch,
			firstStatementDate: XclDateFormatter.stringToDate(pdfStatementXcl.startDate),
			emptyFileSwitch: pdfStatementXcl.emptyFileSwitch,
			pdfSequenceNumber: pdfStatementXcl.pdfSequenceNumber.toString(),
			previousPDFsequenceNumber: pdfStatementXcl.previousPDFsequenceNumber,
			pdfStartDate: XclDateFormatter.stringToDate(pdfStatementXcl.pdfStartDate),
			endDate: XclDateFormatter.stringToDate(pdfStatementXcl.endDate),
			lastStatementDate: XclDateFormatter.stringToDate(pdfStatementXcl.lastGeneratedStatementDate),
			file: pdfStatementXcl.file,
		};
		return accountStatementModel;
	}


	public PdfStatementSummaryXclToAccountStatementSummary(pdfStatementSummaryXcl: PdfStatementSummaryXcl): PdfStatementSummary {
		const accountStatementSummaryModel = <PdfStatementSummary>{
			accountNumber: pdfStatementSummaryXcl.accountNum,
			firstStatementDate: XclDateFormatter.stringToDate(pdfStatementSummaryXcl.firstStatementDate),
			lastStatementDate: XclDateFormatter.stringToDate(pdfStatementSummaryXcl.lastStatementDate)
		};
		return accountStatementSummaryModel;
	}


	/**
	 * Converts the technical model list into functional model list
	 * AccountStatement[] to XclAccountStatementModel[]
	 * @param PdfStatementXcl[] : source format list.
	 * @returns AccountStatement[] : functional model format list.
	 */
	public PdfStatementSummaryXclToAccountStatementSummaryArray(pdfStatementSummaryXcl: PdfStatementSummaryXcl[]): PdfStatementSummary[] {
		if (!pdfStatementSummaryXcl) {
			console.log('ERROR: mapping issue from PdfStatementXcl[] to AccountStatement[]');
			return null;
		}

		const pdfStatementSummaryArray: PdfStatementSummary[] = new Array<PdfStatementSummary>();
		for (const summary of pdfStatementSummaryXcl) {
			const pdfStatementSummary: PdfStatementSummary = this.PdfStatementSummaryXclToAccountStatementSummary(summary);
			pdfStatementSummaryArray.push(pdfStatementSummary);
		}
		return pdfStatementSummaryArray;
	}
}
