import { Directive, HostListener, ElementRef } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Directive({
	selector: '[mdibAccountNameMask]'
})
export class AccountNameMaskDirective {

	constructor(private el: ElementRef) { }

	/**
	 * Disable entering more than one consecutive space in the account name
	 */
	@HostListener('input')
	onEvent() {
		if (!isNullOrUndefined(this.el.nativeElement.value)) {
			this.el.nativeElement.value = this.el.nativeElement.value.replace(/\s\s+/g, ' ');
		}
	}

	/**
	 * Trim account name when focus is removed from the field
	 */
	@HostListener('blur')
	onBlur() {
		if (!isNullOrUndefined(this.el.nativeElement.value)) {
			this.el.nativeElement.value = this.el.nativeElement.value.trim();
		}
	}

}
