import { OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormGroupBuilder } from '@mdib/commons';
import { UtilsHelper } from '@mdib/utils';

export abstract class FormComponent<T> implements OnInit, OnChanges {
	@Input()
	public model: T;

	@Input()
	public readonly = false;

	public formGroup: FormGroup;

	@Output()
	protected modelChange: EventEmitter<T> = new EventEmitter();

	@Output()
	protected formGroupChange: EventEmitter<FormGroup> = new EventEmitter();

	constructor(
		protected modelClass: new (properties) => T,
		protected formGroupBuilder: FormGroupBuilder,
	) { }

	public ngOnInit(): void {
		this.createForm();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (!this.formGroup) { return; }

		if (changes.model) {
			// Special case is the null/empty object which means doing a reset
			if (!this.model || !Object.keys(this.model).length) {
				this.createForm();
			} else {
				// Avoid patching if it's already the same
				const dummy = this.formGroupBuilder.build();
				dummy.patchValue(this.model);
				if (!UtilsHelper.objectsEqual(this.formGroup.value, dummy.value)) {
					this.formGroup.patchValue(this.model);
				}
			}
		}
		if (changes.readonly) {
			this.readonly ? this.formGroup.disable() : this.formGroup.enable();
		}
	}

	public createForm() {
		// Create a new empty form and fill it with the curent model
		this.formGroup = this.formGroupBuilder.build();
		this.formGroup.patchValue(this.model || {}, { emitEvent: false });
		this.readonly ? this.formGroup.disable() : this.formGroup.enable();
		this.formGroupChange.emit(this.formGroup);
		// Watch the changes in the form to update the model
		this.formGroup.valueChanges.subscribe(value => this.modelChange.emit(new this.modelClass(value)));
	}
}
