import { NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { StandingOrdersService } from '@mdib/standing-orders';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';
import { StandingOrdersMemoryService } from './service/standing-orders-memory.service';

@NgModule()
export class StandingOrdersMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(StandingOrdersService, adapterId, StandingOrdersMemoryService);
	}
}
