import { OperationPlaybarButton } from '@mdib/core/adapters';

export const saveButton: OperationPlaybarButton = {
	trigger: 'save',
	icon: '',
};

export const cancelButton: OperationPlaybarButton = {
	trigger: 'cancel',
	type: 'secondary',
};

export const startButton: OperationPlaybarButton = {
	trigger: 'start',
	hidden: true,
};

export const CUSTOM_BUTTONS = [
	{ provide: OperationPlaybarButton, useValue: saveButton, multi: true },
	{ provide: OperationPlaybarButton, useValue: cancelButton, multi: true },
	{ provide: OperationPlaybarButton, useValue: startButton, multi: true },
];
