import { Observable } from 'rxjs';

import { SequenceCache } from '@mdib/core/meta';

import { BackendSearchService } from '../types/backends/backend-service';
import { PaginatedSearchParams } from '../types/pages/paginated-search-params';
import { SearchResult } from '../types/search/search-result';
import { SearchCriteria } from '../types/search/search-criteria';

export abstract class SequenceCacheStrategyService {

	abstract fill<T>(
		cache: SequenceCache<T>,
		backendService: BackendSearchService<T>,
		searchPagination: PaginatedSearchParams,
		searchCriteria: SearchCriteria<any>,
	): Observable<SearchResult<T>>;

}
