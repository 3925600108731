import { Injectable } from '@angular/core';
import { MockAuthenticationStatisticData } from '../mock/mock-authentication-statistic-data';
import { AuthenticationStatisticData } from '@mdib/sessions/model/authentication-statistic-data';
import { AuthenticationDataService } from '@mdib/sessions/service/authentication-data/authentication-data.service';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationDataMemoryService extends AuthenticationDataService {

	getStatistics(): Observable<ServiceResponse<AuthenticationStatisticData[]>> {
		return of(new ServiceResponse<AuthenticationStatisticData[]>(MockAuthenticationStatisticData.getMockAuthenticationStatisticDataList()));
	}

}
