import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { PdfStatementsPageFilter } from '@mdib/pdf-statements/model/pdf-statement-page-filter';
import { PdfStatementStatus } from '@mdib/pdf-statements/model/pdf-statement-status.enum';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { DateValidator, CommonValidator } from '@mdib/utils';
import { isNullOrUndefined } from 'util';
import { CashAccount } from '@mdib/cash-accounts';


@Component({
	selector: 'pdf-statements-page-filter',
	templateUrl: './pdf-statements-page-filter.component.html',
	styleUrls: ['./pdf-statements-page-filter.component.scss'],
})

export class PdfStatementsPageFilterComponent implements OnInit, OnChanges {

	readonly NAMESPACE = 'accountStatementsPagesModule.pdfStatementsPageFilterComponent.';

	@Input() filter: PdfStatementsPageFilter;
	@Output() filterChanged = new EventEmitter<any>();

	public PdfStatementStatus = PdfStatementStatus;
	public form: FormGroup;

	constructor(private fb: FormBuilder) {	}

	ngOnInit() {
		this.form = this.fb.group({
			cashAccount: [null],
			status: [this.filter.status],
			startDate: [null, [DateValidator.dateNotAfterToday]],
			endDate: [null, [DateValidator.dateNotAfterToday]],
		});
		this.initSubscription();
	}

	ngOnChanges() {
		if (!isNullOrUndefined(this.form)) {
			this.form.reset({
					status: PdfStatementStatus.New,
				},
			);
		}
	}

	initSubscription() {
		this.status.valueChanges.subscribe(status => {
			this.filter.status = status;
			if (this.filter.status === PdfStatementStatus.New) {
				this.filter.cashAccount = new CashAccount();
			}
			this.filterChanged.emit();
		});
		this.startDate.valueChanges.subscribe(startDate => this.filter.startDate = startDate);
		this.endDate.valueChanges.subscribe(endDate => this.filter.endDate = endDate);
	}

	/**
	 * This method returns the maximum AccountingFromDate based on the to date filter if filled, otherwise it returns the current date.
	 * @returns {Date} maxDate
	 */
	get maxStartDate(): Date {
		if (this.endDate.value) {
			return 	this.endDate.value;
		} else {
			return this.currentDate;
		}
	}

	/**
	 * This method returns the minimum  AccountingToDate based on the to date filter if filled, otherwise it returns the minimum date value possible.
	 * @returns {Date} maxDate
	 */
	get minEndDate(): Date {
		if (this.startDate.value) {
			return 	this.startDate.value;
		} else {
			return new Date(-8640000000000000);
		}
	}

	get currentDate(): Date {
		return new Date();
	}

	get cashAccount(): AbstractControl {
		return this.form.get('cashAccount');
	}

	get status(): AbstractControl {
		return this.form.get('status');
	}

	get startDate(): AbstractControl {
		return this.form.get('startDate');
	}

	get endDate(): AbstractControl {
		return this.form.get('endDate');
	}

}
