import { NgModule, ModuleWithProviders } from '@angular/core';
import { PdfStatementsService } from '@mdib/pdf-statements/service/pdf-statements.service';
import { PdfStatementsServiceProxy } from '@mdib/pdf-statements/service/pdf-statements.service.proxy';


@NgModule()
export class PdfStatementsModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: PdfStatementsModule,
			providers: [
				{ provide: PdfStatementsService, useClass: PdfStatementsServiceProxy },
			]
		};
	}
}
