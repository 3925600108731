import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { AccountValuationsService } from './account-valuations.service';
import { AccountValuationsServiceStub } from './account-valuations.service.stub';

@Injectable()
export class AccountValuationsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return AccountValuationsService; }
	protected ServiceDefinition() { return AccountValuationsServiceStub; }
}
