import { AccountStatementStatus } from '@mdib/account-statements';
import { MdibFile } from '@mdib/utils';
import { ElectronicalStatementsFormat } from './electronical-statements-format.enum';

export class ElectronicalStatement {
	identifier: string;
	accountNumber: string;
	sequenceNumber: string;
	firstStatementDate: Date;
	lastStatementDate: Date;
	statementNumber: number;
	statementNumberYear: number;
	status: AccountStatementStatus;
	format: ElectronicalStatementsFormat;
	generationDate: Date;
	file: MdibFile;
}
