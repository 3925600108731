import { Injectable } from '@angular/core';
import { AdapterProxy } from '@mdib/core/adapters';
import { InstantPaymentsService } from './instant-payments.service';
import { InstantPaymentsServiceStub } from './instant-payments.service.stub';

@Injectable()
export class InstantPaymentsServiceProxy extends AdapterProxy {
	protected ServiceToken() {
		return InstantPaymentsService;
	}

	protected ServiceDefinition() {
		return InstantPaymentsServiceStub;
	}
}
