import { Observable, of } from 'rxjs';

import { LiveTourStep } from './live-tour-step';
import { LiveTourService } from '../live-tour.service';

export class TargetElementStep extends LiveTourStep {

	constructor(
		private fieldSelector?: string
	) {
		super();
	}

	public execute(service: LiveTourService, liveTourEvents: Observable<Event>): Observable<Event> {
		const input = document.querySelector(this.fieldSelector) as HTMLInputElement;
		service.getTourComponent().targetElement(input);
		return of();
	}
}
