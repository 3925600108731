import { CashAccount } from '@mdib/cash-accounts';
import { FileFormat } from '@mdib/utils';
import { AccountStatementStatus } from './account-statement-status.enum';
import { AccountStatement } from './account-statement';

export class AccountStatementsPageFilter {
	// all the properties on which you can filter your list.
	cashAccount: CashAccount;
	fileFormat?: FileFormat;
	representedClientNumber?: string;
	status: AccountStatementStatus;
	accountingDateFrom: Date;
	accountingDateTo: Date;
	statementNumberFrom: number;
	statementNumberTo: number;
	statementYear: number;

	constructor (account?: Partial<AccountStatement>) {
		Object.assign(this, account);
	}
}
