import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ManagedCurrencyCodes } from '@mdib/config';
import { DataFormattingService } from '../service/data-formatting.service';

@Directive({
	selector: '[amountMask]',
})
export class AmountMaskDirective {
	@Input() currency?: ManagedCurrencyCodes;
	isNumber = /^\d*[.,]?\d*$/;
	amountFormatted = '';
	maxAmountValue = 99999999999999.99;

	constructor(private ref: ElementRef, private control: NgControl, private dataFormat: DataFormattingService) {
	}

	/**
	 *  Formats the amount correctly during the input
	 */
	@HostListener('input')
	onInput() {
		if (this.isNumber.test(this.amount) && this.dataFormat.parseAmount(this.amount) <= this.maxAmountValue) {
			this.amount = this.dataFormat.applyDecimalMark(this.amount);
		} else {
			this.amount = !this.amount && this.amount !== '0' ? '' : this.amountFormatted;
		}
	}

	/**
	 * Formats the amount correctly when I click outside of the input field
	 */
	@HostListener('blur')
	onBlur() {
		this.amount = this.dataFormat.amountToViewFormat(this.amount, this.currency);
	}

	get amount(): string {
		return this.ref.nativeElement.value;
	}

	set amount(amount: string) {
		this.amountFormatted = amount;
		this.ref.nativeElement.value = amount;
		this.control.control.setValue(this.amount);
	}
}
