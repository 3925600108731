import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FormGroupBuilder } from './form-group.builder';

@Injectable({
	providedIn: 'root',
})
export class AddressFormBuilder implements FormGroupBuilder {

	constructor(
		private formBuilder: FormBuilder,
	) { }

	build(): FormGroup {
		return this.formBuilder.group({
			addressLine1: [],
			addressLine2: [],
			country: [],
		});
	}
}
