import { Component, OnInit } from '@angular/core';

import { SignatureProcessComponent } from '@mdib/signature';
import { SvgIconPath } from '@mdib/styling';
import { ServiceResponse, Feedback, CommonValidator } from '@mdib/utils';

import { DigipassSignatureService } from '../../service/digipass-signature.service';
import { DigipassSignature } from '../../model/digipass-signature';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';

@Component({
	selector: 'mdib-digipass-signature-process',
	templateUrl: './digipass-signature-process.component.html',
	styleUrls: ['./digipass-signature-process.component.scss']
})
export class DigipassSignatureProcessComponent extends SignatureProcessComponent implements OnInit {

	readonly NAMESPACE = 'signatureModule.digipassSignatureProcessComponent.';

	/**
	 * @property Hols the curent signature state
	 */
	public signature: DigipassSignature = null;

	/**
	 * @property Indicates if the curent signature process is valid
	 */
	public failed = false;

	public digipassIconPath = SvgIconPath.DIGIPASS;

	/**
	 * @property Indicates if the signature is ready
	 */
	preparingSignature = true;

	/**
	 * @property Form containing the token input by the user
	 */
	digipassSignatureFormGroup: FormGroup;

	/**
	 * Construct the component by injecting dependencies
	 *
	 * @param digipassSignatureService Service required to process the signature
	 * @param formBuilder Form builder to build the digipassSignatureFormGroup
	 */
	constructor(
		private _digipassSignatureService: DigipassSignatureService,
		private _formBuilder: FormBuilder
	) {
		super();
		this.digipassSignatureFormGroup = this._formBuilder.group({
			token: [null, [CommonValidator.required]]
		});
	}

	ngOnInit() {
		// Reset view
		this.token.setValue('');
		this.failed = false;

		// Prepare the signature
		this.digipassSignatureFormGroup.disable();
		this.preparingSignature = true;

		this._digipassSignatureService.prepareSignatureFor(this.signable).subscribe(
			(wrappedSignature: ServiceResponse<DigipassSignature>) => {
				this.signature = wrappedSignature.getModel();
				this.digipassSignatureFormGroup.enable();
				this.preparingSignature = false;
				this.emitEvents(wrappedSignature);
			},
			(wrappedError: ServiceResponse<null>) => {
				this.digipassSignatureFormGroup.enable();
				this.preparingSignature = false;
				this.failed = true;
				this.emitEvents(wrappedError);
			}, () => this.digipassSignatureFormGroup.enable()
		);
	}

	public formatInstructions(instructions: string): Array<string> {
		return instructions.split('<br/>');
	}

	/**
	 * Calls the PasswordProcessService to create a signature.
	 *
	 * @param password The password to use for the signature
	 */
	public sign() {
		this.failed = false;
		this.digipassSignatureFormGroup.disable();

		this._digipassSignatureService.createSignatureFor(this.signable, this.token.value).subscribe(
			(wrappedSignature: ServiceResponse<DigipassSignature>) => {
				this.signable.addSignature(this.signature);
				this.onSuccess.emit(this.signable);
				this.emitEvents(wrappedSignature);
			},
			(wrappedError: ServiceResponse<DigipassSignature>) => {
				this.failed = true;
				this.emitEvents(wrappedError);
				this.digipassSignatureFormGroup.enable();
			}
		);
	}

	/**
	 * Cancels the signature
	 */
	public cancel() {
		this.onEvent.emit(new Event('abort'));
	}

	private emitEvents(serviceResponse: ServiceResponse<any>) {
		serviceResponse.getFeedbacks().forEach(
			(feedback: Feedback) => {
				this.onEvent.emit(feedback);
			}
		);
	}

	get token(): AbstractControl {
		return this.digipassSignatureFormGroup.get('token');
	}

	/**
	 * Return the adequate loading text according to the ongoing action.
	 */
	get loadingText(): string {
		return this.NAMESPACE + (this.preparingSignature ? 'preparingSignature' : 'signing');
	}
}
