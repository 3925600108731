import { Injectable } from '@angular/core';
import { XclSignatureContext, XclSignatureModes } from '@mdib-xcl/core';
import { MonogoalOrderXCLModel } from '@mdib-xcl/http';
import { OrderStateHelper, XclDateFormatter } from '@mdib-xcl/utils';
import { convertBeneficiaryAddressFromXclToStreet, convertBeneficiaryTownFromXclToCity, convertOrdererIdentityFromXcl } from '@mdib-xcl/utils/helper/xcl-connector-helpers';
import { Beneficiary, BeneficiaryType } from '@mdib/beneficiaries';
import { CashAccount } from '@mdib/cash-accounts';
import { Communication, CommunicationTypes, Address } from '@mdib/commons';
import { InternationalPaymentDetails, PaymentOperation, PaymentTypes } from '@mdib/payments';
import { UtilsHelper } from '@mdib/utils';
import { InternationalPaymentXcl } from '../../model/international-payment-xcl';
import { OperationStatusHelper } from '../../helper/operation-status-helper';
import { isNullOrUndefined } from 'util';

@Injectable({
	providedIn: 'root',
})
export class InternationalPaymentsMapperXclService {
	public toXcl(payment: PaymentOperation<InternationalPaymentDetails>): InternationalPaymentXcl {
		const xclModel = <InternationalPaymentXcl>{
			beneficiaryAccountNumber: payment.counterParty.account.number,
			beneficiaryBankDescriptionType: 'A',
			costType: 'SHA',
			beneficiaryBankIdentification_1: payment.counterParty.account.bic ? payment.counterParty.account.bic.toUpperCase() : '',
			beneficiaryName: payment.counterParty.fullName,
			beneficiaryCountryCode: payment.counterParty.address.country,
			transferCurrency: payment.currency,
			transferAmount: payment.amount,
			processingDate: !!payment.paymentDate ? XclDateFormatter.convertDateToXCLDateFormat(payment.paymentDate) : XclDateFormatter.convertDateToXCLDateFormat(new Date()),
			ordererAccountNumber: payment.ordererAccount.number,
			paymentDetail: payment.counterParty.communication.value,
			beneficiaryIdentificationForBeneficiary: payment.counterParty.alias,
			saveBeneficiary: payment.saveBeneficiary,
			telegiroNewStyleSwitch: payment.type === PaymentTypes.urgentPayment ? 'true' : 'false'
		};

		if (payment.counterParty.address) {
			const address = payment.counterParty.address;
			if (address.addressLine1) {
				xclModel.beneficiaryAddress = address.addressLine1.padEnd(33, ' ');
			}
			if (address.addressLine2) {
				xclModel.beneficiaryAddress += address.addressLine2.padEnd(33, ' ');
			}
		}
		return xclModel;
	}

	public fromXcl(payment: PaymentOperation<InternationalPaymentDetails>, paymentXcl: MonogoalOrderXCLModel<InternationalPaymentXcl>): PaymentOperation<InternationalPaymentDetails> {
		payment.reference = paymentXcl.goal.operationReference;

		if (UtilsHelper.nullOrUndefined(payment.ordererAccount)) {
			payment.ordererAccount = new CashAccount();
			payment.ordererAccount.clientWording = paymentXcl.goal.ordererIdentity;
			payment.ordererAccount.number = paymentXcl.goal.ordererAccountNumber;
		}

		if (!UtilsHelper.nullOrUndefined(paymentXcl.goal.ordererIdentity)) {
			// This used to call the xclConnectorHelper.convertOrdererIdentityFromXCL function, but this returned a null.
			payment.ordererName = paymentXcl.goal.ordererIdentity;
		}

		payment.amount = paymentXcl.goal.transferAmount;
		payment.type = paymentXcl.goal.telegiroNewStyleSwitch === 'true' ? PaymentTypes.urgentPayment : PaymentTypes.internationalPayment;
		payment.currency = paymentXcl.goal.transferCurrency;
		payment.paymentDate = XclDateFormatter.stringToDate(paymentXcl.goal.maturityDate);
		payment.operationDate = XclDateFormatter.stringToDate(paymentXcl.goal.processingDate);
		payment.counterParty = new Beneficiary({
			communication: new Communication({
				value: paymentXcl.goal.paymentDetail,
				type: CommunicationTypes.free,
			}),
			account: new CashAccount({
				number: paymentXcl.goal.beneficiaryAccountNumber,
				bic: paymentXcl.goal.beneficiaryBankIdentification_1.toUpperCase(),
			}),
			address: new Address({
				country: paymentXcl.goal.beneficiaryCountryCode,
			}),
			fullName: paymentXcl.goal.beneficiaryName,
			alias: paymentXcl.goal.beneficiaryIdentificationForBeneficiary,
			type: BeneficiaryType.INTERNATIONAL,
		});


		if (!!paymentXcl.goal.beneficiaryAddress) {
			payment.counterParty.address.addressLine1 = paymentXcl.goal.beneficiaryAddress.substring(0, 33).trim();
			payment.counterParty.address.addressLine2 = paymentXcl.goal.beneficiaryAddress.substring(33, paymentXcl.goal.beneficiaryAddress.length).trim();
		}

		payment.saveBeneficiary = paymentXcl.goal.saveBeneficiary;
		payment.signatureTypesAllowed = paymentXcl.signatureTypesAllowed.map(type => XclSignatureModes.fromXclType(type));
		payment.signatureContext = new XclSignatureContext({ signableReference: paymentXcl.reference });
		payment.status = OrderStateHelper.getStatusFromXCLOrderStateCode(paymentXcl.state);
		payment.operationStatus = OperationStatusHelper.paymentStatusFromXcl(paymentXcl.goal.transferStatus);

		return payment;
	}

}
