import { PaymentFileTypes, PaymentFileStatus } from './payment-file.enum';

/**
 *  All the properties on which you can filter your list.
 */
export class PaymentFilesFilter {
	fileStatus?: PaymentFileStatus;
	type?: PaymentFileTypes;
	uploadDateFrom?: Date;
	uploadDateTo?: Date;
}
