import { LanguageCodes } from '@mdib/config';
import { ObjectBuilder } from '@mdib/utils';

import { ContactAddress } from './contact-address';
import { NaturalPersonGender, NaturalPersonMaritalStatus } from './person.enum';
import { NaturalPerson } from './natural-person';

export class NaturalPersonBuilder extends ObjectBuilder<NaturalPerson> {

	build(): NaturalPerson {
		return new NaturalPerson(
			this.properties.id,
			this.properties.name,
			this.properties.firstName,
			this.properties.lastName,
			this.properties.birthDate,
			this.properties.birthCity,
			this.properties.birthCountry,
			this.properties.legalAddress,
			this.properties.language,
			this.properties.maritalStatus,
			this.properties.nationality,
			this.properties.gender,
			this.properties.defaultRepresentedClientSwitch,
		);
	}

	setName(name: string): NaturalPersonBuilder {
		return this.set('name', name);
	}

	setFirstName(firstName: string): NaturalPersonBuilder {
		return this.set('firstName', firstName);
	}

	setLastName(lastName: string): NaturalPersonBuilder {
		return this.set('lastName', lastName);
	}

	setBirthDate(birthDate: Date): NaturalPersonBuilder {
		return this.set('birthDate', birthDate);
	}

	setBirthCity(birthCity: string): NaturalPersonBuilder {
		return this.set('birthCity', birthCity);
	}

	setBirthCountry(birthCountry: string): NaturalPersonBuilder {
		return this.set('birthCountry', birthCountry);
	}

	setLegalAddress(legalAddress: ContactAddress): NaturalPersonBuilder {
		return this.set('legalAddress', legalAddress);
	}

	setLanguage(language: LanguageCodes): NaturalPersonBuilder {
		return this.set('language', language);
	}

	setMaritalStatus(maritalStatus: NaturalPersonMaritalStatus): NaturalPersonBuilder {
		return this.set('maritalStatus', maritalStatus);
	}

	setGender(gender: NaturalPersonGender): NaturalPersonBuilder {
		return this.set('gender', gender);
	}

	setNationality(nationality: string): NaturalPersonBuilder {
		return this.set('nationality', nationality);
	}

	setDefaultRepresentedClientSwitch(defaultSwitch: boolean): NaturalPersonBuilder {
		return this.set('defaultRepresentedClientSwitch', defaultSwitch);
	}
}
