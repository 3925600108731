import { NgModule, Inject } from '@angular/core';

import { AdaptersService } from '@mdib/core/adapters';
import { AccountStatementsService } from '@mdib/account-statements';

import { ADAPTER_MEMORY_IDENTIFIER } from '@mdib-memory/core';

import { AccountStatementsMemoryService } from './service/account-statements-memory.service';

@NgModule()
export class AccountStatementsMemoryModule {
	constructor(adaptersService: AdaptersService, @Inject(ADAPTER_MEMORY_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(AccountStatementsService, adapterId, AccountStatementsMemoryService);
	}
}
