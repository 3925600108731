import { Injectable } from '@angular/core';
import { AuthenticationStatisticData } from '../../model/authentication-statistic-data';
import { AuthenticationDataService } from './authentication-data.service';
import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthenticationDataServiceStub extends AuthenticationDataService {

	getStatistics(): Observable<ServiceResponse<AuthenticationStatisticData[]>> {
		return of();
	}
}
