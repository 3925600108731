import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { XCL_ADAPTER_IDENTIFIER } from '@mdib-xcl/core';
import { AdaptersService } from '@mdib/core/adapters';
import { PaymentsService, StandingOrderService, InternationalPaymentsService, InstantPaymentsService } from '@mdib/payments';

import { InternationalPaymentsXclService } from './service/international/international-payments-xcl.service';
import { PaymentsOperationMapperXclService } from './service/payments-operation-mapper-xcl.service';
import { PaymentsXclService } from './service/payments-xcl.service';
import { PendingPaymentsMapperXclService } from './service/pending-payments-mapper-xcl.service';
import { SepaPaymentXclService } from './service/sepa-payment-xcl.service';
import { StandingOrderMapperXclService } from './service/standing-orders/standing-order-mapper-xcl.service';
import { StandingOrderXclService } from './service/standing-orders/standing-order-xcl.service';
import { InstantPaymentsXclService } from './service/instant-payments/instant-payments-xcl.service';

@NgModule()
export class XclPaymentsModule {

	constructor(adaptersService: AdaptersService, @Inject(XCL_ADAPTER_IDENTIFIER) adapterId: string) {
		adaptersService.setAdapter(PaymentsService, adapterId, PaymentsXclService);
		adaptersService.setAdapter(StandingOrderService, adapterId, StandingOrderXclService);
		adaptersService.setAdapter(InternationalPaymentsService, adapterId, InternationalPaymentsXclService);
		adaptersService.setAdapter(InstantPaymentsService, adapterId, InstantPaymentsXclService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: XclPaymentsModule,
			providers: [
				SepaPaymentXclService,
				StandingOrderMapperXclService,
				InternationalPaymentsXclService,
				PaymentsOperationMapperXclService,
				PendingPaymentsMapperXclService,
				InstantPaymentsXclService,
			],
		};
	}
}
