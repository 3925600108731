import { Injectable } from '@angular/core';
import { BankConfig, ManagedCurrencyCodes } from '@mdib/config';
import { AccountTypesIcons } from '@mdib/styling';
import { CashAccount } from '../model/cash-account';
import { CashAccountService } from './cash-account.service';

@Injectable()
export abstract class CashAccountCommonService extends CashAccountService {

	// TODO Temp structure to be removed when the icon types param table is created
	iconMap = new Map<string, string>();

	public getIconByCashAccountType(cashAccountType: string): string {
		this.iconMap.set('current_account_ordinary', 'account_balance_wallet');
		this.iconMap.set('notice_account_30_days', 'pan_tool');
		this.iconMap.set('regulated_savings_account', 'account_balance');
		return !cashAccountType ? AccountTypesIcons['Unknown'] : this.iconMap.get(cashAccountType);
	}

	public getTotalAmount(cashAccounts: CashAccount[], baseCurrencyCode?: ManagedCurrencyCodes): number {
		let totalAmount = 0;
		baseCurrencyCode = !baseCurrencyCode ? BankConfig.baseCurrencyCode : baseCurrencyCode;
		cashAccounts.forEach(
			cashAccount => totalAmount += cashAccount.valuationCurrency === baseCurrencyCode ? cashAccount.currentBalance : 0,
		);
		return totalAmount;
	}
}
