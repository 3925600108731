import { ContractType } from '@mdib/sepa-direct-debits';

export class XclContractType {
	private static mapper = {
		'20': ContractType.SepaDirectDebitMainScheme,
		'21': ContractType.DirectDebitOrLoanRepayment,
		'23': ContractType.SepaDirectDebitB2BScheme,
	};

	/**
	 * Converts XCL contract type to functional contract type
	 * @param {string} [type] XCL contract type
	 * @returns {ContractType} contract type
	 * @memberof XclContractType
	 */
	public static fromXclType(type: string): ContractType {
		return XclContractType.mapper[type];
	}

	/**
	 * Converts functional contract type to XCL contract type
	 * @param {ContractType} [contractType] contract type
	 * @returns {string} XCL contract type value
	 * @memberof XclContractType
	 */
	public static toXclType(contractType: ContractType): string {
		return Object.getOwnPropertyNames(XclContractType.mapper).find(type => XclContractType.mapper[type] === contractType);
	}
}
