import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { HttpResponseModel } from '../model/http-response-model';
import { HttpStatusActionModel } from '../model/http-status-action-model';

@Injectable()
export class HttpStatusManagementService {

	private httpStatusSubscription: Subscription;
	private _errorActionStack: HttpStatusActionModel[] = [];

	public init(httpStatusBehavior: BehaviorSubject<HttpResponseModel>) {
		this.httpStatusSubscription = httpStatusBehavior.subscribe(
			httpResponse => {
				const stackLength = this._errorActionStack.length;
				for (let i = 0; i < stackLength; i++) {
					if (this._errorActionStack[i]) { this._errorActionStack[i].execute(httpResponse); }
				}
			}
		);
	}

	public createActionOnHttpStatus(action: any, httpStatus?: string, endpoint?: string): boolean {

		const errorAction: HttpStatusActionModel = new HttpStatusActionModel();

		errorAction.action = action;
		errorAction.httpStatus = httpStatus;
		errorAction.endpoint = endpoint;

		return this.setActionOnHttpStatus(errorAction);
	}

	public setActionOnHttpStatus(errorAction: HttpStatusActionModel): boolean {
		let pushed = false;
		if (!this.existInStack(errorAction)) {
			this._errorActionStack.push(errorAction);
			pushed = true;
		}
		return pushed;
	}

	private existInStack(errorAction: HttpStatusActionModel): boolean {
		let isInStack = false;

		if (errorAction instanceof HttpStatusActionModel) {
			const stackLength = this._errorActionStack.length;
			for (let i = 0; i < stackLength && !isInStack; i++) {
				isInStack = errorAction.equals(this._errorActionStack[i]);
			}
		} else {
			isInStack = true;
		}

		return isInStack;
	}

}



