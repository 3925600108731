
import { mergeMap, finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { XclSignatureContext } from '@mdib-xcl/core';
import { ShoppingBasketCommonService, ShoppingBasketEnvelope, ShoppingBasketOperation } from '@mdib/shopping-basket';
import { ParameterModel } from '@mdib/http';
import { MonogoalOrderXCLModel, AbstractOrderXCLModel, FunctionalFeedbacksFromXclOrderExtractor, XclHttpService } from '@mdib-xcl/http';
import { ServiceResponse, Feedback, FeedbackTypes, Status, UtilsHelper, ConfigurationService } from '@mdib/utils';
import { OrderStateHelper } from '@mdib-xcl/utils';
import { Observable, empty } from 'rxjs';
import { ShoppingBasketOperationMapperXclService } from './shopping-basket-operation-mapper-xcl.service';
import { SessionUtilsService } from '@mdib/sessions';

@Injectable({
	providedIn: 'root',
})
export class ShoppingBasketXclService extends ShoppingBasketCommonService {

	/**
	 * If no limit for retrieve is provided to the backend, it will set it to 20.
	 * If limit = 0, an error is raised.
	 * Since we want all of the orders in shopping basket if !limit, we use this constant as maxResults parameter.
	 */
	static readonly ARBITRARY_HIGH_LIMIT = 999;

	// Private boolean to indicate whether a reload is ongoing on the backend side (XCL), and with which parameters
	private backendReloadOngoing: { offset, limit } = null;

	constructor(private xclHttpService: XclHttpService,
		private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private mapper: ShoppingBasketOperationMapperXclService,
		sessionUtilsService: SessionUtilsService,
		configurationService: ConfigurationService

	) {
		super(sessionUtilsService, configurationService);
	}

	public reloadOperations(offset?: number, limit?: number): void {
		// Retrieve the operation upper stream on which to emit new values
		const upperStream = this.operationsStream;
		// Emit a "null" on the upper stream, to inform that a refresh of data is ongoing
		upperStream.next(null);

		const params: ParameterModel[] = [
			new ParameterModel('status', OrderStateHelper.getXCLOrderStateCodeFromStatus(Status.InShoppingBasket)),
		];
		if (UtilsHelper.nullOrUndefined(limit)) {
			limit = ShoppingBasketXclService.ARBITRARY_HIGH_LIMIT; // FIXME: THIS IS TEMPORARY, UNTIL THE BACKEND FIXES THE ISSUE. CURRENTLY, IF THIS PARAMETER IS NOT SPECIFIED, THE BACKEND RETURNS ONLY 20 RESULTS, WHICH IS QUITE SMALL.
		}
		if (!UtilsHelper.nullOrUndefined(offset)) {
			params.push(new ParameterModel('start', offset.toString()));
		}
		params.push(new ParameterModel('maxResult', limit.toString()));

		// If a reload is already ongoing on the XCL side, don't make a second reload in parallel.
		if (!!this.backendReloadOngoing
			&& this.backendReloadOngoing.offset === offset
			&& this.backendReloadOngoing.limit === limit
		) { return; }
		this.backendReloadOngoing = { offset: offset, limit: limit };
		this.xclHttpService.execute('shoppingbasket', XclHttpService.GET, params, null).pipe(
			finalize(() => {
				this.backendReloadOngoing = null;
			}),
			map((xclOrders: any) =>
				new ServiceResponse<ShoppingBasketOperation[]>(
					xclOrders.map((xclOrder: MonogoalOrderXCLModel<any>) => this.mapper.xclToFunctional(xclOrder)),
					xclOrders.reduce((mergedFeedbacks: Feedback[], xclOrder: MonogoalOrderXCLModel<any>) => mergedFeedbacks.concat(this.feedbackExtractor.extract(xclOrder)), []), // Aggregate the feedbacks into a merged array of feedbacks
				)))
			.subscribe(
				(response) => upperStream.next(response),
				(error) => upperStream.error(error),
				// On completion of the XCL stream, don't complete the upper stream !!);
			);
	}

	public retrieve(reference: string): Observable<ServiceResponse<ShoppingBasketOperation>> {
		const params: ParameterModel[] = [
			new ParameterModel('orderReference', reference),
		];

		return this.xclHttpService.execute('order', XclHttpService.GET, params, null)
			.pipe(map((xclOrder: MonogoalOrderXCLModel<any>) => {
				return new ServiceResponse<ShoppingBasketOperation>(
					this.mapper.xclToFunctional(xclOrder),
					this.feedbackExtractor.extract(xclOrder),
				);
			}));
	}

	// Override
	public deleteOperation(operationToDelete: ShoppingBasketOperation): Observable<ServiceResponse<ShoppingBasketOperation>> {
		if (UtilsHelper.nullOrUndefined(operationToDelete)) {
			return empty();
		}

		const params: ParameterModel[] = [
			new ParameterModel('restmethod', 'Cancel'),
			new ParameterModel('reference', operationToDelete.reference),
		];
		const xclObservable = this.xclHttpService.execute('shoppingbasket', XclHttpService.PUT, params, null) as Observable<MonogoalOrderXCLModel<any>>;

		return xclObservable.pipe(map((xclOrder: MonogoalOrderXCLModel<any>) => new ServiceResponse<ShoppingBasketOperation>(
			this.mapper.xclToFunctional(xclOrder),
			this.feedbackExtractor.extract(xclOrder),
		)));
	}

	// Override
	public createEnvelope(operationsToSign: ShoppingBasketOperation[]): Observable<ServiceResponse<ShoppingBasketEnvelope>> {
		const envelope: ShoppingBasketEnvelope = new ShoppingBasketEnvelope(operationsToSign);

		return this.initializeXCLEnvelope(envelope).pipe(mergeMap(this.validateXCLEnvelope))
			.pipe(map((envelopeOrder: AbstractOrderXCLModel) => {
				const envelopeState: Status = OrderStateHelper.getStatusFromXCLOrderStateCode(envelopeOrder.state);

				if (envelopeState !== Status.EnvelopeReadyToBeSigned) {
					throw ServiceResponse.emptyWithOnefeedback(new Feedback(
						'EnvelopeWrongStatus', FeedbackTypes.FRONTEND_ERROR,
						'The envelope is not in status 7200',
					));
				}

				this.feedEnvelopeFromXCLOrder(envelope, envelopeOrder);

				return new ServiceResponse<ShoppingBasketEnvelope>(envelope, this.feedbackExtractor.extract(envelopeOrder));
			}));
	}

	// Override
	public signEnvelope(envelope: ShoppingBasketEnvelope): Observable<ServiceResponse<ShoppingBasketEnvelope>> {
		return this.xclHttpService.execute('envelopes-sign', XclHttpService.PUT, [{ par: '', val: envelope.reference }])
			.pipe(map((response: AbstractOrderXCLModel) => {
				const feedbacks: Array<Feedback> = this.feedbackExtractor.extract(response);
				envelope.status = OrderStateHelper.getStatusFromXCLOrderStateCode(response.state);

				if (envelope.status !== Status.Closed) {
					throw new ServiceResponse(
						null,
						feedbacks.concat(new Feedback(
							'envelopeSignatureNotValidated', FeedbackTypes.FRONTEND_ERROR,
							'Something went wrong when signing the envelope',
						)),
					);
				}

				return new ServiceResponse(envelope, feedbacks);
			}));
	}

	private initializeXCLEnvelope(envelope: ShoppingBasketEnvelope): Observable<AbstractOrderXCLModel> {
		return this.xclHttpService.execute(
			'envelope',
			XclHttpService.POST,
			null,
			envelope.getOrderReferences(),
		);
	}

	private validateXCLEnvelope: (order: AbstractOrderXCLModel) => Observable<AbstractOrderXCLModel> =
		(order: AbstractOrderXCLModel) => this.xclHttpService.execute(
			'envelope-validate',
			XclHttpService.PUT,
			<ParameterModel[]>[{ par: 'reference', val: order.reference }],
		)

	private feedEnvelopeFromXCLOrder(envelope: ShoppingBasketEnvelope, envelopeOrder: AbstractOrderXCLModel): void {
		envelope.reference = envelopeOrder.reference;
		envelope.status = OrderStateHelper.getStatusFromXCLOrderStateCode(envelopeOrder.state);
		envelope.isReadyToBeSigned = envelopeOrder.isReadyToBeSigned;
		// Signature Context
		envelope.signatureContext = new XclSignatureContext({ signableReference: envelope.reference });
	}
}
