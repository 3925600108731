import { SignatureModes } from '@mdib/signature-modes';

/**
 * Helper defining signature modes available on XCL
 */
export class XclSignatureModes {
	private static mapper = {
		'PWD': SignatureModes.PASSWORD,
		'UCR': SignatureModes.UCR,
		'EID': SignatureModes.EID,
		'TWO': SignatureModes.DIGIPASS,
		'OTP': SignatureModes.OTP
	};

	public static fromXclType(type: string): SignatureModes {
		return XclSignatureModes.mapper[type];
	}

	public static toXclType(mode: SignatureModes): string {
		return Object.getOwnPropertyNames(XclSignatureModes.mapper)
			.find((types, type) => XclSignatureModes.mapper[type] === mode);
	}
}
