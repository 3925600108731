export interface AddressModel {
	id: string;
	addressLine1: string;
	addressLine2: string;
	country: string;
}

export class Address implements AddressModel {
	// 'addressLine1': [this.address && this.address.streetName],
	// 'addressLine2': [this.address && this.address.postCode],
	public id: string;
	public addressLine1: string;
	public addressLine2: string;
	public country: string;

	constructor(properties: Partial<AddressModel> = {}) {
		Object.assign(this, properties);
	}

	public set(key: keyof AddressModel, value: any): Address {
		const copy: any = {};
		Object.assign(copy, this);
		copy[key] = value;
		return new Address(copy);
	}

	public setAddressLine1(addressLine1: string): Address {
		return this.set('addressLine1', addressLine1);
	}

	public setAddressLine2(addressLine2: string): Address {
		return this.set('addressLine2', addressLine2);
	}

	public setCountry(country: string): Address {
		return this.set('country', country);
	}
}
