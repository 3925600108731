import { Component } from '@angular/core';

@Component({
	selector: 'mdib-default-error-page',
	templateUrl: './default-error-page.component.html',
	styleUrls: ['./default-error-page.component.scss']
})
export class DefaultErrorPageComponent {

}
