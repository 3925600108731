import { Component, Input } from '@angular/core';
import { BeneficiaryType } from '@mdib/beneficiaries';
import { ConfigService } from '@mdib/config';
import { PaymentForm, PaymentTypes } from '@mdib/payments';

@Component({
	selector: 'mdib-beneficiary-section',
	templateUrl: './beneficiary-section.component.html',
	styleUrls: ['./beneficiary-section.component.scss'],
})
export class BeneficiarySectionComponent {

	public readonly BeneficiaryType = BeneficiaryType;
	paymentTypes = PaymentTypes;

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.dataGatheringStepComponent.beneficiarySectionComponent.';
	@Input() paymentForm: PaymentForm;
	@Input() isConsultation = false;
	@Input() isUpdate = false;

	constructor(private configService: ConfigService) {
	}

	selectBeneficiaryType(beneficiaryType: BeneficiaryType) {
		if (beneficiaryType !== this.paymentForm.beneficiaryForm.type.value) {
			this.paymentForm.beneficiaryForm.type.setValue(beneficiaryType);
			this.paymentForm.clearCounterParty();
		}

		// This is used to make sure that if internal beneficiary is selected, the urgent payment switch will be set to false (since urgent payments cannot be done internally).
		if (beneficiaryType === BeneficiaryType.INTERNAL) {
			this.paymentForm.type.patchValue(this.paymentTypes.sepaCreditTransfer);
		}
	}

	isBeneficiary(beneficiaryType: BeneficiaryType): boolean {
		return this.paymentForm.beneficiaryForm.type.value === beneficiaryType;
	}

	get currency(): string {
		return this.configService.baseCurrencyCode;
	}

	get isInternational() {
		return this.paymentForm.type.value === PaymentTypes.internationalPayment;
	}

	get isUrgent() {
		return this.paymentForm.type.value === PaymentTypes.urgentPayment;
	}
}
