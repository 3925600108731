import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericPagesModule } from '@mdib-pages/generic';
import { StandingOrdersModule } from '@mdib/standing-orders';
// Third party
import { MatSelectModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
// Transversal modules
import { StylingModule } from '@mdib/styling';
import { UtilsModule } from '@mdib/utils';
import { CashAccountsModule } from '@mdib/cash-accounts';
// import { BeneficiariesComponentsModule } from '@mdib-pages/beneficiaries';
import { SignatureModule } from '@mdib/signature';
// Our components
import { StandingOrdersRoutingModule } from './standing-orders-routing.module';
import { StandingOrdersPageComponent } from './component/standing-orders-page/standing-orders-page.component';
import { UpdateStandingOrderPageComponent } from './component/update-standing-order-page/update-standing-order-page.component';
import { DataGatheringStepComponent } from '@mdib-pages/standing-orders/component/update-standing-order-page/data-gathering-step/data-gathering-step.component';
import { SecurityModule } from '@mdib/core/security';
import { BeneficiariesModule } from '@mdib/beneficiaries';
import { PaymentsGatheringPageModule, PaymentsPagesModule } from '@mdib-pages/payments';
import { CommonsModule } from '@mdib/commons';
import { ConfirmationStepComponent } from './component/update-standing-order-page/confirmation-step/confirmation-step.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		StylingModule,
		UtilsModule,
		CashAccountsModule,
		StandingOrdersModule,
		StandingOrdersRoutingModule,
		GenericPagesModule,
		SignatureModule,
		MatSelectModule,
		SecurityModule,
		BeneficiariesModule,
		PaymentsGatheringPageModule,
		CommonsModule,
	],
	declarations: [
		StandingOrdersPageComponent,
		UpdateStandingOrderPageComponent,
		DataGatheringStepComponent,
		ConfirmationStepComponent
	],
})
export class StandingOrdersPagesModule { }
