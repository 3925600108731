import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { HashAlgorithms, PaymentFileTypes } from '../model/payment-file.enum';
import { CommonValidator, FileValidator } from '@mdib/utils';
import { PaymentFile } from '../model/payment-file';

export class PaymentFilesUploadForm {
	private form: FormGroup;

	constructor(private fb: FormBuilder,
				private readonly paymentFile?: PaymentFile,
				private readonly fileExtension?: any,
				private readonly fileSize?: any,
	) {
		if (!this.paymentFile) {
			this.paymentFile = new PaymentFile();
		}
		this.create();
		this.subscription();
	}

	public clear() { // Unused method.
		this.form.reset({
			'type': PaymentFileTypes.sctInCu2Fi,
			'hash': HashAlgorithms.sha1,
			'fileName': '',
			'file': this.paymentFile.file.document
		});
		this.form.enable();
	}

	private create() {
		this.form = this.fb.group({
			'type': [PaymentFileTypes.sctInCu2Fi],
			'fileName': [this.paymentFile.file.name, [CommonValidator.required, FileValidator.fileName, FileValidator.fileExtension(this.fileExtension)]],
			'file': [this.paymentFile.file.document, [FileValidator.fileSize(this.fileSize)]],
			'hash': [this.paymentFile.hashAlgorithm, [CommonValidator.required]],
			'hashDigitsCheck': [this.paymentFile.hashDigitsCheck, [CommonValidator.required]],
		});
	}

	private subscription() {
		this.type.valueChanges.subscribe(type => this.paymentFile.type = type);
		this.fileName.valueChanges.subscribe(fileName => this.paymentFile.file.name = fileName);
		this.file.valueChanges.subscribe(file => this.paymentFile.file.document = file);
		this.hash.valueChanges.subscribe(hash => this.paymentFile.hashAlgorithm = hash);
		this.hashDigitsCheck.valueChanges.subscribe(hashDigitsCheck => this.paymentFile.hashDigitsCheck = hashDigitsCheck);
	}

	get group(): FormGroup {
		return this.form;
	}

	get model(): PaymentFile {
		return this.paymentFile;
	}

	get type(): AbstractControl {
		return this.form.get('type');
	}

	get file(): AbstractControl {
		return this.form.get('file');
	}

	get fileName(): AbstractControl {
		return this.form.get('fileName');
	}

	get hash(): AbstractControl {
		return this.form.get('hash');
	}

	get hashDigitsCheck(): AbstractControl {
		return this.form.get('hashDigitsCheck');
	}
}
