import { SignatureModes } from '@mdib/signature-modes';
import { ShoppingBasketOperation } from '../model/shopping-basket-operation';
import { Signature } from '@mdib/signature';
import { Status, UtilsHelper } from '@mdib/utils';

export class ShoppingBasketOperationBuilder {
	private _shoppingBasketOperation: ShoppingBasketOperation;

	constructor(shoppingBasketOperation?: ShoppingBasketOperation) {
		this._shoppingBasketOperation = UtilsHelper.nullOrUndefined(shoppingBasketOperation) ? new ShoppingBasketOperation() : shoppingBasketOperation;
	}

	/**
	 * Initiates a builder based on an empty {@link ShoppingBasketOperation}
	 * @returns {ShoppingBasketOperationBuilder}
	 */
	static empty(): ShoppingBasketOperationBuilder {
		return new ShoppingBasketOperationBuilder();
	}

	/**
	 * Initiates a builder based on an already existing {@link ShoppingBasketOperation}.
	 * The {@link ShoppingBasketOperation} will be cloned.
	 *
	 * @param {ShoppingBasketOperation} shoppingBasketOperation
	 * @returns {ShoppingBasketOperationBuilder}
	 */
	static from(shoppingBasketOperation: ShoppingBasketOperation): ShoppingBasketOperationBuilder {
		return new ShoppingBasketOperationBuilder(shoppingBasketOperation);
	}

	public reference(value: string): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.reference = value;
		return this;
	}

	public status(value: Status): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.status = value;
		return this;
	}

	public name(value: string): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.name = value;
		return this;
	}

	public isReadyToBeSigned(value: boolean): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.isReadyToBeSigned = value;
		return this;
	}

	public isContentLocked(value: boolean): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.isContentLocked = value;
		return this;
	}

	public signatureTypesAllowed(value: SignatureModes[]): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.signatureTypesAllowed = value;
		return this;
	}

	public ordererAccountNumber(value: string): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.ordererAccountNumber = value;
		return this;
	}

	public counterpartyAccountNumber(value: string): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.counterpartyAccountNumber = value;
		return this;
	}

	public amount(value: number): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.amount = value;
		return this;
	}

	public currency(value: string): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.currency = value;
		return this;
	}

	public maturityDate(value: string): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.paymentDate = value;
		return this;
	}

	public orderNatureCode(value: string): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.orderNatureCode = value;
		return this;
	}

	public orderNatureLabel(value: string): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.orderNatureLabel = value;
		return this;
	}

	public isAlreadySignedByUser(value: boolean): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.isAlreadySignedByUser = value;
		return this;
	}

	public signature(value: Signature): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.signature = value;
		return this;
	}

	public signatureContext(value: any): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.signatureContext = value;
		return this;
	}

	public modelIdentifier(value: any): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.modelIdentifier = value;
		return this;
	}

	public encoderUserIdentifier(value: any): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.encoderUserIdentifier = value;
		return this;
	}

	public countPastSignatures(value: any): ShoppingBasketOperationBuilder {
		this._shoppingBasketOperation.countPastSignatures = value;
		return this;
	}

	public ordererAccountAlias(value: string): ShoppingBasketOperationBuilder {
		if (!UtilsHelper.nullOrUndefined(value)) {
			this._shoppingBasketOperation.ordererAccountAlias = value;
		}
		return this;
	}

	public counterPartyName(value: string): ShoppingBasketOperationBuilder {
		if (!UtilsHelper.nullOrUndefined(value)) {
			this._shoppingBasketOperation.counterPartyName = value;
		}
		return this;
	}

	get shoppingBasketOperation(): ShoppingBasketOperation {
		return this._shoppingBasketOperation;
	}
}
