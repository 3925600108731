import { NotificationMessage } from './notification-message';
import { TranslateService } from '@ngx-translate/core';

export class DisplayedNotification {

	readonly NAMESPACE = 'notification.message.';

	public title: string;

	public message: string;
	public messageParams: Object;
	public hasTimeOut: boolean;
	public timeOut: number;
	public isClosable: boolean;
	public scrollToMe: boolean;

	constructor(
		notificationMessage: NotificationMessage,
		translateService: TranslateService
	) {
		this.messageParams = notificationMessage.messageParams || {};

		this.title = notificationMessage.title || '';
		try {
			translateService.get(this.NAMESPACE + this.title, this.messageParams)
				.subscribe(
					(result) => {
						this.title = result;
					}
				);
		} catch (e) { }

		this.message = notificationMessage.message || '';
		try {
			translateService.get(this.message, this.messageParams)
				.subscribe((result) => {
					if (result === notificationMessage.message) {
						if (notificationMessage.fallbackMessage && notificationMessage.fallbackMessage.length) {
							translateService.get(notificationMessage.fallbackMessage, this.messageParams).subscribe(
								(fallbackResult) => {
									this.message = fallbackResult;
								}
							);
						}
					} else { this.message = result; }
				});
		} catch (e) { }

		this.hasTimeOut = !!notificationMessage.hasTimeout;
		this.timeOut = notificationMessage.timeOut || 0;
		this.isClosable = !!notificationMessage.isClosable;
		this.scrollToMe = !!notificationMessage.scrollToMe;
	}

	public equals(displayedNotification: DisplayedNotification): boolean {
		if (displayedNotification.title === this.title &&
			displayedNotification.message === this.message &&
			displayedNotification.hasTimeOut === this.hasTimeOut &&
			displayedNotification.timeOut === this.timeOut &&
			displayedNotification.isClosable === this.isClosable &&
			displayedNotification.scrollToMe === this.scrollToMe &&
			JSON.stringify(displayedNotification.messageParams) === JSON.stringify(this.messageParams)) {
			return true;
		}
		return false;
	}
}
