import { ContactAddress } from '@mdib/customers';
import { CommunicationTypes, Address } from '@mdib/commons';
import { Signable, Signature } from '@mdib/signature';
import { SignatureModes } from '@mdib/signature-modes';
import { OperationStatus, StandingOrderAmountType, StandingOrderExecutionCode, StandingOrderFrequency } from '@mdib/standing-orders';
import { Months, StandingOrderPeriodicity, WeekDays } from '@mdib/standing-orders/model/standing-orders.enum';
import { isNullOrUndefined } from 'util';

export class StandingOrder implements Signable {
	address: Address;
	communication: string;
	communicationType: CommunicationTypes;
	counterpartyAccountNumber: string;
	counterpartyAddress: ContactAddress = new ContactAddress();
	counterpartyName: string;
	currency: string;
	dayOfTheMonth1: string;
	dayOfTheMonth2: string;
	dayOfTheMonth3: string;
	executionCode: StandingOrderExecutionCode;
	fixedAmount: number;
	frequency: StandingOrderFrequency;
	maturityDate: Date;
	maximumPaymentAmount: number;
	minimumPaymentAmount: number;
	nextPaymentDate: Date;
	numberOfExecutions: number;
	operationAmount: number;
	operationReference: string;
	ordererAccountAlias: string;
	ordererAccountNumber: string;
	paymentDay: string;
	periodicity: StandingOrderPeriodicity;
	reference: string;
	saveBeneficiary = false;
	startDate: Date;
	startingMonth: Months;
	status: OperationStatus;
	targetBalance: number;
	totalAmount: number;
	type: StandingOrderAmountType;
	typeIdentification: string;
	weekDay: WeekDays;

	signatureTypesAllowed: SignatureModes[] = [];
	signature: Signature = null;
	signatureContext: any = null;

	constructor(standingOrderObject?: Object) {
		if (!standingOrderObject) {
			return;
		}
		const self = this;
		Object.keys(standingOrderObject).forEach(function (key) {
			self[key] = standingOrderObject[key];
		});
	}

	public isSignatureTypeAllowed(signatureType: any): boolean {
		return (isNullOrUndefined(this.signatureTypesAllowed) ? false : this.signatureTypesAllowed.indexOf(signatureType) >= 0);
	}

	public isSigned(): boolean {
		return !isNullOrUndefined(this.signature);
	}

	public addSignature(signature: Signature): void {
		this.signature = signature;
	}

	public getSignatureContext(): any {
		return this.signatureContext;
	}
}
