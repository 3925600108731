import { Injector } from '@angular/core';

import { BeneficiaryV2 as Beneficiary } from '@mdib/beneficiaries';

import { BackendCreationOperationXcl } from '@mdib-xcl/core';

import { XclBeneficiaryModel } from '../../model/xcl-beneficiary-model';
import { BeneficiaryMapperService } from '../mappers/beneficiary-mapper.service';

export class BeneficiaryCreationXclOperation extends BackendCreationOperationXcl<Beneficiary, Beneficiary, XclBeneficiaryModel> {

	protected xclEndpoint = 'xcl.beneficiaries.generic';

	private beneficiaryMapperService: BeneficiaryMapperService;

	// Dependencies
	public constructor(injector: Injector) {
		super(injector);

		this.beneficiaryMapperService = injector.get(BeneficiaryMapperService);
	}

	protected getInitData(input: Beneficiary): any {
		return null;
	}

	protected getValidateData(input: Beneficiary): any {
		const xclInput = this.beneficiaryMapperService.toXcl(input);
		xclInput.principalIdentification = this.sessionUtilsService.getSessionActiveUserId();
		delete xclInput.beneficiaryType;
		return xclInput;
	}
}
