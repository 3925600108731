import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '@mdib/config';
import { PaymentForm, PaymentTypes } from '@mdib/payments';

@Component({
	selector: 'orderer-section',
	templateUrl: './orderer-section.component.html',
	styleUrls: ['./orderer-section.component.scss'],
})
export class OrdererSectionComponent implements OnInit {

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.dataGatheringStepComponent.ordererAccountSection.';

	@Input() isConsultation = false;
	@Input() paymentForm: PaymentForm;
	@Input() selectFirstCashAccount = false;

	constructor(private configService: ConfigService) {
	}

	ngOnInit() {
		if (!this.paymentForm.ordererAccount.value) {
			this.selectFirstCashAccount = true;
		}
	}

	get accountCurrency(): string {
		return this.paymentForm.model.type === PaymentTypes.internationalPayment ? null : this.configService.baseCurrencyCode;
	}

}
