import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';

import { CashAccountSortOrderService } from './cash-account-sort-order.service';
import { CashAccountSortOrderServiceStub } from './cash-account-sort-order.service.stub';

@Injectable()
export class CashAccountSortOrderServiceProxy extends AdapterProxy {
	protected ServiceToken() { return CashAccountSortOrderService; }
	protected ServiceDefinition() { return CashAccountSortOrderServiceStub; }
}
