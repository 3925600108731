import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { BeneficiaryV2 as Beneficiary } from '@mdib/beneficiaries';
import { CommonValidator, DataFormattingService, DateValidator } from '@mdib/utils';
import { printFormat } from 'iban';

import { CashAccountOperationsFilter } from '../types/interface/cash-account-operations-filter.interface';
import { CashAccountOperationMovement } from './cash-accounts.enum';

export class CashAccountOperationFilterForm {
	readonly _form: FormGroup;
	private _model: CashAccountOperationsFilter;

	constructor(private dataFormattingService: DataFormattingService,
				private fb: FormBuilder,
				filter?: CashAccountOperationsFilter,
	) {
		this._model = filter || <CashAccountOperationsFilter>{};
		this._form = this.fb.group({
			movementOperation: CashAccountOperationMovement.Both,
			counterPartyName: this._model.counterPartyName,
			counterPartyAccountNumber: this._model.counterPartyAccountNumber,
			minAmount: [this._model.minAmount],
			maxAmount: [this._model.maxAmount],
			dateStart: this._model.startDate,
			dateEnd: [this._model.endDate, [DateValidator.dateNotAfterToday]],
			currency: [this._model.currency],
		});
		this.initSubscription();
	}

	initSubscription() {
		this.movementOperation.valueChanges.subscribe((movementOperation) => {
			switch (movementOperation) {
				case 'credit' : {
					this._model.credit = true;
					this._model.debit = false;
					break;
				}
				case 'debit' : {
					this._model.debit = true;
					this._model.credit = false;
					break;
				}
				default : {
					this._model.credit = true;
					this._model.debit = true;
				}
			}
			this._model.movementOperation = movementOperation;
		});
		this.counterPartyName.valueChanges.subscribe(counterPartyName => this._model.counterPartyName = counterPartyName);
		this.counterPartyAccountNumber.valueChanges.subscribe(counterPartyAccountNumber => this._model.counterPartyAccountNumber = this.dataFormattingService.parseIban(counterPartyAccountNumber));
		this.minAmount.valueChanges.subscribe(minAmount => {
			this._model.minAmount = this.dataFormattingService.parseAmount(minAmount);
			if (this.maxAmount.value > 0) {
				this.minAmount.setValidators(CommonValidator.max(this.maxAmount));
			}
		});
		this.maxAmount.valueChanges.subscribe(maxAmount => {
			this._model.maxAmount = this.dataFormattingService.parseAmount(maxAmount);
			if (this.maxAmount.value > 0) {
				this.minAmount.setValidators(CommonValidator.max(this.maxAmount));
				this.minAmount.updateValueAndValidity();
			}
		});
		this.dateStart.valueChanges.subscribe(operationDateFrom => this._model.startDate = operationDateFrom);
		this.dateEnd.valueChanges.subscribe(operationDateTo => this._model.endDate = operationDateTo);
	}

	reset() {
		this._form.reset({movementOperation: CashAccountOperationMovement.Both});
	}

	get _(): FormGroup {
		return this._form;
	}

	set counterParty(beneficiary: Beneficiary) {
		this.counterPartyName.setValue(beneficiary.fullName);
		this.counterPartyAccountNumber.setValue(printFormat(beneficiary.account.number, ' '));
	}

	get movementOperation(): AbstractControl {
		return this._form.get('movementOperation');
	}

	get counterPartyName(): AbstractControl {
		return this._form.get('counterPartyName');
	}

	get counterPartyAccountNumber(): AbstractControl {
		return this._form.get('counterPartyAccountNumber');
	}

	get minAmount(): AbstractControl {
		return this._form.get('minAmount');
	}

	get maxAmount(): AbstractControl {
		return this._form.get('maxAmount');
	}

	get dateStart(): AbstractControl {
		return this._form.get('dateStart');
	}

	get dateEnd(): AbstractControl {
		return this._form.get('dateEnd');
	}

	get currency(): AbstractControl {
		return this._form.get('currency');
	}
}
