import { ReplaySubject, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { Injectable, Injector } from '@angular/core';
import { ParameterModel } from '@mdib/http';
import { FunctionalFeedbacksFromXclOrderExtractor, MultigoalOrderXCLModel, MonogoalOrderXCLModel, AbstractOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { ServiceResponse, Feedback, FeedbackTypes, UtilsHelper, ConfigurationService } from '@mdib/utils';
import { XclDateFormatter } from '@mdib-xcl/utils';
import { MailboxConversation, MessageTypes, MailboxMessage, MailboxMessagesCommonService, MailboxConversationSummary } from '@mdib/mailbox';
import { XclMailboxConversation } from '../interface/xcl-mailbox-conversation';
import { XclMailboxMessage } from '../interface/xcl-mailbox-message';
import { SessionUtilsService, SessionsService } from '@mdib/sessions';
import { NaturalPerson, PersonMandate, PersonsService } from '@mdib/customers';
import { MessageTypeParameter } from '@mdib/mailbox/model/mailbox.enum';
import { ParameterSetsService } from '@mdib/core/customization';

@Injectable({
	providedIn: 'root'
})
export class MailboxMessagesXclService extends MailboxMessagesCommonService {

	private sessionService: SessionsService;
	private personsService: PersonsService;

	private conversationTypes: Map<string, MessageTypeParameter> = new Map();
	private defaultConversationType: MessageTypeParameter = { id: '', label: '' };

	constructor(
		private xclHttpService: XclHttpService,
		private sessionUtilsService: SessionUtilsService,
		private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
		private parameterSetsService: ParameterSetsService,
		injector: Injector
	) {
		super(injector);

		this.sessionService = injector.get(SessionsService);
		this.personsService = injector.get(PersonsService);
		this.configurationService = injector.get(ConfigurationService);

	}

	public reloadConversations(offset?: number, limit?: number): void {
		// Retrieve the conversations upper stream on which to emit new values
		const upperStream = this.conversationsSummariesStream;

		// Emit a "null" on the upper stream, to inform that a refresh of data is ongoing
		upperStream.next(null);

		// Set XCL parameters
		const principalId = this.sessionUtilsService.getSessionActiveUserId();
		const params: ParameterModel[] = [
			new ParameterModel('principalIdentification', principalId),
		];

		if (!UtilsHelper.nullOrUndefined(offset)) {
			params.push(new ParameterModel('start', offset.toString()));
		}
		if (!UtilsHelper.nullOrUndefined(limit)) {
			params.push(new ParameterModel('maxResults', limit.toString()));
		}

		const representedClient: Observable<string> = this.personsService.getRepresentedClientNumber();

		this.loadParameters()
			.pipe(mergeMap(() => representedClient))
			.pipe(map((client: string) => {
					if (client) {
						params.push(new ParameterModel('representedClientNumber', client));
					}
					// Call XCL to retrieve the list of messages
					return this.xclHttpService.execute('mailbox-conversations-list', XclHttpService.GET, params)
						.pipe(map((xclOrder: MultigoalOrderXCLModel<XclMailboxConversation>) =>
							new ServiceResponse<MailboxConversationSummary[]>(
								this.getMailboxConversationsArrayFromTechnicalModelArray(xclOrder.goalList),
								this.feedbackExtractor.extract(xclOrder),
							)));

				}),
				mergeMap((value: Observable<ServiceResponse<MailboxConversationSummary[]>>) => {
					return value;
				})).subscribe(
			(response) => upperStream.next(response),
			(error) => upperStream.error(error),
			// On completion of the XCL stream, don't complete the upper stream !!);
		);
	}

	public reloadConversation(conversationIdentifier: string): void {
		if (!conversationIdentifier) {
			return;
		}

		// Retrieve the conversations upper stream on which to emit new values
		const upperStream = this.getConversationStream(conversationIdentifier);

		// Emit a "null" on the upper stream, to inform that a refresh of data is ongoing
		upperStream.next(null);

		// Set XCL parameters
		const principalId = this.sessionUtilsService.getSessionActiveUserId();
		const params: ParameterModel[] = [
			new ParameterModel('principalIdentification', principalId),
			new ParameterModel('messageReference', conversationIdentifier.split('µ')[0]),
			new ParameterModel('messageSequenceNumber', conversationIdentifier.split('µ')[1]),
		];

		// In B2B, add the represented client parameter
		let setParameters = of(null);
		setParameters = this.personsService.getRepresentedClientNumber().pipe(
			tap((client: string) => {
				if (client) {
					params.push(new ParameterModel('representedClientNumber', client));
				}
			}),
		);

		// Call XCL to retrieve the list of messages
		this.loadParameters().pipe(
			mergeMap(() => setParameters),
			mergeMap(() => {
				return this.xclHttpService.execute('mailbox-conversation-messages', XclHttpService.GET, params)
					.pipe(map((xclOrder: MultigoalOrderXCLModel<XclMailboxMessage>) => {
						const messagesList = this.getMailboxMessagesArrayFromTechnicalModelArray(xclOrder.goalList);

						/*
						UNTIL THE XCL PROVIDES A PROPER WAY TO RETRIEVE A SINGLE CONVERSATION'S PROPERTIES,
						WE TEMPORARILY USE THE PROPERTIES OF THE LAST MESSAGE OF THE LIST INSTEAD.
						NORMALLY, THE FULL DETAILS OF THE CONVERSATION SHOULD BE RETRIEVED FROM THE XCL, AND SHOULD BE MAPPED
						TO THE CONVERSATION OBJECT.
						 */
						const conversation = Object.assign(new MailboxConversation(), messagesList[0]);
						conversation.identifier = xclOrder.goalList[0].messageReference + 'µ' + xclOrder.goalList[0].messageSequenceNumber;

						// Attach the messages list to the conversation
						conversation.messages = messagesList;

						return new ServiceResponse<MailboxConversation>(conversation, this.feedbackExtractor.extract(xclOrder));
					}));
			})).subscribe(
			(response) => upperStream.next(response),
			(error) => upperStream.error(error),
			// On completion of the XCL stream, don't complete the upper stream !!);
		);
	}

	public addMessage(message: MailboxMessage): Observable<ServiceResponse<MailboxMessage>> {
		// Set parameters
		const principalId = this.sessionUtilsService.getSessionActiveUserId();
		const body = {
			bankSubject: message.title,
			destinationDepartment: message.recipientName,
			messageSubject: '05',
			text: message.content,
			principalIdentification: principalId,
		};

		// Steps
		const initCall = () => {
			return this.xclHttpService.execute('mailbox-conversation-messages', XclHttpService.POST, [], body);
		};

		const actionCall = (action: string, order) => {
			const act = new ParameterModel('restmethod', action);
			const ref = new ParameterModel('reference', order.reference);
			return this.xclHttpService.execute('mailbox-conversation-messages', XclHttpService.PUT, [act, ref], body);
		};

		// Call XCL to send the message
		const result = initCall().pipe(
			mergeMap(order => actionCall('retrieve', order)),
			mergeMap(order => actionCall('validate', order)),
			mergeMap(order => actionCall('confirm', order))
		);

		result.subscribe(response => {
			// TEMP: this is an example
			// console.log(response);
		});

		return of(new ServiceResponse());
	}

	public deleteConversation(conversationIdentifier: string, reloadConversationsUponCompletion?: boolean): Observable<ServiceResponse<null>> {
		if (isNullOrUndefined(reloadConversationsUponCompletion)) {
			reloadConversationsUponCompletion = true;
		}

		// Emit a null on the conversation stream, to indicate that something is happening (a refresh, or a completion).
		this.getConversationStream(conversationIdentifier).next(null);

		const replaySubject: ReplaySubject<ServiceResponse<null>> = new ReplaySubject();

		const principalId = this.sessionUtilsService.getSessionActiveUserId();

		of(null).pipe(
			mergeMap(() => {
				// Step 1 : Call the DELETE method on the conversation
				const params: ParameterModel[] = [
					new ParameterModel('identifier', conversationIdentifier),
				];
				return this.xclHttpService.execute('mailbox-conversation', XclHttpService.DELETE, params);
			}),
			tap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) =>
				// Step 1 bis : verify that the deletion order is well in the correct state
				this.assert(xclOrder.state === '0000', 'MailboxConversationDeletionError', 'Error while deleting the secure message.')),
			mergeMap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) => {
				// Step 2 : Call the RETRIEVE trigger on the conversation state machine (for the order reference retrieved by the previous call)
				const params: ParameterModel[] = [
					new ParameterModel('trigger', 'retrieve'),
					new ParameterModel('orderReference', xclOrder.reference),
				];
				const body = Object.assign(new Object(), xclOrder.goal, {
					'principalIdentification': principalId,
					'identifier': conversationIdentifier,
				});
				return this.xclHttpService.execute('mailbox-conversation-statemachine', XclHttpService.PUT, params, body);
			}),
			tap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) =>
				// Step 2 bis : verify that the deletion order is well in the correct state
				this.assert(xclOrder.state === '6000', 'MailboxConversationDeletionError', 'Error while deleting the secure message.')),
			mergeMap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) => {
				// Step 3 : Call the CONFIRM trigger on the conversation state machine (for the order reference retrieved by the previous call)
				return this.callConfirmTrigger(xclOrder, principalId, conversationIdentifier);
			}),
			tap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) =>
				// Step 3 bis : verify that the deletion order is well in the correct state
				this.assert(xclOrder.state === '9000', 'MailboxConversationDeletionError', 'Error while deleting the secure message.')),
			map((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) => {
				// Return a ServiceResponse with the optional list of feedbacks returned by the XCL
				return new ServiceResponse<MailboxConversation>(null, this.feedbackExtractor.extract(xclOrder));
			}),
			tap(() => {
				this.closeConversationStreamIfExists(conversationIdentifier);
				this.reloadConversationsIfTrueOrNullOrUndefined(reloadConversationsUponCompletion);
			}, () => {
				this.reloadConversation(conversationIdentifier);
				this.reloadConversationsIfTrueOrNullOrUndefined(reloadConversationsUponCompletion);
			}))
			.subscribe(replaySubject);

		return replaySubject.asObservable();
	}

	// OVERRIDE
	public markConversationsAsReadOrUnread(conversationIdentifier: string[], isRead: boolean): Observable<ServiceResponse<null>> {
		// WE NEED TO OVERRIDE THE PARENT METHOD JUST BECAUSE OF THIS:
		// AS THE XCL DOES NOT ALLOW TO PUT BACK CONVERSATIONS AS UNREAD, WE WILL ONLY PROCESS THE "MARK AS READ" CASE.
		// FOR THE "MARK AS UNREAD" CASE, WE JUST DON'T DO ANYTHING.
		if (!isRead) {
			return of(ServiceResponse.emptyWithOnefeedback(new Feedback('ActionNotSupportedByTheBackend', FeedbackTypes.FRONTEND_WARNING, 'Sorry, this action is not supported by the backend.')));
		}

		return super.markConversationsAsReadOrUnread(conversationIdentifier, isRead);
	}


	public markConversationAsReadOrUnread(conversationIdentifier: string, isRead: boolean): Observable<ServiceResponse<null>> {
		if (!isRead) {
			// AS THE XCL DOES NOT ALLOW TO PUT BACK CONVERSATIONS AS UNREAD, WE WILL ONLY PROCESS THE "MARK AS READ" CASE.
			// FOR THE "MARK AS UNREAD" CASE, WE JUST DON'T DO ANYTHING.
			return of(ServiceResponse.emptyWithOnefeedback(new Feedback('ActionNotSupportedByTheBackend', FeedbackTypes.FRONTEND_WARNING, 'Sorry, this action is not supported by the backend.')));
		}

		// Emit a null on the conversation stream, to indicate that something is happening (a refresh).
		this.getConversationStream(conversationIdentifier).next(null);

		const replaySubject: ReplaySubject<ServiceResponse<null>> = new ReplaySubject();

		const principalId = this.sessionUtilsService.getSessionActiveUserId();

		of(null).pipe(
			mergeMap(() => {
				// Step 1 : Call the PUT method on the conversation
				const params: ParameterModel[] = [
					new ParameterModel('identifier', conversationIdentifier),
				];
				return this.xclHttpService.execute('mailbox-conversation', XclHttpService.PUT, params);
			}),
			tap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) =>
				// Step 1 bis : verify that the deletion order is well in the correct state
				this.assert(xclOrder.state === '0000', 'MailboxConversationUpdateError', 'Error while updating the secure message information.')),
			mergeMap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) => {
				// Step 2 : Call the RETRIEVE trigger on the conversation state machine (for the order reference retrieved by the previous call)
				const params: ParameterModel[] = [
					new ParameterModel('trigger', 'retrieve'),
					new ParameterModel('orderReference', xclOrder.reference),
				];
				const body = Object.assign(new Object(), xclOrder.goal, {
					'principalIdentification': principalId,
					'identifier': conversationIdentifier,
					'markAsReadSwitch': isRead,
				});
				return this.xclHttpService.execute('mailbox-conversation-statemachine', XclHttpService.PUT, params, body);
			}),
			tap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) =>
				// Step 2 bis : verify that the deletion order is well in the correct state
				this.assert(xclOrder.state === '1500', 'MailboxConversationUpdateError', 'Error while updating the secure message.')),
			mergeMap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) => {
				// Step 3 : Call the VALIDATE trigger on the conversation state machine (for the order reference retrieved by the previous call)
				const params: ParameterModel[] = [
					new ParameterModel('trigger', 'validate'),
					new ParameterModel('orderReference', xclOrder.reference),
				];
				const body = Object.assign(new Object(), xclOrder.goal, {
					'principalIdentification': principalId,
					'identifier': conversationIdentifier,
					'markAsReadSwitch': isRead,
				});
				return this.xclHttpService.execute('mailbox-conversation-statemachine', XclHttpService.PUT, params, body);
			}),
			tap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) =>
				// Step 3 bis : verify that the deletion order is well in the correct state
				this.assert(xclOrder.state === '6000', 'MailboxConversationUpdateError', 'Error while updating the secure message.')),
			mergeMap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) => {
				// Step 4 : Call the CONFIRM trigger on the conversation state machine (for the order reference retrieved by the previous call)
				return this.callConfirmTrigger(xclOrder, principalId, conversationIdentifier);
			}),
			tap((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) =>
				// Step 3 bis : verify that the deletion order is well in the correct state
				this.assert(xclOrder.state === '9000', 'MailboxConversationUpdateError', 'Error while updating the secure message.')),
			map((xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>) => {
				// Return a ServiceResponse with the optional list of feedbacks returned by the XCL
				return new ServiceResponse<MailboxConversation>(null, this.feedbackExtractor.extract(xclOrder));
			}),
			tap(() => {
				this.reloadConversation(conversationIdentifier);
				this.reloadConversations();
			}, () => {
				this.reloadConversation(conversationIdentifier);
				this.reloadConversations();
			}))
			.subscribe(replaySubject);

		return replaySubject.asObservable();
	}

	public countUnreadMessages(): Observable<ServiceResponse<number>> { // new method, not sure if implemented.
		// Set XCL parameters
		const principalId = this.sessionUtilsService.getSessionActiveUserId();
		const params: ParameterModel[] = [
			new ParameterModel('principalIdentification', principalId),
		];

		return this.personsService.getRepresentedClientNumber().pipe(mergeMap((representedClient: string) => {
			// B2B
			if (representedClient) {
				params.push(new ParameterModel('representedClientNumber', representedClient));
			}

			return this.xclHttpService.execute('mailbox-unread-messages-count', XclHttpService.GET, params).pipe(
				map((xclOrder: AbstractOrderXCLModel) => new ServiceResponse<number>(xclOrder.rowCount, this.feedbackExtractor.extract(xclOrder))),
			);

		}));
	}


	protected assert(conditionResult: boolean, errorLabelKeyIfFalse: string, errorMessageIfFalse: string): void {
		if (!conditionResult) {
			throw ServiceResponse.emptyWithOnefeedback(new Feedback('errorLabelKeyIfFalse', FeedbackTypes.FRONTEND_ERROR, errorMessageIfFalse));
		}
	}

	/**
	 * Converts an array of technical model mailbox conversations to an equivalent array of functional model mailbox conversations
	 */
	protected getMailboxConversationsArrayFromTechnicalModelArray(technicalModelArray: XclMailboxConversation[]): MailboxConversationSummary[] {
		if (isNullOrUndefined(technicalModelArray)) {
			console.error('Invalid list of secured messages conversations provided by the backend: ' + JSON.stringify(technicalModelArray));
			return [];
		}
		return technicalModelArray.map((technicalModel: XclMailboxConversation) => this.feedMailboxConversationSummaryFromTechnicalModel(technicalModel));
	}

	/**
	 * Converts an array of technical model mailbox messages to an equivalent array of functional model mailbox messages
	 */
	protected getMailboxMessagesArrayFromTechnicalModelArray(technicalModelArray: XclMailboxMessage[]): MailboxMessage[] {
		if (isNullOrUndefined(technicalModelArray)) {
			console.error('Invalid list of secured messages provided by the backend: ' + JSON.stringify(technicalModelArray));
			return [];
		}
		return technicalModelArray.map((technicalModel: XclMailboxMessage) => this.feedMailboxMessageFromTechnicalModel(technicalModel));
	}

	protected feedMailboxConversationSummaryFromTechnicalModel(xclConversation: XclMailboxConversation, conversation?: MailboxConversationSummary): MailboxConversationSummary {
		if (isNullOrUndefined(conversation)) {
			conversation = new MailboxConversationSummary();
		}

		this.feedCommonFieldsOfConversationAndMessage(xclConversation, conversation);

		conversation.identifier = xclConversation.identifier;

		return conversation;
	}

	protected feedMailboxConversationFromTechnicalModel(xclConversation: XclMailboxConversation, conversation?: MailboxConversation): MailboxConversation {
		if (isNullOrUndefined(conversation)) {
			conversation = new MailboxConversation();
		}

		this.feedMailboxConversationSummaryFromTechnicalModel(xclConversation, conversation);

		// TODO: MAP FIELDS THAT ARE ONLY PROVIDED FOR FULL CONVERSATIONS

		return conversation;
	}

	protected feedMailboxMessageFromTechnicalModel(xclMessage: XclMailboxMessage, message?: MailboxMessage): MailboxMessage {
		if (isNullOrUndefined(message)) {
			message = new MailboxMessage();
		}

		// Feed the fields that are common to conversations and messages
		this.feedCommonFieldsOfConversationAndMessage(xclMessage, message);

		const principalId = this.sessionUtilsService.getSessionActiveUserId();

		// Feed the fields that are proper to messages
		message.identifier = xclMessage.messageReference;
		message.senderName = xclMessage.fromIdentification;
		message.recipientName = xclMessage.toIdentification;
		message.wasSentByCurrentUser = (xclMessage.senderSubscriptionNumber === principalId);
		message.content = xclMessage.text;

		return message;
	}

	/**
	 * This function feeds a Conversation or Message from the fields that are common to both XCL Conversation or Message
	 *
	 * @param {XclMailboxConversation | XclMailboxMessage} xclConversationOrMessage
	 * @param {MailboxConversationSummary} conversation
	 */
	protected feedCommonFieldsOfConversationAndMessage(xclConversationOrMessage: XclMailboxConversation | XclMailboxMessage, conversation?: MailboxConversationSummary): void {
		conversation.title = xclConversationOrMessage.bankSubject;
		conversation.isImportant = xclConversationOrMessage.urgentMessageSwitch === 'Y';

		conversation.sendingDate = new Date(XclDateFormatter.convertXCLToISODateFormat(xclConversationOrMessage.receptionDate) + 'T' + XclDateFormatter.convertXCLToISOTimeFormat(xclConversationOrMessage.receptionTime));

		conversation.isWaitingResponse = (xclConversationOrMessage.context.charAt(0) === 'Y');
		conversation.isRead = (xclConversationOrMessage.context.charAt(1) !== 'Y');

		conversation.isAnswered = (xclConversationOrMessage.messageStatus === 'A');

		// FIXME : TO REPLACE BY THE USE OF MICROSERVICES INSTEAD OF HARDCODING PARAMETER TABLE VALUES HERE
		switch (xclConversationOrMessage.messageSubject) {
			case '00': {
				conversation.type = MessageTypes.BankInformation;
				break;
			}
			case '03': {
				conversation.type = MessageTypes.Suggestions;
				break;
			}
			case '04': {
				conversation.type = MessageTypes.BankProductsAndServices;
				break;
			}
			case '05': {
				conversation.type = MessageTypes.Complaint;
				break;
			}
			default: { conversation.type = MessageTypes.Unknown; }
		}

		if (conversation.isAnswered) {
			conversation.correspondentName = xclConversationOrMessage.toIdentification;
		} else {
			conversation.correspondentName = xclConversationOrMessage.fromIdentification;
		}
	}

	private callConfirmTrigger(xclOrder: MonogoalOrderXCLModel<XclMailboxConversation>, principalId: string, conversationIdentifier: string): Observable<AbstractOrderXCLModel> {
		const params: ParameterModel[] = [
			new ParameterModel('trigger', 'confirm'),
			new ParameterModel('orderReference', xclOrder.reference),
		];
		const body = Object.assign(new Object(), xclOrder.goal, {
			'principalIdentification': principalId,
			'identifier': conversationIdentifier,
		});
		return this.xclHttpService.execute('mailbox-conversation-statemachine', XclHttpService.PUT, params, body);
	}

	private loadParameters(): Observable<boolean> {
		return this.parameterSetsService.get('mailbox/conversation-types').pipe(map((params: MessageTypeParameter[]) => {
			this.conversationTypes = new Map();
			params.forEach(p => this.conversationTypes.set(p.id, p));
			this.defaultConversationType = this.conversationTypes.get('??');
			return true;
		}));
	}

}
