import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElectronicalStatementsService } from './service/electronical-statements.service';
import { ElectronicalStatementsServiceProxy } from './service/electronical-statements.service.proxy';

@NgModule()
export class ElectronicalStatementsModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: ElectronicalStatementsModule,
			providers: [
				{ provide: ElectronicalStatementsService, useClass: ElectronicalStatementsServiceProxy },
			]
		};
	}
}

