import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AuthorizationsService } from '@mdib/core/security';
import { SessionResetService } from '@mdib/sessions';

@Injectable({
	providedIn: 'root'
})
export class SessionResetMemoryService implements SessionResetService {

	constructor(
		private authorizationsService: AuthorizationsService,
	) { }

	public reset(): Observable<any> {
		this.authorizationsService.flushAccessRights();
		return of();
	}

}
