import { Component, Input, OnChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { BeneficiaryV2 as Beneficiary, BeneficiarySearchModalComponent } from '@mdib/beneficiaries';
import { CashAccount } from '@mdib/cash-accounts';
import { PendingPaymentsPageFilter } from '@mdib/payments';
import { DataFormattingService, CommonValidator } from '@mdib/utils';
import { printFormat } from 'iban';

@Component({
	selector: 'pending-payments-page-filter',
	templateUrl: './pending-payments-page-filter.component.html',
	styleUrls: ['./pending-payments-page-filter.component.scss'],
})
export class PendingPaymentsPageFilterComponent implements OnChanges {

	readonly NAMESPACE = 'paymentsPagesModule.pendingPaymentsPageComponent.pendingPaymentsPageFilterComponent.';
	readonly form: FormGroup;

	@Input() filter: PendingPaymentsPageFilter;

	constructor(private fb: FormBuilder,
		private dialog: MatDialog,
		private dataFormattingService: DataFormattingService) {
		this.form = this.fb.group({
			ordererAccountNumber: [''],
			counterpartyName: [''],
			counterpartyAccountNumber: [''],
			maturityDateFrom: [null],
			maturityDateTo: [null],
			amountFrom: [null],
			amountTo: [null],
		});
		this.initSubscription();
	}

	initSubscription() {
		this.form.get('ordererAccountNumber').valueChanges.subscribe(ordererAccountNumber => ordererAccountNumber ? this.filter.ordererAccountNumber = ordererAccountNumber.accountNumber : this.filter.ordererAccountNumber = ordererAccountNumber);
		this.form.get('counterpartyName').valueChanges.subscribe(counterpartyName => this.filter.counterpartyName = counterpartyName);
		this.form.get('counterpartyAccountNumber').valueChanges.subscribe(counterpartyAccountNumber => this.filter.counterpartyAccountNumber = counterpartyAccountNumber);
		this.form.get('maturityDateFrom').valueChanges.subscribe(maturityDateFrom => this.filter.maturityDateFrom = maturityDateFrom);
		this.form.get('maturityDateTo').valueChanges.subscribe(maturityDateTo => this.filter.maturityDateTo = maturityDateTo);
		this.form.get('amountFrom').valueChanges.subscribe(amountFrom => {
			this.filter.amountFrom = this.dataFormattingService.parseAmount(amountFrom);
			if (this.filter.amountTo > 0) {
			this.amountFromControl.setValidators([CommonValidator.max(this.filter.amountTo)]);
			}
		});
		this.form.get('amountTo').valueChanges.subscribe(amountTo => {
			this.filter.amountTo = this.dataFormattingService.parseAmount(amountTo);
			if (this.filter.amountTo > 0) {
				this.amountFromControl.setValidators([CommonValidator.max(this.filter.amountTo)]);
				this.amountFromControl.updateValueAndValidity();
			}
		});
		}

	ngOnChanges() {
		this.form.reset();
		this.initSubscription();
	}

	createDialog() {
		const dialogRef = this.dialog.open(BeneficiarySearchModalComponent, {
			width: '1200px',
			disableClose: true,
			maxWidth: '99%',
			maxHeight: '99%',
		});

		dialogRef.componentInstance.itemChange.subscribe((beneficiary: Beneficiary) => {
			this.applyBeneficiary(beneficiary);
			dialogRef.close();
		});
	}

	public addAccount(account: CashAccount) {
		this.form.patchValue({
			ordererAccountNumber: account,
		});
	}

	protected applyBeneficiary(beneficiary: Beneficiary): void {
		this.form.patchValue({
			counterpartyName: beneficiary.fullName,
			counterpartyAccountNumber: printFormat(beneficiary.account.number),
		});
	}

	get dateFromControl(): AbstractControl {
		return this.form.get('maturityDateFrom');
	}

	get dateToControl(): AbstractControl {
		return this.form.get('maturityDateTo');
	}

	get amountToControl(): AbstractControl {
		return this.form.get('amountTo');
	}

	get amountFromControl(): AbstractControl {
		return this.form.get('amountFrom');
	}
}
