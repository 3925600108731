import { Component, Input } from '@angular/core';
import { PaymentForm, PaymentTypes } from '@mdib/payments';
import { Status } from '@mdib/utils';

@Component({
	selector: 'mdib-beneficiary-section-european-international',
	templateUrl: './beneficiary-section-european-international.component.html',
	styleUrls: ['./beneficiary-section-european-international.component.scss'],
})
export class BeneficiarySectionEuropeanInternationalComponent {

	readonly NAMESPACE = 'dailyBankingPagesModule.createPaymentPageComponent.dataGatheringStepComponent.beneficiarySectionEuropeanInternationalComponent.';
	readonly Status = Status;
	PaymentTypes = PaymentTypes;

	@Input() paymentForm: PaymentForm;
	@Input() isConsultation = false;
	@Input() isUpdate = false;

}
