/**
 * @export
 * @interface Feedback
 */
export class Feedback {
	/**
	 * @type {string}
	 * @memberof Feedback
	 * @description A unique key to describe the feedback, which could be used for translation
	 */
	key: string;
	/**
	 * @type {string}
	 * @memberof Feedback
	 * @description A type which could be used to sort a list of feedbacks
	 */
	type: string;

	/**
	 * @type {string}
	 * @memberof Feedback
	 * @description A human readable message for the fallback
	 */
	defaultMessage?: string;
	/**
	 * @type {Object}
	 * @memberof Feedback
	 * @description Optional field
	 */
	option?: Object;

	constructor(key: string, type: string, defaultMessage?: string, option?: Object) {
		this.type = type;
		this.key = (this.type && this.type.length ? `${this.type}_` : '') + key;
		this.defaultMessage = defaultMessage || '';
		this.option = option;
	}
}
