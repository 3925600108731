import { ServiceResponse } from '@mdib/utils';
import { Observable, of } from 'rxjs';
import { StandingOrder } from '../model/standing-order';
import { StandingOrdersPageFilter } from '../model/standing-orders-page-filter';
import { StandingOrdersService } from './standing-orders.service';

export class StandingOrdersServiceStub extends StandingOrdersService {
	list(offset?: number, limit?: number, filter?: StandingOrdersPageFilter): Observable<ServiceResponse<StandingOrder[]>> {
		return of();
	}

	count(filter: StandingOrdersPageFilter): Observable<ServiceResponse<number>> {
		return of();
	}

	get(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>> {
		return of();
	}

	validateStandingOrder(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>> {
		return of();
	}

	signStandingOrder(standingOrder: StandingOrder): Observable<ServiceResponse<StandingOrder | null>> {
		return of();
	}

}
