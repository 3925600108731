export class CachedSeries<T> {

	public readonly freshness: Date = new Date();
	public elements: T[] = [];

	constructor(
		elements: T[] = [],
		public index = 0,
		public previous: CachedSeries<T> | null = null,
		public next: CachedSeries<T> | null = null,
	) {
		this.elements.push(...elements);
	}

	public get count(): number {
		return this.elements.length;
	}

	public get lastIndex(): number {
		return this.index + this.elements.length - 1;
	}

	public isExpired(validityLimit: number): boolean {
		return this.freshness.getTime() < (new Date().getTime() - validityLimit);
	}
}
