import { BackendOperationStub, BackendOperationType, BackendOperation, SearchCriteria, SearchResult } from '@mdib/core/adapters';

import { BeneficiariesService } from './beneficiaries.service';
import { Beneficiary } from '../../types/models/beneficiary';

export class BeneficiariesServiceStub extends BeneficiariesService {
	public create(): BackendOperation<Beneficiary, Beneficiary> {
		return new BackendOperationStub(BackendOperationType.CREATE);
	}

	public retrieve(): BackendOperation<string, Beneficiary> {
		return new BackendOperationStub(BackendOperationType.RETRIEVE);
	}

	public update(): BackendOperation<Partial<Beneficiary>, Beneficiary> {
		return new BackendOperationStub(BackendOperationType.UPDATE);
	}

	public delete(): BackendOperation<string, Beneficiary> {
		return new BackendOperationStub(BackendOperationType.DELETE);
	}

	public search(): BackendOperation<SearchCriteria<any>, SearchResult<Beneficiary>> {
		return new BackendOperationStub(BackendOperationType.SEARCH);
	}
}
