import { Injectable } from '@angular/core';

import { AdapterProxy } from '@mdib/core/adapters';
import { PdfStatementsService } from '@mdib/pdf-statements/service/pdf-statements.service';
import { PdfStatementsServiceStub } from '@mdib/pdf-statements/service/pdf-statements.service.stub';


@Injectable()
export class PdfStatementsServiceProxy extends AdapterProxy {
	protected ServiceToken() { return PdfStatementsService; }
	protected ServiceDefinition() { return PdfStatementsServiceStub; }
}
