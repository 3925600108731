import { MailboxConversation, MessageTypes, MailboxMessage } from '@mdib/mailbox';

export class MockMailboxMessages {
	static getConversations(): MailboxConversation[] {
		return [
			Object.assign(new MailboxConversation(), <MailboxConversation>{
				identifier: '9',
				type: MessageTypes.BankInformation,
				isImportant: true,
				isRead: false,
				isWaitingResponse: true,
				isAnswered: false,
				sendingDate: new Date('2018-06-14 16:35:14'),
				title: 'Geweigerde betaling | Ref.B7K27CWSIO4FPT3V',
				messages: [
					Object.assign(new MailboxMessage(), <MailboxMessage>{
						senderName: 'NWB Bank',
						recipientName: 'Ludovic De Pestel',
						content: 'De volgende betaling op uw rekening kon niet worden uitgevoerd<br>'
						+ '<p>'
						+ '<div class="table-responsive"> '
						+ '<table class="table">'
						+ '<tbody>'
						+ '<tr><td>REFERENTIE</td><td>B7K27CWSIO4FPT3V</td></tr>'
						+ '<tr><td>BETALER</td><td>Ludovic De Pestel</td></tr>'
						+ '<tr><td></td><td>BE11 7369 8897 1648</td></tr>'
						+ '<tr><td>SCHULDEISER</td><td>Engie</td></tr>'
						+ '<tr><td></td><td>BE74 9452 3939 2707</td></tr>'
						+ '<tr><td>BEDRAG</td><td>133,25€</td></tr>'
						+ '<tr><td>DATUM</td><td>14/06/2018</td></tr>'
						+ '<tr><td>MEDEDELING</td><td>Elektriciteit mei 2018</td></tr>'
						+ '</tbody>'
						+ '</table>'
						+ '</div>'
						+ '</p>',
						sendingDate: new Date('2018-06-14 16:35:14'),
						wasSentByCurrentUser: false,
					}),
				],
				// content: 'Please go to your bank now',
				correspondentName: 'NWB Bank',
			}),
			Object.assign(new MailboxConversation(), <MailboxConversation>{
				identifier: '8',
				type: MessageTypes.BankInformation,
				isImportant: true,
				isRead: false,
				isWaitingResponse: true,
				isAnswered: false,
				sendingDate: new Date('2018-06-14 16:53:14'),
				title: 'Geweigerde betaling | Ref.B7K27CWSIO4FPT3W',
				messages: [
					Object.assign(new MailboxMessage(), <MailboxMessage>{
						senderName: 'NWB Bank',
						recipientName: 'John',
						content: 'De volgende incasso op uw rekening kon niet worden uitgevoerd<br>'
						+ '<p>'
						+ '<div class="table-responsive"> '
						+ '<table class="table">'
						+ '<tbody>'
						+ '<tr><td>REFERENTIE</td><td>B7K27CWSIO4FPT3W</td></tr>'
						+ '<tr><td>BETALER</td><td>Ludovic De Pestel</td></tr>'
						+ '<tr><td></td><td>BE11 7369 8897 1648</td></tr>'
						+ '<tr><td>SCHULDEISER</td><td>Engie</td></tr>'
						+ '<tr><td></td><td>BE74 9452 3939 2707</td></tr>'
						+ '<tr><td>BEDRAG</td><td>133,25€</td></tr>'
						+ '<tr><td>DATUM</td><td>14/06/2018</td></tr>'
						+ '<tr><td>MEDEDELING</td><td>Elektriciteit mei 2018</td></tr>'
						+ '</tbody>'
						+ '</table>'
						+ '</div>'
						+ '</p>',
						sendingDate: new Date('2018-06-14 16:35:14'),
						wasSentByCurrentUser: false,
					}),
				],
				// content: 'Please go to your bank now',
				correspondentName: 'NWB Bank',
			}),
			Object.assign(new MailboxConversation(), <MailboxConversation>{
				identifier: '7',
				type: MessageTypes.BankInformation,
				isImportant: true,
				isRead: false,
				isWaitingResponse: true,
				isAnswered: false,
				sendingDate: new Date('2017-12-13T20:17:07'),
				title: 'Important stuff',
				messages: [
					Object.assign(new MailboxMessage(), <MailboxMessage>{
						senderName: 'NWB Bank',
						recipientName: 'John',
						content: 'Please go to your bank now',
						sendingDate: new Date('2017-12-13T20:17:07'),
						wasSentByCurrentUser: false,
					}),
				],
				// content: 'Please go to your bank now',
				correspondentName: 'NWB Bank',
			}),
			Object.assign(new MailboxConversation(), <MailboxConversation>{
				identifier: '4',
				type: MessageTypes.Complaint,
				isImportant: false,
				isRead: true,
				isWaitingResponse: false,
				isAnswered: true,
				sendingDate: new Date('2017-12-12T15:18:21'),
				title: 'Re: Some random question ??',
				messages: [
					Object.assign(new MailboxMessage(), <MailboxMessage>{
						senderName: 'NWB Bank',
						recipientName: 'John',
						content: 'I do not get your point !!',
						sendingDate: new Date('2017-12-12T15:18:21'),
						wasSentByCurrentUser: false,
					}),
				],
				// content: 'I do not get your point !!',
				correspondentName: 'NWB Bank',
			}),
			Object.assign(new MailboxConversation(), <MailboxConversation>{
				identifier: '3',
				type: MessageTypes.Complaint,
				isImportant: false,
				isRead: false,
				isWaitingResponse: false,
				isAnswered: false,
				sendingDate: new Date('2017-12-11T08:03:55'),
				title: 'Some random title ...',
				messages: [
					Object.assign(new MailboxMessage(), <MailboxMessage>{
						senderName: 'NWB Bank',
						recipientName: 'John',
						content: '.. for some random message.',
						sendingDate: new Date('2017-12-11T08:03:55'),
						wasSentByCurrentUser: false,
					}),
				],
				// content: '... for some random message.',
				correspondentName: 'NWB Bank',
			}),
			Object.assign(new MailboxConversation(), <MailboxConversation>{
				identifier: '2',
				type: MessageTypes.BankInformation,
				isImportant: true,
				isRead: true,
				isWaitingResponse: true,
				isAnswered: false,
				sendingDate: new Date('2017-12-01T21:13:42'),
				title: 'Test Conversation',
				// content: 'We are pleased to welcome you here',
				correspondentName: 'NWB Bank',
				messages: [
					Object.assign(new MailboxMessage(), <MailboxMessage>{
						senderName: 'NWB Bank',
						recipientName: 'John',
						content: 'Sorry, you won\'t.',
						sendingDate: new Date('2017-12-02T08:39:14'),
						wasSentByCurrentUser: false,
					}),
					Object.assign(new MailboxMessage(), <MailboxMessage>{
						senderName: 'John',
						recipientName: 'NWB Bank',
						content: 'Thank you ! I want a lot of money.',
						sendingDate: new Date('2017-12-01T22:50:07'),
						wasSentByCurrentUser: true,
					}),
					Object.assign(new MailboxMessage(), <MailboxMessage>{
						senderName: 'NWB Bank',
						recipientName: 'John',
						content: 'We are pleased to welcome you here.',
						sendingDate: new Date('2017-12-01T21:13:42'),
						wasSentByCurrentUser: false,
					}),
				],
			}),
			Object.assign(new MailboxConversation(), <MailboxConversation>{
				identifier: '1',
				type: MessageTypes.BankInformation,
				isImportant: false,
				isRead: true,
				isWaitingResponse: false,
				isAnswered: false,
				sendingDate: new Date('2017-12-01T21:13:42'),
				title: 'Welcome message!',
				// content: 'We are pleased to welcome you here',
				messages: [
					Object.assign(new MailboxMessage(), <MailboxMessage>{
						senderName: 'NWB Bank',
						recipientName: 'John',
						content: 'We are pleased to welcome you here',
						sendingDate: new Date('2017-12-01T21:13:42'),
						wasSentByCurrentUser: false,
					}),
				],
				correspondentName: 'NWB Bank',
			}),
		];
	}
}
