type Writable<T> = {
	[K in keyof T]: T[K];
};

export abstract class ObjectBuilder<T> {
	protected properties: Partial<Writable<T>> = {};

	constructor(object: Partial<T>) {
		Object.assign(this.properties, object);
	}

	public set(key: string, value: any) {
		if (value) {
			this.properties[key] = value;
		}
		return this;
	}

	public abstract build(): T;
}
