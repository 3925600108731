// Modules
export { CustomersModule } from './customers.module';
export { HolderContact } from './model/holder-contact';
export { Customer } from './model/customer';

// Types
export { Person } from './model/person';
export { NaturalPerson } from './model/natural-person';
export { NaturalPersonBuilder } from './model/natural-person-builder';
export { ArtificialPerson } from './model/artificial-person';
export { Contact } from './model/contact';
export { ContactEmail } from './model/contact-email';
export { ContactPhoneNumber } from './model/contact-phone-number';
export { ContactType, NaturalPersonGender, NaturalPersonMaritalStatus } from './model/person.enum';
export { ContactAddress } from './model/contact-address';
export { AddressForm } from './types/forms/address-form';
export { PersonForm } from './form/person-form';

export { ContactAddressBuilder } from './builder/contact-address.builder';
export { PersonMandate } from './model/person-mandate';
export { SecretQuestion, SecretQuestionModel } from './types/secret-question';
export { SecretQuestionsList, SecretQuestionsListModel } from './types/secret-questions-list';
export { SecretQuestionParameter } from './types/models/secret-question-parameter';

// Services
export { CustomersService } from './service/customers/customers.service';
export { SecretQuestionsListsService } from './service/secret-questions-lists/secret-questions-lists.service';
export { SecretQuestionsListsServiceStub } from './service/secret-questions-lists/secret-questions-lists.service.stub';
export { PersonsService } from './service/persons/persons.service';
export { PersonsServiceStub } from './service/persons/persons.service.stub';
export { ContactsService } from './service/contacts/contacts.service';
export { ContactsServiceStub } from './service/contacts/contacts.service.stub';

// export * from './model/holder.enum';
// export * from './model/secret-question-typings';
